import CreatePassword from '../../components/Signup/CreatePassword';
import { DefaultLayout } from '../../components/layouts';
import React from 'react';

export const CreatePasswordPage: React.FC = () => {
  return (
    <DefaultLayout>
      <CreatePassword />
    </DefaultLayout>
  );
};
