import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { EmployeeTimePackage } from '@/types';
import { displayDate } from '@/utils/date.utils';
import { QuestionYesNoInput } from '@/components/QuestionYesNoInput';

interface DateStartInputProps {
  className?: string;
  /**
   * 'self' = employee declares for itself
   * 'employee'= rh declares for another employee
   */
  employeeTarget: 'self' | 'other-employee';
  employeeTimePackage: EmployeeTimePackage;
  lastDayWork: string | undefined; // lastDayWork
  onChange: (newValue: string | undefined) => void;
}

export const DateStartInput = (props: DateStartInputProps) => {
  const { employeeTarget, lastDayWork, employeeTimePackage, onChange, ...rootProps } = props;
  const [checkboxValue, setCheckboxValue] = useState(false);
  const isTimePackageDaily = employeeTimePackage === EmployeeTimePackage.Day;
  const adjustDate = (date: DateStartInputProps['lastDayWork']) =>
    (isTimePackageDaily ? moment(date) : checkboxValue ? moment(date).add(1, 'd') : moment(date)).format('YYYY-MM-DD');
  const dateDuJour = moment();
  const dateFrom = moment(lastDayWork);
  const dateDebut = adjustDate(lastDayWork);
  const dateStartString = moment(lastDayWork).format('DD/MM/YYYY');
  const title =
    employeeTarget == 'self'
      ? `Avez vous travaillé plus de 5h le ${dateStartString} ?`
      : `L'employé a-t-il travaillé plus de 5h le ${dateStartString} ?`;
  const dateDebutIsAfterToday = dateDuJour.isBefore(dateFrom);

  const renderCaption = (lastDayWork: string | undefined, dateStart: string | undefined) =>
    !dateStart || lastDayWork === dateStart ? (
      ''
    ) : (
      <div className="pb-3">
        <em>{`Date réelle de début de l'arrêt : ${displayDate(dateStart)}`}</em>
      </div>
    );
  useEffect(() => {
    if (isTimePackageDaily || dateDebutIsAfterToday) {
      setCheckboxValue(false);
    }
  }, [dateDebutIsAfterToday, isTimePackageDaily]);

  useEffect(() => {
    if (onChange) {
      onChange(adjustDate(lastDayWork));
    }
  }, [lastDayWork]);

  useEffect(() => {
    if (onChange) {
      onChange(adjustDate(lastDayWork));
    }
  }, [checkboxValue]);

  return dateDebutIsAfterToday || isTimePackageDaily ? (
    <></>
  ) : (
    <div {...rootProps}>
      <QuestionYesNoInput
        className="col-12 pb-3"
        title={title}
        value={checkboxValue}
        onChange={(value) => setCheckboxValue(Boolean(value))}
      />
      {renderCaption(lastDayWork, dateDebut)}
    </div>
  );
};
