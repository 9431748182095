import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spacer } from '@/components/Spacer';
import { Button } from '@/components/Button';
import { DocumentState } from './data';

export interface AbsenceDateSubmitProps {
  disabled?: boolean;
  document?: DocumentState;
}

export function AbsenceDateSubmit(props: AbsenceDateSubmitProps) {
  const { disabled, document } = props;
  const navigate = useNavigate();
  const location = useLocation();

  function clickPrevious() {
    const types1 = ['32', '33', '41', '42'];
    let path = '/declaration-type-2';
    if (types1.includes(document?.absenceType.value)) path = '/declaration-type-1';
    else if (document?.absenceType.value === '2') path = '/declaration';

    navigate(path, { state: location.state });
  }

  return (
    <>
      <Button color="primary" type="submit" disabled={disabled}>
        Suivant
      </Button>
      <Spacer />
      <Button color="secondary" onClick={clickPrevious}>
        Précédent
      </Button>
    </>
  );
}
