import React, { useState } from 'react';
import { Absence } from '@/types';
import { AbsencesInputSelectItemBox } from './AbsencesInputSelectItemBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Box, Divider } from '@mui/material';
import { AbsencesInputSelectItemGroup } from './AbsencesInputSelectItemGroup';
import moment from 'moment';
import type { AbsencesInputSelectProperties } from './AbsencesInputSelect';
import { Button } from '@/components/Button';

export interface AbsencesInputSelectItemProperties {
  item: Absence;
  value: AbsencesInputSelectProperties['value'];
  onChange: AbsencesInputSelectProperties['onChange'];
}

export const AbsencesInputSelectItem = ({ item, value, onChange }: AbsencesInputSelectItemProperties) => {
  const [showMore, setShowMore] = useState(false);

  const sortByDateEnd = (absences: Absence[]) =>
    absences.sort((a, b) => {
      const dateA = moment(a.date_end || a.date_end_hospi_theory);
      const dateB = moment(b.date_end || b.date_end_hospi_theory);
      return dateA.diff(dateB);
    });

  const groupedAbsence = sortByDateEnd([item, ...(item.prolongations ?? [])]);
  const absencesIds = groupedAbsence.map(({ id }) => id);
  const hasProlongation = item.prolongations != null && item.prolongations.length > 0;

  const renderItem = (absence: Absence) => (
    <AbsencesInputSelectItemBox
      key={absence.id}
      value={absence}
      checked={value?.map(({ id }) => id)?.includes(absence.id)}
      onChange={(e, absence) => {
        const selectIndex = absencesIds.indexOf(absence.id);
        const concern = groupedAbsence.filter(
          (_, index) => (e.checked && index >= selectIndex) || (!e.checked && index <= selectIndex),
        );
        onChange(e, concern);
      }}
    />
  );

  const renderHeader = () => (
    <AbsencesInputSelectItemGroup
      value={groupedAbsence}
      indeterminate={absencesIds.some((id) => value?.map(({ id }) => id)?.includes(id))}
      checked={absencesIds.every((id) => value?.map(({ id }) => id)?.includes(id))}
      onChange={(e, absence) => onChange(e, [absence, ...(absence.prolongations ?? [])])}
    />
  );

  const renderShowMore = () => (
    <Button
      color="tertiary"
      sx={{ height: '2rem', marginBottom: '0.5rem', minWidth: '12.5rem', padding: '0 1rem 0 0.5rem' }}
      onClick={() => setShowMore((value) => !value)}
    >
      {showMore ? (
        <>
          <ExpandLess sx={{ marginRight: '1rem' }} />
          Masquer le détail
        </>
      ) : (
        <>
          <ExpandMoreIcon sx={{ marginRight: '1rem' }} />
          Afficher le détail
        </>
      )}
    </Button>
  );

  return (
    <>
      {hasProlongation && renderHeader()}

      {hasProlongation ? (
        <Box>
          {renderShowMore()}
          <Box p={'0 1rem'} width={'100%'}>
            {showMore && groupedAbsence.map((prolongation) => renderItem(prolongation))}
          </Box>
        </Box>
      ) : (
        renderItem(item)
      )}

      <Divider sx={{ mt: '0.25rem', mb: '0.75rem' }} />
    </>
  );
};
