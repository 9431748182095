import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { colors } from '../../../../constants/theme.constants';
import React from 'react';
import { Link } from 'react-router-dom';
import ViewPdfModal from '../../../../components/base/ViewPdfModal';
interface EntretiensLegalItemProps {
  title: string;
  path: string | null;
}

const EntretiensLegalItem = ({ title, path }: EntretiensLegalItemProps) => {
  const [visibleModal, setVisibleModal] = React.useState(false);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          padding: '1rem 0',
        }}
      >
        <Box>
          <Typography
            style={{
              fontWeight: 'bold',
              color: colors.darkblue,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <Tooltip title="Télécharger">
            <Link to={path || ''} target="_blank" download>
              <IconButton>
                <DownloadIcon
                  style={{
                    color: colors.darkblue,
                  }}
                />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Afficher">
            <IconButton
              onClick={() => {
                setVisibleModal(true);
              }}
            >
              <VisibilityIcon
                style={{
                  color: colors.darkblue,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <ViewPdfModal visibleModal={visibleModal} setVisibleModal={setVisibleModal} title={title} path={path} />
    </Box>
  );
};

export default EntretiensLegalItem;
