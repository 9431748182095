import * as React from 'react';
import { createEditor } from 'slate';
import { withReact } from 'slate-react';
import { withShortcuts } from './withShortcut';
// import { withHistory } from 'slate-history'

//
function withHistory<T>(t: T) {
  return t;
}

export function useTextEditor() {
  return React.useMemo(() => withShortcuts(withReact(withHistory(createEditor()))), []);
}
