import { Card, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { retrieveEmployee } from '@/services/rh/employees';
import { setEmployee } from '@/store/action/employee';
import { EmployeeInformation } from '@/types/user.interface';
import { EmployeeCardContent } from '@/components/EmployeeCard';
import { useAppDispatch, useAppSelector } from '@/store';
import { ListCardContent } from '@/components/DashboardRH/ListCard';
import { EmployeeCardHeader } from '@/components/DashboardRH/EmployeeCard';

interface EmployeeCardProps {
  idEmployee: string;
  EmployeeCardHeaderProps?: { secondaryAction: React.ReactNode };
}

const EmployeeCard = (props: EmployeeCardProps) => {
  const { idEmployee, EmployeeCardHeaderProps } = props;

  const [infosEmployee, setInfosEmployee] = useState<EmployeeInformation | null>(null);
  const dispatch = useAppDispatch();
  const employee = useAppSelector((state) =>
    state.employee.employeeList?.find((employee: EmployeeInformation) => employee.id === idEmployee),
  );

  useEffect(() => {
    if (employee) {
      setInfosEmployee(employee);
    } else {
      retrieveEmployee({ id: idEmployee }).then((response) => {
        setInfosEmployee(response.data.employee);
        dispatch(setEmployee(response.data.employee));
      });
    }
  }, [idEmployee, employee]);

  if (!infosEmployee)
    return (
      <div className={'text-center'}>
        {' '}
        <CircularProgress />
      </div>
    );

  return (
    <Card sx={{ padding: 0 }}>
      <EmployeeCardHeader employeeInformation={infosEmployee} {...EmployeeCardHeaderProps} />
      <ListCardContent>
        <EmployeeCardContent employeeInformation={infosEmployee} />
      </ListCardContent>
    </Card>
  );
};

export default EmployeeCard;
