import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { PolicyLayout } from '../components/layouts';
import moment from 'moment';
import { useError } from '@/shared/useError';
import { fetchEmployeePoliciesClient } from '../services/employee';

export const PrivacyPolicyClientPage: React.FC = () => {
  const [policy, setPolicy] = useState<{
    content: string;
    updated_at: string;
  }>({ content: '', updated_at: '' });
  const { error, triggerError } = useError();

  useEffect(() => {
    const getPolicy = async () => {
      try {
        const response = await fetchEmployeePoliciesClient();
        setPolicy(response.data.policy);
      } catch {
        triggerError();
      }
    };

    getPolicy();
  }, []);

  let formattedDate = '';
  if (policy.updated_at && moment(policy.updated_at).isValid()) {
    formattedDate = moment(policy.updated_at).format('DD/MM/YYYY');
  }

  return (
    <PolicyLayout title={'Politique de confidentialité'} date={formattedDate}>
      {/* eslint-disable-next-line xss/no-mixed-html */}
      <div
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line xss/no-mixed-html
          __html: DOMPurify.sanitize(policy.content),
        }}
      />
      {error && <div className="err">{error}</div>}
    </PolicyLayout>
  );
};
