import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import 'popstate-direction';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';
import business from 'moment-business';

const ChoixDateManagerFin = () => {
  const state = useLocation();
  const addDays = business.addWeekDays(moment(state.state.dateNotif), 9);

  return (
    <div>
      {state.state.unavailability ? (
        <div className="container-fluid">
          <h3 className="question-form mt-5 mb-5 text-center">Proposition de date</h3>
          <div className="declaration-check mb-5">
            <FontAwesomeIcon className="fa-5x" icon={faCheck} />
          </div>
          <div className="col-12 text-center">
            <p>
              Vous êtes indisponible du <b>{moment(state.state.dateNotif).format('L')}</b> au{' '}
              <b>{moment(addDays).format('L')}</b>, les Ressources Humaines sont informées de votre réponse et
              confieront cette tâche à un autre collaborateur.
            </p>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <h3 className="question-form mt-5 mb-5 text-center">Votre proposition de dates a bien été prise en compte</h3>

          <form className="form-declaration text-center col-12 col-md-8">
            <div className="declaration-check mb-5">
              <FontAwesomeIcon className="fa-5x" icon={faCheck} />
            </div>

            <div className="col-12">
              <p>
                Le salarié va maintenant être informé de vos propositions afin de confirmer la date de l’entretien
                <br />
                <br />
              </p>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ChoixDateManagerFin;
