import React, { useEffect } from 'react';
import { CustomModal, type CustomModalProps } from '@/components/base/CustomModal';
import { ActionButton } from '@/components/ActionButton';
import { Box, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMUIField } from '@/hooks/useMUIField';
import { parcoursCreate } from '@/services/rh/parcours';
import { ParcoursQuery } from '@/query';
import { useToast } from '../Toast';

export interface ParcoursCreateDialogProps extends CustomModalProps {}

export const ParcoursCreateDialog = (props: ParcoursCreateDialogProps) => {
  const toast = useToast();

  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
    } satisfies parcoursCreate.Parameters,
  });
  const { registerTextField } = useMUIField(control);
  const parcoursCreateMutation = ParcoursQuery.useCreate();

  const onSubmit = handleSubmit(async (data) => {
    await parcoursCreateMutation.mutateAsync({
      name: data.name,
    });

    toast.present({ message: 'Le parcours est créé et activé', severity: 'success' });
    props.onClose?.({}, 'escapeKeyDown');
  });

  // Reset form when hidden
  useEffect(() => {
    if (props.open) {
      reset();
    }
  }, [props.open]);

  return (
    <CustomModal
      {...props}
      PaperProps={{
        // @ts-ignore do not how to fix this
        component: 'form',
        onSubmit,
      }}
      renderHeader={() => 'Nouveau parcours'}
      renderContent={() => (
        <Box>
          <TextField
            fullWidth
            label="Nom du parcours"
            {...registerTextField('name', {
              maxLength: 255,
            })}
          />
        </Box>
      )}
      renderFooter={() => (
        <>
          <ActionButton actionName="cancel" onClick={() => props.onClose?.({}, 'escapeKeyDown')} />
          <ActionButton
            actionName="add"
            type="submit"
            color="primary"
            label="Ajouter nouveau parcours"
            submitting={isSubmitting}
            disabled={!isValid}
          />
        </>
      )}
    />
  );
};
