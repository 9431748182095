import React, { useState } from 'react';
import { Absence } from '@/types';
import { PageTitle } from '@/components/PageTitle';
import { DeleteAbsencesSteps } from './DeleteAbsencesSteps';
import { deleteAbsence } from '@/services/rh/absences/service';
import { PermissionBox } from '@/components/PermissionBox';
import { useToast } from '@/components/Toast';
import { RHPage } from '@/components/RHPage';

export const DeleteAbsencesPage = () => {
  const toast = useToast();

  // States
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  // SUBMIT
  async function submitForm(values: Absence[], cause?: string | null) {
    let responses: Promise<any>[] = [];

    values.forEach((absence) => {
      responses = [
        ...responses,
        deleteAbsence({
          params: { idAbsence: absence.id },
          body: {
            action: 'delete',
            description: cause,
          },
        }),
      ];
    });
    await Promise.all(responses);
    toast.present({ message: `${responses.length} absence(s) supprimée(s)`, severity: 'success' });
    setIsSubmit(true);
  }

  return (
    <PermissionBox scope="absence-management" action="edit">
      <RHPage title="Supprimer une absence">
        <DeleteAbsencesSteps isSubmit={isSubmit} onSubmit={submitForm} onReset={() => setIsSubmit(false)} />
      </RHPage>
    </PermissionBox>
  );
};
