import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { DefaultLayout } from '../../../components/layouts';
import { colors } from '../../../constants';
import { absenceType } from '../../../features/dashboard-rh/create-absence-rh/constants/absenceType.constant';

interface Cpam {
  Nom?: string;
  'Adresse 1'?: string;
  'Adresse 2'?: string;
  Code?: string;
  Ville?: string;
}

export function DeclarationFinPage() {
  const navigate = useNavigate();
  const state = useLocation();
  const cpam: Cpam = state.state.cpam;

  const onClickBackButton = (event: any) => {
    event.preventDefault(); // stop request to server for new html
    event.stopPropagation();
    navigate('/');
  };

  useEffect(() => {
    window.addEventListener('popstate', onClickBackButton);
    return () => {
      window.removeEventListener('popstate', onClickBackButton);
    };
  });

  return (
    <div>
      <DefaultLayout>
        <div className="container-fluid">
          <h3 className="question-form mt-5 mb-5 text-center">Déclaration Terminée</h3>

          <form className="form-declaration text-center col-12 col-md-8">
            <div className="declaration-check mb-5">
              <FontAwesomeIcon className="fa-5x" icon={faCheck} />
            </div>

            <div className="col-12">
              <p>
                Merci. <br /> Votre dossier est pris en compte, nous demandons à votre service RH de valider le
                document. <br />
                <br />
              </p>

              {state.state &&
                state.state.absenceType !== absenceType.conge_maternite &&
                state.state.absenceType !== absenceType.conge_parentalite && (
                  <div>
                    <p style={{ color: 'red' }}>
                      {
                        'Attention si votre arrêt de travail n’a pas été télétransmis vous devez impérativement adresser le volet 1 de votre document à la CPAM dans les 48h, sinon vous risquez de ne pas être indemnisé'
                      }
                    </p>
                    <div>
                      {"L'adresse de votre CPAM est la suivante"}
                      <Tooltip
                        className="mb-1"
                        title="Compte tenu de votre adresse déclarée, il s’agit de l’adresse de la CPAM dont vous dépendez.
                                                        Cependant, si votre dossier CPAM n’est pas à jour, nous vous recommandons de bien vérifier l’adresse avant l’envoi."
                      >
                        <IconButton>
                          <InfoRoundedIcon
                            style={{
                              color: colors.secondary,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    {cpam ? (
                      <div>
                        <div
                          style={{
                            fontSize: '1.5rem',
                            color: '#40C9C9',
                          }}
                        >
                          {cpam && (
                            <strong>
                              {cpam.Nom && <p>{cpam.Nom}</p>}
                              {cpam['Adresse 1'] && <p>{cpam['Adresse 1']}</p>}
                              {cpam['Adresse 2'] && <p>{cpam['Adresse 2']}</p>}
                              {cpam.Code && <p>{cpam.Code}</p>}
                              {cpam.Ville && <p>{cpam.Ville}</p>}
                            </strong>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
            </div>
          </form>
          <div className="position-relative col-12 col-md-8 text-center mb-3">
            <Link className="btn-link" to="/">
              <button type="button" className="btn btn-kerij col-6 col-lg-3">
                {"Retour à l'accueil"}
              </button>
            </Link>
          </div>
        </div>
      </DefaultLayout>
    </div>
  );
}
