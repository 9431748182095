import React from 'react';
import { IconButton } from '@mui/material';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppBadIcon from '@mui/icons-material/GppBad';
import PolicyIcon from '@mui/icons-material/Policy';
import { DocumentAbsence } from '../../../types/absences.type';

interface DocumentUploaderIconStatusProperties {
  file: DocumentAbsence;
  onClick: () => void;
}

export const DocumentUploaderIconStatus = ({ file, onClick }: DocumentUploaderIconStatusProperties) => {
  function setIconColor() {
    switch (file.status) {
      case 'approved': {
        return 'success';
      }
      case 'rejected': {
        return 'error';
      }
      case 'pending': {
        return 'info';
      }
      default: {
        return 'default';
      }
    }
  }

  function setIconComponent() {
    switch (file.status) {
      case 'approved': {
        return <GppGoodIcon />;
      }
      case 'rejected': {
        return <GppBadIcon />;
      }
      case 'pending': {
        return <PolicyIcon />;
      }
      default: {
        return <PolicyIcon />;
      }
    }
  }

  return (
    <IconButton color={setIconColor()} onClick={onClick}>
      {setIconComponent()}
    </IconButton>
  );
};
