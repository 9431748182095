import React from 'react';
import { EmployeeSearchField } from '../../../shared/components/Form/EmployeeSearchField';

export const FilterSortAbsences = ({ setSearch }) => {
  return (
    <div
      className="fluid-chidren mb-3 justify-content-between text-center filtre-actions-2"
      style={{
        flexDirection: 'row',
      }}
    >
      <div className="col-6 text-start" style={{ width: '100%' }}>
        <EmployeeSearchField onChange={(searchString) => setSearch(searchString)} />
      </div>
    </div>
  );
};
