import axios from '@/lib/axios';

interface DocumentValidation {
  idDocument: string;
}

export const acceptDocument = async (params: DocumentValidation): Promise<any> => {
  return await axios.post(`/api/rh/documents/` + params.idDocument + `/accept`);
};

export const refuseDocument = async (params: DocumentValidation): Promise<any> => {
  return await axios.post(`/api/rh/documents/` + params.idDocument + `/reject`);
};
