/* eslint-disable @typescript-eslint/ban-ts-comment */
import moment from 'moment';
import { AbsenceCreation, StepProperties } from '../../props/step.interface';
import React, { useEffect, useState } from 'react';
import { useError } from '@/shared/useError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { getParamsBusiness as getParametersBusiness } from '../../../../declaration/services/get_params_business.service';
import { getBusinessAbsenceOption } from '../../../../declaration/services/get_business_absence_option.service';
import { statementStep } from '../../constants/step.constants';
import TitleProcess from '../TitleProcess';

const getAbsenceWeek = (condition: string): { pre: number; post: number } => {
  switch (condition) {
    case '311': {
      return { pre: 6, post: 10 };
    }
    case '312': {
      return { pre: 8, post: 18 };
    }
    case '32': {
      return { pre: 12, post: 22 };
    }
    case '33': {
      return { pre: 24, post: 22 };
    }
    default: {
      return { pre: 0, post: 0 };
    }
  }
};

export const MotherDate = ({ absence, setAbsence, handleChangeStep, infosEmployee }: StepProperties) => {
  const condition = absence.absenceType ?? '' + absence.absenceType1 ?? '' + absence.absenceType2 ?? '';
  const [preAbsence, setPreAbsence] = useState<number>(getAbsenceWeek(condition).pre);
  const [postAbsence, setPostAbsence] = useState<number>(getAbsenceWeek(condition).post);
  const { error, triggerError, clearError } = useError();

  const [prePromoWeek, setPrePromoWeek] = useState<number>(0);
  const [preReductionWeek, setPreReductionWeek] = useState<number>(0);
  const [preReductionHours, setPreReductionHours] = useState<number>(0);
  const [postPromoWeek, setPostPromoWeek] = useState<number>(0);

  const [modalIsOpenOption1, setModalIsOpenOption1] = useState(false);
  const [modalIsOpenOption2, setModalIsOpenOption2] = useState(false);

  const handleOnChangeChildBirthDate = (
    event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
  ) => {
    if (event.type === 'change') {
      setAbsence((absence: AbsenceCreation) => {
        const dateNaissance = event.target.value;
        let dateDebut, dateFin, isClickedOption1, isClickedOption2;
        if (event.target.value) {
          if (moment(event.target.value).isAfter(moment()) || moment().format('YYYY-MM-DD') === event.target.value) {
            clearError();
          }
          dateDebut = moment(event.target.value).subtract(preAbsence, 'weeks').format('YYYY-MM-DD');
          dateFin = moment(event.target.value).add(postAbsence, 'weeks').format('YYYY-MM-DD');
        } else {
          dateDebut = '';
          dateFin = '';
          isClickedOption1 = false;
          isClickedOption2 = false;
        }
        return {
          ...absence,
          dateNaissance,
          dateDebut,
          dateFin,
          isClickedOption1,
          isClickedOption2,
        };
      });
    } else {
      if (moment(absence.dateNaissance).isBefore(moment()) && moment().format('YYYY-MM-DD') !== absence.dateNaissance) {
        triggerError("La date de naissance doit être supérieur ou égale à aujourd'hui");
      }
      if (!event.target.value) {
        setAbsence((absence: AbsenceCreation) => ({
          ...absence,
          isClickedOption1: false,
          isClickedOption2: false,
        }));
      }
    }
  };

  useEffect(() => {
    if (infosEmployee?.business?.id) {
      getParametersBusiness(
        absence.absenceType,
        absence.absenceType1 as string,
        absence.absenceType2 as string,
        postAbsence,
        0,
        preAbsence,
        infosEmployee.business.id,
      ).then((value) => {
        setPostAbsence(value.postAbsence);
        setPreAbsence(value.preAbsence);
      });

      getBusinessAbsenceOption(
        absence.absenceType,
        absence.absenceType1 as string,
        absence.absenceType2 as string,
        infosEmployee.business.id,
      ).then((value) => {
        setPrePromoWeek(value.prePromoWeek);
        setPreReductionWeek(value.preReductionWeek);
        setPreReductionHours(value.preReductionHours);
        setPostPromoWeek(value.postPromoWeek);
      });
    }
  }, [infosEmployee, absence]);

  useEffect(() => {
    if (!error && absence.dateNaissance && absence.dateDebut && absence.dateFin) {
      handleChangeStep(statementStep.Document);
    }
  }, [error, absence]);

  return (
    <div>
      <TitleProcess title={`Comment s'organise votre congé maternité ?`} />
      <div className="mb-3 col-lg-12">
        <label className="col-8">
          Date de naissance prévue
          <input
            className="form-control form-date"
            name="dateNaissance"
            type="date"
            placeholder="Date de naissance"
            value={absence.dateNaissance}
            onChange={handleOnChangeChildBirthDate}
            onBlur={handleOnChangeChildBirthDate}
            min={moment().format('YYYY-MM-DD')}
            required
          />
        </label>
      </div>

      <h4 className="question-form mb-3 text-center">Congé légal de maternité : {preAbsence + postAbsence} Semaines</h4>

      <div className="row col-lg-12 planning-maternite text-center mb-5">
        <div className="col-4 col-lg-4 pre-nat container-date">
          <div className="title-div-nat">Congé pré-natal</div>
          <div className="week-div-nat">
            {preAbsence} semaines {absence.isClickedOption2 ? <span>(+ {prePromoWeek} semaines)</span> : ''}
          </div>
          <div className="date-div-nat">
            {absence.dateDebut && absence.dateNaissance && `Du ${moment(absence.dateDebut).format('DD/MM/YYYY')}`}
          </div>
        </div>
        <div className="col-4 col-lg-4 date-nat container-date">
          <div className="title-div-nat">NAISSANCE THEORIQUE</div>
          <div className="date-div-nat">
            {absence.dateNaissance && moment(absence.dateNaissance).format('DD/MM/YYYY')}
          </div>
        </div>
        <div className="col-4 col-lg-4 post-nat container-date">
          <div className="title-div-nat">Congé post-natal</div>
          <div className="week-div-nat">
            {postAbsence} semaines {absence.isClickedOption2 ? <span>(+ {postPromoWeek} semaines)</span> : ''}
          </div>
          <div className="date-div-nat">
            {absence.dateFin && absence.dateNaissance && `Jusqu'au ${moment(absence.dateFin).format('DD/MM/YYYY')}`}
          </div>
        </div>
      </div>
      {absence.dateNaissance && prePromoWeek ? (
        <div>
          <h4
            className="question-form mb-3 text-center"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: 0,
              alignItems: 'flex-start',
            }}
          >
            {"Congé supra légal offert par l'entreprise : "}
            {prePromoWeek} semaines
          </h4>
          <h6
            className="question-form mb-3 mt-2 text-center"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: 0,
              alignItems: 'flex-start',
            }}
          >
            Choisissez une option
          </h6>
        </div>
      ) : (
        <></>
      )}
      {absence.dateNaissance && (
        <ul
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 0,
            alignItems: 'flex-start',
          }}
        >
          {preReductionWeek && preReductionHours ? (
            <li
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 20,
                padding: 0,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 20,
                }}
              >
                <FontAwesomeIcon
                  icon={absence.isClickedOption1 ? faCircleCheck : faCircle}
                  style={{
                    color: '#40C9C9',
                    marginRight: 5,
                    width: 30,
                    height: 30,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (!absence.isClickedOption1) {
                      setAbsence((absence: AbsenceCreation) => ({
                        ...absence,
                        isClickedOption2: false,
                      }));
                    }
                    setAbsence((absence: AbsenceCreation) => ({
                      ...absence,
                      isClickedOption1: !absence.isClickedOption1,
                    }));
                  }}
                />{' '}
                Aménagement des horaires &nbsp;
                <FontAwesomeIcon
                  className="fa-1x modal-info"
                  icon={faCircleInfo}
                  onClick={() => {
                    setModalIsOpenOption1(!modalIsOpenOption1);
                  }}
                />
              </div>
            </li>
          ) : (
            <></>
          )}
          {prePromoWeek ? (
            <li
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 20,
                }}
              >
                <FontAwesomeIcon
                  icon={absence.isClickedOption2 ? faCircleCheck : faCircle}
                  style={{
                    color: '#40C9C9',
                    marginRight: 5,
                    width: 30,
                    height: 30,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (!absence.isClickedOption2) {
                      setAbsence((absence: AbsenceCreation) => ({
                        ...absence,
                        isClickedOption1: false,
                      }));
                    }

                    // @ts-ignore FIXME: les dates sont undefined
                    setAbsence((absence: AbsenceCreation) => ({
                      ...absence,
                      isClickedOption2: !absence.isClickedOption2,
                      dateEndReduction:
                        preReductionWeek && preReductionHours
                          ? absence.isClickedOption2
                            ? moment(absence.dateFin).add(preReductionWeek, 'weeks').format('YYYY-MM-DD')
                            : moment(absence.dateFin)
                                .add(preReductionWeek, 'weeks')
                                .subtract(prePromoWeek, 'weeks')
                                .format('YYYY-MM-DD')
                          : null,
                      hoursReduction: preReductionWeek && preReductionHours ? preReductionHours : null,
                      dateEndPromo: prePromoWeek
                        ? absence.isClickedOption2
                          ? moment(absence.dateFin).add(prePromoWeek, 'weeks').format('YYYY-MM-DD')
                          : moment(absence.dateFin).format('YYYY-MM-DD')
                        : null,
                    }));
                  }}
                />{' '}
                {"Congé supplémentaire (Jusqu'au "}
                <span style={{ fontWeight: '700', marginLeft: 5 }}>
                  {absence.isClickedOption2
                    ? moment(absence.dateFin).format('DD/MM/YYYY')
                    : moment(absence.dateFin).add(prePromoWeek, 'weeks').format('DD/MM/YYYY')}
                </span>
                )&nbsp;
                <FontAwesomeIcon
                  className="fa-1x modal-info"
                  icon={faCircleInfo}
                  onClick={() => {
                    setModalIsOpenOption2(!modalIsOpenOption2);
                  }}
                />
              </div>
            </li>
          ) : (
            <></>
          )}
        </ul>
      )}
    </div>
  );
};
