import React, { useState, useEffect } from 'react';
import { Navbar } from '@/components/Navbar/Navbar';
import Footer from '../Footer/Footer';
import { NavbarMobile } from '@/components/Navbar/NavbarMobile';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../../lib/axios';
import moment from 'moment';
import { employeeVerifyAbsence } from '../../services/employee';
import { StatusCodes } from 'http-status-codes';
import RequireLogin from '../RequireLogin';

function QuestionnaireAcceuille1() {
  const navigate = useNavigate();
  const [isReturn, setIsReturn] = useState('1');
  const [isInterview, setIsInterview] = useState('1');
  const [searchParams] = useSearchParams();
  const [absence, setAbsence] = useState();
  const [access, setAccess] = useState(true);
  const token = searchParams.get('token_decode');

  useEffect(() => {
    try {
      if (token) {
        employeeVerifyAbsence()
          .then((value) => {
            if (value.status === StatusCodes.OK) {
              if (
                value.data.absence.is_welcome ||
                Math.ceil(moment(value.data.absence.date_end).diff(moment(value.data.absence.date_start), 'd', true)) >=
                  60 ||
                Math.ceil(moment(value.data.absence.date_end).diff(moment(value.data.absence.date_start), 'd', true)) <
                  30
              ) {
                return setAccess(false);
              }
              setAbsence(value.data.absence);
            }
          })
          .catch((err) => {
            setAccess(false);
          });
      }
    } catch (err) {
      setAccess(false);
    }
  }, [token]);

  const handleClick = () => {
    axios
      .post(
        '/api/rh/business/reponse_welcome_question',
        {
          isInterview: isInterview,
          isReturn: isReturn,
          type: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((value) => {
        if (value.status === StatusCodes.OK) {
          navigate('/questionnaireretourfin');
        }
      });
  };

  return (
    <RequireLogin>
      <div>
        <Navbar />
        {access ? (
          <div>
            {absence ? (
              <div className="container-fluid mt-5">
                <h1 className="question-form mb-5">Questionnaire de réacceuille</h1>
                <h4 className="subtitle-services text-center" style={{ width: '60%' }}>
                  Bonjour, nous espérons que vous allez mieux. Votre retour de "
                  {absence.type_absence.description_label.toLowerCase()}" en théorique est prévu dans{' '}
                  {Math.ceil(moment(absence.date_end).diff(moment(), 'd', true))} jours. Afin de vous réaccueillir dans
                  les meilleures conditions possibles, vous bénéficierez d’un entretien de retour à l’emploi avec votre
                  manager afin de créer un cadre favorable à votre reprise d’activité{' '}
                  {absence.type_absence.description_2_label.toLowerCase().includes('accident de travail')
                    ? 'et une visite de reprise avec le médecin du travail'
                    : ''}
                  . Afin de nous organiser, nous vous remercions de répondre aux questions ci-dessous.
                </h4>
                <h3 className="subtitle-services text-center">Pensez revenir au travail à l’issue de votre arrêt :</h3>
                <form className="form-declaration form-declaration col-lg-4">
                  <label>
                    <select
                      className="form-select"
                      name="retour"
                      value={isReturn}
                      onChange={(e) => {
                        setIsReturn(e.target.value);
                      }}
                      required
                    >
                      <option value={1}>Je pense que mon arrêt ne sera pas prolongé</option>
                      <option value={2}>Je pense que mon arrêt va être prolongé</option>
                      <option value={3}>Je n’en sais rien.</option>
                    </select>
                  </label>
                </form>
                {isReturn !== '2' ? (
                  <div className="container-fluid">
                    <h3 className="subtitle-services text-center">
                      Souhaitez vous bénéficier d’un entretien avec le médecin du travail (I)
                    </h3>
                    <form className="form-declaration form-declaration col-lg-4">
                      <label>
                        <select
                          className="form-select"
                          name="retour"
                          value={isInterview}
                          onChange={(e) => {
                            setIsInterview(e.target.value);
                          }}
                          required
                        >
                          <option value={1}>Non</option>
                          <option value={2}>Oui</option>
                        </select>
                      </label>
                    </form>
                  </div>
                ) : null}
                <button className="btn btn-kerij" onClick={handleClick}>
                  Valider
                </button>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="container-fluid mt-5">
            <h1 className="question-form mb-5">Ce lien n'est plus disponible</h1>
          </div>
        )}

        <NavbarMobile />
        <Footer />
      </div>
    </RequireLogin>
  );
}

export default QuestionnaireAcceuille1;
