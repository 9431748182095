export const QUESTIONNAIRE_SUIVI_CHOICES = {
  BACK_CORRECT_DATE: '1',
  BACK_OTHER_DATE: '2',
  BACK_PROLONGATION: '3',
};

export const TypesDocuments = {
  Arret: '1',
  Hospitalisation: '2',
  Grossesse: '3',
};

export const getTypeDocument = (absenceType: string) => {
  switch (absenceType) {
    case TypesDocuments.Arret: {
      return 'arret de travail';
    }
    case TypesDocuments.Hospitalisation: {
      return 'hospitalisation';
    }
    case TypesDocuments.Grossesse: {
      return 'certificat de grossesse';
    }
    default: {
      return '';
    }
  }
};
