import React from 'react';
import { Typography } from '@mui/material';
import { DocumentAbsence, DocumentStatus } from '../../../types/absences.type';

interface DocumentUploaderDescriptionProperties {
  document: DocumentAbsence;
}

export const DocumentUploaderDescription = ({ document }: DocumentUploaderDescriptionProperties) => {
  function getStatusDescription() {
    const status = document.status;

    switch (status) {
      case DocumentStatus.PENDING: {
        return 'Ce document mérite une validation manuelle';
      }
      case DocumentStatus.REJECTED: {
        return 'Ce document ne correspond pas';
      }
      case DocumentStatus.APPROVED: {
        return 'Document validé';
      }
      case DocumentStatus.ANNEXED: {
        return "Document annexé à l'absence";
      }
      default: {
        return "Ce document n'a pas été vérifié";
      }
    }
  }

  return (
    <>
      <Typography>{document.name}</Typography>
      <Typography
        fontSize={'small'}
        fontStyle={'italic'}
        sx={{
          color:
            document.status === DocumentStatus.REJECTED
              ? 'error.main'
              : document.status === DocumentStatus.APPROVED || document.status === DocumentStatus.ANNEXED
              ? 'success.main'
              : 'info.main',
        }}
      >
        {getStatusDescription()}
      </Typography>
    </>
  );
};
