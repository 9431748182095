import React, { useState } from 'react';
import { Box } from '@mui/material';
import CustomModal from '../CustomModal';
import { Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';

interface IViewPdfModalProperties {
  setVisibleModal?: any;
  visibleModal: boolean;
  path: string | null;
  title?: string;
}

const ViewPdfModal = ({ visibleModal, setVisibleModal, path, title }: IViewPdfModalProperties) => {
  return (
    <CustomModal
      renderHeader={() => <>{title}</>}
      open={visibleModal || false}
      setOpen={setVisibleModal}
      contentProps={{
        style: { paddingLeft: '4rem', paddingRight: '4rem' },
      }}
      renderContent={() => <Content path={path} />}
      maxWidth="md"
    />
  );
};

interface IContentProperties {
  path: string | null;
}

const Content = ({ path }: IContentProperties) => {
  const [numberPages, setNumberPages] = useState<number>();

  const onDocumentLoadSuccess = ({ numPages: nextNumberPages }: PDFDocumentProxy) => {
    setNumberPages(nextNumberPages);
  };
  return (
    <Box className="container-fluid col-12">
      <Document file={path} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from({ length: numberPages || 0 }).map((element, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={800}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        ))}
      </Document>
    </Box>
  );
};

export default ViewPdfModal;
