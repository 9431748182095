import { isAccident, isHospitalisation, isMaladie } from '../../../services/rh/absences/data';
import { CodesAbsences } from '../../../services/rh/codes/data';
import { TypesAbsences } from '../../../services/rh/types/data';
import type { Absence } from '../../../types/absences.type';

const MALADIE_TO_ACCIDENT = {
  code_absence: CodesAbsences.ACCIDENT_TRAVAIL,
  id_type_absence: TypesAbsences.ACCIDENT_TRAVAIL,
  log: 'Changement de nature Maladie → Accident - Maladie professionnelle',
};

const ACCIDENT_TO_MALADIE = {
  code_absence: CodesAbsences.MALADIE,
  id_type_absence: TypesAbsences.MALADIE,
  log: 'Changement de nature Accident - Maladie professionnelle → Maladie',
};

const HOSPITALISATION_TO_ACCIDENT = {
  code_absence: CodesAbsences.ACCIDENT_TRAVAIL,
  id_type_absence: TypesAbsences.ACCIDENT_TRAVAIL,
  log: 'Changement de nature Hospitalisation → Accident - Maladie professionnelle',
};

export function getNatureBody(absence: Absence) {
  if (isMaladie(absence)) return MALADIE_TO_ACCIDENT;
  else if (isAccident(absence)) return ACCIDENT_TO_MALADIE;
  else if (isHospitalisation(absence)) return HOSPITALISATION_TO_ACCIDENT;
}
