import { AbsenceDate } from './Date/AbsenceDate.component';
import { HospiDate } from './Date/HospiDate.component';
import { StepProperties } from '../props/step.interface';
import { MotherDate } from './Date/MotherDate.component';
import { FatherDate } from './Date/FatherDate.component';
import React from 'react';
const getAbsenceDate = ({ absence, setAbsence, handleChangeStep, infosEmployee }: StepProperties) => {
  const absenceType = absence.absenceType;
  switch (absenceType) {
    case '1': {
      return (
        <AbsenceDate
          absence={absence}
          setAbsence={setAbsence}
          handleChangeStep={handleChangeStep}
          infosEmployee={infosEmployee}
        />
      );
    }
    case '2': {
      return (
        <HospiDate
          absence={absence}
          setAbsence={setAbsence}
          handleChangeStep={handleChangeStep}
          infosEmployee={infosEmployee}
        />
      );
    }
    case '3': {
      return (
        <MotherDate
          absence={absence}
          setAbsence={setAbsence}
          handleChangeStep={handleChangeStep}
          infosEmployee={infosEmployee}
        />
      );
    }
    case '4': {
      return (
        <FatherDate
          absence={absence}
          setAbsence={setAbsence}
          handleChangeStep={handleChangeStep}
          infosEmployee={infosEmployee}
        />
      );
    }
    default: {
      return <></>;
    }
  }
};

export const Date = ({ absence, setAbsence, handleChangeStep, infosEmployee }: StepProperties) => {
  return (
    <div>
      {getAbsenceDate({
        absence,
        setAbsence,
        handleChangeStep,
        infosEmployee,
      })}
    </div>
  );
};
