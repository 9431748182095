import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

export interface QuestionYesNoInputProps {
  className?: string;
  title?: React.ReactNode;
  value?: boolean;
  onChange?: (value: boolean | undefined) => void;
}

export const QuestionYesNoInput = React.forwardRef(function QuestionYesNoInput(
  props: QuestionYesNoInputProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const { title, value, onChange, ...rootProps } = props;

  const renderButton = ({ label, value: buttonValue }: { label: string; value: boolean }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexBasis: 'row',
          alignItems: 'center',
          marginRight: 30,
        }}
        onClick={() => {
          console.log('click', rootProps);
          if (onChange) {
            console.log('change');
            onChange(buttonValue);
          }
        }}
      >
        <FontAwesomeIcon
          icon={value === buttonValue ? faCircleCheck : faCircle}
          style={{
            color: '#40C9C9',
            marginRight: 5,
          }}
        ></FontAwesomeIcon>
        <div style={{ fontWeight: '800' }}>{label}</div>
      </div>
    );
  };

  return (
    <div ref={forwardedRef} {...rootProps}>
      <div className="col-12 mb-3" style={{ fontWeight: '800', whiteSpace: 'nowrap' }}>
        {title}
      </div>
      <div className="d-flex col-3 mx-auto">
        {renderButton({ label: 'Oui', value: true })}
        {renderButton({ label: 'Non', value: false })}
      </div>
    </div>
  );
});
