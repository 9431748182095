import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { employeeSendCode, employeeVerifyContact } from '../../../../../services/employee';
import { useAppSelector } from '@/store';
import { Button } from '@/components/Button';

function ModifyContactMailUI() {
  const [mail, setMail] = useState('');
  const [validContact, setValidContact] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const state = useLocation();
  const user = useAppSelector((state) => state.accountContext.user);
  const idUser = state.state?.data?.id || (user ? user.employee_information.id : null);

  useEffect(() => {
    if (!state.state) {
      navigate('/login');
    }
    if (mail) {
      employeeVerifyContact({
        email: mail,
        idEmployeeInformation: idUser,
      }).then((value) => {
        if (value.data.hasExisted) {
          setMessage("Cette adresse email est déjà présente dans l'application");
          setValidContact(false);
        } else {
          setMessage('');
          setValidContact(true);
        }
      });
    } else {
      setMessage('');
      setValidContact(false);
    }
  }, [mail]);

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      if (validContact) {
        await employeeSendCode({
          email: mail,
        }).then((value) => {
          const name =
            state.state?.data?.employee_information.lastname || (user ? user.employee_information.firstname : null);
          const page = state.state?.page;
          const document = state.state?.document || {};
          navigate('/confirmation', {
            state: {
              id: idUser,
              mail: mail,
              ...state.state.data,
              telephone: null,
              nom: name,
              page,
              document,
              location: state.pathname,
            },
          });
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <form onSubmit={handleSubmit} className="mt-5 mb-5 col-lg-4">
          <h3 className="question-form mb-5">Informations de contact</h3>

          <div className="msg-pass">
            <em>
              {`Ces informations permettent à KERij de vous
                            accompagner tout au long de vos parcours d'absences.
                            Elles ne seront, en aucun cas, transmises à votre
                            employeur.`}
            </em>
          </div>

          <label>
            <input
              name="mail"
              type="email"
              placeholder="Email"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />
          </label>
          <div className="err">{message}</div>
          <Button color="primary" type="submit">
            Valider
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ModifyContactMailUI;
