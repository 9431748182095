import { type PayloadAction, createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { type BusinessId, type CompanyId } from '../../types/business.interface';
import type { AppState } from '../store';
import type * as RHAccountContext from './rhAccountContext';
import { rhAdminFetchBusiness, rhAdminFetchCompany } from '@/services/rh/admin';

export interface BusinessEntry {
  id: BusinessId;
  isChecked: boolean;
  name: string;
}

export interface CompanyEntry {
  id: CompanyId;
  isChecked: boolean;
  name: string;
}

export interface State {
  listBusiness: Array<BusinessId>;
  selectedIdBusiness: null | BusinessId;
  listSelectBusiness: Array<BusinessEntry>;
  listSelectCompany: Array<CompanyEntry>;
  selectedIdCompany: null | CompanyId;
}

const initialState: State = {
  listBusiness: [],
  selectedIdBusiness: null,
  listSelectBusiness: [],
  listSelectCompany: [],
  selectedIdCompany: null,
};

export const slice = createSlice({
  name: 'rhNavbarContext',
  initialState,
  reducers: {
    setListBusiness: (state, action: PayloadAction<State['listBusiness']>) => {
      state.listBusiness = action.payload;
    },
    setSelectedIdBusiness: (state, action: PayloadAction<State['selectedIdBusiness']>) => {
      state.selectedIdBusiness = action.payload;
    },
    setListSelectBusiness: (state, action: PayloadAction<State['listSelectBusiness']>) => {
      state.listSelectBusiness = action.payload;
    },
    setListSelectCompany: (state, action: PayloadAction<State['listSelectCompany']>) => {
      state.listSelectCompany = action.payload;
    },
    setSelectedIdCompany: (state, action: PayloadAction<State['selectedIdCompany']>) => {
      state.selectedIdCompany = action.payload;
    },
  },
});

export const getSelf = (state: AppState) => state[slice.name];

export const getSelectedBusinessId = createSelector([getSelf], (self) => self.selectedIdBusiness);

export const getBusinessSelectList = createSelector([getSelf], (self) => self.listSelectBusiness);

export const getBusinessSelectedIdList = createSelector([getBusinessSelectList], (listSelectBusiness) =>
  listSelectBusiness.filter((value) => value.isChecked).map((value) => value.id),
);

export const getBusinessList = createSelector([getSelf], (self) => self.listBusiness);

export const getCompanySelectList = createSelector([getSelf], (self) => self.listSelectCompany);

export const getCopyrightYear = () => new Date().getFullYear(); // HACK: the year almost never changes

export const { actions } = slice;

// TODO: listen to action and trigger update
export const update = createAsyncThunk<Promise<void>, RHAccountContext.RhContext | undefined>(
  `${slice.name}/update`,
  async (rh, { dispatch }) => {
    //const state = getState() as AppState;

    if (rh) {
      if (rh.rh_information.id_business) {
        dispatch(actions.setSelectedIdBusiness(rh.rh_information.id_business));
      }
      if (rh.rh_information.id_businesses) {
        dispatch(
          actions.setSelectedIdBusiness(
            rh.rh_information.id_business || JSON.parse(rh.rh_information.id_businesses).at(0),
          ),
        );
      }

      if (
        !rh.rh_information.id_business &&
        !rh.rh_information.id_group &&
        rh.rh_information.id_parent_company &&
        rh.rh_information.id_businesses
      ) {
        rhAdminFetchBusiness({
          listIdBusiness: JSON.stringify(JSON.parse(rh.rh_information.id_businesses)),
        }).then((val) => {
          dispatch(
            actions.setListSelectBusiness(
              val.data.businesses.map((v: BusinessEntry) => {
                v.isChecked = true;
                return v;
              }),
            ),
          );
          dispatch(actions.setListBusiness(val.data.businesses.map((v: BusinessEntry) => v.id)));
        });
      }
      if (
        !rh.rh_information.id_business &&
        !rh.rh_information.id_parent_company &&
        rh.rh_information.id_group &&
        rh.rh_information.id_parent_companies
      ) {
        rhAdminFetchCompany({
          listIdCompany: JSON.stringify(Object.keys(JSON.parse(rh.rh_information.id_parent_companies))),
        }).then((val) => {
          dispatch(
            actions.setListSelectCompany(
              val.data.companies.map((v: CompanyEntry) => {
                v.isChecked = false;
                return v;
              }),
            ),
          );
        });
      }
    }
  },
);
