// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentPreview {
  border-radius: 0.25rem;
}
.DocumentPreview__Page canvas {
  width: 100% !important;
  height: auto !important;
}

.DocumentPreview--Default,
.DocumentPreview__Icon--Default {
  border: 0.25rem #40c9c9 solid;
}
.DocumentPreview--Failed,
.DocumentPreview__Icon--Failed {
  border: 0.25rem #ff2e00 solid;
}
.DocumentPreview--Warning,
.DocumentPreview__Icon--Warning {
  border: 0.25rem #ff8039 solid;
}`, "",{"version":3,"sources":["webpack://./src/features/dashboard-rh/create-absence-rh/components/Modal/OCR/DocumentPreview.scss"],"names":[],"mappings":"AAAA;EAQE,sBAAA;AANF;AAAI;EACE,sBAAA;EACA,uBAAA;AAEN;;AAOE;;EACE,6BAAA;AAHJ;AAME;;EACE,6BAAA;AAHJ;AAME;;EACE,6BAAA;AAHJ","sourcesContent":[".DocumentPreview {\n  &__Page {\n    canvas {\n      width: 100% !important;\n      height: auto !important;\n    }\n  }\n\n  border-radius: 0.25rem;\n}\n\n.DocumentPreview,\n.DocumentPreview__Icon {\n  &--Default {\n    border: 0.25rem #40c9c9 solid;\n  }\n\n  &--Failed {\n    border: 0.25rem #ff2e00 solid;\n  }\n\n  &--Warning {\n    border: 0.25rem #ff8039 solid;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
