import { FooterLink } from '../types/footerLink.interface';

export const defaultLinks = [
  { label: 'CGU', url: '/conditions' },
  { label: 'Mentions légales', url: '/mentions-legales' },
  { label: 'Politique de cookies', url: '/politique-de-cookies' },
  {
    label: 'Nous signaler un problème',
    url: '/report',
  },
];

export const footerLinksConfig: Record<string, FooterLink[]> = {
  '^/services': [
    ...defaultLinks,
    {
      label: 'Politique de confidentialité',
      url: '/politique-de-confidentialite',
    },
  ],
  '^/declaration': [
    { label: 'CGU', url: '/conditions' },
    { label: 'Politique de cookies', url: '/politique-de-cookies' },
    {
      label: 'Politique de confidentialité',
      url: '/politique-de-confidentialite-client',
    },
  ],
  '.*': [...defaultLinks], // catch-all route
};
