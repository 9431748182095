import * as React from 'react';
import { useAppFeature } from '@/store';
import type { Feature } from '@/types/feature.type';

export interface IfFeatureProps {
  /**
   * Name of the feature
   */
  name: keyof Feature;
  /**
   * Children to display when feature enabled
   */
  children?: React.ReactNode;
  /**
   * Fallback component to display when feature disabled
   */
  fallback?: React.ReactNode;
}

/**
 * @example
 * <IfFeature name="ServiceManagement" fallback={<div>Coming Soon!</div> }>
 *   <Button>
 *     Restricted area !
 *   </Button>
 * </IfFeature>
 * @param props
 */
export const IfFeature = (props: IfFeatureProps) => {
  return useAppFeature()[props.name] ? <>{props.children}</> : <>{props.fallback ?? null}</>;
};
