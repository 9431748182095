import { Box } from '@mui/material';
import React from 'react';

interface ListCardProps {
  children?: React.ReactNode;
}

export const ListCard = (props: ListCardProps) => {
  const { children } = props;

  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      className="d-flex"
    >
      <Box width={'100%'} mb={2.5}>
        {children}
      </Box>
    </Box>
  );
};
