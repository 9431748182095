import * as ThemeIcon from '@/components/ServiceThemeIcon';

export interface Service {
  id: string;
  id_business: string;
  title: string;
  themes: ServiceTheme[];
  eligibilities: ServiceEligibility[];
  adder?: ServiceAdder;
  funding?: ServiceFinance;
  short_description: string;
  description: string;
  phone?: string;
  email?: string;
  website?: string;
  identifiant?: string;
  password?: string;
  promo?: string;
  address?: string;
  address_complement?: string;
  zipcode?: string;
  city?: string;
  provider?: string;
  document_url?: string;
  img_url?: string;
  service_documents?: ServiceDocument[];
  categories?: (ServiceReferenceItem & { type: ServiceCategoryType })[];
}

export interface ServiceReferenceItem {
  id: string;
  reference: ServiceReference;
}

export interface ServiceReference {
  id: string;
  label: string;
}

export const ServiceReferenceType = {
  Category: 'category',
  Subcategory: 'subcategory',
} as const;
export type ServiceReferenceType = (typeof ServiceReferenceType)[keyof typeof ServiceReferenceType];
export const isServiceReferenceType = (value?: any): value is ServiceReferenceType =>
  typeof value === 'string' && Object.values(ServiceReferenceType).includes(value as any);

export const ServiceCategoryType = {
  Category: 'category',
  Subcategory: 'subcategory',
} as const;
export type ServiceCategoryType = (typeof ServiceCategoryType)[keyof typeof ServiceCategoryType];

export interface ServiceDocument {
  id: string;
  id_business: string;
  id_service: string;
  name: string;
  bucket_key: string;
  type: string;
  created_at: string;
  updated_at: string;
  url: string;
}

export type ServiceDocumentCreation = Omit<ServiceDocument, 'id' | 'created_at' | 'updated_at' | 'url'>;

export const ServiceDocumentType = {
  Logo: 'logo',
  Referential: 'referential',
} as const;
export type ServiceDocumentType = (typeof ServiceDocumentType)[keyof typeof ServiceDocumentType];

// ENUM liés aux Services
export enum ServiceTheme {
  Addictions = 'addictions',
  Aidants = 'aidants',
  Coordonnees = 'coordonnees',
  Deces = 'deces',
  Handicap = 'handicap',
  Hospitalisation = 'hospitalisation',
  Parentalite = 'parentalite',
  RetourEmploi = 'retour',
  Sante = 'sante',
  TMS = 'tms',
  TPS = 'psychologie',
  VieQuotidienne = 'vieQuotidienne',
}

export enum ServiceAdder {
  Assureur = 'assureur',
  Courtier = 'courtier',
  CRAM = 'cram',
  AGEFIP = 'agefip',
  Employeur = 'employeur',
  SST = 'sst',
}

export enum ServiceFinance {
  Gratuit = 'gratuit',
  Payant = 'payant',
  CoFinance = 'co-finance',
}

export enum ServiceEligibility {
  Tous = 'tous',
  Cadres = 'cadres',
  NonCadres = 'non-cadres',
  CadresDirigeants = 'cadres-dirigeants',
  ETAM = 'etam',
}

// Helper
export const convertServiceToSelectItem = (values: { [key: string]: string }, labels: { [key: string]: string }) =>
  Object.values(values).map((value) => ({ value, label: labels[`${value}`] }));

export const ServiceThemeLabel = {
  [ServiceTheme.Addictions]: 'Addictions',
  [ServiceTheme.Aidants]: 'Aidants',
  [ServiceTheme.Coordonnees]: "Carnet d'adresses",
  [ServiceTheme.Deces]: 'Fin de vie et décès',
  [ServiceTheme.Handicap]: 'Handicap',
  [ServiceTheme.Hospitalisation]: 'Hospitalisation',
  [ServiceTheme.Parentalite]: 'Parentalité',
  [ServiceTheme.RetourEmploi]: "Retour à l'emploi",
  [ServiceTheme.Sante]: 'Santé',
  [ServiceTheme.TMS]: 'Troubles musculo–squelettiques',
  [ServiceTheme.TPS]: 'Troubles psycho-sociaux',
  [ServiceTheme.VieQuotidienne]: 'Vie quotidienne',
};

export const ServiceThemeIcon = {
  [ServiceTheme.Addictions]: ThemeIcon.addictions,
  [ServiceTheme.Aidants]: ThemeIcon.aidants,
  [ServiceTheme.Coordonnees]: ThemeIcon.coordonnees,
  [ServiceTheme.Deces]: ThemeIcon.deces,
  [ServiceTheme.Handicap]: ThemeIcon.handicap,
  [ServiceTheme.Hospitalisation]: ThemeIcon.hospitalisation,
  [ServiceTheme.Parentalite]: ThemeIcon.parentalite,
  [ServiceTheme.RetourEmploi]: ThemeIcon.retour,
  [ServiceTheme.TMS]: ThemeIcon.tms,
  [ServiceTheme.TPS]: ThemeIcon.psychologie,
  [ServiceTheme.Sante]: ThemeIcon.sante,
  [ServiceTheme.VieQuotidienne]: ThemeIcon.vieQuotidienne,
};

export const ServiceAdderLabel = {
  [ServiceAdder.Assureur]: 'Assureur',
  [ServiceAdder.Courtier]: 'Courtier',
  [ServiceAdder.CRAM]: 'CRAM',
  [ServiceAdder.AGEFIP]: 'AGEFIP',
  [ServiceAdder.Employeur]: 'Employeur',
  [ServiceAdder.SST]: 'SST',
};

export const ServiceFinanceLabel = {
  [ServiceFinance.Gratuit]: 'Gratuit',
  [ServiceFinance.Payant]: 'Payant',
  [ServiceFinance.CoFinance]: 'Co-financé',
};

export const ServiceEligibilityLabel = {
  [ServiceEligibility.Tous]: 'Tous les salariés',
  [ServiceEligibility.Cadres]: 'Les cadres',
  [ServiceEligibility.NonCadres]: 'Les non cadres',
  [ServiceEligibility.CadresDirigeants]: 'Les cadres dirigeants',
  [ServiceEligibility.ETAM]: 'ETAM',
};
