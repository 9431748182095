import { Box, Input, InputLabel } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import logo from '../../../Logotype-Icone-Fondclair.svg';
import { KerijLoading, KerijLoadingType } from '@/components/base';
import CustomModal from '@/components/base/CustomModal';
import KerijButton from '@/components/base/button/kerijButton';
import { PageTitle } from '@/components/PageTitle';
import { fetchRhProfile, fetchRhRoleById, updateRhProfile } from '@/services/rh';
import { RHInformation, RHLoginInterface, RHRole } from '@/types';

import ChangePasswordModal from '@/components/DashboardRH/ChangePasswordModal/ChangePasswordModal';
import { Create } from '@/components/DashboardRH/Administration/AdminRoles/Create';
import { ActionButton } from '@/components/ActionButton';
import { RHPage } from '@/components/RHPage';

interface IEditModal {
  isOpen: boolean;
  onClose: () => void;
  rhProfile: RHInformation;
}

const EditModal = ({ isOpen, rhProfile, onClose }: IEditModal) => {
  const [profile, setProfile] = useState(rhProfile);
  const handleSubmit = () => {
    updateRhProfile(profile).then((response) => response.status === StatusCodes.OK && onClose());
  };

  return (
    <CustomModal
      open={isOpen}
      setOpen={() => onClose()}
      showExitIcon={true}
      renderHeader={() => <>Modifier mes informations de profil</>}
      renderFooter={() => <KerijButton label="Valider" onClick={() => handleSubmit()} />}
      renderContent={() => (
        <Form>
          <InputLabel>Nom</InputLabel>
          <Input
            name={'lastname'}
            value={profile.lastname}
            onChange={(event) =>
              setProfile((profile) => ({
                ...profile,
                lastname: event.target.value,
              }))
            }
          />
          <InputLabel>Prénom</InputLabel>
          <Input
            name={'firstname'}
            value={profile.firstname}
            onChange={(event) =>
              setProfile((profile) => ({
                ...profile,
                firstname: event.target.value,
              }))
            }
          />
        </Form>
      )}
    ></CustomModal>
  );
};

export const EditProfilPage: React.FC = () => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenRoleModal, setIsOpenRoleModal] = useState(false);

  const [myProfile, setMyProfile] = useState<RHLoginInterface | null>(null);
  const [changePasswordVisible, setChangePasswordVisible] = useState<boolean>(false);
  const [role, setRole] = useState<RHRole | undefined>(undefined);

  useEffect(() => {
    if (!myProfile) return;

    fetchRhRoleById(myProfile.rh_information.rh_secures?.[0].id_role)
      .then((response) => {
        setRole(response.data);
      })
      .catch((error) => {
        console.error("Une erreur s'est produite :", error);
      });
  }, [myProfile]);

  const fetchMyProfile = async () => {
    const profileResponse = await fetchRhProfile();
    setMyProfile(profileResponse.data?.rh);
  };

  useEffect(() => {
    fetchMyProfile();
  }, []);

  if (!myProfile) return <KerijLoading type={KerijLoadingType.VALIDATION} />;
  const {
    email,
    rh_information: {
      firstname,
      lastname,
      business: { name: businessName },
    },
    rh_information,
  } = myProfile;
  return (
    <RHPage title="Mon Profil">
      <div className="row col-12">
        <div className="col-12">
          <div className="container-fluid ">
            <div className="row col-12 col-lg-8 shadow p-5">
              <div className="col-10 h4 mb-5 nom-validation">
                {firstname} {lastname?.toUpperCase()}
                <br />
              </div>
              <div className="col-2">
                <img alt="kerij" src={logo} width="50" />
              </div>
              <div className="text-muted">SOCIETE</div>
              <hr className="hr-kerij col-12" />

              <div className="items-validation row col-12 mb-3">
                <div className="data-validation col-12 row">
                  <div>{businessName}</div>
                </div>
              </div>

              <div className="text-muted mt-3">COMPTE</div>
              <hr className="hr-kerij col-12" />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div className="title-validation">Email</div>
                <div className="data-validation">{email}</div>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div className="title-validation">Mot de passe</div>
                <ActionButton label={'Modifier Password'} onClick={() => setChangePasswordVisible(true)} />
              </Box>
              <div className="text-muted">ROLE</div>
              <hr className="hr-kerij col-12" />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div className={'title-validation'}>{role?.role_name}</div>
                <ActionButton label={'Autorisations'} onClick={() => setIsOpenRoleModal(true)} />
              </Box>
            </div>

            <EditModal
              isOpen={isOpenEditModal}
              rhProfile={rh_information}
              onClose={async () => {
                await fetchMyProfile();
                setIsOpenEditModal(false);
              }}
            />
            <ChangePasswordModal isVisible={changePasswordVisible} onClose={() => setChangePasswordVisible(false)} />
            <CustomModal
              open={isOpenRoleModal}
              onClose={() => {
                setIsOpenRoleModal(false);
              }}
              renderHeader={() => {
                return <></>;
              }}
              renderContent={() => (
                <div className="container-fluid col-12">
                  <Create
                    onRoleCreated={(role: RHRole) => {
                      setIsOpenRoleModal(false);
                    }}
                    role={role}
                    readonly={true}
                  />
                </div>
              )}
            />
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            {/*<KerijButton*/}
            {/*    label={'Change Password'}*/}
            {/*    onClick={() => setChangePasswordVisible(true)}*/}
            {/*/>*/}
            {/*<KerijButton*/}
            {/*    label={'Modifier'}*/}
            {/*    onClick={() => setIsOpenEditModal(true)}*/}
            {/*/>*/}
          </div>
        </div>
      </div>
    </RHPage>
  );
};
