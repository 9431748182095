import { Box } from '@mui/material';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

interface IOptionContentProps {
  setModalIsOpenNotePromo?: (value: boolean) => void;
  noteAbsenceOptions?: any;
  setModalIsOpenNoteReduction?: (value: boolean) => void;
}

const OptionContent = ({
  setModalIsOpenNotePromo,
  noteAbsenceOptions,
  setModalIsOpenNoteReduction,
}: IOptionContentProps) => {
  return (
    <Box className="mt-4 col-10">
      <Box>
        <h4 className="text-start mb-3">Options des congés</h4>
        <Box
          className="mb-3"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onClick={() => setModalIsOpenNotePromo && setModalIsOpenNotePromo(true)}
        >
          <FontAwesomeIcon className="fa-1x modal-info" style={{ marginRight: 10 }} icon={faCircleInfo} />
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <span>
              <u>Nombre de semaine supplémentaire</u>
              &nbsp;&nbsp;&nbsp;
              <em
                style={{ cursor: 'pointer' }}
                onClick={() => setModalIsOpenNotePromo && setModalIsOpenNotePromo(true)}
              >
                Modifier
              </em>
            </span>
            <span>
              <em>
                Créez une note explicative max 250 caractères pour valoriser les avantages consentis par votre
                entreprise
              </em>
            </span>
          </Box>
        </Box>
        <Box className="mb-3 border">{noteAbsenceOptions.notePromo}</Box>
        <Box
          className="mb-3"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon className="fa-1x modal-info mr-3" style={{ marginRight: 10 }} icon={faCircleInfo} />
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <span>
              <u>Aménagement temps de travail</u> &nbsp;&nbsp;&nbsp;
              <em
                style={{ cursor: 'pointer' }}
                onClick={() => setModalIsOpenNoteReduction && setModalIsOpenNoteReduction(true)}
              >
                Modifier
              </em>
            </span>
            <span>
              <em>
                Créez une note explicative max 250 caractères pour valoriser les avantages consentis par votre
                entreprise
              </em>
            </span>
          </Box>
        </Box>
        <Box className="mb-3 border">{noteAbsenceOptions.noteReduction}</Box>
      </Box>
    </Box>
  );
};

export default OptionContent;
