import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { KerijBox, KerijBoxType } from '../../base';

export const DocumentUploaderEmpty = () => {
  return (
    <TableRow>
      <TableCell style={{ width: 50 }} colSpan={5}>
        <KerijBox
          message={"Vous n'avez pas encore ajouté de document justifiant cette absence"}
          type={KerijBoxType.Info}
        ></KerijBox>
      </TableCell>
    </TableRow>
  );
};
