import 'dynamsoft-document-viewer/dist/ddv.css';
import React, { ForwardedRef, ChangeEvent } from 'react';

export interface InputFileHiddenProps extends Omit<React.ComponentProps<'input'>, 'onChange'> {
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: Array<File>) => void;
}

export const InputFileHidden = React.forwardRef(function InputFileHidden(
  _: InputFileHiddenProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { onChange, ...otherProps } = _;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allowedTypes = event.target.accept.split(', ');
    const allowedFiles = event.target.files
      ? // eslint-disable-next-line unicorn/prefer-spread
        Array.from(event.target.files).filter((value) => allowedTypes.includes(value.type))
      : [];
    onChange?.(event, [...allowedFiles]);
    // Reset is required, if not we cannot select twice
    event.target.value = '';
  };
  return <input style={{ display: 'none' }} type="file" ref={ref} onChange={handleChange} {...otherProps} />;
});
