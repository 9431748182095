import React, { useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';

const InputPassword = ({ register, label, error }: any) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="input-group">
      <label>Mot de passe</label>
      <div className="label-password">
        <input
          name="password"
          type={showPassword ? 'text' : 'password'}
          className="input-password"
          {...register('password', {
            required: 'Password requis',
          })}
        />
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
          className="icon-show-password"
          onClick={() => setShowPassword(!showPassword)}
        ></FontAwesomeIcon>
      </div>
      {error && <Typography style={{ color: 'red', marginLeft: '10px' }}>{error}</Typography>}
    </div>
  );
};

export default InputPassword;
