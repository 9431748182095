import React from 'react';
import moment from 'moment';
import { Absence } from '@/types/absences.type';
import { FormatDate } from '@/constants/date.constants';

interface QuestionnaireSuiviHeaderProperties {
  absence?: Absence;
}

export function QuestionnaireSuiviHeader({ absence }: QuestionnaireSuiviHeaderProperties) {
  return (
    <>
      <h1 className="question-form text-center mb-5">{`Questionnaire de suivi d'absence`}</h1>
      {absence?.date_end && (
        <h4 className="subtitle-services text-center">
          {` Bonjour, vous avez déclaré une absence jusqu'au`}{' '}
          {absence ? moment(absence.date_end).format(FormatDate.FRENCH_DATE) : ''}. Quel est le statut de cette absence
          ?
        </h4>
      )}
      {!absence?.date_end && absence?.date_end_hospi_theory && (
        <h4 className="subtitle-services text-center">
          {' '}
          Vous avez déclaré une hospitalisation sans durée connue. Nous avons appliqué une date théorique de fin le{' '}
          {absence ? moment(absence.date_end_hospi_theory).format(FormatDate.FRENCH_DATE) : ''}. Quel est le statut de
          cette absence ?
        </h4>
      )}
    </>
  );
}
