export const TypesAbsences = {
  MALADIE: '1',
  MALADIE_1: '2',
  MALADIE_TIERS: '3',
  MALADIE_MATERNITE: '4',
  MATERNITE_PATHO: '5',
  ACCIDENT_TRAVAIL: '8',
  ACCIDENT_TRAJET: '9',
  MALADIE_PRO: '10',
  HOSPITALISATION: '12',
  MATERNITE_1: '15',
  MATERNITE_2: '16',
  MATERNITE_3: '17',
  MATERNITE_4: '18',
  MATERNITE_5: '19',
  PATERNITE: '20',
} as const;

export const TypesAbsencesMaladie = [
  TypesAbsences.MALADIE,
  TypesAbsences.MALADIE_1,
  TypesAbsences.MALADIE_TIERS,
  TypesAbsences.MALADIE_MATERNITE,
] as const;
export const TypesAbsencesAccident = [
  TypesAbsences.MALADIE_PRO,
  TypesAbsences.ACCIDENT_TRAJET,
  TypesAbsences.ACCIDENT_TRAVAIL,
] as const;
export const TypesAbsencesParentalite = [
  TypesAbsences.MATERNITE_1,
  TypesAbsences.MATERNITE_2,
  TypesAbsences.MATERNITE_3,
  TypesAbsences.MATERNITE_4,
  TypesAbsences.MATERNITE_5,
  TypesAbsences.PATERNITE,
] as const;
export const TypesAbsencesHospitalisation = [TypesAbsences.HOSPITALISATION] as const;

export type TypeAbsence = (typeof TypesAbsences)[keyof typeof TypesAbsences];
