import React, { ForwardedRef } from 'react';
import ReactLoading from 'react-loading';
import classNames from 'clsx';
import {
  buttonClasses,
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  styled,
  Typography,
  typographyClasses,
} from '@mui/material';
import { ButtonPrimaryStyle } from './ButtonPrimaryStyle';
import { ButtonSecondaryStyle } from './ButtonSecondaryStyle';
import { ButtonErrorStyle } from './ButtonErrorStyle';
import { ButtonTertiaryStyle } from './ButtonTertiaryStyle';

// Name and options
const componentName = 'KRJButton';

const buttonPropsMap: Record<string, MUIButtonProps> = {
  primary: {
    color: 'secondary', // MUI color
    variant: 'contained',
    sx: ButtonPrimaryStyle, // Custom style
  },
  secondary: {
    color: 'primary', // MUI color
    variant: 'outlined',
    sx: ButtonSecondaryStyle, // Custom style
  },
  tertiary: {
    variant: 'text', // MUI color
    color: 'primary',
    sx: ButtonTertiaryStyle, // Custom style
  },
  error: {
    color: 'error', // MUI color
    variant: 'outlined',
    sx: ButtonErrorStyle, // Custom style
  },
};

export interface ButtonProps extends MUIButtonProps {
  submitting?: boolean;
}

export const Button = React.forwardRef(function Button(props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) {
  const { submitting, children, className, color, ...otherProps } = props;
  // eslint-disable-next-line security/detect-object-injection
  const buttonProps = color == null ? undefined : (buttonPropsMap[color] ?? { color });

  return (
    <CustomButton
      disableTouchRipple
      className={classNames(componentName, `${componentName}--${color}`, className)}
      ref={ref}
      {...buttonPropsMap.tertiary}
      {...buttonProps}
      {...otherProps}
    >
      {submitting ? (
        <ReactLoading className={`${componentName}__Loader`} type="bubbles" color="primary" />
      ) : (
        <Typography fontSize={12} width={'100%'}>
          {children}
        </Typography>
      )}
    </CustomButton>
  );
});

const CustomButton = styled(MUIButton)(({ theme, size }) => ({
  height: size === 'small' ? theme.spacing(4) : size === 'large' ? theme.spacing(6.25) : theme.spacing(5),
  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(-1),
    [`& .${typographyClasses.root}`]: { fontSize: 10 },
  },
  [`& .${buttonClasses.startIcon}`]: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(-1),
  },
  [`& .${typographyClasses.root}`]: {
    fontWeight: 600,
    fontSize: size === 'large' ? theme.spacing(2) : theme.spacing(1.5),
  },
}));
