import axios from 'axios';
import React from 'react';
import { useError } from '@/shared/useError';
import { getFullName } from '../../../../shared/utils/getFullName.service';
import { StatusCodes } from 'http-status-codes';

const NegativeInterviewModal = ({ onRequestClose, selectedInterview, onLoadingChange, onUpdate, content }) => {
  const { triggerError } = useError();
  return (
    <div>
      <div className="container-fluid text-center">
        <h3 className="question-form titre-modal mt-3 mb-4">Entretien refusé</h3>
        <div className="card col-12">
          <div className="card-body card-style">
            <div className="row col-12">
              <p className="card-text text-center col-12 contacts-libelle">
                {getFullName(selectedInterview.employee_information)} {content}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <button
          className="btn btn-kerij-bleu btn-modal col-4"
          onClick={() => {
            onLoadingChange(true);
            axios
              .post('/api/rh/finish_notif', {
                idRhNotification: selectedInterview.id,
                idBusiness: selectedInterview.employee_information.id_business,
              })
              .then((value) => {
                if (value.status === StatusCodes.OK) {
                  onLoadingChange(false);
                  onUpdate();
                  onRequestClose();
                }
              })
              .catch((error) => {
                onLoadingChange(false);
                triggerError(error);
              });
          }}
        >
          J’ai pris connaissance et contacté le salarié
        </button>
      </div>
    </div>
  );
};
export default NegativeInterviewModal;
