import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import LoginUI from './components/LoginUI';

export const LoginPage: React.FC = () => {
  return (
    <DefaultLayout>
      <LoginUI />
    </DefaultLayout>
  );
};
