export const WarningColumns = {
  Declaration: 'declaration',
  OCR: 'ocr',
  Edit: 'custom',
};
export type WarningColumns = (typeof WarningColumns)[keyof typeof WarningColumns];

export const WarningColumnsTypes = {
  Button: 'button',
  Date: 'date',
};
export type WarningColumnsTypes = (typeof WarningColumnsTypes)[keyof typeof WarningColumnsTypes];

export const WarningBoolean = {
  True: 'Oui',
  False: 'Non',
};
export type WarningBoolean = (typeof WarningBoolean)[keyof typeof WarningBoolean];

export const toBooleanValue = (bool: boolean) => (bool ? WarningBoolean.True : WarningBoolean.False);
export const toBoolean = (bool?: string) => (bool === WarningBoolean.True ? true : false);

export const TypeAbsence = {
  Prolongation: 'Prolongation',
  Initial: 'Initial',
};
