export interface DocumentState {
  dateDebut?: string;
  dateFin?: string;
  lastDayWork?: string;
  isWorkDay?: boolean;
  period?: any[];
  absenceType?: any;
  absenceType1?: any;
  dateNaissance?: string | null;
  isLinkHospi?: boolean;
  idAbsenceHospi?: string | null;
  nbJoursHospi?: number | null;
  isShownDureeHospi?: boolean | null;
  optionnal?: boolean | null;
  dateEndReduction?: string | null;
  hoursReduction?: string | null;
  dateEndPromo?: string | null;
}

const isValidPeriod = (document: DocumentState) =>
  ['1', '2', '3'].includes(document.absenceType?.value) && document.period != null && document.period.length > 0;

export const getDateInitValue = (document: DocumentState, propertyKey: 'dateDebut' | 'dateFin'): string | undefined => {
  if (document[`${propertyKey}`]) return document[`${propertyKey}`];
  else if (isValidPeriod(document)) return document.period?.at(0)[`${propertyKey}`];
};
