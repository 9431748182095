export * from './DeclarationType/DeclarationTypePage';
export * from './Declaration/DeclarationPage';
export * from './DeclarationType1/DeclarationType1Page';
export * from './DeclarationType2/DeclarationType2Page';
export * from './DeclarationDate/DeclarationDatePage';
export * from './DeclarationContact/DeclarationContactPage';
export * from './DeclarationDocument/DeclarationDocumentPage';
export * from './DeclarationInformation/DeclarationInformationPage';
export * from './DeclarationValidation/DeclarationValidationPage';
export * from './DeclarationProlongation/DeclarationProlongationPage';
export * from './DeclarationMessage/DeclarationMessagePage';
export * from './DeclarationFin/DeclarationFinPage';
export * from './DeclarationQui/DeclarationQuiPage';
export * from './DeclarationDocumentValidation/DeclarationDocumentValidationPage';
