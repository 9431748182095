import axios from '@/lib/axios';
import { EmployeeInformation, RHNotificationParcours } from '@/types';
import { Parcours } from '@/types/Parcours';
import { AxiosResponse } from 'axios';

export const parcoursFinish = async (params: parcoursDelete.Parameters) => {
  return await axios.post(`/api/rh/parcours/finish`, params);
};

export namespace parcoursDelete {
  export interface Parameters {
    parcours_id: Parcours['id'];
    employee_information_id: EmployeeInformation['id'];
    id_rh_notification?: RHNotificationParcours['id'];
  }
  export type Return = AxiosResponse;
}
