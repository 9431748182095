/* eslint-disable unicorn/prefer-module */
import React, { useEffect, useState } from 'react';
import { EmployeeCard } from '@/components/EmployeeCard';
import { Box } from '@mui/material';
import { getCollaborateursRequest, getMoreCollaborateursRequest } from '../../store/action/collaborateurs';
import { EmployeeInformation } from '../../types/user.interface';
import useDebounce from '../../hooks/useDebounce';
import { employeeCsv } from '../../services/employee';
import { StatusCodes } from 'http-status-codes';
import { ActionButton } from '@/components/ActionButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EmployeeSearchField } from '../../components/DashboardRH/shared/components/Form/EmployeeSearchField';
import { useAppDispatch, useAppSelector } from '@/store';
import { ListCard } from '@/components/DashboardRH/ListCard';
import { RHPage } from '@/components/RHPage';
import { Spacer } from '@/components/Spacer';

const handleDownloadExcel = async (searchValue: string) => {
  const response = await employeeCsv(searchValue);
  if (response.status === StatusCodes.OK) {
    const data = response.data;
    const blob = new Blob([data], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = 'data.csv';
    downloadLink.click();
    downloadLink.remove();
  }
};
const Collaborateurs = () => {
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');

  const searchValue = useDebounce(search, 1000);

  useEffect(() => {
    dispatch(getCollaborateursRequest({ searchValue: searchValue }) as any);
  }, [searchValue]);

  const employeeData: EmployeeInformation[] | undefined = useAppSelector(
    (state: any) => state.collaborateurs?.employeesData?.retrieved?.employees,
  );

  const count: number = useAppSelector((state) => state.collaborateurs?.employeesData?.retrieved?.count);

  const dispatch = useAppDispatch();
  const fetchMoreData = () => {
    const newOffset = offset + 10;

    dispatch(
      getMoreCollaborateursRequest(newOffset, searchValue, () => {
        setOffset(newOffset);
      }) as any,
    );
  };

  return (
    <RHPage
      title="Collaborateurs"
      actions={
        <ActionButton
          label="Export Excel"
          onClick={() => {
            handleDownloadExcel(searchValue);
          }}
          endIcon={<FileDownloadIcon />}
        />
      }
    >
      <Box className="col-6 text-start">
        <EmployeeSearchField
          onChange={(searchString: string) => {
            setOffset(0);
            setSearch(searchString);
          }}
        />
        <Spacer />
      </Box>

      <InfiniteScroll
        style={{ overflow: 'visible' }}
        dataLength={employeeData?.length || 0}
        next={fetchMoreData}
        hasMore={(employeeData?.length || 0) < count}
        loader={<h4>Loading...</h4>}
      >
        <MemoRenderEmployeeData employeeData={employeeData} />
      </InfiniteScroll>
    </RHPage>
  );
};

const RenderEmployeeData = ({ employeeData }: any) => {
  return (
    <>
      {employeeData?.map((employee: EmployeeInformation, index: number) => {
        return (
          <ListCard key={index}>
            <EmployeeCard employeeInformation={employee} />
          </ListCard>
        );
      })}
    </>
  );
};

const MemoRenderEmployeeData = React.memo(RenderEmployeeData);

export default Collaborateurs;
