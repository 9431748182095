import axios from '../../../../../lib/axios';
export const GetInterviews = ({
  listSelectBusiness,
  selectedIdBusiness,
  offset,
  limit,
  setListInterviews,
  typeInterview,
}) => {
  if (selectedIdBusiness) {
    axios
      .get('/api/rh/interviews', {
        params: {
          typeInterview: typeInterview,
          idBusinesses: JSON.stringify(listSelectBusiness.filter((val) => val.isChecked).map((val) => val.id)),
          idBusiness: selectedIdBusiness,
          offset: offset,
          limit: limit,
        },
      })
      .then((value) => {
        setListInterviews(
          value.data.interviews.map((value) => {
            return {
              ...value,
              isShownGererNotif: false,
              isShown: false,
            };
          }),
        );
      });
  }
};
