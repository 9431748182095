import React from 'react';
import moment from 'moment';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

export interface AbsenceDateHospitalisationProps {
  date?: string;
  value?: boolean;
  onChange: (value: boolean) => void;
}

export function AbsenceDateHospitalisation(props: AbsenceDateHospitalisationProps) {
  const { date, value, onChange } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexBasis: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div className="mb-4 mt-2" style={{ fontWeight: '800' }}>
        Votre arrêt est-il en lien avec votre hospitalisation du {moment(date).format('DD/MM/YYYY')}?
      </div>
      <div
        style={{
          display: 'flex',
          flexBasis: 'row',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexBasis: 'row',
            alignItems: 'center',
            marginRight: 30,
          }}
          onClick={() => onChange(true)}
        >
          <FontAwesomeIcon
            icon={value ? faCircleCheck : faCircle}
            style={{ color: '#40C9C9', marginRight: 5 }}
          ></FontAwesomeIcon>
          <div style={{ fontWeight: '800' }}>Oui</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexBasis: 'row',
            alignItems: 'center',
          }}
          onClick={() => onChange(false)}
        >
          <FontAwesomeIcon
            icon={value ? faCircle : faCircleCheck}
            style={{ color: '#40C9C9', marginRight: 5 }}
          ></FontAwesomeIcon>
          <div style={{ fontWeight: '800' }}>Non</div>
        </div>
      </div>
    </div>
  );
}
