import React from 'react';
import { Link } from 'react-router-dom';
import './Services.css';
import ImgServices from '../../../../../assets/img-declaration-services.svg';

function ServicesLoginUI() {
  return (
    <div>
      <div className="container-fluid mt-5">
        <div className="col-12 col-lg-10 px-3 text-center">
          <h1 className="question-form mb-5">Vous aider</h1>

          <img alt="kerij" className="mb-5" src={ImgServices} width={200} />

          <h2>Nous vous facilitons l’accès aux services de votre employeur.</h2>

          <div>
            Nous vous accompagnons dans le suivi des étapes de votre arrêt, de votre déclaration au retour au poste.
          </div>

          <div className="position-relative col-12 text-center mt-5 mb-3">
            <Link className="btn-link" to="/login" state={{ page: '/services' }}>
              <button type="button" className="btn btn-kerij-bleu col-6 col-lg-3">
                Se connecter
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesLoginUI;
