const PUBLIC_API_ROUTES = {
  EMPLOYEE_VERIFY_CONTACT: `/api/public/employee/verify_contact`,
  EMPLOYEE_VERIFY_EMPLOYEE: '/api/public/employee/verify_employee',
  EMPLOYEE_UPDATE_PASSWORD: '/api/public/employee/update_password',
};
const PROTECTED_API_ROUTES = {
  EMPLOYEE_GET_ALL_ABSENCES: '/api/protected/employee/absences/all_absences',
};
export const API_ROUTES = {
  ...PUBLIC_API_ROUTES,
  ...PROTECTED_API_ROUTES,
};
