import { Notification } from '@/types';
import { type PayloadAction, createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '../store';
import { employeeCheckNotif, employeeViewNotif } from '@/services/employee';
import { notifications } from '@/constants';

export interface State {
  loading: boolean;
  list: Notification[];
  total: number;
}

const initialState: State = {
  loading: true,
  list: [],
  total: 0,
};

export const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    append: (state, action: PayloadAction<State['list']>) => {
      state.list = [...state.list, ...action.payload];
    },
    setLoading: (state, action: PayloadAction<State['loading']>) => {
      state.loading = action.payload;
    },
    setTotal: (state, action: PayloadAction<State['total']>) => {
      state.total = action.payload;
    },
    setChecked: (state, action: PayloadAction<{ id: Notification['id']; is_checked: boolean }>) => {
      const notification = state.list.find((_) => _.id === action.payload.id);
      if (notification) {
        notification.is_checked = action.payload.is_checked;
      }
    },
  },
});

export const getSelf = (state: { notification: State }) => state[slice.name];

export const isLoading = createSelector([getSelf], (self) => self.loading);
export const getTotal = createSelector([getSelf], (self) => self.total);
export const getList = createSelector([getSelf], (self) => self.list);
export const indexById = createSelector([getSelf], (self) => Object.fromEntries(self.list.map((_) => [_.id, _])));

export const hasUnread = createSelector([getList], (list) => list.some((notification) => !notification.is_checked));

/**
 * Refresh Notification total counter
 */
export const load = createAsyncThunk(`${slice.name}/load`, async (_, thunkAPI) => {
  const state = thunkAPI.getState() as AppState;
  thunkAPI.dispatch(actions.setLoading(true));
  try {
    const response = await employeeViewNotif({
      limit: 10,
      offset: getList(state).length,
    });

    const newNotifications = response.data.notifications?.rows || [];

    thunkAPI.dispatch(actions.setTotal(response.data.notifications?.count ?? 0));
    thunkAPI.dispatch(actions.append(newNotifications));
  } finally {
    thunkAPI.dispatch(actions.setLoading(false));
  }
});

export const markAsRead = createAsyncThunk(`${slice.name}/markAsRead`, async ({ id }: { id: string }, thunkAPI) => {
  const state = thunkAPI.getState() as AppState;
  const notification = indexById(state)[id];
  if (notification) {
    if (notification.is_checked) {
      return; // no nothing
    } else {
      try {
        thunkAPI.dispatch(
          actions.setChecked({
            id: notification.id,
            is_checked: true,
          }),
        );
        employeeCheckNotif({
          idNotification: notification.id,
        });
      } catch (error) {
        thunkAPI.dispatch(
          actions.setChecked({
            id: notification.id,
            is_checked: false,
          }),
        );
        throw error;
      }
    }
  }
});

export const { actions } = slice;
