import { SET_EMPLOYEE, SET_EMPLOYEE_LIST } from '../action/employee/types';
import { EmployeeInformation } from '../../types/user.interface'; /* eslint-disable sonarjs/no-small-switch */

/* eslint-disable sonarjs/no-small-switch */
const initialState = {
  employeeList: [],
};

interface IAction {
  type: string;
  payload: EmployeeInformation;
}

export function employeeReducer(state: any = initialState, action: IAction) {
  switch (action.type) {
    case SET_EMPLOYEE: {
      return {
        ...state,
        employeeList: state.employeeList?.find(
          (currentEmployee: EmployeeInformation) => currentEmployee?.id === action.payload?.id,
        )
          ? state.employeeList?.map((currentEmployee: EmployeeInformation) => {
              if (currentEmployee.id === action.payload?.id) return action.payload;
              return currentEmployee;
            })
          : [...state.employeeList, action.payload],
      };
    }
    case SET_EMPLOYEE_LIST: {
      return {
        ...state,
        employeeList: state.employeeList?.concat([action.payload]),
      };
    }

    default: {
      return state;
    }
  }
}
