// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-icon {
  text-align: center;
  color: rgb(14, 6, 83, 0.75);
  font-size: 0.75em;
  overflow: auto;
  white-space: nowrap;
}

.services {
  text-align: center;
}

.search-services {
  font-size: 0.75em;
  position: relative;
}

.icon-search-services {
  height: 1.5rem;
  width: 1.5rem;
  /* background-color: red; */
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 15%;
  left: 10%;
  /* transform: translateY(-50%); */
  color: #0e0653;
}
.input-search-services {
  height: 3.5em;
  box-sizing: border-box;
  padding-left: 1.5em;
}
.container {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  margin-top: 10%;
  /* background-color: red; */
}
`, "",{"version":3,"sources":["webpack://./src/pages/employee/ServicesLogin/components/ServicesLoginUI/Services.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,2BAA2B;EAC3B,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,QAAQ;EACR,SAAS;EACT,iCAAiC;EACjC,cAAc;AAChB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,2BAA2B;AAC7B","sourcesContent":[".services-icon {\n  text-align: center;\n  color: rgb(14, 6, 83, 0.75);\n  font-size: 0.75em;\n  overflow: auto;\n  white-space: nowrap;\n}\n\n.services {\n  text-align: center;\n}\n\n.search-services {\n  font-size: 0.75em;\n  position: relative;\n}\n\n.icon-search-services {\n  height: 1.5rem;\n  width: 1.5rem;\n  /* background-color: red; */\n  padding: 4px;\n  position: absolute;\n  box-sizing: border-box;\n  top: 15%;\n  left: 10%;\n  /* transform: translateY(-50%); */\n  color: #0e0653;\n}\n.input-search-services {\n  height: 3.5em;\n  box-sizing: border-box;\n  padding-left: 1.5em;\n}\n.container {\n  display: flex;\n  justify-content: center;\n  height: 100%;\n  align-items: center;\n  margin-top: 10%;\n  /* background-color: red; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
