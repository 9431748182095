import { Absence } from '@/types';
import { toMomentUTCDate } from '@/utils/date.utils';

export interface ItemCloseAbsenceModal {
  value: string;
  label: string;
  action?: string;
  dateSelection?: boolean;
}

type ClosesOptions = object & {
  NORMAL: ItemCloseAbsenceModal;
  ANTICIPE: ItemCloseAbsenceModal;
  PROLONGE: ItemCloseAbsenceModal;
};

export const CloseAbsenceOptions: ClosesOptions = {
  NORMAL: {
    value: '1',
    label: 'Le collaborateur a repris son activité à la date prévue',
  },
  ANTICIPE: {
    value: '2',
    label: 'Le collaborateur est revenu de manière anticipée le',
    dateSelection: true,
    action: 'Ajouter certificat',
  },
  PROLONGE: {
    value: '3',
    label: 'Une nouvelle absence « prolongation » a été prescrite',
  },
};

export const CLOSE_ABSENCE_OPTIONS = Object.values(CloseAbsenceOptions);

export const verifyDateReturn = (absence: Absence, value: string | undefined, now: Date) => {
  if (value == null) return { error: 'Renseignez une date de reprise anticipée' };
  const { date_start, date_end } = absence;

  const date = toMomentUTCDate(value).startOf('day');
  const today = toMomentUTCDate(now).startOf('day');
  const dateStart = toMomentUTCDate(date_start).startOf('day');
  const dateEnd = toMomentUTCDate(date_end).startOf('day');

  if (!date.isSame(today) && date.isAfter(today))
    return { error: "La date doit être inférieure ou égale à aujourd'hui" };
  if (date.isSameOrAfter(dateEnd)) return { error: 'La date doit être inférieure à la date prévue' };
  if (date.isBefore(dateStart)) return { error: 'Renseignez une date supérieure à la date de début' };

  return true;
};
