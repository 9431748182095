import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouterConfig } from '../../../../../config/RouterConfig';
import { useError } from '@/shared/useError';
import { getDataHospi } from '../../../services/get_data_hospi.service';
import { Spacer } from '@/components/Spacer';
import { checkCrossover } from '@/services/rh/business';
import { StatusCodes } from 'http-status-codes';
import { generateCrossoverMessage } from '../../../utils/generateCrossoverMessage';
import { DocumentState, getDateInitValue } from './data';
import { FormatDate } from '@/constants/date.constants';
import { AbsenceDateSubmit } from './AbsenceDateSubmit';
import { AbsenceDateHospitalisation } from './AbsenceDateHospitalisation';
import { AbsenceDateRange } from '@/components/AbsenceDatesRange';
import { AbsenceDateRangeModel, isEmptyDate, isInvalideDate } from '@/components/AbsenceDatesRange/data';

export interface CheckCrossover {
  payload: {
    dateStart: any;
    dateEnd: any;
    idEmployeeInformation?: string;
  };
}

export const AbsenceDate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { error, triggerError, clearError } = useError();
  const document: DocumentState = location.state.document || ({} as DocumentState);

  const [model, setModel] = useState<AbsenceDateRangeModel>({
    dateStart: getDateInitValue(document, 'dateDebut'),
    dateEnd: getDateInitValue(document, 'dateFin'),
    lastDayWork: document.lastDayWork,
  });

  const [isHospiBefore, setIsHospiBefore] = useState<boolean>(false);
  const [dataIsHospi, setDataIsHospi] = useState<any>();
  const [isLinkHospi, setIsLinkHospi] = useState<boolean>(false);

  const [canSubmit, setCanSubmit] = useState(false);
  const disabledSubmit = useMemo(
    () => !canSubmit || error != null || isEmptyDate(model.dateStart) || isEmptyDate(model.dateEnd),
    [canSubmit, model, error],
  );

  const verifyDates = async ({ dateDebut, dateFin }: { dateFin?: string; dateDebut?: string }) => {
    if (isInvalideDate(dateDebut)) return triggerError('Le format de la date de début est incorrect');
    if (isInvalideDate(dateFin)) return triggerError('Le format de la date de fin est invalide');
    if (isEmptyDate(dateDebut) || isEmptyDate(dateFin)) return;

    const sevenDaysAgo = moment().subtract(7, 'days').format(FormatDate.DATE_ONLY);
    const momentDateStart = moment(dateDebut).startOf('day');
    const momentDateEnd = moment(dateFin).startOf('day');
    if (momentDateStart.isBefore(sevenDaysAgo))
      return triggerError(
        `Votre déclaration aurait du être effectuée au plus tard 7 jours après la date de début, veuillez vous rapprocher de votre service Ressources Humaines`,
      );
    if (momentDateEnd.isBefore(momentDateStart))
      return triggerError('La date de fin doit être supérieure ou égale à la date de début');

    const crossover = await checkAbsenceCrossover({
      payload: {
        dateEnd: momentDateEnd.format(FormatDate.DATE_ONLY),
        dateStart: momentDateStart.format(FormatDate.DATE_ONLY),
      },
    });

    if (crossover) return triggerError(crossover);
    else clearError();
  };

  const checkAbsenceCrossover = async ({ payload }: CheckCrossover) => {
    const { dateStart, dateEnd } = payload;
    if (dateStart == null || dateEnd == null) return;
    let error: string | undefined;
    const response = await checkCrossover(payload);
    if (response?.status === StatusCodes.CONFLICT) error = generateCrossoverMessage(response.data);
    else if (response?.status !== StatusCodes.OK) error = 'Une erreur est survenue lors de la vérification';
    return error;
  };

  const handleClick = async () => {
    const documentValue: DocumentState = {
      ...document,
      period: [{ dateDebut: model.dateStart, dateFin: model.dateEnd }],
      dateNaissance: null,
      lastDayWork: model.lastDayWork,
      isLinkHospi: isLinkHospi,
      isWorkDay: document.isWorkDay || false,
      idAbsenceHospi: isLinkHospi === true ? dataIsHospi.id : null,
      dateDebut: model.dateStart,
      dateFin: model.dateEnd,
      nbJoursHospi: null,
      isShownDureeHospi: null,
      optionnal: null,
      dateEndReduction: null,
      hoursReduction: null,
      dateEndPromo: null,
    };
    navigate(RouterConfig.declarationDocumentPage().path, {
      state: { document: documentValue },
    });
  };

  useEffect(() => {
    if (model.dateStart && !isInvalideDate(model.dateStart)) {
      getDataHospi(model.dateStart).then((value) => {
        setIsHospiBefore(value.isHospi);
        setDataIsHospi(value.dataHospi);
      });
    }
  }, [model.dateStart]);

  return (
    <div className="container-fluid">
      <h3 className="KerijHeader mt-5 mb-5 text-center">Quelle est la durée de votre arrêt de travail ?</h3>

      <form onSubmit={handleClick} style={{ padding: 0 }}>
        <AbsenceDateRange
          error={error}
          value={model}
          onChange={setModel}
          onChangeSubmitStatus={setCanSubmit}
          callback={(model) => verifyDates({ dateDebut: model.dateStart, dateFin: model.dateEnd })}
          additionalFields={
            <>
              {model.dateStart && isHospiBefore && dataIsHospi && (
                <AbsenceDateHospitalisation
                  date={dataIsHospi.date_start}
                  value={isLinkHospi}
                  onChange={setIsLinkHospi}
                />
              )}
            </>
          }
        />
        <Spacer size="medium" />
        <AbsenceDateSubmit disabled={disabledSubmit} document={document} />
      </form>
    </div>
  );
};
