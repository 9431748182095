/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
// Fork of https://github.com/mui/material-ui/blob/next/packages/mui-base/src/useInput/useInput.ts
// Allow to use other type than string as value

import { useControlled, useForkRef, useIsFocusVisible } from '@mui/material';
import {
  type FocusEvent,
  type SyntheticEvent,
  type RefObject,
  useRef,
  useState,
  EventHandler,
  useEffect,
  useCallback,
} from 'react';

/**
 * Generic synthetic event sent by input
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputChangeEvent<El = Element, Ev = Event> extends SyntheticEvent<El, Ev> {}

/**
 * Generic synthetic event handler
 */
export type InputChangeEventHandler<Event extends InputChangeEvent<any, any>, Value> = {
  bivarianceHack(event: Event, value: Value): void;
}['bivarianceHack'];

// type InputElement = Element; // TODO: make this customizable
// type InputSlotElement = Element; // TODO: make this customizable

/**
 * Input props
 */
export interface InputProps<SlotElement, Value> {
  /**
   * The input is in disabled state
   */
  disabled?: boolean;
  /**
   * The input is in readonly state
   */
  readOnly?: boolean;
  /**
   * The input has error
   */
  error?: boolean;
  /**
   * The input value (when controlled mode)
   */
  value?: Value;
  /**
   * The input default value (set once after mounted)
   */
  defaultValue?: Value;
  /**
   * The input value is required
   */
  required?: boolean;
  /**
   * The input value change handler
   *
   * First parameter is the DOM event
   * Second parameter is the input value
   */
  onChange?: InputChangeEventHandler<InputChangeEvent<SlotElement>, Value>;
  /**
   * Blur event handler
   */
  onBlur?: EventHandler<FocusEvent<SlotElement>>;
  /**
   * Focus event handler
   */
  onFocus?: EventHandler<FocusEvent<SlotElement>>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseInputParameters<RootElement, SlotElement, Value> {
  /**
   * Component Name (default: Input)
   */
  componentName?: string;
  /**
   * Props coming from component
   */
  props: InputProps<SlotElement, Value>;
  /**
   * The ref attached to the root of the Input.
   */
  ref?: React.Ref<RootElement>;
}

export interface UseInputReturnValue<RootElement, SlotElement, Value> {
  /**
   * The input default value (set once after mounted)
   */
  defaultValue: Value | undefined;
  /**
   * The input current value (from state)
   */
  value: Value | undefined;
  /**
   * The input is in disabled state
   */
  disabled: boolean;
  /**
   * The input is in readonly state
   */
  readOnly: boolean;
  /**
   * The input has error
   */
  error: boolean;
  /**
   * The input is focused
   */
  focusVisible: boolean;
  /**
   * The input has a controlled value
   */
  isControlled: boolean;
  /**
   * The input value is required
   */
  required: boolean;
  /**
   * A ref to the root element
   */
  rootRef: RefObject<RootElement | null>;
  /**
   * A ref to the slot element
   */
  slotRef: RefObject<SlotElement | null>;
  /**
   * The props that can be forwarded to the wrapped input
   */
  useSlotProps<ExternalProps extends Pick<InputProps<SlotElement, Value>, 'onChange' | 'onFocus' | 'onBlur'> = {}>(
    externalProps?: ExternalProps,
  ): InputSlotProps<SlotElement, ExternalProps, Value>;
  /**
   * The props that can be forwarded to the root element
   */
  useRootProps<ExternalProps extends Record<string, any> = {}>(
    externalProps?: ExternalProps,
  ): InputRootProps<RootElement, ExternalProps>;
}

type InputSlotOwnProps<SlotElement, Value> = {
  disabled: boolean;
  readOnly: boolean;
  required: boolean;
  defaultValue: Value | undefined;
  ref: React.RefCallback<SlotElement> | null;
  onChange: InputChangeEventHandler<InputChangeEvent<SlotElement>, Value>;
  onBlur: EventHandler<FocusEvent<SlotElement>>;
  onFocus: EventHandler<FocusEvent<SlotElement>>;
};

export type InputSlotProps<SlotElement, ExternalProps, Value> = Omit<
  ExternalProps,
  keyof InputSlotOwnProps<any, Value>
> &
  InputSlotOwnProps<SlotElement, Value>;

type InputRootOwnProps<RootElement> = {
  ref: React.RefCallback<RootElement> | null;
};
export type InputRootProps<RootElement, ExternalProps> = Omit<
  ExternalProps,
  keyof InputRootOwnProps<any> | keyof InputProps<any, any>
> &
  InputRootOwnProps<RootElement>;

function omitProps<T extends object, K extends string | number | symbol>(props: T, keys: K[]): Omit<T, K> {
  const returnValue = {};
  const keySet = new Set(keys);
  for (const key of Object.keys(props)) {
    // @ts-ignore
    if (!keySet.has(key)) {
      // @ts-ignore
      returnValue[key] = props[key];
    }
  }
  return returnValue as Omit<T, K>;
}

const INPUT_PROPS: Array<keyof InputProps<any, any>> = [
  'onChange',
  'onBlur',
  'onFocus',
  'required',
  'defaultValue',
  'readOnly',
  'disabled',
  'value',
  'error',
];

/**
 * @example
 * type InputValue = { foo: boolean };
 * interface MyCustomInputProps extends InputProps<InputValue> {}
 *
 * function MyCustomInput(props: MyCustomInputProps) {
 *   const input = useInput({ componentName: 'MyCustomInput', props });
 *   const rootProps = input.useRootProps(props); // props without { onChange, onBlur, ... }
 *   const inputSlotProps = input.useSlotProps();// { onChange, ... }
 *
 *   return (<div {...rootProps}>
 *     <SomeInput {...inputSlotProps} />
 *   </div>)
 * }
 */
export function useInput<RootElement extends Element, SlotElement extends Element, Value>(
  parameters: UseInputParameters<RootElement, SlotElement, Value>,
): UseInputReturnValue<RootElement, SlotElement, Value> {
  const { componentName = 'Input', props, ref } = parameters;
  const {
    disabled = false,
    required = false,
    error = false,
    readOnly = false,
    defaultValue,
    value,
    onBlur,
    onChange,
    onFocus,
    // ...cleanProps
  } = props;
  const [valueState, setValueState] = useControlled({
    controlled: value,
    default: defaultValue,
    name: componentName,
    state: 'value',
  });
  const [focusVisible, setFocusVisible] = useState(false);
  const rootRef = useRef<RootElement | null>(null);
  const slotRef = useRef<SlotElement | null>(null);
  const isControlledRef = useRef(valueState != null);
  const {
    isFocusVisibleRef,
    onBlur: handleBlurVisible,
    onFocus: handleFocusVisible,
    ref: focusVisibleRef,
  } = useIsFocusVisible();
  const handleSlotRef = useForkRef(focusVisibleRef, slotRef);
  const handleRef = useForkRef(ref, rootRef);

  if (disabled && focusVisible) {
    setFocusVisible(false);
  }

  useEffect(() => {
    isFocusVisibleRef.current = focusVisible;
  }, [focusVisible, isFocusVisibleRef]);

  return {
    defaultValue,
    value: valueState,
    isControlled: isControlledRef.current,
    disabled,
    readOnly,
    error,
    focusVisible,
    required,
    rootRef,
    slotRef,
    useSlotProps<ExternalProps extends Pick<InputProps<any, Value>, 'onChange' | 'onFocus' | 'onBlur'> = {}>(
      externalProps: ExternalProps = {} as ExternalProps,
    ): InputSlotProps<SlotElement, ExternalProps, Value> {
      return {
        disabled,
        readOnly,
        required,
        defaultValue,
        value, // Controlled like parent component
        ...externalProps,
        ref: handleSlotRef,
        onChange: useCallback(
          (event, value) => {
            // if (!isControlledRef.current) {
            //   const element = event.target || slotRef.current;
            //   if (element == null) {
            //     throw new Error(
            //       'Expected valid input target. ' +
            //         'Did you use a custom `slots.input` and forget to forward refs? ' +
            //         'See https://mui.com/r/input-component-ref-interface for more info.',
            //     );
            //   }
            // }
            if (event.nativeEvent.defaultPrevented) {
              return;
            }
            setValueState(value);
            onChange?.(event, value);
            externalProps.onChange?.(event, value);
          },
          [onChange, externalProps.onChange],
        ),
        onFocus: useCallback(
          (event) => {
            // Fix for https://github.com/facebook/react/issues/7769
            if (!slotRef.current) {
              slotRef.current = event.currentTarget;
            }

            handleFocusVisible(event);
            if (isFocusVisibleRef.current === true) {
              setFocusVisible(true);
              // onFocusVisible?.(event);
            }

            onFocus?.(event);
            externalProps.onFocus?.(event);
          },
          [onFocus, externalProps.onFocus],
        ),
        onBlur: useCallback(
          (event) => {
            handleBlurVisible(event);

            if (isFocusVisibleRef.current === false) {
              setFocusVisible(false);
            }

            onBlur?.(event);
            externalProps.onBlur?.(event);
          },
          [onBlur, externalProps.onBlur],
        ),
      };
    },
    useRootProps<ExternalProps extends Record<string, unknown> = {}>(
      externalProps: ExternalProps = {} as ExternalProps,
    ): InputRootProps<RootElement, ExternalProps> {
      return {
        // ...cleanProps,
        ...(omitProps(externalProps, INPUT_PROPS) as any),
        ref: handleRef,
      };
    },
  };
}
