/* eslint-disable xss/no-mixed-html */
/* eslint-disable unicorn/no-array-callback-reference */
/* eslint-disable sonarjs/no-small-switch */
import { htmlToSlate, htmlToSlateConfig, slateToHtml, type HtmlToSlateConfig } from '@slate-serializers/html';
import type { SlateToDomConfig } from '@slate-serializers/dom';
import type { Descendant as SlateDescendant, Node as SlateNode } from 'slate';
import { getAttributeValue } from 'domutils';
import { Element } from 'domhandler';

const toSlateConfig: HtmlToSlateConfig = {
  ...htmlToSlateConfig,
  elementTags: {
    ...htmlToSlateConfig.elementTags,
    blockquote: () => ({
      type: 'block-quote',
    }),
    h1: () => ({
      type: 'heading-1',
    }),
    h2: () => ({
      type: 'heading-2',
    }),
    h3: () => ({
      type: 'heading-3',
    }),
    li: () => ({
      type: 'list-item',
    }),
    ol: () => ({
      type: 'numbered-list',
    }),
    ul: () => ({
      type: 'bulleted-list',
    }),
    p: () => ({
      type: 'paragraph',
    }),
    a: (el) => ({
      type: 'link',
      url: el && getAttributeValue(el as any, 'href'),
      newTab: el && getAttributeValue(el as any, 'target') === '_blank',
    }),
  },
  textTags: {
    ...htmlToSlateConfig.textTags,
    code: () => ({ code: true }),
    pre: () => ({ code: true }),
    del: () => ({ strikethrough: true }),
    em: () => ({ italic: true }),
    i: () => ({ italic: true }),
    s: () => ({ strikethrough: true }),
    strong: () => ({ bold: true }),
    u: () => ({ underline: true }),
  },
};
const toSlate = (htmlContent: string): SlateDescendant[] =>
  htmlToSlate(htmlContent, toSlateConfig) as SlateDescendant[];

const toHTMLConfig: SlateToDomConfig = {
  markMap: {
    strikethrough: ['s'],
    bold: ['strong'],
    underline: ['u'],
    italic: ['i'],
    code: ['pre', 'code'],
  },
  elementMap: {
    ['block-quote']: 'blockquote',
    ['heading-1']: 'h1',
    ['heading-2']: 'h2',
    ['heading-3']: 'h3',
    ['list-item']: 'li',
    ['numbered-list']: 'ol',
    ['bulleted-list']: 'ul',
    paragraph: 'p',
    link: 'a',
  },
  elementTransforms: {
    quote: ({ children = [] }) => {
      const p = [new Element('p', {}, children)];
      return new Element('blockquote', {}, p);
    },
    // @ts-ignore
    link: ({ node, children = [] }) => {
      const attrs: any = {};
      if (node.newTab) {
        attrs.target = '_blank';
      }
      return new Element(
        'a',
        {
          href: node.url,
          ...attrs,
        },
        children,
      );
    },
  } as any,
  // elementAttributeTransform: ({ node }) => {
  //   const elementStyleMap: { [key: string]: string } = {
  //     align: 'textAlign',
  //   }
  //   const attribs = styleMapToAttribs({elementStyleMap, node})
  //   return isEmptyObject(attribs) ? {} : attribs
  // },
  encodeEntities: true,
};
const toHTML = (value: SlateNode[]): string => {
  return slateToHtml(value, toHTMLConfig);
};

export function useHTMLSerializer() {
  return {
    parse: toSlate,
    stringify: toHTML,
  };
}
