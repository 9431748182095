import React from 'react';
import { defaultStyles } from './baseModalRH';
import ReactLoading from 'react-loading';
import Modal from 'react-modal';

export const LoadingModal = (isLoading) => {
  return (
    <Modal
      style={defaultStyles}
      className="Modal"
      overlayClassName="Overlay"
      closeTimeoutMS={200}
      isOpen={isLoading}
      ariaHideApp={false}
    >
      <div className="container-fluid container-loading">
        <h2>Veuillez patienter</h2>
        <div className="container-load">
          <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
        </div>
      </div>
    </Modal>
  );
};
