import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { colors } from '../../../../../../constants/theme.constants';
import { Absence, DocumentStatus, TypeAbsenceDescription } from '../../../../../../types/absences.type';
import moment from 'moment';
import { FormatDate } from '@/constants/date.constants';
import { ADPExportStatus } from '@/types/ADPExportStatus';
import { Info } from '@mui/icons-material';

interface LineContentInterface {
  label: React.ReactNode;
  value: string;
}

const LineContent = ({ label, value }: LineContentInterface) => (
  <Typography
    style={{
      color: colors.primary,
    }}
  >
    <span style={{ fontWeight: 'bold' }}>{label}: </span>
    {value}
  </Typography>
);

interface AbsenceDetailsInterface {
  absence: Absence;
}

export const AbsenceDetails = ({ absence }: AbsenceDetailsInterface) => {
  const approvedDocument = absence?.documents?.find((doc) => doc.status === DocumentStatus.APPROVED);

  function displaySortieAutorisee() {
    if (!approvedDocument || approvedDocument.ocr_result?.data?.authorized_exit?.value?.detect == null) return '-';
    return approvedDocument.ocr_result.data.authorized_exit.value?.detect ? 'Oui' : 'Non';
  }

  function displayDateExport() {
    if (absence.absence_exports == null) return '-';
    return moment(absence.absence_exports.time).format(FormatDate.FRENCH_DATE);
  }

  const adpLog = useMemo(
    () => absence?.absence_logs?.filter((log) => log.triggered_by === 'adp' && log.description) ?? [],
    [absence?.absence_logs],
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <LineContent label={'Type d’arrêt'} value={absence?.id_absence ? 'Prolongation' : 'Initial'} />
          <LineContent
            label={'En lien avec un accident impliquant un tiers'}
            value={
              absence?.type_absence.description_2_label === TypeAbsenceDescription.ACCIDENT_IMPLIQUANT_TIERS
                ? 'Oui'
                : 'Non'
            }
          />
          <LineContent
            label={'En lien avec un(e) ATMP'}
            value={
              Object.values(TypeAbsenceDescription.ATMP).includes(absence?.type_absence.description_2_label)
                ? 'Oui'
                : 'Non'
            }
          />
        </Box>
        <Box>
          {approvedDocument?.type === 'arret de travail' && (
            <LineContent label={'Sorties autorisées'} value={displaySortieAutorisee()} />
          )}

          {absence.absence_exports != null && (
            <LineContent
              label={absence.export_status === ADPExportStatus.Integrated ? 'Exportée et intégrée le ' : 'Exportée le '}
              value={displayDateExport()}
            />
          )}
        </Box>
      </Box>

      {adpLog.map((log) => (
        <>
          <Box mt={1}>
            {log.description && (
              <LineContent
                label={
                  <span style={{ fontWeight: 'bold' }}>
                    <Info color="error" sx={{ mr: 1 }} />
                    Erreur d'intégration dans le logiciel des temps
                  </span>
                }
                value={log.description}
              />
            )}
          </Box>
        </>
      ))}
    </Box>
  );
};
