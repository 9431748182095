import React, { useEffect, useState } from 'react';
import { useError } from '@/shared/useError';
import './CreationAccount.modal.scss';
import { KerijBox, KerijBoxType } from '../../../../../components/base';
import validator from 'validator';
import { useDebouncedCallback } from 'use-debounce';
import CustomModal from '../../../../../components/base/CustomModal';
import { createEmployeeAccount, verifyEmployeeEmail, verifyEmployeePhone } from '../../../../../services/rh/employees';
import { EmployeeInformation } from '../../../../../types/user.interface';
import KerijButton from '../../../../../components/base/button/kerijButton';
import { StatusCodes } from 'http-status-codes';
import { setEmployee } from '../../../../../store/action/employee';
import { useForm, Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import { useAppDispatch } from '@/store';
import { useToast } from '@/components/Toast';
interface CreationAccountProperties {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  employeeInformation: EmployeeInformation;
  onEmployeeCreated: (employee: EmployeeInformation) => void;
}

export const CreationAccountModal = ({
  isOpenModal,
  handleCloseModal,
  employeeInformation,
  onEmployeeCreated,
}: CreationAccountProperties) => {
  const {
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    reset,
  } = useForm();

  const toast = useToast();
  const [hasContact, setHasContact] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // if (
    //     employeeInformation?.employee_login?.email &&
    //     employeeInformation?.employee_login?.phone
    // ) {
    //     reset({
    //         email: employeeInformation?.employee_login?.email,
    //         phone: employeeInformation?.employee_login?.phone,
    //     });
    // }
    setHasContact(!!(employeeInformation?.employee_login?.email && employeeInformation?.employee_login?.phone));
    return () => reset();
  }, [employeeInformation, isOpenModal]);

  const handleNoEmail = () => {
    setHasContact(false);
  };
  const handleHasEmail = () => {
    setHasContact(true);
  };

  const createEmployee = async (data: any) => {
    if (!hasContact) {
      handleCloseModal?.();
      return;
    }
    await createEmployeeAccount({
      email: data?.email,
      phone: data?.phone,
      idEmployeeInformation: employeeInformation.id,
      idBusiness: employeeInformation?.business.id,
    }).then((response) => {
      if (response.status === StatusCodes.OK) {
        handleCloseModal?.();
        onEmployeeCreated?.(response.data);
        dispatch(setEmployee(response.data));
        toast.present({ message: "Les identifiants d'accès ont été transmis à l'utilisateur", severity: 'success' });
      }
    });
  };

  return (
    <CustomModal
      open={isOpenModal}
      onClose={handleCloseModal}
      renderHeader={() => <>Compte employé</>}
      renderFooter={() => <KerijButton label={'Valider'} onClick={handleSubmit(createEmployee)} />}
      renderContent={() => (
        <div className="mb-5">
          <h5>
            Le salarié n’a pas encore créé de compte sur KERij. En créant cette absence, vous allez lui en générer un
            automatiquement. Afin de l’en informer et de l’aider à activer son compte, nous avons besoin de connaître
            une adresse mail et un numéro de téléphone mobile auxquels il peut accéder pendant son absence.
          </h5>
          <div className="creation-account__container">
            <label className="creation-account__label">
              <input
                className="creation-account__input-radio my-2 align-items-center"
                value={(!hasContact)?.toString()}
                checked={!hasContact}
                onChange={handleNoEmail}
                name="radio"
                type="radio"
              />
              <span className="creation-account__span">Je ne connais pas son mail et son numéro de téléphone</span>
            </label>
            <label className="creation-account__label">
              <input
                className="creation-account__input-radio my-2 align-items-center mr-2"
                value={hasContact?.toString()}
                checked={hasContact}
                onChange={handleHasEmail}
                name="radio"
                type="radio"
              />
              <span className="creation-account__span">Je connais son mail et son numéro de téléphone</span>
            </label>
            {hasContact && (
              <>
                <Controller
                  render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                    <>
                      <input
                        className="form-control"
                        placeholder="Email du salarié"
                        value={value}
                        onChange={onChange}
                        {...rest}
                      />
                      {error && (
                        <Typography
                          style={{
                            color: 'red',
                            marginLeft: '10px',
                          }}
                        >
                          {error.message}
                        </Typography>
                      )}
                    </>
                  )}
                  name={'email'}
                  control={control}
                  rules={{
                    required: 'Email requis',
                    pattern: {
                      value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i,
                      message: `Format d'email invalide`,
                    },
                    validate: async () => {
                      const email = getValues('email');
                      const validated = await verifyEmployeeEmail(email);
                      if (validated?.data?.isExisted) {
                        return 'Cette adresse email a été utilisée. Veuillez entrer une autre adresse email';
                      }
                      return true;
                    },
                  }}
                />
                <Controller
                  render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                    <>
                      <input
                        className="form-control"
                        placeholder="Numéro de téléphone du salarié"
                        onChange={onChange}
                        value={value}
                        {...rest}
                      />
                      {error && (
                        <Typography
                          style={{
                            color: 'red',
                            marginLeft: '10px',
                          }}
                        >
                          {error.message}
                        </Typography>
                      )}
                    </>
                  )}
                  name={'phone'}
                  control={control}
                  rules={{
                    required: 'Phone requis',
                    pattern: {
                      value: /^(\+\d{1,3}[ -]?)?\d{10}$/,
                      message: `Format de téléphone invalide`,
                    },
                  }}
                />
              </>
            )}
          </div>
        </div>
      )}
    />
  );
};
