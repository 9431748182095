import * as React from 'react';
import classNames from 'clsx';
import { Parcours } from '@/types/Parcours';
import { Box } from '@mui/material';
import { ParcoursListItem } from './ParcoursListItem';
import { ListCard } from '../DashboardRH/ListCard';

const componentName = 'ParcoursList';

export interface ParcoursListProps extends React.ComponentProps<typeof Box> {
  items?: Array<Parcours>;
  onRefresh?: () => void;
}

export function ParcoursList(props: ParcoursListProps) {
  const { className, items, onRefresh, ...boxProps } = props;

  return items == null ? (
    <></>
  ) : (
    <Box className={classNames(componentName, className)} {...boxProps}>
      {items.map((item) => (
        <ListCard key={item.id}>
          <ParcoursListItem value={item} onChange={onRefresh} />
        </ListCard>
      ))}
    </Box>
  );
}
