import React, { useState } from 'react';
import { Absence } from '@/types';
import { updateAbsence } from '@/services/rh/absences/service';
import { getNatureBody } from './EditAbsencesData';
import { EditAbsencesSteps } from './EditAbsencesSteps';
import { PermissionBox } from '@/components/PermissionBox';
import { useToast } from '@/components/Toast';
import { RHPage } from '@/components/RHPage';

export const EditAbsencesPage = () => {
  const toast = useToast();

  // States
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  // SUBMIT
  async function submitForm(values: Absence[]) {
    let responses: Promise<any>[] = [];
    values.forEach((absence) => {
      const body = getNatureBody(absence);
      if (body) {
        responses = [
          ...responses,
          updateAbsence({
            params: { idAbsence: absence.id },
            body,
          }),
        ];
      }
    });
    try {
      await Promise.all(responses);
      toast.present({ message: `${responses.length} absence(s) modifiée(s)`, severity: 'success' });
    } catch {
      toast.present({ message: `Une erreur est survenue à la modification`, severity: 'error' });
    }

    setIsSubmit(true);
  }

  return (
    <PermissionBox scope="absence-management" action="edit">
      <RHPage title="Modifier une absence">
        <EditAbsencesSteps
          isSubmit={isSubmit}
          onSubmit={(event) => submitForm(event)}
          onReset={() => setIsSubmit(false)}
        />
      </RHPage>
    </PermissionBox>
  );
};
