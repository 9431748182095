import React from 'react';
import ImgServiceKerijBlanc from '@/assets/service-logokerij-blanc.jpg';
import { Service, ServiceDocumentType } from '@/types';
import { CardMedia } from '@mui/material';

export interface ServiceCardLogoProps {
  value: Service;
}

export function ServiceCardLogo(props: ServiceCardLogoProps) {
  const { value } = props;

  const serviceLogo =
    value?.service_documents?.find((e) => e.type === ServiceDocumentType.Logo)?.url || ImgServiceKerijBlanc;

  return <CardMedia component="img" sx={{ height: '25vh' }} src={serviceLogo} alt="Kerij" crossOrigin="anonymous" />;
}
