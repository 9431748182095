import { usePermission } from '@/hooks/usePermission';
import * as React from 'react';
import { RHPermissionScope, RHPermissionScopeEntry } from '@/types';

export interface IfPermissionProps {
  scope: RHPermissionScope | 'admin';
  action: keyof RHPermissionScopeEntry;
  children?: React.ReactNode;
  fallback?: React.ReactNode;
}

/**
 * @example
 * <IfPermission scope="some-permission" action="access">
 *   <Button>
 *     Restricted area !
 *   </Button>
 * </IfPermission>
 *
 *
 * @param props
 * @returns
 */
export const IfPermission = (props: IfPermissionProps) => {
  const { hasPermission, isAdmin } = usePermission();
  const { scope, action, children, fallback } = props;
  return (scope === 'admin' ? isAdmin : hasPermission(scope, action)) ? <>{children}</> : <>{fallback ?? null}</>;
};
