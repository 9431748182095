import { type PayloadAction, createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppState } from '../store';
import type { RHNotification } from '../../types/rh.interface';
import * as RHNavbarContext from './rhNavbarContext';
import { fetchRhAction } from '../../services/rh';

export interface State {
  rhNotificationsList: Array<RHNotification>;
  total: number;
}

const initialState: State = {
  rhNotificationsList: [],
  total: 0,
};

export const slice = createSlice({
  name: 'rhNotification',
  initialState,
  reducers: {
    append: (state, action: PayloadAction<RHNotification[]>) => {
      state.rhNotificationsList = _.uniqBy([...state.rhNotificationsList, ...action.payload], 'id');
    },
    update: (state, action: PayloadAction<RHNotification>) => {
      state.rhNotificationsList = state.rhNotificationsList?.find(
        (currentNotifications: RHNotification) => currentNotifications?.id === action.payload?.id,
      )
        ? state.rhNotificationsList?.map((currentNotifications: RHNotification) => {
            if (currentNotifications.id === action.payload?.id) return action.payload;
            return currentNotifications;
          })
        : [...state.rhNotificationsList, action.payload];
    },
    initialize: (state, action: PayloadAction<RHNotification[]>) => {
      state.rhNotificationsList = action.payload;
    },
    reset: (state) => {
      state.rhNotificationsList = [];
    },
    remove: (state, action: PayloadAction<RHNotification>) => {
      state.rhNotificationsList = state.rhNotificationsList.filter(
        (notification: RHNotification) => notification.id !== action.payload.id,
      );
    },
    setTotal: (state, action: PayloadAction<State['total']>) => {
      state.total = action.payload;
    },
  },
});

export const getSelf = (state: { rhNotification: State }) => state[slice.name];

export const getList = createSelector([getSelf], (self) => self.rhNotificationsList);

export const getTotal = createSelector([getSelf], (self) => self.total);

/**
 * Refresh Notification total counter
 */
export const updateTotal = createAsyncThunk(`${slice.name}/updateTotal`, async (_, thunkAPI) => {
  const state = thunkAPI.getState() as AppState;
  const listSelectBusinessId = RHNavbarContext.getBusinessSelectedIdList(state);
  const selectedBusinessId = RHNavbarContext.getSelectedBusinessId(state);

  const response = await fetchRhAction({
    idBusinesses: JSON.stringify(listSelectBusinessId),
    idBusiness: selectedBusinessId ?? undefined,
    limit: 0,
    offset: 0,
  });
  if (response) {
    thunkAPI.dispatch(actions.setTotal(response.data?.length ?? 0));
  }
});

export const { actions } = slice;
