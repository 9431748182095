import { useAppSelector } from './useAppSelector';
import * as Feature from '../slice/feature';
import { Feature as FeatureObject } from '@/types/feature.type';

export type TypedUseFeatureHook<T extends object> = () => T;

/**
 * @example
 * ```ts
 * function MyComponent() {
 *   const someFeatureEnabled = useAppFeature().someFeature;
 * }
 * ```
 * @param featureName
 */
export const useAppFeature: TypedUseFeatureHook<FeatureObject> = () => {
  return useAppSelector(Feature.getAll) as unknown as FeatureObject;
};
