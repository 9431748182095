import React from 'react';

interface FailedVerificationProperties {
  onContinueWithDocument: () => void;
  onChoiceNewDocument: () => void;
}

export const FailedVerification = ({ onContinueWithDocument, onChoiceNewDocument }: FailedVerificationProperties) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <span className="header">Que souhaitez-vous faire ?</span>
      <div className="mt-3 mb-3 line"></div>
      <div className="container-file col-lg-4">
        <button
          type="button"
          className="btn-retour btn btn-kerij-outline btn-sm col-12"
          onClick={onContinueWithDocument}
        >
          Je continue avec ce document
        </button>
      </div>
      <div className="mt-3 mb-3 line"></div>
      <div className="container-file col-lg-4">
        <button
          type="button"
          className="btn-retour btn btn-kerij-outline btn-sm col-12"
          onClick={() => {
            onChoiceNewDocument?.();
          }}
        >
          Je sélectionne un autre document
        </button>
      </div>
    </div>
  );
};
