import moment from 'moment';

interface CrossoverAbsences {
  id: string;
  dateStart: string;
  dateEnd: string;
}

export const generateCrossoverMessage = (crossovers: { details: CrossoverAbsences[] }): string => {
  const absences = crossovers.details;
  const count = absences?.length;
  const periods = absences?.map((absence) => ({
    dateStart: moment(absence.dateStart).format('DD/MM/YYYY'),
    dateEnd: moment(absence.dateEnd).format('DD/MM/YYYY'),
  }));
  const pluralSuffix = count > 1 ? 's' : '';

  return `Vous avez ${count} déclaration${pluralSuffix} d'absence${pluralSuffix} qui ${
    count > 1 ? 'entrent' : 'entre'
  } en conflit avec votre nouvelle déclaration: ${periods
    .map((period) => `du ${period.dateStart} au ${period.dateEnd}`)
    .join(', et ')}.`;
};
