import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import classNames from 'clsx';

const componentName = 'RHPage';

export interface RHPageProps extends React.ComponentProps<typeof Box> {
  title?: string;
  actions?: React.ReactElement;
  variant?: 'form';
}

export function RHPage(props: RHPageProps) {
  const { variant, title, actions, className, children, ...othersProps } = props;

  return (
    <Box className={classNames(componentName, className)} {...othersProps}>
      {title && (
        <>
          <Box sx={{ pl: 2.5, pr: 2.5, pt: 2, pb: 2, display: 'flex', minHeight: 68.5 }}>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
              <Typography color="primary" fontWeight="bold">
                {title}
              </Typography>
            </Box>

            <Box sx={{ flexShrink: 0 }}>{actions}</Box>
          </Box>
          <Divider />
        </>
      )}
      <Box p={2.5} pb={10.5} maxWidth={variant === 'form' ? 590 : undefined}>
        {children}
      </Box>
    </Box>
  );
}
