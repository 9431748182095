import React from 'react';
import { Document, Page } from 'react-pdf';
import { CircleSuccess, CircleError } from '@/components/AppIcon';
import { DocumentBoxType } from '../../../features/declaration/types/DocumentBoxType';
import ViewPdfModal from '../../../components/base/ViewPdfModal';
import { Box, BoxProps, Paper, Typography, styled, useTheme } from '@mui/material';

const componentName = 'DocumentBox';

const renderFile = (file?: File) => {
  const [visibleModal, setVisibleModal] = React.useState(false);
  if (file) {
    const type = file.type;
    const url = URL.createObjectURL(file);
    switch (type) {
      case 'application/pdf': {
        return (
          <>
            <Document file={url}>
              <Page
                onClick={() => setVisibleModal(true)}
                scale={1}
                pageNumber={1}
                renderMode="canvas"
                renderAnnotationLayer={false}
                renderTextLayer={false}
                width={300}
                height={500}
              />
            </Document>
            <ViewPdfModal visibleModal={visibleModal} setVisibleModal={setVisibleModal} title={'Document'} path={url} />
          </>
        );
      }
      default: {
        return <img alt="kerij" src={url} className="img-fluid" style={{ width: 300 }}></img>;
      }
    }
  }
  return null;
};

const useColor = (type: DocumentBoxType) => {
  const theme = useTheme();
  switch (type) {
    case DocumentBoxType.Failed: {
      return theme.palette.error.main;
    }
    case DocumentBoxType.Warning: {
      return theme.palette.warning.main;
    }
    default: {
      return theme.palette.success.main;
    }
  }
};

const useIcon = (type: DocumentBoxType) => {
  switch (type) {
    case DocumentBoxType.Failed: {
      return <CircleError color="error" />;
    }
    case DocumentBoxType.Success: {
      return <CircleSuccess color="success" />;
    }
  }
};

const DocumentBoxRoot = styled(Box)({
  display: 'flex',
  // position: 'relative',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

const DocumentBoxFrame = styled(Paper)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'zoom-in',
  borderRadius: theme.shape.borderRadius,
  padding: theme.shape.borderRadius,
  minWidth: '250px',
  minHeight: '250px',
}));

export interface DocumentBoxProps extends BoxProps {
  file: File | undefined;
  type: DocumentBoxType;
}

export const DocumentBox = (props: DocumentBoxProps) => {
  const { file, type, children, ...otherProps } = props;
  const color = useColor(type);
  const icon = useIcon(type);
  return (
    <DocumentBoxRoot className={componentName} {...otherProps}>
      <DocumentBoxFrame className={`${componentName}__Frame`} variant="outlined" style={{ borderColor: color }}>
        {renderFile(file)}
        <Paper variant="outlined" square={false} sx={{ position: 'absolute', textAlign: 'center', margin: 2 }}>
          {icon}
          {icon ? <br /> : undefined}
          <Typography variant="h6">
            {type === DocumentBoxType.Warning && <span>Votre document sera analysé sous 48h.</span>}
            {type === DocumentBoxType.Success && <span>Votre document est validé.</span>}
          </Typography>
          {children}
        </Paper>
      </DocumentBoxFrame>
    </DocumentBoxRoot>
  );
};
