import axios from '../../../../lib/axios';

interface BodyProps {
  idEmployeeInformation: string;
  idBusiness: string;
}

export const verifyEmployeeAbsenceMat = async (
  { idEmployeeInformation, idBusiness }: BodyProps,
  featureEnabled: boolean,
) => {
  return featureEnabled
    ? await axios
        .post('/api/rh/verify_absence_mat', {
          idEmployeeInformation,
          idBusiness,
        })
        .then((val) => {
          return {
            isValid: val.data.isHaving,
            isHaving: val.data.isHaving,
            dataAbsence: val.data.absence,
          };
        })
    : {
        isValid: true,
        isHaving: false,
        dataAbsence: undefined,
      };
};
