import {
  getRefreshToken,
  getToken,
  getTokenData,
  hasTokenExpired,
  setRedirectPath,
  storeAuthData,
} from '../utils/auth.utils';
import { StatusCodes } from 'http-status-codes';
import { exchangeToken } from '../services/auth';

const refreshToken = async () => {
  // setRedirectPath(window.location.pathname);
  //throw new Error('Failed to refresh the token');
  let token = getToken();
  const tokenData = getTokenData();
  const exp = tokenData?.exp;
  if (!token || !exp) {
    return;
  }

  if (hasTokenExpired(exp)) {
    try {
      const refreshToken = getRefreshToken();
      const response = await exchangeToken({ refreshToken });
      if (response.status === StatusCodes.OK) {
        token = response.data.token;
        storeAuthData({
          userType: response.data?.userType,
          token: response.data?.token,
          exp: response.data?.exp,
          refreshToken: response.data?.refreshToken,
        });
      } else {
        token = null;
        setRedirectPath(window.location.pathname);
        throw new Error('Failed to refresh the token');
      }
    } catch {
      token = null;
      setRedirectPath(window.location.pathname);
      throw new Error('Failed to refresh the token');
    }
  }
  return token;
};
export default refreshToken;
