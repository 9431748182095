import CustomModal from '../../../../base/CustomModal';
import EmployeeCard from '../../../../../features/dashboard-rh/create-absence-rh/components/EmployeeCard';
import React from 'react';
import { EmployeeInformation } from '../../../../../types/user.interface';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@/components/IconButton';

interface EmployeInformationModalProperties {
  employeeInformation: EmployeeInformation;
  visible: boolean;
  onClosed: () => void;
}

export const EmployeeInformationModal = ({
  employeeInformation,
  visible,
  onClosed,
}: EmployeInformationModalProperties) => {
  return (
    <CustomModal open={visible} onClose={onClosed}>
      <EmployeeCard
        idEmployee={employeeInformation?.id}
        EmployeeCardHeaderProps={{
          secondaryAction: <IconButton icon={<CloseIcon sx={{ color: 'white' }} />} onClick={onClosed} />,
        }}
      />
    </CustomModal>
  );
};
