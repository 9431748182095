import { Dispatch } from 'redux';
import * as employeeServices from '../../../services/rh/employees';
import {
  CollaborateursActionTypes,
  ICollaborateursAttemptAction,
  ICollaborateursFailureAction,
  ICollaborateursSuccessAction,
} from './type';
interface ICollaborateursRequest {
  searchValue: string;
}

export const getCollaborateursRequest = ({ searchValue }: ICollaborateursRequest) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(getCollaborateursAttempt(true));
      const response: any = await employeeServices.rhSearchEmployee({
        lastname: searchValue,
        firstname: searchValue,
        description: searchValue,
      });

      const data = {
        employees: response.data?.rows,
        count: response.data?.count,
      };

      dispatch(getCollaborateursSuccess(data));
      dispatch(getCollaborateursAttempt(false));
    } catch (error: any) {
      dispatch(getCollaborateursFailure(error.message));
      dispatch(getCollaborateursAttempt(false));
    }
  };
};

export const getMoreCollaborateursRequest = (offset: number, searchValue: string, onSuccess?: () => void) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(getCollaborateursAttempt(true));
      const response: any = await employeeServices.rhSearchEmployee({
        lastname: searchValue,
        firstname: searchValue,
        description: searchValue,
        offset,
      });
      const data = {
        employees: response.data?.rows,
        count: response.data?.count,
      };
      dispatch(getMoreCollaborateursSuccess(data));
      dispatch(getCollaborateursAttempt(false));
      onSuccess && onSuccess();
    } catch (error: any) {
      dispatch(getCollaborateursFailure(error.message));
      dispatch(getCollaborateursAttempt(false));
    }
  };
};

export const getCollaborateursSuccess = (payload: any): ICollaborateursSuccessAction => {
  return {
    type: CollaborateursActionTypes.LIST_COLLABORATEURS,
    payload,
  };
};

export const getMoreCollaborateursSuccess = (payload: any): ICollaborateursSuccessAction => {
  return {
    type: CollaborateursActionTypes.LIST_COLLABORATEURS_SUCCESS,
    payload,
  };
};

export const getCollaborateursFailure = (error?: any): ICollaborateursFailureAction => {
  return {
    type: CollaborateursActionTypes.LIST_COLLABORATEURS_FAILURE,
    payload: error,
  };
};

export const getCollaborateursAttempt = (loading: boolean): ICollaborateursAttemptAction => {
  return {
    type: CollaborateursActionTypes.LIST_COLLABORATEURS_LOADING,
    payload: loading,
  };
};
