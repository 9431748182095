export enum AbsenceCurrentActionTypes {
  GET_ABSENCE_CURRENT_REQUEST = 'GET_ABSENCE_CURRENT_REQUEST',
  LIST_CURRENT_ABSENCE = 'LIST_CURRENT_ABSENCE',
  LIST_CURRENT_ABSENCE_SUCCESS = 'LIST_CURRENT_ABSENCE_SUCCESS',
  LIST_CURRENT_ABSENCE_FAILURE = 'LIST_CURRENT_ABSENCE_FAILURE',
  LIST_CURRENT_ABSENCE_LOADING = 'LIST_CURRENT_ABSENCE_LOADING',
}

export interface IAbsenceCurrentAction {
  type: AbsenceCurrentActionTypes.GET_ABSENCE_CURRENT_REQUEST;
  payload: any;
}

export interface IAbsenceCurrentSuccessAction {
  type: AbsenceCurrentActionTypes.LIST_CURRENT_ABSENCE | AbsenceCurrentActionTypes.LIST_CURRENT_ABSENCE_SUCCESS;
  payload: any;
}

export interface IAbsenceCurrentFailureAction {
  type: AbsenceCurrentActionTypes.LIST_CURRENT_ABSENCE_FAILURE;
  payload?: any;
}
export interface IAbsenceCurrentAttemptAction {
  type: AbsenceCurrentActionTypes.LIST_CURRENT_ABSENCE_LOADING;
  payload?: boolean;
}

export enum AbsenceHistoryActionTypes {
  GET_ABSENCE_HISTORY_REQUEST = 'GET_ABSENCE_HISTORY_REQUEST',
  LIST_HISTORY_ABSENCE = 'LIST_HISTORY_ABSENCE',
  LIST_HISTORY_ABSENCE_SUCCESS = 'LIST_HISTORY_ABSENCE_SUCCESS',
  LIST_HISTORY_ABSENCE_FAILURE = 'LIST_HISTORY_ABSENCE_FAILURE',
  LIST_HISTORY_ABSENCE_LOADING = 'LIST_HISTORY_ABSENCE_LOADING',
  LIST_HISTORY_ABSENCE_LOADED = 'LIST_HISTORY_ABSENCE_LOADED',
}

export interface IAbsenceHistoryAction {
  type: AbsenceHistoryActionTypes.GET_ABSENCE_HISTORY_REQUEST;
  payload: any;
}

export interface IAbsenceHistorySuccessAction {
  type: AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE | AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE_SUCCESS;
  payload: any;
}

export interface IAbsenceHistoryFailureAction {
  type: AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE_FAILURE;
  payload?: any;
}
export interface IAbsenceHistoryAttemptAction {
  type: AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE_LOADING;
  payload?: boolean;
}
export interface IAbsenceHistoryLoadedAction {
  type: AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE_LOADED;
  payload?: boolean;
}

export type AbsenceAction =
  | IAbsenceCurrentAction
  | IAbsenceCurrentSuccessAction
  | IAbsenceCurrentFailureAction
  | IAbsenceCurrentAttemptAction
  | IAbsenceHistoryAction
  | IAbsenceHistorySuccessAction
  | IAbsenceHistoryFailureAction
  | IAbsenceHistoryAttemptAction
  | IAbsenceHistoryLoadedAction;
