import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import './components/Declaration/Declaration.css';
import './components/Footer/Footer.css';
import './components/Home/Home.css';
import './components/Login/Login.css';
import './components/Navbar/Navbar.css';
import './components/Signup/Signup.css';
import './fonts/Montserrat-Regular.ttf';
import './index.css';
import axios from './lib/axios';
import { Provider } from 'react-redux';
import { isProPathName } from './utils/auth.utils';
import { NavigationProvider } from './components/Context/NavigationProvider';
import { RouterConfig } from './config/RouterConfig';
import { SocketContext } from './contexts/socket.context';
import { SocketClient } from './socket/socket';
import { QueryClient, QueryClientProvider } from 'react-query';
import ThemeComponent from './components/ThemeComponent';
import AccountRhWithReducer from './components/AccountRhWithReducer';
import { store } from './store';
import { ToastProvider } from './components/Toast';

const iconList = Object.keys(Icons)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((icon) => Icons[icon as Exclude<keyof typeof Icons, 'fas' | 'prefix'>]);
library.add(...iconList);

const queryClient = new QueryClient();

function App() {
  const [isProUrl, setIsProUrl] = useState(isProPathName(window.location.pathname));
  const [socketClient, setSocketClient] = useState<SocketClient | null>(null);

  useEffect(() => {
    setIsProUrl(isProPathName(window.location.pathname));
    if (isProUrl && !socketClient) {
      const socket = new SocketClient();
      setSocketClient(socket);
    } else if (!isProUrl && socketClient) {
      socketClient?.disconnect();
      setSocketClient(null);
    }
    return () => {
      socketClient?.disconnect();
      setSocketClient(null);
    };
  }, [window.location.pathname]);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeComponent>
          <ToastProvider>
            <SocketContext.Provider value={socketClient!}>
              <BrowserRouter>
                <NavigationProvider>
                  <AccountRhWithReducer>
                    <div>
                      <Routes>
                        <Route {...RouterConfig.rootPage()} />
                        <Route {...RouterConfig.profilPage()} />
                        <Route {...RouterConfig.profilvalidationPage()} />
                        <Route {...RouterConfig.loginPage()} />
                        <Route {...RouterConfig.modifycontactPage()} />
                        <Route {...RouterConfig.checkCodePage()} />
                        <Route {...RouterConfig.informationPage()} />
                        <Route {...RouterConfig.changepasswordPage()} />
                        <Route {...RouterConfig.signupPage()} />
                        <Route {...RouterConfig.verifyPage()} />
                        <Route {...RouterConfig.verifyselectPage()} />
                        <Route {...RouterConfig.forgotPasswordPage()} />
                        <Route {...RouterConfig.resetPasswordPage()} />
                        <Route {...RouterConfig.createpasswordPage()} />
                        <Route {...RouterConfig.createcontactPage()} />
                        <Route {...RouterConfig.confirmationPage()} />
                        <Route {...RouterConfig.declarationPage()} />
                        <Route {...RouterConfig.declarationquiPage()} />
                        <Route {...RouterConfig.declarationTypePage()} />
                        <Route {...RouterConfig.declarationDocumentPage()} />
                        <Route {...RouterConfig.declarationdocumentvalidationPage()} />
                        <Route {...RouterConfig.declarationType1Page()} />
                        <Route {...RouterConfig.declarationType2Page()} />
                        <Route {...RouterConfig.declarationDatePage()} />
                        <Route {...RouterConfig.declarationInformationPage()} />
                        <Route {...RouterConfig.declarationValidationPage()} />
                        <Route {...RouterConfig.declarationfinPage()} />
                        <Route {...RouterConfig.declarationQuestionsContactPage()} />
                        <Route {...RouterConfig.declarationQuestionsMessagePage()} />
                        <Route {...RouterConfig.declarationProlongationPage()} />
                        <Route {...RouterConfig.choixfinPage()} />
                        <Route {...RouterConfig.servicesPage()} />
                        <Route {...RouterConfig.servicedetailsPage()} />
                        <Route {...RouterConfig.servicesloginPage()} />
                        <Route {...RouterConfig.questionnairesuiviPage()} />
                        <Route {...RouterConfig.questionnairesuivifinPage()} />
                        <Route {...RouterConfig.questionnaireretourPage()} />
                        <Route {...RouterConfig.questionnaireretourfinPage()} />
                        <Route {...RouterConfig.historiquesalariePage()} />
                        <Route {...RouterConfig.depotdocumenthistoriquefinPage()} />
                        <Route {...RouterConfig.questionnaireacceuille1Page()} />
                        <Route {...RouterConfig.questionnaireaccueil2Page()} />
                        <Route {...RouterConfig.activitesPage()} />

                        <Route {...RouterConfig.activitesloginPage()} />
                        <Route {...RouterConfig.rdvPage()} />
                        <Route {...RouterConfig.rdvloginPage()} />

                        <Route {...RouterConfig.entretienliaisonfichePage()} />

                        <Route {...RouterConfig.entretienretourfichePage()} />

                        <Route {...RouterConfig.entretienfichefinPage()} />

                        <Route {...RouterConfig.questionnairevisitefinPage()} />

                        <Route {...RouterConfig.questionnairevisitePage()} />

                        <Route {...RouterConfig.validatedemanderdvPage()} />

                        <Route {...RouterConfig.modifycontacttelPage()} />

                        <Route {...RouterConfig.modifycontactmailPage()} />

                        <Route {...RouterConfig.depotdocumentPage()} />

                        <Route {...RouterConfig.depotdocumentvisitePage()} />

                        <Route {...RouterConfig.depotdocumentfinPage()} />

                        <Route {...RouterConfig.meetingroomsPage()} />

                        {/* @ts-ignore */}
                        <Route {...RouterConfig.homeHRPage()}>
                          <Route index element={<Navigate to={RouterConfig.accueilHRPage().path} replace />} />
                          <Route {...RouterConfig.accueilHRPage()} />
                          <Route {...RouterConfig.absencesHRPage()} />
                          <Route {...RouterConfig.progressAbsenceHRPage()} />
                          <Route {...RouterConfig.actionsHRPage()} />
                          <Route {...RouterConfig.absencesExtractHRPage()} />
                          <Route {...RouterConfig.correspTableAdminPage()} />
                          <Route {...RouterConfig.statsHRPage()} />
                          <Route {...RouterConfig.stats1HRPage()} />
                          <Route {...RouterConfig.listentretienliaisonHRPage()} />
                          <Route {...RouterConfig.listentretienreturnHRPage()} />
                          <Route {...RouterConfig.adminHRPage()} />
                          <Route {...RouterConfig.usersAdminPage()} />
                          <Route {...RouterConfig.rolesAdminPage()} />
                          <Route {...RouterConfig.addserviceAdminPage()} />
                          <Route {...RouterConfig.fileAdminPage()} />
                          <Route {...RouterConfig.settingAdminPage()} />
                          <Route {...RouterConfig.serviceAdminPage()} />
                          <Route {...RouterConfig.toolsRHPage()} />
                          <Route {...RouterConfig.loginRHPage()} />
                          <Route {...RouterConfig.passwordRHPage()} />
                          <Route {...RouterConfig.confirmationRHPage()} />
                          <Route {...RouterConfig.encoursglobalRHPage()} />
                          <Route {...RouterConfig.tendancegeneraleRHPage()} />
                          <Route {...RouterConfig.linechartarretRHPage()} />
                          <Route {...RouterConfig.linecharthospitalisationRHPage()} />
                          <Route {...RouterConfig.linechartparentaliteRHPage()} />
                          <Route {...RouterConfig.radarcharttauxRHPage()} />
                          <Route {...RouterConfig.radarchartprevalenceRHPage()} />
                          <Route {...RouterConfig.radarchartoccurrenceRHPage()} />
                          <Route {...RouterConfig.barchartgraviteRHPage()} />
                          <Route {...RouterConfig.validationprofilRHPage()} />
                          <Route {...RouterConfig.detailsabsenceRHPage()} />
                          <Route {...RouterConfig.preferencesemployeeRHPage()} />
                          <Route {...RouterConfig.choixdatemanagerRHPage()} />
                          <Route {...RouterConfig.choixdatemanagerfinRHPage()} />
                          <Route {...RouterConfig.choixdatesalarieRHPage()} />
                          <Route {...RouterConfig.choixdatesstRHPage()} />
                          <Route {...RouterConfig.creerarretRHPage()} />
                          <Route {...RouterConfig.editarretRHPage()} />
                          <Route {...RouterConfig.deletearretRHPage()} />
                          <Route {...RouterConfig.declarationEndRHPage()} />
                          <Route {...RouterConfig.choixdatesalariefinRHPage()} />
                          <Route {...RouterConfig.editprofilHRPage()} />
                          <Route {...RouterConfig.entretiensLegalPage()} />
                          <Route {...RouterConfig.collaborateursPage()} />
                          <Route {...RouterConfig.serviceAnimationPage()} />
                          <Route {...RouterConfig.parcoursListPage()} />
                        </Route>
                        <Route {...RouterConfig.questionsalarievisitePage()} />
                        <Route {...RouterConfig.politiqueDeConfidentialiteClientPage()} />
                        <Route {...RouterConfig.politiqueDeCookiesPage()} />
                        <Route {...RouterConfig.conditionsPage()} />
                        <Route {...RouterConfig.politiqueDeConfidentialitePage()} />
                        <Route {...RouterConfig.mentionsLegalesePage()} />
                        <Route {...RouterConfig.reportPage()} />
                      </Routes>
                    </div>
                  </AccountRhWithReducer>
                </NavigationProvider>
              </BrowserRouter>
            </SocketContext.Provider>
          </ToastProvider>
        </ThemeComponent>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
