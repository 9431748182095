import { Box, Card } from '@mui/material';
import { EmployeeInformation } from '@/types/user.interface';
import { EmployeeCardContent, EmployeeCardContentElement } from './EmployeeCardContent';

import React from 'react';
import { EmployeeCardHeader } from '../DashboardRH/EmployeeCard';
import { ListCardActions, ListCardContent } from '../DashboardRH/ListCard';
import { FormatDate } from '@/constants/date.constants';
import moment from 'moment';

const header1Width = 100;
const header2Width = 240;
const emptyLabel = '-';

const renderField = (
  employeeInformation: EmployeeInformation | undefined,
  getter: (employee: EmployeeInformation) => string | undefined,
  label: string,
  headerWidth: number,
) => (
  <EmployeeCardContentElement
    label={label}
    value={employeeInformation == null ? emptyLabel : getter(employeeInformation) ?? emptyLabel}
    headerWidth={headerWidth}
  />
);

interface EmployeeCardProps {
  employeeInformation?: EmployeeInformation;
}

export const EmployeeCard = ({ employeeInformation }: EmployeeCardProps) => {
  return (
    <Card>
      <EmployeeCardHeader employeeInformation={employeeInformation as any} />

      <ListCardContent>
        <EmployeeCardContent employeeInformation={employeeInformation} />
      </ListCardContent>

      <ListCardActions collapse collapseTitle="INFORMATIONS COMPLÉMENTAIRES">
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            {renderField(
              employeeInformation,
              (_) => moment(_.birthday).format(FormatDate.FRENCH_DATE),
              'Date de naissance',
              header1Width,
            )}
            {renderField(employeeInformation, (_) => _.zipcode, 'Code Postal', header1Width)}
          </Box>
          <Box sx={{ display: 'flex', flex: 2, flexDirection: 'column' }}>
            {renderField(employeeInformation, (_) => _.birth_commun, 'Département naissance', header2Width)}
            {renderField(
              employeeInformation,
              (_) => (_.employee_login?.status === 'active' ? 'Oui' : 'Non'),
              'Inscription confirmée',
              header2Width,
            )}
          </Box>
        </Box>
      </ListCardActions>
    </Card>
  );
};
