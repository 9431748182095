import React from 'react';
import classNames from 'clsx';
import { Navbar } from '@/components/Navbar/Navbar';
import { NavbarMobile } from '@/components/Navbar/NavbarMobile';
import Footer from '@/components/Footer/Footer';
import './DefaultLayout.scss';
import { Box, type BoxProps } from '@mui/material';

const componentName = 'DefaultLayout';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DefaultLayoutProps extends BoxProps {}

export const DefaultLayout = (props: DefaultLayoutProps) => {
  const { className, children, ...otherProps } = props;
  return (
    <Box {...otherProps} className={classNames(componentName, className)}>
      <Box className={`${componentName}__Header`} component={'header'}>
        <Navbar />
      </Box>

      <Box component={'main'} className={`${componentName}__Content`}>
        <Box
          className={`${componentName}__Container`}
          style={{
            paddingBottom: '56px', // NavbarMobile
          }}
        >
          {children}
        </Box>
      </Box>

      <Box component={'footer'} className={`${componentName}__Footer`} sx={{ zIndex: 1000 }}>
        <NavbarMobile />
        <Footer />
      </Box>
    </Box>
  );
};
