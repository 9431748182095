import moment from 'moment';
import { FormatDate } from '../constants/date.constants';
import { Absence } from '../types/absences.type';

export const toFormatDate = (date?: string): string => {
  const date_ = moment(date);
  const isValid = date_.isValid();
  return isValid ? date_.format(FormatDate.FRENCH_DATE) : '';
};

/**
 * Format a date for display
 *
 * @param dateString
 */
export const displayDate = (dateString: string | undefined) => {
  return toFormatDate(dateString);
};

/**
 * Format date string
 *
 * @param date
 */
export const formatDate = (date: string | undefined) => (date ? moment(date).format('YYYY-MM-DD') : '');

export const calculateDate = (dateStart: string, dateEnd: string) => {
  return moment(dateEnd).diff(moment(dateStart), 'days') + 1;
};

export const sortByDate = (a: string, b: string) => {
  const dateA: any = new Date(a);
  const dateB: any = new Date(b);
  return dateB - dateA;
};

export const toISODate = (dateString: string, originalFormat?: string) =>
  moment(dateString, originalFormat).format(FormatDate.DATE_ONLY);

export const displayDateEndOrHospi = (
  absence: Pick<Absence, 'date_end' | 'date_end_hospi_theory'>,
  dateFormat?: string,
) => {
  if (!absence) return;
  const defaultDateFormat = FormatDate.FRENCH_DATE;
  if (absence.date_end) return toMomentUTCDate(absence.date_end).format(dateFormat || defaultDateFormat);
  else if (absence.date_end_hospi_theory)
    return '(' + toMomentUTCDate(absence.date_end_hospi_theory).format(dateFormat || defaultDateFormat) + ')';
  return '-';
};

export const toMomentUTCDate = (value: string | Date, format?: FormatDate) => {
  return moment.utc(value, format);
};
