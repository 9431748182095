import React, { ReactNode, useState } from 'react';
import './kerijBox.scss';
import { faCircleExclamation, faCircleInfo, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const KerijBoxType = {
  Info: 'info',
  Warning: 'warning',
  Error: 'error',
} as const;
export type KerijBoxType = (typeof KerijBoxType)[keyof typeof KerijBoxType];

const getColor = (type: KerijBoxType) => {
  switch (type) {
    case KerijBoxType.Warning: {
      return '#FF8039';
    }
    case KerijBoxType.Error: {
      return '#FF0000';
    }
    case KerijBoxType.Info:
    default: {
      return '#40C9C9';
    }
  }
};

const getIcon = (type: KerijBoxType) => {
  switch (type) {
    case KerijBoxType.Warning: {
      return faCircleExclamation;
    }
    case KerijBoxType.Error: {
      return faCircleXmark;
    }
    case KerijBoxType.Info:
    default: {
      return faCircleInfo;
    }
  }
};

interface KerijBoxProps {
  message: ReactNode;
  style?: React.CSSProperties;
  type?: KerijBoxType;
  onClick?: () => void;
  hoverColor?: string;
}

export const KerijBox: React.FC<KerijBoxProps> = ({
  message,
  style,
  type = KerijBoxType.Info,
  onClick,
  hoverColor,
}: KerijBoxProps) => {
  const color = getColor(type);
  const icon = getIcon(type);
  const [isHover, setIsHover] = useState<boolean>(false);
  const onMouseOver = () => {
    setIsHover(true);
  };
  const onMouseOut = () => {
    setIsHover(false);
  };

  return (
    <div
      className="kerij-box mb-2 mt-2"
      style={{
        borderColor: color,
        cursor: onClick ? 'pointer' : 'default',
        backgroundColor: isHover ? hoverColor : 'transparent',
        ...style,
      }}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <div className="kerij-col">
        <FontAwesomeIcon className="fa-2x" icon={icon} style={{ color: onClick && isHover ? 'black' : color }} />
      </div>
      <div className="kerij-col kerij-box-content">
        <p className="kerij-box-message">{message}</p>
      </div>
    </div>
  );
};
