import React from 'react';
import { Box } from '@mui/material';
import CustomModal from '../../../base/CustomModal';
import DataTable from 'react-data-table-component';
interface IModalVisualProperties {
  handleCloseModalVisual?: () => void;
  columns?: any;
  dataCsv?: any;
  isModalVisual?: boolean;
}

const ModalVisual = ({ handleCloseModalVisual, columns, dataCsv, isModalVisual }: IModalVisualProperties) => {
  return (
    <CustomModal
      open={isModalVisual || false}
      setOpen={() => {
        handleCloseModalVisual && handleCloseModalVisual();
      }}
      contentProps={{
        style: { paddingLeft: '4rem', paddingRight: '4rem' },
      }}
      renderContent={() => (
        <Content handleCloseModalVisual={handleCloseModalVisual} columns={columns} dataCsv={dataCsv} />
      )}
      renderHeader={() => <></>}
    />
  );
};

interface IContentProperties {
  handleCloseModalVisual?: () => void;
  columns?: any;
  dataCsv?: any;
}

const Content = ({ columns, dataCsv }: IContentProperties) => {
  return (
    <Box className="container-fluid col-12">
      <DataTable columns={columns} data={dataCsv} pagination highlightOnHover expandOnRowClicked />
    </Box>
  );
};

export default ModalVisual;
