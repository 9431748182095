import * as React from 'react';
import classNames from 'clsx';
import brandLogo from './assets/Logotype-Simple-Fondclair.svg';
import brandLogoDark from './assets/Logotype-Simple-Fondfonce.svg';
import { ENVIRONMENT_NAME, isProduction } from '@/config';
import './BrandIcon.css';

// Name and options
const componentName = 'BrandIcon';

// Component properties
export interface BrandIconProps extends React.ComponentProps<'div'> {
  noCaption?: boolean;
  dark?: boolean;
}

export function BrandIcon(props: BrandIconProps) {
  const { className, dark, noCaption, children, ...otherProps } = props;
  const environmentName = ENVIRONMENT_NAME;
  return (
    <div className={classNames(componentName, className)} {...otherProps}>
      {noCaption || !environmentName || isProduction() ? undefined : (
        <svg
          className={classNames(`${componentName}__Caption`)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 600 100"
        >
          <text x="0" y="50%" textAnchor="start" dominantBaseline="middle">
            {environmentName}
          </text>
        </svg>
      )}
      <img src={dark ? brandLogoDark : brandLogo} className={classNames(`${componentName}__Image`)} alt="logo" />
    </div>
  );
}
