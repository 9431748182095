import React, { useState } from 'react';
import CustomModal from '../../../base/CustomModal';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RHNotification, RHNotificationHistory } from '../../../../types/rh.interface';
import KerijButton from '../../../base/button/kerijButton';
import { Box } from '@mui/material';
import { fetchFileS3, updateRhReportDocument } from '../../../../services/rh';
import * as xlsx from 'xlsx';
import ModalVisual from './ModalVisual';

interface IModalIsOpenFileProperties {
  rhNotification: RHNotification;
  handleCloseModal?: () => void;
  visible: boolean;
}

const ModalIsOpenFile = ({ rhNotification, handleCloseModal, visible }: IModalIsOpenFileProperties) => {
  /* Visualisez les fichiers csv */
  const [isModalVisual, setIsModalVisual] = useState(false);
  const [dataCsv, setDataCsv] = useState<object>([]);
  const [columns, setColumns] = useState<object>([]);

  const {
    id: idRhNotification,
    employee_information: employeeInformation,
    rh_notification_histories: rhNotificationHistories,
  } = rhNotification;

  const handleCloseModalVisual = () => {
    setIsModalVisual(!isModalVisual);
    setDataCsv([]);
    setColumns([]);
  };

  const handleClickViewButton = async (value: { report_documents: { url: any }[] }) => {
    const urlString = value.report_documents[0].url;
    // eslint-disable-next-line unicorn/prefer-string-slice
    const extension = urlString.substring(urlString.lastIndexOf('.') + 1, urlString.length);

    const response = await fetchFileS3(
      {
        url: value.report_documents[0].url,
        type: 'report_document',
        idBusiness: employeeInformation?.business.id,
        idRhInformation: idRhNotification,
      },
      extension === 'xlsx' ? 'arraybuffer' : 'text',
    );

    if (response.status === 200) {
      if (extension === 'csv') {
        const content = response.data.split('\n');
        const columns = content
          .at(0)
          .split(',')
          .map((value: string) => {
            return {
              name: value.trim(),
              selector: (row: { [x: string]: any }) => row[value.trim()],
            };
          });
        setColumns(columns);
        const dataCsv = content.slice(1).map((value: any) => {
          const row = value.split(',');
          const json: any = {};
          for (let index = 0; index < row.length; index++) {
            json[columns[index].name] = row.at(index).trim();
          }
          return json;
        });
        setDataCsv(dataCsv);
        setIsModalVisual(true);
      } else if (extension === 'xlsx') {
        const workbook = xlsx.read(response.data, {
          type: 'binary',
          cellText: false,
          cellDates: true,
        });
        const worksheets = workbook.SheetNames[0];
        const newDataCsv = xlsx.utils.sheet_to_json(workbook.Sheets[worksheets], {
          header: 0,
          raw: false,
          dateNF: 'dd/mm/yyyy',
        });
        const columns = Object.keys(newDataCsv.at(0) || {}).map((value) => {
          return {
            name: value.trim(),
            selector: (row: any) => row[value.trim()],
          };
        });
        setColumns(columns);
        setDataCsv(newDataCsv);
        setIsModalVisual(true);
      }
    }
  };

  const handleClickDownloadButton = async (value: any) => {
    const response = await fetchFileS3(
      {
        url: value.report_documents[0].url,
        type: 'report_document',
        idBusiness: employeeInformation?.business.id,
        idRhInformation: idRhNotification,
      },
      'arraybuffer',
    );

    if (response.status === 200) {
      const urlString = value.report_documents[0].url;
      // eslint-disable-next-line unicorn/prefer-string-slice
      const extension = urlString.substring(urlString.lastIndexOf('.') + 1, urlString.length);
      const file = new Blob([response.data], {
        type: extension === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });
      const fileURL = URL.createObjectURL(file);
      const element = document.createElement('a');
      document.body.append(element);
      // element.style = 'display: none';
      element.href = fileURL;
      element.download = `listabsences_${moment(value.date_notification).format('YYYYMMDD_HHmm')}.${extension}`;
      element.click();
      element.remove();
      window.URL.revokeObjectURL(fileURL);
      const updateResponse = await updateRhReportDocument({
        idReportDocument: value.report_documents[0].id,
        idBusiness: employeeInformation?.business.id,
      });

      if (updateResponse.status === 200) {
        handleCloseModal && handleCloseModal();
      }
    }
  };

  return (
    <CustomModal
      open={visible || false}
      setOpen={handleCloseModal}
      contentProps={{
        style: { paddingLeft: '4rem', paddingRight: '4rem' },
      }}
      renderContent={() => (
        <>
          <Content
            rhNotificationHistories={rhNotificationHistories}
            setModalIsOpenFile={handleCloseModal}
            handleClickViewButton={handleClickViewButton}
            handleClickDownloadButton={handleClickDownloadButton}
          />
          {isModalVisual && (
            <ModalVisual
              handleCloseModalVisual={handleCloseModalVisual}
              columns={columns}
              dataCsv={dataCsv}
              isModalVisual={isModalVisual}
            />
          )}
        </>
      )}
      renderHeader={() => <>Extraction des absences</>}
      renderFooter={() => (
        <>
          <KerijButton
            label={'Retourner au menu'}
            onClick={() => {
              handleCloseModal && handleCloseModal();
            }}
          />
        </>
      )}
    />
  );
};

interface IContentProperties {
  rhNotificationHistories: any;
  setModalIsOpenFile: any;
  handleClickViewButton?: (value: any) => void;
  handleClickDownloadButton?: (value: any) => void;
}

const Content = ({ rhNotificationHistories, handleClickViewButton, handleClickDownloadButton }: IContentProperties) => {
  return (
    <Box className="col-12">
      <div className="container-fluid text-center">
        <h3 className="question-form titre-modal mb-5">{`Télécharger le fichier d'extraction d'absences`}</h3>
        {rhNotificationHistories &&
          rhNotificationHistories.length > 0 &&
          rhNotificationHistories.map((rhNotificationHistory: RHNotificationHistory, index: number) => {
            return (
              <div className="card col-12 mt-2" key={index}>
                <div className="card-body card-style">
                  <div className="row col-12">
                    {rhNotificationHistory.report_documents[0] &&
                      rhNotificationHistory.report_documents[0].is_downloaded && (
                        <div
                          className="col-1 text-center btn-lg"
                          style={{
                            color: '#40C9C9',
                            position: 'absolute',
                          }}
                        >
                          <FontAwesomeIcon
                            icon={'fa-solid fa-circle-check' as any}
                            style={{
                              color: '#79D9D9',
                            }}
                          />
                        </div>
                      )}
                    <p className="card-text text-end col-2 contacts-libelle">Fichier</p>
                    <p className="card-text col-7 text-center mail-active">
                      Extraction des absences {moment(rhNotificationHistory.date_notification).format('DD/MM/YYYY')}{' '}
                      {moment(rhNotificationHistory.date_notification).format('HH:mm')}
                    </p>
                    {rhNotificationHistory.report_documents.length > 0 &&
                      rhNotificationHistory.report_documents[0] &&
                      rhNotificationHistory.report_documents[0].url && (
                        <div
                          className="col-2 text-end contacts-modif"
                          onClick={() => {
                            handleClickViewButton?.(rhNotificationHistory);
                          }}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          Visualiser
                        </div>
                      )}
                    {rhNotificationHistory.report_documents[0] && rhNotificationHistory.report_documents[0].url && (
                      <div
                        className="col-1"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleClickDownloadButton?.(rhNotificationHistory);
                        }}
                      >
                        <FontAwesomeIcon icon={'fa-solid fa-download' as any} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Box>
  );
};

export default ModalIsOpenFile;
