import * as React from 'react';
import classNames from 'clsx';
import { Box } from '@mui/material';
import { ParcoursScript } from '@/types';
import { ParcoursRule } from '../ParcoursRule';

const componentName = 'ParcoursListItemDetails';

export interface ParcoursListItemDetailsProps extends React.ComponentProps<typeof Box> {
  value: ParcoursScript;
}

export function ParcoursListItemDetails(props: ParcoursListItemDetailsProps) {
  const { value, className, ...boxProps } = props;

  return (
    <Box className={classNames(componentName, className)} {...boxProps}>
      {value.rule.where.map((rule, index) => (
        <ParcoursRule key={index} value={rule} index={index} />
      ))}
    </Box>
  );
}
