import React, { useState } from 'react';
import { Absence, AbsenceStatus } from '../../../../../../types/absences.type';
import Typography from '@mui/material/Typography';
import { AbsenceDate } from '../../../../Absences/AbsenceDate';
import { AbsenceLabel } from '../../../../Absences/AbsenceLabel';
import { CardAbsenceHistoryModal } from './CardAbsenceHistoryModal';
import { Box } from '@mui/material';

interface CardAbsenceHistoryItemProperties {
  absence: Absence;
}

export const CardAbsenceHistoryItem = ({ absence }: CardAbsenceHistoryItemProperties) => {
  const [isOpen, setIsOpen] = useState(false);
  const deletedStyle = absence.status === AbsenceStatus.DELETED ? { textDecoration: 'line-through' } : {};

  return (
    <Box>
      <div onClick={() => setIsOpen(true)} className="CardContentItem">
        <Typography className="CardContentItem__Label">
          <AbsenceLabel absence={absence} />
          {' - '}
          <span style={deletedStyle}>
            <AbsenceDate value={absence} />
          </span>
        </Typography>
      </div>

      <CardAbsenceHistoryModal absence={absence} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Box>
  );
};
