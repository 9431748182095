// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msg-pass {
  font-size: 0.85em;
  margin-bottom: 2em;
}

.msg-pass-create {
  font-size: 16px;
  margin-bottom: 2em;
}

.msg-code {
  font-size: 0.7em;
  margin-bottom: 2em;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Chrome */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Opéra*/
input::-o-inner-spin-button,
input::-o-outer-spin-button {
  -o-appearance: none;
  margin: 0;
}

.err {
  background: #ffffff;
  border-radius: 10px;
  color: #ff2e00;
  padding: 0.5rem;
}

.list-salarie {
  border: 1px solid #40c9c9;
  background: #ffffff;
  border-radius: 10px;
  color: #342f6c;
  padding: 1rem;
  cursor: pointer;
}

.input-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.label-password {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
}

.input-password {
  margin: auto;
}

.icon-show-password {
  position: absolute;
  z-index: 2;
  margin-right: 1rem;
  cursor: pointer;
}

.checkbox-business:checked {
  background-color: #40c9c9;
}
`, "",{"version":3,"sources":["webpack://./src/components/Signup/Signup.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B;;AAEA,WAAW;AACX;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,SAAS;AACT;;EAEE,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;EACnB,cAAc;EACd,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".msg-pass {\n  font-size: 0.85em;\n  margin-bottom: 2em;\n}\n\n.msg-pass-create {\n  font-size: 16px;\n  margin-bottom: 2em;\n}\n\n.msg-code {\n  font-size: 0.7em;\n  margin-bottom: 2em;\n}\n\n/* Firefox */\ninput[type='number'] {\n  -moz-appearance: textfield;\n}\n\n/* Chrome */\ninput::-webkit-inner-spin-button,\ninput::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Opéra*/\ninput::-o-inner-spin-button,\ninput::-o-outer-spin-button {\n  -o-appearance: none;\n  margin: 0;\n}\n\n.err {\n  background: #ffffff;\n  border-radius: 10px;\n  color: #ff2e00;\n  padding: 0.5rem;\n}\n\n.list-salarie {\n  border: 1px solid #40c9c9;\n  background: #ffffff;\n  border-radius: 10px;\n  color: #342f6c;\n  padding: 1rem;\n  cursor: pointer;\n}\n\n.input-group {\n  margin-bottom: 1rem;\n  display: flex;\n  flex-direction: column;\n}\n\n.label-password {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  overflow: hidden;\n  justify-content: flex-end;\n}\n\n.input-password {\n  margin: auto;\n}\n\n.icon-show-password {\n  position: absolute;\n  z-index: 2;\n  margin-right: 1rem;\n  cursor: pointer;\n}\n\n.checkbox-business:checked {\n  background-color: #40c9c9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
