import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import 'popstate-direction';
import { useNavigate } from 'react-router-dom';

function DepotDocumentHistorique() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb-5 text-center">Mise à jour du dossier</h3>

        <form className="form-declaration text-center col-12 col-md-8">
          <div className="declaration-check mb-5">
            <FontAwesomeIcon className="fa-5x" icon={faCheck} />
          </div>

          <div className="col-12">
            <p>
              Merci.
              <br />
              Votre dossier a bien été mis à jour
              <br />
              <br />
            </p>
          </div>
        </form>
        <div className="position-relative col-12 col-md-8 mt-5 mb-5">
          <button
            type="button"
            className="position-absolute top-0 start-50 translate-middle-x btn btn-kerij"
            onClick={() => {
              navigate('/historiquesalarie');
            }}
          >
            Retourner sur Kerij
          </button>
        </div>
      </div>
    </div>
  );
}

export default DepotDocumentHistorique;
