import React, { useState } from 'react';
import { Box, CardActions, Divider, styled, Theme, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { Button } from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Actions = styled(CardActions)(({ theme }) => ({
  display: 'inherit',
  margin: 0,
  padding: 0,
  fontSize: 12,
}));

interface ListCardActionsProps {
  children?: React.ReactNode;
  collapse?: boolean;
  collapseTitle?: string;
}

export const ListCardActions = (props: ListCardActionsProps) => {
  const { children, collapse, collapseTitle } = props;
  const [isShowMore, setIsShowMore] = useState(false);

  return (
    <Actions>
      <Box>
        <Divider sx={{ width: '100%', bgcolor: (theme) => theme.palette.grey[300] }} />
        <Box sx={{ width: '100%', color: (theme) => theme.palette.primary.main }}>
          {collapse ? (
            <>
              <Box textAlign="center">
                <Button
                  variant={isShowMore ? 'contained' : 'text'}
                  onClick={() => setIsShowMore(!isShowMore)}
                  fullWidth
                  disableElevation
                  style={{ minHeight: 34 }}
                  sx={
                    isShowMore
                      ? (theme) => ({
                          borderRadius: 0,
                          backgroundColor: theme.palette.primaryBackground.main,
                          color: theme.palette.primaryBackground.contrastText,
                          ':hover': { backgroundColor: theme.palette.primaryBackground.main },
                        })
                      : { borderRadius: 0 }
                  }
                >
                  {collapseTitle && (
                    <Typography mr={1} fontSize={10} fontWeight="bolder">
                      {collapseTitle}
                    </Typography>
                  )}
                  {isShowMore ? (
                    <FontAwesomeIcon icon="chevron-up" width={8} />
                  ) : (
                    <FontAwesomeIcon icon="chevron-down" width={8} />
                  )}
                </Button>
              </Box>

              {isShowMore && (
                <Box p={2.5} paddingTop={1.25} paddingBottom={1.25}>
                  {children}
                </Box>
              )}
            </>
          ) : (
            <Box p={2.5} paddingTop={1.25} paddingBottom={1.25}>
              {children}
            </Box>
          )}
        </Box>
      </Box>
    </Actions>
  );
};
