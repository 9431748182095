// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (max-width: 991px) {
  .titre-details {
    font-size: 25px;
  }

  .card-rh-header-phone {
    width: 105%;
    height: 3.5em;
  }

  .text-details-infos {
    font-size: 15px;
    line-height: normal;
  }

  .details-absence-text-2 {
    font-size: 15px;
    line-height: normal;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/DashboardRH/DetailsAbsence/DetailsAbsence.css"],"names":[],"mappings":"AAAA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,aAAa;EACf;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;AACF","sourcesContent":["@media all and (max-width: 991px) {\n  .titre-details {\n    font-size: 25px;\n  }\n\n  .card-rh-header-phone {\n    width: 105%;\n    height: 3.5em;\n  }\n\n  .text-details-infos {\n    font-size: 15px;\n    line-height: normal;\n  }\n\n  .details-absence-text-2 {\n    font-size: 15px;\n    line-height: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
