import React, { useState, useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
  RadialLinearScale,
  Filler,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import '../Chart.css';
import Slider from '@mui/material/Slider';
import ChartZoom from 'chartjs-plugin-zoom';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchRhGraphDataAbsenteeism } from '../../../services/rh';
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
  ChartZoom,
  RadialLinearScale,
  Filler,
);

const BarChartGravite = () => {
  const chartRef = useRef(null);
  const [total, setTotal] = useState(0);
  const marks = [
    {
      value: 1,
      label: '1 mois',
    },
    {
      value: 2,
      label: '2 mois',
    },
    {
      value: 3,
      label: '3 mois',
    },
    {
      value: 4,
      label: '4 mois',
    },
    {
      value: 5,
      label: '5 mois',
    },
    {
      value: 6,
      label: '6 mois',
    },
  ];

  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  const [category, setCategory] = useState('total');

  const valuetext = (value) => {
    return `${value} jours`;
  };

  const [month, setMonth] = useState(1);
  const borderColor = [
    'rgba(255, 0, 0, 1)',
    'rgba(0, 0, 255, 1)',
    'rgba(60, 179, 113, 1)',
    'rgba(238, 130, 238, 1)',
    'rgba(255, 165, 0, 1)',
    'rgba(106, 90, 205, 1)',
  ];
  const backgroundColor = [
    'rgba(255, 0, 0, 0.2)',
    'rgba(0, 0, 255, 0.2)',
    'rgba(60, 179, 113, 0.2)',
    'rgba(238, 130, 238, 0.2)',
    'rgba(255, 165, 0, 0.2)',
    'rgba(106, 90, 205, 0.2)',
  ];
  const [data, setData] = useState({
    labels: ['20-27 ans', '28-35 ans', '36-43 ans', '44-51 ans', '52-59 ans', '60+ ans'],
    datasets: [
      {
        label: '3 jours',
        data: [...Array(6)].map((value) => 0),
        borderWidth: 2,
        borderColor: borderColor[0],
        backgroundColor: backgroundColor[0],
      },
      {
        label: '4-7 jours',
        data: [...Array(6)].map((value) => 0),
        borderWidth: 2,
        borderColor: borderColor[1],
        backgroundColor: backgroundColor[1],
      },
      {
        label: '8-30 jours',
        data: [...Array(6)].map((value) => 0),
        borderWidth: 2,
        borderColor: borderColor[2],
        backgroundColor: backgroundColor[2],
      },
      {
        label: '31-60 jours',
        data: [...Array(6)].map((value) => 0),
        borderWidth: 2,
        borderColor: borderColor[3],
        backgroundColor: backgroundColor[3],
      },
      {
        label: '61-90 jours',
        data: [...Array(6)].map((value) => 0),
        borderWidth: 2,
        borderColor: borderColor[4],
        backgroundColor: backgroundColor[4],
      },
      {
        label: '90+ jours',
        data: [...Array(6)].map((value) => 0),
        borderWidth: 2,
        borderColor: borderColor[5],
        backgroundColor: backgroundColor[5],
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: 'Line chart with Configuration',
      },
      label: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      elements: {
        line: {
          borderWidth: 3,
        },
      },
      tooltip: {
        callbacks: {
          label: function (item, data) {
            return `${item.dataset.label}: ${item.raw}%`;
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    datasetFill: true,
  };

  const getDataAbsence = async (month, category) => {
    const response = await fetchRhGraphDataAbsenteeism({
      month: '04',
      year: '2022',
      type: 3,
      monthDiff: month,
    });
    if (response) {
      setData({
        labels: response.data.absenteeisme.partial.labels.map((value) => value + ' ans'),
        datasets: response.data.absenteeisme.partial.value[category].map((v, i) => {
          return {
            label: Object.keys(v)[0] + ' jours',
            data: Object.values(v)[0],
            fill: false,
            borderWidth: 2,
            borderColor: borderColor[i],
            backgroundColor: backgroundColor[i],
          };
        }),
      });
      setTotal(response.data.absenteeisme.total);
    }
    // axios
    //     .get('/api/rh/graph/data_absenteeism', {
    //         params: {
    //             month: '04',
    //             year: '2022',
    //             type: 3,
    //             monthDiff: month,
    //         },
    //     })
    //     .then((value) => {
    //         setData({
    //             labels: value.data.absenteeisme.partial.labels.map((value) => value + ' ans'),
    //             datasets: value.data.absenteeisme.partial.value[category].map((v, i) => {
    //                 return {
    //                     label: Object.keys(v)[0] + ' jours',
    //                     data: Object.values(v)[0],
    //                     fill: false,
    //                     borderWidth: 2,
    //                     borderColor: borderColor[i],
    //                     backgroundColor: backgroundColor[i],
    //                 };
    //             }),
    //         });
    //         setTotal(value.data.absenteeisme.total);
    //     });
  };

  useEffect(() => {
    getDataAbsence(month, category);
  }, [month, category]);
  return (
    <div className="row col-12">
      <div className="p-2 flex-fill bd-highlight col-10" id="tendancegenerale">
        <div className="container-fluid mt-5">
          <div className="chart-container">
            <div className="chart-item">
              <h1 className="titre-rh mb-5">La gravité des arrêts de travail depuis {month ? `M-${month}` : 'M-0'}</h1>
              <Slider
                className="mb-5"
                defaultValue={month}
                value={month}
                onChangeCommitted={(event, newVal) => {
                  setMonth(newVal);
                }}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                step={null}
                marks={marks}
                min={1}
                max={6}
              ></Slider>
              <div
                className="row"
                style={{
                  height: '100%',
                  width: '70vw',
                  aligneItems: 'center',
                }}
              >
                <div
                  className="col-sm"
                  style={{
                    display: 'flex',
                    alignSelf: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '50%',
                  }}
                >
                  <div
                    style={{
                      width: '300px',
                      height: '200px',
                      borderRadius: '10px',
                      backgroundColor: '#0C064B',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <span
                      style={{
                        textAlign: 'center',
                        fontSize: '36px',
                        color: '#FFF',
                      }}
                    >
                      Nombre total: <br /> <strong>{total}</strong>
                    </span>
                  </div>
                </div>
                <div className="col-sm" style={{ height: '600px', width: '70vw' }}>
                  <select
                    value={category}
                    onChange={(event) => setCategory(event.target.value)}
                    style={{ marginLeft: 30 }}
                  >
                    <option value="total">total</option>
                    <option value="femme">femme</option>
                    <option value="homme">homme</option>
                    <option value="cadre">cardre</option>
                    <option value="non-cadre">non-cardre</option>
                  </select>
                  <Bar data={data} options={options} ref={chartRef}></Bar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarChartGravite;
