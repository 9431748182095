import { Editor, Element } from 'slate';
import { CustomElement } from '../type';

export const isBlockActive = (editor: Editor, format: CustomElement['type'], blockType: 'type' = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  // eslint-disable-next-line unicorn/prefer-spread
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        // eslint-disable-next-line security/detect-object-injection
        n[blockType] === format,
    }),
  );

  return !!match;
};
