import React, { PureComponent } from 'react';

class TextAreaAutoResize extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      rows: 5,
      minRows: 5,
    };
  }

  handleChange = (event) => {
    const textareaLineHeight = 24;
    const { minRows, maxRows } = this.state;

    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    this.setState({
      value: event.target.value,
      rows: currentRows,
    });
  };

  render() {
    return (
      <textarea
        rows={this.state.rows}
        className="textarea container-fluid export-text"
        value={this.state.value}
        onChange={this.handleChange}
        placeholder={this.props.placeholder ? this.props.placeholder : ''}
        style={{ textAlign: 'left', verticalAlign: 'top' }}
      />
    );
  }
}

export default TextAreaAutoResize;
