/* eslint-disable xss/no-mixed-html */
export interface PieChartsData {
  labels: string[];
  datasets: {
    data: number[];
    borderColor: string[];
    borderWidth: number;
    hoverOffset: number;
    backgroundColor?: string[];
  }[];
}

export interface PieData {
  datasets: {
    labels: string[];
    data: string[];
    backgroundColor: string[];
    borderWidth: number;
  }[];
}

export const defaultPieChartsData: PieChartsData = {
  labels: [],
  datasets: [
    {
      data: [],
      borderColor: [],
      borderWidth: 0,
      hoverOffset: 0,
    },
  ],
};

export const defaultPiesData: PieData = {
  datasets: [
    {
      labels: [],
      data: [],
      backgroundColor: [],
      borderWidth: 0,
    },
  ],
};

export const pieChartsBaseOptions = {
  plugins: {
    responsive: true,
    cutoutPercentage: 75,
    legend: {
      display: false,
      position: 'bottom',
      align: 'start',
      fullSize: false,
    },
    datalabels: {
      formatter: (value: any, context: any) => {
        const sum = context.chart.data.datasets[0].data.reduce((a: any, b: any) => a + b, 0);
        const percentage = ((value * 100) / sum).toFixed(2) + '%';
        return value > 0 ? percentage : '0%';
      },
      color: '#fff',
    },
    tooltip: {
      enabled: false,
      external: initExternalTooltip,
    },
  },
};

export const pieBaseOptions = {
  plugins: {
    legend: { display: false },
    datalabels: {
      formatter: (value: any) => value,
      color: '#fff',
      anchor: 'start',
      borderRadius: 3,
      font: { size: 30 },
    },
    tooltip: {
      event: ['click', 'mouseover'],
      callbacks: { title: () => 'Nombre total des arrêts' },
      enabled: false,
      external: initExternalTooltip,
    },
  },
};

export function initExternalTooltip(context: any) {
  const tooltipElement: HTMLDivElement = createTooltipElement();

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipElement.style.opacity = '0';
    return;
  }

  // Set caret Position
  tooltipElement.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) tooltipElement.classList.add(tooltipModel.yAlign);
  else tooltipElement.classList.add('no-transform');

  // Set Text
  const tableRoot = tooltipElement.querySelector('table');
  if (tooltipModel.body && tableRoot) {
    const innerHTML = getTooltipContent(tooltipModel);
    tableRoot.innerHTML = innerHTML;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  tooltipElement.style.left =
    tooltipModel.caretX + tableRoot?.offsetWidth < 225 && tooltipModel.x + tooltipModel.width < 225
      ? position.left - window.pageXOffset - tooltipModel.caretX + 'px'
      : position.left + window.pageXOffset + tooltipModel.caretX + 'px';
  tooltipElement.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
  tooltipElement.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
  setTooltipStyle(tooltipElement);
}

export const generateLegend = (legends: any) => {
  const ul = document.createElement('ul');
  ul.style.cssText = 'display: flex; flex-wrap: wrap; width: 100%; align-self: center;';

  for (const element of legends) {
    const li = document.createElement('li');
    li.style.cssText = 'display: flex; justify-content: flex-start; align-items: center; flex: 1 1 auto; width: 200px';
    const div = document.createElement('div');
    div.style.cssText = `background-color: ${element.fillStyle}; width: 60px; display: inline-block; height: 15px; margin-right: 10px`;
    const textNode = document.createTextNode(element.text);
    li.append(div);
    li.append(textNode);
    ul.append(li);
  }
  return ul.outerHTML;
};

function createTooltipElement() {
  let tooltipElement: HTMLDivElement | null = document.querySelector('#chartjs-tooltip');
  if (tooltipElement != null) return tooltipElement;

  tooltipElement = document.createElement('div');
  tooltipElement.id = 'chartjs-tooltip';
  tooltipElement.innerHTML = '<table></table>';
  document.body.append(tooltipElement);

  return tooltipElement;
}

function getTooltipContent(tooltipModel: any) {
  const titleLines = tooltipModel.title || [];
  const bodyLines = tooltipModel.body.map((item: any) => item.lines);
  let innerHtml = '<thead>';

  for (const title of titleLines) {
    innerHtml += '<tr><th>' + title + '</th></tr>';
  }
  innerHtml += '</thead><tbody>';

  for (const [index, body] of bodyLines.entries()) {
    const colors = tooltipModel.labelColors[index];
    let backgroundColor = '';
    if (body[0].includes('Arrêt de travail')) backgroundColor = 'rgba(54, 162, 235, 1)';
    else if (body[0].includes('Hospitalisation')) backgroundColor = 'rgba(255, 99, 132, 1)';
    else if (body[0].includes('Parentalité')) backgroundColor = 'rgba(255, 206, 86, 1)';

    let style = 'background-color:' + backgroundColor;
    style += '; border-color:' + colors.borderColor;
    style += '; border-width: 2px';
    const span = '<span style="' + style + '"></span>';
    innerHtml += '<tr><td>' + span + body + '</td></tr>';
  }
  innerHtml += '</tbody>';

  return innerHtml;
}

function setTooltipStyle(tooltipElement: HTMLDivElement) {
  tooltipElement.style.opacity = '1';
  tooltipElement.style.position = 'absolute';
  tooltipElement.style.pointerEvents = 'none';
  tooltipElement.style.backgroundColor = 'black';
  tooltipElement.style.borderRadius = '10px';
  tooltipElement.style.paddingLeft = '10px';
  tooltipElement.style.paddingRight = '10px';
  tooltipElement.style.color = 'white';
  tooltipElement.style.zIndex = '9999';
}
