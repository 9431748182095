import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../../../../../../constants';
import { CloseAbsenceModalContentForm } from './CloseAbsenceModalContentForm';

interface CloseAbsenceModalContentProperties {
  value?: string;
  dateValue?: string;
  documentSelected?: boolean;
  onChange: (value: string) => void;
  onChangeDate: (value?: string) => void;
  onItemAction: (itemValue: string) => void;
}

export const CloseAbsenceModalContent = ({
  value,
  dateValue,
  documentSelected,
  onChange,
  onChangeDate,
  onItemAction,
}: CloseAbsenceModalContentProperties) => {
  return (
    <Box
      sx={{
        overflowY: 'hidden',
        paddingBlock: '2rem',
      }}
    >
      <Typography
        style={{
          color: colors.darkblue,
          fontSize: '1rem',
        }}
      >{`Gérer la fin d'une absence`}</Typography>
      <CloseAbsenceModalContentForm
        value={value}
        dateValue={dateValue}
        documentSelected={documentSelected}
        onChange={onChange}
        onChangeDate={onChangeDate}
        onItemAction={onItemAction}
      />
    </Box>
  );
};
