// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kerij-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto;
}

.kerij-col {
  display: flex;
}

.kerij-box-content {
  text-align: start;
  margin-left: 1em;
}

.kerij-box-message {
  color: #342f6c;
  font-family: "Montserrat", Helvetica, serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.kerij-box-border {
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/base/boxes/kerijBox.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;EACA,iBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,cAAA;EACA,2CAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,SAAA;EACA,UAAA;AACF;;AAEA;EACE,yBAAA;EACA,iBAAA;EACA,mBAAA;EACA,mBAAA;AACF","sourcesContent":[".kerij-box {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  background-color: #ffffff;\n  border-width: 1px;\n  border-style: solid;\n  border-radius: 10px;\n  max-width: 100%;\n  padding: 1rem;\n  margin: 0 auto;\n}\n\n.kerij-col {\n  display: flex;\n}\n\n.kerij-box-content {\n  text-align: start;\n  margin-left: 1em;\n}\n\n.kerij-box-message {\n  color: #342f6c;\n  font-family: 'Montserrat', Helvetica, serif;\n  font-size: 14px;\n  font-weight: 400;\n  letter-spacing: 0;\n  line-height: 20px;\n  margin: 0;\n  padding: 0;\n}\n\n.kerij-box-border {\n  background-color: #ffffff;\n  border-width: 1px;\n  border-style: solid;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
