import axios from '@/lib/axios';

export const verifyToken = async (data: any, token?: string): Promise<any> => {
  return await axios.post('/api/token/decode', data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const encodeToken = async (data: any): Promise<any> => {
  return await axios.post('/api/token/encode', data);
};

export const exchangeToken = async (data: any): Promise<any> => {
  return await axios.post('/api/auth/token_exchange', data);
};
