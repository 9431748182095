import { useLocation, useNavigate, Location } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faTrash, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { getParamsBusiness } from '../../services/get_params_business.service';
import Modal, { Styles } from 'react-modal';
import { KerijBox, KerijBoxType } from '../../../../components/base';
import { AccountContext, useAppSelector } from '@/store';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';

type periodType = {
  dateDebut: string;
  dateFin: string;
  error: number | string;
};

const getAbsenceWeek = (condition: string): { pre: number; post: number } => {
  switch (condition) {
    case '41':
      return { pre: 7, post: 21 };
    case '42':
      return { pre: 7, post: 28 };
    default:
      return { pre: 7, post: 21 };
  }
};

const getOrdinalNumber = (value: number) => {
  switch (value) {
    case 1:
      return '1ère';
    case 2:
      return '2nde';
    case 3:
      return '3ème';
    default:
      return `${value}ème`;
  }
};

const getRestDate = (postAbsence: number, period: periodType[], index: number) => {
  return (
    postAbsence -
    1 -
    period
      .filter((val, ind) => ind < index && ind > 1)
      .reduce((prev, curr) => {
        if (moment(curr.dateFin).diff(moment(curr.dateDebut)) > 0) {
          return prev + moment(curr.dateFin).diff(moment(curr.dateDebut), 'days') + 1;
        } else {
          return prev + 0;
        }
      }, 0)
  );
};

const zone = 'metropole';

const customStyles = {
  content: {
    border: '0',
    borderRadius: '4px',
    bottom: 'auto',
    minHeight: '10rem',
    left: '50%',
    padding: '2rem',
    position: 'fixed',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    minWidth: '20rem',
    width: '80%',
    maxWidth: '60rem',
  },
};

export const FatherDate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useAppSelector(AccountContext.getUser);
  const document = location.state.document || {};
  const condition =
    document?.absenceType?.value ?? '' + document?.absenceType1?.value ?? '' + document?.absenceType2?.value ?? '';
  const [dateNaissance, setDateNaissance] = useState(document.dateNaissance ? document.dateNaissance : '');

  const [error, setError] = useState<string | number>('');
  const [period, setPeriod] = useState<periodType[]>(
    document.period && document.period.length > 0 ? document.period : [{ dateDebut: '', dateFin: '', error: '' }],
  );

  const [preAbsence, setPreAbsence] = useState<number>(getAbsenceWeek(condition).pre);
  const [postAbsence, setPostAbsence] = useState<number>(getAbsenceWeek(condition).post);
  const [birthDuration, setBirthDuration] = useState<number>(3);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(document.period && document.period.length > 2 ? true : false);

  const showDate = () => {
    if (!isActive) {
      setPeriod((period: periodType[]) => {
        if (period.length === 2) {
          return [
            ...period,
            {
              dateDebut: moment(period[0].dateDebut).add(7, 'days').format('YYYY-MM-DD'),
              dateFin: moment(period[0].dateDebut)
                .add(7 + postAbsence - 1, 'days')
                .format('YYYY-MM-DD'),
              error: -1,
            },
          ];
        }
        return period;
      });
    } else {
      setPeriod((period: periodType[]) => period.filter((value, index) => index < 2));
    }
    setIsActive(!isActive);
  };
  const handleChangeChildBirthDate = (
    event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
  ) => {
    if (event.type === 'change') {
      setDateNaissance(event.target.value);
      if (event.target.value && moment(event.target.value).year() >= moment().year()) {
        let date = moment(event.target.value).add(1, 'd').format('YYYY-MM-DD');
        setPeriod([
          {
            dateDebut: moment(date).format('YYYY-MM-DD'),
            dateFin: moment(date)
              .add(birthDuration - 1, 'days')
              .format('YYYY-MM-DD'),
            error: -1,
          },
          {
            dateDebut: moment(date).add(birthDuration, 'days').format('YYYY-MM-DD'),
            dateFin: moment(date)
              .add(preAbsence - 1, 'days')
              .format('YYYY-MM-DD'),
            error: -1,
          },
        ]);
      } else {
        setPeriod([
          {
            dateDebut: '',
            dateFin: '',
            error: -1,
          },
        ]);
      }
      if (
        moment(event.target.value).isAfter(moment()) ||
        moment().format('YYYY-MM-DD') === event.target.value ||
        !event.target.value
      ) {
        setError('');
      } else {
        setError('La date de naissance doit être supérieur ou égale à la date du jour');
      }
    } else {
      if (event.target.value) {
        setError('');
      }
      if (moment(event.target.value).isBefore(moment()) && moment().format('YYYY-MM-DD') !== event.target.value) {
        setError('La date de naissance doit être supérieur ou égale à la date du jour');
      }
    }
  };

  const handleChangePeriodDateStart = (
    event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.type === 'change') {
      setPeriod((period) =>
        period.map((val, ind) => {
          if (ind === index) {
            val.dateDebut = event.target.value;
            if (event.target.value && moment(val.dateFin).diff(moment(event.target.value), 'days') < 4) {
              val.error = 'Vous ne pouvez pas prendre un congé inférieur à 5 jours';
            } else if (
              event.target.value &&
              moment(event.target.value).isAfter(moment(dateNaissance).add(6, 'months'))
            ) {
              val.error =
                'La date de début de nouvelle période doit être avant le ' +
                moment(dateNaissance).add(6, 'months').format('DD/MM/YYYY');
            } else if (
              index !== 0 &&
              event.target.value &&
              moment(event.target.value).isSameOrBefore(moment(period[index - 1].dateFin))
            ) {
              val.error =
                'La date de début de nouvelle période doit être supérieure à la date de fin de période précédente';
            } else if (event.target.value && moment(event.target.value).isSameOrBefore(moment(period[1].dateFin))) {
              val.error = 'La période doit débuter après les 7 jours de congé obligatoires';
            } else if (event.target.value && moment(event.target.value).isBefore(moment())) {
              val.error = 'La date de début doit être supérieur ou égale à la date du jour';
            } else if (val.dateFin && event.target.value && moment(event.target.value).isAfter(moment(val.dateFin))) {
              val.error = 'La date de fin doit être supérieur ou égale à la date de début';
            } else {
              val.error = '';
            }
          } else if (period.length > 2 && ind === period.length - 1) {
            const ad = getRestDate(postAbsence, period, index);
            val.dateFin = event.target.value
              ? moment(event.target.value)
                  .add(ad < 0 ? 0 : ad, 'd')
                  .format('YYYY-MM-DD')
              : '';
          }
          return val;
        }),
      );
    } else {
      setPeriod(
        period.map((val, ind) => {
          if (ind === index) {
            if (event.target.value && moment(val.dateFin).diff(moment(event.target.value), 'days') < 4) {
              val.error = 'Vous ne pouvez pas prendre un congé inférieur à 5 jours';
            } else if (
              event.target.value &&
              moment(event.target.value).isAfter(moment(dateNaissance).add(6, 'months'))
            ) {
              val.error =
                'La date de début de nouvelle période doit être avant le ' +
                moment(dateNaissance).add(6, 'months').format('DD/MM/YYYY');
            } else if (
              index !== 0 &&
              event.target.value &&
              moment(event.target.value).isSameOrBefore(moment(period[index - 1].dateFin))
            ) {
              val.error =
                'La date de début de nouvelle période doit être supérieure à la date de fin de période précédente';
            } else if (event.target.value && moment(event.target.value).isSameOrBefore(moment(period[1].dateFin))) {
              val.error = 'La période doit débuter après les 7 jours de congé obligatores';
            } else if (event.target.value && moment(event.target.value).isBefore(moment())) {
              val.error = 'La date de début doit être supérieur ou égale à la date du jour';
            } else if (event.target.value && val.dateFin && moment(event.target.value).isAfter(moment(val.dateFin))) {
              val.error = 'La date de fin doit être supérieur ou égale à la date de début';
            } else {
              val.error = '';
            }
          }
          return val;
        }),
      );
    }
  };

  const handleChangePeriodDateEnd = (
    event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.type === 'change') {
      setPeriod((period) =>
        period.map((val, ind) => {
          if (ind === index) {
            val.dateFin = event.target.value;
            if (event.target.value && moment(event.target.value).isBefore(moment(period[1].dateFin))) {
              val.error = 'Attention, vous devez respecter les 7 jours de congés obligatores';
            } else if (event.target.value && moment(event.target.value).diff(moment(val.dateDebut), 'days') < 4) {
              val.error = 'Vous ne pouvez pas prendre un congé inférieur à 5 jours';
            } else if (
              index > 0 &&
              index < period.length - 1 &&
              event.target.value &&
              (moment(event.target.value).isAfter(moment(period[index + 1].dateDebut)) ||
                moment(event.target.value).format('YYYY-MM-DD') === period[index + 1].dateDebut)
            ) {
              val.error =
                'La date de début de nouvelle période doit être supérieure à la date de fin de période précédente';
            } else {
              val.error = '';
            }
          } else if (period.length > 2 && ind === period.length - 1) {
            const ad = getRestDate(postAbsence, period, index);
            val.dateFin = event.target.value
              ? moment(event.target.value)
                  .add(ad < 0 ? 0 : ad, 'd')
                  .format('YYYY-MM-DD')
              : '';
          }
          return val;
        }),
      );
    } else {
      setPeriod(
        period.map((val, ind) => {
          if (ind === index) {
            if (event.target.value && moment(event.target.value).isBefore(moment(period[1].dateFin))) {
              val.error = 'Attention, vous devez respecter les 7 jours de congés obligatores';
            } else if (event.target.value && moment(event.target.value).diff(moment(val.dateDebut), 'days') < 4) {
              val.error = 'Vous ne pouvez pas prendre un congé inférieur à 5 jours';
            } else if (
              index > 0 &&
              index < period.length - 1 &&
              event.target.value &&
              (moment(event.target.value).isAfter(moment(period[index + 1].dateDebut)) ||
                moment(event.target.value).format('YYYY-MM-DD') === period[index + 1].dateDebut)
            ) {
              val.error =
                'La date de début de nouvelle période doit être supérieure à la date de fin de période précédente';
            } else {
              val.error = '';
            }
          }
          return val;
        }),
      );
    }
  };

  const addMoreDate = () => {
    const date_debut = period[period.length - 1].dateFin
      ? moment(period[period.length - 1].dateFin)
          .add(1, 'd')
          .format('YYYY-MM-DD')
      : '';
    const resDate = getRestDate(postAbsence, period, period.length);
    const ad =
      period.slice(2).filter((value) => !value.dateDebut || !value.dateFin || (value.error !== -1 && value.error))
        .length === 0 && resDate > 0
        ? resDate
        : 0;
    let err = '';
    if (ad < 4) {
      err = 'Vous ne pouvez pas prendre un congé inférieur à 5 jours';
    }
    const date_fin = date_debut ? moment(date_debut).add(ad, 'days').format('YYYY-MM-DD') : '';
    setPeriod([...period, { dateDebut: date_debut, dateFin: date_fin, error: err }]);
  };

  const deleteDate = (index: number) => {
    setPeriod((period) =>
      period
        .filter((val, ind) => ind !== index)
        .map((val, ind) => {
          if (ind === index - 1) {
            val.dateFin = val.dateDebut
              ? moment(val.dateDebut)
                  .add(postAbsence - 1, 'days')
                  .format('YYYY-MM-DD')
              : '';
          }
          return val;
        }),
    );
  };

  const handleOnClick = () => {
    navigate('/declaration-document', {
      state: {
        document: {
          ...document,
          period: period,
          dateNaissance: dateNaissance,
          isLastDayWork: null,
          isLinkHospi: null,
        },
      },
    });
  };

  const label = document.absenceType.value;
  const label1 = document.absenceType1.value;
  const label2 = document.absenceType2.value;

  useEffect(() => {
    if (user) {
      getParamsBusiness(label, label1, label2, postAbsence, birthDuration, preAbsence).then((value) => {
        setPostAbsence(value.postAbsence);
        setBirthDuration(value.birthDuration);
        setPreAbsence(value.preAbsence);
      });
    }
  }, [user]);

  useEffect(() => {
    if (getRestDate(postAbsence, period, period.length) + 1 < 0) {
      setError("Votre période d'arrêt a surpassé la limite");
    } else {
      setError('');
      setPeriod((period) =>
        period.map((value, index) => {
          if (moment(value.dateFin).diff(moment(value.dateDebut), 'days') < 4 && index !== 0 && index !== 1) {
            value.error = 'Vous ne pouvez pas prendre un congé inférieur à 5 jours';
          }
          return value;
        }),
      );
    }
  }, [dateNaissance]);

  useEffect(() => {
    if (getRestDate(postAbsence, period, period.length) + 1 < 0) {
      setError("Votre période d'arrêt a surpassé la limite");
    } else {
      if (period.length > 2 && getRestDate(postAbsence, period, period.length) + 1 > 0) {
        setError('Il faut que vous prennez les jours restants');
      } else if (
        period.filter(
          (value, index) =>
            index !== 0 && index !== 1 && moment(value.dateDebut).isSameOrBefore(moment(period[index - 1].dateFin)),
        ).length > 0
      ) {
        setError('La date de début de nouvelle période doit être supérieure à la date de fin de période précédente');
      } else {
        setError('');
      }
    }
  }, [period]);

  return (
    <div className="container-fluid">
      <h3 className="question-form mt-5 mb-5 text-center">
        Comment s'organise votre congé de parentalité et d’accueil de l’enfant ?
      </h3>
      <div className="mb-1 col-lg-4">
        <label className="col-12">
          Date de naissance prévue
          <input
            className="form-control form-date"
            name="dateNaissance"
            type="date"
            placeholder="Date de naissance"
            value={dateNaissance}
            onChange={handleChangeChildBirthDate}
            onBlur={handleChangeChildBirthDate}
            min={moment().format('YYYY-MM-DD')}
            required
          />
        </label>
      </div>
      <h4 className="question-form mb-3 text-center">
        Nombre de jour total : {birthDuration} + {preAbsence - birthDuration} + {postAbsence} jours&nbsp;
        <FontAwesomeIcon
          className="fa-1x modal-info"
          icon={faCircleInfo}
          onClick={() => {
            setModalIsOpen(!modalIsOpen);
          }}
        />
      </h4>
      {dateNaissance && (
        <div className="col-lg-10 col-12 planning-paternite text-center mb-5">
          <div className="dates-pater dates-pater-sup">
            <div className="mb-2 col-lg-10 col-12">
              <p>
                <strong>{preAbsence} jours</strong> de congés à la naissance à partir du prochain jour ouvré{' '}
                <strong></strong>
              </p>
              {period.length >= 2 ? (
                <div className="container-form">
                  <label className="date-item">
                    <p className="font-p-pat-label">Date de début du congé </p>
                    <input
                      className="form-control form-date font-p-pat"
                      name="dateDebutC"
                      type="text"
                      placeholder="Date de début"
                      value={moment(period[0].dateDebut).format('DD/MM/YYYY')}
                      disabled
                    />
                  </label>

                  <label className="date-item">
                    <p className="font-p-pat-label">Date de fin du congé</p>
                    <input
                      className="form-control form-date font-p-pat"
                      name="dateFinC"
                      type="text"
                      placeholder="Date de fin"
                      value={moment(period[1].dateFin).format('DD/MM/YYYY')}
                      disabled
                    />
                  </label>
                </div>
              ) : (
                <></>
              )}
              <div>
                <button
                  type="button"
                  className="btn btn-kerij-outline btn-sm"
                  style={{
                    marginBottom: '2%',
                    fontSize: '1em',
                  }}
                  onClick={showDate}
                >
                  {isActive ? 'Supprimer' : 'Ajouter'}
                </button>
              </div>
            </div>
          </div>

          {period.map((value: periodType, index: number) => {
            if (index === 0 || index === 1) return <div key={index} />;
            return (
              <div
                className={
                  isActive ? 'dates-pater dates-pater-sup col-12' : 'dates-pater dates-pater-sup col-12 d-none'
                }
                key={index}
              >
                <form className="mb-2 col-lg-12 col-12">
                  <div className="container-form">
                    <label className="date-item">
                      {/* <p className="font-p-pat-label">1er jour de l'arrêt de travail</p> */}
                      <p className="font-p-pat-label">Date début de {getOrdinalNumber(index + 1)} période </p>
                      <input
                        className="form-control form-date font-p-pat-date"
                        name="dateDebut"
                        type="date"
                        placeholder="Date de début"
                        value={value.dateDebut}
                        onChange={(event) => handleChangePeriodDateStart(event, index)}
                        onBlur={(event) => handleChangePeriodDateStart(event, index)}
                        min={
                          index === 0
                            ? moment(dateNaissance).format('YYYY-MM-DD')
                            : moment(period[index - 1].dateFin)
                                .add(1, 'd')
                                .format('YYYY-MM-DD')
                        }
                        required
                      />
                    </label>

                    <label className="date-item">
                      {/* <p className="font-p-pat-label"> Date de fin de l'absence</p> */}
                      <p className="font-p-pat-label">Date de fin de {getOrdinalNumber(index - 1)} période </p>
                      <input
                        className="form-control form-date font-p-pat-date"
                        name="dateFin"
                        type="date"
                        placeholder="Date de fin"
                        value={value.dateFin}
                        min={moment(value.dateDebut).add(4, 'days').format('YYYY-MM-DD')}
                        onChange={(event) => handleChangePeriodDateEnd(event, index)}
                        onBlur={(event) => handleChangePeriodDateEnd(event, index)}
                        required
                      />
                    </label>
                  </div>
                  {value.error && value.error !== -1 ? (
                    <KerijBox message={value.error} type={KerijBoxType.Error} />
                  ) : (
                    <></>
                  )}
                </form>
                {index > 1 && period.length > 3 ? (
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="date-item"
                    style={{
                      cursor: 'pointer',
                      width: '5%',
                      height: '1rem',
                      marginBottom: value.error !== -1 && value.error ? '5%' : '2%',
                    }}
                    onClick={deleteDate.bind(this, index)}
                  ></FontAwesomeIcon>
                ) : (
                  <div style={{ height: '20%', width: '5%' }}></div>
                )}
              </div>
            );
          })}
          <div>Jours restant : {getRestDate(postAbsence, period, period.length) + 1} jours</div>
        </div>
      )}
      {error && error !== -1 && <KerijBox message={error} type={KerijBoxType.Error} />}
      {dateNaissance &&
      period.length > 2 &&
      period.length < 5 &&
      getRestDate(postAbsence, period, period.length) > 0 ? (
        <button
          type="button"
          className="btn btn-kerij-outline btn-sm"
          style={{ marginBottom: '2%', fontSize: '1em' }}
          onClick={addMoreDate}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> &nbsp; Ajouter
        </button>
      ) : (
        <></>
      )}

      <Spacer size="medium" />
      <Button
        color="primary"
        type="submit"
        disabled={
          !(
            period.length > 2 &&
            !error &&
            period.filter((value) => !value.dateDebut || !value.dateFin || (value.error !== -1 && value.error))
              .length === 0 &&
            dateNaissance
          )
        }
        onClick={handleOnClick}
      >
        Suivant
      </Button>
      <Spacer />
      <Button
        color="secondary"
        onClick={() => {
          if (
            document.absenceType.value + document.absenceType1.value === '41' ||
            document.absenceType.value + document.absenceType1.value === '42'
          ) {
            navigate('/declaration-type-1', {
              state: location.state,
            });
          } else {
            navigate('/declaration-type-2', {
              state: location.state,
            });
          }
        }}
      >
        Précédent
      </Button>

      <Modal
        style={customStyles as Styles}
        className="Modal"
        overlayClassName="Overlay"
        closeTimeoutMS={200}
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(!modalIsOpen);
        }}
        ariaHideApp={false}
      >
        <div className="float-left">
          <button
            onClick={() => {
              setModalIsOpen(!modalIsOpen);
            }}
            className="btn-close exit-modal"
          ></button>
        </div>
        <h3 className="question-form text-center titre-modal">Que comprend votre congé parentalité ?</h3>
        <div className="text-center text-modal mt-3">
          <p>Différentes périodes obligatoires ou non composent votre congé parental.</p>
          Les 3 jours supplémentaires correspondent au congé de naissance. Celui-ci commence, au choix du salarié, le
          jour de la naissance de l’enfant ou le 1er jour ouvrable qui suit la naissance.
          <br /> <br />
          Votre congé comporte 2 périodes distinctes en plus du congé de naissance
          <br /> <br />
          La 1ère période obligatoire de 4 jours calendaires prises immédiatement après le congé de naissance
          <br />
          <br />
          La 2nde période non obligatoire composée de <strong>{postAbsence} jours</strong> de congés consécutifs ou non
          dans les 6 mois qui suivent la naissance.
          <br />
          <br />
          Attention pour la 2nde période, si vous choisissez de prendre des congés non consécutifs (maximum 2 périodes
          distinctes) chacune des périodes doit être d'une durée minimale de 5 jours.
          <br />
          <br />
          <strong>*</strong> Les jours ouvrables sont tous les jours de la semaine, sauf le jour de repos hebdomadaire,
          en principe le dimanche, et les jours féries non travaillés dans l’entreprise.
        </div>
        <div className="text-center"></div>
      </Modal>
    </div>
  );
};
