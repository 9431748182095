import CustomModal from '../../../../base/CustomModal';
import React from 'react';
import { Box } from '@mui/material';
import axios from '../../../../../lib/axios';
import { useError } from '@/shared/useError';
import { addNoteAbsenceOption } from '../api';
import { StatusCodes } from 'http-status-codes';

interface IWorkingTimeModalProps {
  noteAbsenceOptions: any;
  setNoteAbsenceOptions: (value: any) => void;
  listSelectBusiness?: any[];
  selectedIdBusiness?: string | null;
  setModalIsOpenNoteReduction?: any;
  getAbsenceOptions?: any;
  listSelectCompany?: any[];

  modalIsOpenNoteReduction: boolean;
}

const WorkingTimeModal = ({
  noteAbsenceOptions,
  setNoteAbsenceOptions,
  listSelectBusiness,
  selectedIdBusiness,
  setModalIsOpenNoteReduction,
  getAbsenceOptions,
  listSelectCompany,
  modalIsOpenNoteReduction,
}: IWorkingTimeModalProps) => {
  return (
    <CustomModal
      open={modalIsOpenNoteReduction || false}
      setOpen={setModalIsOpenNoteReduction}
      contentProps={{
        style: { paddingLeft: '4rem', paddingRight: '4rem' },
      }}
      renderContent={() => (
        <Content
          noteAbsenceOptions={noteAbsenceOptions}
          setNoteAbsenceOptions={setNoteAbsenceOptions}
          listSelectBusiness={listSelectBusiness}
          selectedIdBusiness={selectedIdBusiness}
          getAbsenceOptions={getAbsenceOptions}
          setModalIsOpenNoteReduction={setModalIsOpenNoteReduction}
          listSelectCompany={listSelectCompany}
        />
      )}
    />
  );
};

interface IContentProps {
  noteAbsenceOptions: any;
  setNoteAbsenceOptions: (value: any) => void;
  listSelectBusiness?: any[];
  selectedIdBusiness?: string | null;
  setModalIsOpenNoteReduction?: any;
  getAbsenceOptions?: any;
  listSelectCompany?: any[];
}

const Content = ({
  noteAbsenceOptions,
  setNoteAbsenceOptions,
  listSelectBusiness,
  selectedIdBusiness,
  setModalIsOpenNoteReduction,
  getAbsenceOptions,
  listSelectCompany,
}: IContentProps) => {
  const { error, triggerError, clearError } = useError();

  const [newNoteAbsenceOptions, setNewNoteAbsenceOptions] = React.useState<any>(noteAbsenceOptions);

  const handleSubmit = async () => {
    const idBusiness =
      listSelectBusiness && listSelectBusiness?.filter((value: any) => value.isChecked).length > 0
        ? listSelectBusiness.filter((value: any) => value.isChecked).at(0).id
        : selectedIdBusiness;

    const res = await addNoteAbsenceOption({
      idBusiness: idBusiness,
      notePromo: newNoteAbsenceOptions.notePromo,
      noteReduction: newNoteAbsenceOptions.noteReduction,
    });
    if (res.status === StatusCodes.OK) {
      setNoteAbsenceOptions((prev: any) => ({
        ...prev,
        noteReduction: newNoteAbsenceOptions.noteReduction,
      }));
      getAbsenceOptions(listSelectBusiness, listSelectCompany, null);
      setModalIsOpenNoteReduction((state: any) => !state);
    } else {
      triggerError();
    }
  };

  return (
    <Box className="container-fluid col-12">
      <h4>Ajouter votre commentaire</h4>
      <textarea
        defaultValue={noteAbsenceOptions.noteReduction}
        onChange={(e) => {
          setNewNoteAbsenceOptions((prev: any) => ({
            ...prev,
            noteReduction: e.target.value,
          }));
        }}
        maxLength={256}
        style={{ resize: 'none', width: '100%' }}
        rows={5}
      />
      <button
        className="mt-3 btn-kerij"
        onClick={() => {
          handleSubmit();
        }}
      >
        Valider
      </button>
    </Box>
  );
};

export default WorkingTimeModal;
