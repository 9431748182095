export const customStyles = {
  content: {
    border: '0',
    borderRadius: '10px',
    bottom: 'auto',
    minHeight: '10rem',
    left: '50%',
    padding: '2rem',
    position: 'fixed',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    height: 'auto',
    boxShadow: '1px 2px 12px #000000',
  },
};

export const customStylesExtractionFichier = {
  content: {
    border: '0',
    borderRadius: '10px',
    bottom: 'auto',
    minHeight: '10rem',
    left: '50%',
    padding: '2rem',
    position: 'fixed',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    minWidth: '20rem',
    width: '100%',
    height: '80%',
    maxWidth: '60rem',
    boxShadow: '1px 2px 12px #000000',
    overflowY: 'scroll',
  },
};

export const customDeleteAction = {
  content: {
    border: '0',
    borderRadius: '10px',
    bottom: 'auto',
    minHeight: '10rem',
    left: '50%',
    padding: '2rem',
    position: 'fixed',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    minWidth: '20rem',
    width: '80%',
    maxWidth: '60rem',
  },
};
