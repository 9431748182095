import moment from 'moment';
import { fetchRhAbsencesHistories, fetchRhAction } from '../../services/rh';
import _ from 'lodash';

export const getDataAbsences = async ({
  dateDebut,
  dateFin,
  search,
  listStatutArret,
  listTypeArret,
  order,
  selectedIdBusiness,
  offset,
  limit,
  setData,
  setLength,
  setLoading,
  isLoadmore,
  setIsLoadmore,
}) => {
  if (
    (!dateDebut || /\d{4}-\d{2}-\d{2}/g.test(dateDebut)) &&
    (!dateFin || /\d{4}-\d{2}-\d{2}/g.test(dateFin)) &&
    selectedIdBusiness
  ) {
    if (
      /\d{4}-\d{2}-\d{2}/g.test(dateDebut) &&
      /\d{4}-\d{2}-\d{2}/g.test(dateFin) &&
      moment(dateDebut, 'YYYY-MM-DD').isSameOrAfter(moment(dateFin, 'YYYY-MM-DD'))
    ) {
      setData([]);
      setLoading && setLoading(false);
    } else {
      setLoading && setLoading(true);
      const response = await fetchRhAbsencesHistories({
        dateStart: dateDebut,
        dateEnd: dateFin,
        search: search,
        status: JSON.stringify(listStatutArret),
        type: JSON.stringify(listTypeArret),
        order: order,
        offset: offset,
        limit: limit,
      });
      if (response) {
        setData((prev) => {
          let result = null;
          const employees = response?.data?.rows;
          if (isLoadmore) {
            result = prev?.concat(employees);
            setIsLoadmore(false);
          } else {
            result = employees;
          }

          return _.uniqBy(Object.values(result), function (e) {
            return e.id;
          });
        });
        setLength(response?.data?.count);
      }
      setLoading && setLoading(false);
    }
  }
};

export const getDataActions = async ({
  dateDebut,
  dateFin,
  search,
  listSelectBusiness,
  selectedIdBusiness,
  offset,
  limit,
}) => {
  if (
    (!dateDebut || /\d{4}-\d{2}-\d{2}/g.test(dateDebut)) &&
    (!dateFin || /\d{4}-\d{2}-\d{2}/g.test(dateFin)) &&
    selectedIdBusiness
  ) {
    if (
      /\d{4}-\d{2}-\d{2}/g.test(dateDebut) &&
      /\d{4}-\d{2}-\d{2}/g.test(dateFin) &&
      moment(dateDebut, 'YYYY-MM-DD').isSameOrAfter(moment(dateFin, 'YYYY-MM-DD'))
    ) {
      return [];
    } else {
      const response = await fetchRhAction({
        search: search,
        idBusinesses: JSON.stringify(listSelectBusiness.filter((value) => value.isChecked).map((value) => value.id)),
        idBusiness: selectedIdBusiness,
        offset: offset,
        limit: limit,
      });

      return response.data;
    }
  }
};
