import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChoixFin = () => {
  const navigate = useNavigate();
  return (
    <div
      className="row col-12 mb-5 text-center"
      style={{
        marginTop: '35px',
        padding: '25px',
        fontSize: '1.2em',
      }}
    >
      <h3 className="titre-rh titre-details">Aucun créneau ne vous convient</h3>

      <div style={{ marginTop: '45px' }}>
        <FontAwesomeIcon icon={'fa-solid fa-check' as any} style={{ fontSize: '3em', color: '#40C9C9' }} />
        <h6>Merci</h6>
        <p>Votre manager ou contact de référence a été informé(e) de votre demande de nouveaux créneaux</p>
      </div>

      <div>
        <button
          type="button"
          className="btn btn-kerij col-6 col-lg-3"
          style={{ marginTop: '45px' }}
          onClick={() => navigate('/')}
        >
          {`Retour à l'accueil`}
        </button>
      </div>
    </div>
  );
};

export default ChoixFin;
