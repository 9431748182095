import React, { useEffect } from 'react';
import { Step, Stepper } from '@mui/material';
import { EmployeeInformation } from '../../../types/user.interface';
import { AbsencesFormItem } from './AbsencesFormItem';

export interface AbsenceFormStep {
  label: string;
  description: JSX.Element;
  optionsLabel?: () => JSX.Element | null;
}

export interface AbsencesFormProps {
  employee: EmployeeInformation | null;
  steps: AbsenceFormStep[];
  submitStep: number;
  isSubmit: boolean;
  onSubmit: () => void;
  onBackToFirst: () => void;
}

export const AbsencesForm = ({ employee, steps, submitStep, isSubmit, onSubmit, onBackToFirst }: AbsencesFormProps) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = async () => {
    if (activeStep === submitStep && employee) await onSubmit();
    else setActiveStep((previousActiveStep) => previousActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) onBackToFirst();
    setActiveStep((previousActiveStep) => previousActiveStep - 1);
  };

  useEffect(() => {
    if (!employee) setActiveStep(0);
  }, [employee]);

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
        <Step key={step.label}>
          <AbsencesFormItem
            size={steps.length}
            isSubmit={isSubmit}
            step={{
              ...step,
              index: index,
              option: step.optionsLabel?.(),
            }}
            disabledConfirm={index === 0 && !employee}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </Step>
      ))}
    </Stepper>
  );
};
