export { default as addictions } from './assets/service-addictions.svg';
export { default as aidants } from './assets/service-aidants.svg';
export { default as coordonnees } from './assets/service-coordonnees.svg';
export { default as deces } from './assets/service-findevie.svg';
export { default as handicap } from './assets/service-handicap.svg';
export { default as harcelement } from './assets/service-hospitalisation.svg';
export { default as hospitalisation } from './assets/service-hospitalisation.svg';
export { default as chronique } from './assets/service-parentalite.svg';
export { default as parentalite } from './assets/service-parentalite.svg';
export { default as psychologie } from './assets/service-psychologie.svg';
export { default as retour } from './assets/service-retouremploi.svg';
export { default as sante } from './assets/service-sante.svg';
export { default as vieQuotidienne } from './assets/service-vieQuotidienne.svg';
export { default as tms } from './assets/service-tms.svg';
export { default as tous } from './assets/service-tous.svg';
