import axios from '../../../lib/axios';

export const getNoteAbsenceParams = async (
  idBusiness?: string,
): Promise<{ notePromo: string; noteReduction: string }> => {
  return await axios
    .get('/api/business/employee/get_note_absence_options_params', {
      params: {
        idBusiness,
      },
    })
    .then((value) => {
      return {
        notePromo: value.data.noteAbsenceOption?.note_promo ?? '',
        noteReduction: value.data.noteAbsenceOption?.note_reduction ?? '',
      };
    })
    .catch((err) => {
      throw new Error();
    });
};
