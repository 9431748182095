export const ParcoursRuleComparableKey = {
  AbsenceDateStart: '$absence.date_start$',
  AbsenceDateEnd: '$absence.date_end$',
  AbsenceTotalDuration: 'absence_total_duration',
  AbsenceParentDuration: 'absence_parent_duration',
  AbsenceCount: 'absence_count',
} as const;
export type ParcoursRuleComparableKey = (typeof ParcoursRuleComparableKey)[keyof typeof ParcoursRuleComparableKey];
export const isParcoursRuleComparableKey = (value: unknown): value is ParcoursRuleComparableKey =>
  value != null && Object.values(ParcoursRuleComparableKey).includes(value as any);

export const ParcoursRuleIdentifiableKey = {
  InternalCodeAbsence: 'absence_code',
} as const;
export type ParcoursRuleIdentifiableKey =
  (typeof ParcoursRuleIdentifiableKey)[keyof typeof ParcoursRuleIdentifiableKey];
export const isParcoursRuleIdentifiableKey = (value: unknown): value is ParcoursRuleIdentifiableKey =>
  value != null && Object.values(ParcoursRuleIdentifiableKey).includes(value as any);

export const ParcoursRuleKey = {
  ...ParcoursRuleComparableKey,
  ...ParcoursRuleIdentifiableKey,
} as const;
export type ParcoursRuleKey = (typeof ParcoursRuleKey)[keyof typeof ParcoursRuleKey];
export const isParcoursRuleKey = (value: unknown): value is ParcoursRuleKey =>
  value != null && Object.values(ParcoursRuleKey).includes(value as any);

export const ParcoursRuleKeyLabel = {
  [ParcoursRuleKey.AbsenceDateStart]: "Date de début de l'absence",
  [ParcoursRuleKey.AbsenceDateEnd]: "Date de fin de l'absence",
  [ParcoursRuleKey.AbsenceTotalDuration]: 'Durée d’absence',
  [ParcoursRuleKey.AbsenceParentDuration]: 'Durée d’absence continue',
  [ParcoursRuleKey.AbsenceCount]: "Nombre total d'absences",
  [ParcoursRuleKey.InternalCodeAbsence]: 'Nature d’absence',
} as const;
