import React from 'react';
import { Absence } from '../../../../../../types/absences.type';
import CustomModal from '../../../../../base/CustomModal';
import { AbsenceDetails, AbsenceDetailsLogs } from '../AbsenceDetails';
import { DocumentIcon, ExportIcon, LinkIcon } from '../../../../Icons';
import { AbsenceCardHeader } from '@/components/DashboardRH/AbsenceCard';
import { Card } from '@mui/material';
import { IconButton } from '@/components/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ListCardActions, ListCardContent } from '@/components/DashboardRH/ListCard';

interface CardAbsenceHistoryDetailModalProperties {
  absence: Absence;
  isOpen: boolean;
  onClose: () => void;
}

export const CardAbsenceHistoryModal = ({ absence, isOpen, onClose }: CardAbsenceHistoryDetailModalProperties) => {
  return (
    <CustomModal open={isOpen} onClose={onClose}>
      <Card className={'col-12'}>
        {absence.employee_information && (
          <>
            <AbsenceCardHeader
              employeeInformation={absence.employee_information}
              absence={absence}
              secondaryAction={
                <>
                  <DocumentIcon absence={absence} />
                  <LinkIcon absence={absence} />
                  <ExportIcon absence={absence} />
                  <IconButton icon={<CloseIcon sx={{ color: 'white' }} />} onClick={onClose} />
                </>
              }
              hideEmployeeActions
            />

            <ListCardContent>
              <AbsenceDetails absence={absence} />
            </ListCardContent>

            <ListCardActions>
              <AbsenceDetailsLogs absence={absence} />
            </ListCardActions>
          </>
        )}
      </Card>
    </CustomModal>
  );
};
