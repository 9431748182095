import type { TabListProps } from '@mui/lab';
import * as React from 'react';

export interface UseMUITabControllerParameters<T> {
  initialValue: T;
}

export function useMUITabController<T extends string | number>(parameters: UseMUITabControllerParameters<T>) {
  const [value, setValue] = React.useState(parameters.initialValue);

  return {
    control: {
      value,
      setValue,
    },
    tabContextProps: {
      value,
    },
    tabListProps: {
      onChange: (_: any, value: T) => setValue(value) as TabListProps['onChange'],
    },
  };
}
