import React from 'react';
import { Absence } from '@/types/absences.type';
import { getFullName } from '@/shared/utils/getFullName.service';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { WarningVerificationRow } from './WarningVerificationRow';
import { OCRAPIResponse } from '@/types';
import { toISODate } from '@/utils/date.utils';
import { ItemTypes, WarningItems, defineFields } from './defineFields';
import { WarningBoolean } from './data';

export interface WarningVerificationProps {
  absence: Absence;
  ocrResult: OCRAPIResponse;
  onInformationChanged: (infos: WarningItems) => void;
}

export const WarningVerification = ({ absence, ocrResult, onInformationChanged }: WarningVerificationProps) => {
  /**
   * Tous les champs du formulaire sont ajouté via cette fonction
   */
  const initialValueData = defineFields(ocrResult, absence);
  const [valueData, setValueData] = React.useState<WarningItems>(initialValueData);
  const absenceLabel = absence.type_absence.description_label;

  const handleDataChange = (value: string | boolean | null, type: string) => {
    const updatedValueData = valueData;

    const valueDateStart = updatedValueData[ItemTypes.DateStart];
    if (type === ItemTypes.DateStart && valueDateStart != null && typeof value === 'string')
      updatedValueData[ItemTypes.DateStart] = {
        ...valueDateStart,
        confirmed: toISODate(value),
        isValid: true,
        isDisabled: false,
      };

    const valueDateEnd = updatedValueData[ItemTypes.DateEnd];
    if (type === ItemTypes.DateEnd && valueDateEnd != null && typeof value === 'string')
      updatedValueData[ItemTypes.DateEnd] = {
        ...valueDateEnd,
        confirmed: toISODate(value),
        isValid: true,
        isDisabled: false,
      };

    const valueWork = updatedValueData[ItemTypes.RelatedWork];
    if (type === ItemTypes.RelatedWork && valueWork != null)
      updatedValueData[ItemTypes.RelatedWork] = {
        ...valueWork,
        confirmed: value ? WarningBoolean.True : WarningBoolean.False,
        isValid: true,
      };

    const valuePregnancy = updatedValueData[ItemTypes.Pregnancy];
    if (type === ItemTypes.Pregnancy && valuePregnancy != null)
      updatedValueData[ItemTypes.Pregnancy] = {
        ...valuePregnancy,
        confirmed: value ? WarningBoolean.True : WarningBoolean.False,
        isValid: true,
      };

    setValueData(updatedValueData);
    onInformationChanged(updatedValueData);
  };

  return (
    <div className="warning-verification" style={{ alignItems: 'flex-start' }}>
      <div className="row">
        <p className="text-left">
          <span style={{ fontWeight: 'bold' }}>
            <span style={{ fontFamily: 'Montserrat' }}>{`Nature de l'absence :`}</span> {absenceLabel}
          </span>
        </p>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <span style={{ fontFamily: 'Montserrat' }}>
                {absence.employee_information == null ? '' : getFullName(absence.employee_information)}
              </span>
            </TableCell>
            <TableCell>
              <span style={{ fontFamily: 'Montserrat' }}>Scan KERij</span>
            </TableCell>
            <TableCell className="text-center">
              <span style={{ fontFamily: 'Montserrat' }}>Saisie libre</span>
            </TableCell>
            <TableCell className="text-center">
              <span style={{ fontFamily: 'Montserrat' }}>Statut</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(valueData).map(([type, data]) => (
            <>
              {data != null && (
                <WarningVerificationRow
                  label={data.label}
                  type={data.type}
                  isValid={data.isValid}
                  isDisabled={data.isDisabled}
                  declared={data.data?.declared}
                  ocr={data.data?.ocr}
                  onChange={(value) => handleDataChange(value, type)}
                />
              )}
            </>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
