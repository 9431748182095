import React from 'react';
import { PolicyLayout } from '../components/layouts';
import { Link } from 'react-router-dom';
import { datesPolicies } from '../config/policies.config';

export const CookiePolicyPage: React.FC = () => {
  return (
    <PolicyLayout title={'Politique de cookies'} date={datesPolicies.cookies}>
      <ol>
        <li>
          <strong>
            <em>PREAMBULE</em>
          </strong>
          <p>
            Bienvenue sur notre page consacrée à notre « <strong>Politique cookies</strong> » pour la plateforme KERIJ
            éditée par la société WITHBOT accessible à{' '}
            <Link to={'/'}>
              <span>www.kerij.fr</span>
            </Link>{' '}
            (la « <strong>Plateforme</strong> »).
            <br />
            Nous vous remercions d’utiliser notre Plateforme et nos services qui y sont disponibles (les «
            <strong>Services</strong> »). La protection de la vie personnelle est un point capital pour nous et nous
            prenons la protection de vos données très au sérieux.
            <br />
            La société WITHBOT, société par actions simplifiée à associé unique au capital social de 100.000 €,
            immatriculée sous le numéro 880 118 419 au RCS de Paris, dont le siège social est situé au 128, rue de la
            Boétie, 75008 Paris ( « <strong>WITHBOT</strong> », « <strong>nous</strong> », « <strong>notre</strong> » ou
            « <strong>nos</strong> »),{' '}
            {`s'engage à protéger et à
                        respecter la vie privée de toute personne dont nous
                        traitons les données personnelles dans le cadre de la
                        fourniture de nos Services.`}
            <br />
            WITHBOT est le responsable du traitement des données personnelles dans le cadre des traitement effectués via
            les cookies (le « <strong>Responsable de traitement</strong> »).
            <br />
            Nous tenons absolument à vous expliquer ici comment nous traitons vos données personnelles et comment nous
            nous y prenons pour respecter votre intégrité conformément au règlement européen nᵒ 2016/679, dit règlement
            général sur la protection des données (« <strong>RGPD</strong> »), à la loi française n°78-17, dite loi
            informatique et libertés (« <strong>LIL</strong> ») et toutes lignes directrices et recommandations des
            organes et autorités compétentes, ensemble (la « <strong>Réglementation</strong> »). Nous vous encourageons
            à lire la présente Politique de confidentialité. Son utilisation devrait vous aider à prendre des décisions
            en toute connaissance de cause.
            <br />
            Pour toute question à propos de la présente Politique cookies ou d’ordre général concernant vos données
            personnelles, n’hésitez pas à nous contacter à l’adresse{' '}
            <a href="mailto:privacy@kerij.fr">privacy@kerij.fr</a>.
          </p>
        </li>
        <li>
          <strong>
            <em>QUE SONT LES COOKIES ?</em>
          </strong>
          <p>
            Les « <strong>cookies</strong>{' '}
            {`» sont de petits fichiers
                        texte, comprenant souvent des identifiants uniques, qui
                        sont envoyés par les serveurs web aux navigateurs web,
                        et qui peuvent ensuite être renvoyés au serveur chaque
                        fois que le navigateur demande une page au serveur. Les
                        cookies sont très utiles et permettent à un site
                        internet de vous reconnaître, de se connecter lorsque
                        vous visitez une page particulière, de fournir une
                        connexion sécurisée à un site internet et d'améliorer
                        votre expérience d'utilisateur en améliorant votre
                        confort de navigation et/ou en adaptant le contenu d'une
                        page à vos centres d'intérêt.`}
          </p>
        </li>
        <li>
          <strong>
            <em>COMMENT LES COOKIES SONT-ILS UTILISES ?</em>
          </strong>
          <p>
            {`Lorsqu'un consentement préalable est requis pour leur
                        utilisation, la durée de validité du consentement au
                        dépôt des cookies est de 6 mois. À l'issue de cette
                        période, nous vous demanderons à nouveau votre
                        consentement. Les cookies que nous plaçons en tant
                        qu'opérateurs de notre Plateforme sont appelés « cookies
                        internes ». Les cookies que des tiers pourraient nous
                        confier sur notre Plateforme sont appelés « cookies
                        tiers ». Les cookies tiers permettent de fournir des
                        caractéristiques ou des fonctionnalités tierces sur ou
                        via un site internet (par exemple, des contenus
                        publicitaires, interactifs et analytiques). Les
                        personnes qui créent ces cookies tiers peuvent collecter
                        certaines de vos informations personnelles, reconnaître
                        votre ordinateur à la fois lors de la visite du site
                        internet en question et lors de celle d'autres sites
                        internet. Aucun cookies tiers ne sont déposés sur la
                        Plateforme. Les types de cookies qui sont utilisés sur
                        notre Plateforme sont les suivants :`}
          </p>
          <ul>
            <li>Cookies techniques :</li>
          </ul>
          <p>
            {`Ces cookies vous permettront de vous déplacer sur notre
                        Plateforme et d'utiliser ses fonctionnalités plus
                        efficacement. Ces cookies permettent également à notre
                        Plateforme de se souvenir de votre action précédente au
                        cours de la même session de navigation. Plus précisément
                        les cookies utilisés sur notre Plateforme sont les
                        suivants :`}
          </p>
          <table>
            <tr>
              <th>Nom</th>
              <th>Catégorie</th>
            </tr>
            <tr>
              <td>Access_token_employee</td>
              <td>Technique</td>
            </tr>
            <tr>
              <td>Refresh_token_employee</td>
              <td>Technique</td>
            </tr>
            <tr>
              <td>Access_token_rh</td>
              <td>Technique</td>
            </tr>
            <tr>
              <td>Refresh_token_rh</td>
              <td>Technique</td>
            </tr>
          </table>
        </li>
        <li>
          <strong>
            <em>COORDONNEES</em>
          </strong>
          <p>
            {` Si vous avez d'autres questions ou commentaires sur
                        notre Politique de confidentialité, veuillez nous
                        contacter à l’adresse :`}{' '}
            <a href="mailto:privacy@kerij.fr">privacy@kerij.fr</a>.
          </p>
        </li>
        <li>
          <strong>
            <em>MODIFICATION DE LA PRESENTE POLITIQUE DE CONFIDENTIALITE</em>
          </strong>
          <p>
            WITHBOT a la possibilité de modifier cette Politique de confidentialité périodiquement afin de refléter
            l’évolution de nos pratiques en la matière. Lorsque nous modifions la présente Politique de confidentialité,
            nous modifions également la date de « <strong>Dernière mise à jour</strong> » située en haut la première
            page. Nous vous invitons à consulter régulièrement la présente Politique de confidentialité pour être
            informé de la façon dont WITHBOT protège vos Données personnelles.
          </p>
        </li>
      </ol>
    </PolicyLayout>
  );
};
