import * as React from 'react';
import { ListSubheader, List } from '@mui/material';
import { RHMenuItem, isGroupItemsProps } from './data';
import { LayoutMenuItem } from './LayoutMenuItem';
import { LayoutMenuGroupItems } from './LayoutMenuGroupItems';
import { IfPermission } from '../../IfPermission';
import isAuthorized from '@/hooks/isAuthorized';
import { useAppFeature } from '@/store';

export function LayoutMenu(props: { menu: RHMenuItem[] }) {
  const { menu } = props;
  const appFeature = useAppFeature();
  return (
    <>
      {menu.map((element, elementKey) =>
        element.feature && !appFeature[element.feature] ? null : (
          <>
            {element.permission == null ? (
              <PageContent element={element} key={elementKey} />
            ) : (
              <IfPermission {...element.permission}>
                <PageContent element={element} key={elementKey} />
              </IfPermission>
            )}
          </>
        ),
      )}
    </>
  );
}

function PageContent(props: { element: RHMenuItem }) {
  const { element } = props;

  return (
    <>
      {isAuthorized(window.location.pathname) && (
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <h6 className="section-title">{element.title}</h6>
            </ListSubheader>
          }
        >
          {element.items.map((item, index) =>
            isGroupItemsProps(item) ? (
              <LayoutMenuGroupItems key={index} {...item} />
            ) : (
              <LayoutMenuItem key={index} {...item} />
            ),
          )}
        </List>
      )}
    </>
  );
}
