import { DefaultLayout } from '../components/layouts';
import React from 'react';
import DepotDocumentHistorique from '../components/DashboardSalarie/DepotDocumentHistorique';

export const DepotDocumentHistoriquePage: React.FC = () => {
  return (
    <DefaultLayout>
      <DepotDocumentHistorique />
    </DefaultLayout>
  );
};
