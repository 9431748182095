import React from 'react';
import {
  Badge,
  Button,
  IconButton as MUIIconButton,
  styled,
  Tooltip,
  type BadgeProps,
  type TooltipProps,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'clsx';

const componentName = 'IconButton';
const defaultBadgeProps = {
  className: `${componentName}__Badge`,
  color: 'secondary' as const,
  variant: 'dot' as const,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  } as const,
};

export interface IconButtonProps extends React.ComponentProps<typeof Button> {
  icon: IconProp | React.ReactElement<unknown>;
  badge?: boolean;
  /**
   * Props passed to the Badge component
   */
  BadgeProps?: BadgeProps;
  /**
   * Props passed to the Tooltip component
   */
  TooltipProps?: Omit<TooltipProps, 'children'>;
}

export const IconButton = styled((props: IconButtonProps) => {
  const { className, icon, badge, BadgeProps, TooltipProps, ...otherProps } = props;
  const iconElement = React.isValidElement(icon) ? icon : <FontAwesomeIcon icon={icon}></FontAwesomeIcon>;
  const button = (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore There is a problem with tertiary, but it is never used
    <MUIIconButton className={classNames(componentName, className)} type="button" {...otherProps}>
      {badge && (
        <Badge {...defaultBadgeProps} {...BadgeProps}>
          {iconElement}
        </Badge>
      )}
      {!badge && iconElement}
    </MUIIconButton>
  );
  return TooltipProps == null ? button : <Tooltip {...TooltipProps}>{button}</Tooltip>;
})(({ theme }) => ({
  // Shape as circle or square
  aspectRatio: 1,
  '& .MuiSvgIcon-root': {
    fontSize: 'inherit !important',
  },
}));
