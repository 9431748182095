import React from 'react';
import { RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import { Absence, AbsenceContactType, isAbsenceContactType } from '@/types';

export interface DeclarationContactTypeProps {
  value: Absence['contact_type'];
  onChange: (value: Absence['contact_type']) => void;
}

export function DeclarationContactType(props: DeclarationContactTypeProps) {
  const { value, onChange } = props;

  return (
    <>
      <FormLabel>Vous souhaitez être contacté par :</FormLabel>
      <RadioGroup
        value={value}
        onChange={(event) => {
          const changedValue = event.target.value;
          if (isAbsenceContactType(changedValue)) onChange(changedValue);
        }}
      >
        <FormControlLabel value={AbsenceContactType.Manager} control={<Radio />} label="Mon manager" />
        <FormControlLabel value={AbsenceContactType.GestionnaireRh} control={<Radio />} label="Mon partenaire RH" />
        <FormControlLabel
          value={AbsenceContactType.ManagerEtGestionnaireRH}
          control={<Radio />}
          label="Mon manager & mon partenaire RH"
        />
      </RadioGroup>
    </>
  );
}
