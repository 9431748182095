import React, { ForwardedRef, ReactNode, useCallback, useState } from 'react';
import classNames from 'clsx';
import { ToastContext } from './useToast';
import { Snackbar } from './Snackbar';
import { Alert } from './Alert';
import { Message } from './Message';

const componentName = 'KRJToast';

export interface ToastProps {
  children: ReactNode;
  severity?: 'success' | 'error';
}

export const ToastProvider = React.forwardRef(function Toast(props: ToastProps, ref: ForwardedRef<HTMLDivElement>) {
  const { children } = props;

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<React.ReactElement | string>('');
  const [severity, setSeverity] = useState<ToastProps['severity']>('success');

  const present = useCallback(
    ({ severity, message }: { severity: ToastProps['severity']; message: React.ReactElement | string }) => {
      setMessage(message);
      setSeverity(severity);
      setOpen(true);
    },
    [],
  );

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  return (
    <ToastContext.Provider value={{ present }}>
      {children}
      <Snackbar
        ref={ref}
        open={open}
        onClose={handleClose}
        className={classNames(componentName)}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity={severity} onClose={handleClose}>
          <Message severity={severity}>{message}</Message>
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
});
