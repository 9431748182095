import { AddContactForm } from './AddContactForm';
import { EmployeeContactListTable } from './EmployeeContactListTable';
import React from 'react';
import { EmployeeContact } from '../../../types/user.interface';
import { Box } from '@mui/material';

interface ContentGestionContactProperties {
  listContact: Array<EmployeeContact>;
  idEmployeeInformation: string;
  idBusiness: string;
  refreshPage?: () => void;
}

export const ContentGestionContact = ({
  listContact,
  idEmployeeInformation,
  idBusiness,
  refreshPage,
}: ContentGestionContactProperties) => {
  return (
    <Box className="">
      <EmployeeContactListTable
        listContact={listContact}
        idEmployeeInformation={idEmployeeInformation}
        idBusiness={idBusiness}
        refreshPage={refreshPage}
      />
      <AddContactForm
        listContact={listContact}
        idEmployeeInformation={idEmployeeInformation}
        idBusiness={idBusiness}
        refreshPage={refreshPage}
      />
    </Box>
  );
};
