import axios from '../../../lib/axios';

export const getParamsBusiness = async (
  label: string,
  label1: string,
  label2: string,
  postAbsence: number,
  birthDuration: number,
  preAbsence: number,
  idBusiness?: string,
): Promise<{
  postAbsence: number;
  birthDuration: number;
  preAbsence: number;
}> => {
  return await axios
    .get('/api/business/date_params', {
      params: {
        label,
        label1,
        label2,
        idBusiness,
      },
    })
    .then((value) => {
      return {
        postAbsence: value.data.date?.post_absence ?? postAbsence,
        birthDuration: value.data.date?.birth_duration ?? birthDuration,
        preAbsence: value.data.date?.pre_absence ?? preAbsence,
      };
    })
    .catch((err) => {
      throw new Error();
    });
};
