import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Collapse, Typography } from '@mui/material';
import { LayoutMenuItem, LayoutMenuItemProps } from './LayoutMenuItem';

export interface LayoutMenuGroupItemsProps {
  title: string;
  items: LayoutMenuItemProps[];
}

export function LayoutMenuGroupItems(props: LayoutMenuGroupItemsProps) {
  const { title, items } = props;
  const [open, setOpen] = React.useState(false);
  const handleClick = () => setOpen(!open);

  return (
    <>
      <ListItem disablePadding onClick={handleClick}>
        <ListItemButton>
          <Typography color="primary" style={{ fontSize: '12px', paddingLeft: '1rem' }}>
            {title}
          </Typography>
          <ListItemIcon style={{ position: 'absolute', right: '0' }}>
            {open ? <ArrowDropUpIcon color="primary" /> : <ArrowDropDownIcon color="primary" />}
          </ListItemIcon>
        </ListItemButton>
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List style={{ padding: 0, paddingBottom: '0.5rem' }} component="div" disablePadding>
          {items.map((item, index) => (
            <LayoutMenuItem subItem key={index} {...item} />
          ))}
        </List>
      </Collapse>
    </>
  );
}
