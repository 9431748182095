import CustomModal from '@/components/base/CustomModal';
import DocumentPreview from './DocumentPreview';
import React, { useEffect, useMemo, useState } from 'react';
import { Absence, DocumentAbsence, DocumentStatus } from '@/types/absences.type';
import { Box, Typography } from '@mui/material';
import { acceptDocument, refuseDocument } from '@/services/rh/ocr';
import { StatusCodes } from 'http-status-codes';
import { WarningVerification, toBoolean } from './WarningVerification';
import { FailedVerification } from './FailedVerification';
import { DocumentBoxType } from '@/features/declaration/types/DocumentBoxType';
import { updateAbsence } from '@/services/rh/absences/service';
import { ItemTypes, WarningItems } from './WarningVerification/defineFields';
import { CodesAbsences } from '@/services/rh/codes/data';
import { TypesAbsences } from '@/services/rh/types/data';
import { EmployeeInformation, isOCRResult, safeParseOCRAPIResponse } from '@/types';
import { UpdateAbsenceRequestBody } from '@/services/rh/absences/data';
import { Button } from '@/components/Button';
import { useToast } from '@/components/Toast';
import { ActionButton } from '@/components/ActionButton';

export interface VerifyingFileModalProps {
  isVisible: boolean;
  employeeInformation: EmployeeInformation;
  onCloseModal: () => void;
  data?: any;
  absence: Absence;
  onDocumentUpdated: (document: DocumentAbsence) => void;
  document: DocumentAbsence;
}

const getTextAnalyse = (document: DocumentAbsence) => {
  switch (document.status) {
    case 'rejected': {
      return {
        header: 'Votre document et vos informations n’ont pas été reconnus, désolé.',
        content: 'Nous n’avons pas pu analyser votre document',
      };
    }
    case 'pending': {
      return {
        header: <span style={{ color: 'red' }}>Le document est conforme mais des informations ne coincident pas</span>,
        content: <span>Merci de sélectionner la bonne information ou de refuser le document</span>,
      };
    }
    case 'approved': {
      return {
        header: 'Votre document et vos informations ont été reconnus !',
        content: 'Vous pouvez poursuivre votre déclaration',
      };
    }
    default: {
      return {
        header: "Votre document et vos informations sont non traitées par l'OCR !",
        content: 'Vous pouvez ajouter de nouveaux documents',
      };
    }
  }
};

export const VerifyingFileModal = ({
  isVisible,
  employeeInformation,
  onCloseModal: handleCloseModal,
  absence,
  document,
  onDocumentUpdated,
}: VerifyingFileModalProps) => {
  const toast = useToast();

  const [status, setStatus] = useState<DocumentBoxType>();
  const [newValues, setNewValues] = useState<WarningItems | undefined>();

  const disableValidation = useMemo(
    () => newValues == null || Object.values(newValues).some((value) => value?.isValid === false),
    [newValues],
  );

  const ocrResult = document?.ocr_result;
  useEffect(() => {
    ocrResult &&
      setStatus(
        document.status === 'pending'
          ? DocumentBoxType.Warning
          : document.status === 'rejected'
            ? DocumentBoxType.Failed
            : document.status === 'approved'
              ? DocumentBoxType.Success
              : DocumentBoxType.Warning,
      );
  }, [ocrResult]);

  function handleRejectDocument() {
    refuseDocument({
      idDocument: document.id,
    }).then((response) => {
      if (response.status === StatusCodes.NO_CONTENT) {
        onDocumentUpdated({
          ...document,
          status: DocumentStatus.REJECTED,
        });
        toast.present({ message: 'Le justificatif a été refusé. Le salarié est informé', severity: 'success' });
        handleCloseModal();
      }
    });
  }

  function getDefaultBodyType(): Omit<UpdateAbsenceRequestBody, 'date_end' | 'date_start'> | null {
    const pregnancy = newValues?.[ItemTypes.Pregnancy]?.confirmed;
    const declarePregnancy = absence.type_absence.id === '5';

    const relatedWork = toBoolean(newValues?.[ItemTypes.RelatedWork]?.confirmed);
    const declareRelatedWork = ['2', '3', '8', '9', '10'].includes(absence.type_absence.id);

    if ((declarePregnancy && !toBoolean(pregnancy)) || (declareRelatedWork && !relatedWork)) {
      return {
        code_absence: CodesAbsences.MALADIE,
        id_type_absence: TypesAbsences.MALADIE,
        log: `Modification par la RH de la nature d’absence ${
          declarePregnancy && !toBoolean(pregnancy) ? 'Congé Patho.' : 'AT / MP'
        } → Maladie suite à analyse du justificatif`,
      };
    }

    return null;
  }

  function getBodyPregnancy(): Omit<UpdateAbsenceRequestBody, 'date_end' | 'date_start'> | null {
    const pregnancy = newValues?.[ItemTypes.Pregnancy]?.confirmed;
    const declarePregnancy = absence.type_absence.id === '5';

    if (!declarePregnancy && toBoolean(pregnancy)) {
      return {
        code_absence: CodesAbsences.MATERNITE_PATHO,
        id_type_absence: TypesAbsences.MATERNITE_PATHO,
        log: 'Modification par la RH de la nature d’absence Maladie → Congé Patho. suite à analyse du justificatif',
      };
    }

    return null;
  }

  function getBodyRelatedWork(): Omit<UpdateAbsenceRequestBody, 'date_end' | 'date_start'> | null {
    const relatedWork = toBoolean(newValues?.[ItemTypes.RelatedWork]?.confirmed);
    const declareRelatedWork = ['2', '3', '8', '9', '10'].includes(absence.type_absence.id);

    if (!declareRelatedWork && relatedWork) {
      return {
        code_absence: CodesAbsences.MALADIE_1,
        id_type_absence: TypesAbsences.MALADIE_1,
        log: 'Modification par la RH de la nature d’absence Maladie → AT/MP suite à analyse du justificatif',
      };
    }

    return null;
  }

  async function handleAcceptDocument() {
    const dateStart = newValues?.[ItemTypes.DateStart].confirmed;
    const dateEnd = newValues?.[ItemTypes.DateEnd].confirmed;

    let bodyType = getBodyPregnancy();
    if (bodyType == null) bodyType = getBodyRelatedWork();
    if (bodyType == null) bodyType = getDefaultBodyType();

    try {
      const response = await updateAbsence({
        params: { idAbsence: absence.id },
        body: {
          date_start: dateStart,
          date_end: dateEnd,
          ...(bodyType ?? {}),
        },
      });
      if (response.status === StatusCodes.OK)
        acceptDocument({ idDocument: document.id }).then((responseDoc) => {
          onDocumentUpdated(responseDoc.data);
          handleCloseModal();
          toast.present({ message: 'La déclaration est désormais validée', severity: 'success' });
        });
    } catch {
      toast.present({ message: "Une erreur est survenue à la modification de l'absence", severity: 'error' });
    }
  }

  const { header, content } = getTextAnalyse(document);

  return (
    <CustomModal
      maxWidth="lg"
      open={isVisible}
      onClose={() => {
        handleCloseModal();
      }}
      renderHeader={() => <>Reconnaissance automatique de documents</>}
      renderContent={() => {
        return (
          <div className={'row'}>
            <div className="col-12 text-center mb-4">
              <Typography variant={'h6'}>{header}</Typography>
              <Typography variant={'subtitle1'}>{content}</Typography>
            </div>
            <div className="col-4">
              <DocumentPreview document={document} type={status} />
            </div>
            <div className="col-8">
              {document.status === 'pending' && (
                <WarningVerification
                  absence={absence}
                  ocrResult={
                    isOCRResult(document.ocr_result)
                      ? safeParseOCRAPIResponse({ absence, ocr: document.ocr_result })
                      : safeParseOCRAPIResponse(document.ocr_result?.data)
                  }
                  onInformationChanged={(data) => setNewValues((value) => ({ ...value, ...data }))}
                />
              )}
              {document.status === 'rejected' && (
                <FailedVerification
                  onChoiceNewDocument={handleCloseModal}
                  onContinueWithDocument={handleCloseModal}
                  // setAbsence={setAbsence}
                />
              )}
              {document.status === 'approved' && (
                <div className={'warning-verification'}>
                  <Typography color="primary">
                    Ce document a été approuvé{' '}
                    {document.validation_source === 'ocr' ? 'automatiquement' : 'manuellement'}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        );
      }}
      renderFooter={() => (
        <>
          {document.status === 'pending' && (
            <Box textAlign="right">
              <ActionButton actionName={'cancel'} label={'Refuser'} onClick={handleRejectDocument} sx={{ mr: 1 }} />
              <ActionButton
                disabled={disableValidation}
                actionName={'validate'}
                label={'Accepter'}
                onClick={handleAcceptDocument}
              />
            </Box>
          )}
          {document.status !== 'pending' && (
            <Button
              onClick={() => {
                handleCloseModal();
              }}
            >
              {'Fermer'}
            </Button>
          )}
        </>
      )}
    />
  );
};
