import React from 'react';

export const NotConnectedRH = () => {
  return (
    <div className="col-10">
      <div className="container-fluid mt-5">
        <h1 className="question-form mb-5">Attention ! Vous n'avez pas l'autorisation d'accéder à cette page</h1>
      </div>
    </div>
  );
};
