import React from 'react';
import { Absence } from '@/types';
import { Box, Checkbox, Chip, FormControlLabel } from '@mui/material';
import { AbsenceDate } from '@/components/DashboardRH/Absences/AbsenceDate';
import { AbsenceLabel } from '@/components/DashboardRH/Absences/AbsenceLabel';

export interface AbsencesInputSelectItemBoxProperties {
  value: Absence;
  checked?: boolean;
  onChange: (event: { checked: boolean }, value: Absence) => void;
}

export const AbsencesInputSelectItemBox = ({ value, checked, onChange }: AbsencesInputSelectItemBoxProperties) => {
  return (
    <Box width={'100%'}>
      <FormControlLabel
        label={
          <>
            <AbsenceLabel absence={value} />
            <div>
              <Chip
                label={<AbsenceDate value={value} />}
                variant="outlined"
                size="small"
                sx={{ background: 'inherit' }}
              />
            </div>
          </>
        }
        control={
          <Checkbox
            style={{ marginRight: '0.5rem' }}
            checked={checked}
            onChange={(_, isCheck) => onChange({ checked: isCheck }, value)}
          />
        }
      />
    </Box>
  );
};
