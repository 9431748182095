import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
} from '@mui/material';
import { EmployeeContact, EmployeeContactTypeModule } from '../../../types/user.interface';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { deleteEmployeeContact } from '../../../services/rh';

export interface EmployeeContactListTableProps extends TableContainerProps {
  listContact: Array<EmployeeContact>;
  idEmployeeInformation: string;
  idBusiness: string;
  refreshPage?: () => void;
}

export const EmployeeContactListTable = ({
  listContact,
  idEmployeeInformation,
  idBusiness,
  refreshPage,
  ...rootProps
}: EmployeeContactListTableProps) => {
  const handleDeleteContact = async (contact: EmployeeContact) => {
    await deleteEmployeeContact(contact.id);
    refreshPage?.();
  };
  return (
    <TableContainer component={Paper} {...rootProps}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="dense table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Nom / Organisme</TableCell>
            <TableCell>Prénom</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listContact.map((contact, index) => (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}
            >
              <TableCell component="th" scope="row">
                {EmployeeContactTypeModule.format(contact.type)}
              </TableCell>
              <TableCell>{contact.email ?? '_'}</TableCell>
              <TableCell>{contact.lastname ?? '_'}</TableCell>
              <TableCell>{contact.firstname ?? '_'}</TableCell>
              <TableCell align="right">
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ cursor: 'pointer' }}
                  onClick={handleDeleteContact.bind(this, contact)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
