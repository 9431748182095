import axios from '../../../lib/axios';

export const getBusinessAbsenceOption = async (
  label: string,
  label1: string,
  label2: string,
  idBusiness?: string,
): Promise<{
  prePromoWeek: number;
  preReductionWeek: number;
  preReductionHours: number;
  postPromoWeek: number;
}> => {
  return await axios
    .get('/api/rh/business/absence_option', {
      params: {
        label: label,
        label1: label1,
        label2: label2,
        idBusiness,
      },
    })
    .then((value) => {
      return {
        prePromoWeek: value.data.preWeekPromo ?? 0,
        preReductionWeek: value.data.preWeekReduction ?? 0,
        preReductionHours: value.data.preHoursReduction ?? 0,
        postPromoWeek: value.data.postWeekPromo ?? 0,
      };
    })
    .catch((err) => {
      throw new Error();
    });
};
