// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* html,
body,
iframe {
  height: 100vh;
  width: 100%;
} */
`, "",{"version":3,"sources":["webpack://./src/components/MeetingRooms/MeetingRooms.css"],"names":[],"mappings":"AAAA;;;;;GAKG","sourcesContent":["/* html,\nbody,\niframe {\n  height: 100vh;\n  width: 100%;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
