import { RHInformation, RHPermissionScopes } from '@/types';
import { type PayloadAction, createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import type { AppState } from '../store';
import { fetchRhProfile } from '@/services/rh';
import jwtDecode from 'jwt-decode';
import * as RHNavbarContext from './rhNavbarContext';
import { ACCESS_TOKEN_HR } from '@/constants';

export interface RhContext {
  // extracted from code
  id_rh_information: string | undefined;
  rh_information: RHInformation;
}

export interface State {
  rh: RhContext | undefined;
  isAdmin: boolean;
  loadingRh: boolean;
  permissionScopes: RHPermissionScopes;
}

const initialState: State = {
  rh: undefined,
  isAdmin: false,
  loadingRh: true,
  permissionScopes: {},
};

export const slice = createSlice({
  name: 'accountRhContext',
  initialState,
  reducers: {
    setAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    setRh: (state, action: PayloadAction<State['rh']>) => {
      state.rh = action.payload;
    },
    setPermissionScopes: (state, action: PayloadAction<State['permissionScopes']>) => {
      state.permissionScopes = action.payload;
    },
    setLoading: (state, action: PayloadAction<State['loadingRh']>) => {
      state.loadingRh = action.payload;
    },
    reset(state) {
      state.isAdmin = false;
      state.rh = undefined;
      state.permissionScopes = {};
    },
  },
});

export const getSelf = (state: { accountRhContext: State }) => state[slice.name];

export const isLoading = createSelector([getSelf], (self) => self.loadingRh);

export const isAdmin = createSelector([getSelf], (self) => Boolean(self.isAdmin));

export const getPermissionScopes = createSelector([getSelf], (self) => self.permissionScopes);

export const getProfile = createSelector([getSelf], (self) => self.rh);

export const { actions } = slice;

/**
 * Load profile
 */
export const load = createAsyncThunk(`${slice.name}/load`, async (_, { dispatch, getState }) => {
  // const state = getState() as AppState;

  const token = localStorage.getItem(ACCESS_TOKEN_HR);
  const decodedToken: any = token == null ? undefined : jwtDecode(token);

  try {
    dispatch(actions.setLoading(true));
    const rhProfile = await fetchRhProfile();

    dispatch(actions.setRh(rhProfile.data.rh));
    dispatch(actions.setAdmin(Boolean(decodedToken?.isAdmin)));
    dispatch(actions.setPermissionScopes(rhProfile.data.rh?.permission_scopes ?? {}));
    dispatch(actions.setLoading(false));

    // TODO: move this in a listener
    dispatch(RHNavbarContext.update(getSelf(getState() as AppState).rh));
  } finally {
    dispatch(actions.setLoading(false));
  }
});
