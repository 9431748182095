import { Box, Typography } from '@mui/material';
import React from 'react';

interface TitleProps {
  title: string;
  customStyle?: any;
  customStyleTitle?: any;
  className?: string;
}

const TitleProcess = ({ title, customStyle, customStyleTitle, className }: TitleProps) => {
  return (
    <Box
      sx={{
        ...customStyle,
      }}
    >
      <Typography
        sx={{
          ...customStyleTitle,
        }}
        className={className || 'question-form mt-5 text-center'}
        variant="h5"
      >
        {title}
      </Typography>
    </Box>
  );
};

export default TitleProcess;
