import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { DocumentAbsence } from '../../../types/absences.type';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface DocumentUploaderPreviewsProperties {
  document: DocumentAbsence | File;
  button?: boolean;
}

const isFile = (doc: DocumentAbsence | File): doc is File => !Object.hasOwn(doc, 'url');

export const DocumentUploaderPreviews = ({ document, button }: DocumentUploaderPreviewsProperties) => {
  const openUrl = () => {
    if (isFile(document)) window.open(URL.createObjectURL(document), '_blank');
    else window.open(document.url, '_blank');
  };

  return (
    <>
      {button && (
        <IconButton onClick={openUrl}>
          <VisibilityIcon />
        </IconButton>
      )}
      {!button && (
        <Tooltip title={'Afficher le document'}>
          <IconButton onClick={openUrl}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
