import logo from '../../../../../Logotype-Simple-Fondclair.svg';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { RHNotification } from '../../../../../types/rh.interface';
import ModalIsOpenFile from '../ModalIsOpenFile';
import IconButton from '@mui/material/IconButton';

interface ExtractionCardInterface {
  rhNotification: RHNotification;
  onClick: () => void;
}

const ExtractionCard = ({ rhNotification, onClick }: ExtractionCardInterface) => {
  const [modalIsOpenFile, setModalIsOpenFile] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
      className="card-actions-rh mb-3"
    >
      <div className="card-actions-rh-header-yellow row text-start">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src={logo}
            className="App-logo"
            alt="logo"
            width="63"
            style={{
              display: 'flex',
              marginRight: '2vw',
            }}
          />
          <span>
            <strong>{rhNotification.employee_information?.business.name}</strong>
          </span>
        </div>
      </div>

      <div className="card-actions-rh-body-yellow row">
        <div className="col-1 text-center btn-lg" style={{ color: '#40C9C9' }}>
          {/*<FontAwesomeIcon icon="fa-solid fa-circle" />*/}
        </div>
        <p className="card-actions-rh-text col-7 text-start">
          <strong>{rhNotification.type_notification}</strong>
          <span className="card-actions-rh-text-muted">
            &nbsp; - le {moment(rhNotification.date_notification).format('DD/MM/YYYY')} &nbsp;{' '}
            {moment(rhNotification.date_notification).format('HH:mm')}
          </span>
        </p>
        <div className="col-4 text-end">
          <button
            type="button"
            className="btn btn-kerij-rh btn-sm"
            onClick={() => {
              setModalIsOpenFile(true);
            }}
          >
            <IconButton>
              <ArrowCircleDownIcon />
            </IconButton>
            &nbsp;&nbsp;&nbsp;Télécharger le fichier
          </button>
        </div>
      </div>

      <div className="card-actions-rh-footer-yellow row text-center p-2"></div>
      <ModalIsOpenFile
        rhNotification={rhNotification}
        handleCloseModal={() => {
          onClick?.();
          setModalIsOpenFile(false);
        }}
        visible={modalIsOpenFile}
      />
    </div>
  );
};
export default ExtractionCard;
