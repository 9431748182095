import { Dispatch } from 'redux';
import * as rhServices from '../../../services/rh';
import { RHUploadDocumentApiResponse, RhUploadFileAbsenceRequest } from '@/services/rh';
import {
  DocumentActionTypes,
  IDocumentFailureAction,
  IDocumentLoadingAction,
  IDocumentResetAction,
  IDocumentSuccessAction,
} from './type';

export const createDocumentRequest = (request: RhUploadFileAbsenceRequest) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(createDocumentLoading(true));
      const data = await rhServices.rhUploadFileAbsence(request);
      dispatch(createDocumentSuccess(data?.[0]));
      dispatch(createDocumentLoading(false));
    } catch (error: any) {
      dispatch(getAbsenceCurrentFailure(error.message));
      dispatch(createDocumentLoading(false));
    }
  };
};

export const createDocumentSuccess = (payload: RHUploadDocumentApiResponse | undefined): IDocumentSuccessAction => {
  return {
    type: DocumentActionTypes.CREATE_DOCUMENT_SUCCESS,
    payload,
  };
};

export const getAbsenceCurrentFailure = (error?: any): IDocumentFailureAction => {
  return {
    type: DocumentActionTypes.CREATE_DOCUMENT_FAILURE,
    payload: error,
  };
};

export const createDocumentLoading = (loading: boolean): IDocumentLoadingAction => {
  return {
    type: DocumentActionTypes.CREATE_DOCUMENT_LOADING,
    payload: loading,
  };
};
export const createDocumentReset = (): IDocumentResetAction => {
  return {
    type: DocumentActionTypes.CREATE_DOCUMENT_RESET,
  };
};
