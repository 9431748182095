import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import { ModifyContactTelForm } from './ModifyContactTelForm';

export const ModifyContactTelPage: React.FC = () => {
  return (
    <DefaultLayout>
      <ModifyContactTelForm />
    </DefaultLayout>
  );
};
