import React, { ForwardedRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

interface ConfirmDialogProps {
  open: boolean;
  text?: React.ReactElement | string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export const ConfirmDialog = React.forwardRef(function ConfirmDialog(
  props: ConfirmDialogProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { open, text, onConfirm, onCancel, onClose } = props;

  return (
    <React.Fragment>
      <Dialog ref={ref} open={open} onClose={onClose}>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onConfirm} autoFocus>
            Confirmer
          </Button>
          <Button color="error" onClick={onCancel}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});
