export const AbsenceCode = {
  Maladie: 'MA',
  MaladiePro: 'MP',
  Hospistalisation: 'HO',
  CongeMat: 'CM',
  AccidentTravail: 'AT',
  AccidentTrajet: 'AJ',
  CongePat: 'CP',
  PathologieGrossesse: 'GP',
} as const;
export type AbsenceCode = (typeof AbsenceCode)[keyof typeof AbsenceCode];

export const AbsenceCodeLabel = {
  [AbsenceCode.Maladie]: 'Maladie',
  [AbsenceCode.MaladiePro]: 'Maladie professionnelle',
  [AbsenceCode.Hospistalisation]: 'Hospistalisation',
  [AbsenceCode.CongeMat]: 'Congé maternité',
  [AbsenceCode.AccidentTravail]: 'Accident de travail',
  [AbsenceCode.AccidentTrajet]: 'Accident de trajet',
  [AbsenceCode.CongePat]: 'Congé paternité',
  [AbsenceCode.PathologieGrossesse]: 'Grossesse patho.',
} satisfies Record<AbsenceCode, string>;
