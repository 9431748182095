import React from 'react';
import { Box, BoxProps, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faUpload, faExpand } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@/components/Button';
import classNames from 'clsx';

const componentName = 'InputFileButton';

export interface InputFileButtonProps extends BoxProps {
  detectDocument?: boolean;
  disabled?: boolean;
  helperText?: string;
  label?: string;
  menuTitle?: string;
  menuSubtitle?: string;
  onOpenScan?: () => void;
  onOpenFiles?: () => void;
}

export const InputFileButton = (_: InputFileButtonProps) => {
  const {
    label = 'Sélectionner un fichier',
    menuTitle,
    menuSubtitle,
    detectDocument,
    disabled,
    onOpenScan,
    onOpenFiles,
    helperText,
    className,
    ...otherProps
  } = _;
  const anchorRef = React.useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (detectDocument) setMenuOpen(true);
    else onOpenFiles?.();
  };
  const handleClose = () => setMenuOpen(false);

  return (
    <Box className={classNames(componentName, className)} {...otherProps}>
      <Button
        color="secondary"
        disabled={disabled}
        id="basic-button"
        ref={anchorRef}
        aria-controls={menuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleClick}
        style={{ width: '100%' }}
        endIcon={<FontAwesomeIcon icon={faUpload}></FontAwesomeIcon>}
      >
        {label}
      </Button>
      {helperText ? (
        <div className="text-center mb-1 text-muted small">
          <em>{helperText}</em>
        </div>
      ) : undefined}

      <Menu
        id="basic-menu"
        anchorEl={anchorRef.current}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem>
          <Typography variant="h6">{menuTitle}</Typography>
        </MenuItem>
        <MenuItem disabled>
          <Typography variant="body1" sx={{ whiteSpace: 'normal' }}>
            <em>{menuSubtitle}</em>
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
            onOpenScan?.();
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faExpand} />
          </ListItemIcon>
          <ListItemText>Prendre une photo</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onOpenFiles?.();
          }}
          style={{ minWidth: '20rem' }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faImages} />
          </ListItemIcon>
          <ListItemText>Choisir depuis…</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};
