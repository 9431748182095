import * as React from 'react';
import classNames from 'clsx';
import { Avatar, Box, Stack, type StackProps } from '@mui/material';
import { ParcoursDetailSection } from './ParcoursDetailSection';
import { ParcoursAvatar } from '@/components/ParcoursAvatar';
import { EmployeeContactTypeModule, Parcours, ParcoursEmployeeRef, ParcoursOwner } from '@/types';
import { Abc, AlarmOff, RepeatRounded, PersonPinCircle } from '@mui/icons-material';
import { PageTitle } from '@/components/Page';
import { Email, Inbox, AllInbox, ScheduleSend } from '@/components/AppIcon';
import { parcoursWhenDelay } from '@/components/ParcoursInput';
import { parcoursEmailFooter, parcoursEmailHeader } from './data';
import { extractRule } from '../ParcoursRule/extractRule';
import { ParcoursRuleDetailText, ParcoursRuleIcon } from '@/components/ParcoursRule';
import { printVariables } from './printVariables';
import { HTMLViewer } from '@/components/HTMLViewer';
import { ParcoursStatusText } from './ParcoursStatusText';
import { ParcoursSwitchActive } from '../ParcoursSwitchActive';
import { parcoursPresence } from './parcoursPresence';
import { StringVariable } from '../StringVariable';

// Name and options
const componentName = 'ParcoursDetail';

type ParcoursData = Pick<Parcours, 'script' | 'name' | 'legal' | 'active' | 'owner'> & { id?: Parcours['id'] };

// Component properties
export interface ParcoursDetailProps extends StackProps {
  parcours: ParcoursData | undefined;
  headerVariant?: 'title' | 'section';
  excludedSections?: Array<keyof Pick<Parcours, 'active'>>;
}

export function ParcoursDetail(props: ParcoursDetailProps) {
  const { className, parcours, headerVariant = 'title', excludedSections, ...otherProps } = props;
  const renderEmployeeRefs = (refs: ParcoursEmployeeRef[]) =>
    refs.map((ref) => (ref === 'employee' ? 'Le collaborateur' : EmployeeContactTypeModule.format(ref))).join(', ');

  const parcoursScript = parcours?.script;
  const parcoursAction = parcoursScript?.action;
  const parcoursActionEmail = parcoursAction?.email?._ === 'ParcoursActionEmail' ? parcoursAction?.email : undefined;
  let parcoursType: string | undefined;
  if (parcours != null) {
    parcoursType = parcours.legal ? 'Parcours légal' : 'Parcours de management';
    if (parcours.owner === ParcoursOwner.Admin) parcoursType = 'Parcours Kerij';
  }

  const subtitle =
    parcoursType == null || parcours == null
      ? ''
      : [parcoursType, ' • ', <ParcoursStatusText key="status" parcours={parcours} />];
  return parcours == null ? null : (
    <Stack direction="column" spacing={2.5} className={classNames(componentName, className)} {...otherProps}>
      {headerVariant === 'title' ? (
        <PageTitle
          className={classNames(`${componentName}__Title`)}
          avatar={<ParcoursAvatar parcours={parcours} />}
          title={parcours.name}
          subtitle={subtitle}
          primaryAction={<ParcoursSwitchActive value={parcours} />}
        />
      ) : (
        <ParcoursDetailSection
          className={classNames(`${componentName}__Title`)}
          sectionTitle="Définition du programme"
          sectionItems={[
            {
              avatar: <ParcoursAvatar parcours={parcours} />,
              title: parcours.name,
              subtitle: subtitle,
            },
          ]}
        />
      )}

      <ParcoursDetailSection
        className={classNames(`${componentName}__Declencheur`)}
        sectionTitle="Déclencheur"
        sectionItems={parcoursScript?.rule.where?.map((rule) => {
          const ruleExtracted = extractRule(rule);

          return ruleExtracted.success
            ? {
                avatar: (
                  <Avatar>
                    <ParcoursRuleIcon parcoursRule={rule} />
                  </Avatar>
                ),
                title: ruleExtracted.rule.key,
                subtitle: <ParcoursRuleDetailText parcoursRule={rule} />,
              }
            : { avatar: <Abc />, title: '#ERROR' };
        })}
        sectionItemEmpty={{
          avatar: (
            <Avatar>
              <AlarmOff />
            </Avatar>
          ),
          title: 'Aucune règle automatique',
          subtitle: 'Il ne peut être déclenché que manuellement.',
        }}
      />
      <ParcoursDetailSection
        className={classNames(`${componentName}__To`)}
        sectionTitle="Qui est informé ?"
        sectionItems={[
          ...(parcoursActionEmail?.to && parcoursActionEmail?.to.length > 0
            ? [
                {
                  avatar: (
                    <Avatar>
                      <Inbox />
                    </Avatar>
                  ),
                  title: renderEmployeeRefs(parcoursActionEmail?.to),
                  subtitle: "en destinataire de l'email",
                },
              ]
            : []),
          ...(parcoursActionEmail?.cc && parcoursActionEmail?.cc.length > 0
            ? [
                {
                  avatar: (
                    <Avatar>
                      <AllInbox />
                    </Avatar>
                  ),
                  title: renderEmployeeRefs(parcoursActionEmail?.cc),
                  subtitle: "en copie de l'email",
                },
              ]
            : []),
        ]}
        sectionItemEmpty={{
          avatar: (
            <Avatar>
              <Inbox />
            </Avatar>
          ),
          title: "Ce parcours n'a aucun destinataire",
        }}
      />
      <ParcoursDetailSection
        className={classNames(`${componentName}__Message`)}
        sectionTitle="Le message"
        sectionItems={[
          {
            avatar: (
              <Avatar>
                <Email />
              </Avatar>
            ),
            title:
              parcoursActionEmail?.subject == null ? (
                ''
              ) : (
                <StringVariable fontSize={14} fontWeight={600} value={parcoursActionEmail.subject} />
              ),
            subtitle: "en objet de l'email",
          },
          {
            avatar: undefined,
            title: '',
            inset: false,
            subtitle: (
              <Box maxHeight={250} overflow="scroll">
                <HTMLViewer
                  before={<StringVariable value={parcoursEmailHeader} fontSize={12} />}
                  htmlContent={parcoursActionEmail?.content ?? ''}
                  after={<p style={{ whiteSpace: 'pre-wrap' }}>{parcoursEmailFooter}</p>}
                />
              </Box>
            ),
          },
        ]}
      />

      <ParcoursDetailSection
        className={classNames(`${componentName}__Message`)}
        sectionTitle="Les modalités de déclenchement du message"
        sectionItems={[
          {
            avatar: (
              <Avatar>
                <ScheduleSend />
              </Avatar>
            ),
            title: parcoursScript?.when?.delay == null ? '' : parcoursWhenDelay(parcoursScript?.when?.delay),
            subtitle: (
              <>
                par rapport à{' '}
                <strong>
                  {parcoursScript?.when?.reference_date === 'parcoursTriggeredAt'
                    ? "la date d'éligibilité"
                    : "la date de fin d'absence connue"}
                </strong>
              </>
            ),
          },
          {
            title: 'Fréquence d’envoi',
            avatar: (
              <Avatar>
                <RepeatRounded />
              </Avatar>
            ),
            subtitle:
              parcoursScript?.frequency?._ === 'franchise'
                ? 'Période de franchise entre deux envois'
                : 'À chaque échéance',
          },
          {
            avatar: (
              <Avatar>
                <PersonPinCircle />
              </Avatar>
            ),
            title: 'Condition de présence',
            subtitle: parcoursPresence(parcoursScript?.when?.employee_presence ?? 'any'),
          },
        ]}
      />
      {props.children}
    </Stack>
  );
}
