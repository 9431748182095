import { DefaultLayout } from '@/components/layouts';
import { Activites } from '@/components/Activites';
import React from 'react';

export const ActivitiesPage: React.FC = () => {
  return (
    <DefaultLayout>
      <Activites />
    </DefaultLayout>
  );
};
