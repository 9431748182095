import { Box } from '@mui/material';
import React from 'react';
import { Button } from '@/components/Button';

interface CloseAbsenceModalFooterProperties {
  onCancel: () => void;
  onConfirm: () => void;
  cancelLabel?: string;
  disableConfirm?: boolean;
}

export const CloseAbsenceModalFooter = ({
  onCancel,
  onConfirm,
  disableConfirm,
  cancelLabel,
}: CloseAbsenceModalFooterProperties) => {
  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'right',
      }}
    >
      <Button onClick={onCancel} sx={{ mr: 0.5 }} variant="text">
        {cancelLabel || 'Annuler'}
      </Button>
      <Button onClick={onConfirm} disabled={disableConfirm}>
        Confirmer
      </Button>
    </Box>
  );
};
