import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import validator from 'validator';
import { useDebouncedCallback } from 'use-debounce';
import { KerijBox, KerijBoxType } from '../../../../../components/base';
import KerijButton from '../../../../../components/base/button/kerijButton';
import { Button } from '@/components/Button';

function ForgetPasswordUI() {
  const [login, setLogin] = useState('');
  const [telephone, setTelephone] = useState('');
  const message: any = '';
  const user: any = null;
  const navigate = useNavigate();
  const state = useLocation();

  const [emailError, setEmailError] = useState<any>(null);
  const [phoneError, setPhoneError] = useState<any>(null);

  const validateEmail = useDebouncedCallback((email) => {
    if (validator.isEmail(email)) {
      setEmailError(null);
    } else {
      setEmailError('Veuillez entrer une adresse email valide.');
    }
  }, 1000);

  const validatePhone = useDebouncedCallback((phone) => {
    if (validator.isMobilePhone(phone, 'fr-FR')) {
      setPhoneError(null);
    } else {
      setPhoneError('Veuillez entrer un numéro de téléphone valide.');
    }
  }, 500);

  const handleSubmit = (event: any) => {
    try {
      event.preventDefault();
      if (!login && !telephone) {
        alert('Telephone et mail ne doivent pas être null en même temps');
      }
      if (message === `Cet identifiant n'existe pas`) {
        alert("Cet identifiant n'existe pas");
      } else if ((login || telephone) && message !== "Cet identifiant n'existe pas") {
        navigate('/resetPassword', {
          state: {
            id_salarie_login: user ? user.id : '',
            mail: login,
            telephone: telephone,
            nom: user ? user.employee_information.lastname : '',
            prenom: user ? user.employee_information.firstname : '',
            document: state.state.document,
            idBusiness: user ? user.employee_information.business.id : '',
            idEmployeeInformation: user ? user.employee_information.id : '',
          },
        });
      }
    } catch (err) {
      console.log(err);
      alert('erreur');
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <h1 className="question-form mt-5 mb-5">Modifier le mot de passe</h1>
        {emailError && <KerijBox type={KerijBoxType.Error} message={emailError} />}
        {phoneError && <KerijBox type={KerijBoxType.Error} message={phoneError} />}
        <form onSubmit={handleSubmit}>
          <label>
            Identifiant
            <input
              name="login"
              type="Email"
              value={login}
              onChange={(e) => {
                setLogin(e.target.value);
                validateEmail(e.target.value);
              }}
              required
            />
          </label>
          <div className="err">{message.toString()}</div>
          <label>
            Telephone *
            <input
              name="telephone"
              type="text"
              // placeholder="Mot de passe"
              value={telephone}
              required
              pattern="[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}"
              onChange={(e) => {
                setTelephone(e.target.value);
                validatePhone(e.target.value);
              }}
            />
          </label>
          <Button color="primary" type="submit" disabled={!login || !telephone || emailError || phoneError}>
            Valider
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ForgetPasswordUI;
