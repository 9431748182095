import React from 'react';
import { CircularProgress, TableCell, TableRow, Typography } from '@mui/material';
import { DocumentUploaderPreviews } from './DocumentUploaderPreview';

interface DocumentUploaderLoadingProperties {
  file: File;
}

export const DocumentUploaderLoading = ({ file }: DocumentUploaderLoadingProperties) => {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell style={{ width: 50 }}>
        <CircularProgress />
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography>
          {file.name}
          <br />
        </Typography>
        <Typography color="orange" fontStyle="italic" fontWeight="bold">
          Analyse OCR du document en cours, veuillez patienter 10 secondes
        </Typography>
      </TableCell>

      <TableCell style={{ width: 50 }}>
        <DocumentUploaderPreviews document={file} button />
      </TableCell>

      <TableCell style={{ width: 50 }}></TableCell>
      <TableCell style={{ width: 50 }}></TableCell>
    </TableRow>
  );
};
