import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import LoginInformationUI from './components/LoginInformationUI';

export const LoginInformationPage: React.FC = () => {
  return (
    <DefaultLayout>
      <LoginInformationUI />
    </DefaultLayout>
  );
};
