import React, { useState } from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useAppSelector, AccountContext } from '@/store';

export interface DeclarationContactInputProps {
  type: 'email' | 'phone';
  value?: string;
  onChange: (value?: string) => void;
}

export function DeclarationContactInput(props: DeclarationContactInputProps) {
  const { value, onChange, type } = props;
  const user = useAppSelector(AccountContext.getUser);
  const defaultValue = type === 'email' ? user?.email : user?.phone;
  const [isDefault, setIsDefault] = useState(value == null || value === defaultValue);

  return (
    <div style={{ padding: '0 1rem' }}>
      <RadioGroup
        value={isDefault ? 'default' : 'custom'}
        onChange={(event) => {
          setIsDefault(event.target.value === 'default');
          if (event.target.value === 'default') onChange(defaultValue);
          else onChange('');
        }}
      >
        {defaultValue && (
          <FormControlLabel value="default" control={<Radio />} label={defaultValue} sx={{ height: '1.5rem' }} />
        )}

        <FormControlLabel
          value="custom"
          control={<Radio />}
          label={type === 'email' ? 'Adresse email alternative' : 'Numéro de téléphone alternatif'}
          sx={{ height: '1.5rem' }}
        />
      </RadioGroup>

      {!isDefault && (
        <div>
          <input
            type={type === 'email' ? 'mail' : 'text'}
            placeholder={type === 'email' ? 'Votre nouvelle adresse mail' : 'Votre nouveau numéro'}
            pattern={type === 'email' ? undefined : '[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}'}
            value={value}
            onChange={(event) => onChange(event.target.value)}
          />
        </div>
      )}
    </div>
  );
}
