import { SCANBOT_LICENSE } from '@/config';
import ScanbotSDK from 'scanbot-web-sdk/webpack';

const translations = {
  hint: {
    OK: 'Capture de votre document en cours... Veuillez ne pas déplacer la caméra.',
    OK_SmallSize: 'Le document est trop petit. Essayez de vous rapprocher.',
    OK_BadAngles: "Cet angle de caméra est mauvais. Maintenez l'appareil droit au-dessus du document.",
    OK_BadAspectRatio: "Tournez l'appareil sur le côté, afin que le document s'adapte mieux à l'écran.",
    OK_OffCenter: "Essayez de tenir l'appareil au centre du document.",
    Error_NothingDetected: "Veuillez placer l'appareil au-dessus d'un document pour commencer la numérisation.",
    Error_Brightness: "Il fait trop sombre. Essayez d'allumer une lumière.",
    Error_Noise: 'Veuillez déplacer le document sur une surface claire.',
  },
};

export async function useScanbot() {
  const initSDK = async () => {
    const licenseKey = SCANBOT_LICENSE;

    if (licenseKey == null)
      throw "License Scanbot non renseignée. Mettez à jour la variable 'REACT_APP_SCANBOT_LICENSE'";
    return await ScanbotSDK.initialize({ licenseKey });
  };

  const initScanner = async (onDocumentDetected: (e: any) => Promise<void>, onError: (e: any) => Promise<void>) => {
    const value = await sdk.createDocumentScanner({
      containerId: 'ScanBotContainer',
      text: translations,
      autoCaptureSensitivity: 0.33,
      onCaptureButtonClick: () => {},
      onDocumentDetected,
      onError,
    });
    value.enableAutoCapture();
    return value;
  };

  const sdk = await initSDK();

  return {
    sdk,
    initScanner,
  };
}
