import { useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { DefaultLayout } from '../../../components/layouts';
import { StepProgressBar } from '../../../features/declaration/components/bars/StepProgressBar';
import { NavigationContext } from '../../../components/Context/NavigationContext';
import { NavigationSteps } from '../../../components/Context/NavigationProvider';
import { AccountContext, useAppSelector } from '@/store';
import { EmployeeCivility } from '@/types';

// TODO: replace any by an interface
const getAbsenceType1InitValue = (document: any): string => {
  return document.absenceType1 ? document.absenceType1?.value : '1';
};

const getAbsenceType1InitLabel = (document: any): string => {
  if (document.absenceType1) {
    return document.absenceType1?.label as string;
  } else {
    switch (document.absenceType?.value) {
      case '1': {
        return 'Maladie';
      }
      case '2': {
        return 'Maladie';
      }
      case '3': {
        return "J'attends 1 enfant";
      }
      case '4': {
        return "J'attends 1 enfant";
      }
      default: {
        return '';
      }
    }
  }
};

const getRelationInitValue = (document: any): string => {
  return document.relationEnfant ?? (document.absenceType?.value === '4' ? 'pere' : null);
};

const getAbsenceType1Component = (
  absenceType: string,
  options: {
    absenceType1: string;
    setAbsenceType1: React.Dispatch<React.SetStateAction<string>>;
    setAbsenceType1Label: React.Dispatch<React.SetStateAction<string>>;
    relation: string;
    setRelation: React.Dispatch<React.SetStateAction<string>>;
  },
) => {
  let title, selections;

  switch (absenceType) {
    case '1': {
      title = 'Quelle est la raison de votre arrêt de travail ?';
      selections = [
        {
          value: '1',
          label: 'Maladie',
        },
        {
          value: '2',
          label: 'Accident - Maladie professionnelle',
        },
      ];
      break;
    }
    case '2': {
      title = 'Quelle est la raison de votre hospitalisation ?';
      selections = [
        {
          value: '1',
          label: 'Maladie',
        },
        {
          value: '2',
          label: 'Accident',
        },
      ];
      break;
    }
    case '3': {
      title = "Combien d'enfant(s) attendez-vous ?";
      selections = [
        {
          value: '1',
          label: "J'attends 1 enfant",
        },
        {
          value: '2',
          label: "J'attends 2 enfants",
        },
        {
          value: '3',
          label: "J'attends 3 enfants ou plus",
        },
      ];
      break;
    }
    default: {
      title = "Combien d'enfant(s) attendez-vous ?";
      selections = [
        {
          value: '1',
          label: "J'attends 1 enfant",
        },
        {
          value: '2',
          label: "J'attends 2 enfants ou plus",
        },
      ];
      break;
    }
  }

  return (
    <div className="type1-hospi container-fluid">
      <h3 className="question-form mt-5 mb-5 text-center">{title}</h3>
      <div className="form-declaration text-center col-lg-4">
        <label className="col-12">
          <select
            className="form-select"
            name="absenceType1"
            value={options.absenceType1 ?? ''}
            onChange={(e) => {
              options.setAbsenceType1(e.target.value);
              // eslint-disable-next-line xss/no-mixed-html
              const index = (e.nativeEvent.target as HTMLSelectElement).selectedIndex;
              // eslint-disable-next-line xss/no-mixed-html
              options.setAbsenceType1Label((e.nativeEvent.target as HTMLSelectElement)[index]?.innerHTML);
            }}
            required
          >
            {selections.map((value) => (
              <option value={value.value} key={value.value}>
                {value.label}
              </option>
            ))}
          </select>
        </label>
        {absenceType === '4' ? (
          <div className="text-center">
            <div className="col-12 mt-3" style={{}}>
              {` Quelle est votre relation avec l'enfant ?`}
            </div>
            <div
              className="mb-3 mt-3 col-12"
              style={{
                display: 'flex',
                flexBasis: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                className="col-12 row"
                style={{
                  display: 'flex',
                  flexBasis: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  className="mb-3"
                  style={{
                    display: 'flex',
                    flexBasis: 'row',
                    alignItems: 'center',
                    marginRight: 30,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    options.setRelation('pere');
                  }}
                >
                  <FontAwesomeIcon
                    icon={options.relation === 'pere' ? faCircleCheck : faCircle}
                    style={{
                      color: '#40C9C9',
                      marginRight: 5,
                    }}
                  ></FontAwesomeIcon>
                  <div style={{ fontWeight: '800' }}>{`Je suis le père de l'enfant`}</div>
                </div>
                <div
                  className="col-12"
                  style={{
                    display: 'flex',
                    flexBasis: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    options.setRelation('conjoint');
                  }}
                >
                  <FontAwesomeIcon
                    icon={options.relation === 'conjoint' ? faCircleCheck : faCircle}
                    style={{
                      color: '#40C9C9',
                      marginRight: 5,
                    }}
                  ></FontAwesomeIcon>
                  <div style={{ fontWeight: '800' }}>
                    {`Je suis le conjoint de la mère de
                                            l'enfant`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export const DeclarationType1Page = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const document = location.state?.document || {};
  const user = useAppSelector(AccountContext.getUser);

  const [absenceType1, setAbsenceType1] = useState<string>(getAbsenceType1InitValue(document));
  const [absenceType1Label, setAbsenceType1Label] = useState<string>(getAbsenceType1InitLabel(document));
  const [relation, setRelation] = useState<string>(getRelationInitValue(document));
  const absenceType = document.absenceType?.value;

  const { setCurrentStep, changeStep } = useContext(NavigationContext);

  useEffect(() => {
    setCurrentStep(NavigationSteps.DECLARATION_TYPE_1);
  }, []);

  const handleClick = () => {
    if (
      document.absenceType?.value + absenceType1 === '23' ||
      document.absenceType?.value + absenceType1 === '32' ||
      document.absenceType?.value + absenceType1 === '33' ||
      document.absenceType?.value + absenceType1 === '41' ||
      document.absenceType?.value + absenceType1 === '42'
    ) {
      changeStep(NavigationSteps.DECLARATION_DATE, {
        state: {
          document: {
            ...document,
            absenceType1: {
              value: absenceType1,
              label: absenceType1Label,
            },
            absenceType2: { value: null, label: null },
            relationEnfant: { value: relation },
          },
        },
      });
    } else {
      if (absenceType === '1') {
        changeStep(NavigationSteps.DECLARATION_PROLONGATION, {
          state: {
            document: {
              ...document,
              absenceType1: {
                value: absenceType1,
                label: absenceType1Label,
              },
            },
          },
        });
      } else {
        changeStep(NavigationSteps.DECLARATION_TYPE_2, {
          state: {
            document: {
              ...document,
              absenceType1: {
                value: absenceType1,
                label: absenceType1Label,
              },
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (user && user.employee_information.civility === EmployeeCivility.Male && absenceType === '3') {
      alert(
        "Une erreur s'est produite, vous ne pouvez pas déclarer un congé maternité si vous êtes un homme, vous allez être redirigé vers la page d'accueil",
      );
      navigate('/', { replace: true });
    }

    if (document) {
      if (document.absenceType?.value === '2') {
        changeStep(NavigationSteps.DECLARATION_TYPE_2, {
          state: {
            document: {
              ...document,
              absenceType1: { value: null, label: null },
              absenceType2: { value: null, label: null },
            },
          },
        });
      } else if (document.absenceType?.value === '1' && document.absenceType1?.value !== null) {
        changeStep(NavigationSteps.DECLARATION_PROLONGATION, {
          state: {
            document: {
              ...document,
            },
          },
        });
      }
    }
  }, []);

  return (
    <DefaultLayout>
      <div className="container-fluid">
        <StepProgressBar type={absenceType} />
        {getAbsenceType1Component(absenceType, {
          absenceType1,
          setAbsenceType1,
          setAbsenceType1Label,
          relation,
          setRelation,
        })}
        <div className="position-relative col-12 col-md-8 text-center mb-3 mt-5">
          <button type="button" className="btn btn-kerij col-6 col-lg-3" onClick={handleClick}>
            Suivant
          </button>
        </div>
        <div className="position-relative col-12 col-md-8 text-center mb-5">
          <button
            type="button"
            className="btn btn-kerij-outline btn-sm col-6 col-lg-3"
            onClick={() => {
              if (user && user.employee_information.civility === EmployeeCivility.Male && absenceType === '4') {
                navigate('/declaration', {
                  state: location.state,
                });
              } else {
                changeStep(NavigationSteps.DECLARATION_PROLONGATION, {
                  state: location.state,
                });
              }
            }}
          >
            Précédent
          </button>
        </div>
      </div>
    </DefaultLayout>
  );
};
