import * as React from 'react';
import type { DialogProps } from '@mui/material';

export interface UseMUIDialogController {
  /**
   * Modal controller
   */
  control: {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
  };
  /**
   * Properties to forward to dialog component
   */
  getRootProps: () => {
    open: DialogProps['open'];
    onClose: NonNullable<DialogProps['onClose']>;
  };
}

/**
 *
 * @example
 * ```ts
 * const controller = useMUIDialogController();
 * const open = () => controller.setVisible(true);
 *
 * <CustomModal {...controller.props} />
 * ```
 */
export function useMUIDialogController(): UseMUIDialogController {
  const [open, setOpen] = React.useState(false);
  const handleCloseModal = () => setOpen(false);

  return {
    control: {
      isOpen: open,
      setOpen,
    },
    getRootProps: () => ({
      open: open,
      onClose: handleCloseModal,
    }),
  };
}
