import CustomModal from '../../../../base/CustomModal';
import React from 'react';
import { Box } from '@mui/material';
import axios from '../../../../../lib/axios';
import { useError } from '@/shared/useError';
import { StatusCodes } from 'http-status-codes';

interface IEditAbsenceModalProps {
  absenceOptions: any[];
  setAbsenceOptions: (value: any) => void;
  select?: number | string;
  listSelectBusiness?: any[];
  selectedIdBusiness?: string | null;
  setModalIsOpenEditAbsenceOptions?: any;
  getAbsenceOptions?: any;
  listSelectCompany?: any[];
  modalIsOpenEditAbsenceOptions?: boolean;
}

const EditAbsenceModal = ({
  absenceOptions,
  setAbsenceOptions,
  select,
  listSelectBusiness,
  selectedIdBusiness,
  setModalIsOpenEditAbsenceOptions,
  getAbsenceOptions,
  listSelectCompany,
  modalIsOpenEditAbsenceOptions,
}: IEditAbsenceModalProps) => {
  return (
    <CustomModal
      open={modalIsOpenEditAbsenceOptions || false}
      setOpen={setModalIsOpenEditAbsenceOptions}
      contentProps={{
        style: { paddingLeft: '4rem', paddingRight: '4rem' },
      }}
      renderContent={() => (
        <Content
          absenceOptions={absenceOptions}
          setAbsenceOptions={setAbsenceOptions}
          select={select}
          listSelectBusiness={listSelectBusiness}
          selectedIdBusiness={selectedIdBusiness}
          getAbsenceOptions={getAbsenceOptions}
          setModalIsOpenEditAbsenceOptions={setModalIsOpenEditAbsenceOptions}
          listSelectCompany={listSelectCompany}
        />
      )}
    />
  );
};

interface IContentProps {
  absenceOptions: any[];
  setAbsenceOptions: (value: any) => void;
  select?: number | string;
  listSelectBusiness?: any[];
  selectedIdBusiness?: string | null;
  setModalIsOpenEditAbsenceOptions?: any;
  getAbsenceOptions?: any;
  listSelectCompany?: any[];
}

const Content = ({
  absenceOptions,
  setAbsenceOptions,
  select,
  listSelectBusiness,
  selectedIdBusiness,
  setModalIsOpenEditAbsenceOptions,
  getAbsenceOptions,
  listSelectCompany,
}: IContentProps) => {
  const { error, triggerError, clearError } = useError();

  const handleSubmit = () => {
    axios
      .put('/api/rh/admin/update_absence_option', {
        id_absence_options: absenceOptions.filter((value) => value.id === select).at(0).id,
        pre_week_reduction: absenceOptions.filter((value) => value.id === select).at(0).pre_week_reduction,
        pre_week_promo: absenceOptions.filter((value) => value.id === select).at(0).pre_week_promo,
        pre_hours_reduction: absenceOptions.filter((value) => value.id === select).at(0).pre_hours_reduction,
        post_week_reduction: absenceOptions.filter((value) => value.id === select).at(0).post_week_reduction,
        post_week_promo: absenceOptions.filter((value) => value.id === select).at(0).post_week_promo,
        post_hours_reduction: absenceOptions.filter((value) => value.id === select).at(0).post_hours_reduction,
        idBusiness:
          listSelectBusiness && listSelectBusiness?.filter((value: any) => value.isChecked)?.length > 0
            ? listSelectBusiness?.filter((value: any) => value.isChecked).at(0).id
            : selectedIdBusiness,
      })
      .then((value: any) => {
        if (value.status === StatusCodes.OK) {
          setModalIsOpenEditAbsenceOptions((state: any) => !state);
          getAbsenceOptions(listSelectBusiness, listSelectCompany, null);
        }
      })
      .catch(() => {
        triggerError("Une erreur s'est produite");
      });
  };

  return (
    <Box className="container-fluid col-12">
      <Box className="text-center">
        <span className="modal-titre">ADMINISTRATION</span>
      </Box>
      <br />
      <Box className="text-center mb-4">
        <span className="modal-sous-titre"> Modifier paramètres du congés maternité</span>
      </Box>
      <Box className="col-12 row">
        <Box className="col-4">
          <label className="label-add-user col-12">
            Congés supra légal
            <span style={{ fontSize: '11px' }}>
              <em>(semaines)</em> (pré congé natalité)
            </span>
            <input
              type="text"
              className="card-text"
              name="usermail"
              value={absenceOptions?.filter((value: any) => value.id === select).at(0).pre_week_promo}
              onChange={(e) => {
                setAbsenceOptions((state: any[]) =>
                  state.map((value: any) => {
                    if (value.id === select) {
                      value.pre_week_promo = e.target.value;
                    }
                    return value;
                  }),
                );
              }}
              placeholder={absenceOptions.filter((value: any) => value.id === select).at(0).pre_week_promo}
            ></input>
          </label>
        </Box>
        <Box className="col-4">
          <label className="label-add-user col-12">
            <span style={{ fontSize: '14px' }}>Temps de travail réduit</span>
            <span style={{ fontSize: '11px' }}>
              <em>(semaines)</em> (pré congé natalité)
            </span>
            <input
              type="text"
              className="card-text"
              name="usermail"
              value={absenceOptions.filter((value) => value.id === select).at(0).pre_week_reduction}
              onChange={(e) => {
                setAbsenceOptions((state: any) =>
                  state.map((value: any) => {
                    if (value.id === select) {
                      value.pre_week_reduction = e.target.value;
                    }
                    return value;
                  }),
                );
              }}
              placeholder={absenceOptions.filter((value) => value.id === select).at(0).pre_week_reduction}
            ></input>
          </label>
        </Box>
        <Box className="col-4">
          <label className="label-add-user col-12">
            <span style={{ fontSize: '14px' }}>Temps de travail réduit</span>
            <span style={{ fontSize: '11px' }}>
              <em>(h/jour)</em> (pré congé natalité)
            </span>
            <input
              type="text"
              className="card-text"
              name="usermail"
              value={absenceOptions.filter((value) => value.id === select).at(0).pre_hours_reduction}
              onChange={(e) => {
                setAbsenceOptions((state: any) =>
                  state.map((value: any) => {
                    if (value.id === select) {
                      value.pre_hours_reduction = e.target.value;
                    }
                    return value;
                  }),
                );
              }}
              placeholder={absenceOptions.filter((value) => value.id === select).at(0).pre_hours_reduction}
            ></input>
          </label>
        </Box>
        <Box className="col-4">
          <label className="label-add-user col-12">
            Congés supra légal
            <span style={{ fontSize: '11px' }}>
              <em>(semaines)</em> (post congé natalité)
            </span>
            <input
              type="text"
              className="card-text"
              name="usermail"
              value={absenceOptions.filter((value) => value.id === select).at(0).post_week_promo}
              onChange={(e) => {
                setAbsenceOptions((state: any) =>
                  state.map((value: any) => {
                    if (value.id === select) {
                      value.post_week_promo = e.target.value;
                    }
                    return value;
                  }),
                );
              }}
              placeholder={absenceOptions.filter((value) => value.id === select).at(0).post_week_promo}
            ></input>
          </label>
        </Box>
        <Box className="col-4">
          <label className="label-add-user col-12">
            <span style={{ fontSize: '14px' }}>Temps de travail réduit</span>
            <span style={{ fontSize: '11px' }}>
              <em>(semaines)</em> (post congé natalité)
            </span>
            <input
              type="text"
              className="card-text"
              name="usermail"
              value={absenceOptions.filter((value) => value.id === select).at(0).post_week_reduction}
              onChange={(e) => {
                setAbsenceOptions((state: any) =>
                  state.map((value: any) => {
                    if (value.id === select) {
                      value.post_week_reduction = e.target.value;
                    }
                    return value;
                  }),
                );
              }}
              placeholder={absenceOptions.filter((value) => value.id === select).at(0).post_week_reduction}
            ></input>
          </label>
        </Box>
        <Box className="col-4">
          <label className="label-add-user col-12">
            <span style={{ fontSize: '14px' }}>Temps de travail réduit</span>
            <span style={{ fontSize: '11px' }}>
              <em>(h/jour)</em> (post congé natalité)
            </span>
            <input
              type="text"
              className="card-text"
              name="usermail"
              value={absenceOptions.filter((value) => value.id === select).at(0).post_hours_reduction}
              onChange={(e) => {
                setAbsenceOptions((state: any) =>
                  state.map((value: any) => {
                    if (value.id === select) {
                      value.post_hours_reduction = e.target.value;
                    }
                    return value;
                  }),
                );
              }}
              placeholder={absenceOptions.filter((value) => value.id === select).at(0).post_hours_reduction}
            ></input>
          </label>
        </Box>
      </Box>
      <Box className="col-12 mt-4">
        <Box className="text-center">
          <button
            className="btn-kerij"
            onClick={() => {
              handleSubmit();
            }}
          >
            Valider la modification
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default EditAbsenceModal;
