import * as React from 'react';
import classNames from 'clsx';
import { Typography, type TypographyProps } from '@mui/material';
import { Code } from '../Code';
import { isAllowedStringVariable, StringVariableTranslation } from './translation';

// Name and options
const componentName = 'StringVariable';

// Component properties
export interface StringVariableProps extends Omit<TypographyProps, 'children' | 'fontSize'> {
  value: string;
  fontSize: number;
  ratio?: number;
}

/**
 * Rend les variables plus jolies dans une string
 * Une variable à le même format que pour le back
 *
 * @param props
 */
export function StringVariable(props: StringVariableProps) {
  const { className, value, fontSize, ratio, ...otherProps } = props;

  const variableRegex = /{{(.*?)}}/g;
  const parts = value.split(variableRegex);
  const variableSize = fontSize > 12 && ratio ? fontSize * ratio : fontSize;

  const children = parts.map((part, index) => {
    if (index % 2 !== 1) return part;

    const trimPart = part.trim();
    const displayText = isAllowedStringVariable(trimPart) ? StringVariableTranslation[trimPart] : part;

    return (
      <Code key={index} fontSize={variableSize}>
        {displayText}
      </Code>
    );
  });

  return (
    <Typography component="span" className={classNames(componentName, className)} fontSize={fontSize} {...otherProps}>
      {children}
    </Typography>
  );
}
