import React from 'react';
import { Card } from '@mui/material';
import { Absence } from '../../../../../../types/absences.type';
import { AbsenceDetails, AbsenceDetailsLogs } from '../AbsenceDetails';
import DocumentIcon from '../../../../Icons/DocumentIcon';
import { LinkIcon } from '../../../../Icons';
import { ExportIcon } from '@/components/DashboardRH/Icons';
import { AbsenceCardHeader } from '@/components/DashboardRH/AbsenceCard';
import { ListCardActions, ListCardContent } from '@/components/DashboardRH/ListCard';

interface CardCurrentAbsenceInterface {
  absence: Absence;
}

const CardCurrentAbsence = ({ absence }: CardCurrentAbsenceInterface) => {
  const employeeInformation = absence?.employee_information;

  return (
    <Card className={'col-12'}>
      {employeeInformation && (
        <AbsenceCardHeader
          employeeInformation={employeeInformation}
          absence={absence}
          secondaryAction={
            <>
              <DocumentIcon absence={absence} />
              <LinkIcon absence={absence} />
              <ExportIcon absence={absence} />
            </>
          }
        />
      )}

      <ListCardContent>
        <AbsenceDetails absence={absence} />
      </ListCardContent>

      <ListCardActions collapse collapseTitle="DERNIÈRES ACTIONS">
        <AbsenceDetailsLogs absence={absence} />
      </ListCardActions>
    </Card>
  );
};

export default CardCurrentAbsence;
