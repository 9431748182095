import { EmployeeInformation } from '../../types/user.interface';
import React from 'react';
import { CardActions, CardHeader } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

interface HtmlNotificationInterface {
  employeeInformation: EmployeeInformation;
  children: React.ReactNode;
  title?: string;
}

export const EmployeeNotificationCard = ({
  employeeInformation,
  children,
  title = 'Kerij Vous accompagne',
}: HtmlNotificationInterface) => {
  return (
    <Card>
      <CardHeader
        title={title}
        className={'p-5'}
        sx={{
          color: 'white',
          background: 'linear-gradient(to bottom, rgba(12, 5, 74, 1), rgba(255, 201, 201, 1))',
        }}
      ></CardHeader>
      <CardContent className={'text-center'}>
        <h2>{`Bonjour ${employeeInformation.firstname}`}</h2>
        {children}
        <footer>{"L'équipe Kerij"}</footer>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};
