import * as React from 'react';
import { Button } from '@/components/Button';
import { RHNotification, useAppDispatch, useAppSelector } from '@/store';
import { useNavigate } from 'react-router-dom';
import { PRO_URL } from '@/constants/urls.constants';
import isAuthorized from '@/hooks/isAuthorized';

export function LayoutActions() {
  const location = window.location.pathname;
  const pathToActions = PRO_URL.PREFIXE_PRO + PRO_URL.ACTIONS;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notificationTotal = useAppSelector(RHNotification.getTotal);
  const isActionsPage = React.useMemo(() => location === pathToActions, [location, pathToActions]);

  React.useEffect(() => {
    if (isAuthorized(window.location.pathname)) dispatch(RHNotification.updateTotal());
  }, []);

  return (
    <div style={{ padding: '0.5rem', paddingTop: '2rem' }}>
      <Button
        color={isActionsPage ? 'secondary' : 'primary'}
        variant="contained"
        style={{ width: '100%' }}
        onClick={() => navigate(PRO_URL.PREFIXE_PRO + PRO_URL.ACTIONS)}
      >
        {
          <div style={{ width: '100%', textAlign: 'left' }}>
            Actions à réaliser{' '}
            {notificationTotal > 0 && (
              <span className="pastille-nb-actions" style={{ color: '#0E0653' }}>
                {notificationTotal}
              </span>
            )}
          </div>
        }
      </Button>
    </div>
  );
}
