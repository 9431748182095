import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import ProfilUI from './components/ProfilUI';

export const ProfilPage: React.FC = () => {
  return (
    <DefaultLayout>
      <ProfilUI />
    </DefaultLayout>
  );
};
