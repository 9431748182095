import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../../../../../../constants';

export const CloseAbsenceModalHeader = () => {
  return (
    <Box>
      <Typography
        style={{
          color: colors.darkblue,
          fontSize: '1.5rem',
          fontWeight: 'bold',
        }}
      >{`Clôturer l'absence`}</Typography>
    </Box>
  );
};
