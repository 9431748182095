import React from 'react';
import './LoginRH.css';
import { useNavigate } from 'react-router-dom';
import { PRO_URL } from '../../constants/urls.constants';
import { LoginResponse, isLoginResponse, login } from '@/services/rh/auth/login';
import { UserTypes } from '../../types/userTypes.enum';
import { storeAuthData } from '../../utils/auth.utils';
import { StatusCodes } from 'http-status-codes';
import InputEmailValidate from '../base/input/InputEmailValidate';
import { useError } from '@/shared/useError';
import { FieldValues, useForm } from 'react-hook-form';
import InputPassword from '../base/input/InputPassword';
import { RHAccountContext, useAppDispatch } from '@/store';
import { Spacer } from '@/components/Spacer';
import { useToast } from '../Toast';
import { ActionButton } from '../ActionButton';
import { Box } from '@mui/material';

export const LoginRH = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error, triggerError } = useError();

  const { register, formState, handleSubmit } = useForm();
  const { errors, isValid, isSubmitting } = formState;

  const storeToken = (data: LoginResponse) => {
    const { token, exp, refreshToken, isResetPassword } = data;
    storeAuthData({ userType: UserTypes.HR, token, exp, refreshToken });
    dispatch(RHAccountContext.load());
    if (isResetPassword) navigate(PRO_URL.PREFIXE_PRO + PRO_URL.PASSWORD);
    else navigate(PRO_URL.PREFIXE_PRO + PRO_URL.ACCUEIL);
  };

  const onSubmit = async (event: FieldValues) => {
    const { email, password } = event;
    try {
      const { data, status } = await login({ email, password });

      if (isLoginResponse(data)) storeToken(data);
      else if (status == StatusCodes.TOO_MANY_REQUESTS) triggerError('Trop de requêtes, veuillez réessayer plus tard.');
      else triggerError();
    } catch {
      toast.present({ message: 'Identifiant ou mot de passe invalide', severity: 'error' });
    }
  };

  return (
    <Box>
      <div className="container-fluid col-12">
        <Spacer size="large" />
        <h1 className="KerijHeader m-auto text-center">Authentifiez-vous pour accéder au dashboard entreprise</h1>

        {error && <h5 style={{ color: 'red' }}>{error}</h5>}

        <Spacer size="large" />
        <form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: 260 }}>
          <Spacer />
          <InputEmailValidate label="E-Mail" register={register} error={errors?.email?.message} />
          <InputPassword label="Mot de passe" register={register} error={errors?.password?.message} />
          <Spacer size="small" />

          <Box textAlign={'right'}>
            <ActionButton type="submit" actionName="next" disabled={!isValid || isSubmitting} label={'Valider'} />
          </Box>
        </form>
      </div>
    </Box>
  );
};
