import axios from '@/lib/axios';
import { AxiosResponse } from 'axios';

export const absencesExtract = async (params: absencesExtract.Parameters) => {
  return await axios.get<absencesExtract.APIResponse>(`/api/rh/extract/absences`, { params });
};

export namespace absencesExtract {
  export interface Parameters {
    portfolio?: 'true';
    duration?: string;
  }
  export type Return = AxiosResponse;
  export type APIResponse = {
    Matricule: string;
    Email: string;
    ['Nom prénom']: string;
    ['Date début absence initiale']: string;
    ['Date fin absence en cours']: string;
    ['Initial / Prolongation']: 'Prolongation' | 'Initial';
    ['Nature absence']: string | 'Non défini';
    ['Durée']: string | number;
  }[];
}
