import { Typography } from '@mui/material';
import * as React from 'react';
import type { RenderElementProps, RenderLeafProps } from 'slate-react';

export function useHTMLRender() {
  return {
    renderElement: React.useCallback(({ attributes, children, element }: RenderElementProps): JSX.Element => {
      switch (element.type) {
        case 'block-quote': {
          return <blockquote {...attributes}>{children}</blockquote>;
        }
        // case 'break': {
        //   return <br />;
        // }
        case 'bulleted-list': {
          return <ul {...attributes}>{children}</ul>;
        }
        case 'heading-1': {
          return (
            <Typography variant="h1" component="p" {...attributes}>
              {children}
            </Typography>
          );
        }
        case 'heading-2': {
          return (
            <Typography variant="h2" component="p" {...attributes}>
              {children}
            </Typography>
          );
        }
        case 'heading-3': {
          return (
            <Typography variant="h3" component="p" {...attributes}>
              {children}
            </Typography>
          );
        }
        // case 'heading-4': {
        //     return <h4 {...attributes}>{children}</h4>;
        // }
        // case 'heading-5': {
        //     return <h5 {...attributes}>{children}</h5>;
        // }
        // case 'heading-6': {
        //     return <h6 {...attributes}>{children}</h6>;
        // }
        // case 'image': {
        //   return (
        //     <>
        //       <img
        //         {...attributes}
        //         src={element.url as string}
        //         title={element.title as string}
        //         alt={element.alt as string}
        //       />
        //       {children}
        //     </>
        //   );
        // }
        case 'link': {
          return (
            <a {...attributes} href={element.url as string}>
              {children}
            </a>
          );
        }
        case 'list-item': {
          return <li {...attributes}>{children}</li>;
        }
        case 'numbered-list': {
          return <ol {...attributes}>{children}</ol>;
        }
        case 'paragraph': {
          return <p {...attributes}>{children}</p>;
        }
        case 'table': {
          return (
            <table>
              <tbody {...attributes}>{children}</tbody>
            </table>
          );
        }
        case 'table-row': {
          return <tr {...attributes}>{children}</tr>;
        }
        case 'table-cell': {
          return <td {...attributes}>{children}</td>;
        }
        default: {
          break;
        }
      }
      //return <p {...attributes}>{children}</p>;
      return <span {...attributes}>{children}</span>;
    }, []),
    renderLeaf: React.useCallback(({ attributes, children, leaf }: RenderLeafProps) => {
      if (leaf.bold) {
        children = <strong>{children}</strong>;
      }
      if (leaf.italic) {
        children = <em>{children}</em>;
      }
      if (leaf.strikethrough) {
        children = <s>{children}</s>;
      }
      if (leaf.code) {
        children = <code>{children}</code>;
      }
      if (leaf.underline) {
        children = <u>{children}</u>;
      }
      return <span {...attributes}>{children}</span>;
    }, []),
  };
}
