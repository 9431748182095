import { useMutation, useQuery } from 'react-query';
import * as RhService from '../../../../services/rh/employees';

export const useSearchEmployee = (options: { onSuccess?: () => void; params: RhService.ISearchEmployeeParams }) => {
  return useQuery(
    ['rhSeachEmployee', ...Object.values(options?.params)],
    () => RhService.rhSearchEmployee(options?.params),
    {
      onSuccess: options.onSuccess,
      enabled: true,
      keepPreviousData: true,
    },
  );
};
