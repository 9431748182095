import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '@/components/DashboardRH/DashboardRH.css';
import '../Admin/Admin.css';
import Modal from 'react-modal';
import ImgEdit from '../../../assets/icon-edit.svg';
import axios from '../../../lib/axios';
import { useError } from '@/shared/useError';
import isAuthorized from '../../../hooks/isAuthorized';
import { PRO_URL } from '../../../constants/urls.constants';
import { NotConnectedRH } from '../../../components/DashboardRH/shared/components/NotConnectedRH';
import { RHAccountContext, useAppSelector } from '@/store';

function AdminCorrespTable() {
  const rh = useAppSelector(RHAccountContext.getProfile);
  const isAdmin = useAppSelector(RHAccountContext.isAdmin);
  const [select, setSelect] = useState<any>();

  const listSelectBusiness = useAppSelector((state) => state.rhNavbarContext?.listSelectBusiness);

  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext?.selectedIdBusiness);

  const listSelectCompany = useAppSelector((state) => state.rhNavbarContext?.listSelectCompany);

  const [codeAbsences, setCodeAbsences] = useState<any>([]);
  const [error, setError] = useState<any>('');
  const { triggerError } = useError();
  const location = useLocation();
  const pathname = location.pathname;
  const [modalIsOpenEditCodeAbsences, setModalIsOpenEditCodeAbsences] = useState(false);

  const getCodeAbsence = (listSelectBusiness: any, listSelectCompany: any) => {
    if (rh) {
      const idBusinesses = rh.rh_information.id_business
        ? JSON.stringify([rh.rh_information.id_business])
        : listSelectBusiness.filter((value: any) => value.isChecked).map((value: any) => value.id).length > 0
        ? JSON.stringify(listSelectBusiness.filter((value: any) => value.isChecked).map((value: any) => value.id))
        : '[]';
      const idCompanies = rh.rh_information.id_parent_company
        ? JSON.stringify([rh.rh_information.id_parent_company])
        : listSelectCompany?.filter((value: any) => value.isChecked).length > 0
        ? JSON.stringify(listSelectCompany.filter((value: any) => value.isChecked).map((value: any) => value.id))
        : '[]';
      // const idGroup = rh.rh_information.id_group
      if (
        rh.rh_information.id_parent_company ||
        rh.rh_information.id_business ||
        (JSON.parse(idBusinesses).length > 0 && JSON.parse(idCompanies).length > 0)
      ) {
        axios
          .get('/api/rh/business/get_code_absences', {
            params: {
              idBusiness:
                listSelectBusiness?.filter((value: any) => value.isChecked).length > 0
                  ? listSelectBusiness.filter((value: any) => value.isChecked).at(0).id
                  : selectedIdBusiness,
            },
          })
          .then((value) => {
            if (value.status === 200) {
              setCodeAbsences(value.data.codeAbsences);
            } else {
              setError("Une erreur s'est porduite");
            }
          });
      } else {
        setCodeAbsences([]);
      }
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized(pathname)) {
      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN);
    }
  }, []);

  useEffect(() => {
    getCodeAbsence(listSelectBusiness, listSelectCompany);
  }, [rh, listSelectBusiness, listSelectCompany]);

  return (
    <div>
      <div className="row col-12">
        {isAdmin ? (
          <div className="container-fluid col-12">
            <div className="container-fluid row mt-5">
              {codeAbsences.length > 0 ? (
                <div className="container-fluid col-lg-10 col-10">
                  <div className="col-10 row">
                    <div className="gerer-user col-6">
                      <strong>Table de Correspondance</strong>
                    </div>
                  </div>
                  <hr className="col-10" />
                  {codeAbsences.map((value: any, index: number) => {
                    return (
                      <div className="col-10 row mb-3" key={index}>
                        <div className="col-6 prenom-nom">{value.label}</div>
                        <div className="col-4 poste text-start">{value.code}</div>
                        <div className="col-2 text-start">
                          <img
                            alt="edit"
                            src={ImgEdit}
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSelect(value.id);
                              setModalIsOpenEditCodeAbsences(true);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="container-fluid col-lg-10 col-10">Pas de données</div>
              )}
            </div>
          </div>
        ) : (
          <NotConnectedRH />
        )}

        {codeAbsences?.length && select !== undefined && modalIsOpenEditCodeAbsences && (
          <Modal
            // style={customStyles}
            className="Modal modal-style"
            // overlayclassName="Overlay"
            closeTimeoutMS={200}
            isOpen={modalIsOpenEditCodeAbsences}
            onRequestClose={() => {
              setModalIsOpenEditCodeAbsences(!modalIsOpenEditCodeAbsences);
              setSelect(null);
            }}
            ariaHideApp={false}
          >
            <div className="container-fluid col-12">
              <div className="text-center">
                <span className="modal-titre">ADMINISTRATION</span>
              </div>
              <br />
              <div className="text-center mb-4">
                <span className="modal-sous-titre"> Modifier la table de correspondance</span>
              </div>
              <div className="col-8 row">
                <div className="col-12">
                  <label className="label-add-user col-12">
                    {codeAbsences?.filter((value: any) => value.id === select).at(0).label}
                    <input
                      type="text"
                      className="card-text"
                      name="usermail"
                      value={codeAbsences.filter((value: any) => value.id === select).at(0).code}
                      onChange={(e) => {
                        setCodeAbsences((codeAbsences: any) =>
                          codeAbsences.map((value: any) => {
                            if (value.id === select) {
                              value.code = e.target.value;
                            }
                            return value;
                          }),
                        );
                      }}
                      placeholder={codeAbsences.filter((value: any) => value.id === select).at(0).code}
                    ></input>
                  </label>
                </div>
              </div>
              {error && (
                <div className="col-12">
                  <span style={{ color: 'red' }}>{error}</span>
                </div>
              )}
              <div className="col-12 mt-4">
                <div className="text-center">
                  <button
                    className="btn-kerij"
                    onClick={() => {
                      axios
                        .put('/api/rh/admin/update_code_absence', {
                          id_code_absence: codeAbsences.filter((value: any) => value.id === select).at(0).id,
                          code: codeAbsences.filter((value: any) => value.id === select).at(0)?.code,
                          idBusiness:
                            listSelectBusiness?.filter((value) => value.isChecked).length > 0
                              ? listSelectBusiness.filter((value) => value.isChecked).at(0)?.id
                              : selectedIdBusiness,
                        })
                        .then((value) => {
                          if (value.status === 200) {
                            setModalIsOpenEditCodeAbsences(!modalIsOpenEditCodeAbsences);
                            getCodeAbsence(listSelectBusiness, listSelectCompany);
                          }
                        })
                        .catch(() => triggerError());
                    }}
                  >
                    Valider la modification
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default AdminCorrespTable;
