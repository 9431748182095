import { useEffect, useState } from 'react';
import { notifications } from '../../../constants/notifications.constants';
import { EmployeeInformation } from '../../../types/user.interface';
import NotificationManagementModal from '../Modal/NotificationManagement.modal';
import React from 'react';
import { IconButton, IconButtonProps } from '@/components/IconButton';
import { Notifications } from '@/components/AppIcon';
interface NotificationIconProperties {
  employeeInformation: EmployeeInformation;
  size?: IconButtonProps['size'];
}

const NotificationIcon = ({ employeeInformation, size }: NotificationIconProperties) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [employee, setEmployee] = useState(employeeInformation);
  useEffect(() => {
    setEmployee(employeeInformation);
  }, [employeeInformation]);

  const notificationTooltipText = () => {
    switch (employee?.notification) {
      case notifications.CODE_ALLNOTIFICATIONS: {
        return 'Toutes les notifications';
      }
      case notifications.CODE_ONLYLEAVING: {
        return 'Seulement les notifications administratives';
      }
      case notifications.CODE_NONOTIFICATIONS: {
        return 'Notifications désactivées';
      }
      default: {
        return 'Gérer des notifications';
      }
    }
  };

  const colorIcon = () => {
    let badgeColor: 'warning' | 'error' | 'default' = 'default';

    switch (employee?.notification) {
      case notifications.CODE_ALLNOTIFICATIONS: {
        break;
      }
      case notifications.CODE_ONLYLEAVING: {
        badgeColor = 'warning';
        break;
      }
      case notifications.CODE_NONOTIFICATIONS: {
        badgeColor = 'error';
        break;
      }
      default: {
        badgeColor = 'default';
      }
    }
    return badgeColor;
  };
  return (
    <>
      <IconButton
        size={size}
        onClick={() => {
          setModalIsOpen(true);
        }}
        badge={!!colorIcon()}
        BadgeProps={{
          color: colorIcon(),
        }}
        TooltipProps={{
          title: notificationTooltipText(),
        }}
        icon={<Notifications sx={{ color: 'white' }} />}
      />
      <NotificationManagementModal
        visible={modalIsOpen}
        handleCloseModal={() => {
          setModalIsOpen(false);
        }}
        employeeInformation={employee}
        onUpdated={(updatedEmployee) => setEmployee((state) => ({ ...state, ...updatedEmployee }))}
      />
    </>
  );
};

export default NotificationIcon;
