import React from 'react';
import './FooterRH.css';
import worldIcon from '../../assets/footer-icon-world.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RHNavbarContext } from '@/store';

export function FooterRH() {
  const copyrightYear = RHNavbarContext.getCopyrightYear();

  return (
    <div className="footer-container text-center">
      <div className="footer-links">
        <div className="footer-item footer-kerij">KERIJ {copyrightYear}</div>
        {/* <Link className="footer-item footer-kerij" to={RouterConfig.reportPage().path + '?origin=pro'}>
          Nous signaler un problème
        </Link> */}
      </div>
      <div className="footer-lang">
        <img className="footer-lang-icon" alt={'lang'} src={worldIcon} />
        <div className="footer-lang-selected">Français</div>
        <div className="footer-lang-selector">
          <FontAwesomeIcon icon="chevron-down" className="footer-lang-selector-icon" />
        </div>
      </div>{' '}
    </div>
  );
}
