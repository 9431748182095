import React, { useEffect, useState } from 'react';
import { VerifyingFileModal } from '@/features/dashboard-rh/create-absence-rh/components/Modal/OCR/VerifyingFileModal';
import { Paper, Table, TableBody, TableContainer, TableFooter } from '@mui/material';
import { Absence, DocumentAbsence, DocumentStatus } from '@/types/absences.type';
import { EmployeeInformation } from '@/types/user.interface';
import { createDocumentRequest, createDocumentReset, createDocumentSuccess } from '@/store/action/document';
import { DocumentUploaderHeader } from './DocumentUploaderHeader';
import { DocumentUploaderEmpty } from './DocumentUploaderEmpty';
import { DocumentUploaderDocuments } from './DocumentUploaderDocuments';
import { DocumentUploaderLoading } from './DocumentUploaderLoading';
import { DocumentUploaderFooter } from './DocumentUploaderFooter';
import { getFileFromInput, getTypeDocument } from './data';
import { useAppDispatch, useAppSelector } from '@/store';

interface DocumentUploaderProperties {
  absence: Absence;
  infosEmployee: EmployeeInformation;
  status?: DocumentStatus;
  onDocumentsChanged?: (documents: Array<DocumentAbsence>) => void;
}

export const DocumentUploader = ({
  absence,
  infosEmployee,
  status,
  onDocumentsChanged,
}: DocumentUploaderProperties) => {
  const [file, setFile] = useState<File>();
  const [documents, setDocuments] = useState<Array<DocumentAbsence>>(absence.documents);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentAbsence>();
  const loading = useAppSelector((state) => state.documentCreate.loading);
  const createdDocument = useAppSelector((state) => state.documentCreate.retrieved);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        // @ts-ignore remove when converted to redux slice
        createDocumentReset(),
      );
    };
  }, []);

  useEffect(() => {
    createdDocument &&
      setDocuments((docs: DocumentAbsence[]) =>
        docs.some((doc) => doc.id === createdDocument.id)
          ? docs.map((doc) => (doc.id === createdDocument.id ? createdDocument : doc))
          : [...docs, createdDocument],
      );
  }, [createdDocument]);

  useEffect(() => {
    if (documents !== absence.documents) onDocumentsChanged?.(documents);
  }, [documents]);

  function setFormData(absence: Absence, docFile: File) {
    const idAbsence = absence.id;
    const typeAbsence = absence.type_absence.description?.toString();
    const nameTypeAbsence = getTypeDocument(typeAbsence);

    const formData = new FormData();
    formData.set('idAbsence', idAbsence);
    formData.set('typeDocument', nameTypeAbsence);
    formData.set('documents', docFile);
    if (status) formData.set('status', status);

    return formData;
  }

  const handleOnChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = getFileFromInput(event);
    if (newFile == null) return;
    setFile(newFile);

    //Upload
    const formData = setFormData(absence, newFile);
    const idBusiness_ = infosEmployee?.id_business;
    const idEmployeeInformation_ = infosEmployee?.id;

    if (idBusiness_ && idEmployeeInformation_) {
      dispatch(
        createDocumentRequest({
          params: {
            idBusiness: idBusiness_,
            idEmployeeInformation: idEmployeeInformation_,
          },
          body: formData,
        }) as any,
      );
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <DocumentUploaderHeader />
          <TableBody>
            {file == null && documents?.length === 0 && <DocumentUploaderEmpty />}

            {documents?.length > 0 && (
              <DocumentUploaderDocuments
                documents={documents}
                onStatusClick={(document) => {
                  setIsVisible(true);
                  setSelectedDocument(document);
                }}
              />
            )}

            {loading && file && <DocumentUploaderLoading file={file} />}
          </TableBody>

          <TableFooter>
            <DocumentUploaderFooter
              disabled={
                file != null || documents.some((document_) => ['pending', 'approved'].includes(document_.status))
              }
              onFileChange={handleOnChangeFile}
            />
          </TableFooter>
        </Table>
      </TableContainer>

      {selectedDocument && isVisible && (
        <VerifyingFileModal
          isVisible={isVisible}
          onCloseModal={() => setIsVisible((isVisible) => !isVisible)}
          absence={absence}
          document={selectedDocument}
          employeeInformation={infosEmployee}
          onDocumentUpdated={(document) => {
            dispatch(
              // @ts-ignore remove when converted to redux slice
              createDocumentSuccess(document),
            );
          }}
        />
      )}
    </div>
  );
};
