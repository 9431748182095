import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { colors } from '../../../../../../constants/theme.constants';
import { Absence, AbsenceHistory } from '../../../../../../types/absences.type';
import moment from 'moment';
import { FormatDate } from '../../../../../../constants/date.constants';

interface LineContent {
  label: string;
  value: string;
}

const LineContent = ({ label, value }: LineContent) => (
  <Typography
    style={{
      color: colors.primary,
      fontWeight: 'bold',
    }}
  >
    {label} - <span style={{ color: colors.darkblue, fontWeight: '300' }}>{value}</span>
  </Typography>
);

interface AbsenceDetailsLogsProperties {
  absence: Absence;
}

export const AbsenceDetailsLogs = ({ absence }: AbsenceDetailsLogsProperties) => {
  const logList = useMemo(
    () => absence?.absence_logs?.filter((log) => log.triggered_by !== 'adp' && log.description) ?? [],
    [absence?.absence_logs],
  );

  const isEmpty = useMemo(() => logList.length > 0, [logList]);

  return (
    <Box textAlign="left" width="100%">
      {isEmpty &&
        logList.map((history) => (
          <LineContent
            key={history.id}
            label={history.description ?? 'Erreur de récupération de l’événement'}
            value={moment(history.created_at).format(FormatDate.FRENCH_DATE)}
          />
        ))}

      {!isEmpty && <>Aucun évènements disponible</>}
    </Box>
  );
};
