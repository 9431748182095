import axios from '../../lib/axios';
import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactLoading from 'react-loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { customStyles } from '../../shared/models/custom_modal.models';
import { PRO_URL } from '../../constants/urls.constants';
import { employeeKeepPassword, fetchEmployeeProfile } from '../../services/employee';
import { fetchRhProfile, rhKeepPassword } from '../../services/rh';
import { AccountContext, RHAccountContext, useAppDispatch } from '@/store';

function ModalResetPassword(props) {
  const [modalResetPassword, setModalResetPassword] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const handleKeepPassword = async () => {
    setLoading(true);
    if (props.type === 'employee') {
      await employeeKeepPassword().then(async () => {
        await fetchEmployeeProfile({
          withCredentials: true,
          credentials: 'include',
        }).then((value) => {
          dispatch(AccountContext.actions.setUser(value.data));
          setLoading(false);
          setModalResetPassword(false);
        });
      });
    } else if (props.type === 'rh') {
      await rhKeepPassword({}).then(async () => {
        fetchRhProfile().then((value) => {
          dispatch(RHAccountContext.actions.setRh(value.data.rh));
          setLoading(false);
          setModalResetPassword(false);
        });
      });
    }
  };

  const handleChangePassword = async () => {
    if (props.type === 'employee') {
      navigate('/changepassword');
    } else if (props.type === 'rh') {
      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.PASSWORD);
    }
  };

  return (
    <div>
      <Modal
        style={customStyles}
        className="Modal modal-style"
        overlayclassName="Overlay"
        // className="modal-document"
        closeTimeoutMS={200}
        isOpen={modalResetPassword}
        // onRequestClose={() => {
        //     setModalResetPassword(!modalResetPassword)
        // }}
        ariaHideApp={false}
      >
        {loading ? (
          <div className="container-fluid container-loading">
            <h2>Veuillez patienter</h2>
            <div className="container-load">
              <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
            </div>
          </div>
        ) : (
          <div>
            {/* <div className="float-left">
                            <button onClick={() => { setModalResetPassword(!modalResetPassword) }} className="btn-close exit-modal"></button>
                        </div> */}
            <h3 className="question-form text-center titre-modal">Avertissement</h3>
            <div className="text-center text-modal">
              Cela fait 6 mois que vous utilisez le même mot de passe, il recommandé de le changer
            </div>
            <div className="text-center mb-4 text-modal">Souhaitez-vous changer de mot de passe ?</div>
            <div className="col-12 text-center mb-3">
              <button className="btn btn-kerij-bleu btn-modal col-6" onClick={handleChangePassword}>
                Changer mon mot de passe
              </button>
            </div>
            <div className="col-12 text-center">
              <button className="btn btn-kerij-bleu btn-modal col-6" onClick={handleKeepPassword}>
                Garder mon mot de passe
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default ModalResetPassword;
