/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ListRoles } from './ListBusiness';
import { useError } from '@/shared/useError';
import jwtDecode from 'jwt-decode';
import { rhAclSecurity } from '../../../../services/rh';
import { rhAdminAddUser, rhAdminUpdateUser } from '../../../../services/rh/admin';
import CustomModal from '../../../base/CustomModal';
import { StatusCodes } from 'http-status-codes';
import { ActionButton } from '@/components/ActionButton';
import { useForm } from 'react-hook-form';
import { Typography } from '@mui/material';

const EditUserModal = ({ isOpen, onClosed, user }) => {
  return (
    <CustomModal
      open={isOpen}
      onClose={onClosed}
      renderHeader={() => {
        return user?.id ? <>Modifier un utilisateur</> : <>Créer un utilisateur</>;
      }}
      renderContent={() => <EditUser user={user} onUserUpdated={() => onClosed && onClosed()} />}
    ></CustomModal>
  );
};

const EditUser = ({ user, onUserUpdated }) => {
  const [roleId, setRoleId] = useState(null);
  const { error, triggerError, clearError } = useError();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    if (user) {
      reset({
        prenom: user?.firstname,
        nom: user?.lastname,
        email: user?.rh_login?.email,
      });
    }
  }, [user]);
  //
  // useEffect(() => {
  //     if (userEdit !== user || roleId) setIsButtonDisabled(false);
  // }, [userEdit, roleId]);

  const updateSecurityPermissions = async (userId) => {
    if (roleId) {
      const token = localStorage.getItem('access_token_rh');
      const decodedToken = jwtDecode(token);

      const permissions = {
        id_role: roleId,
        id_business: decodedToken.idBusinesses[0],
        id_rh_information: userId,
      };
      const response = await rhAclSecurity([permissions]);

      if (response.status === StatusCodes.OK) {
        console.log('response acl_security', response.data);
      } else {
        triggerError();
      }
    }
  };

  const onSubmit = (data) => {
    const idUser = user?.id;

    if (idUser) {
      rhAdminUpdateUser({
        idUser: idUser,
        email: data?.email,
        firstname: data?.prenom,
        lastname: data?.nom,
      }).then(async (value) => {
        if (value.status === StatusCodes.OK) {
          await updateSecurityPermissions(value.data?.id);

          onUserUpdated && onUserUpdated(value.data);
        }
      });
    } else {
      rhAdminAddUser({
        email: data?.email,
        firstname: data?.prenom,
        lastname: data?.nom,
      }).then(async (value) => {
        if (value.status === StatusCodes.OK) {
          await updateSecurityPermissions(value.data?.id);

          onUserUpdated && onUserUpdated(value.data);
        }
      });
    }
  };

  return (
    <div className="container-fluid col-12">
      <div className="col-12 row">
        <div className="col-6">
          <label className="label-add-user col-12">
            PRENOM
            <input
              type="text"
              className="card-text"
              name="prenom"
              {...register('prenom', {
                required: 'Le prénom est obligatoire',
              })}
            ></input>
          </label>
          {errors?.prenom && (
            <Typography style={{ color: 'red', marginLeft: '10px' }}>{errors?.prenom?.message}</Typography>
          )}
        </div>
        <div className="col-6">
          <label className="label-add-user col-12">
            NOM
            <input
              type="text"
              className="card-text"
              name="nom"
              {...register('nom', {
                required: 'Le nom est obligatoire',
              })}
            ></input>
          </label>
          {errors?.nom && <Typography style={{ color: 'red', marginLeft: '10px' }}>{errors?.nom?.message}</Typography>}
        </div>
      </div>
      <div className="col-12 row">
        <div className="col-6">
          <label className="label-add-user col-12">
            ADRESSE E-MAIL
            <input
              type="text"
              className="card-text"
              name="email"
              pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              {...register('email', {
                required: "L'email est obligatoire",
                pattern: {
                  value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i,
                  message: `Format d'email invalide`,
                },
              })}
            ></input>
          </label>
          {errors?.email && (
            <Typography style={{ color: 'red', marginLeft: '10px' }}>{errors?.email?.message}</Typography>
          )}
        </div>
        <div className="col-6">
          <ListRoles
            roleId={roleId || user?.rh_secures?.[0]?.id_role}
            onChange={(roleId) => {
              setRoleId(roleId);
            }}
          />
        </div>
        <div className="col-12 mt-4">
          <div className="text-center">
            <ActionButton label="Valider" disabled={!roleId && !user?.id} onClick={handleSubmit(onSubmit)} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditUserModal;
