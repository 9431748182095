import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import ModifyContactMailUI from './components/ModifyContactMailUI';

export const ModifyContactMailPage: React.FC = () => {
  return (
    <DefaultLayout>
      <ModifyContactMailUI />
    </DefaultLayout>
  );
};
