import axios from '@/lib/axios';
import { varToStringParams } from '@/shared/utils/paths';
import { AxiosError } from 'axios';

export const fetchNoteAbsenceOptionsParams = async (params: any) => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'idBusiness',
        value: params.idBusiness,
      },
    ],
  });
  if (!params.idBusiness) {
    return null;
  }
  return await axios.get(`/api/rh/business/get_note_absence_options_params${query}`);
};

export const fetchAbsenceOptionsParams = async (params: any) => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'idBusiness',
        value: params.idBusiness,
      },
    ],
  });
  if (!params.idBusiness) {
    return null;
  }

  return await axios.get(`/api/rh/business/get_absence_options_params${query}`);
};

export const addNoteAbsenceOptionRequest = async (payload: any) => {
  return await axios.post(`/api/rh/business/add_note_absence_option`, payload);
};

interface CrossOverRequest {
  dateStart: string;
  dateEnd: string;
  idEmployeeInformation?: string;
}

export const checkCrossover = async (payload: CrossOverRequest) => {
  try {
    return await axios.post('/api/declaration/verify_crossover', payload);
  } catch (e: unknown) {
    const error: AxiosError = e as any;
    return error.response;
  }
};

export const rhFetchCodeAbsences = async (params: any) => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'idBusiness',
        value: params.idBusiness,
      },
    ],
  });
  if (!params.idBusiness) {
    return null;
  }

  return await axios.get(`/api/rh/business/get_code_absences${query}`);
};

export const fetchFeatures = async () => {
  return await axios.get<{ [featureName: string]: boolean }>(`/api/feature`);
};
