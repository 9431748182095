import { Autocomplete, TextField } from '@mui/material';
import React, { ReactNode, CSSProperties } from 'react';

interface IOption {
  id: string | number;
  label: string;
  labelShow?: string;
}

export interface ISelectSearchProps {
  name: string;
  title: string | JSX.Element;
  rules: any;
  options: IOption[];
  error: boolean;
  required?: boolean;
  disabled?: boolean;
  helperText: ReactNode;
  style?: CSSProperties;
  renderOption?: any;
  onSelected?: any;
  onChangeInput?: any;
  fullWidth?: boolean;
}

export default function AppSelectSearch(props: ISelectSearchProps) {
  const {
    error,
    required,
    disabled,
    helperText,
    name,
    title,
    options,
    renderOption,
    onSelected,
    onChangeInput,
    fullWidth,
    ...other
  } = props;
  const [value, setValue] = React.useState<IOption | null>(null);
  return (
    <Autocomplete
      fullWidth={fullWidth || false}
      onChange={(event, item) => {
        // onChange(item);
        onSelected && onSelected(item);
        setValue(item);
      }}
      value={value}
      options={options}
      getOptionLabel={(item) => item.labelShow || item.label}
      style={{
        marginTop: -16,
      }}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          onChange={(event) => {
            onChangeInput && onChangeInput(event.target.value);
          }}
          {...params}
          label={title}
          variant="outlined"
          error={error}
          helperText={helperText}
          required={required}
          sx={(theme) => ({
            '& input:focus': {
              border: 'none !important',
            },
            '& .MuiOutlinedInput-root': {
              bgcolor: theme.palette.common.white,
            },
          })}
        />
      )}
      renderOption={renderOption}
      autoHighlight
      {...other}
    />
  );
}
