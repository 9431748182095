import Card from '@mui/material/Card';
import React from 'react';
import { RHNotification } from '../../../../../types/rh.interface';
import { DocumentIcon, LinkIcon } from '../../../Icons';
import { AbsenceCardHeader } from '@/components/DashboardRH/AbsenceCard';
import { EmployeeCardHeader } from '@/components/DashboardRH/EmployeeCard';
import { ListCardContent } from '@/components/DashboardRH/ListCard';

export interface NotificationCardInterface {
  rhNotification: RHNotification;
  children?: React.ReactNode;
}

const NotificationCard = ({ rhNotification, children }: NotificationCardInterface) => {
  return (
    <Card className="mb-4 w-100">
      {rhNotification.employee_information && rhNotification.absence && (
        <AbsenceCardHeader
          employeeInformation={rhNotification.employee_information}
          absence={rhNotification.absence}
          secondaryAction={
            <>
              <DocumentIcon absence={rhNotification.absence} />
              <LinkIcon absence={rhNotification.absence} />
            </>
          }
        />
      )}

      {rhNotification.employee_information && !rhNotification.absence && (
        <EmployeeCardHeader employeeInformation={rhNotification.employee_information} />
      )}

      <ListCardContent>{children}</ListCardContent>
    </Card>
  );
};
export default NotificationCard;
