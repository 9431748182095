import React, { useEffect, useState } from 'react';
import '../ChoixDateSalarie/ChoixDateSalarie.css';
import { Link, useSearchParams } from 'react-router-dom';
import logo from '../../../Logotype-Simple-Fondclair.svg';
import ImgRdvEntretienLiaison from '../../../assets/img-declaration-services.svg';
import ImgRdvEntretienRetour from '../../../assets/img-rdv-managerial.svg';
import './ChoixSST.css';
import { encodeToken, verifyToken } from '../../../services/auth';
import { chooseSSTRH } from '../../../services/rh';
import { useToast } from '@/components/Toast';

function ChoixSST() {
  const toast = useToast();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token_decode');
  const [expire, setExpire] = useState('');
  const [decode, setDecode] = React.useState({
    idRhNotification: '',
    idBusiness: '',
    type: '',
    place: '',
    who: '',
    address: '',
    name_business: '',
    name_salarie: '',
    type_contact: '',
  });
  const [choixSST, setChoixSST] = useState('2');

  useEffect(() => {
    if (token) {
      verifyToken({}, token)
        .then((val) => {
          if (val.status === 200) {
            setDecode(val.data.decode);
          }
        })
        .catch(() => {
          toast.present({ message: 'Connexion interrompue', severity: 'error' });
        });
    }
  }, []);

  return (
    <div>
      {expire ? (
        <div className="container-fluid mt-5">
          <h1 className="question-form mb-5">{expire}</h1>
        </div>
      ) : (
        <div>
          <div className="px-4 my-3">
            <Link
              className="text-link mt-4 pt-5"
              to="/declaration"
              style={{
                color: 'inherit',
                textDecoration: 'inherit',
              }}
            >
              <img src={logo} className="App-logo" alt="logo" width="73" />
            </Link>
          </div>
          <div className="container-fluid col-10 col-xl-6 mt-5">
            <img
              alt="kerij"
              className="mb-3 img-rdv"
              src={decode.type.includes('liaison') ? ImgRdvEntretienLiaison : ImgRdvEntretienRetour}
            />
            <div className="text-center col-12 col-xl-10 mt-3">
              <div className="text-center col-12 mb-3">
                <h5 className="details-entretien">
                  {` L'entreprise `}
                  <b>
                    <u>{decode.name_business}</u>
                  </b>{' '}
                  situé au <b>{decode.address.toLowerCase()}</b>, organise un{' '}
                  <b>{decode.type.includes('liaison') ? 'entretien de liaison ' : "entretien de retour à l'emploi "}</b>
                  pour <b>{decode.name_salarie}</b> en {decode.place === 'present' ? 'présentiel' : 'distanciel'}
                </h5>
              </div>
              <div className="text-center col-12 mb-2 text-modal mt-3">
                Souhaitez vous participer à{' '}
                {decode.type.includes('liaison') ? "l'entretien de liaison" : "l'entretien de retour à l'emploi"} ?
              </div>
              <label className="col-12 col-xl-6 mb-3">
                <select
                  className="form-select text-modal-select short-text mt-3"
                  value={choixSST}
                  onChange={(e) => {
                    setChoixSST(e.target.value);
                  }}
                  required
                >
                  {decode.place === 'present' && <option value="1">Oui, en présentiel</option>}
                  <option value="2">Oui, en distanciel</option>
                  <option value="3">Je ne peux pas participer</option>
                </select>
              </label>
            </div>
            <div className="text-center col-10 col-xl-4">
              <button
                value={1}
                className="btn btn-kerij-bleu btn-modal col-12 mt-3"
                onClick={() => {
                  encodeToken({
                    idRhNotification: decode.idRhNotification,
                    idBusiness: decode.idBusiness,
                  }).then((val) => {
                    if (val.status === 200) {
                      const token = val.data.token;
                      chooseSSTRH(token, {
                        response: choixSST,
                        type: decode.type,
                        who: decode.who,
                        type_contact: decode.type_contact,
                      })
                        .then((value) => {
                          if (value.status === 200) {
                            window.close();
                            setExpire("Ce lien n'est plus disponible");
                          }
                        })
                        .catch((error) => {
                          alert(JSON.stringify(error.response));
                        });
                    }
                  });
                }}
              >
                {' '}
                Valider
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChoixSST;
