import * as React from 'react';
import classNames from 'clsx';
import { Parcours } from '@/types/Parcours';
import { ParcoursListItemDetails } from './ParcoursListItemDetails';
import { ParcoursListItemHeader } from './ParcoursListItemHeader';
import { parcoursDelete } from '@/services/rh/parcours';
import { ParcoursEditDialog } from '../ParcoursEditDialog/ParcoursEditDialog';
import { useMUIDialogController } from '@/hooks/useMUIDialogController';
import { Card } from '@mui/material';
import { ListCardActions, ListCardContent } from '../DashboardRH/ListCard';
import moment from 'moment';
import { FormatDate } from '@/constants/date.constants';
import { useToast } from '../Toast';

const componentName = 'ParcoursListItem';

export interface ParcoursListItemProps {
  value: Parcours;
  onChange?: (type: 'edit' | 'edited' | 'deleted') => void;
}

export function ParcoursListItem(props: ParcoursListItemProps) {
  const toast = useToast();
  const { value, onChange } = props;
  const parcoursEditDialogController = useMUIDialogController();

  const deleteParcours = async () => {
    await parcoursDelete(value.id);
    toast.present({ message: `Le parcours est supprimé`, severity: 'success' });
    onChange?.('deleted');
  };

  const editParcours = async () => {
    parcoursEditDialogController.control.setOpen(true);
    onChange?.('edit');
  };

  const changeParcours = (type: 'edit' | 'edited' | 'deleted') => {
    onChange?.(type);
  };

  const updatedAt = `Dernière modification le ${moment(value.updated_at).format(FormatDate.FRENCH_DATE)}`;

  return (
    <Card className={classNames(componentName)}>
      <ParcoursEditDialog value={value} onChange={changeParcours} {...parcoursEditDialogController.getRootProps()} />

      <ParcoursListItemHeader
        value={value}
        onClickAction={(type) => (type === 'delete' ? deleteParcours() : editParcours())}
      />

      <ListCardContent>
        <ParcoursListItemDetails value={value.script} />
      </ListCardContent>

      <ListCardActions>{updatedAt}</ListCardActions>
    </Card>
  );
}
