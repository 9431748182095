import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import '../Chart.css';
import Slider from '@mui/material/Slider';
import moment from 'moment';
import ChartZoom from 'chartjs-plugin-zoom';
import { useFetchRhGraphDataTrending } from '../api';
import { useAppSelector } from '@/store';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
  ChartZoom,
);

const LineChartHospitalisation = () => {
  const chartRef = useRef(null);
  const listSelectBusiness = useAppSelector((state) => state.rhNavbarContext?.listSelectBusiness);
  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext?.selectedIdBusiness);
  const marks = [
    {
      value: 30,
      label: '30 jours',
    },
    {
      value: 90,
      label: '90 jours',
    },
    {
      value: 180,
      label: '180 jours',
    },
    {
      value: 360,
      label: '360 jours',
    },
  ];

  const valuetext = (value) => {
    return `${value} jours`;
  };

  const [day, setDay] = useState(30);
  const [data, setData] = useState({
    labels: [''],
    datasets: [
      {
        label: `Nombre d'arrêts d'hospitalisation`,
        data: [0],
        fill: false,
        borderColor: 'rgba(54, 162, 235, 1)',
        tension: 0,
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: 'Line chart with Configuration',
      },
      label: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          drag: {
            enabled: true,
            modifierKey: 'ctrl',
          },
          enabled: true,
          mode: 'xy',
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (value, index) {
            return index % 5 === 0 ? this.getLabelForValue(value) : '';
          },
          stepsize: day / 5,
          align: 'center',
          beginAtZero: true,
        },
      },
      y: {
        min: 0,
      },
    },
  };

  // const getDataTrending = (day, listSelectBusiness, selectedIdBusiness) => {
  //     axios
  //         .get('/api/rh/graph/data_trending', {
  //             params: {
  //                 currentDay: moment().subtract(1, 'd').format('YYYY-MM-DD'),
  //                 day: day,
  //                 type: 2,
  //                 idBusiness: selectedIdBusiness,
  //                 idBusinesses: JSON.stringify(
  //                     listSelectBusiness.filter((val) => val.isChecked).map((val) => val.id),
  //                 ),
  //             },
  //         })
  //         .then((value) => {
  //             setData({
  //                 labels: value.data.trending.map((val) => val.day),
  //                 datasets: [
  //                     {
  //                         label: `Nombre d'arrêts d'hospitalisation`,
  //                         data: value.data.trending.map((val) => val.totalNumber),
  //                         fill: false,
  //                         borderColor: 'rgba(54, 162, 235, 1)',
  //                         tension: 0,
  //                     },
  //                 ],
  //             });
  //         });
  // };

  // useEffect(() => {
  //     if (selectedIdBusiness) {
  //         getDataTrending(day, listSelectBusiness, selectedIdBusiness)
  //     }
  // }, [day, listSelectBusiness, selectedIdBusiness])

  useFetchRhGraphDataTrending(
    {
      currentDay: moment().subtract(1, 'd').format('YYYY-MM-DD'),
      day: day,
      type: 2,
      idBusiness: selectedIdBusiness,
      idBusinesses: JSON.stringify(listSelectBusiness.filter((val) => val.isChecked).map((val) => val.id)),
      isAuthorized: true,
    },
    {
      onSuccess: (value) => {
        setData({
          labels: value.data.trending.map((val) => val.day),
          datasets: [
            {
              label: `Nombre d'arrêts d'hospitalisation`,
              data: value.data.trending.map((val) => val.totalNumber),
              fill: false,
              borderColor: 'rgba(54, 162, 235, 1)',
              tension: 0,
            },
          ],
        });
      },
    },
  );

  return (
    <div className="row col-12">
      <div className="p-2 flex-fill bd-highlight col-10" id="linecharthospitalisation">
        <div className="container-fluid mt-5">
          <div className="chart-container">
            <div className="chart-item">
              <h1 className="titre-rh mb-5">La tendance de type hospitalisation depuis {day ? `J-${day}` : 'J-0'}</h1>
              <Slider
                defaultValue={day}
                value={day}
                onChangeCommitted={(event, newVal) => {
                  setDay(newVal);
                }}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                step={null}
                marks={marks}
                min={30}
                max={360}
              ></Slider>
              <div
                style={{
                  height: '600px',
                  width: '50vw',
                  position: 'relative',
                }}
              >
                <Line data={data} options={options} ref={chartRef}></Line>
              </div>
              <button
                onClick={() => {
                  if (chartRef.current) {
                    chartRef.current.resetZoom();
                  }
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineChartHospitalisation;
