import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import ModifyContactUI from './components/ModifyContactUI';

export const ModifyContactPage: React.FC = () => {
  return (
    <DefaultLayout>
      <ModifyContactUI />
    </DefaultLayout>
  );
};
