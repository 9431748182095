import { EmployeeInformation } from '../../../../types/user.interface';
import { statementStep } from '../constants/step.constants';

export interface Period {
  dateDebut: string;
  dateFin: string;
  error: number | string;
}
export enum ProlongationChoices {
  'INITIAL' = '1',
  'PROLONGATION' = '2',
}

type DateString = string;

export interface AbsenceCreation {
  absenceType: string;
  absenceType1?: string;
  absenceType2?: string;
  lastDayWork?: DateString;
  dateDebut?: DateString;
  dateFin?: DateString;
  period: Period[];
  files: File[];
  stateAbsence?: string;
  isClickedOption1?: boolean;
  isClickedOption2?: boolean;
  dateNaissance?: string;
  dateAbsence?: string;
  isWorkDay?: boolean;
  relationEnfant?: string;
  dateEndReduction?: string;
  hoursReduction?: string;
  dateEndPromo?: string;
  isTextArea?: boolean;
  message?: string;
  withNoDoc: boolean;
  prolongation: boolean;
}

export interface StepProperties {
  absence: AbsenceCreation;
  setAbsence: {
    (absenceCreation: AbsenceCreation): void;
    (modify: (absenceCreation: AbsenceCreation) => AbsenceCreation): void;
  };
  handleChangeStep: (step: statementStep) => void;
  infosEmployee: EmployeeInformation;
}
