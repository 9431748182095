/* eslint-disable unicorn/no-negated-condition */
import { CrashReporter as createCrashReporter } from '@captive/crash-reporter';
import { SentryPlugin } from '@captive/crash-reporter/dist/plugin/sentry';
import * as Sentry from '@sentry/react';

const environmentName = process.env['ENVIRONMENT'] ?? process.env['NODE_ENV'] ?? 'production';
const isDevelopment = environmentName === 'development' || environmentName === 'local';

export const CrashReporter = createCrashReporter({
  app: 'kerij-frontend',
  enabled:
    process.env['REACT_APP_CRASH_REPORT'] != null
      ? ['true', '1'].includes(process.env['REACT_APP_CRASH_REPORT'])
      : !isDevelopment,
  debug:
    process.env['REACT_APP_CRASH_REPORT_DEBUG'] != null
      ? ['true', '1'].includes(process.env['REACT_APP_CRASH_REPORT_DEBUG'])
      : isDevelopment,
  environment: environmentName,
  plugin: SentryPlugin(Sentry, {
    dsn: 'https://45e2db5ebbcc92d6cd2d314e7b735c5d@o4504609984086016.ingest.us.sentry.io/4507102060216320',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.01,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/.*\.kerij\.fr\/api/],
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  }),
});
