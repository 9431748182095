// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .KRJButton {
  height: 3rem;
}

.KRJButton--primary {
  border-radius: 10px !important;
}

.KRJButton--secondary {
  border-radius: 10px !important;
  font-size: 0.75rem !important;
}

.KRJButton--tertiary {
  font-size: 12px !important;
  border-radius: 10px !important;
} */

.KRJButton__Loader {
  height: 2rem !important; /* FIXME: utiliser des em  */
  width: 2rem !important; /* FIXME: utiliser des em  */
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;GAgBG;;AAEH;EACE,uBAAuB,EAAE,4BAA4B;EACrD,sBAAsB,EAAE,4BAA4B;AACtD","sourcesContent":["/* .KRJButton {\n  height: 3rem;\n}\n\n.KRJButton--primary {\n  border-radius: 10px !important;\n}\n\n.KRJButton--secondary {\n  border-radius: 10px !important;\n  font-size: 0.75rem !important;\n}\n\n.KRJButton--tertiary {\n  font-size: 12px !important;\n  border-radius: 10px !important;\n} */\n\n.KRJButton__Loader {\n  height: 2rem !important; /* FIXME: utiliser des em  */\n  width: 2rem !important; /* FIXME: utiliser des em  */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
