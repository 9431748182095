import React from 'react';
import { Navbar } from '@/components/Navbar/Navbar';
import { NavbarMobile } from '@/components/Navbar/NavbarMobile';
import Footer from '../Footer/Footer';
import './PolicyLayout.scss';

export interface PolicyLayoutProps extends React.ComponentProps<'div'> {
  title: string;
  date: string;
  withNavigation?: boolean;
}
export const PolicyLayout = ({ children, title, date, withNavigation = true }: PolicyLayoutProps) => {
  return (
    <>
      {withNavigation && <Navbar />}
      <div className="policy-layout">
        <div className="policy-layout-header">
          <h1>{title}</h1>
          <h4>DERNIERE MISE A JOUR : {date}</h4>
        </div>
        <div className="policy-layout-content">{children}</div>
      </div>
      {withNavigation && <NavbarMobile />}
      {withNavigation && <Footer />}
    </>
  );
};
