import { Dispatch } from 'redux';
import * as rhServices from '../../../services/rh';
import {
  AbsenceCurrentActionTypes,
  AbsenceHistoryActionTypes,
  IAbsenceCurrentAttemptAction,
  IAbsenceCurrentFailureAction,
  IAbsenceCurrentSuccessAction,
  IAbsenceHistoryAttemptAction,
  IAbsenceHistoryFailureAction,
  IAbsenceHistoryLoadedAction,
  IAbsenceHistorySuccessAction,
} from './type';
// import { RhUploadFileAbsenceRequest } from '../../../services/rh';
export const getAbsenceCurrentRequest = (search?: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(getAbsenceCurrentAttempt(true));
      const response: any = await rhServices.fetchCurrentAbsences({
        search: search,
      });

      dispatch(getAbsenceCurrentSuccess(response.data));
      dispatch(getAbsenceCurrentAttempt(false));
    } catch (error: any) {
      dispatch(getAbsenceCurrentFailure(error.message));
      dispatch(getAbsenceCurrentAttempt(false));
    }
  };
};

export const getMoreAbsenceCurrentRequest = (offset: number, onSuccess?: () => void) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(getAbsenceCurrentAttempt(true));
      const response: any = await rhServices.fetchCurrentAbsences({
        offset,
      });
      dispatch(getMoreAbsenceCurrentSuccess(response.data));
      dispatch(getAbsenceCurrentAttempt(false));
      onSuccess && onSuccess();
    } catch (error: any) {
      dispatch(getAbsenceCurrentFailure(error.message));
      dispatch(getAbsenceCurrentAttempt(false));
    }
  };
};

export const getAbsenceCurrentSuccess = (payload: any): IAbsenceCurrentSuccessAction => {
  return {
    type: AbsenceCurrentActionTypes.LIST_CURRENT_ABSENCE,
    payload,
  };
};

export const getMoreAbsenceCurrentSuccess = (payload: any): IAbsenceCurrentSuccessAction => {
  return {
    type: AbsenceCurrentActionTypes.LIST_CURRENT_ABSENCE_SUCCESS,
    payload,
  };
};

export const getAbsenceCurrentFailure = (error?: any): IAbsenceCurrentFailureAction => {
  return {
    type: AbsenceCurrentActionTypes.LIST_CURRENT_ABSENCE_FAILURE,
    payload: error,
  };
};

export const getAbsenceCurrentAttempt = (loading: boolean): IAbsenceCurrentAttemptAction => {
  return {
    type: AbsenceCurrentActionTypes.LIST_CURRENT_ABSENCE_LOADING,
    payload: loading,
  };
};

// For history absence

interface IAbsenceHistoryRequest {
  search?: string;
  offset?: number;
  limit?: number;
}

export const getAbsenceHistoryRequest = (params: IAbsenceHistoryRequest) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(getAbsenceHistoryAttempt(true));
      const response = await rhServices.fetchRhAbsencesHistories(params);

      const data = response.data;
      const convertedData = {
        count: data.length,
        historyAbsences: data ?? [],
      };

      dispatch(getAbsenceHistorySuccess(convertedData));
      dispatch(getAbsenceHistoryAttempt(false));
      dispatch(getAbsenceHistoryLoaded(params.limit == null || convertedData.count < params.limit));
    } catch (error: any) {
      dispatch(getAbsenceHistoryFailure(error.message));
      dispatch(getAbsenceHistoryAttempt(false));
    }
  };
};

export const getMoreAbsenceHistoryRequest = (params: IAbsenceHistoryRequest, onSuccess?: () => void) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(getAbsenceHistoryAttempt(true));
      const response = await rhServices.fetchRhAbsencesHistories(params);
      const data = response.data;
      const convertedData = {
        count: data.length,
        historyAbsences: data ?? [],
      };
      dispatch(getMoreAbsenceHistorySuccess(convertedData));
      dispatch(getAbsenceHistoryAttempt(false));
      dispatch(getAbsenceHistoryLoaded(params.limit == null || convertedData.count < params.limit));
      onSuccess && onSuccess();
    } catch (error: any) {
      dispatch(getAbsenceHistoryFailure(error.message));
      dispatch(getAbsenceHistoryAttempt(false));
    }
  };
};

export const getAbsenceHistorySuccess = (payload: any): IAbsenceHistorySuccessAction => {
  return {
    type: AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE,
    payload,
  };
};

export const getMoreAbsenceHistorySuccess = (payload: any): IAbsenceHistorySuccessAction => {
  return {
    type: AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE_SUCCESS,
    payload,
  };
};

export const getAbsenceHistoryFailure = (error?: any): IAbsenceHistoryFailureAction => {
  return {
    type: AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE_FAILURE,
    payload: error,
  };
};

export const getAbsenceHistoryAttempt = (loading: boolean): IAbsenceHistoryAttemptAction => {
  return {
    type: AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE_LOADING,
    payload: loading,
  };
};

export const getAbsenceHistoryLoaded = (loaded: boolean): IAbsenceHistoryLoadedAction => {
  return {
    type: AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE_LOADED,
    payload: loaded,
  };
};
