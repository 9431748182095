/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { AbsenceCreation, StepProperties } from '../props/step.interface';
import { statementStep } from '../constants/step.constants';
import { absenceType, toSelect } from '../constants/absenceType.constant';

const getAbsenceType = (absence: AbsenceCreation) => {
  const type = absence?.absenceType || '';
  const type1 = absence?.absenceType1 || '';

  if (type === `${absenceType.hospitalisation}`) return absenceType.hospitalisation;
  if (type === `${absenceType.arret_maladie}`)
    return type1 === '2' ? absenceType.accident_arret_professionnel : absenceType.arret_maladie;

  return toSelect;
};

export const AbsenceType = ({ absence, setAbsence, handleChangeStep, infosEmployee }: StepProperties) => {
  const handleChangeTypeAbsence = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!infosEmployee) return;

    const baseValidAbsence = {
      stateAbsence: '0',
      dateDebut: '',
      dateFin: '',
      period: [{ dateDebut: '', dateFin: '', error: -1 }],
    };
    let currentAbsence: Partial<AbsenceCreation>;
    let nextStep: statementStep;

    switch (Number.parseInt(event.target.value)) {
      case toSelect: {
        currentAbsence = { absenceType: `${toSelect}` };
        nextStep = statementStep.TypeAbsence;
        break;
      }
      case absenceType.arret_maladie: {
        currentAbsence = { ...baseValidAbsence, absenceType: '1', absenceType1: '1' };
        nextStep = statementStep.IsProlongation;
        break;
      }
      case absenceType.accident_arret_professionnel: {
        currentAbsence = { ...baseValidAbsence, absenceType: '1', absenceType1: '2' };
        nextStep = statementStep.IsProlongation;
        break;
      }
      case absenceType.hospitalisation: {
        currentAbsence = { ...baseValidAbsence, absenceType: event.target.value, absenceType1: '0' };
        nextStep = statementStep.Date;
        break;
      }
      default: {
        currentAbsence = { ...baseValidAbsence, absenceType: event.target.value, absenceType1: '0' };
        nextStep = statementStep.TypeAbsence1;
        break;
      }
    }
    setAbsence((absence: AbsenceCreation) => ({ ...absence, ...currentAbsence }));
    handleChangeStep(nextStep);
  };

  return (
    <div className="form-declaration text-center col-lg-12">
      <label className="col-8">
        <select
          className="form-select"
          name="absenceType"
          value={getAbsenceType(absence)}
          onChange={handleChangeTypeAbsence}
          required
        >
          <option value={toSelect}>{`Choisir type d'absence`}</option>
          <option value={absenceType.arret_maladie}>Arrêt maladie</option>
          <option value={absenceType.hospitalisation}>Hospitalisation</option>
          <option value={absenceType.accident_arret_professionnel}>Accident/Maladie professionnelle</option>
        </select>
      </label>
    </div>
  );
};
