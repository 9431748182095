import Modal from 'react-modal';
import { defaultStyles } from '../../../../shared/ui/modals/baseModalRH';
import axios from 'axios';
import React from 'react';
import { useError } from '@/shared/useError';
import { StatusCodes } from 'http-status-codes';

const DeleteActionModal = ({
  isOpen,
  onRequestClose,
  customStyles,
  className,
  overlayClassName,
  closeTimeoutMS = 200,
  ariaHideApp = false,
  onLoadingChange,
  select,
  selectedIdBusiness,
  onUpdate,
}) => {
  const styles = customStyles || defaultStyles;
  const { triggerError } = useError();

  const deleteEvent = () => {
    onLoadingChange(true);
    axios
      .post('/api/rh/delete_notif', {
        idRhNotification: select,
        idBusiness: selectedIdBusiness,
      })
      .then((value) => {
        if (value.status === StatusCodes.OK) {
          onRequestClose();
          onLoadingChange(false);
          onUpdate();
        }
      })
      .catch((error) => {
        onLoadingChange(false);
        triggerError(error);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={styles}
      className={className}
      overlayClassName={overlayClassName}
      closeTimeoutMS={closeTimeoutMS}
      ariaHideApp={ariaHideApp}
    >
      <div className="float-left">
        <button onClick={onRequestClose} className="btn-close exit-modal"></button>
      </div>
      <h3 className="question-form text-center titre-modal">Attention</h3>
      <div className="text-center text-modal">Vous êtes sur le point de supprimer l'évènement</div>
      <div className="text-center mb-4 text-modal">Etes-vous sur de votre choix ?</div>
      <div className="text-center">
        <button value={1} className="btn btn-kerij-bleu btn-modal col-6" onClick={deleteEvent}>
          {' '}
          Valider
        </button>
      </div>
    </Modal>
  );
};

export default DeleteActionModal;
