import * as React from 'react';
import { Box, InputAdornment, Typography } from '@mui/material';
import { AppTextField } from '@/components/AppTextField';
import { AppSelect, AppSelectMultiple } from '@/components/AppSelect';
import {
  isParcoursRuleComparableKey,
  isParcoursRuleIdentifiableKey,
  isParcoursRuleOperator,
  ParcoursRuleKey,
  ParcoursRuleKeyLabel,
  ParcoursRuleOperator,
  ParcoursRuleOperatorComparable,
  ParcoursRuleOperatorIdentifiable,
  ParcoursRuleOperatorLabel,
  ParcoursRuleType,
} from '@/types';
import { AppTextFieldHelper } from '@/components/AppTextFieldHelper';
import { IconButton } from '@/components/IconButton';
import { ParcoursRuleValue } from '@/types/ParcoursRuleValue';
import { Check, Delete } from '@/components/AppIcon';

export interface ParcoursInputRule {
  order: number;
  key: ParcoursRuleKey;
  operator?: ParcoursRuleOperator;
  value?: ParcoursRuleValue;
}

export interface ParcoursInputProps {
  value: ParcoursInputRule;
  adding?: boolean;
  scriptKeys: Array<ParcoursRuleKey | null>;
  onConfirm?: (value: ParcoursInputRule, options?: { duration: number }) => void;
  onChange?: (value: ParcoursInputRule) => void;
  onDelete?: (value: ParcoursInputRule) => void;
}

export function ParcoursInput(props: ParcoursInputProps) {
  const { value, adding, scriptKeys, onChange, onConfirm, onDelete } = props;

  const [ruleValue, setRuleValue] = React.useState<ParcoursRuleKey | undefined>(value.key);
  const [operatorValue, setOperatorValue] = React.useState<ParcoursRuleOperator | undefined>(value.operator);
  const [customValue, setCustomValue] = React.useState<string | undefined>();
  const [customMultipleValue, setCustomMultipleValue] = React.useState<string[]>();
  const [durationValue, setDurationValue] = React.useState<string | undefined>();

  const operatorItems = React.useMemo(() => {
    if (isParcoursRuleComparableKey(ruleValue)) {
      return Object.entries(ParcoursRuleOperatorComparable).map(([key, value]) => ({
        label: ParcoursRuleOperatorLabel[value],
        value: value,
      }));
    } else if (isParcoursRuleIdentifiableKey(ruleValue)) {
      return Object.entries(ParcoursRuleOperatorIdentifiable).map(([key, value]) => ({
        label: ParcoursRuleOperatorLabel[value],
        value: value,
      }));
    } else {
      return [];
    }
  }, [ruleValue]);

  function initValue() {
    setRuleValue(value.key);
    setOperatorValue(value.operator);
    setCustomValue(
      typeof value?.value === 'string' || typeof value?.value === 'number'
        ? `${value.value}`
        : typeof (value?.value as any)?.value === 'number' || typeof (value?.value as any)?.value === 'string'
        ? `${(value?.value as any)?.value}`
        : undefined,
    );
    setCustomMultipleValue(Array.isArray(value?.value) ? value.value.map((v) => `${v}`) : []);
    setDurationValue(
      (value?.value as any)?.since
        ? (value?.value as any)?.sinceUnit === 'days'
          ? `${(value?.value as any).since}`
          : `${(value?.value as any)?.since * 365}`
        : undefined,
    );
  }

  React.useEffect(() => initValue(), [value]);
  return (
    <>
      <div className="row">
        <div className="col-4" style={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 2 }}>
            <IconButton
              size="medium"
              icon={<Delete />}
              disabled={ruleValue && scriptKeys.includes(ruleValue) && adding}
              onClick={() => onDelete?.(value)}
            />
          </Box>

          <AppTextField
            value={ruleValue ? ParcoursRuleKeyLabel[ruleValue] : ruleValue}
            label={''}
            readOnly
            sx={{ flex: 1 }}
          />
        </div>

        <div className="col-2">
          <AppSelect
            value={operatorValue}
            label={''}
            placeholder="Sélectionnez un opérateur"
            items={operatorItems}
            disabled={ruleValue == null}
            readOnly={ruleValue && scriptKeys.includes(ruleValue)}
            onChange={(_, _value) => {
              const newValue = isParcoursRuleOperator(_value) ? _value : undefined;
              setOperatorValue(newValue);
              onChange?.({ ...value, operator: newValue });
            }}
          />
        </div>

        {ruleValue &&
          (ParcoursRuleType[ruleValue].type === 'number' || ParcoursRuleType[ruleValue].type === 'duration') && (
            <div className="col-1" style={{ display: 'flex' }}>
              <AppTextField
                value={customValue}
                InputProps={{
                  type: 'number',
                  componentsProps: {
                    input: {
                      max: 999,
                      maxLength: 3,
                      style: { textAlign: 'center' },
                    },
                  },
                }}
                label={''}
                disabled={operatorValue == null}
                readOnly={ruleValue && scriptKeys.includes(ruleValue)}
                onChange={(_, _value) => {
                  const newValue = _value == null || _value.trim() === '' ? undefined : _value;
                  setCustomValue(newValue);
                  onChange?.({ ...value, value: { value: Number.parseInt(_value) } });
                }}
              />
            </div>
          )}

        {ruleValue && ParcoursRuleType[ruleValue].type === 'date' && (
          <div className="col-3" style={{ display: 'flex' }}>
            <AppTextFieldHelper label="">
              <input
                value={customValue}
                type="date"
                readOnly={ruleValue && scriptKeys.includes(ruleValue)}
                onChange={(event) => {
                  setCustomValue(event.target.value);
                  onChange?.({ ...value, value: event.target.value });
                }}
              />
            </AppTextFieldHelper>
          </div>
        )}

        {ruleValue && ParcoursRuleType[ruleValue].type === 'enum' && operatorValue !== ParcoursRuleOperator.in && (
          <div className="col-3" style={{ display: 'flex' }}>
            <AppSelect
              value={customValue}
              label={''}
              items={(ParcoursRuleType[ruleValue] as any).items}
              disabled={operatorValue == null}
              readOnly={ruleValue && scriptKeys.includes(ruleValue)}
              onChange={(_, _value) => {
                const newValue = _value == null || _value.trim() === '' ? undefined : _value;
                setCustomValue(newValue);
                onChange?.({ ...value, value: newValue });
              }}
            />
          </div>
        )}

        {ruleValue && ParcoursRuleType[ruleValue].type === 'enum' && operatorValue === ParcoursRuleOperator.in && (
          <div className="col-3" style={{ display: 'flex' }}>
            <AppSelectMultiple
              value={customMultipleValue ?? []}
              label={''}
              items={(ParcoursRuleType[ruleValue] as any).items}
              disabled={operatorValue == null}
              readOnly={ruleValue && scriptKeys.includes(ruleValue)}
              onChange={(_, _value) => {
                setCustomMultipleValue(_value ?? []);
                onChange?.({ ...value, value: _value ?? [] });
              }}
            />
          </div>
        )}

        {ruleValue && ParcoursRuleType[ruleValue].type === 'duration' && (
          <>
            <div className="col-2" style={{ display: 'flex' }}>
              <Typography m={'auto'} variant="body2" fontWeight="bold" color="secondary">
                OBSERVATION SUR
              </Typography>
            </div>

            <div className="col-2">
              <AppTextField
                value={durationValue}
                InputProps={{
                  type: 'number',
                  componentsProps: {
                    input: {
                      max: 999,
                      maxLength: 3,
                      style: { textAlign: 'right' },
                    },
                  },
                  className: 'align-items-center',
                  endAdornment: <InputAdornment position="end">jour(s)</InputAdornment>,
                }}
                label={''}
                readOnly={ruleValue && scriptKeys.includes(ruleValue)}
                onChange={(_, _value) => {
                  setDurationValue(_value);
                  onChange?.(value);
                }}
              />
            </div>
          </>
        )}

        {ruleValue && !scriptKeys.includes(ruleValue) && (
          <div className="col-1">
            <IconButton
              size="small"
              icon={<Check />}
              disabled={
                (customValue == null && operatorValue !== ParcoursRuleOperator.in) ||
                (customMultipleValue &&
                  customMultipleValue.length === 0 &&
                  operatorValue === ParcoursRuleOperator.in) ||
                (ParcoursRuleType[ruleValue].type === 'duration' && durationValue == null)
              }
              sx={{ position: 'relative', top: '1.25rem' }}
              onClick={() => {
                onConfirm?.(value, durationValue ? { duration: Number.parseInt(durationValue) } : undefined);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
