export const RHPermissionScope = {
  AbsenceManagement: 'absence-management',
  Management: 'management',
  ServiceManagement: 'service-management',
  ServiceAnimation: 'service-animation',
  Bot: 'bot',
  Statistic: 'statistic',
  TechnicalManagement: 'technical-parameters',
} as const;
export type RHPermissionScope = (typeof RHPermissionScope)[keyof typeof RHPermissionScope];
