import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

export interface DeclarationContactPermissionProps {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export function DeclarationContactPermission(props: DeclarationContactPermissionProps) {
  const { label, value, onChange } = props;

  return <FormControlLabel control={<Checkbox checked={value} onChange={() => onChange(!value)} />} label={label} />;
}
