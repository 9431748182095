/**
 * Default email header
 */
export const parcoursEmailHeader = [
  'Message concernant : {{employee.firstname}} {{employee.lastname}} ({{employee.description}})',
].join('\n');

/**
 * Default email footer
 */
export const parcoursEmailFooter = ['Bien à vous,', "L'équipe KERij"].join('\n');
