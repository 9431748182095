import React, { useState } from 'react';
import { EmployeeInformation as EmployeeInformationComponent } from '../../../features/dashboard-rh/create-absence-rh/components/EmployeeInformation.component';
import { AbsencesInput } from '@/components/DashboardRH/AbsencesInput';
import { Absence, AbsenceStatus, EmployeeInformation } from '@/types';
import { Typography } from '@mui/material';
import { getFullName } from '@/shared/utils/getFullName.service';
import { ChangeTypeAbsenceResult } from '@/components/DashboardRH/ChangeTypeAbsenceResult/ChangeTypeAbsenceResult';
import { ChangeTypeAbsenceResultValidation } from '@/components/DashboardRH/ChangeTypeAbsenceResult/ChangeTypeAbsenceResultValidation';
import { isAccident, isHospitalisation, isMaladie } from '@/services/rh/absences/data';
import { AbsencesForm } from '@/components/DashboardRH/AbsencesForm';
import { AbsenceFormStep } from '@/components/DashboardRH/AbsencesForm/AbsencesForm';
import { useAppSelector } from '@/store';
import { TypesAbsencesAccident, TypesAbsencesHospitalisation, TypesAbsencesMaladie } from '@/services/rh/types/data';
import { CodesAbsencesAccident, CodesAbsencesHospitalisation, CodesAbsencesMaladie } from '@/services/rh/codes/data';

export const EDIT_ABSENCES_STEPS = {
  EMPLOYEE: 0,
  ABSENCES: 1,
  RESUME: 2,
  VALIDATION: 3,
};

interface EditAbsencesStepsProperties {
  isSubmit: boolean;
  onSubmit: (value: Absence[]) => void;
  onReset: () => void;
}

export const EditAbsencesSteps = ({ isSubmit, onSubmit, onReset }: EditAbsencesStepsProperties) => {
  const [absences, setAbsences] = useState<Absence[]>([]);
  const [infosEmployee, setInfosEmployee] = useState<EmployeeInformation | null>(null);

  const employee = useAppSelector((state) =>
    state.employee.employeeList?.find((employee: EmployeeInformation) => employee.id === infosEmployee?.id),
  );

  const getEditedAbsences = () =>
    absences?.filter((abs) => isMaladie(abs) || isHospitalisation(abs) || isAccident(abs));

  function resetForm() {
    setInfosEmployee(null);
    setAbsences([]);
    onReset();
  }

  const steps: AbsenceFormStep[] = [
    {
      label: 'Rechercher un employé',
      description: <EmployeeInformationComponent value={infosEmployee} onChange={setInfosEmployee} />,
      optionsLabel: () => {
        if (infosEmployee == null) return null;
        const content = getFullName(infosEmployee);
        return <Typography variant="caption">{content}</Typography>;
      },
    },
    {
      label: 'Sélectionner un ou plusieurs arrêts',
      description: employee ? (
        <AbsencesInput
          value={absences}
          onChange={(e, value) => setAbsences(value)}
          filters={{
            status: [AbsenceStatus.DRAFT, AbsenceStatus.FINISHED, AbsenceStatus.PENDING, AbsenceStatus.VALIDATED],
            id_employee_information: employee.id,
            type_absence: [...TypesAbsencesMaladie, ...TypesAbsencesAccident, ...TypesAbsencesHospitalisation],
            code_absence: [...CodesAbsencesMaladie, ...CodesAbsencesAccident, ...CodesAbsencesHospitalisation].filter(
              (code) => typeof code === 'string',
            ) as string[],
          }}
        />
      ) : (
        <></>
      ),
      optionsLabel: () => {
        const content = `${absences.length} arrêt(s) sélectionné(s)`;
        return <Typography variant="caption">{content}</Typography>;
      },
    },
    {
      label: 'Modifier la nature',
      description: <ChangeTypeAbsenceResult absences={getEditedAbsences()} />,
    },
    {
      label: 'Validation',
      description: (
        <ChangeTypeAbsenceResultValidation
          employee={employee}
          absences={getEditedAbsences()}
          isSubmit={isSubmit}
          onReset={resetForm}
        />
      ),
    },
  ];

  return (
    <AbsencesForm
      steps={steps}
      employee={infosEmployee}
      submitStep={EDIT_ABSENCES_STEPS.VALIDATION}
      isSubmit={isSubmit}
      onSubmit={() => onSubmit(getEditedAbsences())}
      onBackToFirst={() => setAbsences([])}
    />
  );
};
