import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../lib/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './PasswordRH.css';
import isAuthorized from '../../hooks/isAuthorized';
import { PRO_URL } from '../../constants/urls.constants';
import { StatusCodes } from 'http-status-codes';
import { PageTitle } from '@/components/PageTitle';
import { Typography } from '@mui/material';
import { useAppDispatch } from '@/store';

function PasswordRH() {
  const [oldPassword, setOldPassword] = useState('');
  const dispatch = useAppDispatch();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [valid, setValid] = useState(true);

  useEffect(() => {
    if (!isAuthorized(window.location.pathname)) {
      navigate(`${PRO_URL.PREFIXE_PRO}${PRO_URL.LOGIN}`);
    }
  }, []);

  useEffect(() => {
    if (oldPassword) {
      axios
        .post('/api/rh/verify_password', {
          password: oldPassword,
        })
        .then((data) => {
          if (data.data.isValid) {
            setMessage('');
            setValid(true);
          } else {
            setMessage('Le mot de passe est erroné');
            setValid(false);
          }
        })
        .catch();
    } else {
      setMessage('');
      setValid(false);
    }
  }, [oldPassword]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    try {
      if (!oldPassword || !newPassword || !confirmPassword) {
        alert('Attention, tous les champs doivent être renseignés');
        return;
      }
      if (valid) {
        if (newPassword !== confirmPassword) {
          alert('Attention, les mots de passe ne correspondent pas.');
          return;
        }

        await axios
          .post('/api/rh/send_code')
          .then((value) => {
            if (value.status === StatusCodes.OK) {
              navigate(PRO_URL.PREFIXE_PRO + PRO_URL.CONFIRMATION, {
                state: {
                  id_rh_login: value.data.id,
                  password: newPassword,
                },
              });
            }
          })
          .catch((err) => console.log(err));
      } else {
        alert('Mot de passe incorrecte');
      }
    } catch {
      alert('Erreur');
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <PageTitle label={'Ceci est votre première connexion'} />
        <Typography variant={'h6'}>
          {"Veuillez créer un nouveau mot de passe afin d'accéder à la plateforme"}
        </Typography>
        <form className="col-3" onSubmit={handleSubmit}>
          <div className="input-group col-6">
            <label>Ancien mot de passe (reçu dans le mail)</label>
            <div className="label-password">
              <input
                name="password"
                type={!showOldPassword ? 'password' : 'text'}
                // placeholder="Mot de passe"
                value={oldPassword}
                placeholder=""
                onChange={(e) => setOldPassword(e.target.value)}
                className="input-password"
                required
              />
              <FontAwesomeIcon
                icon={!showOldPassword ? faEye : faEyeSlash}
                className="icon-show-password"
                onClick={() => setShowOldPassword(!showOldPassword)}
              ></FontAwesomeIcon>
            </div>
            {message && <div className="error">{message}</div>}
          </div>

          <div className="input-group">
            <label>Nouveau mot de passe</label>
            <div className="label-password">
              <input
                name="newpassword"
                type={!showNewPassword ? 'password' : 'text'}
                // placeholder="Mot de passe"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="input-password"
                required
              />
              <FontAwesomeIcon
                icon={!showNewPassword ? faEye : faEyeSlash}
                className="icon-show-password"
                onClick={() => setShowNewPassword(!showNewPassword)}
              ></FontAwesomeIcon>
            </div>
          </div>

          <div className="input-group">
            <label>Confirmer le nouveau mot de passe</label>
            <div className="label-password">
              <input
                name="password"
                type={!showConfirmPassword ? 'password' : 'text'}
                // placeholder="Mot de passe"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="input-password"
                required
              />
              <FontAwesomeIcon
                icon={!showConfirmPassword ? faEye : faEyeSlash}
                className="icon-show-password"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              ></FontAwesomeIcon>
            </div>
          </div>

          <button className="btn-submit">Valider</button>
        </form>
      </div>
    </div>
  );
}

export default PasswordRH;
