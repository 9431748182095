import React from 'react';
import { RHInformation } from '../../../types/rh.interface';
import CustomModal from '../../base/CustomModal';
import { useError } from '@/shared/useError';
import { KerijBox, KerijBoxType } from '../../base';
import { adminDeleteUser } from '../../../services/rh/admin';
import { StatusCodes } from 'http-status-codes';
import { ActionButton } from '@/components/ActionButton';
import { useToast } from '@/components/Toast';

interface DeleteUserModalProperties {
  visible: boolean;
  handleCloseModal: () => void;
  rhInformation: RHInformation;
}

const DeleteUserModal = ({ visible, handleCloseModal, rhInformation }: DeleteUserModalProperties) => {
  const toast = useToast();
  const { error, triggerError, clearError } = useError();

  return (
    <CustomModal
      open={visible}
      onClose={() => {
        handleCloseModal?.();
      }}
      renderHeader={() => <>Supprimer l'utilisateur</>}
      renderFooter={() => (
        <ActionButton
          label={'Confirmer'}
          onClick={() => {
            adminDeleteUser(rhInformation?.id)
              .then((value) => {
                if (value.status === StatusCodes.OK) {
                  handleCloseModal?.();
                  clearError();
                  toast.present({ message: "L'utilisateur a bien été supprimé", severity: 'success' });
                }
              })
              .catch(() => triggerError("L'utilisateur n'a pas pu être supprimé"));
          }}
        />
      )}
      renderContent={() => (
        <>
          {"Vous êtes sûr le point de supprimer l'utilisateur"}{' '}
          <strong>
            {rhInformation?.firstname?.at(0)?.toUpperCase() +
              rhInformation?.firstname.slice(1).toLowerCase() +
              ' ' +
              rhInformation?.lastname}
          </strong>
          , êtes vous sur de votre choix ?{error && <KerijBox message={error} type={KerijBoxType.Error} />}
        </>
      )}
    ></CustomModal>
  );
};

export default DeleteUserModal;
