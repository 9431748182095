import { SET_EMPLOYEE_LIST, SET_EMPLOYEE } from './types';

export const setEmployeeList = (data: any) => {
  return {
    type: SET_EMPLOYEE_LIST,
    payload: data,
  };
};

export const setEmployee = (data: any) => {
  return {
    type: SET_EMPLOYEE,
    payload: data,
  };
};
