import { type PayloadAction, createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '../store';
import { fetchFeatures } from '@/services/rh/business';
import { StatusCodes } from 'http-status-codes';

export interface State {
  /**
   * Map of all features
   */
  feature: {
    [featureName: string]: boolean;
  };
  loading: boolean;
  initialized: boolean;
}

const initialState: State = {
  feature: {},
  loading: false,
  initialized: false,
};

export const slice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<State['loading']>) => {
      state.loading = action.payload;
    },
    update: (state, action: PayloadAction<State['feature']>) => {
      state.feature = action.payload;
      state.initialized = true;
    },
  },
});

export const getSelf = (state: { feature: State }) => state[slice.name];

export const isLoading = createSelector([getSelf], (self) => self.loading);

export const isInitialized = createSelector([getSelf], (self) => self.initialized);

export const getAll = createSelector([getSelf], (self) => self.feature);

export const { actions } = slice;

/**
 * Load profile
 */
export const load = createAsyncThunk(
  `${slice.name}/load`,
  async (_, { dispatch }) => {
    try {
      dispatch(actions.setLoading(true));
      const response = await fetchFeatures();
      if (response.status === StatusCodes.OK) {
        dispatch(actions.update(response.data));
      } else {
        console.error(response);
      }
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  {
    condition: (_, { getState }) => !isInitialized(getState() as AppState) && !isLoading(getState() as AppState),
  },
);
