// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ScanBotContainer {
  background-color: rgba(255, 255, 255, 0.8) !important;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.InputFileButton {
  text-align: center;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/InputFile/InputFile.css"],"names":[],"mappings":"AAAA;EACE,qDAAqD;EACrD,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;AACV;AACA;EACE,kBAAkB;EAClB,WAAW;AACb","sourcesContent":["#ScanBotContainer {\n  background-color: rgba(255, 255, 255, 0.8) !important;\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n.InputFileButton {\n  text-align: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
