import type { IfPermissionProps } from '@/components/IfPermission';
import type { LayoutMenuGroupItemsProps } from './LayoutMenuGroupItems';
import type { LayoutMenuItemProps } from './LayoutMenuItem';
import { Feature } from '@/types';

export const menuWidth = 260;
export const footerWidth = 48;

export type RHMenuItemProps = LayoutMenuGroupItemsProps | LayoutMenuItemProps;

export interface RHMenuItem {
  title: string;
  permission?: IfPermissionProps;
  feature?: keyof Feature;
  items: RHMenuItemProps[];
}

export const isGroupItemsProps = (properties: RHMenuItemProps): properties is LayoutMenuGroupItemsProps =>
  Object.hasOwn(properties, 'title');
