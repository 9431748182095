import React from 'react';
import HistoryAbsence from './HistoryAbsence';

function Absences() {
  return (
    <div>
      <HistoryAbsence />
    </div>
  );
}

export default Absences;
