export interface AbsencesDeleteOptionsItem {
  value: string;
  label: string;
  input?: boolean;
}

export const pickerOptions: AbsencesDeleteOptionsItem[] = [
  {
    value: '1',
    label: "L'absence n'est pas conforme à la réglementation",
  },
  {
    value: '2',
    label: "Le collaborateur n'a pas transmis son justificatif",
  },
  {
    value: '3',
    label: "Je vais déclarer un nouveau type d'arrêt sur la période",
  },
  {
    value: '4',
    label: 'Autre',
    input: true,
  },
];
