import React from 'react';
import './kerijButton.scss';

interface KerijButtonProps {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const KerijButton: React.FC<KerijButtonProps> = ({ label, disabled = false, onClick, style }: KerijButtonProps) => {
  return (
    <button
      className={`kerij-button ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {label}
    </button>
  );
};

export default KerijButton;
