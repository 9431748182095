import { Editor } from 'slate';
import { CustomText } from '../type';

export const isMarkActive = (editor: Editor, markType: Exclude<keyof CustomText, 'text'>) => {
  const marks = Editor.marks(editor);
  // eslint-disable-next-line security/detect-object-injection
  return marks
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      marks[markType] === true
    : false;
};
