import React from 'react';
import { Service } from '@/types';
import { ServiceCard, ServiceCardLoader } from '../ServiceCard';
import { IconButtonProps } from '../IconButton/IconButton';

export interface ServiceListProps {
  values: Service[];
  isLoading?: boolean;
  action?: (value: Service) => IconButtonProps;
  onClick?: (value: Service) => void;
}

export function ServiceList(props: ServiceListProps) {
  const { values, action, isLoading, onClick } = props;

  return (
    <div className="row mt-4">
      {values.length > 0 &&
        values.map((value, index) => {
          return (
            <div className="col-12 col-sm-4 col-lg-3 mt-4" key={index}>
              <ServiceCard value={value} onClick={() => onClick?.(value)} action={action?.(value)} />
            </div>
          );
        })}

      {isLoading && (
        <div className="col-12 col-sm-4 col-lg-3 mt-4">
          <ServiceCardLoader />
        </div>
      )}

      {values.length === 0 && !isLoading && (
        <div className="col-12 col-sm-4 col-lg-3 mt-4">
          <ServiceCardLoader failedText="Aucun service disponible dans cette catégorie" />
        </div>
      )}
    </div>
  );
}
