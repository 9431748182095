import { RHNotification } from '../../../../../../types/rh.interface';
import React, { useState } from 'react';
import { DeleteAbsenceModal } from '../ActionCardModal/DeleteAbsenceModal';
import { ContentStructure } from './common';
import { deleteAbsence } from '../../../../../../services/rh/absences/service';
import { Button } from '@/components/Button';
import { Box } from '@mui/material';
import { useToast } from '@/components/Toast';

export interface IntegrationFailedProps {
  rhNotification: RHNotification;
  onActionDone: () => void;
}

export const IntegrationFailed = ({ rhNotification, onActionDone }: IntegrationFailedProps) => {
  const toast = useToast();
  const [deleteAbsenceModalVisible, setDeleteAbsenceModalVisible] = useState(false);

  const integrationLog = rhNotification.absence?.absence_logs?.find(
    (log) => log.triggered_by === 'adp' && log.active && log.level === 'error',
  );
  const description = (
    <>
      <Box>Cette absence n'a pas été intégrée dans le logiciel des temps.</Box>
      <Box>Description - « {integrationLog?.description ?? "La cause n'est pas disponible dans le rapport"} »</Box>
      <Box mt={1}> Que souhaitez-vous faire ?</Box>
    </>
  );

  return (
    <div>
      <ContentStructure
        description={description}
        title={'Intégration au logiciel des temps échouée'}
        buttons={<Button onClick={() => setDeleteAbsenceModalVisible(true)}>{"Supprimer l'absence"}</Button>}
      />

      <DeleteAbsenceModal
        visible={deleteAbsenceModalVisible}
        setVisible={setDeleteAbsenceModalVisible}
        onDeleteAbsence={async (description) => {
          if (rhNotification.absence)
            await deleteAbsence({
              params: {
                idAbsence: rhNotification.absence.id,
              },
              body: {
                action: 'delete',
                description: description,
              },
            }).then(() => {
              toast.present({ message: `Absence supprimée`, severity: 'success' });
              onActionDone();
            });
          setDeleteAbsenceModalVisible(false);
        }}
      />
    </div>
  );
};
