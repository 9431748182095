import { DefaultLayout } from '../components/layouts';
import React from 'react';
import ActivitesLogin from '../components/Activites/ActivitesLogin';

export const ActivitesLoginPage: React.FC = () => {
  return (
    <DefaultLayout>
      <ActivitesLogin />
    </DefaultLayout>
  );
};
