import { AbsenceAction, AbsenceCurrentActionTypes, AbsenceHistoryActionTypes } from '../action/absence/type';
import { combineReducers, Reducer } from 'redux';
import { DocumentAction, DocumentActionTypes } from '../action/document/type';
import { Absence, DocumentAbsence } from '../../types/absences.type';

export interface IState {
  loaded: boolean | undefined;
  loading: boolean | undefined;
  retrieved: any;
  error: any;
}

export const initialState: IState = {
  loaded: false,
  loading: false,
  retrieved: null,
  error: null,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TODO: refactor avec RTK slice
export const absenceCurrentReducer: Reducer<IState> = (
  state: any = initialState,
  action: AbsenceAction | DocumentAction,
) => {
  switch (action.type) {
    case AbsenceCurrentActionTypes.LIST_CURRENT_ABSENCE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case AbsenceCurrentActionTypes.LIST_CURRENT_ABSENCE: {
      return {
        ...state,
        retrieved: action.payload,
      };
    }
    case AbsenceCurrentActionTypes.LIST_CURRENT_ABSENCE_SUCCESS: {
      return {
        ...state,
        retrieved: {
          ...state.retrieved,
          rows: [...state.retrieved.rows, ...action.payload.rows],
        },
      };
    }
    case AbsenceCurrentActionTypes.LIST_CURRENT_ABSENCE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DocumentActionTypes.CREATE_DOCUMENT_SUCCESS: {
      const document = action.payload;
      if (!state.retrieved?.currentAbsences) return state;
      const absences = state.retrieved.rows.map((absence: Absence) => {
        //doc is not related to this absence
        if (absence.id !== document.id_absence) return absence;
        return {
          ...absence,
          documents: absence.documents.some((document_: DocumentAbsence) => document_.id === document.id)
            ? absence.documents.map((document_: DocumentAbsence) =>
                document_.id === document.id ? document : document_,
              )
            : [document],
        };
      });
      return {
        ...state,
        retrieved: {
          ...state.retrieved,
          rows: absences,
        },
      };
    }
    default: {
      return state;
    }
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TODO: refactor avec RTK slice
export const absenceHistoryReducer: Reducer<IState> = (state: any = initialState, action: AbsenceAction) => {
  switch (action.type) {
    case AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE_LOADED: {
      return {
        ...state,
        loaded: action.payload,
      };
    }
    case AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE: {
      return {
        ...state,
        retrieved: action.payload,
      };
    }
    case AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE_SUCCESS: {
      return {
        ...state,
        retrieved: {
          ...state.retrieved,
          historyAbsences: [...state.retrieved.historyAbsences, ...action.payload.historyAbsences],
        },
      };
    }
    case AbsenceHistoryActionTypes.LIST_HISTORY_ABSENCE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
export default combineReducers({
  absenceCurrent: absenceCurrentReducer,
  absenceHistory: absenceHistoryReducer,
});
