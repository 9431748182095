import axios from 'axios';
import React from 'react';
import { useError } from '@/shared/useError';
import { getFullName } from '../../../../shared/utils/getFullName.service';
import { StatusCodes } from 'http-status-codes';

const AskInterviewModal = ({ onRequestClose, typeInterview, selectedInterview, onLoadingChange, onUpdate }) => {
  const { triggerError } = useError();

  const notifyEmployee = (typeInterview) => {
    onLoadingChange(true);
    if (typeInterview === 'Return') {
      axios
        .post('/api/rh/notify_employee_interview_retour', {
          idRhNotification: selectedInterview.id,
          idEmployeeInformation: selectedInterview.employee_information.id,
          idBusiness: selectedInterview.employee_information.id_business,
          typeNotification: selectedInterview.type_notification,
          type: 'Planifier entretien de retour',
          dateSendToSalarie: true,
        })
        .then((value) => {
          if (value.status === StatusCodes.OK) {
            onRequestClose();
            onUpdate();
            onLoadingChange(false);
          }
        })
        .catch((error) => {
          onLoadingChange(false);
          triggerError(error);
        });
    } else {
      axios
        .post('/api/rh/notify_employee_interview_liaison', {
          idRhNotification: selectedInterview.id,
          idEmployeeInformation: selectedInterview.employee_information.id,
          idBusiness: selectedInterview.employee_information.id_business,
          typeNotification: selectedInterview.type_notification,
          type: 'Planifier entretien de liaison',
          dateSendToSalarie: true,
        })
        .then((value) => {
          if (value.status === StatusCodes.OK) {
            onRequestClose();
            onUpdate();
            onLoadingChange(false);
          }
        })
        .catch((error) => {
          onLoadingChange(false);
          triggerError(error);
        });
    }
  };
  return (
    <>
      <div className="container-fluid text-center">
        <h3 className="question-form titre-modal mt-3 mb-4">
          {getFullName(selectedInterview.employee_information)}{' '}
          {typeInterview === 'Return'
            ? "doit suivre un entretien de retour à l'emploi."
            : 'doit suivre un entretien de liaison'}{' '}
        </h3>
        <div className="card col-12">
          <div className="card-body card-style">
            <div className="row col-12">
              <p className="card-text text-center col-12 contacts-libelle">
                Notifiez le salarié en lui demandant ses préférences pour réaliser l'entretien
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mt-4">
        <button
          className="btn btn-kerij-bleu btn-modal col-4"
          onClick={() => {
            notifyEmployee(typeInterview);
          }}
        >
          Informer le salarié
        </button>
      </div>
    </>
  );
};
export default AskInterviewModal;
