export const AllowedStringVariable = {
  ParcoursName: 'parcours.name',
  ParcoursEmployee: 'parcours.employee',
  EmployeeFirstname: 'employee.firstname',
  EmployeeLastname: 'employee.lastname',
  EmployeeDescrition: 'employee.description',
} as const;

export const StringVariableTranslation: Record<AllowedStringVariable, string> = {
  [AllowedStringVariable.ParcoursName]: 'Nom parcours',
  [AllowedStringVariable.ParcoursEmployee]: 'Prénom et nom employé',
  [AllowedStringVariable.EmployeeFirstname]: 'Prénom employé',
  [AllowedStringVariable.EmployeeLastname]: 'Nom employé',
  [AllowedStringVariable.EmployeeDescrition]: 'Matricule employé',
} as const;

export type AllowedStringVariable = (typeof AllowedStringVariable)[keyof typeof AllowedStringVariable];
export const isAllowedStringVariable = (variable: string): variable is AllowedStringVariable =>
  Object.values(AllowedStringVariable).includes(variable as any);
