import {
  ACCESS_TOKEN_EMPLOYEE,
  ACCESS_TOKEN_HR,
  REDIRECT_PATH,
  REFRESH_TOKEN,
  TOKEN_DATA,
} from '../constants/auth.constants';
import { UserTypes } from '../types/userTypes.enum';
import { NavigateFunction } from 'react-router-dom';
import { PRO_URL } from '../constants/urls.constants';

interface TokenData {
  userType: UserTypes;
  token: string;
  exp: string;
  refreshToken?: string;
}

export const storeAuthData = ({ userType, token, exp, refreshToken }: TokenData) => {
  const tokenKey = getTokenKeyByUserType(userType);
  localStorage.setItem(tokenKey, token);
  refreshToken && localStorage.setItem(REFRESH_TOKEN, refreshToken);
  setTokenData({ userType, exp } as any);
};

export const removeAuthData = (userType: UserTypes = getTokenData()?.userType as UserTypes) => {
  const tokenKey = getTokenKeyByUserType(userType);
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(TOKEN_DATA);
  localStorage.removeItem(REFRESH_TOKEN);
};

export const setTokenData = ({ userType, exp }: Omit<TokenData, 'token'>) => {
  const data = [userType, exp || ''].join(',');
  localStorage.setItem(TOKEN_DATA, data);
};

export const getTokenData = () => {
  const payload = localStorage.getItem(TOKEN_DATA);
  if (!payload) {
    return null;
  }
  const [userType, exp] = payload.split(',');
  return { userType, exp };
};

export const getToken = () => {
  const tokenData = getTokenData();
  if (!tokenData) {
    return null;
  }
  const tokenKey = getTokenKeyByUserType(tokenData.userType as UserTypes);
  return localStorage.getItem(tokenKey);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

const getTokenKeyByUserType = (userType: UserTypes) => {
  let tokenKey;
  switch (userType) {
    case UserTypes.HR: {
      tokenKey = ACCESS_TOKEN_HR;
      break;
    }
    case UserTypes.EMPLOYEE: {
      tokenKey = ACCESS_TOKEN_EMPLOYEE;
      break;
    }
    default: {
      throw new Error('Invalid user type');
    }
  }
  return tokenKey;
};

export const isProPathName = (pathname: string) => /(^(\/pro\/).*)|(^\/pro$)/.test(pathname);

export const redirectToStoredPath = (navigate: NavigateFunction) => {
  const redirectPath = localStorage.getItem(REDIRECT_PATH) || '/';
  localStorage.removeItem(REDIRECT_PATH);
  navigate(redirectPath);
};

export const setRedirectPath = (path: string) => {
  localStorage.setItem(REDIRECT_PATH, path);
};

export const hasTokenExpired = (exp: string) => {
  const currentTimestamp = Date.now() / 1000; // convert to seconds
  if (!exp) {
    return true;
  }
  const expTimestamp = Number(exp);
  // Check if token will expire within the next minute
  const isExpire = expTimestamp - currentTimestamp <= 0;
  return (!!localStorage.getItem(ACCESS_TOKEN_HR) || !!localStorage.getItem(ACCESS_TOKEN_EMPLOYEE)) && isExpire;
};

export const isInterviewLink = (url: string) => {
  const listLink = [
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DETAILS_ABSENCE}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.PREFERENCES_EMPLOYEE}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_MANAGER}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_MANAGER_END}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_EMPLOYEE}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_SST}`,
    `${PRO_URL.PREFIXE_PRO}${PRO_URL.DATE_CHOICE_EMPLOYEE_END}`,
  ];
  return listLink.includes(url);
};
export function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}
