import React from 'react';
import RdvLoginUI from './components/RdvLoginUI';
import { DefaultLayout } from '../../../components/layouts';

export const RdvLoginPage: React.FC = () => {
  return (
    <DefaultLayout>
      <RdvLoginUI />
    </DefaultLayout>
  );
};
