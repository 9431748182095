// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-histo {
  font-size: 1.2em;
  font-weight: bolder;
}

.row-histo {
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  display: flex;
  align-items: center;
}

@media all and (max-width: 984px) {
  .date-modif {
    text-align: center;
  }
}

.document-load {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.page-load {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/DashboardSalarie/Historique.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iDAAiD;EACjD,yFAAyF;EACzF,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd","sourcesContent":[".header-histo {\n  font-size: 1.2em;\n  font-weight: bolder;\n}\n\n.row-histo {\n  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */\n  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;\n  display: flex;\n  align-items: center;\n}\n\n@media all and (max-width: 984px) {\n  .date-modif {\n    text-align: center;\n  }\n}\n\n.document-load {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-self: center;\n  flex-direction: column;\n  flex-grow: 1;\n  width: 100%;\n  height: 100%;\n}\n\n.page-load {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-self: center;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
