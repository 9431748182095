import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import RdvFinUI from './components/RdvFinUI';

export const RdvFinPage: React.FC = () => {
  return (
    <DefaultLayout>
      <RdvFinUI />
    </DefaultLayout>
  );
};
