import React from 'react';
import moment from 'moment';
import './Activites.css';
import { EmployeeNotificationCard } from './EmployeeNotificationCard';
import { IfFeature } from '@/components/IfFeature';

// TODO: THIS Code is duplicated from backend !

export const ActivitesContent = ({ notification }: any) => {
  switch (notification.type_notification.toLowerCase()) {
    case 'notification des services': {
      return (
        <EmployeeNotificationCard employeeInformation={notification.employee_information}>
          <p>
            Vous venez de déclarer {notification.data.typeAbsence} sur la plateforme KERij,{' '}
            {notification.data.typeAbsence === 'un congé parentalité' ||
            notification.data.typeAbsence === 'un congé maternité'
              ? 'félicitations !'
              : 'nous vous souhaitons un bon rétablissement !'}
          </p>

          {notification.data.typeAbsence === 'un congé maternité' && notification.data.isOption1 ? (
            <p>
              Grâce aux avantages proposés par votre employeur, vous avez choisi l’option de réduction de votre temps de
              travail à raison d’une heure par jour à partir de la fin de votre congé maternité et jusqu’aux 18 mois de
              votre enfant.{' '}
            </p>
          ) : (
            <div></div>
          )}

          {notification.data.typeAbsence === 'un congé maternité' && notification.data.isOption2 ? (
            <p>
              Grâce aux avantages proposés par votre employeur, vous avez choisi l’option de réduction de votre temps de
              travail à raison de 30 minutes par jour avant votre congé maternité et d’allonger votre congé maternité
              avec le congé supra légal.{' '}
            </p>
          ) : (
            <div></div>
          )}

          {notification.data.typeAbsence === 'un congé maternité' && notification.data.isOption1 ? (
            <>
              <p>Nous vous informons que cette option irrévocable peut être aménagée de la manière suivante : </p>
              <ul className="ul-container">
                <li>
                  En concertation avec votre manager, vous pouvez fragmenter votre réduction de temps de travail en
                  demi-heures (matin et soir) ou au contraire cumuler les heures dans la limite d’une demie journée par
                  semaine
                </li>
                <li>
                  Si le second parent est salarié de la CNP, il peut bénéficier, si vous l’acceptez, de l’aménagement
                  d’horaires. Cette option est activable pour une durée minimum de 3 mois et doit être discutée avec
                  votre HR VP
                </li>
              </ul>
            </>
          ) : (
            <div></div>
          )}
          <IfFeature name="ServiceManagement">
            <p>
              {'Bonne nouvelle, votre entreprise propose de nombreux services & programmes '}
              {notification.data.typeAbsence === 'un congé parentalité' ||
              notification.data.typeAbsence === 'un congé maternité'
                ? "pour préparer l'arrivée de votre enfant"
                : 'pour vous aider'}
            </p>
            <p>
              <a href={notification.url}>
                <u>
                  <b>Je consulte les services disponibles</b>
                </u>
              </a>
            </p>
          </IfFeature>
        </EmployeeNotificationCard>
      );
    }
    case "notification de suivi de l'arret": {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Mise à jour de votre absence'}
        >
          <p>Votre retour au travail est prévu aujourd’hui. Vous devez mettre à jour votre situation.</p>

          <p>
            <a href={notification.url} target="_blank" rel="noreferrer">
              <u>Je mets à jour ma situation</u>
            </a>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case 'absence refusée': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Absence refusée par les Ressources Humaines'}
        >
          <p>Votre service RH a supprimé votre déclaration d’absence</p>
        </EmployeeNotificationCard>
      );
    }
    case 'absence modifiée': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Absence modifiée par les Ressources Humaines'}
        >
          <p>
            Suite à l'analyse de votre dossier, cette absence est requalifiée en{' '}
            {notification?.absence?.type_absence?.description_1_label}
          </p>
        </EmployeeNotificationCard>
      );
    }
    case 'absence validée': {
      return (
        <EmployeeNotificationCard employeeInformation={notification.employee_information} title={'Absence validée'}>
          <p>Votre absence a été validée par les Ressources Humaines.</p>
        </EmployeeNotificationCard>
      );
    }
    case 'questionnaire de retour': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={"Suivi du retour à l'emploi"}
        >
          <p>Vous être de retour au travail, c’est une excellente nouvelle !</p>

          <p>Nous vous invitons à compléter le baromètre de retour à l’emploi en cliquant sur le lien ci-dessous</p>

          <p>
            Ce questionnaire ne vous prendra que 5 mn et les réponses anonymisées ne seront pas transmises à votre
            emloyeur.
          </p>

          <p>
            Cliquez sur ce lien :{' '}
            <a href={notification.url} target="_blank" rel="noreferrer">
              <u>
                <b>Répondre au questionnaire</b>
              </u>
            </a>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case 'notification de document manquant': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Un justificatif est manquant'}
        >
          <p>
            Vous avez déclaré{' '}
            {notification.data.typeAbsence === 'hospitalisation'
              ? 'une hospitalisation'
              : notification.data.typeAbsence === 'arrêt de travail' ||
                  notification.data.typeAbsence === 'un arrêt de travail'
                ? 'un arrêt de travail'
                : notification.data.typeAbsence === 'congé maternité'
                  ? 'un congé maternité'
                  : notification.data.typeAbsence === 'congé paternité'
                    ? 'un congé parentalité'
                    : ''}{' '}
            le {moment(notification.absence.date_start).format('DD/MM/YYYY')}
          </p>
          <p style={{ color: 'red' }}>
            {' '}
            Pour valider votre{' '}
            {notification.data.typeAbsence === 'hospitalisation'
              ? 'une hospitalisation'
              : notification.data.typeAbsence === 'arrêt de travail' ||
                  notification.data.typeAbsence === 'un arrêt de travail'
                ? 'un arrêt de travail'
                : notification.data.typeAbsence === 'congé maternité'
                  ? 'un congé maternité'
                  : notification.data.typeAbsence === 'congé paternité'
                    ? 'un congé parentalité'
                    : ''}{' '}
            , vous devez déposer votre justificatif sur la plateforme KERij{' '}
          </p>
          <p>
            <a href={notification.url}>
              <u>Je dépose mon document</u>
            </a>
            .
          </p>
        </EmployeeNotificationCard>
      );
    }
    case 'naissance manquant': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Un justificatif est manquant'}
        >
          <p>
            Selon nos informations, votre enfant est né le ${moment(notification.absence.birthday).format('DD/MM/YYYY')}{' '}
            félicitations !{' '}
          </p>
          <p style={{ color: 'red' }}>
            Pour valider votre congé et obtenir vos indemnités, vous devez déposer l’acte de naissance de l’enfant.
          </p>
          <p>
            <b>
              <a href={notification.url}>Je dépose l’acte de naissance de mon enfant</a>
            </b>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case "prolongation d'absence": {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={"Prolongation d'absence"}
        >
          <p>Votre absence est prolongée.</p>
          <p>Vous devez déclarer une nouvelle absence sur la plateforme KERij</p>

          <p>
            <b>
              <a href={notification.url}>Déclarer une nouvelle absence</a>
            </b>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case "choix de la date pour l'entretien de liaison": {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={"Demande d'entretien de liaison"}
        >
          <p>
            Vous allez réaliser un entretien de liaison avec votre{' '}
            {notification.data.type_contact.toString().toLowerCase()}.
          </p>

          <p>3 créneaux sont possibles.</p>
          <p>
            Confirmez au plus vite celui qui vous convient{' '}
            <a href={notification.url} target="_blank" rel="noreferrer">
              <b>en cliquant sur ce lien</b>
            </a>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case "choix de la date pour l'entretien de retour à l'emploi": {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={"Demande d'entretien de retour à l'emploi"}
        >
          <p>
            Vous allez réaliser un entretien de retour à l’emploi avec votre $
            {notification.data.type_contact.toString().toLowerCase()}.
          </p>

          <p>3 créneaux sont possibles.</p>
          <p>
            Confirmez au plus vite celui qui vous convient{' '}
            <a href={notification.url} target="_blank" rel="noreferrer">
              <b>en cliquant sur ce lien</b>
            </a>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case "confirmation de l'entretien de liaison": {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={"Confirmation d'entretien de liaison"}
        >
          <p>Votre entretien de liaison est confirmé : </p>

          <p>
            Le {moment(notification.data.date).format('DD/MM/YYYY')} de{' '}
            {moment(notification.data.from, 'HH:mm:ss').format('HH:mm')} à{' '}
            {moment(notification.data.to, 'HH:mm:ss').format('HH:mm')}
          </p>

          <p>Pour vous aider à préparer cet entretien, vous trouverez en pièce jointe le guide d’entretien.</p>

          {notification.url && notification.data.place === 'distanciel'
            ? `<p><strong><a href="${notification.url}" target="_blank">Participer à l'entretien en visioconférence</a><strong></p>`
            : ''}
        </EmployeeNotificationCard>
      );
    }
    case "confirmation d'entretien de retour à l'emploi": {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={"Confirmation d'entretien de liaison"}
        >
          <p>Votre entretien de retour à l’emploi est confirmé : </p>

          <p>
            Le {moment(notification.data.date).format('DD/MM/YYYY')} de{' '}
            {moment(notification.data.from, 'HH:mm:ss').format('HH:mm')} à{' '}
            {moment(notification.data.to, 'HH:mm:ss').format('HH:mm')}
          </p>

          <p>Pour vous aider à préparer cet entretien, vous trouverez en pièce jointe le guide d’entretien.</p>

          {notification.url
            ? `<p><strong><a href="${notification.url}" target="_blank">Participer à l'entretien en visioconférence</a><strong></p>`
            : ''}
        </EmployeeNotificationCard>
      );
    }
    case 'aucun creneau valide': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={"Demande d'entretien de liaison"}
        >
          <p>Vous n’avez validé aucun créneau proposé.</p>
        </EmployeeNotificationCard>
      );
    }
    case 'reaccueillir a retour': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Notification de retour'}
        >
          <p>
            Vous êtes en incapacité de travailler depuis {notification.data.alreadyDate} jours et votre retour théorique
            est prévu dans {notification.data.stillDate} jours.
          </p>

          <p>Nous souhaitons vous accueillir dans les meilleures conditions possibles.</p>
          <p>
            Suivez <a href={notification.url}>ce lien</a> afin de répondre à quelques questions
          </p>
        </EmployeeNotificationCard>
      );
    }
    case 'rendez-vous visite médicale de pré-reprise confirmé': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Rendez-vous visite médicale'}
        >
          <p>Vous avez fait une demande de visite médicale de pré reprise avec le Service de Santé au Travail.</p>

          <p>Un RDV est fixé : </p>

          <p>
            <b>
              {' '}
              Le {moment(notification.data.date).format('DD/MM/YYYY')} à{' '}
              {moment(notification.data.from, 'HH:mm:ss').format('HH:mm')}{' '}
              {notification.data.urlVisiteMedicale
                ? 'en téléconsultation'
                : `à l’adresse : ${notification.data.address}`}
            </b>
          </p>

          <p>
            {notification.data.urlVisiteMedicale ? (
              <a href={notification.data.urlVisiteMedicale}>Participer à la téléconsultation</a>
            ) : (
              <div></div>
            )}
          </p>

          <p>
            <a href={notification.data.url}>
              <u>Je confirme ma présence</u>
            </a>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case 'rendez-vous visite médicale de reprise confirmé': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Rendez-vous visite médicale de reprise'}
        >
          <p>
            Vous avez été absent{' '}
            {notification.data.isAccident ? '30 jours suite à un accident' : '60 jours suite à une maladie'}, vous devez
            rencontrer le Service de Santé au Travail afin de pouvoir reprendre votre poste.
          </p>

          <p>Un RDV est fixé : </p>

          <p>
            <b>
              {' '}
              Le {moment(notification.data.date).format('DD/MM/YYYY')} à{' '}
              {moment(notification.data.from, 'HH:mm:ss').format('HH:mm')}{' '}
              {notification.data.urlVisiteMedicale
                ? 'en téléconsultation'
                : `à l’adresse : {notification.data.address}`}
            </b>
          </p>

          <p>
            {notification.data.urlVisiteMedicale ? (
              <a href={notification.data.urlVisiteMedicale}>Participer à la téléconsultation</a>
            ) : (
              <div></div>
            )}
          </p>

          <p>
            <a href={notification.data.url}>
              <u>Je confirme ma présence</u>
            </a>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case 'rappel visite médicale': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Rappel visite médicale'}
        >
          <p>Pour rappel, vous avez une visite médicale </p>
          <p>
            <b>
              Le {moment(notification.data.date).format('DD/MM/YYYY')} à{' '}
              {moment(notification.data.from, 'HH:mm:ss').format('HH:mm')}
              {notification.data.teleconsultation ? 'en téléconsultation' : `${notification.data.address}`}
            </b>
          </p>

          {notification.data.teleconsultation ? (
            <p>
              <a href={notification.data.teleconsultation}>
                <u>Participer à la téléconsultation</u>
              </a>
            </p>
          ) : (
            <div></div>
          )}
        </EmployeeNotificationCard>
      );
    }
    case 'visite médicale - déposez vos documents': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Visite médicale - Déposez vos documents'}
        >
          <p>Vous avez bénéficié d’une consultation avec le Service de Santé au Travail.</p>

          <p>Pour mettre à jour votre dossier, vous devez déposer votre certificat.</p>

          <p>
            <a href={notification.url} target="_blank" rel="noreferrer">
              <u>
                <b>Déposer mon certificat</b>
              </u>
            </a>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case 'rendez-vous visite médicale de santé au travail confirmé': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Rendez-vous visite médicale de santé'}
        >
          <p>Vous avez fait une demande de visite médicale de santé au travail avec le Service de Santé au Travail.</p>

          <p>Un RDV est fixé : </p>

          <p>
            <b>
              {' '}
              Le {moment(notification.data.date).format('DD/MM/YYYY')} à{' '}
              {moment(notification.data.from, 'HH:mm').format('HH:mm')}{' '}
              {notification.data.urlVisiteMedicale
                ? 'en téléconsultation'
                : `à l’adresse : ${notification.data.address}`}
            </b>
          </p>

          <p>
            {notification.data.urlVisiteMedicale ? (
              <a href={notification.data.urlVisiteMedicale}>Participer à la téléconsultation</a>
            ) : (
              <div></div>
            )}
          </p>

          <p>
            <a href={notification.data.url}>
              <u>Je confirme ma présence</u>
            </a>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case 'rappel entretien de': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={`Rappel ${notification.data.type}`}
        >
          <p>
            {' '}
            Pour rappel, vous passez un
            {notification.data.type}
            le
            {moment(notification.data.date).format('DD/MM/YYYY')}
            de
            {moment(notification.data.from, 'HH:mm:ss').format('HH:mm')}à
            {moment(notification.data.to, 'HH:mm:ss').format('HH:mm')}
          </p>

          {notification.data.urlVisio && notification.data.place === 'distanciel' ? (
            <p>
              <strong>
                <a href={notification.data.urlVisio} target="_blank" rel="noreferrer">
                  Participer à l’entretien en visioconférence
                </a>
              </strong>
            </p>
          ) : (
            <></>
          )}
        </EmployeeNotificationCard>
      );
    }
    case 'entretien de liaison - choisissez vos préférences': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Entretien de liaison - vos préférences'}
        >
          <p>Vous avez été arrêté au moins 30 jours au cours des 12 dernier mois.</p>
          <p>Vous allez pouvoir bénéficier d’un entretien de liaison.</p>
          <p>
            <b>
              <a href={notification.url}>
                <b>Programmer mon entretien de liaison</b>
              </a>
            </b>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case "entretien de retour à l'emploi - choisissez vos préférences": {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={"Entretien de retour à l'emploi - choisissez vos préférences"}
        >
          <p>A l’issue de votre absence, vous allez bénéficier d’un entretien de retour à l’emploi.</p>
          <p>
            <b>
              <a href={notification.url}>
                <b>Programmer mon entretien de retour à l’emploi</b>
              </a>
            </b>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case "entretien de retour à l'emploi terminé": {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={"Entretien de retour à l'emploi terminé"}
        >
          <p>
            <b>
              Vous pouvez visualiser la fiche d’entretien de retour en cliquant sur "Historique" dans la rubrique "Fiche
              d’entretien"
            </b>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case 'entretien de liaison terminé': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Entretien de liaison terminé'}
        >
          <p>Votre entretien de liaison est terminé.</p>
          <p>
            <b>
              Vous pouvez visualiser la fiche d’entretien de liaison en cliquant sur &ldquo;Historique&ldquo; dans la
              rubrique &ldquo;Fiche d’entretien&ldquo;
            </b>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case "notification d'hospitalisation sans date de retour": {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Mettre à jour ma situation'}
        >
          <p>Vous avez déclaré une hospitalisation sans date de retour.</p>
          <p>Vous devez mettre à jour votre situation dès que vous connaitrez votre date de sortie.</p>
          <p>
            <b>
              <u>
                <a href={notification.url}>Je mets à jour ma situation</a>
              </u>
            </b>
          </p>
        </EmployeeNotificationCard>
      );
    }
    case 'document refusé': {
      return (
        <EmployeeNotificationCard
          employeeInformation={notification.employee_information}
          title={'Votre document a été refusé'}
        >
          <p>
            Suite à la déclaration de votre
            {notification.data.typeAbsence} du {moment(notification.data.dateStart).format('DD/MM/YYYY')}
            {notification.data.dateEnd ? ' au ' + moment(notification.data.dateEnd).format('DD/MM/YYYY') : ''}, le
            document que vous avez déposé a été refusé par les Ressources Humaines.
          </p>
          <p>
            {' '}
            Vous devez donc mettre un nouveau document sur la plateforme KERij pour valider votre{' '}
            {notification.data.typeAbsence}.
          </p>
          <p>
            <a href={notification.url}>
              <u>Je dépose mon document</u>
            </a>
            .
          </p>
        </EmployeeNotificationCard>
      );
    }
  }
  return <></>;
};
