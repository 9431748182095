import * as React from 'react';
import { Parcours } from '@/types/Parcours';
import { Box, Typography, OutlinedInput, Tab } from '@mui/material';
import { CustomModal, type CustomModalProps } from '../base/CustomModal';
import { FormatDate } from '@/constants/date.constants';
import moment from 'moment';
import { ParcoursRulesInput } from './ParcoursRulesInput';
import { ActionButton } from '@/components/ActionButton';
import { Button } from '@/components/Button';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ParcoursActionsInput } from './ParcoursActionsInput';
import { useController, useForm } from 'react-hook-form';
import { useMUIField } from '@/hooks/useMUIField';
import { parcoursUpdate } from '@/services/rh/parcours';
import { Edit } from '@/components/AppIcon';
import { useMUITabController } from '@/hooks/useMUITabController';
import { useToast } from '../Toast';

const ParcoursTab = {
  Rules: 'rules',
  Actions: 'actions',
} as const;
type ParcoursTab = (typeof ParcoursTab)[keyof typeof ParcoursTab];

export interface ParcoursEditDialogProps extends Pick<CustomModalProps, 'open' | 'onClose'> {
  value: Parcours;
  onChange?: (type: 'edit' | 'edited' | 'deleted') => void;
}

export function ParcoursEditDialog(props: ParcoursEditDialogProps) {
  const toast = useToast();

  const { open, onClose: onClose, value, onChange } = props;
  const tabController = useMUITabController<ParcoursTab>({ initialValue: ParcoursTab.Actions });
  const form = useForm({
    defaultValues: {
      name: value.name,
      scriptRules: value.script.rule.where,
      action: (value.script.action ?? {}) as Parcours['script']['action'],
    },
  });
  const { registerTextField } = useMUIField(form.control);
  const formField = {
    name: registerTextField('name'),
    scriptsRules: useController({ name: 'scriptRules', control: form.control }), // custom tabController
    action: useController({ name: 'action', control: form.control }), // custom tabController
  };
  const [nameFocused, setNameFocused] = React.useState(false);
  const [editing, setEditing] = React.useState(false);

  function reset() {
    setNameFocused(false);
  }

  const handleClose = () => {
    onClose?.({}, 'backdropClick');
    onChange?.('edited');
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    async function sendParcours() {
      try {
        await parcoursUpdate({
          id: value.id,
          script: {
            ...value.script,
            rule: {
              ...value.script.rule,
              where: data.scriptRules,
            },
            action: data.action,
          },
          name: data.name,
        });
        toast.present({ message: 'Le parcours a été modifié', severity: 'success' });
        return true;
      } catch {
        return false;
      }
    }

    const result = await sendParcours();
    if (!result) {
      toast.present({ message: "Une erreur s'est produite lors de la validation", severity: 'error' });
    }
    handleClose();
  });

  // Reset form when hidden
  React.useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  return (
    <CustomModal
      showExitIcon={!form.formState.isSubmitting}
      PaperProps={{
        // @ts-ignore There is a typing problem
        component: 'form',
        onSubmit: handleSubmit,
      }}
      fullScreen
      disabledBackdropDismiss={form.formState.isSubmitting}
      style={{ paddingBottom: '0', maxWidth: '100%', margin: 'auto' }}
      renderHeader={() => (
        <>
          {nameFocused ? (
            <OutlinedInput
              placeholder={'Nom du parcours'}
              autoFocus
              {...formField.name}
              onBlur={() => setNameFocused(false)}
              inputProps={{ style: { paddingTop: '10px', paddingBottom: '10px' } }}
            />
          ) : (
            <Button color="tertiary" endIcon={<Edit />} onClick={() => setNameFocused(true)}>
              <Typography variant="h6">{form.getValues('name')}</Typography>
            </Button>
          )}
          {value.legal ? (
            <Typography variant="subtitle2" component="span" sx={{ ml: 2 }}>
              {'(Parcours légal)'}
            </Typography>
          ) : undefined}
        </>
      )}
      open={open || false}
      onClose={handleClose}
      renderContent={() => (
        <>
          {/* Script */}
          <TabContext {...tabController.tabContextProps}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList {...tabController.tabListProps}>
                <Tab label="Actions" value={ParcoursTab.Actions} />
                <Tab label="Déclencheur" value={ParcoursTab.Rules} />
              </TabList>
            </Box>
            <TabPanel value={ParcoursTab.Rules}>
              <ParcoursRulesInput
                value={formField.scriptsRules.field.value}
                onChange={formField.scriptsRules.field.onChange}
                onEdit={(step) => setEditing(step === 'start')}
              />
            </TabPanel>
            <TabPanel value={ParcoursTab.Actions}>
              <ParcoursActionsInput
                value={formField.action.field.value}
                onChange={(_, value) => formField.action.field.onChange(value)}
              />
            </TabPanel>
          </TabContext>
        </>
      )}
      renderFooter={() => (
        <>
          <Box sx={{ flex: 1 }}>
            <Typography variant="caption">
              Modifié le {moment(value.updated_at).format(FormatDate.FRENCH_DATE)}
            </Typography>
          </Box>
          <ActionButton actionName="cancel" onClick={() => onClose?.({}, 'escapeKeyDown')} />
          <ActionButton color="primary" disabled={editing} type="submit" actionName="ok" label="Modifier le parcours" />
        </>
      )}
    />
  );
}
