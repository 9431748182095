import React from 'react';
import './AccueilRH.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import imgStats from '../../../assets/img-stats-dashboard.svg';
import { PieChartsEncours } from '../../Charts/PieChartsEncours';
import { PRO_URL } from '../../../constants/urls.constants';
import { Button, Card, CardActions, CardHeader } from '@mui/material';
import { IfPermission } from '@/components/IfPermission';
import { ListCardContent } from '../ListCard';

const WelcomeStats = () => {
  const navigate = useNavigate();

  const BtnAllStats = () => {
    return (
      <Button
        variant={'outlined'}
        onClick={() => {
          navigate(PRO_URL.PREFIXE_PRO + PRO_URL.STATS_EN_COURS);
        }}
        endIcon={
          // @ts-ignore type not correct
          <FontAwesomeIcon icon="fa-solid fa-angle-right" />
        }
      >
        Toutes les statistiques
      </Button>
    );
  };

  const CardStatistique = () => {
    return (
      <Card className="col-12" sx={{ minHeight: 350 }}>
        <CardHeader avatar={<img src={imgStats} />} className="header-stats" title={<>Statistiques</>} />
        <ListCardContent>
          <div className="col-10 mx-auto">
            <PieChartsEncours forPage="Dashboard" />
          </div>
        </ListCardContent>
        <CardActions disableSpacing>
          <BtnAllStats />
        </CardActions>
      </Card>
    );
  };

  return (
    <div className="col-12">
      <IfPermission scope="statistic" action="access">
        <CardStatistique />
      </IfPermission>
    </div>
  );
};

export default WelcomeStats;
