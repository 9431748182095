import { Response } from 'express';
import axios from '../../../../../lib/axios';
import {
  addNoteAbsenceOptionRequest,
  fetchAbsenceOptionsParams,
  fetchNoteAbsenceOptionsParams as fetchNoteAbsenceOptionsParameters,
} from '../../../../../services/rh/business';

interface INoteAbsenceOptions {
  idBusiness: any;
  notePromo: any;
  noteReduction: any;
}
export const addNoteAbsenceOption = async (data: INoteAbsenceOptions) => {
  return addNoteAbsenceOptionRequest(data);
};

export const getAbsenceOptionsParams = async (data: any, handleError: any) => {
  try {
    return await fetchAbsenceOptionsParams(data);
  } catch (error) {
    console.log('error', error);
    handleError && handleError();
  }
};

export const getNoteAbsenceOptionsParams = async (data: any, handleError: any) => {
  try {
    return await fetchNoteAbsenceOptionsParameters(data);
  } catch (error) {
    console.log('error', error);
    handleError && handleError();
  }
};
