import React, { useMemo } from 'react';
import { Absence } from '@/types';
import { ADPExportStatus } from '@/types/ADPExportStatus';
import moment from 'moment';
import { FormatDate } from '@/constants/date.constants';
import { IconButton, IconButtonProps } from '@/components/IconButton';
import { SyncAlt } from '@/components/AppIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Spacer } from '@/components/Spacer';
import { Box } from '@mui/material';

export interface ExportIconProps {
  absence: Absence;
  size?: IconButtonProps['size'];
}

export const ExportIcon = (props: ExportIconProps) => {
  const { absence, size } = props;

  function displayDateExport() {
    if (absence.absence_exports == null) return '-';
    return moment(absence.absence_exports.time).format(FormatDate.FRENCH_DATE);
  }

  const badgeColor = useMemo(() => {
    if (absence.export_status === ADPExportStatus.IntegrationFailed) return 'error';
    if (absence.export_status === ADPExportStatus.Integrated) return 'success';
    if (absence.export_status === ADPExportStatus.Exported) return 'warning';
    return 'info';
  }, [absence.export_status]);

  const badgeMessage = useMemo(() => {
    if (absence.export_status === ADPExportStatus.IntegrationFailed) {
      const message = absence.absence_logs?.find((log) => log.triggered_by === 'adp')?.description;
      return (
        <>
          <Box>
            Intégration échouée
            {message && (
              <>
                <Spacer size="small" />
                <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '0.5rem' }} />
                {message}
              </>
            )}
          </Box>
        </>
      );
    }
    if (absence.export_status === ADPExportStatus.Integrated) return `Intégration réussie le ${displayDateExport()}`;
    if (absence.export_status === ADPExportStatus.Exported) return `Absence exportée le ${displayDateExport()}`;
    return 'En attente d’intégration';
  }, [absence.export_status]);

  return (
    <IconButton
      size={size}
      badge={true}
      BadgeProps={{
        color: badgeColor,
      }}
      TooltipProps={{
        title: badgeMessage,
      }}
      icon={<SyncAlt sx={{ color: 'white' }} />}
    />
  );
};
