import React, { ComponentProps } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import { AppSelectItem } from './data';
import { AppInputLabel } from '../AppInputLabel';
import { AppInput } from '../AppInput';

type InputValue = string | undefined;

interface AppSelectProps extends Omit<ComponentProps<typeof Select<InputValue>>, 'onChange'> {
  label?: React.ReactNode;
  items: Array<AppSelectItem<InputValue>>;
  helperText?: string;
  error?: boolean;
  LabelProps?: React.ComponentProps<typeof InputLabel>;
  onChange?: (event: SelectChangeEvent<'' | InputValue>, value: InputValue) => void;
}

export const AppSelect = React.forwardRef(function AppSelect(
  props: AppSelectProps,
  ref: React.ForwardedRef<typeof Select<InputValue>>,
) {
  const TextFieldProps: Pick<AppSelectProps, 'disabled' | 'readOnly' | 'required' | 'error'> = {
    disabled: props.disabled,
    error: props.error,
    readOnly: props.readOnly,
    required: props.required,
  };

  const { label, items, helperText: helper, placeholder, LabelProps, onChange, ...otherProps } = props;

  const handleChange = (event: SelectChangeEvent<SelectProps<InputValue>['value']>) => {
    const { target } = event;
    const newValues = target.value;
    onChange?.(event, newValues);
  };

  return (
    <FormControl size="small" sx={{ width: '100%' }} {...TextFieldProps}>
      <AppInputLabel shrink {...LabelProps} style={{ top: '7px', left: '-14px' }}>
        {label}
      </AppInputLabel>
      <Select ref={ref} {...otherProps} onChange={handleChange} input={<AppInput />}>
        {placeholder && (
          <MenuItem disabled value={undefined}>
            {placeholder}
          </MenuItem>
        )}
        {items.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helper ?? ' '}</FormHelperText>
    </FormControl>
  );
});
