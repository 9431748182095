export const getLabelDocument = (document: any): string => {
  const absenceType = document.absenceType?.value;
  const absenceType1 = document.absenceType1?.value;
  switch (absenceType) {
    case '1': {
      return absenceType1 === '1' ? 'Arrêt de travail(maladie)' : 'Arrêt de travail(accident)';
    }
    case '2': {
      return "Bulletin d'hospitalisation";
    }
    default: {
      if (document?.relationEnfant?.value !== 'pere') {
        return "Attestation sur l'honneur du conjoint";
      }
      return 'Certificat de maternité';
    }
  }
};
