import React, { useEffect, useState } from 'react';
import { AbsencesInputSelect, AbsencesInputSelectProperties } from './AbsencesInputSelect';
import { Absence } from '@/types';
import { AbsencesFilters, fetchRhAbsences } from '@/services/rh';

export interface AbsencesInputProperties {
  value: Absence[];
  onChange: AbsencesInputSelectProperties['onChange'];
  filters: AbsencesFilters;
}

export const AbsencesInput = ({ value, filters, onChange }: AbsencesInputProperties) => {
  const [absences, setAbsences] = useState<Absence[]>([]);

  async function fetchAbsencesByFilters(page?: number) {
    const pagination = { page: page ?? 1, per_page: 10 };
    const { data } = await fetchRhAbsences({ ...filters, ...pagination });
    setAbsences((initialValue) => (pagination.page === 1 ? data : [...initialValue, ...data]));
    if (data.length === pagination.per_page) await fetchAbsencesByFilters(pagination.page + 1);
  }

  function excludeAbsences(values: Absence[], exclude: Absence | Absence[]) {
    return values.filter(
      (absence) =>
        (Array.isArray(exclude) && !exclude.map(({ id }) => id).includes(absence.id)) ||
        (!Array.isArray(exclude) && absence.id !== exclude.id),
    );
  }

  function parsedAbsences(values: Absence | Absence[]) {
    return Array.isArray(values) ? values : [values];
  }

  useEffect(() => void fetchAbsencesByFilters(), [filters.id_employee_information]);

  return (
    <AbsencesInputSelect
      items={absences}
      value={value}
      onChange={(e, selectedAbsences) => {
        if (e.checked) onChange(e, [...excludeAbsences(value, selectedAbsences), ...parsedAbsences(selectedAbsences)]);
        else onChange(e, excludeAbsences(value, selectedAbsences));
      }}
    />
  );
};
