/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { ToolbarButton, ToolbarButtonProps } from './ToolbarButton';
import { useSlate } from 'slate-react';
import { Editor } from 'slate';
import { CustomText } from '../type';
import { isMarkActive } from '../editor/isMarkActive';

export interface MarkButtonProps extends ToolbarButtonProps {
  mark: keyof Omit<CustomText, 'text'>;
}

/**
 * Toolbar button for bold text mark
 *
 * @see ToolbarButton
 */
export const MarkButton = React.forwardRef(function MarkButton(props: MarkButtonProps, ref) {
  const { mark, ...otherProps } = props;
  const editor = useSlate();
  const selected = isMarkActive(editor, mark);
  const handleMouseDown: ToolbarButtonProps['onMouseDown'] = (event) => {
    if (isMarkActive(editor, mark)) {
      Editor.removeMark(editor, mark);
    } else {
      Editor.addMark(editor, mark, true);
    }
    props.onMouseDown?.(event);
  };

  return <ToolbarButton ref={ref} {...otherProps} selected={selected} onMouseDown={handleMouseDown} />;
});
