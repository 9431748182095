// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (max-width: 991px) {
  .details-entretien {
    font-size: 17px;
    line-height: 1.5em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/choix/ChoixSST/ChoixSST.css"],"names":[],"mappings":"AAAA;EACE;IACE,eAAe;IACf,kBAAkB;EACpB;AACF","sourcesContent":["@media all and (max-width: 991px) {\n  .details-entretien {\n    font-size: 17px;\n    line-height: 1.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
