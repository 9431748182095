export type CompanyId = string;

export type BusinessId = string;

export interface Business {
  id: BusinessId;
  name: string;
  address: string;
  city: string;
  zipcode: string;
  country: string;
  status: string;
}

export interface ParentCompany {
  name: string;
  businesses: Array<Business>;
}
