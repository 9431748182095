import React from 'react';
import { AbsencesDeleteOptionsItem } from './data';

interface AbsencesDeleteCausePickerLabelProps {
  item: AbsencesDeleteOptionsItem;
  value: string | null;
  disabled?: boolean;
  onChange: (value: string) => void;
}

export const AbsencesDeleteCausePickerLabel = ({
  item,
  value,
  disabled,
  onChange,
}: AbsencesDeleteCausePickerLabelProps) => {
  return (
    <>
      {item.input ? (
        <>
          <div className="AbsencesDeleteCausePickerLabel">
            <span className="m-auto">{item.label}</span>
            <input
              className="m-auto"
              value={value ?? undefined}
              onChange={({ target }) => onChange(target.value)}
              onBlur={({ target }) => onChange(target.value)}
              disabled={disabled}
              name="inputCause"
              type="text"
              placeholder="Indiquez la raison ici..."
            />
          </div>
        </>
      ) : (
        <>{item.label}</>
      )}
    </>
  );
};
