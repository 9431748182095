import * as React from 'react';
import { List, Divider, ListItem, ListItemButton } from '@mui/material';
import { menuWidth } from './data';
import logo from '../../../Logotype-Simple-Fondclair.svg';
import { PRO_URL } from '@/constants/urls.constants';
import { useNavigate } from 'react-router-dom';

export function LayoutLogo() {
  const navigate = useNavigate();

  return (
    <div style={{ paddingBottom: '3rem' }}>
      <div style={{ background: 'white' }}>
        <List
          style={{
            position: 'fixed',
            top: 0,
            width: `calc(${menuWidth}px - 2px)`,
            marginLeft: 1,
            background: 'white',
            zIndex: 100,
            paddingBottom: 0,
          }}
        >
          <ListItem disablePadding>
            <ListItemButton
              sx={{ mt: '0.5rem', mb: '1rem' }}
              onClick={() => navigate(PRO_URL.PREFIXE_PRO + PRO_URL.ACCUEIL)}
            >
              <img src={logo} className="App-logo" alt="logo" width="73" height="30" />
            </ListItemButton>
          </ListItem>

          <Divider />
        </List>
      </div>
    </div>
  );
}
