import React from 'react';
import moment from 'moment/moment';
import { RHNotificationParcours } from '@/types';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';
import { Box } from '@mui/material';
import { parcoursFinish } from '@/services/rh/parcours/parcoursFinish';
import { useToast } from '@/components/Toast';

interface ActionCardParcoursProperties {
  rhNotification: RHNotificationParcours;
  onAction?: () => void;
}

export const ActionCardParcours = (params: ActionCardParcoursProperties) => {
  const toast = useToast();
  const { rhNotification, onAction } = params;

  async function finishParcours() {
    if (rhNotification.id_parcours == null || rhNotification.employee_information?.id == null)
      return toast.present({ message: 'Impossible de clôturer ce parcours', severity: 'error' });

    await parcoursFinish({
      parcours_id: rhNotification.id_parcours,
      employee_information_id: rhNotification.employee_information.id,
      id_rh_notification: rhNotification.id,
    });
    toast.present({ message: 'Fin de parcours enregistrée', severity: 'success' });
    return onAction?.();
  }

  return (
    <div>
      <p>
        <strong>{rhNotification.type_notification}</strong>
        <span>
          &nbsp; - Déclenché le {moment(rhNotification.date_notification).format('DD/MM/YYYY')}
          {' à '}
          {moment(rhNotification.date_notification).format('HH:mm')}
        </span>
      </p>

      <Box textAlign="right">
        <Spacer />
        <Button onClick={finishParcours}>Clôturer le parcours</Button>
      </Box>
    </div>
  );
};
