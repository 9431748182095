import React, { ForwardedRef } from 'react';
import { styled, Typography } from '@mui/material';

export interface MessageProps extends Omit<React.ComponentProps<typeof Typography>, 'sx'> {
  severity?: 'success' | 'error';
}

export const Message = React.forwardRef(function Typography(props: MessageProps, ref: ForwardedRef<HTMLDivElement>) {
  const { children, severity, ...othersProps } = props;

  return (
    <MessageToast
      ref={ref}
      sx={(theme) => ({
        color: severity === 'success' ? theme.palette.primaryBackground.contrastText : theme.palette.error.main,
      })}
      {...othersProps}
    >
      {children}
    </MessageToast>
  );
});

export const MessageToast = styled(Typography)(({ theme }) => ({
  maxWidth: 266,
  fontSize: 14,
  marginTop: 0.5,
  fontWeight: 'bold',
}));
