import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { employeeSendCodeNoAuth, employeeVerifyContact } from '../../../../../services/employee';

const ModifyContactUI = () => {
  const [tel, setTel] = useState('');
  const [mail, setMail] = useState('');
  const [validContact, setValidContact] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const state = useLocation();
  useEffect(() => {
    if (!state.state) {
      navigate('/login');
    }
    if (mail || tel) {
      employeeVerifyContact({
        phone: tel,
        email: mail,
        idEmployeeLogin: state.state.data.id,
      }).then((value) => {
        if (value.data.hasExisted) {
          setMessage('Adresse email ou téléphone sont déjà utilisés');
          setValidContact(false);
        } else {
          setMessage('');
          setValidContact(true);
        }
      });
    } else if (!mail && !tel) {
      setMessage('');
      setValidContact(false);
    }
  }, [mail, tel]);

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      if (validContact) {
        employeeSendCodeNoAuth({
          idEmployeeLogin: state.state.data.id,
          email: state.state.data.email,
          idEmployeeInformation: state.state.data.employee_information.id,
          phone: state.state.data.phone,
        })
          .then((value) => {
            navigate('/confirmation', {
              state: {
                id: state.state.data.id,
                mail: mail,
                telephone: tel,
                nom: state.state.data.employee_information.lastname,
                page: state.state.page,
                document: state.state.document,
                employee_information: {
                  business: {
                    id: state.state.data.employee_information.business.id,
                  },
                },
              },
            });
          })
          .catch((err) => {
            navigate('/confirmation', {
              state: {
                id: state.state.data.id,
                mail: mail,
                telephone: tel,
                nom: state.state.data.employee_information.lastname,
                page: state.state.page,
                document: state.state.document,
                employee_information: {
                  business: {
                    id: state.state.data.employee_information.business.id,
                  },
                },
              },
            });
          });
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <form onSubmit={handleSubmit} className="mt-5 mb-5 col-lg-4">
          <h3 className="question-form mb-5">Informations de contact</h3>

          <div className="msg-pass">
            <em>
              {`Ces informations permettent à KERij de vous
                            accompagner tout au long de vos parcours d'absences.
                            Elles ne seront, en aucun cas, transmises à votre
                            employeur.`}
            </em>
          </div>

          <label>
            <input
              name="tel"
              type="tel"
              placeholder="Téléphone"
              pattern="[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            />
          </label>

          <label>
            <input
              name="mail"
              type="email"
              placeholder="Email"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />
          </label>
          <div className="err">{message}</div>
          <button className="btn-submit">Valider</button>
        </form>
      </div>
    </div>
  );
};

export default ModifyContactUI;
