import React from 'react';
import { Service, ServiceCategoryType } from '@/types';
import { Typography } from '@mui/material';

export interface ServiceCardInfosProps {
  value: Service;
}

export function ServiceCardInfos(props: ServiceCardInfosProps) {
  const { value } = props;

  const categories = value.categories?.filter((category) => category.type === ServiceCategoryType.Category) ?? [];
  const subcategories = value.categories?.filter((category) => category.type === ServiceCategoryType.Subcategory) ?? [];

  return (
    <>
      <div style={{ paddingTop: '0.5rem' }}>
        <h3 style={{ maxHeight: '3rem', fontWeight: 'bolder', fontSize: '1rem' }}>{value.title.toUpperCase()}</h3>
      </div>

      <div>
        <Typography fontWeight="bolder" fontSize="0.5rem">
          {categories.map(({ reference }) => reference.label.toUpperCase()).join(' / ')}
        </Typography>
      </div>
      <div>
        <Typography fontWeight="bolder" fontSize="0.5rem">
          {subcategories.map(({ reference }) => reference.label).join(' / ')}
        </Typography>
      </div>
    </>
  );
}
