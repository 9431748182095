import { Business } from './business.interface';
import { EmployeeInformation } from './user.interface';
import { Absence } from './absences.type';
import { RHPermissionScope } from './RHPermissionScope';

export const RHNotificationAssociationType = {
  Absence: 'Absence',
  Parcours: 'Parcours',
};
export type RHNotificationAssociationType =
  (typeof RHNotificationAssociationType)[keyof typeof RHNotificationAssociationType];
export const isRHNotificationAssociationType = (value: unknown): value is RHNotificationAssociationType =>
  value != null && Object.values(RHNotificationAssociationType).includes(value as any);

export interface RHNotificationAbsence extends RHNotificationBase {
  association_id: string;
  association_type: typeof RHNotificationAssociationType.Absence;
  id_absence?: string;
  id_parcours: undefined;
}

export interface RHNotificationParcours extends RHNotificationBase {
  association_id: string;
  association_type: typeof RHNotificationAssociationType.Parcours;
  id_parcours?: string;
  id_absence: undefined;
}

export interface RHNotificationBase {
  id: string;
  id_employee_information?: string | null;
  id_business?: string | null;
  document?: string | null;
  action?: 'refused' | 'progress' | 'finished' | 'deleted' | string;
  type_notification?: string;
  current_level?: number | null;
  max_level?: number | null;
  is_notify_sst?: boolean;
  is_checked?: boolean;
  type_demande_interview?: string | null;
  person_demande_interview?: string | null;
  url_video?: string | null;
  url_interview?: string | null;
  mail_contact?: string | null;
  next_send_date?: string | null;
  next_send_date_salarie?: string | null;
  next_send_date_manager?: string | null;
  date_send_to_salarie?: string | null;
  date_send_to_manager?: string | null;
  date_notification: string;

  total?: string | number;
  employee_information?: EmployeeInformation;
  absence?: Absence;
  date_interviews?: any;
  business?: any;
  absence_documents?: [];
  medical_documents?: MedicalDocument[];
  interview_documents?: InterviewDocument[];
  rh_notification_histories?: RHNotificationHistory[];
}

export type RHNotification = RHNotificationAbsence | RHNotificationParcours;

export interface InterviewDocument {
  url: string;
  name: string;
  type: string;
}

export interface MedicalDocument {
  url: string;
  name: string;
  type: string;
}
export interface ReportDocument {
  url: string;
  name: string;
  type: string;
  is_downloaded: boolean;
}

export interface RHNotificationHistory {
  report_documents: ReportDocument[];
  id: string;
  created_at: string;
  updated_at: string;
  id_rh_notification: string;
  action_level: number;
  action: string;
  document: string;
  date_notification: string;
}

// Warning : Extracted type from use in the code
export interface RHInformationRole {
  permissions: Array<string>;
}

export interface RHInformation {
  id: string;
  created_at: string;
  updated_at: string;
  birthname: string;
  firstname: string;
  lastname: string;
  id_business?: string;
  id_parent_company?: string;
  id_group?: string;
  rh_role: RHInformationRole;
  rh_secures: RHPermissions;
  business: Business;
  rh_login: RHLoginInterface;
  id_rh_information: string;

  id_businesses?: string; // Extracted from code (seems to be JSON data)
  id_parent_companies?: string; // Extracted from code (seems to be JSON data)
}

export type RHPermissions = Array<RHPermission>;

interface RHPermission {
  id_role: string;
  id_business: string;
  id_rh_information: string;
}

export interface RHPermissionScopeEntry {
  access: boolean;
  edit: boolean;
}

export type RHPermissionScopes = {
  [scope in RHPermissionScope]?: RHPermissionScopeEntry;
};

export interface RHRole {
  is_admin: boolean;
  role_name: string;
  slug: RHPermissionScope;
  id: string;
}

export interface RHLoginInterface {
  created_at: string;
  updated_at: string;
  email: string;
  id: string;
  id_rh_information: string;
  rh_information: RHInformation;
  phone: string;
}
