import * as React from 'react';
import { Tooltip, styled } from '@mui/material';
import { IconButton } from '@/components/IconButton';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface AppTooltipInfoProps {
  title: React.ReactNode;
}

export function AppTooltipInfo(props: AppTooltipInfoProps) {
  return (
    <Tooltip placement="right-end" {...props}>
      <ToolTipButton size="small" icon={faInfoCircle} disableRipple />
    </Tooltip>
  );
}

const ToolTipButton = styled(IconButton)({
  width: '1rem',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
});
