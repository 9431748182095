import axios from '../../../lib/axios';

export const GetInterviews = ({
  listSelectBusiness,
  selectedIdBusiness,
  offset,
  limit,
  setListInterviews,
  typeInterview,
}: any) => {
  if (selectedIdBusiness) {
    axios
      .get('/api/rh/interviews', {
        params: {
          typeInterview: typeInterview,
          idBusinesses: JSON.stringify(
            listSelectBusiness.filter((val: any) => val.isChecked).map((val: any) => val.id),
          ),
          idBusiness: selectedIdBusiness,
          offset: offset,
          limit: limit,
        },
      })
      .then((value) => {
        setListInterviews(
          value.data.interviews.map((value: any) => {
            return {
              ...value,
              isShownGererNotif: false,
              isShown: false,
            };
          }),
        );
      });
  }
};
