import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './ResetPassword.css';
import ReactLoading from 'react-loading';
import { employeeSendCodeNoAuth } from '../../../../../services/employee';
import { Button } from '@/components/Button';

function ResetPasswordUI() {
  // const cookies = Cookies();
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const state = useLocation();
  const [error, setError] = useState<string>('');
  const [messagePaste, setMessagePaste] = useState<string>('');

  useEffect(() => {
    if (!state.state) {
      navigate('/forgotPassword');
    }
  }, []);

  const handleClick = (event: any) => {
    event.preventDefault();
    try {
      if (!newPassword || !confirmPassword) {
        alert('Attention, tous les champs doivent être renseignés');
        return;
      } else if (newPassword === confirmPassword) {
        setIsLoading(true);
        employeeSendCodeNoAuth({
          idEmployeeLogin: state.state.id_salarie_login,
          email: state.state.mail,
          phone: state.state.telephone,
          idEmployeeInformation: state.state.idEmployeeInformation,
        })
          .then((value) => {
            setIsLoading(false);
            navigate('/checkCode', {
              state: {
                id_salarie_login: state.state.id_salarie_login,
                mot_de_passe: newPassword,
                document: state.state.document,
                idBusiness: state.state.idBusiness,
                email: state.state.mail,
              },
            });
          })
          .catch((error_) => {
            setIsLoading(false);
            navigate('/checkCode', {
              state: {
                id_salarie_login: state.state.id_salarie_login,
                mot_de_passe: newPassword,
                document: state.state.document,
                idBusiness: state.state.idBusiness,
                email: state.state.mail,
              },
            });
          });
      } else {
        setError('Attention, les mots de passe ne correspondent pas.');
        return;
      }
    } catch {
      alert('Erreur');
    }
  };

  const handleOnPaste = (event: any) => {
    event.preventDefault();
    setMessagePaste('Impossible de copier-coller');
  };

  return (
    <div>
      {isLoading ? (
        <div className="container-fluid container-loading">
          <h2>Veuillez patienter</h2>
          <div className="container-load">
            <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <form className="mt-5 mb-5 col-lg-4" onSubmit={handleClick}>
            <h3 className="question-form mb-5">Mettre à jour votre mot de passe</h3>

            <div className="input-group">
              <label>Nouveau mot de passe</label>
              <div className="label-password">
                <input
                  name="newpassword"
                  type={showNewPassword ? 'text' : 'password'}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  value={newPassword}
                  className="input-password"
                  onChange={(e) => {
                    setMessagePaste('');
                    setNewPassword(e.target.value);
                  }}
                  onPaste={(e) => e.preventDefault()}
                  required
                />
                <FontAwesomeIcon
                  icon={showNewPassword ? faEyeSlash : faEye}
                  className="icon-show-password"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                ></FontAwesomeIcon>
              </div>
            </div>
            <div className="msg-pass">
              <em>
                Votre mot de passe doit être composé de 8 caractères minimum, au moins une lettre majuscule, au moins
                une lettre minuscule, et une valeur numérique.
              </em>
            </div>

            <div className="input-group">
              <label>Confirmation du mot de passe</label>
              <div className="label-password">
                <input
                  name="confirmpassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder={messagePaste}
                  value={confirmPassword}
                  onChange={(e) => {
                    setMessagePaste('');
                    setConfirmPassword(e.target.value);
                  }}
                  onPaste={handleOnPaste}
                  onCopy={false as any}
                  required
                />
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                  className="icon-show-password"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                ></FontAwesomeIcon>
              </div>
            </div>
            <div className="err">{error}</div>
            <Button type="submit">Valider</Button>
          </form>
        </div>
      )}
    </div>
  );
}

export default ResetPasswordUI;
