import React, { useState, useEffect } from 'react';
import '../DashboardRH.css';
import './DetailsAbsence.css';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import axios from '../../../lib/axios';
import logo from '../../../Logotype-Simple-Fondclair.svg';
import Modal from 'react-modal';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { customStyles } from '../../../shared/models/custom_modal.models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PRO_URL } from '../../../constants/urls.constants';
import { StatusCodes } from 'http-status-codes';
import { verifyToken } from '../../../services/auth';
import { AbsenceContactType } from '@/types';
import { useToast } from '@/components/Toast';

function DetailsAbsence() {
  const toast = useToast();

  const [searchParams] = useSearchParams();
  const [dataAbsences, setDataAbsences] = useState();
  const [modalAllFile, setModalAllFile] = useState(false);
  const test = 0;
  const token = searchParams.get('token_decode');
  const [decode, setDecode] = React.useState({
    idAbsence: '',
    idBusiness: '',
  });

  useEffect(() => {
    if (token) {
      verifyToken({}, token)
        .then((value) => {
          if (value.status === StatusCodes.OK) {
            setDecode(value.data.decode);
          }
        })
        .catch(() => {
          toast.present({ message: 'Connexion interrompue', severity: 'error' });
        });
    }
  }, []);

  useEffect(() => {
    if (test === 0 && decode.idAbsence && decode.idBusiness) {
      axios
        .get(`/api/absences/${decode.idAbsence}`, {
          params: {
            idBusiness: decode.idBusiness,
          },
        })
        .then((value) => {
          if (value.status === StatusCodes.OK) {
            setDataAbsences(value.data.absence);
          }
        });
    }
  }, [decode]);

  return (
    <div>
      <div className="px-4 my-3">
        <Link className="text-link mt-4 pt-5" to={PRO_URL.PRO} style={{ color: 'inherit', textDecoration: 'inherit' }}>
          <img src={logo} className="App-logo" alt="logo" width="73" />
        </Link>
      </div>
      <div className="container-fluid mt-5">
        <div className="row col-12 col-lg-8 mb-5 text-center">
          <div className="text-center col-12 mb-5">
            <h1 className="titre-rh titre-details">Visualisation de l'absence</h1>
          </div>

          {dataAbsences && (
            <div>
              {!dataAbsences.date_return ? (
                <h3 className="question-form titre-modal mb-3 mt-3">
                  {dataAbsences.employee_information.firstname + ' ' + dataAbsences.employee_information.lastname} a
                  déclaré une nouvelle absence
                </h3>
              ) : (
                <h3 className="question-form titre-modal mb-3 mt-5">
                  {dataAbsences.employee_information.firstname + ' ' + dataAbsences.employee_information.lastname} a
                  déclaré son retour au travail le {moment(dataAbsences.DATE_RETOUR).format('DD/MM/YYYY')}
                </h3>
              )}
              <div className="col-12">
                <div className="card-body card-style">
                  <h6 className="question-form titre-modal mb-4 mt-4">
                    <u>Détails de l'absence</u>
                  </h6>
                  <div className="row col-12 mt-3"></div>
                  <div
                    style={{
                      textTransform: 'uppercase',
                      backgroundColor: '#0E0653',
                      color: '#FFFFFF',
                      padding: '45px',
                      margin: '15px 0 45px 0',
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderRadius: '10px',
                    }}
                  >
                    <div className="text-details-infos">
                      <strong>
                        {dataAbsences.employee_information.firstname + ' ' + dataAbsences.employee_information.lastname}
                      </strong>
                      <p className="no-p-margin text-details-infos">
                        {dataAbsences.employee_information.business.name}
                      </p>
                    </div>

                    <div className="text-details-infos">
                      <strong>Type d'arrêt</strong>
                      <p className="no-p-margin text-details-infos">{dataAbsences.type_absence.description_1_label}</p>
                    </div>

                    {dataAbsences.id && (
                      <div className="text-details-infos">
                        <strong>Détails</strong>
                        <p className="no-p-margin text-details-infos">{dataAbsences.type_absence.description_label}</p>
                      </div>
                    )}

                    {dataAbsences.id && (
                      <div className="text-details-infos">
                        <strong>Du </strong>
                        <p className="no-p-margin text-details-infos">
                          {moment(dataAbsences.date_start).format('DD/MM/YYYY')}
                        </p>
                      </div>
                    )}
                    {dataAbsences.id && (
                      <div className="text-details-infos">
                        <strong>Au </strong>
                        <p className="no-p-margin text-details-infos">
                          {dataAbsences.date_end ? moment(dataAbsences.date_end).format('DD/MM/YYYY') : '-'}
                        </p>
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      fontSize: '1.2em',
                      marginTop: '20px',
                    }}
                  >
                    {dataAbsences.contact_type === AbsenceContactType.Manager ? (
                      <FontAwesomeIcon
                        icon="fa-solid fa-check"
                        style={{
                          color: 'green',
                          marginRight: '10px',
                          fontSize: '1.1em',
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon="fa-solid fa-xmark"
                        style={{
                          color: 'red',
                          marginRight: '10px',
                          fontSize: '1.2em',
                        }}
                      />
                    )}
                    <span className="contacts-libelle">
                      {dataAbsences.contact_type === AbsenceContactType.Manager
                        ? dataAbsences.employee_information.firstname.slice(0, 1) +
                          dataAbsences.employee_information.firstname.slice(1).toLowerCase() +
                          ' ' +
                          dataAbsences.employee_information.birthname +
                          ' souhaite être contacté pendant son absence'
                        : dataAbsences.employee_information.firstname.slice(0, 1) +
                          dataAbsences.employee_information.firstname.slice(1).toLowerCase() +
                          ' ' +
                          dataAbsences.employee_information.birthname +
                          ' ne souhaite pas être contacté pendant son absence'}
                    </span>

                    <p
                      className="contacts-libelle"
                      style={{
                        fontSize: '1.1em',
                        marginTop: '35px',
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}>{dataAbsences.message ? 'Message au manager' : ''}</span>
                      {dataAbsences.message ? ' : ' + dataAbsences.message : ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {dataAbsences && modalAllFile && (
        <Modal
          // className="modal-document"
          closeTimeoutMS={200}
          isOpen={modalAllFile}
          style={customStyles}
          onRequestClose={() => {
            setModalAllFile(!modalAllFile);
          }}
          ariaHideApp={false}
          contentLabel="Informations documents"
        >
          <button
            onClick={() => {
              setModalAllFile(!modalAllFile);
            }}
            className="btn btn-lg"
          >
            <FontAwesomeIcon className="fa-2x" icon={faXmark} />
          </button>
          <h5 className="card-text text-center col-12 contacts-libelle mb-3">
            {dataAbsences.documents.length > 0 ? 'DOCUMENTS' : 'Aucun justificatif fourni'}{' '}
          </h5>
          {dataAbsences.documents.length > 0 &&
            dataAbsences.documents.map((value, index) => {
              return (
                <div className="card col-12 mt-2" key={index}>
                  <div className="card-body card-style">
                    <div className="row col-12">
                      <p className="card-text text-center col-12 col-md-2 contacts-libelle">
                        {value.type.at(0).toUpperCase() + value.type.slice(1).toLowerCase()}
                      </p>
                      <p className="card-text col-12 col-md-7 text-center mail-active">{value.name}</p>
                      {value.url && (
                        <div
                          className="col-12 col-md-2 text-center contacts-modif"
                          onClick={() => {
                            axios
                              .get('/api/file_s3', {
                                params: {
                                  url: value.url,
                                  type: 'document',
                                  idBusiness: decode.idBusiness,
                                },
                                responseType: 'arraybuffer',
                              })
                              .then((val) => {
                                var file = new Blob([val.data], {
                                  type:
                                    value.name.split('.')[1].toLowerCase() === 'pdf' ? 'application/pdf' : 'image/png',
                                });
                                var fileURL = URL.createObjectURL(file);
                                window.open(fileURL, '_blank');
                              });
                          }}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          Visualiser
                        </div>
                      )}
                      {value.url && (
                        <div
                          className="col-12 col-md-1 text-center"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            axios
                              .get('/api/file_s3', {
                                params: {
                                  url: value.url,
                                  type: 'document',
                                  idBusiness: decode.idBusiness,
                                },
                                responseType: 'arraybuffer',
                              })
                              .then((val) => {
                                var file = new Blob([val.data], {
                                  type:
                                    value.name.split('.')[1].toLowerCase() === 'pdf' ? 'application/pdf' : 'image/png',
                                });
                                var fileURL = URL.createObjectURL(file);
                                var a = document.createElement('a');
                                document.body.appendChild(a);
                                a.style = 'display: none';
                                a.href = fileURL;
                                a.download = `${value.name}`;
                                a.click();
                                a.remove();
                                window.URL.revokeObjectURL(fileURL);
                              });
                          }}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-download" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </Modal>
      )}
    </div>
  );
}

export default DetailsAbsence;
