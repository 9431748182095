import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import moment from 'moment';
import {
  employeeExtendAbsence,
  employeeUpdateAbsence,
  employeeUpdateDateReturn,
  employeeVerifyAbsence,
} from '../../services/employee';
import { StatusCodes } from 'http-status-codes';
import { DefaultLayout } from '../layouts';
import useQuestionnaire from '../../hooks/useQuestionnaire';
import RequireLogin from '../RequireLogin';
import { AccountContext, useAppSelector } from '@/store';
import { QUESTIONNAIRE_SUIVI_CHOICES, getTypeDocument } from './data';
import { Absence } from '@/types/absences.type';
import { QuestionnaireSuiviHeader } from './QuestionnaireSuiviHeader';
import { QuestionnaireSuiviForm } from './QuestionnaireSuiviForm';
import { QuestionnaireSuiviDate } from './QuestionnaireSuiviDate';
import { QuestionnaireSuiviContact } from './QuestionnaireSuiviContact';
import { FormatDate } from '@/constants/date.constants';
import { InputFile } from '../InputFile';

export function QuestionnaireSuivi() {
  const [searchParameters] = useSearchParams();
  const token = searchParameters.get('token_decode');
  const navigate = useNavigate();
  const loading = useAppSelector(AccountContext.isLoading);

  const user = useAppSelector(AccountContext.getUser);
  const [idSalarieInformation, setIdSalarieInformation] = useState();

  const [idAbsence, setIdAbsence] = useState<string>();
  const [absence, setAbsence] = useState<Absence>();
  const [etatAbsence, setEtatAbsence] = useState('1');
  const [dateRetour, setDateRetour] = useState<string | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [expire, setExpire] = useState('');

  const [decode, access, setAccess, loadingAbsence, setLoadingAbsence] = useQuestionnaire();

  async function uploadDocumentIfExist() {
    if (etatAbsence !== QUESTIONNAIRE_SUIVI_CHOICES.BACK_OTHER_DATE) setFiles([]);
    if (absence == null) return { success: false };

    const document = files.at(0);
    if (document != null && user != null) {
      const formData = new FormData();
      formData.set('idEmployeeInformation', user?.employee_information.id);
      formData.set('idAbsence', absence.id);
      formData.set('skipValidationOCR', 'true');
      formData.append('documents', document);
      formData.append('typeDocument', getTypeDocument(`${absence.type_absence.description}`));

      const response = await employeeUpdateAbsence(formData);
      if (response.status !== StatusCodes.OK) return { success: false };
    }
    return { success: true };
  }

  async function normalReturn(notification: string | null, options?: { date?: string; updateEnd?: boolean }) {
    const typeNotification = notification ?? undefined;
    const log = files.length > 0 ? 'Reprise anticipée avec certificat' : 'Reprise anticipée sans certificat';

    if (absence) {
      const dateReturn = options?.date || absence.date_end || absence.date_end_hospi_theory;
      const documentUploaded = await uploadDocumentIfExist();
      if (documentUploaded.success) {
        await employeeUpdateDateReturn({
          idAbsence: absence.id,
          dateReturn,
          typeNotification,
          idEmployeeInformation: absence.employee_information?.id,
          updateEnd: options?.updateEnd,
          log: etatAbsence === QUESTIONNAIRE_SUIVI_CHOICES.BACK_OTHER_DATE ? log : undefined,
        });
      }
    }
  }

  async function extensionReturn() {
    await employeeExtendAbsence({
      idAbsence: idAbsence,
      idEmployeeInformation: idSalarieInformation,
      typeNotification: "Notification de suivi de l'arrêt",
    });
  }

  const handleClick = async () => {
    try {
      if (etatAbsence === QUESTIONNAIRE_SUIVI_CHOICES.BACK_PROLONGATION) {
        await extensionReturn();
      } else if (idAbsence && absence) {
        const dateDeRetour = QUESTIONNAIRE_SUIVI_CHOICES.BACK_OTHER_DATE ? dateRetour : undefined;
        await (dateDeRetour
          ? normalReturn(null, {
              date: dateDeRetour,
              updateEnd: true,
            })
          : normalReturn("Notification de suivi de l'arrêt"));
      }
      navigate('/', { state: { etat: etatAbsence }, replace: true });
    } catch {
      alert('');
    }
  };

  useEffect(() => {
    if (!token) return;
    try {
      setIdSalarieInformation(decode.idEmployeeInformation);
      setIdAbsence(decode.idAbsence);
      setLoadingAbsence(true);
      employeeVerifyAbsence({
        token: token,
      }).then(async (value) => {
        console.log(value?.data?.absence);
        if (value.status === StatusCodes.OK) {
          if (value.data.absence?.action_level === '0') setAbsence(value.data.absence);
          else setExpire("Ce lien n'est plus disponible");

          setAccess(true);
          setLoadingAbsence(false);
        }
      });
    } catch {
      setAccess(false);
      setLoadingAbsence(false);
    }
  }, [loading, decode]);

  useEffect(() => {
    setError(null);
    setDateRetour(null);
  }, [etatAbsence]);

  function onDateSelected(value: string) {
    setDateRetour(value.trim() === '' ? null : value);

    const oldDateEnd = moment(absence?.date_end);
    const dateStart = moment(absence?.date_start);
    const newDateEnd = moment(value).set({ hour: 19, minute: 0, second: 0 });
    const maxEnd = oldDateEnd.subtract(1, 'd').set({ hour: 19, minute: 0, second: 0 });

    const isError = newDateEnd.isBefore(dateStart) || newDateEnd.isAfter(maxEnd);

    if (isError) {
      const baseError = 'Votre date de retour doit être';
      if (absence?.date_start === maxEnd.format(FormatDate.DATE_ONLY))
        setError(`${baseError} ${dateStart.format(FormatDate.FRENCH_DATE)}`);
      else
        setError(
          `${baseError} comprise entre ${moment(absence?.date_start).format(FormatDate.FRENCH_DATE)} et ${maxEnd.format(
            FormatDate.FRENCH_DATE,
          )}`,
        );
    } else setError(null);
  }
  return (
    <RequireLogin>
      <DefaultLayout>
        {loading && !user && loadingAbsence ? (
          <div className="container-fluid container-loading">
            <h2>Veuillez patienter</h2>
            <div className="container">
              <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
            </div>
          </div>
        ) : (
          <div>
            {expire ? (
              <div className="container-fluid mt-5">
                <h1 className="question-form mb-5">{expire}</h1>
              </div>
            ) : (
              <div>
                {access && absence ? (
                  <div className="container-fluid mt-5">
                    <QuestionnaireSuiviHeader absence={absence} />
                    <QuestionnaireSuiviForm value={etatAbsence} absence={absence} onChange={setEtatAbsence} />

                    {etatAbsence === QUESTIONNAIRE_SUIVI_CHOICES.BACK_OTHER_DATE && (
                      <>
                        <QuestionnaireSuiviDate
                          value={dateRetour}
                          onChange={onDateSelected}
                          min={absence.date_start}
                          max={moment(absence.date_end).subtract(1, 'd').format('YYYY-MM-DD')}
                        />
                        {error && <div className="err">{error}</div>}

                        <InputFile value={files} onChange={(_, files) => setFiles(files)} detectDocument={true} />
                      </>
                    )}

                    {/* Cette partie ne fait absolument rien */}
                    <QuestionnaireSuiviContact />

                    <button
                      type="button"
                      className={error == null ? 'btn btn-kerij' : 'btn btn-kerij disabled'}
                      disabled={error != null}
                      onClick={handleClick}
                    >
                      Valider mes réponses
                    </button>
                  </div>
                ) : (
                  <div className="container-fluid mt-5">
                    <h1 className="question-form mb-5">
                      {` Attention ! Vous n'avez pas l'autorisation d'accéder à cette page`}
                    </h1>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </DefaultLayout>
    </RequireLogin>
  );
}
