import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../DashboardRH.css';
import isAuthorized from '../../../hooks/isAuthorized';
import { PRO_URL } from '../../../constants/urls.constants';

function Admin() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthorized(location.pathname)) {
      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN);
    }
  }, []);
  return (
    <div>
      {/* {   !rh ?
            <div className = "container-fluid mt-5">
                <h1 className="question-form mb-5">Attention ! Vous n'avez pas l'autorisation d'accéder à cette page</h1>
            </div>
        : */}
      <div className="container-fluid mt-5">
        <h1 className="titre-rh mb-5 col-lg-8 text-left ">Boite à outils</h1>
      </div>
      {/* } */}
    </div>
  );
}

export default Admin;
