import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import ProfilValidationUI from './components/ProfilValidationUI';

export const ProfilValidationPage: React.FC = () => {
  return (
    <DefaultLayout>
      <ProfilValidationUI />
    </DefaultLayout>
  );
};
