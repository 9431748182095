import { combineReducers, Reducer } from 'redux';
import { DocumentAction, DocumentActionTypes } from '../action/document/type';

export interface IState {
  loading: boolean | undefined;
  retrieved: any;
  error: any;
}

export const initialState: IState = {
  loading: false,
  retrieved: null,
  error: null,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TODO: refactor avec RTK slice
export const createDocumentReducer: Reducer<IState> = (state: any = initialState, action: DocumentAction) => {
  switch (action.type) {
    case DocumentActionTypes.CREATE_DOCUMENT_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case DocumentActionTypes.CREATE_DOCUMENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DocumentActionTypes.CREATE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        retrieved: action.payload,
      };
    }
    case DocumentActionTypes.CREATE_DOCUMENT_RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({ createDocumentReducer });
