import React from 'react';
import { Absence } from '@/types';
import moment from 'moment';
import { displayDateEndOrHospi } from '@/utils/date.utils';

interface AbsenceDateProperties {
  value?: Pick<Absence, 'date_start' | 'date_end' | 'date_end_hospi_theory'>;
}

export const AbsenceDate = ({ value }: AbsenceDateProperties) => {
  return (
    <>
      {value && (
        <span>
          Du {moment(value.date_start).format('DD-MM-YYYY')} Au {displayDateEndOrHospi(value, 'DD-MM-YYYY')}
        </span>
      )}
    </>
  );
};
