import 'dynamsoft-document-viewer/dist/ddv.css';
import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { List, type ListProps, ListItem, ListItemButton, ListItemText, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'clsx';
import { InputProps } from '@/hooks/useInput';

const componentName = 'InputFileList';

const handleClickViewFile = (value: File) => {
  const src = URL.createObjectURL(value);
  window.open(src, '_blank');
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputFileResultProps
  extends ListProps<'ul', Pick<InputProps<HTMLButtonElement, Array<File>>, 'value' | 'onChange'>> {}

export const InputFileList = (props: InputFileResultProps) => {
  const { value, onChange, ...rootProps } = props;

  return (
    <List {...rootProps} className={classNames(componentName, rootProps.className)}>
      {value?.map((file, fileIndex) => (
        <ListItem
          key={fileIndex}
          disablePadding
          secondaryAction={
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <IconButton
              edge="end"
              aria-label="delete"
              color="error"
              variant="outlined"
              onClick={(event) => {
                event.stopPropagation();
                if (value) {
                  onChange?.(
                    // @ts-ignore
                    event,
                    value.filter((_, ind) => fileIndex !== ind),
                  );
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          }
        >
          <ListItemButton onClick={() => handleClickViewFile(file)}>
            <ListItemText primary={file.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
