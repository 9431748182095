// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardContentItem {
  cursor: pointer;
}
.CardContentItem:hover {
  color: #40c9c9;
}
.CardContentItem__Label {
  font-size: 16px;
}

.CardAbsenceHistory__Actions {
  cursor: pointer;
}
.CardAbsenceHistory__Actions__Title {
  font-size: 16px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/DashboardRH/EnCours/components/Absences/CardAbsenceHistory/CardAbsenceHistory.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,cAAA;AACJ;AAEE;EACE,eAAA;AAAJ;;AAKE;EACE,eAAA;AAFJ;AAII;EACE,eAAA;EACA,iBAAA;AAFN","sourcesContent":[".CardContentItem {\n  cursor: pointer;\n\n  &:hover {\n    color: #40c9c9;\n  }\n\n  &__Label {\n    font-size: 16px;\n  }\n}\n\n.CardAbsenceHistory {\n  &__Actions {\n    cursor: pointer;\n\n    &__Title {\n      font-size: 16px;\n      font-weight: bold;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
