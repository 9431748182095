import React, { useState, useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import './ChoixDateManager.css';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { Collapse, Button } from 'react-bootstrap/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import business from 'moment-business';
import 'moment/locale/fr';
import axios from '../../../lib/axios';
import logo from '../../../Logotype-Simple-Fondclair.svg';
import ReactLoading from 'react-loading';
import Calendar from 'react-calendar';
import { PRO_URL } from '../../../constants/urls.constants';
import { verifyToken } from '../../../services/auth';
import { useToast } from '@/components/Toast';

const ChoixDateManager = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();
  const token = searchParameters.get('token_decode');
  const [decode, setDecode] = useState({
    idRhNotification: '',
    idEmployeeInformation: '',
    idBusiness: '',
    maxLevel: '',
    type_contact: '',
  });
  const [dateNotif, setDateNotif] = useState();
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [listDate, setListDate] = useState([
    {
      date: '',
      from: '',
      to: '',
    },
    {
      date: '',
      from: '',
      to: '',
    },
    {
      date: '',
      from: '',
      to: '',
    },
  ]);
  const [access, setAccess] = useState(true);

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [day1, setDay1] = useState(new Date());
  const [day2, setDay2] = useState(new Date());
  const [day3, setDay3] = useState(new Date());
  const [unavailability, setUnavailability] = useState(false);
  // const unavailability = true;
  const maxDate = dateNotif && new Date(business.addWeekDays(moment(dateNotif), 12) as any);
  const minDate = dateNotif && new Date(business.addWeekDays(moment(dateNotif), 3) as any);

  // const [error, setError] = useState("");
  useEffect(() => {
    if (token) {
      verifyToken({}, token)
        .then((value) => {
          if (value.status === 200) {
            setDecode(value.data.decode);
          }
        })
        .catch(() => {
          toast.present({ message: 'Connexion interrompue', severity: 'error' });
        });
    }
  }, []);

  useEffect(() => {
    if (decode.idRhNotification && decode.idEmployeeInformation && decode.idBusiness) {
      axios
        .post('/api/rh/verify_selected_date', {
          idRhNotification: decode.idRhNotification,
          idBusiness: decode.idBusiness,
        })
        .then((value) => {
          setType(value.data.typeNotificiation);
          if (
            value.data.isSelectedDate ||
            value.data.current_level === 1 ||
            (value.data.current_level === 3 && value.data.typeNotificiation === 'Planifier entretien de retour')
          ) {
            setAccess(false);
          }
        });

      axios
        .get('/api/rh/get_date_notif', {
          params: {
            idRhNotification: decode.idRhNotification,
            idEmployeeInformation: decode.idEmployeeInformation,
            idBusiness: decode.idBusiness,
          },
        })
        .then((value) => {
          setName(value.data.name);
          setDateNotif(value.data.dateNotif);
        });

      setAccess(true);
    } else {
      setAccess(false);
    }
  }, [decode]);

  const handleUnavailable = () => {
    setIsLoading(true);
    axios
      .post('/api/rh/suggest_date', {
        unavailability,
        idRhNotification: decode.idRhNotification,
        idEmployeeInformation: decode.idEmployeeInformation,
        idBusiness: decode.idBusiness,
        type: type,
        maxLevel: decode.maxLevel,
      })
      .then((value) => {
        if (value.status === 200) {
          navigate(PRO_URL.PREFIXE_PRO + PRO_URL.DATE_CHOICE_MANAGER_END, {
            state: { unavailability, dateNotif },
          });
        }
      });
  };

  return (
    <div>
      <div className="px-4 my-3">
        <Link className="text-link mt-4 pt-5" to={PRO_URL.PRO} style={{ color: 'inherit', textDecoration: 'inherit' }}>
          <img src={logo} className="App-logo" alt="logo" width="73" />
        </Link>
      </div>
      {isLoading ? (
        <div className="container-fluid container-loading">
          <h2>Veuillez patienter</h2>
          <div className="container-load">
            <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
          </div>
        </div>
      ) : (
        <div>
          {access ? (
            <div className="container-fluid mt-5">
              <div className="row col-12 col-lg-8 mb-5 text-center">
                <div className="text-center col-12 mb-5">
                  <h1 className="titre-rh titre-details">
                    Planification{' '}
                    {((type === 'Entretien de liaison' || type === 'Planifier entretien de liaison') &&
                      "d'entretien de liaison") ||
                      ((type === "Entretien de retour a l'emploi" || type === 'Planifier entretien de retour') &&
                        "d'entretien de retour à l'emploi")}
                  </h1>
                </div>
                <div>
                  <h3 className="question-form mb-5 mt-5">
                    {name}{' '}
                    {((type === 'Entretien de liaison' || type === "Entretien de retour a l'emploi") &&
                      'a sollicité') ||
                      ((type === 'Planifier entretien de liaison' || type === 'Planifier entretien de retour') &&
                        'doit suivre')}{' '}
                    {((type === 'Entretien de liaison' || type === 'Planifier entretien de liaison') &&
                      'un entretien de liaison') ||
                      ((type === "Entretien de retour a l'emploi" || type === 'Planifier entretien de retour') &&
                        "un entretien de retour à l'emploi")}
                  </h3>

                  <h5 className="question-form mt-3">
                    Veuillez choisir 3 créneaux sous 10 jours ouvrés du {moment(minDate).format('DD/MM/YYYY')} au{' '}
                    {moment(maxDate).format('DD/MM/YYYY')}
                  </h5>

                  <label className="question-form ml-3">
                    Je suis absent sur cette période{' '}
                    <input
                      type="checkbox"
                      value={unavailability as any}
                      onChange={(e) => {
                        setUnavailability(e.target.checked);
                        // eslint-disable-next-line unicorn/prefer-query-selector
                        const choice: any = document.getElementById('choice');
                        choice.style.display = e.target.checked ? 'none' : 'block';
                      }}
                      className="form-check-input checkbox-business my-1"
                    />
                  </label>

                  <div className="col-12 text-center row mt-3" id="choice">
                    {/* Date 1 */}
                    <hr className="col-8 col-xl-6 offset-3 text-center my-3"></hr>

                    <Button
                      className="col-12 mb-3 btn-choice"
                      onClick={() => {
                        setOpen1(!open1);
                        setOpen2(false);
                        setOpen3(false);
                      }}
                      aria-controls="example-collapse-text"
                      aria-expanded={open1}
                    >
                      <b>Premier choix</b>
                      {open1 ? (
                        <FontAwesomeIcon
                          style={{
                            fontSize: '1.2em',
                            marginLeft: '10px',
                          }}
                          icon={'fa-solid fa-caret-left' as any}
                        />
                      ) : (
                        <FontAwesomeIcon
                          style={{
                            fontSize: '1.2em',
                            marginLeft: '10px',
                          }}
                          icon={'fa-solid fa-caret-down' as any}
                        />
                      )}
                    </Button>

                    {
                      <p>
                        <b>
                          {listDate.map((value, index) => {
                            if (index === 0) {
                              return (
                                value.date &&
                                moment(new Date(value.date)).format('LL') + '    ' + value.from + ' - ' + value.to
                              );
                            }
                            return '';
                          })}
                        </b>
                        {/* <FontAwesomeIcon icon="fa-solid fa-trash" /> */}
                      </p>
                    }

                    <Collapse in={open1}>
                      <div className="calandar-container">
                        <div className="calandar-block">
                          <Calendar
                            onChange={setDay1 as any}
                            value={day1}
                            onClickDay={(value_) => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.date = moment(value_).format('YYYY-MM-DD');
                                  }
                                  return value;
                                }),
                              );
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            defaultValue={day1}
                          />
                        </div>

                        <div className="container-time col-4 text-center" id="example-collapse-text">
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '09:00';
                                    value.to = '10:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>09:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '09:30';
                                    value.to = '10:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>09:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '10:00';
                                    value.to = '11:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>10:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '10:30';
                                    value.to = '11:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>10:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '11:00';
                                    value.to = '12:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>11:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '11:30';
                                    value.to = '12:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>11:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '12:00';
                                    value.to = '13:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>12:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '14:00';
                                    value.to = '15:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>14:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '14:30';
                                    value.to = '15:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>14:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '15:00';
                                    value.to = '16:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>15:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '15:30';
                                    value.to = '16:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>15:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '16:00';
                                    value.to = '17:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>16:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '16:30';
                                    value.to = '17:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>16:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 0) {
                                    value.from = '17:00';
                                    value.to = '18:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>17:00</b>
                          </div>
                        </div>
                      </div>
                    </Collapse>

                    {/* Duexième choix */}

                    <hr className="col-8 col-xl-6 offset-3 text-center my-3"></hr>

                    <Button
                      className="col-12 mb-3 btn-choice"
                      onClick={() => {
                        setOpen1(false);
                        setOpen2(!open2);
                        setOpen3(false);
                      }}
                      aria-controls="example-collapse-text"
                      aria-expanded={open2}
                    >
                      <b>Deuxième choix</b>
                      {open2 ? (
                        <FontAwesomeIcon
                          style={{
                            fontSize: '1.2em',
                            marginLeft: '10px',
                          }}
                          icon={'fa-solid fa-caret-left' as any}
                        />
                      ) : (
                        <FontAwesomeIcon
                          style={{
                            fontSize: '1.2em',
                            marginLeft: '10px',
                          }}
                          icon={'fa-solid fa-caret-down' as any}
                        />
                      )}
                    </Button>

                    {
                      <p>
                        <b>
                          {listDate.map((value, index) => {
                            if (index === 1) {
                              return (
                                value.date &&
                                moment(new Date(value.date)).format('LL') + '    ' + value.from + ' - ' + value.to
                              );
                            }
                            return '';
                          })}
                        </b>
                        {/* <FontAwesomeIcon icon="fa-solid fa-trash" /> */}
                      </p>
                    }

                    <Collapse in={open2}>
                      <div className="calandar-container">
                        <div className="calandar-block">
                          <Calendar
                            onChange={setDay2 as any}
                            value={day2}
                            onClickDay={(value_) => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.date = moment(value_).format('YYYY-MM-DD');
                                  }
                                  return value;
                                }),
                              );
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            defaultValue={day2}
                          />
                        </div>

                        <div className="container-time col-4 text-center" id="example-collapse-text">
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '09:00';
                                    value.to = '10:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>09:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '09:30';
                                    value.to = '10:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>09:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '10:00';
                                    value.to = '11:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>10:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '10:30';
                                    value.to = '11:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>10:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '11:00';
                                    value.to = '12:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>11:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '11:30';
                                    value.to = '12:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>11:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '12:00';
                                    value.to = '13:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>12:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '14:00';
                                    value.to = '15:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>14:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '14:30';
                                    value.to = '15:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>14:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '15:00';
                                    value.to = '16:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>15:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '15:30';
                                    value.to = '16:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>15:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '16:00';
                                    value.to = '17:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>16:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '16:30';
                                    value.to = '17:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>16:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 1) {
                                    value.from = '17:00';
                                    value.to = '18:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>17:00</b>
                          </div>
                        </div>
                      </div>
                    </Collapse>

                    {/* Date 3 */}
                    <hr className="col-8 col-xl-6 offset-3 text-center my-3"></hr>

                    <Button
                      className="col-12 mb-3 btn-choice"
                      onClick={() => {
                        setOpen1(false);
                        setOpen2(false);
                        setOpen3(!open3);
                      }}
                      aria-controls="example-collapse-text"
                      aria-expanded={open3}
                    >
                      <b>Troisième choix</b>
                      {open3 ? (
                        <FontAwesomeIcon
                          style={{
                            fontSize: '1.2em',
                            marginLeft: '10px',
                          }}
                          icon={'fa-solid fa-caret-left' as any}
                        />
                      ) : (
                        <FontAwesomeIcon
                          style={{
                            fontSize: '1.2em',
                            marginLeft: '10px',
                          }}
                          icon={'fa-solid fa-caret-down' as any}
                        />
                      )}
                    </Button>

                    {
                      <p>
                        <b>
                          {listDate.map((value, index) => {
                            if (index === 2) {
                              return (
                                value.date &&
                                moment(new Date(value.date)).format('LL') + '    ' + value.from + ' - ' + value.to
                              );
                            }
                            return '';
                          })}
                        </b>
                        {/* <FontAwesomeIcon icon="fa-solid fa-trash" /> */}
                      </p>
                    }

                    <Collapse in={open3}>
                      <div className="calandar-container ">
                        <div className="calandar-block">
                          <Calendar
                            onChange={setDay3 as any}
                            value={day3}
                            onClickDay={(value_) => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.date = moment(value_).format('YYYY-MM-DD');
                                  }
                                  return value;
                                }),
                              );
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            defaultValue={day3}
                          />
                        </div>

                        <div className="container-time col-4 text-center" id="example-collapse-text">
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '09:00';
                                    value.to = '10:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>09:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '09:30';
                                    value.to = '10:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>09:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '10:00';
                                    value.to = '11:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>10:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '10:30';
                                    value.to = '11:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>10:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '11:00';
                                    value.to = '12:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>11:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '11:30';
                                    value.to = '12:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>11:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '12:00';
                                    value.to = '13:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>12:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '14:00';
                                    value.to = '15:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>14:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '14:30';
                                    value.to = '15:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>14:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '15:00';
                                    value.to = '16:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>15:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '15:30';
                                    value.to = '16:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>15:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '16:00';
                                    value.to = '17:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>16:00</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '16:30';
                                    value.to = '17:30';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>16:30</b>
                          </div>
                          <div
                            className="time"
                            onClick={() => {
                              setListDate((date) =>
                                date.map((value, index) => {
                                  if (index === 2) {
                                    value.from = '17:00';
                                    value.to = '18:00';
                                  }
                                  return value;
                                }),
                              );
                            }}
                          >
                            <b>17:00</b>
                          </div>
                        </div>
                      </div>
                    </Collapse>

                    {/* Pas de créneau sur cette période */}
                    {/* <hr className="col-8 col-xl-6 offset-3 text-center my-3"></hr>

                                    <Button className="col-12 mb-3 btn-choice" onClick={handleUnavailable} disabled={listDate.filter(value => (value.date && value.from && value.to)).length === 3 ? "disabled" : ""}>
                                        <b>Pas de créneau sur cette période</b>
                                    </Button>
                                 */}
                  </div>

                  {/* Button valider */}

                  <div className="text-center mt-5 col-12">
                    <button
                      type="button"
                      className={
                        listDate.filter((value) => value.date && value.from && value.to).length === 3 || unavailability
                          ? 'btn btn-valid-date'
                          : 'btn btn-valid-date disabled'
                      }
                      onClick={
                        unavailability
                          ? handleUnavailable
                          : () => {
                              setIsLoading(true);
                              axios
                                .post('/api/rh/suggest_date', {
                                  date: listDate,
                                  idRhNotification: decode.idRhNotification,
                                  idEmployeeInformation: decode.idEmployeeInformation,
                                  idBusiness: decode.idBusiness,
                                  type: type,
                                  maxLevel: decode.maxLevel,
                                  type_contact: decode.type_contact,
                                })
                                .then((value) => {
                                  if (value.status === 200) {
                                    setIsLoading(false);
                                    navigate(PRO_URL.PREFIXE_PRO + PRO_URL.DATE_CHOICE_MANAGER_END, {
                                      state: {
                                        dateNotif,
                                      },
                                    });
                                  }
                                });
                            }
                      }
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container-fluid mt-5">
              <h1 className="question-form mb-5">
                {`Attention ! Vous n'avez pas l'autorisation d'accéder à cette page`}
              </h1>
            </div>
          )}
          :
        </div>
      )}
    </div>
  );
};

export default ChoixDateManager;
