import { Editor, Element, Transforms } from 'slate';
import { isBlockActive } from './isBlockActive';
import { CustomElement } from '../type';
const ListTypes = new Set(['numbered-list', 'bulleted-list']);
const TextAlignTypes = new Set(['left', 'center', 'right', 'justify']);

export const toggleBlock = (editor: Editor, format: CustomElement['type']) => {
  const isActive = isBlockActive(editor, format, /*TextAlignTypes.has(format) ? 'align' :*/ 'type');
  const isList = ListTypes.has(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && ListTypes.has(n.type) && !TextAlignTypes.has(format),
    split: true,
  });
  let newProperties: Partial<Element>;
  // if (TextAlignTypes.has(format)) {
  //   newProperties = {
  //     align: isActive ? undefined : format,
  //   };
  // } else {
  newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  //}
  Transforms.setNodes<Element>(editor, newProperties);

  if (!isActive && isList) {
    // @ts-ignore we know what we are doing
    Transforms.wrapNodes(editor, { type: format, children: [] });
  }
};
