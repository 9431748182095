import axios from 'axios';
import React, { useState } from 'react';
import { useError } from '@/shared/useError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFullName } from '../../../../shared/utils/getFullName.service';
import { StatusCodes } from 'http-status-codes';

const AskSSTInterview = ({ onRequestClose, onLoadingChange, onUpdate, selectedInterview }) => {
  const { triggerError, err } = useError();
  const [error, setError] = useState('');
  const [contactLabel, setContactLabel] = useState('');
  const [contactMail, setContactMail] = useState('');
  const [contact, setContact] = useState('0');
  const [addContact, setAddContact] = useState(false);
  const [addBtnContact, setAddBtnContact] = useState(false);
  const [isSST, setIsSST] = useState('0');
  const [isReferentHandicap, setIsReferentHandicap] = useState('0');

  return (
    <div>
      <div className="container-fluid text-center">
        {!selectedInterview.absence && (
          <div>
            <h3 className="question-form titre-modal mt-3 p-2">
              {selectedInterview.type_notification.includes('liaison')
                ? 'Entretien de liaison confirmé'
                : "Entretien de retour à l'emploi confirmé"}
            </h3>
            <p className="card-text text-center mb-2 contacts-libelle">
              {getFullName(selectedInterview.employee_information)} a confirmé son{' '}
              {selectedInterview.type_notification.includes('liaison') ? 'entretien de liaison' : 'entretien de retour'}
              , informez le Service de Santé au travail
              <br />
              <p>
                Pour pouvoir informer le SST ou le référent handicap, il faut que le contact en question soit coché
                "pour informations"
              </p>
            </p>
          </div>
        )}
        {selectedInterview.absence && (
          <div>
            <h3 className="question-form titre-modal mt-3 p-2">
              {selectedInterview.type_notification.includes('liaison')
                ? 'Entretien de liaison confirmé'
                : "Entretien de retour à l'emploi confirmé"}
            </h3>
            <p className="card-text text-center mb-2 contacts-libelle">
              {getFullName(selectedInterview.employee_information)} a confirmé son{' '}
              {selectedInterview.type_notification.includes('liaison') ? 'entretien de liaison' : 'entretien de retour'}
              , informez le Service de Santé au travail
              <br />
              <p>
                Pour pouvoir informer le SST ou le référent handicap, il faut que le contact en question soit coché
                "pour informations"
              </p>
            </p>
          </div>
        )}
        <div className="col-8 mb-3">
          <label className="card-text text-end col-8 contacts-libelle">
            <select
              className="form-select"
              name="addContact"
              value={isSST}
              onChange={(e) => {
                setIsSST(e.target.value);
                if (e.target.value === '1') {
                  setIsReferentHandicap('2');
                }
              }}
              required
            >
              <option value="0" disabled>
                Informer le Service de Santé ?
              </option>
              <option value="1">Oui</option>
              <option value="2">Non</option>
            </select>
          </label>
          <label className="card-text text-end col-8 contacts-libelle">
            <select
              className="form-select"
              name="addContact"
              value={isReferentHandicap}
              onChange={(e) => {
                setIsReferentHandicap(e.target.value);
                if (e.target.value === '1') {
                  setIsSST('2');
                }
              }}
              required
            >
              <option value="0" disabled>
                Informer le Référent Handicap ?
              </option>
              <option value="1">Oui</option>
              <option value="2">Non</option>
            </select>
          </label>
        </div>
        <div className="card col-12">
          <div className="text-center contact-header-style p-2">CONTACTS DE L’ÉQUIPE</div>
          <div className="card-body card-style">
            <div className="row col-12">
              <p className="card-text text-end col-2 contacts-libelle"></p>
              <p className="card-text col-5 text-start mail-active"></p>
              <div className="col-2 text-center" style={{ cursor: 'pointer' }}>
                <span className="check-contact"> POUR ACTION</span>
              </div>
              <div className="col-2 text-center" style={{ cursor: 'pointer' }}>
                <span className="check-contact">INFORMATION</span>
              </div>
              <div className=" form-check form-switch col-1 text-end"></div>
            </div>
            {selectedInterview.employee_information.employee_contacts.length > 0 &&
              selectedInterview.employee_information.employee_contacts.map((value, index) => {
                return (
                  <div className="row col-12" key={index}>
                    <p className="card-text text-end col-2 contacts-libelle">{value.type}</p>
                    <p className="card-text col-5 text-start mail-active">{value.email}</p>
                    <div className=" form-check form-switch col-1 text-end">
                      <FontAwesomeIcon
                        icon="fa-solid fa-trash"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          onLoadingChange(true);
                          axios
                            .delete(`/api/rh/employee_contact/${value.id}`, {
                              params: {
                                idBusiness: selectedInterview.employee_information.id_business,
                              },
                            })
                            .then((value) => {
                              if (value.status === 200) {
                                onUpdate();
                                onLoadingChange(false);
                              }
                            });
                        }}
                      />
                    </div>
                  </div>
                );
              })}

            {addContact ? (
              <div className="text-start mb-3 hide-add-contact"></div>
            ) : (
              <div className="text-start mb-3">
                <button type="button" className="btn ajouter-contact" onClick={() => setAddContact(!addContact)}>
                  <FontAwesomeIcon icon="fa-solid fa-plus" /> &nbsp; Ajouter un contact
                </button>
              </div>
            )}

            {addContact && (
              <div className="row col-12 test-center">
                <hr className="col-12"></hr>
                <div className="col-12 row">
                  <div className="col-4 text-start my-2">POSTE</div>
                  <div className="col-8 text-start my-2">ADRESSE E-MAIL</div>
                </div>
                <label className="card-text text-end col-4 contacts-libelle">
                  <select
                    className="form-select"
                    name="addContact"
                    value={contact}
                    onChange={(e) => {
                      setContact(e.target.value);
                      const index = e.nativeEvent.target.selectedIndex;
                      setContactLabel(e.nativeEvent.target[index].text);
                    }}
                    required
                  >
                    <option value="0" disabled>
                      Choisissez
                    </option>
                    {selectedInterview.employee_information.employee_contacts.filter((val) => val.type === 'Manager')
                      .length === 0 && <option value="1">Manager</option>}
                    <option value="2">Gestionnaire RH</option>
                    <option value="3">Gestionnaire Paie</option>
                    <option value="4">Infirmier(e)</option>
                    {selectedInterview.employee_information.employee_contacts.filter((val) => val.type === 'SST')
                      .length === 0 && <option value="5">SST</option>}
                    <option value="6">DRH</option>
                    <option value="7">Médecin du travail</option>
                    <option value="8">Référence handicap</option>
                    <option value="9">Autre</option>
                  </select>
                </label>
                {/* <p className="card-text text-end col-2 contacts-libelle">Salarié</p> */}
                <input
                  type="text"
                  className="card-text col-8 text-start add-mail"
                  name="contactmail"
                  value={contactMail}
                  onChange={(e) => {
                    setContactMail(e.target.value);
                  }}
                  placeholder="Adresse mail"
                ></input>

                <div className="text-center mt-3">
                  <button
                    type="button"
                    className="btn ajouter-contact-2"
                    onClick={() => {
                      onLoadingChange(true);
                      if (
                        contactMail !== '' &&
                        contact !== '0' &&
                        contactMail
                          .toLowerCase()
                          .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          )
                      ) {
                        axios
                          .post('/api/rh/add_employee_contact', {
                            idEmployeeInformation: selectedInterview.employee_information.id,
                            type: contactLabel,
                            email: contactMail,
                            idBusiness: selectedInterview.employee_information.id_business,
                            isActif:
                              selectedInterview.employee_information.employee_contacts.length === 0 ? true : false,
                          })
                          .then((value) => {
                            if (value.status === StatusCodes.OK) {
                              onUpdate();
                              setError('');
                              setAddContact(!addContact);
                              setAddBtnContact(!addBtnContact);
                              setContactMail('');
                              setContact('0');
                              onLoadingChange(false);
                            }
                          })
                          .catch((error) => {
                            onLoadingChange(false);
                            triggerError(error);
                          });
                      } else {
                        onLoadingChange(false);
                        contactMail.length === 0
                          ? setError('Veuillez entrer une adresse mail')
                          : contactLabel.length === 0
                          ? setError('Type de contact non défini')
                          : setError('Adresse mail non valide');
                      }
                    }}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-plus" /> &nbsp; Valider le contact
                  </button>
                  <div className="text-end p-0 m-0">
                    <button className="btn btn-annuler" onClick={() => setAddContact(!addContact)}>
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="text-center mt-3">
        <p style={{ color: '#F70202', marginTop: '10px' }}>{error ? error : ''}</p>
        {selectedInterview.employee_information.employee_contacts.length === 0 ? (
          ''
        ) : (
          <button
            className="btn btn-kerij-bleu btn-modal col-4"
            onClick={() => {
              onLoadingChange(true);
              if (
                (selectedInterview.employee_information.employee_contacts.filter(
                  (value) => value.is_info && value.type === 'SST',
                ).length === 0 &&
                  isSST === '1') ||
                (selectedInterview.employee_information.employee_contacts.filter(
                  (value) => value.is_info && value.type === 'Référence handicap',
                ).length === 0 &&
                  isReferentHandicap === '1') ||
                isSST === '0' ||
                isReferentHandicap === '0'
              ) {
                setError(
                  'Vous ne pouvez pas envoyer de notification, le contact "SST" ou "Référent handicap" doit être coché sur "POUR INFOS"',
                );
                return onLoadingChange(false);
              }
              axios
                .post('/api/rh/notify_sst', {
                  idRhNotification: selectedInterview.id,
                  idEmployeeInformation: selectedInterview.employee_information.id,
                  idBusiness: selectedInterview.employee_information.id_business,
                  listEmailContact: selectedInterview.employee_information.employee_contacts
                    .filter((val) => val.is_info)
                    .map((val) => val.email)
                    .join(','),
                  urlVideo: selectedInterview.url_video,
                  type: selectedInterview.type_notification,
                  notify_sst: true,
                  type_contact: selectedInterview.person_demande_interview,
                  who:
                    isSST === '1' && isReferentHandicap === '2'
                      ? 1
                      : isReferentHandicap === '1' && isSST === '2'
                      ? 2
                      : isSST === '1' && isReferentHandicap === '1'
                      ? 3
                      : isSST === '2' && isReferentHandicap === '2'
                      ? 4
                      : 0,
                })
                .then((value) => {
                  if (value.status === StatusCodes.OK) {
                    onUpdate();
                    onLoadingChange(false);
                    onRequestClose();
                  }
                })
                .catch((error) => triggerError(error));
            }}
          >
            Envoyer
          </button>
        )}
      </div>
    </div>
  );
};

export default AskSSTInterview;
