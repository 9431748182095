import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './MeetingRooms.css';
import axios from '../../lib/axios';
import Axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { verifyToken } from '../../services/auth';
import { useToast } from '../Toast';

function MeetingRooms() {
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token_decode');
  const [access, setAccess] = useState(false);
  const [src, setSrc] = useState();
  useEffect(() => {
    try {
      verifyToken({}, token)
        .then((val) => {
          if (val.status === StatusCodes.OK) {
            if (val.data.decode.source) {
              setSrc(val.data.decode.source);
              setAccess(true);
            }
          }
        })
        .catch(() => {
          toast.present({ message: 'Connexion interrompue', severity: 'error' });
        });
    } catch {
      setAccess(false);
    }
  }, []);
  return (
    <div>
      {access ? (
        <iframe title="Kerij App" src={src} allow="camera; microphone; fullscreen; speaker; display-capture"></iframe>
      ) : (
        <div className="container-fluid mt-5">
          <h1 className="question-form mb-5">Attention ! Vous n'avez pas l'autorisation d'accéder à cette page</h1>
        </div>
      )}
    </div>
  );
}

export default MeetingRooms;
