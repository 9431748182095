import { createContext, ReactElement, useContext } from 'react';
import { ToastProps } from './ToastProvider';

export interface ToastContextProps {
  present: (options: { severity: ToastProps['severity']; message: string | ReactElement }) => void;
}

export const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
