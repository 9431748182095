import moment from 'moment';
import { AbsenceCreation, StepProperties } from '../../props/step.interface';
import { KerijBox, KerijBoxType } from '../../../../../components/base/boxes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { statementStep } from '../../constants/step.constants';
import TitleProcess from '../TitleProcess';
import { DateStartInput } from './common';
import { isInvalideDate, isToLate } from '@/components/AbsenceDatesRange/data';
import { AppTextFieldHelper } from '@/components/AppTextFieldHelper';

export const HospiDate = ({ absence, setAbsence, handleChangeStep, infosEmployee }: StepProperties) => {
  const [errorText, setErrorText] = useState<string | null>(null);
  const [isShownDureeHospi, setIsShownDureeHospi] = useState<boolean>(false);
  const [nbJoursHospi, setNbJoursHospi] = useState<number>(0);

  useEffect(() => {
    handleOnChangeHospiDate();

    const dateStart = absence.dateDebut;
    if (isInvalideDate(dateStart)) setErrorText('Le format de la date de début est incorrect');
    else if (isToLate(dateStart)) setErrorText('Vous ne pouvez pas déclarer une absence à plus de 2 ans');
    else if (absence.dateFin && moment(absence?.dateDebut).isAfter(moment(absence.dateFin))) {
      setErrorText('La date de fin doit être supérieure ou égale à la date de début');
    } else {
      setErrorText(null);
    }
  }, [absence?.dateDebut, nbJoursHospi]);

  const handleOnChangeLastDayWork = (value: string | undefined) => {
    setAbsence((absence: AbsenceCreation) => ({
      ...absence,
      lastDayWork: value,
      // withNoDoc: false,
    }));
  };

  const handleDateStartChange = (value: string | undefined) => {
    setAbsence((absence: AbsenceCreation) => ({
      ...absence,
      dateDebut: value,
    }));

    if (isInvalideDate(absence.dateDebut)) setErrorText('Le format de la date de début est incorrect');
    else if (absence.dateFin && moment(value).isAfter(moment(absence.dateFin))) {
      setErrorText('La date de fin doit être supérieure ou égale à la date de début');
    } else {
      setErrorText(null);
    }
  };

  const handleOnChangeHospiDate = () => {
    /**
     * Rule: when user choose n days for hospi, we always count the first date, so n-1 days from now
     * exemple: 13 june + 7 days = 19 june
     */
    if (nbJoursHospi > 0) {
      setAbsence((absence: AbsenceCreation) => ({
        ...absence,
        dateFin: moment(absence.dateDebut)
          .add(nbJoursHospi - 1, 'days')
          .format('YYYY-MM-DD'),
      }));
    } else {
      setAbsence((absence: AbsenceCreation) => ({
        ...absence,
        dateFin: undefined,
      }));
    }
  };

  useEffect(() => {
    if (errorText == null && absence.dateDebut && !isInvalideDate(absence.dateDebut)) {
      handleChangeStep(statementStep.Document);
    } else {
      handleChangeStep(statementStep.Date);
    }
  }, [errorText, absence]);

  return (
    <div className="container-fluid text-center">
      <TitleProcess title={`Quelle est la durée de son arrêt de travail ?`} />
      <div className="my-3 col-lg-8">
        <div className="col-8 offset-2">
          <AppTextFieldHelper label="1er jour de l'arrêt de travail">
            <input
              required
              name="lastDayWork"
              value={absence.lastDayWork}
              type="date"
              placeholder="Date de Naissance"
              onChange={(event) => handleOnChangeLastDayWork(event.target.value)}
              onBlur={(event) => handleOnChangeLastDayWork(event.target.value)}
            />
          </AppTextFieldHelper>
          {absence.lastDayWork && infosEmployee && (
            <DateStartInput
              className="mb-3"
              lastDayWork={absence.lastDayWork}
              employeeTarget="other-employee"
              employeeTimePackage={infosEmployee.time_package}
              onChange={(dateDebut) => handleDateStartChange(dateDebut)}
            />
          )}
        </div>
      </div>
      {absence.dateDebut ? (
        <div className="col-12 mb-3 mt-3">
          <div className="col-12 mb-3" style={{ fontWeight: '800' }}>
            {"La durée d'hospitalisation est-elle définie ?"}
          </div>
          <div className="d-flex col-3 mx-auto">
            <div
              style={{
                display: 'flex',
                flexBasis: 'row',
                alignItems: 'center',
                marginRight: 30,
              }}
              onClick={() => {
                setIsShownDureeHospi(true);
              }}
            >
              <FontAwesomeIcon
                icon={isShownDureeHospi ? faCircleCheck : faCircle}
                style={{ color: '#40C9C9', marginRight: 5 }}
              ></FontAwesomeIcon>
              <div style={{ fontWeight: '800' }}>Oui</div>
            </div>
            <div
              style={{
                display: 'flex',
                flexBasis: 'row',
                alignItems: 'center',
              }}
              onClick={() => {
                setIsShownDureeHospi(false);
                setAbsence((absence: AbsenceCreation) => ({
                  ...absence,
                  dateFin: '',
                }));
                setNbJoursHospi(0);
              }}
            >
              <FontAwesomeIcon
                icon={isShownDureeHospi ? faCircle : faCircleCheck}
                style={{ color: '#40C9C9', marginRight: 5 }}
              ></FontAwesomeIcon>
              <div style={{ fontWeight: '800' }}>Non</div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isShownDureeHospi ? (
        <div>
          <label className="card-text text-center col-12 contacts-libelle mb-3">
            Combien de jours êtes-vous hospitalisé ?
            <input
              type="number"
              className="card-text col-12 text-start"
              name="nbJoursHospi"
              value={nbJoursHospi}
              onChange={(event) => setNbJoursHospi(Number.parseInt(event.target.value))}
              placeholder=""
            ></input>
          </label>
          {absence.dateFin && (
            <div className="text-center mb-3">
              <b>Votre date de sortie est prévue le {moment(absence.dateFin).format('DD/MM/YYYY')}</b>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      {errorText}
      {errorText && <KerijBox message={errorText} type={KerijBoxType.Error} />}
    </div>
  );
};
