import { employeeReducer } from './reducer/employee';
import absencesReducer from './reducer/absence';
import collaborateursReducer from './reducer/collaborateurs';
import { createDocumentReducer } from './reducer/document';
import { configureStore } from '@reduxjs/toolkit';
import {
  AccountContext,
  RHNotification,
  RHNavbarContext,
  RHAccountContext,
  Feature,
  BotContext,
  Notification,
} from './slice/index';

// 🔵 Information :
// DEPRECATED tous les reducers en mode "à l'ancienne" doivent être refactorisé en redux toolkit
//
// @see https://redux-toolkit.js.org/usage/usage-with-typescript

export const store = configureStore({
  reducer: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: refactor avec RTK slice
    employee: employeeReducer,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: refactor avec RTK slice
    [AccountContext.slice.name]: AccountContext.slice.reducer,
    [Feature.slice.name]: Feature.slice.reducer,
    [Notification.slice.name]: Notification.slice.reducer,
    [RHAccountContext.slice.name]: RHAccountContext.slice.reducer,
    [RHNavbarContext.slice.name]: RHNavbarContext.slice.reducer,
    [RHNotification.slice.name]: RHNotification.slice.reducer,
    [BotContext.slice.name]: BotContext.slice.reducer,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: refactor avec RTK slice
    absences: absencesReducer,
    collaborateurs: collaborateursReducer,
    documentCreate: createDocumentReducer,
  },
});

/**
 * RootState type
 */
export type AppState = ReturnType<typeof store.getState>;

export default store;
