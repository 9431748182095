import React from 'react';
import { Toolbar, Typography, type ToolbarProps } from '@mui/material';
import ImgRetour from '@/assets/icon-retour.png';
import { useNavigate } from 'react-router-dom';
import classNames from 'clsx';

const componentName = 'PageTitle';

export interface PageTitleProps extends ToolbarProps {
  label: React.ReactNode;
  withNavigation?: boolean;
  actions?: React.ReactNode;
}

export const PageTitle = ({ className, label, withNavigation = false, actions, ...otherProps }: PageTitleProps) => {
  const navigate = useNavigate();

  return (
    <Toolbar className={classNames(componentName, 'mb-2 mt-3 col-12', className)} {...otherProps}>
      {withNavigation && (
        <img
          alt="retour"
          src={ImgRetour}
          className={'float-start me-2 align-middle'}
          onClick={() => {
            navigate(-1);
          }}
        />
      )}
      <Typography className="titre-rh " variant={'h4'} sx={{ flexGrow: 1 }}>
        {label}
      </Typography>
      {actions}
    </Toolbar>
  );
};
