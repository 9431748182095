import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';

export const DocumentUploaderHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={2}>Liste des justificatifs</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};
