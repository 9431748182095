import 'dynamsoft-document-viewer/dist/ddv.css';
import './InputFile.css';
import React from 'react';
import { InputFileButton } from './InputFileButton';
import { InputFileHidden } from './InputFileHidden';
import { InputFileList } from './InputFileList';
import { InputFileScanbot } from './InputFileScanbot';
import { Box, BoxProps } from '@mui/material';
import classNames from 'clsx';

const componentName = 'InputFile';

export interface InputFileProps extends Omit<BoxProps, 'onChange'> {
  value: Array<File>;
  onChange?: (event: undefined, files: Array<File>) => void;
  detectDocument?: boolean;
  disabled?: boolean;
  label?: string;
  menuTitle?: string;
  menuSubtitle?: string;
  hideList?: boolean;
}

export const InputFile = (props: InputFileProps) => {
  const {
    detectDocument,
    value,
    onChange,
    className,
    label,
    menuTitle,
    disabled,
    menuSubtitle,
    hideList,
    ...otherProps
  } = props;

  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const [openScan, setOpenScan] = React.useState<boolean>(false);

  const isMobile = () => /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent);
  const enableDetection = detectDocument && isMobile();

  return (
    <Box className={classNames(componentName, className)} {...otherProps}>
      <InputFileButton
        disabled={disabled}
        detectDocument={enableDetection}
        onOpenScan={() => setOpenScan(true)}
        onOpenFiles={() => inputFileRef.current?.click()}
        label={label}
        menuTitle={menuTitle}
        menuSubtitle={menuSubtitle}
      />
      {enableDetection && (
        <InputFileScanbot
          isOpen={openScan}
          onClose={() => setOpenScan(false)}
          onChange={(_, files) => onChange?.(undefined, files)}
        />
      )}
      <InputFileHidden
        ref={inputFileRef}
        className={`${componentName}__InputFile`}
        onChange={(_, files) => onChange?.(undefined, files)}
        accept="image/jpeg, image/png, application/pdf"
        capture={enableDetection ? false : undefined}
        name="document"
        id="file"
      />
      {hideList !== true && <InputFileList value={value} onChange={(_, files) => onChange?.(undefined, files)} />}
    </Box>
  );
};
