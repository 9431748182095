import { useLocation, useNavigate } from 'react-router-dom';
import { DefaultLayout } from '../../../components/layouts';
import { DocumentBox } from './DocumentBox';
import { DocumentBoxType } from '../../../features/declaration/types/DocumentBoxType';
import React, { useContext, useEffect } from 'react';
import { NavigationContext } from '../../../components/Context/NavigationContext';
import { StepProgressBar } from '../../../features/declaration/components/bars/StepProgressBar';
import { NavigationSteps } from '../../../components/Context/NavigationProvider';
import { RouterConfig } from '../../../config/RouterConfig';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';
import { DocumentStatus, OCRAPIResponse } from '@/types';
import { useToast } from '@/components/Toast';

const renderDocumentBox = (file: File | undefined, valueDocument: OCRAPIResponse, status: string) => {
  const type = getStatus(valueDocument, status);
  return (
    <DocumentBox
      file={file}
      type={
        type == DocumentStatus.APPROVED
          ? DocumentBoxType.Success
          : type == DocumentStatus.PENDING
          ? DocumentBoxType.Warning
          : DocumentBoxType.Failed
      }
      sx={{ zIndex: -1, marginTop: 1 }}
    />
  );
};

const getStatus = (valueDocument: OCRAPIResponse, status: string) => {
  if (status === 'ok') return valueDocument?.documentStatus;
  return DocumentStatus.REJECTED;
};

export const DeclarationInformationPage = () => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const document = location.state.document || {};
  const absenceType = document?.absenceType?.value;
  const valueDocument: OCRAPIResponse = document?.ocr_result?.data ?? null;
  const status = location.state.status || '';
  const { setCurrentStep } = useContext(NavigationContext);

  const handleClickNext = () => {
    if (getStatus(valueDocument, status) === DocumentStatus.REJECTED) {
      navigate(RouterConfig.declarationDocumentPage().path, {
        state: location.state,
      });
    } else {
      navigate('/declaration-questions-contact', {
        state: {
          ...location.state,
          document: { ...document },
          documentStatus: getStatus(valueDocument, status),
        },
      });
    }
  };

  const handleClickPrevious = () => {
    const { status, valueDocument, ...stateWithoutOCR } = location.state;
    navigate(RouterConfig.declarationDocumentPage().path, {
      state: stateWithoutOCR,
    });
  };

  useEffect(() => {
    if (location.state.status == null && typeof location.state.valueDocument === 'string')
      toast.present({ message: location.state.valueDocument, severity: 'error' });
    setCurrentStep(NavigationSteps.DECLARATION_INFORMATION);
  }, []);

  return (
    <DefaultLayout>
      <StepProgressBar type={absenceType} />
      <div className="container-fluid d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        {renderDocumentBox(document.file, valueDocument, status)}

        <form onSubmit={handleClickNext} style={{ padding: 0 }}>
          <Spacer size="medium" />
          <Button color="primary" type="submit">
            Suivant
          </Button>
          <Spacer />
          <Button color="secondary" onClick={handleClickPrevious}>
            Précédent
          </Button>
        </form>
      </div>
    </DefaultLayout>
  );
};
