// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DefaultLayout__Header {
  grid-area: header;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}
.DefaultLayout__Content {
  overflow: scroll;
}
.DefaultLayout__Container {
  grid-area: content;
}
.DefaultLayout__Footer {
  grid-area: footer;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/DefaultLayout.scss"],"names":[],"mappings":"AASE;EACE,iBAAA;EACA,wBAAA;EAAA,gBAAA;EACA,MAAA;EACA,WAAA;AARJ;AAWE;EACE,gBAAA;AATJ;AAYE;EACE,kBAAA;AAVJ;AAaE;EACE,iBAAA;EACA,wBAAA;EAAA,gBAAA;EACA,SAAA;AAXJ","sourcesContent":[".DefaultLayout {\n  //display: grid;\n  //grid-template-areas:\n  //    'header'\n  //    'content'\n  //    'footer';\n  //grid-template-rows: auto 1fr auto;\n  //min-height: 100vh;\n\n  &__Header {\n    grid-area: header;\n    position: sticky;\n    top: 0;\n    z-index: 10;\n  }\n\n  &__Content {\n    overflow: scroll;\n  }\n\n  &__Container {\n    grid-area: content;\n  }\n\n  &__Footer {\n    grid-area: footer;\n    position: sticky;\n    bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
