// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalAbsenceItemAction {
  margin: auto !important;
  margin-left: 0.5rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/DashboardRH/EnCours/components/Notifications/ActionCardModal/CloseAbsenceModal/CloseAbsenceModal.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,8BAA8B;AAChC","sourcesContent":[".ModalAbsenceItemAction {\n  margin: auto !important;\n  margin-left: 0.5rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
