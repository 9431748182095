import React from 'react';
import Modal from 'react-modal';
import { KerijBox, KerijBoxType } from '../../../components/base';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLabelDocument } from './DeclarationDocumentData';
import imgDocumentAt from '../../../assets/exempledoc-arret-travail.png';
import imgDocumentCert from '../../../assets/exempledoc-certificat.png';
import imgDocumentHospi from '../../../assets/exempledoc-hospitalisation.png';
import { AttachmentDisclaimerMessage } from '@/components/AttachmentDisclaimerMessage';

export interface DeclarationDocumentModalProps {
  document: any;
  isOpen: boolean;
  onCloseInfos: () => void;
}

export const DeclarationDocumentModal = ({ isOpen, document, onCloseInfos }: DeclarationDocumentModalProps) => {
  const getExampleDocument = (document: any): string => {
    const absenceType = document.absenceType?.value;
    switch (absenceType) {
      case '1': {
        return imgDocumentAt;
      }
      case '2': {
        return imgDocumentHospi;
      }
      default: {
        return imgDocumentCert;
      }
    }
  };

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={isOpen}
      onRequestClose={onCloseInfos}
      className="DeclarationDocumentModal"
      ariaHideApp={false}
      contentLabel="Informations documents"
    >
      <div className="DeclarationDocumentModal__header">
        <span>Info pratique</span>
        <button onClick={onCloseInfos} className="btn btn-lg">
          <FontAwesomeIcon className="fa-2x" icon={faXmark} />
        </button>
      </div>
      <div className="text-center">
        <h3 className="DeclarationDocumentModal__information">
          {`Veuillez faire attention à prendre en photo
                        l'intégralité du document comme présenté ci-dessus.`}
        </h3>
        <br />
        <div className="DeclarationDocumentModal__content">
          <div>
            <em>{AttachmentDisclaimerMessage}</em>
          </div>
          <KerijBox
            message={
              <span className="DeclarationDocumentModal__content__text">
                Exemple pour un <strong>{getLabelDocument(document)}</strong>
              </span>
            }
            type={KerijBoxType.Warning}
          />
        </div>
        <img alt="kerij" className="img-docinfo col-8 col-md-3 mx-1" src={getExampleDocument(document)} />
      </div>
    </Modal>
  );
};
