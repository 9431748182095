import { absenceType } from '../../../features/dashboard-rh/create-absence-rh/constants/absenceType.constant';

export const getTypeDocument = (type: string) => {
  switch (Number.parseInt(type)) {
    case absenceType.arret_maladie: {
      return 'arret de travail';
    }
    case absenceType.hospitalisation: {
      return 'hospitalisation';
    }
    case absenceType.conge_maternite: {
      return 'certificat de grossesse';
    }
    case absenceType.conge_parentalite: {
      return 'attestation de conjoint';
    }
    default: {
      return '';
    }
  }
};

export const getFileFromInput = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.item(0);

  if (!event.target.files || !file) return null;

  const allowedTypes = event.target.accept.split(', ');
  const isFileSupported = allowedTypes.find((type) => type === file?.type);
  event.target.value = '';

  return isFileSupported ? file : null;
};
