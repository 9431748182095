import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import '@/components/DashboardRH/DashboardRH.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PRO_URL } from '../../constants/urls.constants';
import DeleteActionModal from '../../components/DashboardRH/shared/components/deleteActionModal.component';
import { customDeleteAction } from '../../shared/models/custom_modal.models';
import { BaseModalRH } from '../../shared/ui/modals/baseModalRH';
import NegativeInterviewModal from '../../components/DashboardRH/shared/components/negativeInterviewModal.component';
import FinishedInterviewModal from '../../components/DashboardRH/shared/components/finishedInterviewModal.component';
import AskSSTInterview from '../../components/DashboardRH/shared/components/askSSTInterview.component';
import AskInterviewModal from '../../components/DashboardRH/shared/components/askInterviewModal.component';
import LiaisonInterviewModal from '../../components/DashboardRH/shared/components/liaisonInterviewModal.component';
import NotificationModal from '../../components/DashboardRH/shared/components/notificationModal.component';
import ReturnInterviewModal from '../../components/DashboardRH/shared/components/returnInterviewModal.component';
import { LoadingModal } from '../../shared/ui/modals/loadingModal';
import { useAppSelector } from '@/store';

const ListInterview = ({ typeInterview, listInterviews, setListInterviews, onUpdate, onChangeLoading }: any) => {
  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext?.selectedIdBusiness);

  const [notif, setNotif] = useState<any>();
  const [select, setSelect] = useState('');
  const [modalNotification, setModalNotification] = useState(false);
  const [modalDeleteAction, setModalDeleteAction] = useState(false);
  const [modalNegativeInterview, setModalNegativeInterview] = useState(false);
  const [modalFinishedInterview, setModalFinishedInterview] = useState(false);
  const [modalAskInterview, setModalAskInterview] = useState(false);
  const [modalAskSSTEntretien, setModalAskSSTEntretien] = useState(false);
  const [modalLiaisonInterview, setModalLiaisonInterview] = useState(false);
  const [modalReturnInterview, setModalReturnInterview] = useState(false);
  const [raisonNotif, setRaisonNotif] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSST, setIsSST] = useState('0');
  const [isReferentHandicap, setIsReferentHandicap] = useState('0');
  const [contactLabel, setContactLabel] = useState('');
  const [contact, setContact] = useState('0');
  const [raisonNotifLabel, setRaisonNotifLabel] = useState('');

  const selectedInterview = useMemo(
    () => listInterviews.find((value: any) => value.id === select),
    [select, listInterviews],
  );

  const shouldShowModal = (condition: any) => listInterviews.length > 0 && select && condition;

  const closeNegativeInterview = () => {
    setModalNegativeInterview(false);
    setSelect('');
  };
  const closeNotificationModal = () => {
    setModalNotification(false);
    setSelect('');
    setNotif(null);
    setRaisonNotif(null);
    setRaisonNotifLabel('');
  };

  const closeModalOpenReturnInterview = () => {
    setModalReturnInterview(false);
    setSelect('');
    setContact('0');
    setContactLabel('');
  };

  return (
    <div className="col-10 container-fluid mt-5">
      <div className="col-10 row ">
        <h6 className="titre-rh mb-5 col-8 text-left ">
          {typeInterview === 'Return' ? "Entretiens de retour à l'emploi" : 'Entretiens de liaison'}
        </h6>
      </div>
      {listInterviews.length > 0 &&
        listInterviews.map((value: any, index: number) => {
          const nom = value.employee_information.lastname;
          const prenom = value.employee_information.firstname;
          const date_debut = value.absence ? value.absence.date_start : '';
          const date_fin = value.absence ? value.absence.date_end : '';
          const type_arret = value.absence
            ? value.absence.type_absence.description_label === 'Congé paternité'
              ? 'Congé parentalité'
              : value.absence.type_absence.description_label
            : '';
          const societe = value.employee_information.business.name;
          const current_level = Number.parseInt(value.current_level);
          const max_level = value.max_level;
          return (
            <div className="card-actions-rh mb-3 col-8 col-sm-10" key={index}>
              <div className="card-actions-rh-header row text-start p-2">
                <div className="col-6 col-sm-8 row text-start">
                  <div className="col-3">
                    <Link
                      className=""
                      to={PRO_URL.PREFIXE_PRO + PRO_URL.PROFIL_SALARIE}
                      state={{
                        document: {
                          idSalarie: value.employee_information.id,
                          idBusiness: value.employee_information.business.id,
                        },
                      }}
                    >
                      <strong>
                        {prenom.at(0).toUpperCase() + prenom.slice(1).toLowerCase() + ' ' + nom.toUpperCase()}
                      </strong>
                    </Link>
                    <p className="no-p-margin">{societe}</p>
                  </div>
                  {value.id_absence && <div className="col-3 content-mid">{type_arret}</div>}
                  {value.id_absence && (
                    <div className="col-3 content-mid">Du {moment(date_debut).format('DD/MM/YYYY')}</div>
                  )}
                  {value.id_absence && (
                    <div className="col-3 content-mid">Au {date_fin ? moment(date_fin).format('DD/MM/YYYY') : '-'}</div>
                  )}
                </div>
                <div className="col-6 col-sm-4 row text-end">
                  <div
                    className="col-12 col-sm-12 text-end"
                    style={{
                      color:
                        ((value.employee_information.notification === '2' && '#40C9C9') as any) ||
                        ((value.employee_information.notification === '3' && '#40C9C9') as any),
                    }}
                  >
                    {value.isShownGererNotif ? (
                      <div className="text-right">
                        <div
                          className="col-12 btn-sm content-mid"
                          style={{
                            cursor: 'pointer',
                          }}
                          onMouseLeave={() =>
                            setListInterviews((data: any) =>
                              data.map((value_: any, ind: number) => {
                                if (ind === index) {
                                  value_.isShownGererNotif = !value_.isShownGererNotif;
                                }
                                return value_;
                              }),
                            )
                          }
                          onClick={() => {
                            setModalNotification(true);
                            setNotif(value.employee_information.notification);
                            setRaisonNotif(value.employee_information.reason);
                            setSelect(value.id);
                          }}
                        >
                          {(value.employee_information.notification === '1' && 'Gérer les notifications') ||
                            (value.employee_information.notification === '2' && "Suivi d'absence")}{' '}
                          &nbsp;&nbsp;{' '}
                          <FontAwesomeIcon
                            icon={
                              value.employee_information.notification === '1'
                                ? ('fa-solid fa-bell' as any)
                                : ('fa-solid fa-bell-slash' as any)
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="col-12 btn-sm content-mid"
                        id=""
                        style={{
                          color:
                            ((value.employee_information.notification === '2' && '#40C9C9') as any) ||
                            (value.employee_information.notification === '3' && '#40C9C9'),
                        }}
                      >
                        {value.employee_information.notification === '2' && "Suivi d'absence"}
                        &nbsp;&nbsp;{' '}
                        <FontAwesomeIcon
                          icon={
                            value.employee_information.notification === '1'
                              ? ('fa-solid fa-bell' as any)
                              : ('fa-solid fa-bell-slash' as any)
                          }
                          style={{
                            cursor: 'pointer',
                          }}
                          onMouseEnter={() =>
                            setListInterviews((data: any) =>
                              data.map((value_: any, ind: number) => {
                                if (ind === index) {
                                  value_.isShownGererNotif = !value_.isShownGererNotif;
                                }
                                return value_;
                              }),
                            )
                          }
                          onMouseLeave={() =>
                            setListInterviews((data: any) =>
                              data.map((value_: any, ind: number) => {
                                if (ind === index) {
                                  value_.isShownGererNotif = !value_.isShownGererNotif;
                                }
                                return value_;
                              }),
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-actions-rh-body row">
                <div className="col-1 text-center">
                  <FontAwesomeIcon
                    icon={'fa-solid fa-arrow-right' as any}
                    size="lg"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (value.type_notification === 'Planifier entretien de liaison' && typeInterview === 'Liaison') {
                        if (value.action === 'refused') {
                          setModalNegativeInterview(true);
                        } else {
                          switch (current_level) {
                            case 7: {
                              setModalFinishedInterview(true);

                              break;
                            }
                            case 1: {
                              setModalAskInterview(true);

                              break;
                            }
                            case 3: {
                              setModalLiaisonInterview(true);

                              break;
                            }
                            case 6: {
                              setModalAskSSTEntretien(true);

                              break;
                            }
                            // No default
                          }
                        }
                      } else if (value.type_notification === 'Entretien de liaison' && typeInterview === 'Liaison') {
                        if (value.action === 'refused') {
                          setModalNegativeInterview(true);
                        } else {
                          switch (current_level) {
                            case 5: {
                              setModalFinishedInterview(true);

                              break;
                            }
                            case 1: {
                              setModalLiaisonInterview(true);

                              break;
                            }
                            case 4: {
                              setModalAskSSTEntretien(true);

                              break;
                            }
                            // No default
                          }
                        }
                      } else if (
                        value.type_notification === "Entretien de retour a l'emploi" &&
                        typeInterview === 'Return'
                      ) {
                        if (value.action === 'refused') {
                          setModalNegativeInterview(true);
                        } else {
                          if (current_level === 5) {
                            setModalFinishedInterview(true);
                          } else if (current_level === 1) {
                            setModalReturnInterview(true);
                          }
                        }
                      } else if (
                        value.type_notification === 'Planifier entretien de retour' &&
                        typeInterview === 'Return'
                      ) {
                        if (value.action === 'refused') {
                          setModalNegativeInterview(true);
                        } else {
                          switch (current_level) {
                            case 7: {
                              setModalFinishedInterview(true);

                              break;
                            }
                            case 1: {
                              setModalAskInterview(true);

                              break;
                            }
                            case 3: {
                              setModalReturnInterview(true);

                              break;
                            }
                            // No default
                          }
                        }
                      }
                      setSelect(value.id);
                    }}
                  />
                </div>
                <div className="col-1 text-center ">
                  <span
                    className={value.action === 'refused' ? 'pastille-rouge' : 'pastille-verte'}
                  >{`${current_level}/${max_level}`}</span>{' '}
                </div>
                <p className="card-actions-rh-text col-7 text-start">
                  <strong>
                    {
                      value.rh_notification_histories
                        .sort(
                          (a: any, b: any) =>
                            Number(new Date(b.date_notification)) - Number(new Date(a.date_notification)),
                        )
                        .at(0).action
                    }
                  </strong>
                  <span className="card-actions-rh-text-muted">
                    &nbsp; - le{' '}
                    {moment(
                      value.rh_notification_histories
                        .filter((value_: any) => value_.action_level === current_level)
                        .at(0).date_notification,
                    ).format('DD/MM/YYYY')}
                  </span>
                </p>
                {(current_level === 2 && value.type_notification === 'Entretien de liaison') ||
                (current_level === 3 &&
                  value.action !== 'refused' &&
                  value.type_notification === 'Entretien de liaison') ||
                (current_level === 4 &&
                  value.action !== 'refused' &&
                  value.type_notification === 'Entretien de liaison' &&
                  value.rh_notification_histories
                    .filter((value_: any) => value_.action_level === current_level)
                    .at(0)
                    .action.includes('participe')) ||
                (current_level === 2 && value.type_notification === 'Planifier entretien de liaison') ||
                (current_level === 5 &&
                  value.action !== 'refused' &&
                  value.type_notification === 'Planifier entretien de liaison') ||
                (current_level === 4 &&
                  value.action !== 'refused' &&
                  value.type_notification === 'Planifier entretien de liaison') ||
                (current_level === 6 &&
                  value.action !== 'refused' &&
                  value.type_notification === 'Planifier entretien de liaison' &&
                  value.rh_notification_histories
                    .filter((value_: any) => value_.action_level === current_level)
                    .at(0)
                    .action.includes('participe')) ||
                (current_level === 2 && value.type_notification === 'Planifier entretien de retour') ||
                (current_level === 5 &&
                  value.action !== 'refused' &&
                  value.type_notification === 'Planifier entretien de retour') ||
                (current_level === 4 &&
                  value.action !== 'refused' &&
                  value.type_notification === 'Planifier entretien de retour') ||
                (current_level === 6 &&
                  value.action !== 'refused' &&
                  value.type_notification === 'Planifier entretien de retour') ||
                (current_level === 2 && value.type_notification === "Entretien de retour a l'emploi") ||
                (current_level === 4 &&
                  value.action !== 'refused' &&
                  value.type_notification === "Entretien de retour a l'emploi") ||
                (current_level === 3 &&
                  value.action !== 'refused' &&
                  value.type_notification === "Entretien de retour a l'emploi") ||
                (value.action !== 'refused' && value.is_notify_sst === true) ||
                (value.type_notification === "Retour a l'emploi" && current_level === 2) ||
                (value.action !== 'refused' && value.is_notify_sst === true) ||
                (value.type_notification === "Retour a l'emploi" && current_level === 2) ? (
                  <div className="col-3 text-end">
                    <button type="button" className="btn btn-kerij-rh btn-sm disabled">
                      En attente
                    </button>{' '}
                    &nbsp;{' '}
                    <FontAwesomeIcon
                      icon={'fa-solid fa-trash-can' as any}
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setModalDeleteAction(true);
                        setSelect(value.id);
                      }}
                    />
                  </div>
                ) : (
                  <div className="col-2 text-end">
                    <FontAwesomeIcon
                      icon={'fa-solid fa-trash-can' as any}
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setModalDeleteAction(true);
                        setSelect(value.id);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="card-actions-rh-footer row text-center p-2">
                <div
                  className="col-12 click-footer"
                  onClick={() =>
                    setListInterviews((data: any) =>
                      data.map((value_: any, ind: number) => {
                        if (ind === index) {
                          value_.isShown = !value_.isShown;
                        }
                        return value_;
                      }),
                    )
                  }
                >
                  DERNIERES ACTIONS &nbsp;
                  <FontAwesomeIcon icon={'fa-chevron-down' as any}></FontAwesomeIcon>
                </div>
                {value.isShown &&
                  value.rh_notification_histories
                    .sort(
                      (a: any, b: any) => Number(new Date(b.date_notification)) - Number(new Date(a.date_notification)),
                    )
                    .map((value_: any, ind: number) => {
                      return (
                        <div className="row col-12" key={ind}>
                          <p className="card-actions-rh-text col-8 text-start">
                            <FontAwesomeIcon
                              icon={'fa-solid fa-circle-check' as any}
                              style={{
                                color: '#79D9D9',
                              }}
                            />
                            <span
                              style={{
                                color: 'red',
                              }}
                            ></span>
                            &nbsp; <strong>{value_.action}</strong>
                            <span className="card-actions-rh-text-muted">
                              &nbsp; - le {moment(value_.date_notification).format('DD/MM/YYYY')}
                            </span>
                          </p>
                        </div>
                      );
                    })}
              </div>
            </div>
          );
        })}
      <DeleteActionModal
        customStyles={customDeleteAction as any}
        className="Modal"
        overlayClassName="Overlay"
        closeTimeoutMS={200}
        isOpen={modalDeleteAction}
        onRequestClose={() => {
          setModalDeleteAction(false);
        }}
        onUpdate={onUpdate}
        select={select}
        onLoadingChange={onChangeLoading}
        ariaHideApp={false}
        selectedIdBusiness={selectedIdBusiness}
      />
      {shouldShowModal(modalNegativeInterview) && (
        <BaseModalRH
          selectedAction={selectedInterview}
          isOpen={modalNegativeInterview}
          onRequestClose={() => {
            setModalNegativeInterview(false);
          }}
          content={
            <NegativeInterviewModal
              // isOpen={modalNegativeInterview}
              onRequestClose={closeNegativeInterview}
              // className="Modal"
              // overlayClassName="Overlay"
              selectedInterview={selectedInterview}
              content={"a refusé l'entretien de liaison proposé"}
              onUpdate={onUpdate}
              onLoadingChange={onChangeLoading}
            />
          }
          customStyles={undefined}
        />
      )}
      {shouldShowModal(modalFinishedInterview) && (
        <BaseModalRH
          selectedAction={selectedInterview}
          isOpen={modalFinishedInterview}
          onRequestClose={() => {
            setModalFinishedInterview(false);
          }}
          // className="Modal"
          // overlayClassName="Overlay"
          content={
            <FinishedInterviewModal
              isOpen={modalFinishedInterview}
              onRequestClose={() => {
                setModalFinishedInterview(false);
                // setSelect();
              }}
              className="Modal"
              overlayClassName="Overlay"
              selectedInterview={selectedInterview}
              onLoadingChange={onChangeLoading}
              onUpdate={onUpdate}
              customStyles={undefined}
            />
          }
          customStyles={undefined}
        />
      )}
      {shouldShowModal(modalAskSSTEntretien) && (
        <BaseModalRH
          selectedAction={selectedInterview}
          isOpen={modalAskSSTEntretien}
          onRequestClose={() => {
            setModalAskSSTEntretien(!modalAskSSTEntretien);
          }}
          // contactTable={true}
          // className="Modal"
          // overlayClassName="Overlay"
          content={
            <AskSSTInterview
              selectedInterview={selectedInterview}
              onRequestClose={() => {
                setModalAskSSTEntretien(!modalAskSSTEntretien);
                setContact('0');
                setContactLabel('');
                setIsSST('0');
                setIsReferentHandicap('0');
              }}
              onLoadingChange={onChangeLoading}
              onUpdate={onUpdate}
            />
          }
          customStyles={undefined}
        />
      )}
      {shouldShowModal(modalAskInterview) && (
        <BaseModalRH
          selectedAction={selectedInterview}
          isOpen={modalAskInterview}
          onRequestClose={() => {
            setModalAskInterview(false);
          }}
          // className="Modal"
          // overlayClassName="Overlay"
          content={
            <AskInterviewModal
              // isOpen={modalAskInterview}
              onRequestClose={() => {
                setModalAskInterview(false);
              }}
              typeInterview={'Liaison'}
              // className="Modal"
              // overlayClassName="Overlay"
              selectedInterview={selectedInterview}
              onUpdate={onUpdate}
              onLoadingChange={onChangeLoading}
            />
          }
          customStyles={undefined}
        />
      )}
      {shouldShowModal(modalLiaisonInterview) && (
        <BaseModalRH
          selectedAction={selectedInterview}
          isOpen={modalLiaisonInterview}
          onRequestClose={() => {
            setModalLiaisonInterview(false);
          }}
          // contactTable={true}
          // className="Modal"
          // overlayClassName="Overlay"
          content={
            <LiaisonInterviewModal
              selectedInterview={selectedInterview}
              onRequestClose={() => {
                setModalLiaisonInterview(!modalLiaisonInterview);
                setContact('0');
                setContactLabel('');
              }}
              onLoadingChange={onChangeLoading}
              onUpdate={onUpdate}
            />
          }
          customStyles={undefined}
        />
      )}
      {shouldShowModal(modalNotification) && (
        <BaseModalRH
          selectedAction={selectedInterview}
          isOpen={modalNotification}
          onRequestClose={() => {
            setModalNotification(false);
          }}
          content={
            <NotificationModal
              notif={notif}
              onNotifChange={setNotif}
              reasonNotif={raisonNotif}
              onReasonNotifChange={setRaisonNotif}
              onRequestClose={closeNotificationModal}
              // ariaHideApp={false}
              // listSelectBusiness={listSelectBusiness}
              selectedInterview={selectedInterview}
              onLoadingChange={onChangeLoading}
              onUpdate={onUpdate}
            />
          }
          customStyles={undefined}
        />
      )}
      {shouldShowModal(modalReturnInterview) && (
        <BaseModalRH
          selectedAction={selectedInterview}
          isOpen={modalReturnInterview}
          onRequestClose={() => {
            setModalReturnInterview(false);
          }}
          // contactTable={true}
          // className="Modal"
          // overlayClassName="Overlay"
          content={
            <ReturnInterviewModal
              selectedReturnInterviews={selectedInterview}
              onRequestClose={closeModalOpenReturnInterview}
              onLoadingChange={setIsLoading}
              select={select}
              onSelectChange={setSelect}
              onUpdate={onUpdate}
            />
          }
          customStyles={undefined}
        />
      )}

      {isLoading && <LoadingModal isLoading={isLoading} />}
    </div>
  );
};

export default ListInterview;
