import axios from 'axios';
import React, { useState } from 'react';
import { useError } from '@/shared/useError';
import { StatusCodes } from 'http-status-codes';

/**
 * @deprecated prefer to use NotificationManagementModal
 */
const NotificationModal = ({
  onRequestClose,
  selectedInterview,
  onLoadingChange,
  notif,
  onNotifChange,
  reasonNotif,
  onReasonNotifChange,
  onUpdate,
}) => {
  const [reasonNotifLabel, setReasonNotifLabel] = useState('');
  const { triggerError } = useError();

  const updateNotification = () => {
    onLoadingChange(true);
    axios
      .put('/api/rh/update_notification_employee', {
        idEmployeeInformation: selectedInterview.employee_information.id,
        isNotif: notif,
        reason: reasonNotif,
        idBusiness: selectedInterview.employee_information.id_business,
      })
      .then((value) => {
        if (value.status === StatusCodes.OK) {
          onRequestClose();
          onUpdate();
          onLoadingChange(false);
        }
      })
      .catch(() => {
        onLoadingChange(false);
        triggerError("Une erreur s'est produite");
      });
  };
  return (
    <div>
      <hr className="col-12"></hr>
      <h3 className="question-form text-center titre-modal">Gérer les notifications</h3>
      <label className="col-12 my-3">
        <select
          className="form-select"
          value={notif}
          onChange={(e) => {
            onNotifChange(e.target.value);
            if (e.target.value === '1') {
              onReasonNotifChange('0');
            } else if (e.target.value === '2') {
              onReasonNotifChange('1');
            } else if (e.target.value === '3') {
              onReasonNotifChange('1');
            }
          }}
          required
        >
          <option value="1">Activer toutes les notifications</option>
          <option value="2">Activer les notifications de suivi d’absence</option>
          <option value="3">Désactiver toutes les notifications</option>
        </select>
      </label>
      {notif === '2' && (
        <div className="type1-at container-fluid">
          <h4 className="question-form mt-3 mb-3 text-center">
            Pour quelle raison souhaitez-vous notifier le salarié uniquement sur le suivi d'absence ?
          </h4>

          <form className="mb-5 col-lg-10">
            <label className="col-12">
              <select
                className="form-select"
                name="raisonNotif"
                value={reasonNotif}
                onChange={(e) => {
                  onReasonNotifChange(e.target.value);
                  if (e.target.value !== '3') {
                    const index = e.nativeEvent.target.selectedIndex;
                    setReasonNotifLabel(e.nativeEvent.target[index].text);
                  } else {
                    setReasonNotifLabel('');
                  }
                }}
                required
              >
                <option value={1}>Raison de santé</option>
                <option value={2}>A la demande du salarié</option>
                <option value={3}>Autre</option>
              </select>
            </label>
            {reasonNotif === '3' && (
              <input
                type="text"
                className="card-text col-12 text-start"
                name=""
                value={reasonNotifLabel}
                placeholder="Expliquer la raison..."
                onChange={(e) => setReasonNotifLabel(e.target.value)}
              ></input>
            )}
          </form>
        </div>
      )}

      {notif === '3' && (
        <div className="type1-at container-fluid">
          <h4 className="question-form mt-3 mb-3 text-center">
            Pour quelle raison souhaitez-vous ne plus notifier le salarié ?
          </h4>

          <form className="mb-5 col-lg-10">
            <label className="col-12">
              <select
                className="form-select"
                name="raisonNotif"
                value={reasonNotif}
                onChange={(e) => {
                  onReasonNotifChange(e.target.value);
                  if (e.target.value !== '3') {
                    const index = e.nativeEvent.target.selectedIndex;
                    setReasonNotifLabel(e.nativeEvent.target[index].text);
                  } else {
                    setReasonNotifLabel('');
                  }
                }}
                required
              >
                <option value={1}>Raison de santé</option>
                <option value={2}>A la demande du salarié</option>
                <option value={3}>Autre</option>
              </select>
            </label>
            {reasonNotif === '3' && (
              <input
                type="text"
                className="card-text col-12 text-start"
                name=""
                value={reasonNotifLabel}
                placeholder="Expliquer la raison..."
                onChange={(e) => setReasonNotifLabel(e.target.value)}
              ></input>
            )}
          </form>
        </div>
      )}
      <div className="text-center">
        <button className="btn btn-kerij-bleu btn-modal col-6" onClick={updateNotification}>
          Valider
        </button>
      </div>
    </div>
  );
};

export default NotificationModal;
