/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import OTPInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useError } from '@/shared/useError';
import { KerijBox, KerijBoxType } from '../base';
import { verifyEmployee } from '../../services/employee';
import { StatusCodes } from 'http-status-codes';

function VerifySelect() {
  const state = useLocation();
  const dataEmployee: any[] = state.state.salarie;
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const { error, triggerError, clearError } = useError();

  const handleVerify = async (event: any) => {
    event.preventDefault();
    clearError();

    const response = await verifyEmployee({ ...state.state.form, lastname: otp });

    if (response?.status === StatusCodes.NOT_FOUND || response?.status === StatusCodes.CONFLICT)
      return triggerError(
        response?.data.message ??
          'Nous ne parvenons pas à vous identifier, veuillez vous rapprocher de votre service RH.',
      );
    if (response?.data != null) {
      const hasAccount = !!response?.data.employee_login;
      navigate('/verify', {
        state: {
          salarie: response?.data,
          hasAccount,
          document: state.state?.document,
          page: state.state?.page,
        },
      });
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb-5 text-center col-lg-4">Précisions des informations</h3>

        <div className="mb-5 text-center">
          <div>
            Nous avons trouvé plusieurs correspondances dans notre base de données, pouvez vous nous préciser votre
            identité ?
          </div>

          {dataEmployee?.length > 1 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Typography
                style={{
                  fontSize: '20px',
                }}
              >
                Veuillez saisir les 3 premières lettres de votre nom de famille
              </Typography>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={3}
                renderInput={(properties) => (
                  <input
                    {...properties}
                    style={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '4px',
                      margin: '0 10px',
                      border: '1px solid rgba(0,0,0,0.3)',
                      textAlign: 'center',
                    }}
                  />
                )}
              />
              {error && <KerijBox message={error} type={KerijBoxType.Info} />}
              <Button
                disabled={otp.length !== 3}
                sx={{
                  textTransform: 'none',
                  marginTop: '20px',
                }}
                variant="contained"
                onClick={handleVerify}
              >
                Vérifier
              </Button>
            </div>
          )}
          <br />

          {dataEmployee?.length === 1 &&
            dataEmployee.map((salarie) => {
              return <RenderItem key={salarie.id} salarie={salarie} state={state} />;
            })}
        </div>
      </div>
    </div>
  );
}

const RenderItem = ({ salarie, state }: any) => {
  const navigate = useNavigate();
  return salarie.employee_login ? (
    <div
      className="row list-salarie mb-1"
      key={salarie.id}
      onClick={() =>
        navigate('/verify', {
          state: {
            salarie: salarie,
            hasAccount: true,
            document: state.state.document,
            page: state.state.page,
          },
        })
      }
    >
      <div className="col-4">{salarie?.firstname}</div>
      <div className="col-4">{salarie?.lastname}</div>
      <div className="col-4">{salarie?.business?.name}</div>
    </div>
  ) : (
    <div
      className="row list-salarie mb-1"
      key={salarie.id}
      onClick={() =>
        navigate('/verify', {
          state: {
            salarie: salarie,
            hasAccount: false,
            document: state.state.document,
            page: state.state.page,
          },
        })
      }
    >
      <div className="col-4">{salarie?.firstname}</div>
      <div className="col-4">{salarie?.lastname}</div>
      <div className="col-4">{salarie?.business?.name}</div>
    </div>
  );
};

export default VerifySelect;
