import React from 'react';
import { Service, ServiceFinanceLabel } from '@/types';
import { Chip, styled } from '@mui/material';
import { IconButton } from '../IconButton';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { IconButtonProps } from '../IconButton/IconButton';

const ActionButton = styled(IconButton)({
  backgroundColor: 'rgba(15, 7, 91, 0.5)',
  height: '2rem',
  width: '2rem',
  color: 'white',
  position: 'absolute',
});

const FoundingChip = styled(Chip)({
  position: 'absolute',
  right: '0.5rem',
  bottom: '0.5rem',
  height: '1rem',
  fontSize: '0.5rem',
});

export interface ServiceCardActionsProps {
  value: Service;
  action?: IconButtonProps;
}

export function ServiceCardActions(props: ServiceCardActionsProps) {
  const { value, action } = props;

  return (
    <>
      {value.funding && <FoundingChip label={ServiceFinanceLabel[`${value.funding}`]} size="small" />}

      {action && (
        <ActionButton
          size="small"
          className="ActionButton"
          style={{ borderRadius: '0 0 0.25rem 0', top: '0', left: '0' }}
          {...action}
        />
      )}

      <ActionButton
        icon={faHeart}
        size="small"
        className="ActionButton"
        style={{ borderRadius: '0 0 0 0.25rem', top: '0', right: '0' }}
      />
    </>
  );
}
