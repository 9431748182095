import * as React from 'react';
import { Parcours } from '@/types/Parcours';
import {
  Avatar,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { IconButton } from '@/components/IconButton';
import { Edit, Delete, CodeBranch, Gavel } from '@/components/AppIcon';
import { ListCardHeader, ListCardHeaderAvatar, ListCardHeaderButton } from '../DashboardRH/ListCard';

export interface ParcoursListItemHeaderProps {
  value: Parcours;
  onClickAction?: (type: 'edit' | 'delete') => void;
}

export function ParcoursListItemHeader(props: ParcoursListItemHeaderProps) {
  const { value, onClickAction } = props;
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);

  const parcoursType = value.legal ? 'Parcours légal' : 'Parcours de management';
  const title = value.name ?? parcoursType;

  const renderLegal = () => (
    <Tooltip title={parcoursType}>
      <ListCardHeaderAvatar sx={{ m: 0 }}>
        {value.legal ? <Gavel sx={{ width: 10 }} /> : <CodeBranch sx={{ width: 10 }} />}
      </ListCardHeaderAvatar>
    </Tooltip>
  );

  return (
    <ListCardHeader
      actions={
        <>
          <IconButton
            id="ButtonParcoursEdit"
            sx={{ color: 'white' }}
            icon={<Edit />}
            onClick={(e) => {
              e.stopPropagation();
              onClickAction?.('edit');
            }}
          />
          <IconButton
            id="ButtonParcoursTrash"
            sx={{ color: 'white' }}
            icon={<Delete />}
            onClick={(e) => {
              setOpenConfirmDelete(true);
              e.stopPropagation();
            }}
          />
        </>
      }
    >
      <ConfirmationAlert
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        onConfirm={() => onClickAction?.('delete')}
      />

      <ListCardHeaderButton style={{ cursor: 'default' }} disableRipple>
        <Typography variant="subtitle2" fontWeight="bold">
          {title}
        </Typography>
        <ListCardHeaderAvatar sx={{ ml: 1, mr: 0 }}>{renderLegal()}</ListCardHeaderAvatar>
      </ListCardHeaderButton>
    </ListCardHeader>
  );
}

interface ConfirmationAlertProps extends React.ComponentProps<typeof Dialog> {
  onCancel?: () => void;
  onConfirm: () => void;
}

const ConfirmationAlert = (props: ConfirmationAlertProps) => {
  const { onCancel, onConfirm, onClose } = props;

  const close = (
    event: React.SyntheticEvent,
    role: 'cancel' | 'confirm',
    callback: typeof onCancel | typeof onConfirm,
  ) => {
    event.stopPropagation();
    callback?.();
    if (onClose) onClose({ role }, 'escapeKeyDown');
  };
  return (
    <Dialog {...props} onClose={(e) => close(e as React.SyntheticEvent, 'cancel', onCancel)}>
      <DialogTitle>{'Suppression de parcours'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Vous êtes sur le point de supprimer un parcours. Si vous confirmez la suppression, les mails associés à ce
          parcours ne seront plus adressés.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => close(e, 'cancel', onCancel)} autoFocus>
          Annuler
        </Button>
        <Button onClick={(e) => close(e, 'confirm', onConfirm)} color="error">
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
