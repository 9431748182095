import React, { createRef, useEffect, useState } from 'react';
import '../../../../DashboardRH.css';
import { CardAbsenceHistory } from '../CardAbsenceHistory';
import useCheckRhAuthentication from '../../../../../../shared/hooks/chechRhAuthentication.hooks';
import { FilterSortAbsences } from '../filterSortAbsences.component';
import { PageTitle } from '@/components/PageTitle';
import { CircularProgress } from '@mui/material';
import { getAbsenceHistoryRequest, getMoreAbsenceHistoryRequest } from '../../../../../../store/action/absence';
import { useAppDispatch, useAppSelector } from '@/store';
import { PermissionBox } from '@/components/PermissionBox';
import { ListCard } from '@/components/DashboardRH/ListCard';
import { RHPage } from '@/components/RHPage';

const HistoryAbsence = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.absences?.absenceHistory?.retrieved?.historyAbsences) || [];

  const loading = useAppSelector((state) => state.absences?.absenceHistory?.loading);
  const loaded = useAppSelector((state) => state.absences?.absenceHistory?.loaded);
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({ page: 2, per_page: 10 });
  const loadMoreEl = createRef<HTMLDivElement>();

  const [isInit, setIsInit] = useState(false);
  const [isLoadmore, setIsLoadmore] = useState(false);

  useEffect(() => {
    dispatch(
      getAbsenceHistoryRequest({
        search: search,
        offset: (pagination.page - 1) * pagination.per_page,
        limit: pagination.per_page,
      }),
    ).then(() => {
      setIsInit(true);
    });
  }, [search]);

  useEffect(() => {
    if (isLoadmore) {
      setIsLoadmore(false);
      dispatch(
        getMoreAbsenceHistoryRequest(
          {
            search: search,
            offset: (pagination.page - 1) * pagination.per_page,
            limit: pagination.per_page,
          },
          () => {
            setPagination((initialValue) => ({ page: initialValue.page + 1, per_page: initialValue.per_page }));
          },
        ),
      );
    }
  }, [isLoadmore]);

  useEffect(() => {
    return () => setPagination({ page: 1, per_page: 10 });
  }, [search]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsLoadmore(entry.isIntersecting);
      },
      {
        root: null, // Observe vis-à-vis du viewport
        rootMargin: '0px',
        threshold: 0.5, // 50% de l'élément doit être visible pour déclencher l'observation
      },
    );

    if (loadMoreEl.current) observer.observe(loadMoreEl.current);

    return () => {
      if (loadMoreEl.current) observer.unobserve(loadMoreEl.current);
    };
  }, [loadMoreEl]);

  useCheckRhAuthentication();

  return (
    <PermissionBox scope="absence-management" action="access">
      <RHPage title="Historique des absences">
        <div className="row">
          <div className="col-6">
            <FilterSortAbsences setSearch={setSearch} />
          </div>
        </div>

        {!loading && data.length === 0 && (
          <div className="text-center mt-5 mb-3 col-12 col-sm-10">
            <b>Aucune absence</b>
          </div>
        )}

        {data?.map((employeeInformation: any, index: number) => {
          const { absences, ...rest } = employeeInformation;

          return (
            <ListCard key={index}>
              <CardAbsenceHistory absences={absences} employeeInformation={rest} />
            </ListCard>
          );
        })}

        {!loading && !loaded && isInit && <div ref={loadMoreEl}></div>}

        {loading && (
          <div className={'text-center'}>
            <CircularProgress />
          </div>
        )}
      </RHPage>
    </PermissionBox>
  );
};

export default HistoryAbsence;
