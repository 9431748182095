import { faCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../../components/Footer/Footer';
import { Navbar } from '@/components/Navbar/Navbar';
import { NavbarMobile } from '@/components/Navbar/NavbarMobile';
import React from 'react';
import { useAppSelector } from '@/store';

export const DeclarationDocumentValidationPage = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const context: any = useAppSelector((state) => state.accountContext);

  const absenceType = state.state.document.absenceType.value;
  const handleClick = () => {
    if (context.user) {
      navigate('/declarationvalidation', {
        state: { document: { ...state.state.document } },
      });
    } else {
      navigate('/login', {
        state: { document: { ...state.state.document } },
      });
    }
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        {absenceType === '1' ? (
          <div className="progresses mt-3">
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-active">
              <span>5</span>
            </div>
          </div>
        ) : absenceType === '3' ? (
          <div className="progresses mt-3">
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-active">
              <span>6</span>
            </div>
          </div>
        ) : absenceType === '4' && state.state.document.relationEnfant.value === 'conjoint' ? (
          <div className="progresses mt-3">
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-active">
              <span>5</span>
            </div>
          </div>
        ) : (
          <div className="progresses mt-3">
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-valid">
              <span>.</span>
            </div>
            <div className="steps-active">
              <span>4</span>
            </div>
          </div>
        )}

        <h3 className="question-form mt-5 mb-5 text-center">Vérification du document</h3>

        <form className="form-declaration text-center col-12 col-md-8">
          {/* Intégration OK */}
          {state.state.document.files.length > 0 && (
            <div>
              <div className="declaration-check mb-5">
                <FontAwesomeIcon className="fa-5x" icon={faCheck} />
              </div>

              <div className="col-12">
                <p>
                  Merci. <br /> Vos documents sont conformes. <br />
                  <br />
                </p>
              </div>
            </div>
          )}

          {/* Intégration ERREUR */}
          {state.state.document.files.length === 0 && (
            <div className="d-none">
              <div className="declaration-warning mb-5">
                <FontAwesomeIcon className="fa-5x" icon={faTriangleExclamation} />
              </div>
              <div className="col-12">
                <p>
                  Attention. <br /> Vos documents ne sont pas conformes. <br />
                  <br />
                </p>
              </div>
            </div>
          )}

          {/* Intégration ERREUR DOC */}
          {state.state.document.files.length > 0 && state.state.document.absenceType.value === '3' ? (
            <div className="col-12">
              {state.state.document.birthday ? null : (
                <div>
                  <p style={{ color: 'red' }}>
                    Attention. <br />
                    La date de naissance déclarée ne correspond pas à celle du document
                  </p>
                </div>
              )}
              {/* {state.state.document.identity ? null : <p style={{ color: "red" }}>Attention. <br />Votre identifiant (nom et prénom) ne correspondent pas à celui du document</p>} */}
              {state.state.document.birthday ? null : (
                <div>
                  <button
                    className="btn btn-kerij col-12 col-lg-3 wrong-date"
                    onClick={() =>
                      navigate('/declarationdetailsdates', {
                        state: {
                          document: {
                            ...state.state.document,
                            refill: true,
                          },
                        },
                      })
                    }
                  >
                    Je change la date de naissance
                  </button>
                  <button className="btn btn-kerij col-12 col-lg-3 offset-xl-1 wrong-date" onClick={handleClick}>
                    Je maintiens la date déclarée
                  </button>
                </div>
              )}
            </div>
          ) : null}
        </form>

        {state.state.document.files.length > 0 &&
        state.state.document.absenceType.value === '3' &&
        !state.state.document.birthday ? null : (
          <div className="position-relative col-12 col-md-8 text-center mb-3">
            <button type="button" className="btn btn-kerij col-6 col-lg-3" onClick={handleClick}>
              Suivant
            </button>
          </div>
        )}
        <div className="position-relative col-12 col-md-8 text-center mb-5">
          <button
            type="button"
            className="btn btn-kerij-outline btn-sm col-6 col-lg-3"
            onClick={() =>
              navigate('/declarationdocument', {
                state: state.state,
              })
            }
          >
            Précédent
          </button>
        </div>
      </div>
      <NavbarMobile />
      <Footer />
    </div>
  );
};
