import React, { useEffect, useState, useMemo } from 'react';
import '@/components/DashboardRH/DashboardRH.css';
import { useNavigate } from 'react-router-dom';
import useCheckRhAuthentication from '../../shared/hooks/chechRhAuthentication.hooks';
import { NotConnectedRH } from '../../components/DashboardRH/shared/components/NotConnectedRH';
import { LoadingModal } from '../../shared/ui/modals/loadingModal';
import { GetInterviews } from './services/getInterviews.service';
import ListInterview from './ListInterview';
import { RHAccountContext, useAppSelector } from '@/store';

function ListLiaisonInterview() {
  const isAdmin = useAppSelector(RHAccountContext.isAdmin);
  const listSelectBusiness = useAppSelector((state) => state.rhNavbarContext?.listSelectBusiness);
  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext?.selectedIdBusiness);

  const [offset, setOffset] = useState(0);
  const limit = 10;
  const [listLiaisonInterviews, setListLiaisonInterviews] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useCheckRhAuthentication();

  useEffect(() => {
    GetInterviews({
      listSelectBusiness,
      selectedIdBusiness,
      offset: 0,
      limit: offset + limit,
      setListInterviews: setListLiaisonInterviews,
      typeInterview: 'Liaison',
    });
  }, [listSelectBusiness, selectedIdBusiness, offset, limit]);

  const onUpdate = () => {
    GetInterviews({
      listSelectBusiness,
      selectedIdBusiness,
      offset: 0,
      limit: offset + limit,
      setListInterviews: setListLiaisonInterviews,
      typeInterview: 'Liaison',
    });
  };

  const AllLiaisonInterviews = useMemo(() => {
    return (
      <ListInterview
        typeInterview={'Liaison'}
        listInterviews={listLiaisonInterviews}
        setListInterviews={setListLiaisonInterviews}
        onUpdate={onUpdate}
        onChangeLoading={setIsLoading}
      />
    );
  }, [listLiaisonInterviews, listSelectBusiness, selectedIdBusiness, offset, limit, onUpdate, setIsLoading]);

  return (
    <div>
      <div className="row col-12">{isAdmin ? <>{AllLiaisonInterviews}</> : <NotConnectedRH />}</div>
      {isLoading && <LoadingModal isLoading={isLoading} />}
    </div>
  );
}

export default ListLiaisonInterview;
