export enum UserTypes {
  EMPLOYEE = 'employee',
  HR = 'hr',
  NOTIFICATION = 'notification',
}

export enum UserTypesEmail {
  EMPLOYEE = 'employee',
  HR = 'hr',
  OTHER = 'other',
}
