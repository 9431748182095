import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext, Notification, useAppDispatch, useAppFeature, useAppSelector } from '@/store';
import { Badge, BottomNavigation, BottomNavigationAction, Paper, PaperProps, styled } from '@mui/material';
import { Feature } from '@/types';
import { UserAvatar } from './UserAvatar';
import { Comments, MagnifyingGlass, CircleUser, Dashboard, KERij } from '@/components/AppIcon';
import classNames from 'clsx';

const NavbarPaper = styled(Paper)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius * 2,
  borderTopRightRadius: theme.shape.borderRadius * 2,
  paddingTop: theme.shape.borderRadius,
}));

const componentName = 'NavbarMobile';

export interface NavbarMobileProps extends PaperProps {}

export function NavbarMobile(props: NavbarMobileProps) {
  const { className, ...rootProps } = props;
  const user = useAppSelector(AccountContext.getUser);
  const loading = useAppSelector(AccountContext.isLoading);
  const hasUnread = useAppSelector(Notification.hasUnread);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  //const location = window.location.href;
  const location = useLocation();
  const feature = useAppFeature();

  useEffect(() => {
    if (!localStorage.getItem('access_token_employee')) {
      localStorage.removeItem('access_token_employee');
      dispatch(AccountContext.actions.setUser(null));
      if (location.pathname === '/activites') {
        navigate('/activiteslogin');
      } else if (location.pathname === '/services') {
        navigate('/serviceslogin');
      } else if (location.pathname === '/rdv') {
        navigate('/rdvlogin');
      } else if (location.pathname === '/profil') {
        navigate('/login');
      }
    }
  }, [user, loading, location]);

  const entries: Array<{
    label?: string;
    feature?: keyof Feature;
    to?: string;
    icon?: React.ReactNode;
    isActive?: () => boolean;
  }> = [
    user
      ? {
          label: 'Profil',
          to: '/profil',
          isActive: () => location.pathname.startsWith('/profil'),
          icon: <UserAvatar user={user} sx={{ height: '24px', width: '24px', fontSize: '0.75rem' }} />,
        }
      : {
          label: 'Connexion',
          to: '/login',
          isActive: () => location.pathname.startsWith('/login'),
          icon: <CircleUser />,
        },
    {
      label: 'Services',
      feature: 'ServiceManagement',
      to: '/services',
      isActive: () => location.pathname.startsWith('/services'),
      icon: <MagnifyingGlass />,
    },
    {
      label: 'RDV',
      feature: 'InterviewManagement',
      to: '/rdv',
      isActive: () => location.pathname.startsWith('/rdv'),
      icon: <Comments />,
    },
    {
      label: 'Déclarer',
      to: '/declaration',
      isActive: () => location.pathname.startsWith('/declaration'),
      icon: <KERij />,
    },
    {
      label: 'Activités',
      to: '/activites',
      isActive: () => location.pathname.startsWith('/activites') || location.pathname.startsWith('/historiquesalarie'),
      icon: (
        <Badge
          overlap="circular"
          color="warning"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          variant="dot"
          invisible={!hasUnread}
        >
          <Dashboard />
        </Badge>
      ),
    },
  ];
  const [value, setValue] = useState(entries.findIndex((_) => (_.isActive ? _.isActive() : false)));
  const handleChange = useCallback(
    (_: unknown, value: number) => {
      setValue(value);
      const to = entries[value]?.to;
      if (to) {
        navigate(to);
      }
    },
    [setValue],
  );

  return (
    <NavbarPaper
      //@ts-ignore it is ok
      component="nav"
      elevation={3}
      sx={{ bottom: 0, left: 0, right: 0, position: 'fixed' }}
      {...rootProps}
      className={classNames(componentName, className)}
    >
      <BottomNavigation showLabels value={value} onChange={handleChange}>
        {entries.map((entry, index) => {
          const action = <BottomNavigationAction label={entry.label} icon={entry.icon} />;
          return entry.feature == null || feature[entry.feature]
            ? React.cloneElement(action, {
                key: index,
              })
            : null;
        })}
      </BottomNavigation>
    </NavbarPaper>
  );
}
