import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../DashboardRH.css';
import { PieChartsEncours } from '../../Charts/PieChartsEncours';
import { useLocation } from 'react-router-dom';
import LineChartTotal from '../../Charts/LineChartEncours/Total';
import LineChartArret from '../../Charts/LineChartEncours/ArretTravail';
import LineChartHospitalisation from '../../Charts/LineChartEncours/Hospitalisation';
import LineChartParentalite from '../../Charts/LineChartEncours/Parentalite';
import RadarChartTaux from '../../Charts/RadarChartAbsentisme/Taux';
import RadarChartPrevalence from '../../Charts/RadarChartAbsentisme/Prevalence';
import RadarChartOccurrence from '../../Charts/RadarChartAbsentisme/Occurrence';
import BarChartGravite from '../../Charts/RadarChartAbsentisme/Gravite';
import { NotConnectedRH } from '../shared/components/NotConnectedRH';
import isAuthorized from '../../../hooks/isAuthorized';
import { PRO_URL } from '../../../constants/urls.constants';
import { RHAccountContext, useAppSelector } from '@/store';

function Stats() {
  const navigate = useNavigate();
  const [chart, setChart] = useState();
  const location = useLocation();
  const rh = useAppSelector(RHAccountContext.getProfile);

  useEffect(() => {
    setChart(location.hash.slice(1));
  }, []);

  useEffect(() => {
    if (!isAuthorized(location.pathname)) {
      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN);
    }
  }, [localStorage.getItem('access_token_rh')]);

  return (
    <div className="col-10 d-flex">
      {rh ? (
        <>
          <div className="col-10 d-flex bd-highlight">
            {(!chart || chart === 'encoursglobal') && <PieChartsEncours></PieChartsEncours>}
            {chart === 'tendancegenerale' && <LineChartTotal></LineChartTotal>}
            {chart === 'linechartarret' && <LineChartArret></LineChartArret>}
            {chart === 'linecharthospitalisation' && <LineChartHospitalisation></LineChartHospitalisation>}
            {chart === 'linechartparentalite' && <LineChartParentalite></LineChartParentalite>}
            {chart === 'radarcharttaux' && <RadarChartTaux></RadarChartTaux>}
            {chart === 'radarchartprevalence' && <RadarChartPrevalence></RadarChartPrevalence>}
            {chart === 'radarchartoccurrence' && <RadarChartOccurrence></RadarChartOccurrence>}
            {chart === 'barchartgravite' && <BarChartGravite></BarChartGravite>}
          </div>
        </>
      ) : (
        <NotConnectedRH />
      )}
    </div>
  );
}

export default Stats;
