import React, { useState } from 'react';
import { pickerOptions } from './data';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { colors } from '../../../constants';
import { AbsencesDeleteCausePickerLabel } from './AbsencesDeleteCausePickerLabel';
import './AbsencesDeleteCausePicker.css';

export interface AbsencesDeleteCausePickerProps {
  value: string | null;
  initialCustomValue: string | null;
  onChange: (change: { itemValue: string; customValue: string | null; label?: string | null }) => void;
}

export const AbsencesDeleteCausePicker = ({ value, initialCustomValue, onChange }: AbsencesDeleteCausePickerProps) => {
  const [customCauseValue, setCustomCauseValue] = useState<string | null>(initialCustomValue);

  const causeLabel = (causeValue: string) =>
    causeValue === '4' ? customCauseValue : pickerOptions.find((opt) => opt.value === causeValue)?.label;

  function changeValue(changedValue: string) {
    onChange({
      itemValue: changedValue,
      customValue: customCauseValue,
      label: causeLabel(changedValue),
    });
  }

  function changeInputValue(optionValue: string, customValue: string | null) {
    if (optionValue === '4') {
      setCustomCauseValue(customValue);
      onChange({
        itemValue: optionValue,
        customValue,
        label: causeLabel(optionValue),
      });
    }
  }

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={value}
        onChange={({ target }) => changeValue(target.value)}
      >
        {pickerOptions.map((item, index) => {
          return (
            <FormControlLabel
              style={{ color: colors.darkblue }}
              key={index}
              value={item.value}
              control={<Radio style={{ color: colors.darkblue }} />}
              label={
                <AbsencesDeleteCausePickerLabel
                  item={item}
                  value={customCauseValue}
                  disabled={item.value !== value}
                  onChange={(inputValue) => changeInputValue(item.value, inputValue)}
                />
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
