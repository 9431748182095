import logo from '../../../../../Logotype-Simple-Fondclair.svg';
import moment from 'moment/moment';
import React from 'react';
import { RHNotification } from '../../../../../types/rh.interface';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface UpdateDocumentCardInterface {
  rhNotification: RHNotification;
}

const UpdateDocumentCard = ({ rhNotification }: UpdateDocumentCardInterface) => {
  return (
    <div className="fluid-chidren card-actions-rh mb-3">
      <div
        className="card-actions-rh-header-yellow row text-start"
        style={{
          backgroundColor: '#40C9C9',
          borderColor: '#40C9C9',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src={logo}
            className="App-logo"
            alt="logo"
            width="63"
            style={{
              display: 'flex',
              marginRight: '2vw',
            }}
          />
          <span>
            <strong>{rhNotification.employee_information?.business.name}</strong>
          </span>
        </div>
      </div>

      <div
        className="card-actions-rh-body-yellow row"
        style={{
          borderColor: '#40C9C9',
        }}
      >
        <div className="col-1 text-center btn-lg" style={{ color: '#40C9C9' }}>
          <IconButton>
            <CheckCircleIcon />
          </IconButton>
        </div>
        <p className="card-actions-rh-text col-7 text-start">
          <strong>Rappel : mise à jour des informations</strong>
          <span className="card-actions-rh-text-muted">
            &nbsp; - le {moment(rhNotification.date_notification).format('DD/MM/YYYY')}
          </span>
        </p>
        <div className="col-4 text-end">
          <button type="button" className="btn btn-kerij-rh btn-sm">
            A traiter
          </button>
        </div>
      </div>

      <div
        className="card-actions-rh-footer-yellow row text-center p-2"
        style={{
          borderColor: '#40C9C9',
        }}
      ></div>
    </div>
  );
};
export default UpdateDocumentCard;
