import * as React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { menuWidth } from './data';
import { LayoutMenuItem } from './LayoutMenuItem';
import { PRO_URL } from '@/constants/urls.constants';
import { useNavigate } from 'react-router-dom';
import isAuthorized from '@/hooks/isAuthorized';
import { RHAccountContext, useAppDispatch } from '@/store';
import { UserTypes } from '@/types';
import { removeAuthData } from '@/utils/auth.utils';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Button } from '@/components/Button';

export interface LayoutProfilProps {
  rh?: RHAccountContext.RhContext;
}

export function LayoutProfil(props: LayoutProfilProps) {
  const { rh } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logout = async () => {
    if (isAuthorized(window.location.pathname)) {
      removeAuthData(UserTypes.HR);
      dispatch(RHAccountContext.actions.reset());
      dispatch(RHAccountContext.actions.setLoading(false));
    }
    navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN);
  };

  return (
    <div style={{ background: 'white' }}>
      <List
        sx={{
          color: '#0d064e',
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: '#0d064e',
            color: '#fff',
          },
          '& .MuiListItemButton-root:hover': {
            bgcolor: 'secondary',
          },
        }}
        style={{
          position: 'fixed',
          bottom: 0,
          width: `calc(${menuWidth}px - 2px)`,
          marginLeft: 1,
          background: 'white',
          zIndex: 100,
          padding: 0,
          borderRadius: 0,
        }}
      >
        <Divider />

        {(!isAuthorized(window.location.pathname) || rh?.rh_information == null) && (
          <div style={{ padding: '1rem' }}>
            <Button color="tertiary" fullWidth onClick={() => navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN)}>
              Connexion
            </Button>
          </div>
        )}

        {isAuthorized(window.location.pathname) && rh?.rh_information != null && (
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDropUpIcon color="primary" />}>
              <Typography color="primary" sx={{ fontSize: '12px' }}>
                <span className="pastille-initial-user">
                  {`${rh?.rh_information?.firstname?.at(0)}${rh?.rh_information?.lastname?.at(0)}`}
                </span>
                {`${rh?.rh_information?.firstname} ${rh?.rh_information?.lastname}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <List
                style={{ margin: 0, padding: '0.5rem', paddingTop: 0, borderRadius: '0.25rem' }}
                sx={{
                  color: '#0d064e',
                }}
              >
                <LayoutMenuItem text="Profil" url={PRO_URL.PREFIXE_PRO + PRO_URL.PROFIL} />
                <LayoutMenuItem text="Se déconnecter" onClick={logout} />
              </List>
            </AccordionDetails>
          </Accordion>
        )}
      </List>
    </div>
  );
}
