// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-rdv {
  text-align: center;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.card-title-rdv {
  font-weight: bolder;
  margin-bottom: 2em;
}
.card-img {
  width: 100px;
}
.img-rdv {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: auto;
  /* border: solid 1px rgb(180, 180, 180); */
}

@media all and (max-width: 991px) {
  .short-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/employee/RdvLoginPage/components/RdvLoginUI/Rdv.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,2CAA2C;AAC7C;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,0CAA0C;AAC5C;;AAEA;EACE;IACE,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;EACzB;AACF","sourcesContent":[".card-rdv {\n  text-align: center;\n  padding: 1em;\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;\n}\n\n.card-title-rdv {\n  font-weight: bolder;\n  margin-bottom: 2em;\n}\n.card-img {\n  width: 100px;\n}\n.img-rdv {\n  border-radius: 50%;\n  width: 150px;\n  height: 150px;\n  margin: auto;\n  /* border: solid 1px rgb(180, 180, 180); */\n}\n\n@media all and (max-width: 991px) {\n  .short-text {\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
