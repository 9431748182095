import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { AvatarProps, Badge, useTheme, type BadgeProps, type Theme } from '@mui/material';

function stringToColor(theme: Theme, string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.codePointAt(i)! + ((hash << 5) - hash);
  }

  /*for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }*/
  /* eslint-enable no-bitwise */

  return {
    bgcolor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  };
}

function stringAvatar(theme: Theme, name: string) {
  return {
    sx: {
      ...stringToColor(theme, name),
      width: 40,
      height: 40,
      paddingTop: 0.25,
      paddingLeft: 0.15,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

type User = {
  employee_information: {
    firstname: string;
    lastname: string;
  };
};

export interface UserAvatarProps extends AvatarProps {
  BadgeProps?: BadgeProps;
  user: User;
  hasNotification?: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const { user, BadgeProps, hasNotification = false, ...rootProps } = props;
  const fullName = `${user.employee_information.firstname} ${user.employee_information.lastname}`;
  const theme = useTheme();
  const avatarProps = stringAvatar(theme, fullName);
  return (
    <Badge
      overlap="circular"
      color="warning"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      invisible={!hasNotification}
      {...BadgeProps}
    >
      <Avatar {...rootProps} {...avatarProps} sx={{ ...avatarProps.sx, ...rootProps.sx }} />
    </Badge>
  );
};
