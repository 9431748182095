import { DefaultLayout } from '../components/layouts';
import React from 'react';
import HistoriqueSalarie from '../components/DashboardSalarie/Historique';

export const HistoriqueSalariePage: React.FC = () => {
  return (
    <DefaultLayout>
      <HistoriqueSalarie />
    </DefaultLayout>
  );
};
