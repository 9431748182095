import React, { useState } from 'react';

export function QuestionnaireSuiviContact() {
  const [entretien, setEntretien] = useState('3');

  return (
    <>
      <h4 className="subtitle-services text-center" style={{ marginTop: '2rem' }}>
        {' '}
        Désirez vous un entretien avec un Manager ou une personne des Ressources Humaines ?
      </h4>

      <form className="form-declaration form-declaration col-lg-4" style={{ marginBottom: '2rem' }}>
        <label>
          <select
            className="form-select"
            name="entretien"
            value={entretien}
            onChange={(e) => setEntretien(e.target.value)}
            required
          >
            {/* Cas 1 : On valide c'est tout */}
            <option value={1}>Oui avec mon manager</option>
            {/* Cas 2 : On demande à quelle date ? */}
            <option value={2}>Oui avec une personne des RH</option>
            {/* Cas 3 : On redirige vers la déclaration d'une absence */}
            <option value={3}>Non merci</option>
          </select>
        </label>
      </form>
    </>
  );
}
