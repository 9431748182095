import { ServiceTheme } from '@/types';
import { varToStringParams } from '../../shared/utils/paths';
import axios from '@/lib/axios';

export const rhAdminUpdateUser = async (data: any): Promise<any> => {
  return await axios.put(`/api/rh/admin/update_user_admin`, data);
};

export const rhAdminAddUser = async (data: any): Promise<any> => {
  return await axios.post(`/api/rh/admin/add_user_admin`, data);
};

export interface AdminGetServiceParameters {
  idBusiness?: string | null;
  theme?: ServiceTheme;
  page?: number;
  per_page?: number;
}

export const rhAdminGetService = async (params: AdminGetServiceParameters) => {
  const query = varToStringParams({
    variablesArray: [
      { key: 'page', value: params.page },
      { key: 'per_page', value: params.per_page },
      { key: 'idBusiness', value: params.idBusiness ?? undefined },
      { key: 'theme', value: params.theme },
    ],
  });

  return await axios.get(`/api/rh/admin/get_service${query}`);
};

export const rhAdminFetchUser = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'idBusinesses',
        value: params.idBusinesses,
      },
      {
        key: 'idBusiness',
        value: params.idBusiness,
      },
      {
        key: 'idCompanies',
        value: params.idCompanies,
      },
      {
        key: 'idGroup',
        value: params.idGroup,
      },
    ],
  });
  return await axios.get(`/api/rh/admin/get_users_admin${query}`);
};

export const regenUserAdminPwd = async (payload: any): Promise<any> => {
  return await axios.put(`/api/rh/admin/regen_user_admin_pwd`, payload);
};

export const adminDeleteUser = async (id: string): Promise<any> => {
  return await axios.delete(`/api/rh/admin/user_admin/${id}`);
};

export const rhAdminFetchBusiness = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'listIdBusiness',
        value: params.listIdBusiness,
      },
    ],
  });
  return await axios.get(`/api/rh/admin/get_business${query}`);
};

export const rhAdminFetchCompany = async (params: any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'listIdCompany',
        value: params.listIdCompany,
      },
    ],
  });
  return await axios.get(`/api/rh/admin/get_company${query}`);
};
