import { parcoursCreate, parcoursFindAll } from '@/services/rh/parcours';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const ParcoursQuery = (() => {
  const useIndex = () =>
    useQuery('parcoursIndex', async () => {
      return parcoursFindAll();
    });

  const useCreate = () => {
    const queryClient = useQueryClient();

    return useMutation(
      async (data: parcoursCreate.Parameters) => {
        const response = await parcoursCreate({
          name: data.name,
        });
        console.log(response);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('parcoursIndex');
        },
      },
    );
  };

  return {
    useCreate,
    useIndex,
  };
})();
