import React from 'react';
import type { Location } from 'react-router-dom';
import { KerijBox, KerijBoxType } from '../../../components/base';
import { StepProgressBar } from '../../../features/declaration/components/bars/StepProgressBar';
import { Typography } from '@mui/material';

export interface DeclarationDocumentHeaderProps {
  location: Location;
  document: any;
  absenceType: any;
  onClickInfo: () => void;
}

export const DeclarationDocumentHeader = ({
  location,
  document,
  absenceType,
  onClickInfo,
}: DeclarationDocumentHeaderProps) => {
  return (
    <>
      <StepProgressBar type={absenceType} />
      {location.state?.document?.ocr_result?.data?.documentStatus === 'rejected' ? (
        <h3 className="DeclarationDocumentHeader__text question-form mt-5 mb-5 text-center">
          <Typography color="error" variant="h6">
            Votre document a été refusé, que souhaitez-vous faire ?
          </Typography>
        </h3>
      ) : (
        <h3 className="DeclarationDocumentHeader__text question-form mt-5 mb-5">
          Afin de traiter votre demande, veuillez déposer votre justificatif d’arrêt
        </h3>
      )}
      {document.absenceType?.value !== '4' && (
        <KerijBox
          message={
            <span className="DeclarationDocumentHeader__information">
              <strong>Informations</strong> sur les documents justificatifs
            </span>
          }
          onClick={onClickInfo}
          type={KerijBoxType.Info}
          hoverColor="#79D9D9"
        />
      )}
    </>
  );
};
