import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import isAuthorized from '../../hooks/isAuthorized';
import { PRO_URL } from '../../constants/urls.constants';

const useCheckRhAuthentication = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized(window.location.pathname)) {
      navigate(`${PRO_URL.PREFIXE_PRO}${PRO_URL.LOGIN}`);
    }
  }, []);
};

export default useCheckRhAuthentication;
