import React from 'react';
import ResetPasswordUI from './components/ResetPasswordUI';
import { DefaultLayout } from '../../../components/layouts';

export const ResetPasswordPage: React.FC = () => {
  return (
    <DefaultLayout>
      <ResetPasswordUI />
    </DefaultLayout>
  );
};
