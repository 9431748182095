// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-rdv {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: auto;
  /* border: solid 1px rgb(180, 180, 180); */
}
`, "",{"version":3,"sources":["webpack://./src/components/EntretienLiaison/PreferencesEmployee/PreferencesEmployee.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,0CAA0C;AAC5C","sourcesContent":[".img-rdv {\n  border-radius: 50%;\n  width: 150px;\n  height: 150px;\n  margin: auto;\n  /* border: solid 1px rgb(180, 180, 180); */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
