/* eslint-disable unicorn/prefer-query-selector */
import React, { createRef, useEffect, useState } from 'react';
import '../../../../DashboardRH.css';
import useCheckRhAuthentication from '@/shared/hooks/chechRhAuthentication.hooks';
import { CircularProgress } from '@mui/material';
import CardCurrentAbsence from '../CardCurrentAbsence';
import { Absence, AbsenceStatus } from '@/types';
import { FilterSortAbsences } from '../filterSortAbsences.component';
import { PermissionBox } from '@/components/PermissionBox';
import { AbsencesFilters, fetchRhAbsences } from '@/services/rh';
import { ListCard } from '@/components/DashboardRH/ListCard';
import { RHPage } from '@/components/RHPage';

const CurrentAbsence = () => {
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({ page: 1, per_page: 10 });
  const filters: Omit<AbsencesFilters, 'page' | 'per_page'> = {
    status: [AbsenceStatus.DRAFT, AbsenceStatus.PENDING, AbsenceStatus.VALIDATED],
    detail: true,
  };

  const [currentAbsences, setCurrentAbsences] = useState<Array<Absence>>([]);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadMoreEl = createRef<HTMLDivElement>();

  async function fetchAbsencesByFilters(refresh?: true) {
    const apiPagination = refresh ? { page: 1, per_page: 10 } : pagination;
    setPagination(apiPagination);
    setLoading(true);
    const { data } = await fetchRhAbsences({ ...filters, ...apiPagination, search });
    setCurrentAbsences((initialValue) => (apiPagination.page === 1 ? data : [...initialValue, ...data]));
    setCanLoadMore(data.length === apiPagination.per_page);
    setLoading(false);
    setPagination((initialValue) => ({ page: initialValue.page + 1, per_page: initialValue.per_page }));
  }

  useEffect(() => {
    if (loadMore) {
      setLoadMore(false);
      fetchAbsencesByFilters();
    }
  }, [loadMore]);

  useEffect(() => {
    setCurrentAbsences([]);
    setCanLoadMore(true);
    fetchAbsencesByFilters(true);
  }, [search]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setLoadMore(entry.isIntersecting);
      },
      {
        root: null, // Observe vis-à-vis du viewport
        rootMargin: '0px',
        threshold: 0.5, // 50% de l'élément doit être visible pour déclencher l'observation
      },
    );

    if (loadMoreEl.current) observer.observe(loadMoreEl.current);

    return () => {
      if (loadMoreEl.current) observer.unobserve(loadMoreEl.current);
    };
  }, [loadMoreEl]);

  useCheckRhAuthentication();

  return (
    <PermissionBox scope="absence-management" action="access">
      <RHPage title="Dossiers ouverts">
        <div>
          <div className="row">
            <div className="col-6">
              <FilterSortAbsences setSearch={setSearch} />
            </div>
          </div>

          {currentAbsences?.map((absences: Absence, index) => (
            <ListCard key={index}>
              <CardCurrentAbsence absence={absences} />
            </ListCard>
          ))}

          {loading && (
            <div className={'text-center'}>
              <CircularProgress />
            </div>
          )}

          {!loading && canLoadMore && <div ref={loadMoreEl}></div>}

          {!loading && currentAbsences?.length === 0 && (
            <div className="text-center mt-5 mb-3 col-12 col-sm-10">
              <b>Aucune absence</b>
            </div>
          )}
        </div>
      </RHPage>
    </PermissionBox>
  );
};

export default CurrentAbsence;
