import { Box, Typography } from '@mui/material';
import { colors } from '@/constants/theme.constants';
import React from 'react';
import {
  EmployeeContact,
  EmployeeContactType,
  EmployeeContactTypeModule,
  EmployeeInformation,
} from '@/types/user.interface';
import moment from 'moment';
import { FormatDate } from '@/constants/date.constants';

const emptyLabel = '-';
const renderField = (
  employeeInformation: EmployeeInformation | undefined,
  getter: (employee: EmployeeInformation) => string | undefined,
  label: string,
  headerWidth: number,
) => (
  <EmployeeCardContentElement
    label={label}
    value={employeeInformation == null ? emptyLabel : getter(employeeInformation) ?? emptyLabel}
    headerWidth={headerWidth}
  />
);
const renderContact = (
  employeContactList: Array<EmployeeContact> | undefined,
  contactType: EmployeeContactType,
  headerWidth: number,
) => {
  const contact = employeContactList?.find((contact: EmployeeContact) => contact?.type === contactType);
  return (
    <EmployeeCardContentElement
      label={EmployeeContactTypeModule.format(contactType)}
      value={
        contact?.lastname == null
          ? contact?.email == null
            ? emptyLabel
            : contact?.email
          : [contact?.firstname, contact?.lastname].join(' ')
      }
      headerWidth={headerWidth}
    />
  );
};

interface EmployeeCardContentProps {
  employeeInformation?: EmployeeInformation;
  showMore?: boolean;
}

export const EmployeeCardContent = ({ employeeInformation, showMore }: EmployeeCardContentProps) => {
  const header1Width = 100;
  const header2Width = 240;
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          {renderField(employeeInformation, (_) => _.business?.name, 'Société', header1Width)}
          {renderField(employeeInformation, (_) => _.category, 'Status', header1Width)}
          {renderField(employeeInformation, (_) => _.job_title, 'Fonction', header1Width)}
          {renderField(employeeInformation, (_) => _.organisation?.short_description, 'Service', header1Width)}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            minWidth: '30rem',
          }}
        >
          {renderField(employeeInformation, (_) => _.description, 'Matricule', header2Width)}
          {renderField(employeeInformation, (_) => _.time_package, 'Forfait temps', header2Width)}
          {renderContact(employeeInformation?.employee_contacts, EmployeeContactType.Manager1, header2Width)}
          {renderContact(employeeInformation?.employee_contacts, EmployeeContactType.GTA1, header2Width)}
        </Box>
      </Box>

      {showMore && (
        <>
          <Typography fontSize={24} marginTop={2} marginBottom={1}>
            {' '}
            Informations complémentaires{' '}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              {renderField(
                employeeInformation,
                (_) => moment(_.birthday).format(FormatDate.FRENCH_DATE),
                'Date de naissance',
                header1Width,
              )}
              {renderField(employeeInformation, (_) => _.zipcode, 'Code Postal', header1Width)}
            </Box>
            <Box sx={{ display: 'flex', flex: 2, flexDirection: 'column' }}>
              {renderField(employeeInformation, (_) => _.birth_commun, 'Département naissance', header2Width)}
              {renderField(
                employeeInformation,
                (_) => (_.employee_login?.status === 'active' ? 'Oui' : 'Non'),
                'Inscription confirmée',
                header2Width,
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

interface EmployeeCardContentElementProps {
  label: string;
  value: string;
  headerWidth: number;
}

export const EmployeeCardContentElement = ({ label, value, headerWidth }: EmployeeCardContentElementProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
      }}
    >
      <Box
        sx={{
          minWidth: headerWidth,
          paddingLeft: 1,
          paddingRight: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            color: colors.darkblue,
          }}
        >
          {label}:
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 2,
        }}
      >
        <Typography
          sx={{
            color: colors.darkblue,
          }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};
