import axios from '@/lib/axios';
import { EmployeeInformation } from '@/types';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';

export async function login(data: LoginParameters) {
  try {
    return await axios.post<LoginResponse>(`/api/employee/auth/login`, data);
  } catch (e: unknown) {
    const error: AxiosError = e as any;
    if (error.status === StatusCodes.UNAUTHORIZED) return { status: StatusCodes.UNAUTHORIZED, data: {} };
    if (error.status === StatusCodes.TOO_MANY_REQUESTS) return { status: StatusCodes.TOO_MANY_REQUESTS, data: {} };
    return { status: StatusCodes.INTERNAL_SERVER_ERROR, data: {} };
  }
}

export interface LoginResponse {
  infoEmployee: EmployeeInformation;
  exp: string;
  token: string;
  refreshToken: string;
  status?: string;
  isResetPassword?: boolean;
}

export interface LoginResponseError {
  message: string;
  name: string;
}

export interface LoginParameters {
  email?: string;
  password?: string;
}

export const isLoginResponse = (value: LoginResponse | LoginResponseError | object): value is LoginResponse =>
  Object.hasOwn(value, 'token') && Object.hasOwn(value, 'refreshToken') && Object.hasOwn(value, 'infoEmployee');
