import React from 'react';
import { Avatar, Box, Button, CardHeader, styled } from '@mui/material';

interface ListCardHeaderProps {
  children?: React.ReactNode;
  actions?: React.ReactNode;
}

export const ListCardHeader = (props: ListCardHeaderProps) => {
  const { children, actions } = props;

  return (
    <Header
      title={
        <Box display="flex">
          <Box flexShrink={0} flexGrow={0}>
            {children}
          </Box>

          <Box flexGrow={1} textAlign="right">
            <Box maxHeight={40} pr={1.25}>
              {actions}
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

const Header = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  height: 40,
  padding: 0,
}));

export const ListCardHeaderButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  paddingLeft: 20,
  paddingRight: 20,
  borderRadius: 0,
  height: 40,
  borderRight: `1px solid ${theme.palette.primary.dark}`,
  ':hover': { borderRight: `1px solid ${theme.palette.primary.dark}` },
}));

export const ListCardHeaderAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  fontSize: 10,
  marginRight: 10,
  fontWeight: 'bolder',
  width: 24,
  height: 24,
}));
