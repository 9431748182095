import CurrentAbsence from './CurrentAbsence';
import React from 'react';

export function ProgressAbsencePage() {
  return (
    <div>
      <CurrentAbsence />
    </div>
  );
}
