import type { TypeAbsence } from '@/types';
import axios from '@/lib/axios';

export interface GetTypeAbsenceParameters {
  description: number;
  description_1?: number;
  description_2?: number;
}

export const getTypeAbsence = async (parameters: GetTypeAbsenceParameters): Promise<TypeAbsence> => {
  const { data } = await axios.get<TypeAbsence>('/api/employee/declare_absence/type_absence', { params: parameters });
  return data;
};
