import { EmployeeContactType, EmployeeContactTypeModule } from '@/types';
import { useQuery } from 'react-query';

export const EmployeeContactQuery = (() => {
  const useSelectItems = () =>
    useQuery('employeeContactTypeSelectItems', async () => {
      return Object.values(EmployeeContactType).map((contactType) => ({
        label: EmployeeContactTypeModule.format(contactType),
        value: contactType,
      }));
    });

  return {
    useSelectItems,
  };
})();
