/* eslint-disable xss/no-mixed-html, no-unsanitized/property */
import React, { useRef, useState, useEffect } from 'react';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Plugin,
  Title,
  Tooltip,
} from 'chart.js';
import { Doughnut, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import { useFetchRhGraphDataProgress } from '../api';
import {
  PieChartsData,
  PieData,
  defaultPieChartsData,
  defaultPiesData,
  generateLegend,
  pieBaseOptions,
  pieChartsBaseOptions,
} from './data';
import '../Chart.css';
import { FormatDate } from '../../../constants/date.constants';
import { useAppSelector } from '@/store';
import { PermissionBox } from '@/components/PermissionBox';
import { RHPage } from '@/components/RHPage';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, CategoryScale, LinearScale, BarElement, Title);

export const PieChartsEncours = (properties: { forPage: any }) => {
  const chartIndex = 0;
  const chartReference = useRef(null);
  const pieReference = useRef(null);
  const [data, setData] = useState<PieChartsData>(defaultPieChartsData);
  const [pieData, setPieData] = useState<PieData>(defaultPiesData);
  const listSelectBusiness = useAppSelector((state) => state.rhNavbarContext?.listSelectBusiness);
  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext?.selectedIdBusiness);

  useEffect(() => {
    const legend: any = document.querySelector('#legend');
    if (legend && chartReference.current != null)
      legend.innerHTML = generateLegend((chartReference.current as any).legend?.legendItems);
  }, [chartReference, data]);

  useFetchRhGraphDataProgress(
    {
      date: moment().format('YYYY-MM-DD'),
      idBusiness: selectedIdBusiness,
      idBusinesses: JSON.stringify(
        listSelectBusiness.filter((value: any) => value.isChecked).map((value: any) => value.id),
      ),
    },
    {
      onSuccess: (value: any) => {
        const piectx = pieReference.current;
        const context = chartReference.current;

        if (context != null && piectx != null) {
          const notEmptyValues = Object.values(value.data).filter((a: any) => a.count > 0);
          const labels = notEmptyValues.map((item: any) => item.label);
          const data = notEmptyValues.map((item: any) => item.count);
          const backgroundColor = notEmptyValues.map((item: any) => item.color);

          const pieChartConfig = { borderColor: ['white'], borderWidth: 5, hoverOffset: 4 };
          setData({ labels, datasets: [{ ...pieChartConfig, data, backgroundColor }] });
          const pieConfig = { backgroundColor: ['rgba(0, 0, 139, 1)'], borderWidth: 0 };
          setPieData({ datasets: [{ ...pieConfig, labels: [], data: [data.reduce((a, b) => a + b, 0)] }] });
        }
      },

      onError: () => {
        setData(defaultPieChartsData);
        setPieData(defaultPiesData);
      },
    },
  );

  return (
    <PermissionBox scope="statistic" action="access">
      <RHPage title={`Absences en cours au ${moment().format(FormatDate.FRENCH_DATE)}`}>
        {chartIndex === 0 && (
          <div className="chart-container">
            <div className="chart-item">
              <div
                style={{
                  height: properties.forPage ? 350 : 450,
                  width: properties.forPage ? 350 : 450,
                  position: 'relative',
                }}
              >
                <Doughnut data={data} options={pieChartsBaseOptions as any} ref={chartReference}></Doughnut>
                <div className="PieChartsContainer">
                  <Pie
                    data={pieData}
                    plugins={[ChartDataLabels as Plugin<'pie', object>]}
                    options={pieBaseOptions as any}
                    ref={pieReference}
                  />
                </div>
              </div>
            </div>
            <div id="legend"></div>
          </div>
        )}
      </RHPage>
    </PermissionBox>
  );
};
