import React from 'react';
import { ItemCloseAbsenceModal } from './data';
import { ActionButton } from '@/components/ActionButton';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';

interface CloseAbsenceModalContentFormItemProperties {
  item: ItemCloseAbsenceModal;
  dateValue?: string;
  selectedValue?: string;
  documentSelected?: boolean;
  onChange?: (value?: string) => void;
  onAction?: (itemValue: string) => void;
}

export const CloseAbsenceModalContentFormItem = ({
  item,
  dateValue,
  selectedValue,
  documentSelected,
  onChange,
  onAction,
}: CloseAbsenceModalContentFormItemProperties) => {
  return (
    <>
      {(item.dateSelection || item.action) && (
        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          <span className="m-auto" style={{ paddingRight: '1rem' }}>
            {item.label}
          </span>
          {item.dateSelection && (
            <LabelDate item={item} value={dateValue} selectedValue={selectedValue} onChange={onChange} />
          )}
          {item.action != null && (
            <>
              <LabelAction item={item} selectedValue={selectedValue} onAction={onAction} />
              {documentSelected && (
                <CheckCircleOutline
                  style={{
                    marginTop: '0.75rem',
                    marginLeft: '0.25rem',
                  }}
                  color="success"
                />
              )}
            </>
          )}
        </div>
      )}
      {!item.dateSelection && !item.action && <>{item.label}</>}
    </>
  );
};

interface LabelDateProperties {
  item: ItemCloseAbsenceModal;
  value?: string;
  selectedValue?: string;
  documentSelected?: boolean;
  onChange?: (value?: string) => void;
}

export const LabelDate = ({ item, value, selectedValue, onChange }: LabelDateProperties) => {
  return (
    <input
      className="form-date m-auto"
      name="dateEnd"
      type="date"
      value={value}
      disabled={item.value !== selectedValue}
      onChange={(e) => onChange?.(e.target.value)}
      onBlur={(e) => onChange?.(e.target.value)}
    />
  );
};

interface LabelActionProperties {
  item: ItemCloseAbsenceModal;
  selectedValue?: string;
  onAction?: (itemValue: string) => void;
}

export const LabelAction = ({ item, selectedValue, onAction }: LabelActionProperties) => {
  return (
    <ActionButton
      className="ModalAbsenceItemAction"
      label={item.action ?? ''}
      onClick={() => onAction?.(item.value)}
      disabled={item.value !== selectedValue}
    />
  );
};
