import React from 'react';
import { Button, type ButtonProps } from '@/components/Button';
import { Add } from '@/components/AppIcon';

type Action =
  // List of available actions
  'add' | 'cancel' | 'ok' | 'previous' | 'next' | 'finish';

const actionMap: Record<Action, { icon: React.ReactNode; label: string }> = {
  add: {
    icon: <Add />,
    label: 'Ajouter',
  },
  cancel: {
    icon: undefined,
    label: 'Annuler',
  },
  ok: {
    icon: undefined,
    label: 'OK',
  },
  previous: {
    icon: undefined,
    label: 'Précédent',
  },
  next: {
    icon: undefined,
    label: 'Suivant',
  },
  finish: {
    icon: undefined,
    label: 'Terminer',
  },
};

export interface ActionButtonProps extends ButtonProps {
  /**
   * Well known action (ex: add, remove, destroy, etc)
   */
  actionName?: Action;
  label?: React.ReactNode;
}

/**
 * This component is useful to avoid duplication for every common button
 *
 * @param props
 */
export const ActionButton = (props: ActionButtonProps) => {
  const { label, actionName, startIcon, children, submitting, ...otherProps } = props;

  // eslint-disable-next-line security/detect-object-injection
  const actionData = actionName ? actionMap[actionName] : undefined;

  return (
    <Button
      color="secondary"
      startIcon={submitting ? undefined : startIcon ?? actionData?.icon}
      submitting={submitting}
      {...otherProps}
    >
      {label ?? actionData?.label ?? children}
    </Button>
  );
};
