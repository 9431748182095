import React from 'react';

import ImgEdit from '../../../../../assets/icon-edit.png';

interface DataTableProperties {
  absenceOptions: any;
  setSelect?: (value: any) => void;
  setModalIsOpenEditAbsenceOptions?: (value: boolean) => void;
}

const DataTable = ({ absenceOptions, setSelect, setModalIsOpenEditAbsenceOptions }: DataTableProperties) => {
  return (
    <table style={{ width: '100%' }}>
      <tbody>
        <tr style={{ width: '100%' }}>
          <td style={{ width: '20%' }}></td>
          <td colSpan={3}>Congé pré natalité</td>
          <td colSpan={3}>Congé post natalité</td>
        </tr>
        <tr style={{ width: '100%' }}>
          <td style={{ width: '20%' }}></td>
          <td>Nombre semaine suplémentaire</td>
          <td colSpan={2}>Temps de travail réduit</td>
          <td>Nombre semaine suplémentaire</td>
          <td colSpan={2}>Temps de travail réduit</td>
        </tr>
        <tr style={{ width: '100%' }}>
          <td style={{ width: '20%' }}>{`Type d'absence`}</td>
          <td></td>
          <td>Nombre de semaine</td>
          <td>Temps quotidien</td>
          <td></td>
          <td>Nombre de semaine</td>
          <td>Temps quotidien</td>
        </tr>
        {absenceOptions.map((value: any, index: number) => (
          <tr key={index}>
            <td>
              {value.type_absence.description_1_label}{' '}
              {value.type_absence.description_2_label ? <span>: {value.type_absence.description_2_label}</span> : ''}
            </td>
            <td>{value?.pre_week_promo || ''}</td>
            <td>{value.pre_week_reduction || ''}</td>
            <td>{value.pre_hours_reduction || ''}</td>
            <td>{value.post_week_promo || ''}</td>
            <td>{value.post_week_reduction || ''}</td>
            <td>{value.post_hours_reduction || ''}</td>
            <td
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelect && setSelect(value.id);
                setModalIsOpenEditAbsenceOptions && setModalIsOpenEditAbsenceOptions(true);
              }}
            >
              <i>
                <img alt="kerij" className="img-admin" src={ImgEdit} />
              </i>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;
