import React from 'react';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { DocumentAbsence } from '../../../types/absences.type';
import { PictureAsPdf } from '@mui/icons-material';
import { DocumentUploaderIconStatus } from './DocumentUploaderIconStatus';
import { DocumentUploaderPreviews } from './DocumentUploaderPreview';
import { DocumentUploaderDescription } from './DocumentUploaderDescription';

interface DocumentUploaderDocumentsProperties {
  documents: DocumentAbsence[];
  onStatusClick: (document: DocumentAbsence) => void;
}

export const DocumentUploaderDocuments = ({ documents, onStatusClick }: DocumentUploaderDocumentsProperties) => {
  return (
    <>
      {documents.map((documentAbsence) => (
        <TableRow key={documentAbsence.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell style={{ width: 50 }}>
            <IconButton onClick={() => window.open(documentAbsence.url, '_blank')}>
              <PictureAsPdf />
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" color={'success'}>
            <DocumentUploaderDescription document={documentAbsence} />
          </TableCell>
          <TableCell style={{ width: 50 }}>
            <DocumentUploaderPreviews document={documentAbsence} />
          </TableCell>
          <TableCell style={{ width: 50 }}>
            {' '}
            <Tooltip title={'Analyse OCR'}>
              <DocumentUploaderIconStatus file={documentAbsence} onClick={() => onStatusClick(documentAbsence)} />
            </Tooltip>
          </TableCell>

          <TableCell style={{ width: 50 }}></TableCell>
        </TableRow>
      ))}
    </>
  );
};
