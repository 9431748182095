import * as React from 'react';
import { ToolbarButton, ToolbarButtonProps } from './ToolbarButton';
import { useSlate } from 'slate-react';
import type { CustomElement } from '../type';
import { isBlockActive } from '../editor/isBlockActive';
import { toggleBlock } from '../editor/toggleBlock';

export interface BlockButtonProps extends ToolbarButtonProps {
  block: CustomElement['type'];
}

/**
 * Toolbar button for bold text mark
 *
 * @see ToolbarButton
 */
export const BlockButton = React.forwardRef(function BlockButton(props: BlockButtonProps, ref) {
  const { block, ...otherProps } = props;
  const editor = useSlate();
  const selected = isBlockActive(editor, block, /*TextAlignTypes.has(block) ? 'align' :*/ 'type');
  const handleMouseDown: ToolbarButtonProps['onMouseDown'] = (event) => {
    event.preventDefault();
    toggleBlock(editor, block);
    props.onMouseDown?.(event);
  };
  return <ToolbarButton ref={ref} {...otherProps} selected={selected} onMouseDown={handleMouseDown} />;
});
