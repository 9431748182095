import React from 'react';
import { Card, CardActionArea, Skeleton, Typography, Box } from '@mui/material';

export function ServiceCardLoader({ failedText }: { failedText?: string }) {
  const borderColor = 'rgba(64, 201, 201, 0.5)';

  return (
    <Card variant="outlined" sx={{ borderColor, borderRadius: '10px' }}>
      <CardActionArea>
        {failedText && (
          <Box height={'25vh'} display="flex">
            <Typography align="center" margin="auto" padding="0.5rem">
              {failedText}
            </Typography>
          </Box>
        )}
        {!failedText && <Skeleton variant="rectangular" height={'25vh'} sx={{ bgcolor: 'rgba(13, 6, 78, 0.05)' }} />}
      </CardActionArea>
    </Card>
  );
}
