import axios from '@/lib/axios';

export const login = async (payload: LoginParameters) => {
  return await axios.post<LoginResponse | LoginResponseError>(`/api/rh/auth/login`, payload);
};

export interface LoginResponse {
  exp: string;
  token: string;
  refreshToken: string;
  isResetPassword?: boolean;
}

export interface LoginResponseError {
  message: string;
  name: string;
  origin: string;
}

export interface LoginParameters {
  email?: string;
  password?: string;
}

export const isLoginResponse = (value: LoginResponse | LoginResponseError): value is LoginResponse =>
  Object.hasOwn(value, 'token') && Object.hasOwn(value, 'refreshToken');
