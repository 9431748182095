export enum DocumentActionTypes {
  CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS',
  CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE',
  CREATE_DOCUMENT_LOADING = 'CREATE_DOCUMENT_LOADING',
  CREATE_DOCUMENT_RESET = 'CREATE_DOCUMENT_RESET',
}

export interface IDocumentSuccessAction {
  type: DocumentActionTypes.CREATE_DOCUMENT_SUCCESS;
  payload: any;
}
export interface IDocumentFailureAction {
  type: DocumentActionTypes.CREATE_DOCUMENT_FAILURE;
  payload: any;
}

export interface IDocumentLoadingAction {
  type: DocumentActionTypes.CREATE_DOCUMENT_LOADING;
  payload?: boolean;
}
export interface IDocumentResetAction {
  type: DocumentActionTypes.CREATE_DOCUMENT_RESET;
}

export type DocumentAction =
  | IDocumentSuccessAction
  | IDocumentFailureAction
  | IDocumentResetAction
  | IDocumentLoadingAction;
