import React from 'react';
import { Link } from 'react-router-dom';

export const TermsContent: React.FC = () => {
  return (
    <ol>
      <li>
        <strong>
          <em>OBJET ET CHAMPS D’APPLICATION</em>
        </strong>
        <ol>
          <li>
            WITHBOT, société par actions simplifiée à associé unique au capital social de 100.000 €, immatriculée sous
            le numéro 880 118 419 au RCS de Paris, dont le siège social est situé au 128, rue de la Boétie, 75008 Paris
            (« <strong>WITHBOT</strong> », « <strong>nous</strong> », « <strong>notre</strong> », « <strong>nos</strong>{' '}
            ») est l'éditeur de la plateforme KERIJ accessible via{' '}
            <Link to={'/'}>
              <span>www.kerij.fr</span>
            </Link>{' '}
            ou via tout autre moyen mis à disposition par l’entreprise (la « <strong>Plateforme </strong> ») plateforme
            sur laquelle les utilisateurs, peuvent se rendre afin de d’utiliser les services proposés par des tiers sur
            la Plateforme (les «<strong>Services</strong> »).
            <br />
            Nous avons rédigé ces conditions générales d’utilisation (ci-après les «{' '}
            <strong>Conditions générales</strong> » ou les « <strong>CGU</strong> ») afin que vous connaissiez les
            règles et le fonctionnement de la Plateforme pour les services où WITHBOT est responsable de traitement en
            matière de protection de données. Ces Conditions générales constituent un contrat juridiquement contraignant
            entre vous (« <strong>l’Utilisateur</strong> », « <strong>vous</strong> », « <strong>vos</strong> » ou «{' '}
            <strong>votre</strong> ») et WITHBOT dans le cadre de votre utilisation des services de la Plateforme
            proposés par WITHBOT. Nous vous prions donc les lire attentivement.
          </li>
          <li>
            En utilisant cette Plateforme, vous acceptez les CGU sans réserve. Vous êtes tenu de vous référer à la
            version des CGU accessible en ligne à la date de votre accès et de votre utilisation des Services.
          </li>
          <li>
            Ces CGU ne s’appliquent pas pour les services strictement limités à la déclaration de votre arrêt de travail
            et son suivi pour lesquels votre employeur agit en tant que responsable de traitement au sens de la
            réglementation en matière de protection des données.
            <br />
            Nous vous informons que la seule version applicable des CGU est celle qui est accessible via{' '}
            <Link to={'/conditions'}>
              <span>www.kerij.fr/conditions</span>
            </Link>
          </li>
        </ol>
      </li>

      <li>
        <strong>
          <em>ACCES A LA PLATEFORME ET DESCRIPTION DES SERVICES</em>
        </strong>
        <ol>
          <li>
            Accès à la Plateforme
            <br />
            Pour accéder et utiliser les Services fournis par la Plateforme, vous confirmez que :
            <ul>
              <li>vous avez la capacité de vous engager avec WITHBOT ;</li>
              <li>vous êtes salarié d’un client de WITHBOT ;</li>
              <li>
                vous vous engagez à respecter les présentes Conditions générales ainsi que toutes les lois, règles et
                réglementations locales, nationales et internationales applicables.
              </li>
            </ul>
          </li>
          <li>
            <span>Descriptions des fonctionnalités</span>
            <p>
              Le salarié qui bénéficie de la plateforme KERij accède à différents outils, produits et services qui
              évoluent selon la commande de l’entreprise cliente.
              <br />A minima, le salarié bénéficie du service de déclaration, de suivi administraif et médico-social de
              ses absences en cas d’arrêt de travail, de congé maternité et de congé de parentalité.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <strong>
          <em>CONDITIONS D’ACCES ET D’USAGE DE LA PLATEFORME</em>
        </strong>
        <ol>
          <li>
            Accès aux Services
            <p>
              Vous pouvez accéder et utiliser librement la Plateforme, sous réserve de créer un Compte Utilisateur ou de
              vous connecter à ce Compte Utilisateur.
            </p>
          </li>
          <li>
            Création et accès au Compte Utilisateur
            <p>
              Pour avoir accès et utiliser certaines sections de la Plateforme, vous devrez créer un Compte Utilisateur.
              Ce Compte Utilisateur est personnel et protégé par un mot de passe.
            </p>
            <p>
              La création d'un Compte Utilisateur implique le renseignement par l’Utilisateur d’informations
              personnelles telles que le nom, le prénom, l’adresse postale, département de naissance, le numéro de
              téléphone et l’adresse électronique, qui font l'objet d'un traitement de données tel que prévu dans la{' '}
              <Link to="/politique-de-confidentialite">Politique de confidentialité</Link> de WITHBOT.
            </p>
            <p>
              La création d'un Compte Utilisateur implique également le choix d'un mot de passe. Il est recommandé
              d'adopter un mot de passe robuste (contenant au moins 8 caractères dont des chiffres, des majuscules et
              des caractères spéciaux).
            </p>
            <p>
              Toute perte, détournement ou utilisation non autorisée des identifiants d'un Utilisateur et leurs
              conséquences sont de la seule responsabilité de cet Utilisateur. Dans tous les cas mentionnés ci-dessus,
              l'Utilisateur est tenu d'en informer WITHBOT, sans délai, par courrier électronique, en précisant ses
              identifiants, nom, prénoms à l'adresse suivante : <a href="mailto:security@kerij.fr">security@kerij.fr</a>
              , afin que WITHBOT puisse procéder à la réinitialisation du Compte Utilisateur.
            </p>
          </li>
          <li>
            Droits accordés aux Utilisateurs
            <p>
              WITHBOT accorde à l’Utilisateur une licence personnelle, incessible, non exclusive, révocable et non
              sous-licenciable pour l'accès et l'utilisation de la Plateforme. Cette licence a pour seul but de vous
              permettre d'utiliser la Plateforme d'une manière conforme aux présentes Conditions générales.
            </p>
            <p>
              Toute action allant au-delà de ces droits d'accès et d'utilisation et notamment toute reproduction, mise à
              disposition de tiers, téléchargement illicite constitue un acte de contrefaçon. L'Utilisateur s'engage à
              ne pas utiliser ou mettre en œuvre un robot ou tout autre moyen automatisé pour accéder à la Plateforme
              et/ou au contenu, et plus généralement à ne pas tenter de porter atteinte au fonctionnement de la
              Plateforme.
            </p>
            <p>
              Sauf dans les cas expressément autorisés par la loi, l'Utilisateur ne doit pas, ni permettre à autrui de
              modifier, adapter, traduire, faire de l’ingénierie inversée, décompiler ou désassembler la Plateforme ou
              l'un de ces éléments ou créer des œuvres dérivées.
            </p>
            <p>
              Nous vous informons que vous pouvez à tout moment supprimer votre Compte Utilisateur par courrier
              électronique en précisant vos identifiants, nom et prénoms à l’adresse suivante{' '}
              <a href="mailto:contact@kerij.fr">contact@kerij.fr</a> afin d’utiliser les Services, afin que WITHBOT
              puisse procéder à la réinitialisation du Compte Utilisateur.
            </p>
          </li>
          <li>
            Obligations des Utilisateurs
            <p>
              L’Utilisateur est seul responsable de la saisie de ses données, WITHBOT ne peut en aucun cas être tenu
              responsable d'une information erronée. Dans le cas où l'Utilisateur renseigne des informations fausses,
              inexactes, périmées, incomplètes, trompeuses ou mensongères, WITHBOT peut, immédiatement et sans préavis
              ni indemnité, suspendre ou résilier le Compte Utilisateur et refuser l'accès, temporairement ou
              définitivement, à tout ou partie des Services fournis par la Plateforme.
            </p>
            <p>WITHBOT soutient et défend des valeurs de tolérance, de diversité, de respect d'autrui.</p>
            <p>
              L'Utilisateur s'engage ainsi à respecter ces valeurs lors de l'utilisation de la Plateforme. En
              particulier :
            </p>
            <ul>
              <li>
                à ne pas tenir de propos discriminatoires, racistes, xénophobes, antisémites, insultants, malveillants,
                obscènes, illicites ou autres propos violents ou pornographiques, ni à publier de contenus contraires à
                l'ordre public ou aux bonnes mœurs de quelque manière que ce soit dans le cadre de sa navigation et de
                son utilisation du Site ;
              </li>
              <li>
                ne pas diffuser de contenu pouvant être préjudiciable, diffamatoire, non autorisé, malveillant ou
                portant atteinte à la vie privée ou aux droits de la publicité, incitant à la violence, à la haine
                raciale ou ethnique ou pouvant être qualifié de grossière indécence ou d'incitation à commettre certains
                crimes ou délits ;
              </li>
              <li>
                ne pas usurper l'identité d'une personne ou d'une entité ni présenter de manière inexacte votre
                affiliation à une autre personne ou entité;
              </li>
              <li>ne pas utiliser la Plateforme à des fins politiques, de propagande ou de prosélytisme;</li>
              <li>
                ne pas porter atteinte aux droits de propriété intellectuelle de WITHBOT sur la Plateforme et ses
                éléments;
              </li>
              <li>
                ne pas spammer, intimider, solliciter ou détourner la Plateforme de quelque manière que ce soit de sa
                destination;
              </li>
              <li>
                ne pas mener d'activités illégales, en diffusant des contenus (photographies, images, textes, vidéos)
                représentant des mineurs de quelque manière que ce soit;
              </li>
              <li>de signaler à WITHBOT tout contenu inapproprié.</li>
            </ul>
            <p>
              Vous demeurez seul responsable de votre utilisation de la Plateforme. WITHBOT ne peut être tenu
              responsable de toute perte ou dommage résultant de l'utilisation de la Plateforme par l'Utilisateur, ou
              tout tiers (autorisé ou non).
            </p>
          </li>
        </ol>
      </li>
      <li>
        <strong>
          <em>OBLIGATIONS DE WITHBOT</em>
        </strong>
        <ol>
          <li>
            WITHBOT s'engage à faire ses meilleurs efforts pour assurer l'accès permanent à la Plateforme et à ses
            Services ainsi que son fonctionnement. Toutefois, WITHBOT ne peut être tenue responsable de
            l'indisponibilité de la Plateforme ou d'un Service en cas d'événements tels que :
            <ul>
              <li>
                la maintenance de la Plateforme, y compris lorsque l'intervention de maintenance est effectuée par des
                tiers;
              </li>
              <li>la survenance d'un événement imprévisible, insurmontable et extérieur (cas de force majeure);</li>
              <li>défaillance du réseau internet.</li>
            </ul>
            L'Utilisateur est en outre informé des risques inhérents à l'utilisation d’internet, notamment en termes de
            défaut de sécurité dans la transmission des données et de continuité non garantie de l'accès à la Plateforme
            et à son contenu. En aucun cas, WITHBOT ne pourra être tenue responsable de ces risques et de leurs
            conséquences, préjudiciables, quelle qu'en soit l'ampleur, pour l'Utilisateur. Il appartient à chaque
            Utilisateur de prendre toutes les mesures appropriées de façon à protéger ses propres données, logiciels et
            matériels de la contamination par des virus ou autres formes d'attaques pouvant circuler sur la Plateforme.
          </li>
          <li>
            WITHBOT se réserve le droit, à tout moment, de modifier tout ou partie de la Plateforme et de ses
            fonctionnalités, de modifier ou de proposer de nouveaux contenus et Services. En particulier, WITHBOT peut
            mettre à jour le contenu en fonction de l'évolution des dispositions légales ou des bonnes pratiques en
            vigueur. Toute nouvelle fonctionnalité sera soumise aux présentes Conditions générales. WITHBOT informe
            l'Utilisateur, qui l'accepte, qu'il sous-traite l'hébergement du Site à la société OVH dont les serveurs
            sont situés en France. Dans le cas où une faille de sécurité détectée par WITHBOT est susceptible de
            compromettre gravement la sécurité de la Plateforme, WITHBOT peut, sans préavis, interrompre temporairement
            l'accès à la Plateforme afin de remédier à la faille de sécurité dans les meilleurs délais.
          </li>
        </ol>
      </li>
      <li>
        <strong>
          <em>PROPRIETE INTELLECTUELLE DE WITHBOT</em>
        </strong>
        <p>
          Tous les droits de propriété intellectuelle relatifs à la Plateforme et à son contenu (textes, images, vidéos,
          bases de données, sons, photographies, noms commerciaux, logos, marques, etc. ) sont et restent la propriété
          de WITHBOT, ou sont soumis à des licences et/ou autorisations qui nous sont accordées par des tiers.
          L’Utilisateur s’engage à respecter les droits de propriété intellectuelle de WITHBOT ainsi que les droits des
          tiers. L'Utilisateur est uniquement autorisé à utiliser la Plateforme et son contenu conformément aux
          Conditions générales. L'Utilisateur ne peut pas reproduire, mettre à disposition du public, exécuter, publier
          ou modifier toute partie de la Plateforme et de son contenu sans l'accord écrit préalable de WITHBOT.
        </p>
      </li>
      <li>
        <strong>
          <em>LIENS VERS DES SITES TIERS</em>
        </strong>
        <ol>
          <li>
            La Plateforme peut contenir des liens redirigeant vers d'autres sites et données de tiers proposant les
            services disponibles sur la Plateforme, ces liens seront uniquement fournis à titre d'information, sans que
            WITHBOT n’ait aucun contrôle sur le contenu ou les données de ces sites. Dans la mesure où WITHBOT ne peut
            contrôler ces sites et ces sources externes, il ne peut être tenu pour responsable de la mise à disposition
            de ces sites et sources externes, et ne peut supporter aucune responsabilité quant au contenu, publicités,
            produits, services ou tout autre matériel disponible sur ou à partir de ces sites ou sources externes.
          </li>
          <li>
            De plus, WITHBOT ne pourra être tenu responsable de tous dommages ou pertes avérés ou allégués consécutifs
            ou en relation avec l'utilisation ou avec le fait d'avoir fait confiance au contenu, à des biens ou des
            services disponibles sur ces sites ou sources externes.
          </li>
        </ol>
      </li>
      <li>
        <strong>
          <em>RESPONSABILITE</em>
        </strong>
        <ol>
          <li>
            Responsabilité générale WITHBOT s'efforce de maintenir la Plateforme en état de marche et sans interruption
            ni suspension. Mais nous ne pouvons pas promettre un fonctionnement continu de la Plateforme sans aucun
            problème. L'Utilisateur reconnaît également que la Plateforme est fournie « telle quel » et « telle que
            disponible » sans garantie d'aucune sorte. En outre, bien que WITHBOT s'efforce de fournir une bonne
            expérience à l'Utilisateur, nous ne pouvons affirmer ou garantir que : (a) la Plateforme sera toujours
            sécurisée ou sans erreur ou altérations ; (b) la Plateforme fonctionnera toujours sans retard, perturbation
            ou imperfection.
          </li>
        </ol>
      </li>
      <li>
        <strong>
          <em>DROIT APPLICABLE ET JURIDICTION COMPETENTE</em>
        </strong>
        <ol>
          <li>
            Les présentes CGU, leur formation, interprétation, exécution, résiliation ou expiration sont régies par les
            lois françaises.
          </li>
          <li>
            EN CAS DE LITIGE, NOUS VOUS REMERCIONS DE VOUS ADRESSER EN PRIORITE AU SERVICE CLIENT DE WITHBOT EN APPELANT
            AU 06 45 20 96 21 OU PAR COURRIEL A : <a href="mailto:contact@kerij.fr">contact@kerij.fr</a>. TOUT LITIGE
            ENTRE WITHBOT ET L'UTILISATEUR DECOULANT DE L'INTERPRETATION, DE L'EXECUTION OU DE LA RESILIATION DES
            PRESENTES CGU QUI NE PEUT ETRE RESOLU A L'AMIABLE SERA REGLE PAR LES TRIBUNAUX COMPETENTS DU RESSORT DE LA
            COUR D’APPEL DE PARIS, NONOBSTANT TOUTE RECLAMATION OU GARANTIE INCIDENTE, OU EN CAS DE PLURALITE DE
            DEFENDEURS.
          </li>
        </ol>
      </li>
      <li>
        <strong>
          <em>DIVERS</em>
        </strong>
        <ol>
          <li>
            Les présentes Conditions générales, constituent l’intégralité de l’accord entre les parties et annulent et
            remplacent tous autres documents échangés ou accords, écrits ou verbaux, antérieurement conclus entre les
            Parties.
          </li>
          <li>
            Si WITHBOT ne tire pas profit ou n'applique pas une des dispositions des CGU, cela ne peut être interprété
            par l'Utilisateur comme une renonciation à celles-ci.
          </li>
          <li>Nous nous réservons tous les droits qui ne vous sont pas expressément accordés.</li>
          <li>
            Vous ne pouvez transférer aucun de vos droits ou obligations en vertu des présentes CGU sans notre
            consentement.
          </li>
          <li>
            Les présentes CGU peuvent être modifiées par WITHBOT afin de prendre en compte, notamment les évolutions
            réglementaires, les améliorations apportées à la Plateforme, et les changements de situation. L’Utilisateur
            est invité à consulter régulièrement cette page.
          </li>
        </ol>
      </li>
    </ol>
  );
};
