import { RHAccountContext, useAppSelector } from '@/store';
import { RHPermissionScope, RHPermissionScopeEntry } from '@/types';

export function usePermission() {
  const permissionScopes = useAppSelector(RHAccountContext.getPermissionScopes);
  const isAdmin = useAppSelector(RHAccountContext.isAdmin);

  /**
   * Returns `true` if current user has permission for `scope` and `action`
   *
   * @param scope
   * @param action
   */
  function hasPermission(scope: RHPermissionScope, action: keyof RHPermissionScopeEntry): boolean {
    const permission = permissionScopes[scope];
    return isAdmin || (permission == null ? false : permission[action]);
  }

  return {
    isAdmin,
    hasPermission,
  };
}
