import React from 'react';
import { PolicyLayout } from '../components/layouts';
import { datesPolicies } from '../config/policies.config';

export const LegalMentions: React.FC = () => {
  return (
    <PolicyLayout title={'Mentions légales'} date={datesPolicies.legalMentions}>
      <div>
        <p>
          Vous êtes actuellement sur la plateforme KERIJ (ci-après la « Plateforme ») de WITHBOT, qui est éditée par :
        </p>
        <p>
          La société WITHBOT (ci-après « WITHBOT ») société par actions simplifiée à associé unique au capital social de
          100.000 €, immatriculée sous le numéro 880 118 419 au RCS de Paris, dont le siège social est situé au 128, rue
          de la Boétie, 75008 Paris.
        </p>
        <ul>
          <li>Numéro de TVA : FR40880118419</li>
          <li>
            Email : <a href="mailto:contact@kerij.fr">contact@kerij.fr</a>
          </li>
        </ul>
        <p>
          WITHBOT se réserve le droit de modifier les présentes Mentions légales à tout moment, sans préavis, et à sa
          convenance.
        </p>

        <p>Publication de la Plateforme :</p>
        <ul>
          <li>Directeur de la publication : Jean-Philippe Allory</li>
          <li>
            Contact du Directeur de la publication : <a href="mailto:contact@kerij.fr">contact@kerij.fr</a>
          </li>
        </ul>

        <p>Hébergement de la Plateforme :</p>
        <ul>
          <li>
            La Plateforme est hébergée par la société OVH, société par actions simplifiée, au capital social de
            10.174.560 €, immatriculée sous le numéro 424 761 419 au RCS de Lille, dont le siège social est situé au 2,
            rue Kellermann 59100 Roubaix.
          </li>
          <li>
            Contact de l’hébergeur : <a href="tel:+33972101007">09 72 10 10 07</a>
          </li>
        </ul>
      </div>
    </PolicyLayout>
  );
};
