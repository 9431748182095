import { useState } from 'react';

export function useError() {
  const [error, setError] = useState<string | null>(null);

  function triggerError(errorMessage?: string) {
    setError(errorMessage ?? 'Une erreur est survenue');
  }

  function clearError() {
    setError(null);
  }

  return { error, triggerError, clearError };
}
