import React from 'react';

interface QuestionnaireSuiviDateProperties {
  value: string | null;
  min?: number | string;
  max?: number | string;
  onChange: (value: string) => void;
}

export function QuestionnaireSuiviDate({ value, min, max, onChange }: QuestionnaireSuiviDateProperties) {
  return (
    <div className="type1-at container-fluid">
      <h4 className="subtitle-services text-center">A quelle date êtes-vous revenu ?</h4>

      <form className="mb-1 col-lg-4">
        <label className="col-12">
          <input
            className="form-control form-date text-center"
            style={{ maxWidth: 'inherit' }}
            name="dateRetour"
            type="date"
            placeholder="Date de retour"
            value={value ?? undefined}
            min={min}
            max={max}
            onChange={(e) => onChange(e.target.value)}
            required
          />
        </label>
      </form>
    </div>
  );
}
