import React from 'react';
import { Create } from './Create';
import CustomModal from '../../../base/CustomModal';
import { RHRole } from '@/types';

interface NewRoleModalProperties {
  isOpen: boolean;
  onModalClosed: () => void;
  role: RHRole | undefined;
}

const NewRoleModal = ({ isOpen, onModalClosed, role }: NewRoleModalProperties) => (
  <CustomModal
    open={isOpen}
    onClose={onModalClosed}
    renderHeader={() => {
      return role?.id ? <>Modifier un rôle</> : <>Créer un rôle</>;
    }}
    renderContent={() => (
      <div className="container-fluid col-12">
        <Create onRoleCreated={(role) => onModalClosed && onModalClosed()} role={role} />
      </div>
    )}
  />
);
export default NewRoleModal;
