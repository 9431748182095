import React, { useEffect, useState } from 'react';

import { ReactComponent as LoadingAnalyseSVG } from '../../../assets/loading-analyse.svg';
import './kerijLoading.scss';
import { Box, LinearProgress, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { Spacer } from '@/components/Spacer';

export const enum KerijLoadingType {
  ANALYSE = 'analyse',
  VALIDATION = 'validation',
}

const getTitle = (type: KerijLoadingType) => {
  switch (type) {
    case KerijLoadingType.ANALYSE: {
      return 'Patience, nous avons besoin de quelques secondes supplémentaires pour analyser votre déclaration';
    }
    case KerijLoadingType.VALIDATION: {
      return 'Veuillez patienter...';
    }
    default: {
      return '';
    }
  }
};

const getContent = (type: KerijLoadingType) => {
  switch (type) {
    case KerijLoadingType.ANALYSE: {
      return '';
    }
    case KerijLoadingType.VALIDATION: {
      return 'Cela peut prendre quelques secondes.';
    }
    default: {
      return '';
    }
  }
};

interface KerijLoadingProperties {
  type: KerijLoadingType;
}

export const KerijLoading: React.FC<KerijLoadingProperties> = ({
  type = KerijLoadingType.ANALYSE,
}: KerijLoadingProperties) => {
  const title = getTitle(type);
  const content = getContent(type);
  return (
    <div className="loading_analyse__container">
      <span className="loading_analyse__title_text">{title}</span>
      <span className="loading_analyse__content_text">{content}</span>
      <div className="loading_analyse__content">
        <div className="loading_analyse__loading"></div>
        <div className="loading_analyse__icon">
          <LoadingAnalyseSVG />
        </div>
      </div>
    </div>
  );
};

interface KerijLoadingOCRProperties {
  close?: boolean;
  onClose?: () => void;
}

export const KerijLoadingOCR = (parameters: KerijLoadingOCRProperties) => {
  const { close, onClose } = parameters;

  // Max 7s
  const [intervalDownload, setIntervalDownload] = useState<NodeJS.Timer>();
  const [progressValueDownload, setProgressValueDownload] = useState<number>(0);

  // Max 7s
  const [intervalRead, setIntervalRead] = useState<NodeJS.Timer>();
  const [progressValueRead, setProgressValueRead] = useState<number>(0);

  // Max 7s
  const [intervalDate, setIntervalDate] = useState<NodeJS.Timer>();
  const [progressValueDate, setProgressValueDate] = useState<number>(0);

  // Max 35s
  const [intervalAnalyze, setIntervalAnalyze] = useState<NodeJS.Timer>();
  const [progressValueAnalyze, setProgressValueAnalyze] = useState<number>(0);

  const closeAllLoaders = () => {
    const intervalTime = 1000;

    if (intervalDownload != null) {
      clearInterval(intervalDownload);
      setIntervalDownload(
        setInterval(() => {
          if (progressValueDownload < 100) setProgressValueDownload((value) => value + 1);
        }, intervalTime / 100),
      );
    }

    if (intervalRead != null) {
      clearInterval(intervalRead);
      setIntervalRead(
        setInterval(() => {
          if (progressValueRead < 100) setProgressValueRead((value) => value + 1);
        }, intervalTime / 100),
      );
    }

    if (intervalDate != null) {
      clearInterval(intervalDate);
      setIntervalDate(
        setInterval(() => {
          if (progressValueDate < 100) setProgressValueDate((value) => value + 1);
        }, intervalTime / 100),
      );
    }

    if (intervalAnalyze != null) {
      clearInterval(intervalAnalyze);
      setIntervalAnalyze(
        setInterval(() => {
          if (progressValueAnalyze < 100) setProgressValueAnalyze((value) => value + 1);
        }, intervalTime / 100),
      );
    }
  };

  useEffect(() => {
    setIntervalDownload(setIntervalLoader(progressValueDownload, setProgressValueDownload, close ? 1000 : 7000));
  }, []);

  useEffect(() => {
    if (progressValueDownload >= 100) {
      clearInterval(intervalDownload);
      setIntervalDownload(undefined);
      setIntervalRead(setIntervalLoader(progressValueRead, setProgressValueRead, close ? 1000 : 7000));
    }
  }, [progressValueDownload]);

  useEffect(() => {
    if (progressValueRead >= 100) {
      clearInterval(intervalRead);
      setIntervalRead(undefined);
      setIntervalDate(setIntervalLoader(progressValueDate, setProgressValueDate, close ? 1000 : 7000));
    }
  }, [progressValueRead]);

  useEffect(() => {
    if (progressValueDate >= 100) {
      clearInterval(intervalDate);
      setIntervalDate(undefined);
      setIntervalAnalyze(setIntervalLoader(progressValueAnalyze, setProgressValueAnalyze, close ? 1000 : 35_000));
    }
  }, [progressValueDate]);

  useEffect(() => {
    if (progressValueAnalyze >= 100) {
      clearInterval(intervalAnalyze);
      setIntervalAnalyze(undefined);
      setTimeout(() => onClose?.(), 500);
    }
  }, [progressValueAnalyze]);

  useEffect(() => {
    if (close === true) closeAllLoaders();
  }, [close]);

  return (
    <div style={{ padding: '1rem' }} className="loading_analyse__container">
      <span className="loading_analyse__title_text">
        L’analyse de votre justificatif peut prendre un peu de temps, tout dépend de l’écriture du médecin !
      </span>
      <Spacer />
      <LoaderLine text="Conformité du document" value={progressValueDownload} />
      <LoaderLine text="Contrôle de la nature de l’absence" value={progressValueRead} />
      <LoaderLine text="Conformité des dates de début et de fin" value={progressValueDate} />
      <LoaderLine text="Cohérence avec vos informations saisies" value={progressValueAnalyze} />
    </div>
  );
};

const setIntervalLoader = (
  value: number,
  setValue: React.Dispatch<React.SetStateAction<number>>,
  intervalTime: number,
): NodeJS.Timer => {
  return setInterval(() => {
    if (value < 100) setValue((data) => data + 1);
  }, intervalTime / 100);
};

interface LoaderLineParameters {
  text: string;
  value: number;
}

const LoaderLine = (parameters: LoaderLineParameters) => {
  const { value, text } = parameters;

  return (
    <>
      <Box sx={{ width: '345px', mt: 0.5 }}>
        <Typography variant="body2">{text}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '300px', mr: 1 }}>
          <LinearProgress sx={{ borderRadius: '5px' }} variant="determinate" value={value} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          {value < 100 && <Typography fontSize="1rem" color="text.secondary">{`${Math.round(value)}%`}</Typography>}
          {value >= 100 && (
            <CheckCircle sx={{ position: 'relative', bottom: '2px' }} fontSize="small" color="success" />
          )}
        </Box>
      </Box>
    </>
  );
};
