// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (max-width: 984px) {
  .footer {
    display: none;
  }
}

.footer {
  background: #ffffff;
  margin-top: 2em;
  height: 3em;
  width: 100%;
  line-height: 3;
  box-shadow: 0px 2px 4px rgba(52, 47, 108, 0.5);
}

.footer-container {
  padding: 1em;
  display: flex;
  justify-content: space-around;
  height: 100%;
  align-items: center;
}

.footer-kerij {
  font-weight: 700 !important;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: normal;
}

.footer-item {
  color: #0e0653;
  font-family: 'Montserrat', Helvetica, serif;
  font-size: 12px;
  font-weight: 400;
  margin-right: 1rem;
  letter-spacing: 0;
}

.footer-lang {
  display: flex;
  align-items: center;
  color: #0e0653;
  line-height: normal;
}

.footer-lang-selected {
  font-family: 'Montserrat', Helvetica, serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  margin-left: 15px;
}

.footer-lang-selector {
  cursor: pointer;
  margin-left: 10px;
  bottom: 2px;
  position: relative;
}

.footer-lang-selector-icon {
  height: 10px;
  width: auto;
}

.footer-lang-icon {
  height: 18px;
  width: 18px;
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/FooterRH/FooterRH.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,WAAW;EACX,cAAc;EACd,8CAA8C;AAChD;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,2CAA2C;EAC3C,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;EAC3C,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB","sourcesContent":["@media all and (max-width: 984px) {\n  .footer {\n    display: none;\n  }\n}\n\n.footer {\n  background: #ffffff;\n  margin-top: 2em;\n  height: 3em;\n  width: 100%;\n  line-height: 3;\n  box-shadow: 0px 2px 4px rgba(52, 47, 108, 0.5);\n}\n\n.footer-container {\n  padding: 1em;\n  display: flex;\n  justify-content: space-around;\n  height: 100%;\n  align-items: center;\n}\n\n.footer-kerij {\n  font-weight: 700 !important;\n}\n\n.footer-links {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  line-height: normal;\n}\n\n.footer-item {\n  color: #0e0653;\n  font-family: 'Montserrat', Helvetica, serif;\n  font-size: 12px;\n  font-weight: 400;\n  margin-right: 1rem;\n  letter-spacing: 0;\n}\n\n.footer-lang {\n  display: flex;\n  align-items: center;\n  color: #0e0653;\n  line-height: normal;\n}\n\n.footer-lang-selected {\n  font-family: 'Montserrat', Helvetica, serif;\n  font-size: 12px;\n  font-weight: 700;\n  letter-spacing: 0;\n  margin-left: 15px;\n}\n\n.footer-lang-selector {\n  cursor: pointer;\n  margin-left: 10px;\n  bottom: 2px;\n  position: relative;\n}\n\n.footer-lang-selector-icon {\n  height: 10px;\n  width: auto;\n}\n\n.footer-lang-icon {\n  height: 18px;\n  width: 18px;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
