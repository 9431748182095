// import { io } from "socket.io-client";
const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME ?? process.env.NODE_ENV ?? 'development';
const HOSTNAME = process.env.REACT_APP_HOSTNAME || 'http://localhost:3000';
const HOSTSERVER = process.env.REACT_APP_HOSTSERVER || 'http://localhost';
const SCANBOT_LICENSE =
  process.env.REACT_APP_SCANBOT_LICENSE ||
  'EcEhG23fW6TZjtbWK2Fp2N/2NSpZTMxRwI0okt0UMqwhRJLNyPvHfsrEGRzKT6oaMSZeqs+zzXshJ8xCI6Ab+7awlX8dR91G+eIbES6UwKT7FQyf2xWt9CXoCeXBd312hF/7xIuVZbHR9XcqtniK+L+b66t8TsTtVAONtm8yEOVaSj8Y84ijZEKY1txI1+H74OJ21GAx8ANO2iJRAnF9pKCBMswmzTybc2Xdnzo3BcNRkjCJxGkmMlbpdxeSk4IwSU1r1nDaWTkg2FGITQJPAUSWvaFBm6Or/5ruGtk/2X3bStRbGPCUEfky88RnDK90WLlZYBrruqDrzCzYEYxzng==\nU2NhbmJvdFNESwprZXJpai5mcip8KmtlcmlqLmZyfGxvY2FsaG9zdHxzdGFnaW5nLmtlcmlqLmNhcHRpdmUuZGV2fGtlcmlqLmNhcHRpdmUuZGV2CjE4MTgyODc5OTkKNzgKOA==\n';

const API_URL = process.env['REACT_APP_API_URL'];

// const socket = io(HOSTNAME);
// const peer = new Peer(APP_SECRET, {
//     host: '/',
//     port: 9000,
// })
// export { HOSTNAME, socket, peer };

function isProduction() {
  return ENVIRONMENT_NAME.startsWith('prod');
}

export { isProduction, ENVIRONMENT_NAME, HOSTNAME, HOSTSERVER, API_URL, SCANBOT_LICENSE };
