import { DefaultLayout } from '../../../components/layouts';
import { useLocation } from 'react-router-dom';
import { AbsenceDate } from '../../../features/declaration/components/date/AbsenceDate';
import { HospiDate } from '../../../features/declaration/components/date/HospiDate.component';
import { MotherDate } from '../../../features/declaration/components/date/MotherDate.component';
import { FatherDate } from '../../../features/declaration/components/date/FatherDate.component';
import { StepProgressBar } from '../../../features/declaration/components/bars/StepProgressBar';
import { NavigationContext } from '../../../components/Context/NavigationContext';
import React, { useContext, useEffect } from 'react';
import { NavigationSteps } from '../../../components/Context/NavigationProvider';

const getAbsenceDate = (absenceType: string) => {
  switch (absenceType) {
    case '1': {
      return <AbsenceDate />;
    }
    case '2': {
      return <HospiDate />;
    }
    case '3': {
      return <MotherDate />;
    }
    case '4': {
      return <FatherDate />;
    }
    default: {
      return <></>;
    }
  }
};

export const DeclarationDatePage = () => {
  const location = useLocation();
  const document = location.state.document || {};
  const absenceType = document?.absenceType?.value;
  const { setCurrentStep } = useContext(NavigationContext);
  useEffect(() => {
    setCurrentStep(NavigationSteps.DECLARATION_DATE);
  }, []);
  return (
    <DefaultLayout>
      <div className="container-fluid">
        <StepProgressBar type={absenceType} />
        {getAbsenceDate(absenceType)}
      </div>
    </DefaultLayout>
  );
};
