import Modal from 'react-modal';
import { defaultStyles } from '../../../../shared/ui/modals/baseModalRH';
import axios from '../../../../lib/axios';
import React from 'react';
import { useError } from '@/shared/useError';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFullName } from '../../../../shared/utils/getFullName.service';
import { StatusCodes } from 'http-status-codes';

const FinishedInterviewModal = ({
  isOpen,
  onRequestClose,
  customStyles,
  className,
  overlayClassName,
  closeTimeoutMS = 200,
  ariaHideApp = false,
  selectedInterview,
  onLoadingChange,
  onUpdate,
}) => {
  const styles = customStyles || defaultStyles;

  const { triggerError } = useError();

  const dowloadFile = (selectedInterview) => {
    onLoadingChange(true);
    if (selectedInterview.interview_documents.length > 0) {
      axios
        .get('/api/file_s3', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'arraybuffer',
        })
        .then((val) => {
          onLoadingChange(false);
          var file = new Blob([val.data], {
            type:
              selectedInterview.interview_documents[0].name.split('.')[1].toLowerCase() === 'pdf'
                ? 'application/pdf'
                : 'image/png',
          });
          var fileURL = URL.createObjectURL(file);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = fileURL;
          a.download = `${selectedInterview.interview_documents[0].name}`;
          a.click();
          a.remove();
          window.URL.revokeObjectURL(fileURL);
        });
    } else {
      onLoadingChange(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={styles}
      className={className}
      overlayClassName={overlayClassName}
      closeTimeoutMS={closeTimeoutMS}
      ariaHideApp={ariaHideApp}
    >
      <div className="card-actions-rh-header-modal row text-start p-2">
        <div className="col-12 row text-start">
          <div className="col-3">
            <strong>{getFullName(selectedInterview.employee_information)}</strong>
            <p className="no-p-margin">{selectedInterview.employee_information.business.name}</p>
          </div>
          {selectedInterview.absence && (
            <div className="col-3 content-mid">
              {selectedInterview.absence.type_absence.description_label === 'Congé paternité'
                ? 'Congé parentalité'
                : selectedInterview.absence.type_absence.description_label}
            </div>
          )}
          {selectedInterview.absence && (
            <div className="col-3 content-mid">
              Du {moment(selectedInterview.absence.date_start).format('DD/MM/YYYY')}
            </div>
          )}
          {selectedInterview.absence && (
            <div className="col-3 content-mid">
              Au{' '}
              {selectedInterview.absence.date_end
                ? moment(selectedInterview.absence.date_end).format('DD/MM/YYYY')
                : '-'}
            </div>
          )}
        </div>
      </div>
      <hr className="col-12"></hr>

      <div className="container-fluid text-center">
        {selectedInterview.type_notification === 'Entretien de liaison' && (
          <h3 className="question-form titre-modal mt-3 p-2">
            {getFullName(selectedInterview.employee_information)} a bien effectué son entretien de liaison
          </h3>
        )}
        {selectedInterview.type_notification === 'Planifier entretien de liaison' && (
          <h3 className="question-form titre-modal mt-3 p-2">
            {getFullName(selectedInterview.employee_information)} a bien effectué son entretien de liaison
          </h3>
        )}
        {selectedInterview.type_notification === "Entretien de retour a l'emploi" && (
          <h3 className="question-form titre-modal mt-3 p-2">
            {getFullName(selectedInterview.employee_information)} a bien effectué son entretien de retour à l'emploi
          </h3>
        )}
        <p className="mb-3" style={{ color: '#0E0653' }}>
          Vous pouvez télécharger la fiche d'entretien venant d'être effectué
        </p>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-kerij-rh btn-xl"
            onClick={() => {
              dowloadFile(selectedInterview);
            }}
          >
            <FontAwesomeIcon icon="fa-download"></FontAwesomeIcon>
            &nbsp;&nbsp;&nbsp;Télécharger la fiche
          </button>
        </div>

        <p className="mb-3">
          <em>Veuillez clôturer le dossier en appuyant sur le bouton ci-dessous</em>
        </p>
      </div>

      <div className="text-center mt-3">
        <button
          className="btn btn-kerij-bleu btn-modal col-4"
          onClick={() => {
            onLoadingChange(true);
            axios
              .post('/api/rh/finish_notif', {
                idRhNotification: selectedInterview.id,
                idBusiness: selectedInterview.employee_information.id_business,
              })
              .then((value) => {
                if (value.status === StatusCodes.OK) {
                  onRequestClose();
                  onUpdate();
                  onLoadingChange(false);
                }
              })
              .catch((error) => {
                onLoadingChange(false);
                triggerError(error);
              });
          }}
        >
          Je clôture le dossier
        </button>
      </div>
    </Modal>
  );
};
export default FinishedInterviewModal;
