import CustomModal from '../../../../base/CustomModal';
import React from 'react';
import { Box } from '@mui/material';
import axios from '../../../../../lib/axios';
import { useError } from '@/shared/useError';
import { addNoteAbsenceOption } from '../api';
import { StatusCodes } from 'http-status-codes';

interface IAdditionalWeekModalProps {
  noteAbsenceOptions: any;
  setNoteAbsenceOptions: (value: any) => void;
  listSelectBusiness?: any[];
  selectedIdBusiness?: string | null;
  setModalIsOpenNotePromo?: any;
  getAbsenceOptions?: any;
  listSelectCompany?: any[];

  modalIsOpenNotePromo: boolean;
}

const AdditionalWeekModal = ({
  noteAbsenceOptions,
  setNoteAbsenceOptions,
  listSelectBusiness,
  selectedIdBusiness,
  setModalIsOpenNotePromo,
  getAbsenceOptions,
  listSelectCompany,
  modalIsOpenNotePromo,
}: IAdditionalWeekModalProps) => {
  return (
    <CustomModal
      open={modalIsOpenNotePromo || false}
      setOpen={setModalIsOpenNotePromo}
      contentProps={{
        style: { paddingLeft: '4rem', paddingRight: '4rem' },
      }}
      renderContent={() => (
        <Content
          noteAbsenceOptions={noteAbsenceOptions}
          setNoteAbsenceOptions={setNoteAbsenceOptions}
          listSelectBusiness={listSelectBusiness}
          selectedIdBusiness={selectedIdBusiness}
          getAbsenceOptions={getAbsenceOptions}
          setModalIsOpenNotePromo={setModalIsOpenNotePromo}
          listSelectCompany={listSelectCompany}
        />
      )}
    />
  );
};

interface IContentProps {
  noteAbsenceOptions: any;
  setNoteAbsenceOptions: (value: any) => void;
  listSelectBusiness?: any[];
  selectedIdBusiness?: string | null;
  setModalIsOpenNotePromo?: any;
  getAbsenceOptions?: any;
  listSelectCompany?: any[];
}

const Content = ({
  noteAbsenceOptions,
  setNoteAbsenceOptions,
  listSelectBusiness,
  selectedIdBusiness,
  setModalIsOpenNotePromo,
  getAbsenceOptions,
  listSelectCompany,
}: IContentProps) => {
  const { error, triggerError, clearError } = useError();

  const [newNoteAbsenceOptions, setNewNoteAbsenceOptions] = React.useState<any>(noteAbsenceOptions);

  const handleSubmit = async () => {
    const idBusiness =
      listSelectBusiness && listSelectBusiness?.filter((value: any) => value.isChecked).length > 0
        ? listSelectBusiness.filter((value: any) => value.isChecked).at(0).id
        : selectedIdBusiness;

    const res = await addNoteAbsenceOption({
      idBusiness: idBusiness,
      notePromo: newNoteAbsenceOptions.notePromo,
      noteReduction: newNoteAbsenceOptions.noteReduction,
    });

    if (res.status === StatusCodes.OK) {
      setNoteAbsenceOptions((prev: any) => ({
        ...prev,
        notePromo: newNoteAbsenceOptions.notePromo,
      }));
      getAbsenceOptions(listSelectBusiness, listSelectCompany, null);
      setModalIsOpenNotePromo((state: any) => !state);
    } else {
      triggerError();
    }
  };

  return (
    <Box className="container-fluid col-12">
      <h4>Ajouter votre commentaire</h4>
      <textarea
        defaultValue={noteAbsenceOptions.notePromo}
        onChange={(e) =>
          setNewNoteAbsenceOptions((prev: any) => ({
            ...prev,
            notePromo: e.target.value,
          }))
        }
        maxLength={256}
        style={{ resize: 'none', width: '100%' }}
        rows={5}
      />
      <button
        className="mt-3 btn-kerij"
        onClick={() => {
          handleSubmit();
        }}
      >
        Valider
      </button>
    </Box>
  );
};

export default AdditionalWeekModal;
