import { InputBase, styled } from '@mui/material';

export const AppInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  backgroundColor: theme.palette.common.white,
  border: `1px solid #c4c4c4`,
  fontSize: '1rem',
  width: '100%',
  padding: '5px 0.75rem',
  transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
  '&:focus-within': {
    boxShadow: `#c7dbff 0 0 0 0.25rem`,
    borderColor: theme.palette.primary.main,
  },
  '& .MuiInputBase-input': {
    padding: '0.25em 0',
  },
}));
