import React from 'react';
import { DocumentUploader } from '../DocumentUploader';
import CustomModal from '../../base/CustomModal';
import { MedicalDocument } from '../../../types/rh.interface';
import { fetchFileS3 } from '../../../services/rh';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import IconButton from '@mui/material/IconButton';
import { EmployeeInformation } from '../../../types/user.interface';
import { Absence, DocumentStatus } from '../../../types/absences.type';
import { RHAccountContext, useAppSelector } from '@/store';
import { Button } from '@/components/Button';
import { Box } from '@mui/material';
import { useToast } from '@/components/Toast';

export interface VisualisationFileModalProps {
  absence?: Absence;
  employee_information: EmployeeInformation;
  medical_documents: MedicalDocument[];
  visible?: boolean;
  handleCloseModal?: () => void;
}

export const VisualisationFileModal = ({
  absence,
  employee_information: employeeInformation,
  medical_documents,
  visible,
  handleCloseModal,
}: VisualisationFileModalProps) => {
  const toast = useToast();

  const medicalDocuments = medical_documents || [];
  const rh = useAppSelector(RHAccountContext.getProfile);

  const handleValidate = () => {
    handleCloseModal?.();
    toast.present({ message: `La déclaration est désormais validée`, severity: 'success' });
  };
  if (!absence) return <></>;
  return (
    <CustomModal
      open={visible || false}
      onClose={handleCloseModal}
      renderHeader={() => <>Gérer les documents</>}
      renderFooter={() => (
        <Box textAlign="right">
          <Button onClick={handleValidate}>Valider</Button>
        </Box>
      )}
      renderContent={() => (
        <>
          <div
            className="form-declaration col-12 row"
            style={{
              display: 'flex',
              alignSelf: 'center',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DocumentUploader absence={absence} infosEmployee={employeeInformation} status={DocumentStatus.APPROVED} />
          </div>
          {medicalDocuments && medicalDocuments.length > 0 ? (
            medicalDocuments.map((medicalDocument, index) => {
              return (
                <div className="card col-12 mt-2" key={index}>
                  <div className="card-body card-style">
                    <div className="row col-12">
                      <p className="card-text text-end col-2 contacts-libelle">
                        {medicalDocument.type.at(0)?.toUpperCase() + medicalDocument.type.slice(1).toLowerCase()}
                      </p>
                      <p className="card-text col-7 text-center mail-active">{medicalDocument.name}</p>
                      {medicalDocument.url && (
                        <div
                          className="col-2 text-end contacts-modif"
                          onClick={() => {
                            fetchFileS3({
                              url: medicalDocument.url,
                              type: 'medical_document',
                              idBusiness: employeeInformation?.business.id,
                              idRhInformation: rh?.id_rh_information,
                            }).then((response) => {
                              const file = new Blob([response.data], {
                                type:
                                  medicalDocument.name.split('.')[1].toLowerCase() === 'pdf'
                                    ? 'application/pdf'
                                    : 'image/png',
                              });
                              const fileURL = URL.createObjectURL(file);
                              window.open(fileURL, '_blank');
                            });
                          }}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          Visualiser
                        </div>
                      )}
                      {medicalDocument.url && (
                        <div
                          className="col-1"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            fetchFileS3({
                              url: medicalDocument.url,
                              type: 'medical_document',
                              idBusiness: employeeInformation?.business.id,
                              idRhInformation: rh?.id_rh_information,
                            }).then((response) => {
                              const file = new Blob([response.data], {
                                type:
                                  medicalDocument.name.split('.')[1].toLowerCase() === 'pdf'
                                    ? 'application/pdf'
                                    : 'image/png',
                              });
                              const fileURL = URL.createObjectURL(file);
                              const element = document.createElement('a');
                              document.body.append(element);
                              element.href = fileURL;
                              element.download = `${medicalDocument.name}`;
                              element.click();
                              element.remove();
                              window.URL.revokeObjectURL(fileURL);
                            });
                          }}
                        >
                          <IconButton>
                            <ArrowCircleDownIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </div>
                  {absence?.is_verified ? (
                    <div className="row align-self-center">
                      <button className="col-5 mr-2 btn btn-kerij align-self-center">Accepter</button>
                      <div className="col-2"></div>
                      <button className="col-5 ml-2 btn btn-kerij-refused align-self-center">Refuser</button>
                    </div>
                  ) : null}
                </div>
              );
            })
          ) : (
            <></>
          )}
        </>
      )}
    ></CustomModal>
  );
};
