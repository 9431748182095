import React from 'react';
import './Footer.css';
import worldIcon from '../../assets/footer-icon-world.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFooterLinks from '../../hooks/useFooterLinks';
import { Link } from 'react-router-dom';
import { RHNavbarContext } from '@/store';
import { Box, BoxProps } from '@mui/material';

export interface FooterProps extends BoxProps {}

export const Footer = (props: FooterProps) => {
  const { ...rootProps } = props;
  const links = useFooterLinks();
  const copyrightYear = RHNavbarContext.getCopyrightYear();
  return (
    <Box
      component="nav"
      className="footer mt-auto"
      sx={{ bottom: 0, left: 0, right: 0, position: 'fixed' }}
      {...rootProps}
    >
      <div className="footer-container text-center">
        <div className="footer-links">
          <div className="footer-item footer-kerij">KERIJ {copyrightYear}</div>
          {links.map((link, index) => (
            <Link key={index} to={link.url} className="footer-item">
              {link.label}
            </Link>
          ))}
        </div>
        <div className="footer-lang">
          <img className="footer-lang-icon" alt={'lang'} src={worldIcon} />
          <div className="footer-lang-selected">Français</div>
          <div className="footer-lang-selector">
            <FontAwesomeIcon icon="chevron-down" className="footer-lang-selector-icon" />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Footer;
