import CloseIcon from '@mui/icons-material/Close';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { customStyles } from '../../../shared/models/custom_modal.models';

// TODO: remove this uglyness
const { borderRadius, padding, height, ...customModalDefaultStyle } = customStyles.content;

interface BootstrapDialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const BootstrapDialogTitle = (props: BootstrapDialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme: any) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

/**
 * TODO: Change the API to be identical to MUIDialog with few extra properties
 */
export interface CustomModalProps extends DialogProps {
  renderContent?: () => React.ReactNode;
  renderHeader?: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  contentProps?: DialogContentProps;
  disabledBackdropDismiss?: boolean;
  showExitIcon?: boolean;
  /**
   * @deprecated Use onClose + useCustomModalState()
   */
  setOpen?: (open: boolean) => void;
}

export function CustomModal({
  PaperProps,
  renderContent,
  renderHeader,
  renderFooter,
  style,
  contentProps = {},
  showExitIcon = true,
  open,
  setOpen,
  onClose,
  disabledBackdropDismiss,
  children,
  fullScreen,
  ...otherProps
}: CustomModalProps) {
  const defaultModalProps = {
    fullWidth: true,
    maxWidth: 'md',
  } as const;
  const handleClose =
    (setOpen
      ? () => {
          if (setOpen) {
            setOpen(false);
          }
        }
      : // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClose) ?? (() => {});

  return (
    <Dialog
      {...defaultModalProps}
      PaperProps={{
        sx: (theme) => ({
          ...customModalDefaultStyle,
          // Dirty fix because of global css
          padding: 0,
          ...style,
        }),
        ...PaperProps,
      }}
      onClose={disabledBackdropDismiss ? undefined : handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen={fullScreen}
      {...otherProps}
    >
      {renderHeader && (
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={showExitIcon ? () => handleClose({}, 'backdropClick') : undefined}
        >
          {renderHeader()}
        </BootstrapDialogTitle>
      )}
      {renderContent && (
        <DialogContent dividers {...contentProps}>
          {renderContent()}
        </DialogContent>
      )}
      {renderFooter && <DialogActions>{renderFooter()}</DialogActions>}
      {children || <></>}
    </Dialog>
  );
}
