import React, { useState, useEffect } from 'react';
import { Navbar } from '@/components/Navbar/Navbar';
import Footer from '../Footer/Footer';
import { NavbarMobile } from '@/components/Navbar/NavbarMobile';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { employeeUpdateQuestionMedical, employeeVerifyQuestionMedical } from '../../services/employee';
import { StatusCodes } from 'http-status-codes';
import { encodeToken } from '../../services/auth';
import useQuestionnaire from '../../hooks/useQuestionnaire';
import RequireLogin from '../RequireLogin';

function QuestionnaireVisite() {
  const navigate = useNavigate();

  const [etatRetourVisite, setEtatRetourVisite] = useState('3');
  const [etatRetourVisiteLabel, setEtatRetourVisiteLabel] = useState(
    'Je ne suis pas capable de répondre à cette question pour l’instant',
  );
  const [idSalarieInformation, setIdSalarieInformation] = useState();
  const [idAbsence, setIdAbsence] = useState();

  const [searchParameters] = useSearchParams();
  const [expire, setExpire] = useState('');
  const [dateFin, setDateFin] = useState();
  const token = searchParameters.get('token_decode');

  const [decode, access, setAccess, loadingAbsence, setLoadingAbsence] = useQuestionnaire();

  const handleClick = () => {
    encodeToken({
      idEmployeeInformation: idSalarieInformation,
      idAbsence: idAbsence,
    }).then((value) => {
      if (value.status === StatusCodes.OK) {
        employeeUpdateQuestionMedical({
          question1: 'Revenez-vous bien à la date indiquée ?',
          response1: etatRetourVisiteLabel,
          typeNotification: 'Notification de questionnaire de visite médicale',
        }).then((value) => {
          if (value.status === StatusCodes.OK) {
            navigate('/questionnairevisitefin', {
              state: { value: etatRetourVisite },
              replace: true,
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    try {
      setLoadingAbsence(true);
      if (token) {
        setIdSalarieInformation(decode.idEmployeeInformation);
        setIdAbsence(decode.idAbsence);
        encodeToken({
          idAbsence: decode.idAbsence,
          idEmployeeInformation: decode.idEmployeeInformation,
        }).then((value) => {
          if (value.status === StatusCodes.OK) {
            employeeVerifyQuestionMedical().then(async (value) => {
              if (value.status === StatusCodes.OK) {
                if (value.data.absence && value.data.dateEnd) {
                  setDateFin(value.data.dateEnd);
                  setExpire('');
                } else {
                  setExpire("Ce lien n'est plus disponible");
                }
                setLoadingAbsence(false);
                setAccess(true);
              }
            });
          }
        });
      }
    } catch {
      setLoadingAbsence(false);
      setAccess(false);
    }
  }, [decode]);

  return (
    <RequireLogin>
      <div>
        <Navbar />
        {loadingAbsence ? (
          <div className="container-fluid container-loading">
            <h2>Veuillez patienter</h2>
            <div className="container">
              <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
            </div>
          </div>
        ) : (
          <div>
            {expire ? (
              <div className="container-fluid mt-5">
                <h1 className="question-form mb-5">{expire}</h1>
              </div>
            ) : (
              <div>
                {access ? (
                  <div className="container-fluid mt-5">
                    <h1 className="question-form mb-5 text-center">Questionnaire de Visite Médicale</h1>

                    <h4 className="subtitle-services text-center"> Bonjour, </h4>
                    <br />
                    <p className="subtitle-services text-center">
                      A la prochaine échéance de votre arrêt de travail le {moment(dateFin).format('DD/MM/YYYY')}, une
                      visite médicale de reprise devra être organisée avec le Médecin du travail. Aidez nous à planifier
                      cette visite en sélectionnant l’un des champs suivants:{' '}
                    </p>

                    <form className="form-declaration form-declaration col-lg-4">
                      <label>
                        <select
                          className="form-select"
                          name="retourvisite"
                          value={etatRetourVisite}
                          onChange={(e) => {
                            setEtatRetourVisite(e.target.value);
                            const index = e.nativeEvent.target.selectedIndex;
                            setEtatRetourVisiteLabel(e.nativeEvent.target[index].text);
                          }}
                          required
                        >
                          {/* Cas 1 : Reviens à la date*/}
                          <option value={1}>
                            Je vais mieux et je pense être en mesure de revenir travailler à la date indiquée
                          </option>
                          {/* Cas 2 : Pas sur de revenir */}
                          {
                            <option value={2}>
                              Je pense que mon état de santé actuel va nécessiter une prolongation de mon arrêt de
                              travail
                            </option>
                          }
                          {/* Cas 3 : ne répond pas */}
                          {
                            <option value={3}>
                              Je ne suis pas capable de répondre à cette question pour l’instant
                            </option>
                          }
                        </select>
                      </label>
                    </form>
                    {/* {error && <div className="err">{error}</div>} */}
                    <div className="btn-link">
                      {/* <button type="button" className = {error ? "btn btn-kerij disabled" : "btn btn-kerij"} onClick={handleClick}>Valider mes réponses</button> */}
                      <button type="button" className={'btn btn-kerij'} onClick={handleClick}>
                        Valider mes réponses
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="container-fluid mt-5">
                    <h1 className="question-form mb-5">
                      {`Attention ! Vous n'avez pas
                                            l'autorisation d'accéder à cette
                                            page`}
                    </h1>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <NavbarMobile />
        <Footer />
      </div>
    </RequireLogin>
  );
}

export default QuestionnaireVisite;
