import { FormatDate } from '@/constants/date.constants';
import moment from 'moment';

export type ParcoursRuleValueData = number | string;
export type ParcoursRuleValueDate = string & { $date: true };
export type ParcoursRuleObject = {
  value: ParcoursRuleValueData;
  [key: string]: any;
};
export type ParcoursRuleDuration = {
  value: ParcoursRuleValueData;
  since: number;
  sinceUnit: 'years' | 'days';
};
export type ParcoursRuleArray = Array<ParcoursRuleValueData>;
export type ParcoursRuleValue =
  | ParcoursRuleValueData
  | ParcoursRuleValueDate
  | ParcoursRuleDuration
  | ParcoursRuleObject
  | ParcoursRuleArray;

export const isParcoursRuleValueData = (value: unknown): value is ParcoursRuleValueData =>
  typeof value === 'string' || typeof value === 'number';
export const isParcoursRuleValueDate = (value: unknown): value is ParcoursRuleValueDate =>
  typeof value === 'string' && dateRegex.test(value);
export const isParcoursRuleObject = (value: unknown): value is ParcoursRuleObject =>
  typeof value === 'object' && value != null && Object.hasOwn(value, 'value');
export const isParcoursRuleDuration = (value: unknown): value is ParcoursRuleDuration =>
  isParcoursRuleObject(value) && Object.hasOwn(value, 'since') && Object.hasOwn(value, 'sinceUnit');
export const isParcoursRuleArray = (value: unknown): value is ParcoursRuleArray =>
  Array.isArray(value) && (value.length === 0 || isParcoursRuleValueData(value[0]));
export const isParcoursRuleValue = (value: unknown): value is ParcoursRuleValue =>
  isParcoursRuleValueData(value) || isParcoursRuleObject(value) || isParcoursRuleArray(value);

export const extractRuleValue = (value: ParcoursRuleValue): { value: string; options?: object } | undefined => {
  if (isParcoursRuleArray(value)) return { value: value.map((value_) => `« ${value_} »`).join(' ou ') };
  if (isParcoursRuleDuration(value))
    return { value: `${value.value} depuis ${value.since} ${value.sinceUnit === 'days' ? 'jour(s)' : 'année(s)'}` };
  if (isParcoursRuleObject(value)) {
    const { value: objectValue, ...options } = value;
    return { value: `${objectValue}`, options: options };
  }
  if (isParcoursRuleValueDate(value)) return { value: `${moment(value).format(FormatDate.FRENCH_DATE)}` };
  if (isParcoursRuleValue(value)) return { value: `${value}` };
};

const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
