/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react';
import { statementStep } from '../constants/step.constants';
import { AbsenceCreation, StepProperties } from '../props/step.interface';
import { toSelect } from '../constants/absenceType.constant';
import { verifyEmployeeAbsenceMat } from '../services/verifyEmployeeAbsenceMat.service';
import { useError } from '@/shared/useError';
import { KerijBox, KerijBoxType } from '@/components/base';
import TitleProcess from './TitleProcess';
import { useAppFeature } from '@/store';
import { EmployeeCivility } from '@/types';

interface OptionType {
  absence: AbsenceCreation;
  infosEmployee: any;
  handleChangeTypeAbsence2: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChangeStep: (step: statementStep) => void;
}

const getOptionsType2 = ({ absence, infosEmployee, handleChangeTypeAbsence2, handleChangeStep }: OptionType) => {
  const concatAbsence = absence.absenceType + absence.absenceType1;
  let title,
    selections: {
      value: string;
      label: string;
    }[];
  switch (concatAbsence) {
    case '11': {
      title = "La maladie de l'employé a-t-elle un lien avec une des situations suivantes ?";
      selections = [
        {
          value: toSelect.toString(),
          label: 'Choisir votre option',
        },
        {
          value: '1',
          label: 'Aucun lien',
        },
        {
          value: '2',
          label: 'Accident du travail/Maladie professionnelle',
        },
        {
          value: '3',
          label: ' Accident impliquant un tiers',
        },
        {
          value: '5',
          label: 'Etat pathologique résultant de la grossesse',
        },
      ];
      if (infosEmployee?.civility !== EmployeeCivility.Female) {
        selections = selections.filter((selection) => selection.value !== '5');
      }
      break;
    }
    case '12': {
      title = "Quel est le type d'accident ?";
      selections = [
        {
          value: toSelect.toString(),
          label: 'Choisir votre option',
        },
        {
          value: '1',
          label: 'Accident de travail',
        },
        {
          value: '2',
          label: 'Accident de trajet',
        },
        {
          value: '3',
          label: 'Maladie professionnelle',
        },
      ];
      break;
    }
    case '13': {
      title = 'Sa maladie a-t-elle un lien avec une pathologie antérieure ou une maternité ?';
      selections = [
        {
          value: toSelect.toString(),
          label: 'Choisir votre option',
        },
        {
          value: '1',
          label: 'Aucun lien avec une pathologie antérieure',
        },
        {
          value: '2',
          label: 'Maternité / congé pathologique',
        },
        {
          value: '3',
          label: 'Maladie professionnelle',
        },
        {
          value: '4',
          label: '1ère catégorie invalidité',
        },
        {
          value: '5',
          label: 'Autre',
        },
      ];
      if (infosEmployee?.civility !== EmployeeCivility.Female) {
        selections = selections.filter((selection) => selection.value !== '2');
      }
      break;
    }
    case '31': {
      title = 'Combien a-t-elle eu de grossesse ? *';
      selections = [
        {
          value: toSelect.toString(),
          label: 'Choisir votre option',
        },
        {
          value: '1',
          label: "C'est ma 1ère ou 2ème grossesse",
        },
        {
          value: '2',
          label: "C'est ma 3ème grossesse ou plus",
        },
      ];
      break;
    }
    default: {
      title = '';
      selections = [];
    }
  }
  if (!title && selections.length === 0) {
    return <></>;
  }
  return (
    <div>
      <TitleProcess title={title} />
      <div className="form-declaration text-center col-lg-12">
        <div>
          <label className="col-8">
            <select
              className="form-select"
              name="absenceType2"
              value={absence.absenceType2 ?? ''}
              onChange={handleChangeTypeAbsence2}
              required
            >
              {selections.map((selection) => (
                <option key={selection.value} value={selection.value}>
                  {selection.label}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>
    </div>
  );
};

export const AbsenceType2 = ({ absence, setAbsence, handleChangeStep, infosEmployee }: StepProperties) => {
  const { error, triggerError, clearError } = useError();
  const featureVerifyEmployeeAbsenceMat = useAppFeature().VerifyEmployeeAbsenceMat;
  const handleChangeTypeAbsence2 = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    clearError();
    const inputValue = event.target.value;
    if (!infosEmployee) return;
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (inputValue) {
      case toSelect.toString(): {
        // @ts-ignore absenceType1 est un string
        setAbsence((absence: AbsenceCreation) => ({
          ...absence,
          absenceType2: toSelect,
          dateDebut: '',
          dateFin: '',
          period: [{ dateDebut: '', dateFin: '', error: -1 }],
        }));
        handleChangeStep(statementStep.TypeAbsence2);
        break;
      }
      default: {
        const concatAbsence = ((absence.absenceType as string) + absence.absenceType1) as string;
        if (concatAbsence === '11' && inputValue === '5') {
          const { isValid } = await verifyEmployeeAbsenceMat(
            {
              idEmployeeInformation: infosEmployee?.id,
              idBusiness: infosEmployee?.business?.id,
            },
            featureVerifyEmployeeAbsenceMat,
          );
          if (isValid) {
            setAbsence((absence: AbsenceCreation) => ({
              ...absence,
              absenceType2: inputValue,
            }));
            handleChangeStep(statementStep.Date);
          } else {
            triggerError(
              "Vous n'avez pas déclaré de congé matérnité. Veuillez prendre le temps de procéder à cette déclaration",
            );
          }
          break;
        } else {
          setAbsence((absence: AbsenceCreation) => ({
            ...absence,
            absenceType2: event.target.value,
            dateDebut: '',
            dateFin: '',
            period: [{ dateDebut: '', dateFin: '', error: -1 }],
          }));
          handleChangeStep(statementStep.Date);
          break;
        }
      }
    }
  };
  useEffect(() => {
    const concatAbsence = absence.absenceType + absence.absenceType1;
    if (!['11', '12', '13', '31'].includes(concatAbsence)) {
      handleChangeStep(statementStep.Date);
    }
    if (error) {
      handleChangeStep(statementStep.TypeAbsence2);
    }
  }, [error]);
  return (
    <>
      {getOptionsType2({
        absence,
        infosEmployee,
        handleChangeTypeAbsence2,
        handleChangeStep,
      })}
      {error ? <KerijBox type={KerijBoxType.Error} message={error} /> : <></>}
    </>
  );
};
