import { Reducer, combineReducers } from 'redux';
import { CollaborateursAction, CollaborateursActionTypes } from '../action/collaborateurs/type';

export interface IState {
  loading: boolean | undefined;
  retrieved: any;
  error: any;
}

export const initialState: IState = {
  loading: false,
  retrieved: null,
  error: null,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TODO: refactor avec RTK slice
export const employeesReducer: Reducer<IState> = (state: any = initialState, action: CollaborateursAction) => {
  switch (action.type) {
    case CollaborateursActionTypes.LIST_COLLABORATEURS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case CollaborateursActionTypes.LIST_COLLABORATEURS: {
      return {
        ...state,
        retrieved: action.payload,
      };
    }
    case CollaborateursActionTypes.LIST_COLLABORATEURS_SUCCESS: {
      return {
        ...state,
        retrieved: {
          ...state.retrieved,
          employees: [...state.retrieved.employees, ...action.payload.employees],
        },
      };
    }
    case CollaborateursActionTypes.LIST_COLLABORATEURS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default combineReducers({ employeesData: employeesReducer });
