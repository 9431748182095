import axios from '@/lib/axios';
import { Parcours } from '@/types/Parcours';

export const parcoursFindAll = async (params?: parcoursFindAll.Parameters) => {
  const response = await axios.get<parcoursFindAll.Return>(`/api/rh/parcours`, { params });
  // TODO: handle JSONAPI error here (using a helper)
  const responseBody = response.data;
  return responseBody.data;
};
export namespace parcoursFindAll {
  export interface Parameters {}

  export type Return = {
    data: Array<Parcours>;
    meta: {
      total: number;
    };
  };
}
