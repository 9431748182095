import React from 'react';
import { Box } from '@mui/material';
import { PermissionBox } from '@/components/PermissionBox';
import { ParcoursList } from '@/components/ParcoursList';
import { ParcoursQuery } from '@/query';
import { ActionButton } from '@/components/ActionButton';
import { ParcoursCreateDialog } from '@/components/ParcoursCreateDialog';
import { useMUIDialogController } from '@/hooks/useMUIDialogController';
import { IfPermission } from '@/components/IfPermission';
import { RHPage } from '@/components/RHPage';

export const ParcoursListPage: React.FC = () => {
  const query = ParcoursQuery.useIndex();
  const parcoursCreateController = useMUIDialogController();

  return (
    <PermissionBox scope="management" action="access">
      <RHPage
        title="Parcours"
        actions={
          <IfPermission scope="management" action="edit">
            <Box>
              <ActionButton
                actionName="add"
                onClick={() => {
                  parcoursCreateController.control.setOpen(true);
                }}
              />
              <ParcoursCreateDialog {...parcoursCreateController.getRootProps()} />
            </Box>
          </IfPermission>
        }
      >
        <ParcoursList items={query.data} onRefresh={query.refetch} />
      </RHPage>
    </PermissionBox>
  );
};
