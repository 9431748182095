import * as React from 'react';
import classNames from 'clsx';
import { Avatar, Box, Typography, styled } from '@mui/material';
import { ParcoursRule as ParcoursRuleType, extractRule } from '@/types';

const componentName = 'ParcoursRule';

export interface ParcoursRuleProps extends React.ComponentProps<'div'> {
  value: ParcoursRuleType;
  index?: number;
}

export function ParcoursRule(props: ParcoursRuleProps) {
  const { value, index, ...divProps } = props;

  const extractedRule = extractRule(value);

  const AvatarRule = styled(Avatar)(({ theme }) => ({
    height: 24,
    width: 24,
    fontSize: 16,
    bgcolor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  }));

  return (
    <div className={classNames(componentName)} {...divProps}>
      {extractedRule.success === false && <Typography color="error">{extractedRule.error}</Typography>}

      {extractedRule.success === true && (
        <Box fontWeight="bold" display="flex" mb={1}>
          {index != null && <AvatarRule>{index + 1}</AvatarRule>}

          <Typography ml={1}>
            <b>{extractedRule.rule.key} </b>
            {extractedRule.rule.operator} {extractedRule.rule.value}
            {extractedRule.rule.options && Object.keys(extractedRule.rule.options).length > 0 && (
              <>
                {extractedRule.rule.operator} {extractedRule.rule.value}{' '}
                <AvatarRule>{JSON.stringify(extractedRule.rule.options, undefined, 2)}</AvatarRule>
              </>
            )}
          </Typography>
        </Box>
      )}
    </div>
  );
}
