import type { ParcoursAction } from './ParcoursAction';
import type { ParcoursScript } from './ParcoursScript';

export interface Parcours {
  id: string;
  legal: boolean;
  name: string | undefined;
  updated_at: string;
  script: ParcoursScript;
  action: Record<string, ParcoursAction>;
}
