import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '@/components/DashboardRH/DashboardRH.css';
import './Admin.css';
import ImgUser from '../../../assets/icon-user.svg';
import ImgFiles from '../../../assets/icon-files.svg';
import ImgReglesGenerales from '../../../assets/icon-regles-generales.svg';
import ImgParametres from '../../../assets/icon-settings.svg';
import ImgServices from '../../../assets/icon-services.svg';
import isAuthorized from '../../../hooks/isAuthorized';
import { PRO_URL } from '../../../constants/urls.constants';
import { NotConnectedRH } from '../../../components/DashboardRH/shared/components/NotConnectedRH';
import { RHAccountContext, useAppSelector } from '@/store';

const Admin = () => {
  const isAdmin = useAppSelector(RHAccountContext.isAdmin);
  const location = useLocation();
  const services = [
    {
      img: ImgUser,
      text: 'Utilisateurs',
      url: '/pro/admin/users',
    },
    {
      img: ImgFiles,
      text: 'Fichiers',
      url: '/pro/admin/files',
    },
    {
      img: ImgParametres,
      text: 'Paramètres',
      url: '/pro/admin/settings',
    },
    {
      text: 'Correspondance',
      img: ImgReglesGenerales,
      url: '/pro/admin/corresp_table',
    },
    {
      img: ImgServices,
      text: 'Services',
      url: '/pro/admin/admin_service',
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized(location.pathname)) {
      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN);
    }
  }, []);

  return (
    <div>
      {isAdmin ? (
        <div className="container-fluid mt-5">
          <h1 className="titre-rh mb-5 col-lg-8 text-left ">Administration</h1>
          <div className="container-fluid admin-content col-lg-8 col-8">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 col-12">
              {services.map((value, index) => {
                return (
                  <div className="col mb-2" key={index}>
                    <Link to={value.url}>
                      <div className="card card-admin h-100 text-center">
                        <div className="text-center mt-3 mb-3">
                          <div className="circle-bg">
                            <img alt="kerij" className="mt-4" src={value.img} />
                          </div>
                        </div>
                        <span className="title-admin">{value.text}</span>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <NotConnectedRH />
      )}
    </div>
  );
};

export default Admin;
