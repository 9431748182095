// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kerij-button {
  border: none;
  font-size: inherit;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  background: #40c9c9;
  border-radius: 10px;
  color: #0e0653;
  font-weight: bolder;
  padding: 1rem;
  margin: 0.5em auto 0;
}
.kerij-button:hover {
  color: #ffffff;
}
.kerij-button.disabled {
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  color: #0e0653;
  cursor: not-allowed;
}
.kerij-button.disabled:hover {
  background-color: #c4c4c4;
}`, "",{"version":3,"sources":["webpack://./src/components/base/button/kerijButton.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,eAAA;EACA,sCAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,mBAAA;EACA,aAAA;EACA,oBAAA;AACF;AACE;EACE,cAAA;AACJ;AAEE;EACE,yBAAA;EACA,yBAAA;EACA,cAAA;EACA,mBAAA;AAAJ;AAEI;EACE,yBAAA;AAAN","sourcesContent":[".kerij-button {\n  border: none;\n  font-size: inherit;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  box-sizing: border-box;\n  background: #40c9c9;\n  border-radius: 10px;\n  color: #0e0653;\n  font-weight: bolder;\n  padding: 1rem;\n  margin: 0.5em auto 0;\n\n  &:hover {\n    color: #ffffff;\n  }\n\n  &.disabled {\n    background-color: #ffffff;\n    border: 1px solid #c4c4c4;\n    color: #0e0653;\n    cursor: not-allowed;\n\n    &:hover {\n      background-color: #c4c4c4;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
