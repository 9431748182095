import React, { useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

const ChangePasswordModalInput = ({ label, name, rules, control, handleChange }: any) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      render={({
        field: { onChange, value, ...rest },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <div className="input-group">
          <label>{label}</label>
          <div className="label-password">
            <input
              type={showPassword ? 'text' : 'password'}
              className="input-password"
              onChange={(e) => {
                onChange(e);
                handleChange && handleChange(e);
              }}
              {...rest}
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              className="icon-show-password"
              onClick={() => setShowPassword(!showPassword)}
            ></FontAwesomeIcon>
          </div>
          {error && <Typography style={{ color: 'red', marginLeft: '10px' }}>{error.message}</Typography>}
        </div>
      )}
      name={name}
      control={control}
      rules={rules}
    />
  );
};

export default ChangePasswordModalInput;
