import * as React from 'react';
import {
  Toolbar as ToolbarDefault,
  type ToolbarProps,
  ToggleButtonGroup as ToggleButtonGroupDefault,
  Typography,
  Stack,
  styled,
} from '@mui/material';
import { MarkButton } from './MUIToolbar/MarkButton';
import { BlockButton } from './MUIToolbar/BlockButton';
import { LinkButton } from './MUIToolbar/LinkButton';
import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import FormatStrikethrough from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenter from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRight from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustify from '@mui/icons-material/FormatAlignJustify';
import FormatQuote from '@mui/icons-material/FormatQuote';
import Title from '@mui/icons-material/Title';
import Code from '@mui/icons-material/Code';

const Toolbar = styled(ToolbarDefault)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
}));
const ToggleButtonGroup = styled(ToggleButtonGroupDefault)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
ToggleButtonGroup.defaultProps = {
  color: 'secondary',
};

const renderHeader = (label: string) => (
  <Typography variant="button" sx={{ fontWeight: 'bold', minWidth: '1.75em' }}>
    {label}
  </Typography>
);

export interface MUIToolbarProps extends ToolbarProps {}

/**
 * Toolbar that appears on the top of the editor.
 *
 * It accepts any content as children. If no children are set it displays by default the following buttons:
 * Bold, italic, underline, strike through, code, bulleted list and numbered list
 */
export function MUIToolbar({ children, ...props }: MUIToolbarProps) {
  return (
    <Toolbar {...props}>
      <Stack direction="row" spacing={2}>
        <ToggleButtonGroup>
          <MarkButton icon={<FormatBold />} mark="bold" tooltip="Gras" />
          <MarkButton icon={<FormatItalic />} mark="italic" tooltip="Italique" />
          <MarkButton icon={<FormatStrikethrough />} mark="strikethrough" tooltip="Rayé" />
          <MarkButton icon={<FormatUnderlined />} mark="underline" tooltip="Souligné" />
          {/* <MarkButton icon={<Code />} mark="code" tooltip="Code" /> */}
        </ToggleButtonGroup>
        <ToggleButtonGroup>
          <BlockButton block="heading-1" icon={renderHeader('H1')} tooltip="En tête 1" />
          <BlockButton block="heading-2" icon={renderHeader('H2')} tooltip="En tête 2" />
          <BlockButton block="heading-3" icon={renderHeader('H3')} tooltip="En tête 3" />
          {/* <BlockButton block="block-quote" icon={<FormatQuote />} tooltip="Bloc de citation" /> */}
          {/* <LinkButton tooltip="Lien" /> */}
        </ToggleButtonGroup>
        <ToggleButtonGroup>
          <BlockButton block="bulleted-list" icon={<FormatListBulleted />} tooltip="Liste à puce" />
          <BlockButton block="numbered-list" icon={<FormatListNumbered />} tooltip="Liste numérotée" />
        </ToggleButtonGroup>
      </Stack>

      {/* <ToggleButtonGroup>
        <BlockButton block="left" icon={<FormatAlignLeft />} tooltip="Aligné à gauche" />
        <BlockButton block="center" icon={<FormatAlignCenter />} tooltip="Aligné au centre" />
        <BlockButton block="right" icon={<FormatAlignRight />} tooltip="Aligné à droite" />
        <BlockButton block="justify" icon={<FormatAlignJustify />} tooltip="Justifié" />
      </ToggleButtonGroup> */}
      {children}
    </Toolbar>
  );
}
