import React, { ChangeEvent, useEffect, useState } from 'react';
import '@/components/DashboardRH/DashboardRH.css';
import { Spacer } from '@/components/Spacer';
import { AppSelect, AppSelectMultiple } from '@/components/AppSelect';
import {
  Service,
  ServiceAdder,
  ServiceAdderLabel,
  ServiceDocumentType,
  ServiceEligibility,
  ServiceEligibilityLabel,
  ServiceFinance,
  ServiceFinanceLabel,
  ServiceReference,
  ServiceReferenceType,
  ServiceTheme,
  ServiceThemeLabel,
  convertServiceToSelectItem,
} from '@/types';
import { AppTextField } from '@/components/AppTextField';
import { InputAdornment } from '@mui/material';
import { AppTooltipInfo } from '@/components/AppTooltipInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import axios from '@/lib/axios';
import { useToast } from '../Toast';

const helperText = () => (
  <>
    <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '0.5rem' }} />
    {`L'ID/Nº de client, le mot de passe et/ou le code promo sont les informations à utiliser par le salarié pour s'identifier auprès d'un service.\nLa référence que vous renseignez peut être un identifiant générique ou un texte qui permettra au salarié de réunir les informations utiles.`}
    <div className="mt-2">
      <div>Exemple : A875972K</div>
    </div>
  </>
);

export type ServiceFormModel = Omit<Service, 'id' | 'id_business' | 'categories'> & {
  categories: string[];
  subcategories: string[];
};
export type ServiceFormDocument = Record<ServiceDocumentType, File | null>;

export interface ServiceFormValue {
  service: ServiceFormModel;
  documents?: ServiceFormDocument;
}

export interface ServiceFormProps {
  value?: ServiceFormValue;
  onChange?: (value: Partial<ServiceFormModel>) => void;
  onSubmit: (value: ServiceFormValue) => Promise<void>;
  onSubmitted: () => void;
  submit?: boolean;
}

export function ServiceForm(props: ServiceFormProps) {
  const toast = useToast();
  const { value, submit, onChange, onSubmit, onSubmitted } = props;

  const [isSubmit, setIsSubmit] = useState(false);
  const [categories, setCategories] = useState<ServiceReference[]>([]);
  const [subcategories, setSubcategories] = useState<ServiceReference[]>([]);

  const [documents, setDocuments] = useState<ServiceFormDocument>({
    logo: null,
    referential: null,
    ...value?.documents,
  });
  const [model, setModel] = useState<ServiceFormModel>({
    title: '',
    description: '',
    short_description: '',
    themes: [],
    categories: [],
    subcategories: [],
    eligibilities: [],
    ...value?.service,
  });

  function onChangeValue<K extends keyof ServiceFormModel>(key: K, value?: ServiceFormModel[K]) {
    setModel((data) => {
      const newValue = { ...data, [`${key}`]: value };
      onChange?.(newValue);
      return newValue;
    });
  }

  const changeImage = (event: ChangeEvent<HTMLInputElement>) => {
    const allowedTypes = new Set(['image/png', 'image/jpeg']);
    const selectedFile = event.target.files?.[0] ?? null;
    setDocuments((data) => ({
      ...data,
      logo: selectedFile && allowedTypes.has(selectedFile.type) ? selectedFile : null,
    }));
  };

  const changeReferential = (event: ChangeEvent<HTMLInputElement>) => {
    const allowedTypes = new Set(['application/pdf']);
    const selectedFile = event.target.files?.[0] ?? null;
    setDocuments((data) => ({
      ...data,
      referential: selectedFile && allowedTypes.has(selectedFile.type) ? selectedFile : null,
    }));
  };

  const isEmpty = (value?: string) => value == null || value.trim() === '';
  const isEmptyArray = (value?: string[]) => value == null || value.length === 0;

  const isValideForm = (value: any): value is ServiceFormModel => {
    const emptyCategories =
      isEmptyArray(model.themes) || isEmptyArray(model.categories) || isEmptyArray(model.subcategories);
    const emptyInfos = isEmpty(model.title) || isEmpty(model.provider) || isEmpty(model.adder);
    const emptyDescription =
      isEmpty(model.description) || isEmpty(model.short_description) || isEmptyArray(model.eligibilities);
    return !emptyCategories && !emptyInfos && !emptyDescription;
  };

  const handleSubmit = async () => {
    setIsSubmit(true);

    if (isValideForm(model)) await onSubmit({ service: model, documents });
    else toast.present({ message: "Vous n'avez pas rempli tous les champs requis", severity: 'error' });
    onSubmitted();
  };

  async function getFormDatas() {
    const { data: categoriesValues } = await axios.get<ServiceReference[]>(`/api/rh/services/categories`, {
      params: { type: ServiceReferenceType.Category },
    });
    setCategories(categoriesValues);
    const { data: subcategoriesValues } = await axios.get<ServiceReference[]>(`/api/rh/services/categories`, {
      params: { type: ServiceReferenceType.Subcategory },
    });
    setSubcategories(subcategoriesValues);
  }

  useEffect(() => {
    getFormDatas();
  }, []);

  useEffect(() => {
    if (submit === true) handleSubmit();
  }, [submit]);

  return (
    <form style={{ padding: 0, width: '100%' }}>
      <div>
        <em style={{ color: 'red' }}>* Champs requis</em>
      </div>
      <Spacer />

      <div className="row">
        {/* Nom */}
        <div className="col-12 col-sm-4">
          <AppTextField
            label={'Nom du service'}
            value={model.title}
            error={isEmpty(model.title) && isSubmit}
            onChange={(_, value) => onChangeValue('title', value)}
            required
          />
        </div>

        {/* Prestataire */}
        <div className="col-12 col-sm-4">
          <AppTextField
            label={'Nom du prestataire'}
            value={model.provider}
            error={isEmpty(model.provider) && isSubmit}
            onChange={(_, value) => onChangeValue('provider', value)}
            required
          />
        </div>

        {/* Image */}
        <div className="col-12 col-sm-4">
          <AppTextField
            label="Image / Logo"
            InputProps={{
              inputProps: {
                type: 'file',
                accept: '.png,.jpeg,.jpg',
                style: { height: 'auto' },
              },
            }}
            onChange={changeImage}
            helperText="Uniquement au format JPEG ou PNG"
          />
        </div>
      </div>

      <Spacer />

      <div className="row">
        {/* Thématiques */}
        <div className="col-12 col-sm-4">
          <AppSelectMultiple
            items={convertServiceToSelectItem(ServiceTheme, ServiceThemeLabel)}
            label="Thématiques"
            value={model.themes}
            onChange={(_, value) => onChangeValue('themes', value as ServiceTheme[] | undefined)}
            required
            error={model.themes?.length === 0 && isSubmit}
          />
        </div>

        {/* Categorie */}
        <div className="col-12 col-sm-4">
          <AppSelectMultiple
            items={categories.map((category) => ({ value: category.id, label: category.label }))}
            label="Catégorie de service"
            value={model.categories}
            onChange={(_, value) => onChangeValue('categories', value)}
            required
            error={model.categories?.length === 0 && isSubmit}
          />
        </div>

        {/* Sous-catégorie */}
        <div className="col-12 col-sm-4">
          <AppSelectMultiple
            items={subcategories.map((subcategory) => ({ value: subcategory.id, label: subcategory.label }))}
            label="Sous-catégorie de service"
            value={model.subcategories}
            onChange={(_, value) => onChangeValue('subcategories', value)}
            required
            error={model.subcategories?.length === 0 && isSubmit}
          />
        </div>
      </div>

      <Spacer />

      <div className="row">
        {/* Proposé par */}
        <div className="col-12 col-sm-4">
          <AppSelect
            items={convertServiceToSelectItem(ServiceAdder, ServiceAdderLabel)}
            label="Un service proposé par"
            value={model.adder}
            onChange={(_, value) => onChangeValue('adder', value as ServiceAdder | undefined)}
            required
            error={isEmpty(model.adder) && isSubmit}
          />
        </div>

        {/* Mode financement */}
        <div className="col-12 col-sm-4">
          <AppSelect
            items={[{ value: '', label: 'N/A' }, ...convertServiceToSelectItem(ServiceFinance, ServiceFinanceLabel)]}
            label="Mode de financement"
            value={model.funding}
            onChange={(_, value) => onChangeValue('funding', value as ServiceFinance | undefined)}
            // error={isEmpty(model.funding) && isSubmit}
          />
        </div>

        {/* Éligibilité */}
        <div className="col-12 col-sm-4">
          <AppSelectMultiple
            items={convertServiceToSelectItem(ServiceEligibility, ServiceEligibilityLabel)}
            label="Éligibilité au service"
            value={model.eligibilities}
            onChange={(_, value) => onChangeValue('eligibilities', value as ServiceEligibility[] | undefined)}
            required
            error={model.eligibilities?.length === 0 && isSubmit}
          />
        </div>
      </div>

      <Spacer />

      <div className="row">
        {/* Description */}
        <div className="col-12">
          <AppTextField
            label={'Description générale'}
            value={model.short_description}
            error={isEmpty(model.short_description) && isSubmit}
            onChange={(_, value) => onChangeValue('short_description', value)}
            helperText="50 caractères maximum"
            InputProps={{ inputProps: { maxLength: 50 } }}
            required
          />
        </div>
      </div>

      <Spacer />

      <div className="row">
        {/* Détail */}
        <div className="col-12">
          <AppTextField
            label={'Description détaillée'}
            value={model.description}
            error={isEmpty(model.description) && isSubmit}
            onChange={(_, value) => onChangeValue('description', value)}
            InputProps={{
              multiline: true,
              rows: 2,
              maxRows: 4,
              inputProps: { maxLength: 350 },
            }}
            helperText="350 caractères maximum"
            required
          />
        </div>
      </div>

      <Spacer />

      <div className="row">
        {/* Url */}
        <div className="col-12 col-sm-6">
          <AppTextField
            label={'URL site web'}
            value={model.website}
            onChange={(_, value) => onChangeValue('website', value)}
            InputProps={{ inputProps: { type: 'url' } }}
          />
        </div>
        {/*

      {/* Reference documentaire */}
        <div className="col-12 col-sm-6">
          <AppTextField
            label="Référence documentaire"
            InputProps={{
              inputProps: {
                type: 'file',
                accept: '.pdf',
                style: { height: 'auto' },
              },
            }}
            onChange={changeReferential}
            helperText="Uniquement au format PDF"
          />
        </div>
      </div>

      <div className="row">
        {/* Identifiant */}
        <div className="col-12 col-sm-4">
          <AppTextField
            label={'Identifiant'}
            value={model.identifiant}
            onChange={(_, value) => onChangeValue('identifiant', value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AppTooltipInfo title={helperText()} />
                </InputAdornment>
              ),
            }}
          />
        </div>

        {/* Password */}
        <div className="col-12 col-sm-4">
          <AppTextField
            label={'Mot de passe'}
            value={model.password}
            onChange={(_, value) => onChangeValue('password', value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AppTooltipInfo title={helperText()} />
                </InputAdornment>
              ),
            }}
          />
        </div>

        {/* Code promo */}
        <div className="col-12 col-sm-4">
          <AppTextField
            label={'Code promo'}
            value={model.promo}
            onChange={(_, value) => onChangeValue('promo', value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AppTooltipInfo title={helperText()} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <Spacer />

      <div className="row">
        {/* Téléphone */}
        <div className="col-12 col-sm-4">
          <AppTextField
            label={'Téléphone'}
            value={model.phone}
            onChange={(_, value) => onChangeValue('phone', value)}
            InputProps={{ inputProps: { type: 'tel', pattern: '^0[1-9](d{2}){4}$', maxLength: 10 } }}
          />
        </div>

        {/* Email */}
        <div className="col-12 col-sm-4">
          <AppTextField
            label={'Email'}
            value={model.email}
            onChange={(_, value) => onChangeValue('email', value)}
            InputProps={{ inputProps: { type: 'email' } }}
          />
        </div>
      </div>

      <Spacer />

      <div className="row">
        {/* Adresse 1 */}
        <div className="col-12 col-sm-4">
          <AppTextField
            label={'Adresse 1'}
            value={model.address}
            onChange={(_, value) => onChangeValue('address', value)}
          />
        </div>

        {/* Adresse 2 */}
        <div className="col-12 col-sm-4">
          <AppTextField
            label={'Adresse 2'}
            value={model.address_complement}
            onChange={(_, value) => onChangeValue('address_complement', value)}
          />
        </div>
      </div>

      <Spacer />

      <div className="row">
        {/* Code postal */}
        <div className="col-12 col-sm-4">
          <AppTextField
            label={'Code postal'}
            value={model.zipcode}
            onChange={(_, value) => onChangeValue('zipcode', value)}
          />
        </div>

        {/* Ville */}
        <div className="form-group col-12 col-sm-4">
          <AppTextField label={'Ville'} value={model.city} onChange={(_, value) => onChangeValue('city', value)} />
        </div>
      </div>
    </form>
  );
}
