// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*  FIXME: Mieux définir la typo */
h1 {
  font-size: 2rem;
  color: #342f6c;
  font-weight: bold;
}

.hide-scroll {
  -ms-overflow-style: none; /* IE et Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scroll::-webkit-scrollbar {
  display: none; /* Webkit (Chrome, Safari) */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,wBAAwB,EAAE,eAAe;EACzC,qBAAqB,EAAE,YAAY;AACrC;;AAEA;EACE,aAAa,EAAE,4BAA4B;AAC7C","sourcesContent":["/*  FIXME: Mieux définir la typo */\nh1 {\n  font-size: 2rem;\n  color: #342f6c;\n  font-weight: bold;\n}\n\n.hide-scroll {\n  -ms-overflow-style: none; /* IE et Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n.hide-scroll::-webkit-scrollbar {\n  display: none; /* Webkit (Chrome, Safari) */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
