import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Rdv.css';
import './Modal.css';
import ImgRdvEntretienLiaison from '../../../../../assets/img-declaration-services.svg';
import ImgRdvEntretienRetour from '../../../../../assets/img-rdv-managerial.svg';
import ImgRdvMedical from '../../../../../assets/img-rdv-visite.svg';
import ImgRdvMedicalReprise from '../../../../../assets/img-visite-prereprise.svg';
import Modal from 'react-modal';
import { StatusCodes } from 'http-status-codes';
import { employeeRequestInterview } from '../../../../../services/employee';
import { RHAccountContext, useAppDispatch } from '@/store';

function RdvUI() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [modalIsOpenLiaison, setModalIsOpenLiaison] = useState(false);

  const [modalIsOpenRetour, setModalIsOpenRetour] = useState(false);

  const [modalIsOpenVisite, setModalIsOpenVisite] = useState(false);

  const [modalIsOpenReprise, setModalIsOpenReprise] = useState(false);

  const [entretienLiaison, setEntretienLiaison] = useState('1');
  // const [entretienLiaisonLabel, setEntretienLiaisonLabel] = useState("Je souhaite bénéficier d’un entretien de liaison en présentiel")
  const [entretienLiaisonPersonne, setEntretienLiaisonPersonne] = useState('1');
  // const [entretienLiaisonLabelPersonne, setEntretienLiaisonLabelPersonne] = useState("Mon manager")

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem('access_token_employee')) {
      navigate('/rdvlogin');
    }
  }, []);

  const customStyles = {
    content: {
      border: '0',
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      left: '50%',
      padding: '2rem',
      position: 'fixed',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '20rem',
      width: '80%',
      maxWidth: '60rem',
    },
  };

  return (
    <div>
      <div>
        <div className="container-fluid mt-5">
          <h1 className="question-form text-center">Quel type de rendez-vous désirez-vous ?</h1>

          <div className="row row-cols-1 row-cols-lg-2 row-cols-xxl-4 g-4 mt-5">
            <div className="col car-container">
              <div className="card card-rdv h-100">
                <img alt="kerij" className="mb-3 img-rdv" src={ImgRdvEntretienLiaison} />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title card-title-rdv">Planifier mon entretien de liaison</h5>
                  <p className="card-text">L’entretien de liaison a été créé par la loi du 2 août 2021.</p>
                  <p>
                    Cet entretien est obligatoirement proposé par votre employeur dès que vous cumulez 30 jours d’arrêt
                    de travail au cours des 12 derniers mois mais il peut aussi être sollicité directement par vous, si
                    vous considérez qu’un élément de votre vie personnelle et/ou professionnelle pourrait entraver votre
                    activité et que vous souhaitez envisager des solutions avec votre employeur.
                  </p>
                  <p>
                    Cet entretien, qui n’est pas un RDV médical, est un moment privilégié pendant votre arrêt de travail
                    pour maintenir le lien avec votre entreprise.
                  </p>
                  <button
                    className="btn btn-kerij-bleu mt-3 col-12 mt-auto"
                    onClick={() => {
                      setModalIsOpenLiaison(true);
                    }}
                  >
                    Je fais une demande{' '}
                  </button>
                </div>
              </div>
            </div>
            <div className="col car-container">
              <div className="card card-rdv h-100">
                <img alt="kerij" className="mb-3 img-rdv" src={ImgRdvEntretienRetour} />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title card-title-rdv">Planifier mon entretien de retour à l’emploi</h5>
                  <p className="card-text">
                    C’est un moment privilégié entre vous et votre manager pour vous permettre de revenir à votre poste
                    dans les meilleures conditions.{' '}
                  </p>
                  <p>
                    Cet entretien permettra à votre manager de vous décrire la manière dont les équipes se sont
                    organisées pendant votre absence, les actions qui ont été mises en place et la manière dont vous
                    allez reprendre vos activités. Plus largement, il pourra faire un point sur les évolutions récentes
                    de l’entreprise.{' '}
                  </p>
                  <p>
                    C’est aussi un moment privilégié pour partager vos doutes, vos besoins d’accompagnement, de
                    formation ou tout autre aspect de votre vie personnelle/professionnelle qui pourraient avoir un
                    impact sur votre activité au sein de l’entreprise et que vous souhaitez partager de manière à ce que
                    les solutions requises puissent être mobilisées.
                  </p>
                  <button
                    className="btn btn-kerij-bleu mt-3 col-12 mt-auto"
                    onClick={() => {
                      setModalIsOpenRetour(true);
                    }}
                  >
                    Je fais une demande{' '}
                  </button>
                </div>
              </div>
            </div>
            <div className="col car-container">
              <div className="card card-rdv h-100">
                <img alt="kerij" className="mb-3 img-rdv" src={ImgRdvMedical} />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title card-title-rdv">Planifier une visite médicale de santé au travail</h5>
                  <p className="card-text">
                    Vous pouvez à tout moment solliciter un RDV avec le médecin du travail si vous estimez que votre
                    activité professionnelle fait peser un risque sur votre santé. {`L'infirmière`} ou le médecin du
                    travail qui vous recevra vérifiera l’adaptation de votre poste de travail à votre état de santé. Il
                    vous informera alors des risques d’exposition à votre poste et vous indiquera les moyens de
                    prévention et de surveillance à mettre en place pour préserver votre santé.
                  </p>
                  <button
                    className="btn btn-kerij-bleu mt-3 col-12 mt-auto"
                    onClick={() => {
                      setModalIsOpenVisite(true);
                    }}
                  >
                    Je fais une demande{' '}
                  </button>
                </div>
              </div>
            </div>
            <div className="col car-container">
              <div className="card card-rdv h-100">
                <img alt="kerij" className="mb-3 img-rdv" src={ImgRdvMedicalReprise} />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title card-title-rdv">Planifier une visite médicale de Pré-reprise</h5>
                  <p className="card-text">
                    C’est une visite médicale pendant votre arrêt de travail qui peut être sollicitée par vous ou par
                    votre employeur. Elle est basée sur le volontariat et vous permet de préparer la reprise du travail
                    en échangeant sur votre situation et en identifiant vos besoins éventuels.
                  </p>
                  <button
                    className="btn btn-kerij-bleu mt-3 col-12 mt-auto"
                    onClick={() => {
                      setModalIsOpenReprise(true);
                    }}
                  >
                    Je fais une demande{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        style={customStyles as any}
        className="Modal"
        overlayClassName="Overlay"
        // className="modal-document"
        closeTimeoutMS={200}
        isOpen={modalIsOpenLiaison}
        onRequestClose={() => {
          setModalIsOpenLiaison(!modalIsOpenLiaison);
        }}
        ariaHideApp={false}
      >
        <div className="float-left">
          <button
            onClick={() => {
              setModalIsOpenLiaison(!modalIsOpenLiaison);
            }}
            className="btn-close exit-modal"
          ></button>
        </div>
        <h3 className="question-form text-center titre-modal">Attention</h3>
        <div className="text-center text-modal">
          En effectuant cette demande, vous informez votre entreprise que vous désirez un entretien de liaison
        </div>
        <div className="text-center mb-2 text-modal">Vous souhaitez {`l'entretien`} : </div>
        <label className="col-12 col-xl-10 mb-3 offset-xl-1">
          <select
            className="form-select text-modal-select short-text"
            value={entretienLiaison}
            onChange={(e) => {
              setEntretienLiaison(e.target.value);
              // const index = e.nativeEvent.target.selectedIndex
              // setEntretienLiaisonLabel(e.nativeEvent.target[index].text)
            }}
            required
          >
            <option value={1}>Je souhaite bénéficier d’un entretien de liaison en présentiel</option>
            <option value={2}>Je souhaite bénéficier d’un entretien de liaison en distanciel</option>
          </select>
        </label>
        <div className="text-center mb-2 text-modal">Je souhaite passer mon entretien avec : </div>
        {entretienLiaison !== '' && (
          <label className="col-12 col-xl-10 mb-3 offset-xl-1">
            <select
              className="form-select text-modal-select"
              name="entretienLiaison"
              value={entretienLiaisonPersonne}
              onChange={(e) => {
                setEntretienLiaisonPersonne(e.target.value);
                // const index = e.nativeEvent.target.selectedIndex
                // setEntretienLiaisonLabelPersonne(e.nativeEvent.target[index].text)
              }}
              required
            >
              <option value={1}>Mon manager</option>
              <option value={2}>Mon partenaire RH</option>
            </select>
          </label>
        )}
        <div className="text-center">
          <button
            value={1}
            className="btn btn-kerij-bleu btn-modal col-6"
            onClick={async () => {
              await employeeRequestInterview({
                typeNotification: 'Entretien de liaison',
                responseIndex: entretienLiaison,
                responsePersonIndex: entretienLiaisonPersonne,
              }).then((value) => {
                if (value.status === StatusCodes.OK) {
                  navigate('/validatedemanderdv');
                }
              });
            }}
          >
            {' '}
            Valider
          </button>
        </div>
      </Modal>

      <Modal
        style={customStyles as any}
        className="Modal"
        overlayClassName="Overlay"
        // className="modal-document"
        closeTimeoutMS={200}
        isOpen={modalIsOpenRetour}
        onRequestClose={() => {
          setModalIsOpenRetour(!modalIsOpenRetour);
        }}
        ariaHideApp={false}
      >
        <div className="float-right">
          <button
            onClick={() => {
              setModalIsOpenRetour(!modalIsOpenRetour);
            }}
            className="btn-close exit-modal"
          ></button>
        </div>
        <h3 className="question-form text-center titre-modal">Attention</h3>
        <div className="text-center text-modal">
          En effectuant cette demande, vous informez votre entreprise que vous désirez un entretien de retour à{' '}
          {`l'emploi`}
        </div>
        <div className="text-center mb-4 text-modal">Etes-vous sur de votre choix ?</div>
        <label className="col-12 col-xl-10 mb-3 offset-xl-1">
          <select
            className="form-select text-modal-select short-text"
            value={entretienLiaison}
            onChange={(e) => {
              setEntretienLiaison(e.target.value);
              // const index = e.nativeEvent.target.selectedIndex
              // setEntretienLiaisonLabel(e.nativeEvent.target[index].text)
            }}
            required
          >
            <option value={1}>
              {`Je souhaite bénéficier d’un entretien de retour à
                            l'emploi en présentiel`}
            </option>
            <option value={2}>
              {`Je souhaite bénéficier d’un entretien de retour à
                            l'emploi en distanciel`}
            </option>
          </select>
        </label>
        <div className="text-center mb-2 text-modal">Je souhaite passer mon entretien avec : </div>
        {entretienLiaison !== '' && (
          <label className="col-12 col-xl-10 mb-3 offset-xl-1">
            <select
              className="form-select text-modal-select"
              name="entretienLiaison"
              value={entretienLiaisonPersonne}
              onChange={(e) => {
                setEntretienLiaisonPersonne(e.target.value);
                // const index = e.nativeEvent.target.selectedIndex
                // setEntretienLiaisonLabelPersonne(e.nativeEvent.target[index].text)
              }}
              required
            >
              <option value={1}>Mon manager</option>
              <option value={2}>Mon partenaire RH</option>
            </select>
          </label>
        )}
        <div className="text-center">
          <button
            value={2}
            className="btn btn-kerij-bleu btn-modal col-6"
            onClick={async () => {
              await employeeRequestInterview({
                typeNotification: "Entretien de retour a l'emploi",
                responseIndex: entretienLiaison,
                responsePersonIndex: entretienLiaisonPersonne,
              }).then((value) => {
                if (value.status === StatusCodes.OK) {
                  navigate('/validatedemanderdv');
                }
              });
            }}
          >
            {' '}
            Valider
          </button>
        </div>
      </Modal>
      <Modal
        style={customStyles as any}
        className="Modal"
        overlayClassName="Overlay"
        // className="modal-document"
        closeTimeoutMS={200}
        isOpen={modalIsOpenVisite}
        onRequestClose={() => {
          setModalIsOpenVisite(!modalIsOpenVisite);
        }}
        ariaHideApp={false}
      >
        <div className="float-left">
          <button
            onClick={() => {
              setModalIsOpenVisite(!modalIsOpenVisite);
            }}
            className="btn-close exit-modal"
          ></button>
        </div>
        <h3 className="question-form text-center titre-modal">Attention</h3>
        <div className="text-center text-modal">
          En effectuant cette demande, vous informez votre entreprise que vous désirez une visite médicale
        </div>
        <div className="text-center mb-4 text-modal">Etes-vous sur de votre choix ?</div>
        <div className="text-center">
          <button
            value={3}
            className="btn btn-kerij-bleu btn-modal col-6"
            onClick={async () => {
              await employeeRequestInterview({
                typeNotification: 'Visite medicale de sante au travail',
                responseIndex: entretienLiaison,
                responsePersonIndex: entretienLiaisonPersonne,
              }).then((value) => {
                if (value.status === StatusCodes.OK) {
                  navigate('/validatedemanderdv');
                }
              });
            }}
          >
            {' '}
            Valider
          </button>
        </div>
      </Modal>
      <Modal
        style={customStyles as any}
        className="Modal"
        overlayClassName="Overlay"
        // className="modal-document"
        closeTimeoutMS={200}
        isOpen={modalIsOpenReprise}
        onRequestClose={() => {
          setModalIsOpenReprise(!modalIsOpenReprise);
        }}
        ariaHideApp={false}
      >
        <div className="float-left">
          <button
            onClick={() => {
              setModalIsOpenReprise(!modalIsOpenReprise);
            }}
            className="btn-close exit-modal"
          ></button>
        </div>
        <h3 className="question-form text-center titre-modal">Attention</h3>
        <div className="text-center text-modal">
          En effectuant cette demande, vous informez votre entreprise que vous désirez une visite médicale de
          pré-reprise
        </div>
        <div className="text-center mb-4 text-modal">Etes-vous sur de votre choix ?</div>
        <div className="text-center">
          <button
            value={4}
            className="btn btn-kerij-bleu btn-modal col-6"
            onClick={async () => {
              await employeeRequestInterview({
                typeNotification: 'Visite medicale de prereprise',
                responseIndex: entretienLiaison,
                responsePersonIndex: entretienLiaisonPersonne,
              }).then((value) => {
                if (value.status === StatusCodes.OK) {
                  navigate('/validatedemanderdv');
                }
              });
            }}
          >
            {' '}
            Valider
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default RdvUI;
