import CustomModal from '../../base/CustomModal';
import React from 'react';
import { RHInformation } from '../../../types/rh.interface';
import { useError } from '@/shared/useError';
import { KerijBox, KerijBoxType } from '../../base';
import { regenUserAdminPwd } from '../../../services/rh/admin';
import { StatusCodes } from 'http-status-codes';
import { ActionButton } from '@/components/ActionButton';
import { useToast } from '@/components/Toast';

interface ChangePasswordModalProperties {
  visible: boolean;
  handleCloseModal: () => void;
  rhInformation: RHInformation;
}

const errorMessage = 'Impossible de regénérer le mot de passe';
const ChangePasswordModal = ({ visible, handleCloseModal, rhInformation }: ChangePasswordModalProperties) => {
  const toast = useToast();

  const { error, triggerError, clearError } = useError();
  const handleUpdatePwd = () => {
    const data = {
      idUser: rhInformation?.id,
      email: rhInformation?.rh_login?.email,

      firstname: rhInformation?.firstname?.at(0)?.toUpperCase() + rhInformation?.firstname.slice(1).toLowerCase(),

      lastname: rhInformation?.lastname,
    };
    regenUserAdminPwd(data)
      .then((value) => {
        if (value.status === StatusCodes.OK) {
          handleCloseModal?.();
          clearError();
          toast.present({ message: 'Le mot de passe a été changé avec succès', severity: 'success' });
        }
        {
          triggerError(errorMessage);
        }
      })
      .catch(() => triggerError(errorMessage));
  };
  return (
    <CustomModal
      open={visible}
      onClose={() => {
        handleCloseModal?.();
      }}
      renderHeader={() => <> Regénérer le mot de passe </>}
      renderFooter={() => (
        <>
          <ActionButton
            label={'Valider'}
            onClick={() => {
              if (rhInformation?.id) {
                handleUpdatePwd();
              }
            }}
          />
        </>
      )}
      renderContent={() => (
        <>
          {'Vous êtes sur le point de réinitialiser les accès de '}
          <b> {rhInformation.firstname + ' ' + rhInformation.lastname + ' '}</b>
          {"Un email lui sera transmis avec un mot de passe provisoire qu'il devra changer à sa prochaine connexion."}
          {error && <KerijBox message={error} type={KerijBoxType.Error} />}
        </>
      )}
    />
  );
};

export default ChangePasswordModal;
