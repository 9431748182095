// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AbsencesDeleteCausePickerLabel {
  display: flex;
  white-space: nowrap;
}

.AbsencesDeleteCausePickerLabel > span {
  padding-right: 1rem;
}

.AbsencesDeleteCausePickerLabel > input {
  min-width: 21rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/DashboardRH/AbsencesDeleteCausePicker/AbsencesDeleteCausePicker.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".AbsencesDeleteCausePickerLabel {\n  display: flex;\n  white-space: nowrap;\n}\n\n.AbsencesDeleteCausePickerLabel > span {\n  padding-right: 1rem;\n}\n\n.AbsencesDeleteCausePickerLabel > input {\n  min-width: 21rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
