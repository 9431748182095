import { ParcoursAction } from './ParcoursAction';
import { ParcoursRule } from './ParcoursRule';

export interface ParcoursScript {
  $schema?: string | null;
  version: number;
  name: string | null;
  rule: {
    where: Array<ParcoursRule>;
  };
  action: Record<string, ParcoursAction>;
}
