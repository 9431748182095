import React from 'react';
import { colors } from '../../../../../../../constants';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { CLOSE_ABSENCE_OPTIONS } from './data';
import { FormControl, RadioGroup } from '@mui/material';
import { CloseAbsenceModalContentFormItem } from './CloseAbsenceModalContentFormItem';

interface CloseAbsenceModalContentFormProperties {
  value?: string;
  dateValue?: string;
  documentSelected?: boolean;
  onChange: (value: string) => void;
  onChangeDate: (value?: string) => void;
  onItemAction: (itemValue: string) => void;
}

export const CloseAbsenceModalContentForm = ({
  value,
  dateValue,
  documentSelected,
  onChange,
  onChangeDate,
  onItemAction,
}: CloseAbsenceModalContentFormProperties) => {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {CLOSE_ABSENCE_OPTIONS.map((item, index) => {
          return (
            <FormControlLabel
              style={{ color: colors.darkblue }}
              key={index}
              value={item.value}
              control={<Radio style={{ color: colors.darkblue }} />}
              label={
                <CloseAbsenceModalContentFormItem
                  item={item}
                  selectedValue={value}
                  dateValue={dateValue}
                  documentSelected={documentSelected}
                  onChange={onChangeDate}
                  onAction={onItemAction}
                />
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
