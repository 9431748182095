import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, TableCell, TableRow } from '@mui/material';

interface DocumentUploaderFooterProperties {
  disabled?: boolean;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DocumentUploaderFooter = ({ disabled, onFileChange }: DocumentUploaderFooterProperties) => {
  return (
    <TableRow>
      <TableCell colSpan={5}>
        <div className={'row'}>
          <Box className="col-6 offset-6 justify-content-center flex-row " display={'flex'}>
            <Button
              startIcon={<FontAwesomeIcon icon={faUpload}></FontAwesomeIcon>}
              variant={'outlined'}
              component="label"
              disabled={disabled}
            >
              <input
                className="form-control form-file input-file"
                name="document"
                type="file"
                id="file"
                value=""
                hidden
                onChange={onFileChange}
                accept="image/jpeg, image/png, application/pdf"
              />
              {'Sélectionner Fichier'}
            </Button>
          </Box>
          <Box className="col-6 offset-6 col text-center text-muted small">
            <em>{'Taille des fichiers : 5 Mo maximum'}</em>
          </Box>
        </div>
      </TableCell>
    </TableRow>
  );
};
