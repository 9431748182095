import { employeeVerifyAbsenceMat } from '@/services/employee';

export const verifyAbsenceMat = async (featureEnabled: boolean) => {
  return featureEnabled
    ? await employeeVerifyAbsenceMat().then((val) => {
        return {
          isValid: val.data.isHaving,
          isHaving: val.data.isHaving,
          dataAbsence: val.data.absence,
        };
      })
    : {
        isValid: true,
        isHaving: false,
        dataAbsence: undefined,
      };
};
