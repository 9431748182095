import { Box } from '@mui/material';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ITitleComtentProps {
  setViewCloseAbsenceOptions: (value: boolean) => void;
  viewCloseAbsenceOptions: boolean;
}

const TitleContent = ({ setViewCloseAbsenceOptions, viewCloseAbsenceOptions }: ITitleComtentProps) => {
  return (
    <Box className="gerer-user">
      <Box className="mb-3">
        <FontAwesomeIcon
          icon={(viewCloseAbsenceOptions ? 'fa-solid fa-caret-down' : 'fa-solid fa-caret-right') as any}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setViewCloseAbsenceOptions(!viewCloseAbsenceOptions);
          }}
        />{' '}
        &nbsp;
        <strong
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setViewCloseAbsenceOptions(!viewCloseAbsenceOptions);
          }}
        >
          Congés maternité <span style={{ fontSize: '11px' }}></span>
        </strong>
      </Box>
    </Box>
  );
};

export default TitleContent;
