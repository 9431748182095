import { io } from 'socket.io-client';
import { HOSTSERVER } from '../config';
import { ACCESS_TOKEN_HR } from '../constants';
import { getRefreshToken, removeAuthData, storeAuthData } from '../utils/auth.utils';
import { UserTypes } from '../types/userTypes.enum';
import { ErrorMessages } from '../constants';
import { PRO_URL } from '../constants/urls.constants';
import { StatusCodes } from 'http-status-codes';
import { exchangeToken } from '../services/auth';

export class SocketClient {
  constructor() {
    this.reconnectionAttempts = 0;
    if (!SocketClient.instance) {
      this.initializeSocket();
      SocketClient.instance = this;
    }
    return SocketClient.instance;
  }

  initializeSocket() {
    this.socket = io(HOSTSERVER, {
      autoConnect: false,
      withCredentials: true,
      auth: { token: localStorage.getItem(ACCESS_TOKEN_HR) },
    }).on('connect_error', this.handleError.bind(this));
  }

  async handleError(error) {
    console.error(`Connection error due to ${error.message}`);
    if (error.message === ErrorMessages.INVALID_CREDENTIALS) {
      const existingToken = localStorage.getItem(ACCESS_TOKEN_HR);
      if (!existingToken) {
        console.error('No token found in localStorage');
        return;
      }
      if (this.reconnectionAttempts > 3) {
        console.error('Cannot reconnect to socket after several attempts');
        removeAuthData(UserTypes.HR);
        this.reconnectionAttempts = 0;
        if (this.navigate) {
          this.navigate(`${PRO_URL.PREFIXE_PRO}${PRO_URL.LOGIN}`);
        }
        return;
      }
      const isExchangeSuccessful = await this.exchangeToken();
      if (isExchangeSuccessful) {
        this.reconnectionAttempts++;
        this.socket.auth.token = existingToken;
        this.socket.connect();
      }
    }
  }

  async exchangeToken() {
    try {
      const refreshToken = getRefreshToken();
      const response = await exchangeToken({ refreshToken });
      if (response.status === StatusCodes.OK) {
        storeAuthData({
          userType: UserTypes.HR,
          token: response.data.token,
          exp: response.data.exp,
          refreshToken: response.data.refreshToken,
        });
        return true;
      }
    } catch {
      console.error('Cannot exchange token');
    }
    return false;
  }

  setNavigationHandler(navigate) {
    this.navigate = navigate;
  }

  connect() {
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }

  deleteAction({ idNotification, length }) {
    this.socket.emit('delete_action', { idNotification, length });
  }

  afterDeleteEvent(callback) {
    this.socket.on('after_delete_action', callback);
  }

  updateLength(callback) {
    this.socket.on('update_length', callback);
  }

  afterAddEvent(callback) {
    this.socket.on('after_add_action', callback);
  }

  unsubscribeAfterAddEvent(callback) {
    this.socket.off('after_add_action', callback);
  }

  afterUpdateEvent(callback) {
    this.socket.on('after_update_action', callback);
  }

  unsubscribeAfterUpdateEvent(callback) {
    this.socket.off('after_update_action', callback);
  }

  unsubscribeUpdateLength(callback) {
    this.socket.off('update_length', callback);
  }

  unsubscribeAfterDeleteEvent(callback) {
    this.socket.off('after_delete_action', callback);
  }
}
