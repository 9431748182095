import React, { useEffect, useRef, useState } from 'react';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import '../Chart.css';
import Slider from '@mui/material/Slider';
import ChartZoom from 'chartjs-plugin-zoom';
import { fetchRhGraphDataAbsenteeism } from '../../../services/rh';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
  ChartZoom,
  RadialLinearScale,
  Filler,
);

const RadarChartTaux = ({ forPage }) => {
  const chartRef = useRef(null);
  const [total, setTotal] = useState(0);
  const marks = [
    {
      value: 1,
      label: '1 mois',
    },
    {
      value: 2,
      label: '2 mois',
    },
    {
      value: 3,
      label: '3 mois',
    },
    {
      value: 4,
      label: '4 mois',
    },
    {
      value: 5,
      label: '5 mois',
    },
    {
      value: 6,
      label: '6 mois',
    },
  ];

  const valuetext = (value) => {
    return `${value} jours`;
  };

  const [month, setMonth] = useState(1);
  const borderColor = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
  ];
  const backgroundColor = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
  ];
  const [data, setData] = useState({
    labels: ['20-27', '28-35', '36-43', '44-51', '52-59', '60+'],
    datasets: [
      {
        label: 'total',
        data: [...Array(6)].map((value) => 0),
        borderWidth: 2,
        borderColor: borderColor[0],
        backgroundColor: backgroundColor[0],
      },
      {
        label: 'homme',
        data: [...Array(6)].map((value) => 0),
        borderWidth: 2,
        borderColor: borderColor[1],
        backgroundColor: backgroundColor[1],
      },
      {
        label: 'femme',
        data: [...Array(6)].map((value) => 0),
        borderWidth: 2,
        borderColor: borderColor[2],
        backgroundColor: backgroundColor[2],
      },
      {
        label: 'cadre',
        data: [...Array(6)].map((value) => 0),
        borderWidth: 2,
        borderColor: borderColor[3],
        backgroundColor: backgroundColor[3],
      },
      {
        label: 'non-cadre',
        data: [...Array(6)].map((value) => 0),
        borderWidth: 2,
        borderColor: borderColor[4],
        backgroundColor: backgroundColor[4],
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: 'Line chart with Configuration',
      },
      label: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      elements: {
        line: {
          borderWidth: 3,
        },
      },
      tooltip: {
        callbacks: {
          label: function (item, data) {
            return `${item.dataset.label}: ${item.raw}%`;
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    datasetFill: true,
    scales: {
      r: {
        min: 0,
        ticks: {
          callback: function (value, index, values) {
            return value + '%';
          },
        },
      },
    },
  };

  useEffect(() => {
    const getDataAbsence = async (month) => {
      const response = await fetchRhGraphDataAbsenteeism({
        month: '04',
        year: '2022',
        type: 0,
        monthDiff: month,
      });

      if (response) {
        setData({
          labels: response.data.absenteeisme.partial.labels,
          datasets: Object.keys(response.data.absenteeisme.partial.value).map((v, i) => {
            return {
              label: v,
              data: response.data.absenteeisme.partial.value[v],
              fill: true,
              borderWidth: 2,
              borderColor: borderColor[i],
              backgroundColor: backgroundColor[i],
            };
          }),
        });
        setTotal(response.data.absenteeisme.total);
      }

      // axios.get("/api/rh/graph/data_absenteeism", {
      //     params: {
      //         month: "04",
      //         year: "2022",
      //         type: 0,
      //         monthDiff: month
      //     }
      // }).then(value => {
      //     setData({
      //         labels: value.data.absenteeisme.partial.labels,
      //         datasets: Object.keys(value.data.absenteeisme.partial.value).map((v, i) => {
      //             return {
      //                 label: v,
      //                 data: value.data.absenteeisme.partial.value[v],
      //                 fill: true,
      //                 borderWidth: 2,
      //                 borderColor: borderColor[i],
      //                 backgroundColor: backgroundColor[i],
      //             }
      //         })
      //     })
      //     setTotal(value.data.absenteeisme.total)
      // })
    };
    getDataAbsence(month);
  }, [month]);

  return (
    <div className="row col-12">
      <div className="p-2 flex-fill col-10 bd-highlight" id="tendancegenerale">
        <div className="container-fluid col-12 mt-5">
          <div className="chart-container">
            <div className="chart-item col-12">
              {!forPage && (
                <div>
                  <h1 className="titre-rh mb-5">Les taux d'absentéisme depuis {month ? `M-${month}` : 'M-0'}</h1>
                  <Slider
                    className="mb-5"
                    defaultValue={month}
                    value={month}
                    onChangeCommitted={(event, newVal) => {
                      setMonth(newVal);
                    }}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    step={null}
                    marks={marks}
                    min={1}
                    max={6}
                  ></Slider>
                </div>
              )}
              <div
                className="row"
                style={{
                  height: forPage ? '50%' : '100%',
                  width: '100%',
                  aligneItems: 'center',
                }}
              >
                <div
                  className="col-sm"
                  style={{
                    display: 'flex',
                    alignSelf: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '50%',
                  }}
                >
                  <div
                    style={{
                      width: '300px',
                      height: '200px',
                      borderRadius: '10px',
                      backgroundColor: '#0C064B',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <span
                      style={{
                        textAlign: 'center',
                        fontSize: '36px',
                        color: '#FFF',
                      }}
                    >
                      Taux total <br /> <strong>{total} %</strong>
                    </span>
                  </div>
                </div>
                <div
                  className="col-sm"
                  style={{
                    height: forPage ? '350px' : '600px',
                    width: '50%',
                  }}
                >
                  <Radar data={data} options={options} ref={chartRef}></Radar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RadarChartTaux;
