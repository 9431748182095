import VerifySelect from '../../components/Signup/VerifySelect';
import { DefaultLayout } from '../../components/layouts';
import React from 'react';

export const VerifySelectPage: React.FC = () => {
  return (
    <DefaultLayout>
      <VerifySelect />
    </DefaultLayout>
  );
};
