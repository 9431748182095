import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MenuBar = ({ onSearch }) => {
  return (
    <div className="col-6">
      <div className="form">
        <i className="fa-search">
          <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
        </i>
        <input
          type="text"
          className="form-control form-input"
          name="search"
          // value={search}
          onChange={(event) => onSearch && onSearch(event.target.value)}
          placeholder="Rechercher"
        />
      </div>
    </div>
  );
};

export default MenuBar;
