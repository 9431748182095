import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import CheckCodeUI from './components/CheckCodeUI';

export const CheckCodePage: React.FC = () => {
  return (
    <DefaultLayout>
      <CheckCodeUI />
    </DefaultLayout>
  );
};
