export const absenceType = {
  arret_maladie: 1,
  hospitalisation: 2,
  conge_maternite: 3,
  conge_parentalite: 4,
  accident_arret_professionnel: 5,
};

interface AbsenceDescriptionConverter {
  absenceType: string;
  absenceType1: string;
  absenceType2: string;
}

export const getAbsenceDescriptionFromTypes = ({
  absenceType,
  absenceType1,
  absenceType2,
}: AbsenceDescriptionConverter) => {
  switch (Number.parseInt(absenceType)) {
    case 1:
      if (Number.parseInt(absenceType1) === 2) return 'Accident - Maladie professionnelle';
      return 'Arrêt de travail';
    case 2:
      return 'Hospitalisation';
    case 3:
      return 'Congé maternité';
    case 4:
      return 'Congé paternité';
    default:
      return '';
  }
};
export const toSelect = 0;
