import { type PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import type { AppState } from '../store';

export interface State {
  open: boolean;
}

const initialState: State = {
  open: false,
};

export const slice = createSlice({
  name: 'bot',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<State['open']>) => {
      state.open = action.payload;
    },
  },
});

export const getSelf = (state: AppState) => state[slice.name];

export const isOpen = createSelector([getSelf], (self) => self.open);

export const { actions } = slice;
