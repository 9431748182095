// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entretien-cadre {
  border: 1px solid #d3d0eb;
  border-radius: 10px;
  padding: 1em;
  margin-bottom: 1em;
  font-family: 'DejaVu Sans', 'Arial', sans-serif;
}

@font-face {
  font-family: 'DejaVu Sans';
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf') format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-weight: bold;
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-style: italic;
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-weight: bold;
  font-style: italic;
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf') format('truetype');
}

.k-pdf-export .export-text {
  text-align: left;
  vertical-align: text-top;
  display: inline;
}
`, "",{"version":3,"sources":["webpack://./src/components/EntretienLiaison/FicheEntretienLiaison/FicheEntretienLiaison.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,+CAA+C;AACjD;;AAEA;EACE,0BAA0B;EAC1B,0GAA0G;AAC5G;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;EACjB,+GAA+G;AACjH;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,kHAAkH;AACpH;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;EACjB,kBAAkB;EAClB,kHAAkH;AACpH;;AAEA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,eAAe;AACjB","sourcesContent":[".entretien-cadre {\n  border: 1px solid #d3d0eb;\n  border-radius: 10px;\n  padding: 1em;\n  margin-bottom: 1em;\n  font-family: 'DejaVu Sans', 'Arial', sans-serif;\n}\n\n@font-face {\n  font-family: 'DejaVu Sans';\n  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'DejaVu Sans';\n  font-weight: bold;\n  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'DejaVu Sans';\n  font-style: italic;\n  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'DejaVu Sans';\n  font-weight: bold;\n  font-style: italic;\n  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf') format('truetype');\n}\n\n.k-pdf-export .export-text {\n  text-align: left;\n  vertical-align: text-top;\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
