import { Absence } from './absences.type';

export interface Notification {
  id: string;
  type_notification: string;
  is_checked: boolean;
  action: 'progress';
  last_reminder: string;
  absence?: Absence;
}
