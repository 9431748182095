import * as React from 'react';
import { Typography } from '@mui/material';
import { Button } from '@/components/Button';
import { useNavigate } from 'react-router-dom';
import { PRO_URL } from '@/constants/urls.constants';
import { RHPage } from '@/components/RHPage';

function getBackURL() {
  return PRO_URL.PRO;
}

// Name and options
const componentName = 'ComingSoonPage';

// Component properties
export interface ComingSoonPageProps extends React.ComponentProps<'div'> {}

export function ComingSoonPage(props: ComingSoonPageProps) {
  const navigate = useNavigate();

  return (
    <RHPage title="En cours de construction">
      <Typography variant="h5">
        Les équipes KERij sont en train de construire cette fonctionnalité, n'hésitez pas à revenir plus tard !
      </Typography>
      <Button color="primary" type="button" onClick={() => navigate(getBackURL())} sx={{ mt: 2 }}>
        {"Retour à l'accueil"}
      </Button>
    </RHPage>
  );
}
