import axios from '@/lib/axios';

export const reportSend = async (data: reportSend.Parameters) => {
  const formData = new FormData();
  formData.set('reporterEmail', data.reporterEmail);
  formData.set('category', data.category);
  formData.set('description', data.description);
  data.attachments.forEach((attachment) => {
    formData.append('attachments', attachment);
  });

  return await axios.put<void>('/api/report', formData);
};
export namespace reportSend {
  export interface Parameters {
    reporterEmail: string;
    category: string;
    description: string;
    attachments: any[]; // TODO: type this
  }
}
