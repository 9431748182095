// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progresses {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.steps-active {
  display: flex;
  border: 1px solid #40c9c9;
  background-color: #fff;
  color: #342f6c;
  font-size: 0.8em;
  font-weight: bold;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 1rem 0 1rem;
}

.steps {
  display: flex;
  border: 1px solid #342f6c;
  background-color: #342f6c;
  color: #342f6c;
  font-size: 0.8em;
  font-weight: bold;
  width: 10px;
  height: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 1rem 0 1rem;
}

.steps-valid {
  display: flex;
  border: 1px solid #40c9c9;
  background-color: #40c9c9;
  color: #40c9c9;
  font-size: 0.8em;
  font-weight: bold;
  width: 10px;
  height: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 1rem 0 1rem;
}`, "",{"version":3,"sources":["webpack://./src/features/declaration/components/bars/stepProgressBar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,sBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,qBAAA;AACF","sourcesContent":[".progresses {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n}\n\n.steps-active {\n  display: flex;\n  border: 1px solid #40c9c9;\n  background-color: #fff;\n  color: #342f6c;\n  font-size: 0.8em;\n  font-weight: bold;\n  width: 40px;\n  height: 40px;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  margin: 0 1rem 0 1rem;\n}\n\n.steps {\n  display: flex;\n  border: 1px solid #342f6c;\n  background-color: #342f6c;\n  color: #342f6c;\n  font-size: 0.8em;\n  font-weight: bold;\n  width: 10px;\n  height: 10px;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  margin: 0 1rem 0 1rem;\n}\n\n.steps-valid {\n  display: flex;\n  border: 1px solid #40c9c9;\n  background-color: #40c9c9;\n  color: #40c9c9;\n  font-size: 0.8em;\n  font-weight: bold;\n  width: 10px;\n  height: 10px;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  margin: 0 1rem 0 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
