import React from 'react';
import { Service } from '@/types';
import { Card, CardActionArea } from '@mui/material';
import { IconButtonProps } from '../IconButton/IconButton';
import { ServiceCardLogo } from './ServiceCardLogo';
import { ServiceCardActions } from './ServiceCardActions';
import { ServiceCardInfos } from './ServiceCardInfos';

export interface ServiceCardProps {
  value: Service;
  action?: IconButtonProps;
  onClick?: () => void;
}

export function ServiceCard(props: ServiceCardProps) {
  const { value, action, onClick } = props;

  return (
    <>
      <Card sx={{ borderRadius: '10px' }}>
        <CardActionArea onClick={onClick}>
          <ServiceCardLogo value={value} />
          <ServiceCardActions value={value} action={action} />
        </CardActionArea>
      </Card>
      <ServiceCardInfos value={value} />
    </>
  );
}
