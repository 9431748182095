import React, { useState } from 'react';
import { EmployeeInformation as EmployeeInformationComponent } from '../../../features/dashboard-rh/create-absence-rh/components/EmployeeInformation.component';
import { AbsencesInput } from '@/components/DashboardRH/AbsencesInput';
import { Absence, AbsenceStatus, EmployeeInformation } from '@/types';
import { Typography } from '@mui/material';
import { getFullName } from '@/shared/utils/getFullName.service';
import { AbsencesDeleteCausePicker } from '@/components/DashboardRH/AbsencesDeleteCausePicker';
import { AbsencesDeleteValidation } from '@/components/DashboardRH/AbsencesDeleteValidation';
import { AbsenceFormStep, AbsencesForm } from '@/components/DashboardRH/AbsencesForm/AbsencesForm';
import { useAppSelector } from '@/store';

export const DELETE_ABSENCES_STEPS = {
  EMPLOYEE: 0,
  ABSENCES: 1,
  CAUSE: 2,
  VALIDATION: 3,
};

interface DeleteAbsencesStepsProperties {
  isSubmit: boolean;
  onSubmit: (value: Absence[], cause?: string | null) => void;
  onReset: () => void;
}

export const DeleteAbsencesSteps = ({ isSubmit, onSubmit, onReset }: DeleteAbsencesStepsProperties) => {
  const [causeValue, setCauseValue] = useState<string | null>(null);
  const [customCauseValue, setCustomCauseValue] = useState<string | null>(null);
  const [deleteCause, setDeleteCause] = useState<string | null | undefined>();

  const [absences, setAbsences] = useState<Absence[]>([]);
  const [infosEmployee, setInfosEmployee] = useState<EmployeeInformation | null>(null);

  const employee = useAppSelector((state) =>
    state.employee.employeeList?.find((employee: EmployeeInformation) => employee.id === infosEmployee?.id),
  );

  function resetForm() {
    setInfosEmployee(null);
    setAbsences([]);
    setCauseValue(null);
    setCustomCauseValue(null);
    onReset();
  }

  const steps: AbsenceFormStep[] = [
    {
      label: 'Rechercher un employé',
      description: <EmployeeInformationComponent value={infosEmployee} onChange={setInfosEmployee} />,
      optionsLabel: () => {
        if (infosEmployee == null) return null;
        const content = getFullName(infosEmployee);
        return <Typography variant="caption">{content}</Typography>;
      },
    },
    {
      label: 'Sélectionner un ou plusieurs arrêts',
      description: employee ? (
        <AbsencesInput
          value={absences}
          onChange={(e, value) => setAbsences(value)}
          filters={{
            status: [AbsenceStatus.DRAFT, AbsenceStatus.FINISHED, AbsenceStatus.PENDING, AbsenceStatus.VALIDATED],
            id_employee_information: employee.id,
          }}
        />
      ) : (
        <></>
      ),
      optionsLabel: () => {
        const content = `${absences.length} arrêt(s) sélectionné(s)`;
        return <Typography variant="caption">{content}</Typography>;
      },
    },
    {
      label: 'Suppression',
      description: (
        <AbsencesDeleteCausePicker
          value={causeValue}
          initialCustomValue={customCauseValue}
          onChange={({ itemValue, customValue, label }) => {
            setCauseValue(itemValue);
            setCustomCauseValue(customValue);
            setDeleteCause(label);
          }}
        />
      ),
    },
    {
      label: 'Validation',
      description: (
        <AbsencesDeleteValidation
          employee={employee}
          cause={deleteCause}
          absences={absences}
          isSubmit={isSubmit}
          onReset={resetForm}
        />
      ),
    },
  ];

  return (
    <AbsencesForm
      steps={steps}
      employee={infosEmployee}
      submitStep={DELETE_ABSENCES_STEPS.VALIDATION}
      isSubmit={isSubmit}
      onSubmit={() => onSubmit(absences, deleteCause)}
      onBackToFirst={() => setAbsences([])}
    />
  );
};
