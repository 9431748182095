import { colors } from '../../../../../constants';
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface EmployeeSearchFieldProperties {
  onChange: (searchString: string) => void;
}
export const EmployeeSearchField = ({ onChange }: EmployeeSearchFieldProperties) => {
  const [searchFilter, setSearchFilter] = useState('');

  useEffect(() => {
    const timeoutCallback = setTimeout(() => {
      onChange?.(searchFilter);
    }, 300);
    return () => {
      clearTimeout(timeoutCallback);
    };
  }, [searchFilter]);

  return (
    <TextField
      sx={(theme) => ({
        width: '100%',
        input: { color: colors.darkblue, bgcolor: theme.palette.common.white },
        'input:focus': { outline: 'none', border: 0 },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: colors.darkblue, // Couleur de la bordure
          },
          '&.Mui-focused fieldset': {
            borderColor: colors.secondary, // Couleur de la bordure lorsqu'elle a le focus
          },
        },
      })}
      id="search-textfield"
      label="Par nom, par prénom ou par matricule"
      variant="outlined"
      onChange={(event) => {
        setSearchFilter(() => event.target.value);
      }}
    />
  );
};
