import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

export function createSVGIcon(IconComponent: React.ComponentType, displayName: string): typeof SvgIcon {
  // eslint-disable-next-line no-inner-declarations
  function Component(props: SvgIconProps, ref: React.Ref<any>) {
    return (
      <SvgIcon
        data-testid={`${displayName}Icon`}
        ref={ref}
        inheritViewBox={true}
        component={IconComponent}
        {...props}
      />
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    // Need to set `displayName` on the inner component for React.memo.
    // React prior to 16.14 ignores `displayName` on the wrapper.
    Component.displayName = `${displayName}Icon`;
  }

  Component.muiName = SvgIcon.muiName;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore We know what we are doing here
  return React.memo(React.forwardRef(Component));
}
