import React, { useEffect, useState } from 'react';
import { EmployeeSearchField } from '../../../../shared/components/Form/EmployeeSearchField';
import { IconButton } from '@/components/IconButton';
import { Spacer } from '@/components/Spacer';
import {
  EventRepeatOutlined,
  FileDownloadOff,
  PregnantWomanOutlined,
  SdCardAlertOutlined,
  SignalCellularNoSimOutlined,
} from '@mui/icons-material';
import { NotificationSearchFilter, NotificationsType } from './data';
import { NotificationSearchBarFilter } from './NotificationSearchBarFilters';
import { FilterAlt } from '@/components/AppIcon';

export const emptyNotificationSearchFilter: NotificationSearchFilter = {
  content: '',
  portfolio: false,
  type: {
    [NotificationsType.MissingDocument]: {
      label: `Gérer les justificatifs manquants`,
      icon: <SignalCellularNoSimOutlined />,
      checked: false,
    },
    [NotificationsType.VerifyDocument]: {
      label: `Valider les justificatifs`,
      icon: <SdCardAlertOutlined />,
      checked: false,
    },
    [NotificationsType.AbsenceEnd]: {
      label: `Clôturer les absences`,
      icon: <EventRepeatOutlined />,
      checked: false,
    },
    [NotificationsType.Pathologie]: {
      label: 'Gérer les congés pathologiques',
      icon: <PregnantWomanOutlined />,
      checked: false,
    },
    [NotificationsType.IntegrationFailed]: {
      label: 'Gérer les intégrations échouées',
      icon: <FileDownloadOff />,
      checked: false,
    },
  },
};

interface NotificationSearchBarProperties {
  filtered?: boolean;
  disablePortfolio?: boolean;
  onSubmit?: (filters: NotificationSearchFilter) => void;
  onCancel?: () => void;
  onSearchFilterChanged?: (filters: NotificationSearchFilter) => void;
}

export const NotificationSearchBar = ({
  disablePortfolio,
  onSubmit,
  onCancel,
  onSearchFilterChanged,
}: NotificationSearchBarProperties): JSX.Element => {
  const [filters, setFilters] = useState<NotificationSearchFilter>(emptyNotificationSearchFilter);
  const [isApply, setIsApply] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onSearchFilterChanged?.(filters);
  }, [filters]);
  return (
    <>
      <div className="row">
        <div className="col-6" style={{ zIndex: 10 }}>
          <EmployeeSearchField
            onChange={(searchString: string) => {
              const newFilters = {
                ...filters,
                content: searchString,
              };
              setFilters(newFilters);
              onSubmit?.(newFilters);
            }}
          />
        </div>

        <div className="col-2">
          <IconButton
            size="large"
            badge={
              isApply && !isOpen && (filters.portfolio || Object.values(filters.type).some((filter) => filter.checked))
            }
            icon={<FilterAlt />}
            style={{ position: 'relative', top: '0.25rem', right: '1rem', width: '3rem' }}
            onClick={() => {
              const initialValue = isOpen;
              setIsOpen(!initialValue);
              if (initialValue) {
                setIsApply(true);
                onSubmit?.(filters);
              }
            }}
          />

          <NotificationSearchBarFilter
            open={isOpen}
            value={filters}
            onChange={(value) => setFilters(value)}
            onSubmit={(value) => {
              setIsApply(true);
              onSubmit?.(value);
              setIsOpen(false);
            }}
            onCancel={() => {
              setIsApply(true);
              setFilters(emptyNotificationSearchFilter);
              onCancel?.();
              setIsOpen(false);
            }}
            disablePortfolio={disablePortfolio}
          />
        </div>
        <Spacer />
      </div>
    </>
  );
};
