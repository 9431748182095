import { DefaultLayout } from '@/components/layouts';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '@/assets/Logotype-Icone-Fondclair.svg';
import moment from 'moment';
import { KerijBox, KerijBoxType, KerijLoading, KerijLoadingType } from '@/components/base';
import { NavigationContext } from '@/components/Context/NavigationContext';
import { StepProgressBar } from '@/features/declaration/components/bars/StepProgressBar';
import { NavigationSteps } from '@/components/Context/NavigationProvider';
import { useError } from '@/shared/useError';
import DepotDocumentHistorique from '@/components/DashboardSalarie/DepotDocumentHistorique';
import { employeeDeclareAbsence, employeeUpdateAbsence, fetchEmpBusinessCodeAbsence } from '@/services/employee';
import { RouterConfig } from '@/config/RouterConfig';
import { StatusCodes } from 'http-status-codes';
import { FormatDate } from '@/constants/date.constants';
import { AccountContext, useAppSelector } from '@/store';
import { formatDate } from '@/utils/date.utils';
import { DocumentStatus, EmployeeCivility, OCRAPIResponse } from '@/types';
import { ProlongationChoices } from '@/features/dashboard-rh/create-absence-rh/props/step.interface';
import { Button as KRJButton } from '@/components/Button';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TypographyProps,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getTypeDocument = (absenceType: string) => {
  switch (absenceType) {
    case '1': {
      return 'arret de travail';
    }
    case '2': {
      return 'hospitalisation';
    }
    case '3': {
      return 'certificat de grossesse';
    }
    default: {
      return '';
    }
  }
};

const renderFieldValue = (children: React.ReactNode, props?: TypographyProps) => (
  <Typography className="col-12" color="primary" style={{ fontWeight: 'bold' }} {...props}>
    {children}
  </Typography>
);

export const DeclarationValidationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(AccountContext.getUser)!;
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleClose = () => setModalOpen(false);
  const [codeAbsence, setCodeAbsence] = useState<any[]>([]);
  const document = { ...(location.state?.document?.document ?? location.state?.document) } || {};
  const ocrResult: OCRAPIResponse | undefined = document.valueDocument?.data;
  const documentStatus = ocrResult?.documentStatus ?? DocumentStatus.REJECTED;

  const absenceType = document.absenceType?.value;
  const { setCurrentStep } = useContext(NavigationContext);
  const { error, triggerError, clearError } = useError();
  const isUpdate = location.state?.isUpdate || false;
  const absenceToUpdate = location.state?.absenceToUpdate || {};
  const [isAbsenceUpdated, setIsAbsenceUpdated] = useState<boolean>(false);

  useEffect(() => {
    clearError();
    setCurrentStep(NavigationSteps.DECLARATION_VALIDATION);
    if (isUpdate) {
      HandleUpdateAbsence();
    } else {
      handleFetchAbsenceCode();
    }
  }, [document.ocr_result]);

  const handleFetchAbsenceCode = async () => {
    const responseData = await fetchEmpBusinessCodeAbsence({
      isOption1: document?.optionnal ? document.optionnal.isClickedOption1 : null,
      isOption2: document?.optionnal ? document.optionnal.isClickedOption2 : null,
      description: document?.absenceType?.value,
      description1: document?.absenceType1?.value,
      description2: document?.absenceType2?.value,
    });
    if (responseData.status === StatusCodes.OK) {
      setCodeAbsence(responseData.data.code);
    } else {
      triggerError();
    }
  };

  const HandleUpdateAbsence = async () => {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.set('idEmployeeInformation', user?.employee_information.id);
      formData.set('idAbsence', absenceToUpdate.id);
      formData.set('dateAction', absenceToUpdate.dateAction);
      formData.set('absences', JSON.stringify(absenceToUpdate.absences));
      formData.append('documents', document?.file || '');
      formData.append('ocrResult', JSON.stringify(document.ocr_result) || '');
      formData.append('typeDocument', getTypeDocument(document?.absenceType?.value));
      await employeeUpdateAbsence(formData);
      setIsAbsenceUpdated(true);
    } catch {
      triggerError('Une erreur est survenue lors de la récupération des absences');
      setIsAbsenceUpdated(false);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.set('zipcode', user?.employee_information?.zipcode);
    for (const period of document.period) {
      formData.append('lastdayWork', formatDate(document.lastDayWork));
      formData.append('dateStart', formatDate(period.dateDebut));
      formData.append('dateEnd', formatDate(period.dateFin));
      formData.set('isLinkHospi', document?.isLinkHospi || '');
      formData.set('idAbsenceHospi', document?.isLinkHospi ? document.idAbsenceHospi : '');
    }
    formData.set('birthday', document?.dateNaissance ? formatDate(document?.dateNaissance) : '');
    formData.set('declareBy', '1');
    formData.set('dateAction', moment().format(FormatDate.DATE_ONLY));
    formData.set('description', document?.absenceType?.value || '');
    formData.set('description1', document?.absenceType1?.value || '');
    formData.set('description2', document?.absenceType2?.value || '');

    formData.set('message', document.message);
    formData.set(
      'isReductionTime',
      document?.optionnal &&
        typeof document?.optionnal?.isClickedOption1 === 'boolean' &&
        document?.absenceType?.value === '3'
        ? document?.optionnal?.isClickedOption1
        : '',
    );

    if (document?.contact?.contactType) formData.set('contactType', document.contact.contactType);
    if (document?.contact?.email) formData.set('email', document.contact.email);
    if (document?.contact?.phone) formData.set('phone', document.contact.phone);

    formData.set(
      'isPromoTime',
      document?.optionnal &&
        typeof document?.optionnal?.isClickedOption2 === 'boolean' &&
        document?.absenceType?.value === '3'
        ? document?.optionnal?.isClickedOption2
        : '',
    );
    formData.set('dateEndReduction', document?.dateEndReduction || '');
    formData.set('hoursReduction', document?.hoursReduction || '');
    formData.set('dateEndPromo', document?.dateEndPromo || '');
    formData.set('hasWorkedPlus', document?.isWorkDay || '');
    formData.set('isVerified', document?.isVerified || '');
    formData.set('actionLevel', document?.stateAbsence?.value === '2' ? '1' : '0');
    if (document?.absenceType?.value === '4') {
      formData.set(
        'isFather',
        document?.relationEnfant?.value === 'pere'
          ? 'true'
          : document?.relationEnfant?.value === 'conjoint'
          ? 'false'
          : '',
      );
    }
    if (codeAbsence) {
      if (codeAbsence.length === 1) {
        formData.set('codeAbsence', codeAbsence.at(0).code);
      } else {
        for (const index in document.period) {
          if (index === '0') {
            formData.append('codeAbsence', codeAbsence.filter((value) => value.order === 0).at(0).code);
          } else if (['1', '2', '3'].includes(index)) {
            formData.append('codeAbsence', codeAbsence.filter((value) => value.order === 1).at(0).code);
          } else {
            formData.append('codeAbsence', codeAbsence.filter((value) => value.order === 2).at(0).code);
          }
        }
      }
    } else {
      formData.set('codeAbsence', '');
    }

    formData.append('documents', document?.file || '');
    formData.append('ocrResult', JSON.stringify(document.ocr_result) || '');
    formData.append('documentStatus', document?.ocr_result?.data.documentStatus || DocumentStatus.PENDING);
    formData.append('typeDocument', getTypeDocument(document?.absenceType?.value));

    formData.set('prolongation', document?.stateAbsence?.value === ProlongationChoices.PROLONGATION ? 'true' : 'false');
    const response = await employeeDeclareAbsence(formData);
    if (response.status === StatusCodes.OK) {
      window.history.pushState(response.data.cpam, '', window.location.href);
      navigate(RouterConfig.declarationfinPage().path, {
        state: {
          cpam: response.data.cpam,
          absenceType: document?.absenceType?.value,
          printcpam: document?.printcpam,
        },
      });
      setLoading(false);
    } else {
      triggerError('Une erreur est survenue lors de la déclaration de votre absence');
      setLoading(false);
    }
  };

  const DeclarationSynthese = () => (
    <div className="container-fluid">
      <h3 className="question-form mt-5 mb-5 text-center col-lg-4">Votre déclaration</h3>

      <div className="row col-12 col-lg-8 doc-validation shadow p-5">
        <div className="col-10 h4 mb-5 nom-validation">
          {' '}
          <div>
            <strong>
              {user?.employee_information?.firstname[0].toUpperCase() +
                user?.employee_information?.firstname.slice(1).toLowerCase()}{' '}
              {user?.employee_information?.lastname},{' '}
              {(user && user.employee_information?.civility === EmployeeCivility.Male && <small> né le </small>) ||
                (user && user.employee_information?.civility === EmployeeCivility.Female && (
                  <small> née le </small>
                ))}{' '}
              {moment(user?.employee_information?.birthday, FormatDate.DATE_ONLY).format(FormatDate.FRENCH_DATE)}
            </strong>
          </div>
          <Button
            startIcon={<FontAwesomeIcon icon="circle-info" />}
            onClick={() => setModalOpen(true)}
            size="small"
            style={{ textTransform: 'none' }}
          >
            Responsable de traitement
          </Button>
          <Dialog open={modalOpen} onClose={handleClose}>
            <DialogTitle>{'Note'}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Votre employeur est le responsable du traitement de vos données dans le cadre de la déclaration de votre
                arrêt de travail en application de la réglementation qui lui est applicable. Pour en savoir plus sur la
                gestion de vos données personnelles et pour exercer vos droits, veuillez-vous reporter à la politique de
                confidentialité de votre employeur qui vous est adressée.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="col-2">
          <img alt="kerij" src={logo} width="50" />
        </div>
        <div className="text-muted">ABSENCE</div>
        <hr className="hr-kerij col-12" />
        <div className="items-validation row col-12 mb-3">
          <div className="number-validation col-1">1</div>
          <div className="item-validation col-10">
            <div className="title-validation col-12">{`TYPE D'ABSENCE`}</div>
            {renderFieldValue(
              <>
                <p style={{ margin: 0 }}>
                  {document?.absenceType?.label}
                  {document?.absenceType1?.label ? <span> - {document?.absenceType1?.label}</span> : null}
                </p>
                {document?.stateAbsence?.value === '2' ? (
                  <p style={{ margin: 0 }}>Prolongation</p>
                ) : (
                  <p style={{ margin: 0 }}>Initial</p>
                )}
                {document?.absenceType2?.value === '1' ? null : (
                  <p style={{ margin: 0 }}>{document?.absenceType2?.label}</p>
                )}
              </>,
            )}
          </div>
        </div>
        {
          <div className="items-validation row col-12 mb-3">
            <div className="number-validation col-1">2</div>
            <div className="item-validation col-10">
              <div className="title-validation col-12">JUSTIFICATIF</div>
              {document.file ? (
                <>
                  <div
                    className="col-12"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const source = URL.createObjectURL(document.file);
                      window.open(source, '_blank');
                    }}
                  >
                    {renderFieldValue('Transmis')}
                  </div>
                  {documentStatus === DocumentStatus.APPROVED ? (
                    <Typography variant="body2" color="success.main">
                      Validé
                    </Typography>
                  ) : documentStatus === DocumentStatus.PENDING ? (
                    <Typography variant="body2" color="warning.main">
                      En cours de vérification
                    </Typography>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <div className="col-12">{renderFieldValue('A transmettre', { color: 'error' })}</div>
              )}
            </div>
          </div>
        }
        {document.period && (
          <div className="items-validation row col-12 mb-3">
            <div className="number-validation col-1">3</div>
            <div className="item-validation col-10">
              <div className="title-validation col-12">PÉRIODE</div>
              {document.period.map(
                (
                  value: {
                    dateDebut: string;
                    dateFin: string;
                  },
                  index: number,
                ) => {
                  return (
                    <div key={index} style={{ marginBottom: '2%' }}>
                      {renderFieldValue(
                        <>
                          Du {moment(value.dateDebut).format(FormatDate.FRENCH_DATE)} au{' '}
                          {value.dateFin
                            ? moment(value.dateFin).format(FormatDate.FRENCH_DATE)
                            : moment(value.dateDebut).add(6, 'days').format(FormatDate.FRENCH_DATE)}
                        </>,
                      )}
                    </div>
                  );
                },
              )}
            </div>
          </div>
        )}

        {document.optionnal && (
          <div className="items-validation row col-12 mb-3">
            <div className="number-validation col-1">4</div>
            <div className="item-validation col-10">
              <div className="title-validation col-12">OPTIONNELS</div>
              {document.optionnal.isClickedOption1 &&
                renderFieldValue('Je souhaite réduire mon temps de travail quotidien')}
              {document.optionnal.isClickedOption2 && renderFieldValue('Je souhaite bénéficier du congé supra légal')}
            </div>
          </div>
        )}

        <div className="text-muted mt-3">CONTACTS</div>
        <hr className="hr-kerij col-12" />

        <div className="items-validation row col-12 mb-3" style={{ marginTop: '10px' }}>
          <div className="item-validation col-10">
            {document.message ? (
              <div className="items-validation row col-12 mb-3">
                <div className="col-1" style={{ width: '25px', height: '25px' }}></div>
                <div className="item-validation col-10">
                  <div className="title-validation col-12">MESSAGE</div>
                  {renderFieldValue(document.message)}
                </div>
              </div>
            ) : (
              <div className="items-validation row col-12 mb-3">
                <div className="item-validation col-10" style={{ fontWeight: '900' }}>
                  Vous n'avez pas laissé de message à votre manager
                </div>
              </div>
            )}
            {document?.contact?.mail ? (
              <div className="items-validation row col-12 mb-3">
                <div className="number-validation col-1">1</div>
                <div className="item-validation col-10">
                  <div className="title-validation col-12">E-MAIL</div>
                  {renderFieldValue(document.contact.mail)}
                </div>
              </div>
            ) : null}
            {document?.contact?.phone ? (
              <div className="items-validation row col-12 mb-3">
                <div className="number-validation col-1">{document?.contact?.mai ? 2 : 1}</div>
                <div className="item-validation col-10">
                  <div className="title-validation col-12">TÉLÉPHONE</div>
                  {renderFieldValue(document.contact.phone)}
                </div>
              </div>
            ) : null}
            {document?.contact?.contactType == null && (
              <div className="items-validation row col-12 mb-3">
                <div className="item-validation col-10" style={{ fontWeight: '900' }}>
                  Vous ne souhaitez pas être contacté pendant votre absence
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row col-12 col-lg-8 doc-validation shadow p-5 mb-3 footer-validation text-center container-button">
        <button type="button" className="col-10 col-xl-6 btn btn-kerij-blanc text-center" onClick={handleClick}>
          Je valide cette déclaration
        </button>
      </div>
      <div className="col-12 col-md-8 text-center mb-5">
        <KRJButton
          color="secondary"
          style={{ width: '100%', maxWidth: '16rem' }}
          onClick={() => {
            navigate(RouterConfig.declarationQuestionsMessagePage().path, {
              state: {
                ...location.state,
                document,
              },
            });
          }}
        >
          Précédent
        </KRJButton>
      </div>
    </div>
  );

  return (
    <DefaultLayout>
      <StepProgressBar type={absenceType} />
      {error && <KerijBox type={KerijBoxType.Error} message={error} />}
      {loading ? (
        <KerijLoading type={KerijLoadingType.VALIDATION} />
      ) : isUpdate ? (
        isAbsenceUpdated ? (
          <DepotDocumentHistorique />
        ) : null
      ) : (
        <DeclarationSynthese />
      )}
    </DefaultLayout>
  );
};
