import moment from 'moment';
import 'moment/locale/fr';
import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import ReactLoading from 'react-loading';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../../Logotype-Simple-Fondclair.svg';
import { PRO_URL } from '../../../constants/urls.constants';
import axios from '../../../lib/axios';
import { fetchRhDateInterview, verifySelectedDateEmployee } from '../../../services/rh';
import './ChoixDateSalarie.css';
import { verifyToken } from '../../../services/auth';
import { useToast } from '@/components/Toast';

const ChoixDateSalarie = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [err, setErr] = useState('');
  const token = searchParams.get('token_decode');

  const [decode, setDecode] = useState({
    idRhNotification: '',
    idEmployeeInformation: '',
    idBusiness: '',
    maxLevel: '',
    type_contact: '',
  });

  const [dateEntretien, setDateEntretien] = useState<any>([]);
  const [access, setAccess] = useState(true);
  const [select, setSelect] = useState<any>();
  const [type, setType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [lieuEntretien, setLieuEntretien] = useState('1');

  useEffect(() => {
    if (token) {
      verifyToken({}, token)
        .then((val) => {
          if (val.status === 200) {
            setDecode(val.data.decode);
          }
        })
        .catch(() => {
          toast.present({ message: 'Connexion interrompue', severity: 'error' });
        });
    }
  }, []);

  useEffect(() => {
    const handleVerify = async () => {
      const response = await verifySelectedDateEmployee({
        idRhNotification: decode.idRhNotification,
        idBusiness: decode.idBusiness,
      });

      if (response.status === 200) {
        setType(response.data.typeNotif);
        if (response.data.isNotif) {
          setAccess(false);
        }
        setLieuEntretien(response.data.typeDemandeInterview === 'present' ? '1' : '2');
      }

      const responseDate = await fetchRhDateInterview({
        idRhNotification: decode.idRhNotification,
        idBusiness: decode.idBusiness,
      });
      if (responseDate.status === 200) {
        setDateEntretien(responseDate.data.dateInterview);
      }
    };
    if (decode.idRhNotification && decode.idEmployeeInformation && decode.idBusiness) {
      handleVerify();
      setAccess(true);
    } else {
      setAccess(false);
    }
  }, [decode]);

  return (
    <div>
      <div className="px-4 my-3">
        <Link className="text-link mt-4 pt-5" to={PRO_URL.PRO} style={{ color: 'inherit', textDecoration: 'inherit' }}>
          <img src={logo} className="App-logo" alt="logo" width="73" />
        </Link>
      </div>
      {isLoading ? (
        <div className="container-fluid container-loading">
          <h2>Veuillez patienter</h2>
          <div className="container-load">
            <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
          </div>
          {err && <div>{err}</div>}
        </div>
      ) : (
        <div>
          {access ? (
            <div className="container-fluid mt-5">
              <div className="row col-12 col-lg-8 mb-5 text-center">
                <div className="text-center col-12 mb-5">
                  <h1 className="titre-rh titre-details">
                    Planification{' '}
                    {((type === 'Entretien de liaison' || type === 'Planifier entretien de liaison') &&
                      "d'entretien de liaison") ||
                      "d'entretien de retour à l'emploi"}
                  </h1>
                </div>

                {/* {dataAbsences &&  */}
                <div>
                  {/* <h3 className="question-form titre-modal mb-3 mt-5">{dataAbsences.salarie_information.PRENOM + " " + dataAbsences.salarie_information.NOM_USAGE} a sollicité un entretien de liaison</h3> */}

                  <h3 className="question-form mb-5 mt-5">Voici les dates proposées</h3>

                  <h5 className="question-form mb-5 mt-3">
                    Les 3 créneaux proposés par votre manager pour votre{' '}
                    {((type === 'Entretien de liaison' || type === 'Planifier entretien de liaison') &&
                      'entretien de liaison') ||
                      (type === "Entretien de retour a l'emploi" && "entretien de retour à l'emploi")}{' '}
                    sont :
                  </h5>
                  <div className="col-12 text-center row mt-5">
                    {dateEntretien.length > 0 &&
                      dateEntretien.map((value: any, index: number) => {
                        return (
                          <div key={index}>
                            <div className="col-12 mb-3">
                              <b>
                                {(index === 0 && 'Première proposition') ||
                                  (index === 1 && 'Deuxième proposition') ||
                                  'Troisième proposition'}
                              </b>
                            </div>
                            <div className="col-xl-6 col-12 row offset-2 offset-xl-3">
                              <div className="col-12 row">
                                <input
                                  className="col-1 col-xl-4 text-end radio-date"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  onClick={() => {
                                    setSelect(index);
                                  }}
                                />
                                <div className="text-start col-11 col-xl-8">
                                  <label className="text-proposition-date" htmlFor="flexRadioDefault1">
                                    Le {moment(value.date).format('DD/MM/YYYY')} de{' '}
                                    {moment(value.from, 'HH:mm:ss').format('HH:mm')} à{' '}
                                    {moment(value.to, 'HH:mm:ss').format('HH:mm')}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <hr className="col-8 col-xl-6 offset-2 offset-xl-3 text-center my-3"></hr>
                          </div>
                        );
                      })}
                  </div>

                  <div className="text-center mt-3 col-12">
                    <button
                      type="button"
                      className={typeof select === 'number' ? 'btn btn-valid-date' : 'btn btn-valid-date disabled'}
                      onClick={() => {
                        setIsLoading(true);
                        axios
                          .post('/api/token/encode', {
                            idRhNotification: decode.idRhNotification,
                            idBusiness: decode.idBusiness,
                          })
                          .then((val) => {
                            if (val.status === 200) {
                              const token = val.data.token;
                              axios
                                .post(
                                  '/api/rh/validate_suggested_date',
                                  {
                                    idDateInterview: dateEntretien?.at(select).id,
                                    type: type,
                                    typeInterview: lieuEntretien,
                                    maxLevel: decode.maxLevel,
                                    type_contact: decode.type_contact,
                                    place: lieuEntretien,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                    },
                                  },
                                )
                                .then((value) => {
                                  if (value.status === 200) {
                                    navigate(PRO_URL.PREFIXE_PRO + PRO_URL.DATE_CHOICE_EMPLOYEE_END);
                                  }
                                })
                                .catch((error) => {
                                  alert(JSON.stringify(error.response));
                                  setErr(JSON.stringify(error.response));
                                });
                            }
                          });
                      }}
                    >
                      Valider mon choix
                    </button>
                  </div>
                  {type !== 'Planifier entretien de retour' && (
                    <div className="text-center mt-3 col-12">
                      <button
                        type="button"
                        className="btn btn-kerij-outline-choix"
                        onClick={() => {
                          setIsLoading(true);
                          axios
                            .post('/api/token/encode', {
                              idRhNotification: decode.idRhNotification,
                              idBusiness: decode.idBusiness,
                            })
                            .then((val) => {
                              if (val.status === 200) {
                                const token = val.data.token;
                                axios
                                  .post(
                                    '/api/rh/validate_suggested_date',
                                    {
                                      type: type,
                                      maxLevel: decode.maxLevel,
                                      type_contact: decode.type_contact,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${token}`,
                                      },
                                    },
                                  )
                                  .then((value) => {
                                    if (value.status === 200) {
                                      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.DATE_CHOICE_EMPLOYEE_END);
                                    }
                                  })
                                  .catch((error) => alert(JSON.stringify(error.response)));
                              }
                            });
                        }}
                      >
                        Je ne souhaite{' '}
                        {((type === 'Entretien de liaison' || type === 'Planifier entretien de liaison') &&
                          "plus d'entretien de liaison") ||
                          "plus d'entretien de retour à l'emploi"}
                      </button>
                    </div>
                  )}
                  <div className="text-center mt-3 col-12">
                    <button
                      type="button"
                      className="btn btn-kerij-outline-choix"
                      onClick={() => {
                        setIsLoading(true);
                        axios
                          .post('/api/token/encode', {
                            idRhNotification: decode.idRhNotification,
                            idBusiness: decode.idBusiness,
                          })
                          .then((val) => {
                            if (val.status === 200) {
                              const token = val.data.token;
                              axios
                                .post(
                                  '/api/rh/validate_suggested_date',
                                  {
                                    reason: 'invalable',
                                    type: type,
                                    maxLevel: decode.maxLevel,
                                    type_contact: decode.type_contact,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                    },
                                  },
                                )
                                .then((value) => {
                                  if (value.status === 200) {
                                    navigate('/choixfin');
                                  }
                                })
                                .catch((error) => console.log(error));
                            }
                          });
                      }}
                    >
                      Aucun créneau ne me convient
                    </button>
                  </div>
                </div>

                {/* }   */}
              </div>
            </div>
          ) : (
            <div className="container-fluid mt-5">
              <h1 className="question-form mb-5">
                {`Attention ! Vous n'avez pas l'autorisation d'accéder à cette page`}
              </h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChoixDateSalarie;
