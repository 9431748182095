import axios from '@/lib/axios';
import { Parcours } from '@/types/Parcours';
import { AxiosResponse } from 'axios';

export const parcoursCreate = async (params?: parcoursCreate.Parameters) => {
  return await axios.put(`/api/rh/parcours`, params);
};

export namespace parcoursCreate {
  export type Parameters = Pick<Parcours, 'name'>;
  export type Return = AxiosResponse;
}
