export const CodesAbsences: { [key: string]: string | null } = {
  MALADIE: 'MA',
  MALADIE_1: null,
  ACCIDENT_TRAVAIL: 'AT',
  ACCIDENT_TRAJET: 'TJ',
  MALADIE_PRO: 'PM',
  HOSPITALISATION: 'MA',
  MATERNITE: 'MT',
  MATERNITE_2: 'MATF2',
  MATERNITE_DROG: 'DERONA',
  MATERNITE_PATHO: 'MP',
  PATERNITE: 'PA',
  PATERNITE_QVT: 'PAQVT',
  PARENTALITE: 'AN',
} as const;

export const CodesAbsencesMaladie = [CodesAbsences.MALADIE] as const;
export const CodesAbsencesAccident = [
  CodesAbsences.MALADIE_PRO,
  CodesAbsences.ACCIDENT_TRAJET,
  CodesAbsences.ACCIDENT_TRAVAIL,
] as const;
export const CodesAbsencesParentalite = [
  CodesAbsences.MATERNITE,
  CodesAbsences.MATERNITE_2,
  CodesAbsences.MATERNITE_DROG,
  CodesAbsences.MATERNITE_PATHO,
  CodesAbsences.PATERNITE,
  CodesAbsences.PATERNITE_QVT,
  CodesAbsences.PARENTALITE,
] as const;
export const CodesAbsencesHospitalisation = [CodesAbsences.HOSPITALISATION] as const;

export type CodeAbsence = (typeof CodesAbsences)[keyof typeof CodesAbsences];
