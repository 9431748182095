import React from 'react';
import '../DashboardRH.css';
import useCheckRhAuthentication from '@/shared/hooks/chechRhAuthentication.hooks';
import ProgressAbsence from './ProgressAbsence';
import ToDoAction from './ToDoAction';
import WelcomeStats from './WelcomeStats';
import { RHPage } from '@/components/RHPage';

function AccueilRH() {
  useCheckRhAuthentication();
  return (
    <RHPage title="Dashboard">
      <div className="row">
        <div className="col-6">
          <ProgressAbsence />
        </div>
        <div className="col-6">
          <ToDoAction />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <WelcomeStats />
        </div>
      </div>
    </RHPage>
  );
}

export default AccueilRH;
