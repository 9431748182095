import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import { ServicesUI } from './ServicesUI';
import RequireLogin from '../../../components/RequireLogin';

export const ServicesPage: React.FC = () => {
  return (
    <RequireLogin>
      <DefaultLayout>
        <ServicesUI />
      </DefaultLayout>
    </RequireLogin>
  );
};
