import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@/components/Button';
import { Spacer } from '../Spacer';
import { StatusCodes } from 'http-status-codes';
import { verifyEmployee } from '../../services/employee';
import { AppTextField } from '../AppTextField';
import { AppTextFieldHelper } from '../AppTextFieldHelper';
import { Checkbox, FormControlLabel, InputAdornment } from '@mui/material';
import { AppTooltipInfo } from '../AppTooltipInfo';
import { useToast } from '../Toast';

export function Signup() {
  const toast = useToast();
  const navigate = useNavigate();
  const state = useLocation();

  const isEmpty = (value?: string): value is '' | undefined => value == null || value.trim() === '';
  // inclut la Corse et les DOM/TOM
  const isValidDepartment = (value: string) => /^(0[1-9]|[1-8]\d|9[0-5]|2A|2B|97[1-8]|98[1-8]|99)$/.test(value);
  const isValidCodePostal = (value?: string) =>
    !isEmpty(value) && /^(0[1-9]|[1-8]\d|9[0-5]|2A|2B|97|98)\d{3}$/.test(value);

  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    prenom: '',
    dateNaissance: '',
    dptNaissance: '',
    codePostal: '',
    checkbox: false,
  });

  const isValideForm = () => {
    return (
      !isEmpty(form.prenom) &&
      !isEmpty(form.dateNaissance) &&
      isValidCodePostal(form.codePostal) &&
      isValidDepartment(form.dptNaissance)
    );
  };

  const handleInputChange = (key: string, value: string) => {
    setForm({ ...form, [key]: value });
  };

  const handleCheckboxChange = (value: boolean) => {
    setForm((previousForm) => ({
      ...previousForm,
      checkbox: value,
      dptNaissance: value ? '99' : '',
    }));
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    setIsSubmitting(true);

    if (isValideForm()) {
      try {
        const response = await verifyEmployee(form);
        setIsSubmitting(false);

        if (response?.status === StatusCodes.NOT_FOUND)
          return toast.present({ message: response?.data.message, severity: 'error' });
        if (response?.status === StatusCodes.CONFLICT) {
          navigate('/verifyselect', {
            state: {
              salarie: response?.data.conflicts,
              document: state.state?.document,
              page: state.state?.page,
              form: {
                firstname: form.prenom,
                birthday: form.dateNaissance,
                birthCommun: form.dptNaissance,
                zipcode: form.codePostal,
              },
            },
          });
        } else if (response?.data) {
          const hasAccount = !!response?.data.employee_login;
          navigate('/verify', {
            state: {
              salarie: response?.data,
              hasAccount,
              document: state.state?.document,
              page: state.state?.page,
            },
          });
        }
      } catch {
        setIsSubmitting(false);
        return toast.present({ message: 'Une erreur est survenue, veuillez ressayer', severity: 'error' });
      }
    } else {
      setIsSubmitting(false);
      if (isEmpty(form.prenom) || isEmpty(form.dateNaissance))
        return toast.present({ message: "Vous n'avez pas rempli tous les champs requis", severity: 'error' });
      else if (!isValidDepartment(form.dptNaissance))
        return toast.present({ message: 'Votre département de naissance ne semble pas correct', severity: 'error' });
      else if (!isValidCodePostal(form.codePostal))
        return toast.present({ message: 'Votre code postal ne semble pas correct', severity: 'error' });
    }
  };

  return (
    <div className="text-center">
      <div className="container-fluid">
        <div className="row mb-3 col-lg-4">
          <Spacer />
          <h1 className="question-form text-center">Activation du dossier personnel</h1>
          <Spacer />

          <form>
            <AppTextField
              label={'Votre prénom'}
              value={form.prenom}
              error={isEmpty(form.prenom) && isSubmit}
              onChange={(_, value) => handleInputChange('prenom', value)}
              required
            />

            <AppTextFieldHelper label="Votre date de naissance" error={isEmpty(form.dateNaissance) && isSubmit}>
              <input
                value={form.dateNaissance}
                type="date"
                required
                placeholder="Date de Naissance"
                onChange={(event) => handleInputChange('dateNaissance', event.target.value)}
              />
            </AppTextFieldHelper>

            <FormControlLabel
              label={'Êtes-vous né(e) à l’étranger ?'}
              sx={{ marginTop: 3, marginBottom: 2 }}
              control={
                <Checkbox
                  style={{ marginRight: '0.5rem' }}
                  checked={form.checkbox}
                  onChange={(_, isCheck) => handleCheckboxChange(isCheck)}
                />
              }
            />

            <AppTextField
              label={'Votre département de naissance'}
              value={form.dptNaissance}
              InputProps={{
                placeholder: 'Exemple : 59',
                endAdornment: (
                  <InputAdornment position="end">
                    <AppTooltipInfo title={'Veuillez entrer un numéro de département à deux chiffres'} />
                  </InputAdornment>
                ),
              }}
              error={!isValidDepartment(form.dptNaissance) && isSubmit}
              disabled={form.checkbox}
              onChange={(_, value) => handleInputChange('dptNaissance', value)}
              required
            />

            <AppTextField
              label={`Votre code postal d'habitation`}
              value={form.codePostal}
              InputProps={{
                placeholder: 'Exemple : 59193',
                endAdornment: (
                  <InputAdornment position="end">
                    <AppTooltipInfo title={'Veuillez entrer un code postal à cinq caractères'} />
                  </InputAdornment>
                ),
              }}
              error={!isValidCodePostal(form.codePostal) && isSubmit}
              onChange={(_, value) => handleInputChange('codePostal', value)}
              required
            />

            <Spacer />
            <Button
              color="primary"
              type="button"
              disabled={isSubmitting}
              submitting={isSubmitting}
              onClick={handleSubmit}
              style={{ width: '100%' }}
            >
              Valider
            </Button>
            <Spacer />
            <Button color="secondary" onClick={() => navigate('/login')}>
              {"J'ai déjà un identifiant"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
