/* eslint-disable @typescript-eslint/ban-ts-comment */
import { absenceType, toSelect } from '../constants/absenceType.constant';
import { statementStep } from '../constants/step.constants';
import { AbsenceCreation, StepProperties } from '../props/step.interface';
import React from 'react';
import TitleProcess from './TitleProcess';

const getTypeAbsence1 = (absence: AbsenceCreation) => {
  let options: any[] = [];
  switch (absence.absenceType) {
    case '3': {
      options = [
        { value: toSelect, label: 'Choisir la valeur' },
        { value: '1', label: '1 enfant' },
        { value: '2', label: '2 enfants' },
        { value: '3', label: '3 enfants ou plus' },
      ];
      break;
    }
    case '4': {
      options = [
        { value: toSelect, label: 'Choisir la valeur' },
        { value: '1', label: '1 enfant' },
        { value: '2', label: '2 enfants ou plus' },
      ];
      break;
    }
    default: {
      break;
    }
  }
  return options.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));
};

export const AbsenceType1 = ({ absence, setAbsence, handleChangeStep }: StepProperties) => {
  const handleChangeTypeAbsence1 = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (event.target.value) {
      case toSelect.toString(): {
        // @ts-ignore absenceType1 est un string
        setAbsence((absence: AbsenceCreation) => ({
          ...absence,
          absenceType1: toSelect,
          dateDebut: '',
          dateFin: '',
          period: [{ dateDebut: '', dateFin: '', error: -1 }],
        }));
        handleChangeStep(statementStep.TypeAbsence1);
        break;
      }
      default: {
        // @ts-ignore absenceType1 est un string
        setAbsence((absence: AbsenceCreation) => ({
          ...absence,
          absenceType1: event.target.value,
          absenceType2: toSelect,
          dateDebut: '',
          dateFin: '',
          period: [{ dateDebut: '', dateFin: '', error: -1 }],
        }));
        if (absence.absenceType === absenceType.conge_parentalite.toString()) {
          handleChangeStep(statementStep.Date);
        } else {
          if (event.target.value === '1') {
            handleChangeStep(statementStep.TypeAbsence2);
          } else {
            handleChangeStep(statementStep.Date);
          }
        }
        break;
      }
    }
  };

  return ['3', '4'].includes(absence.absenceType) ? (
    <div>
      <TitleProcess title="Combien d'enfants l'employé attend ?" />
      <div className="form-declaration text-center col-lg-12">
        <label className="col-8">
          <select
            className="form-select"
            name="absenceType1"
            value={absence.absenceType1}
            onChange={handleChangeTypeAbsence1}
            required
          >
            {getTypeAbsence1(absence)}
          </select>
        </label>
      </div>
    </div>
  ) : (
    <></>
  );
};
