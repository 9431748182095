import React, { useEffect } from 'react';
import { fetchEmployeeProfile } from '../../services/employee';
import { removeAuthData } from '../../utils/auth.utils';
import { UserTypes } from '../../types/userTypes.enum';
import jwtDecode from 'jwt-decode';
import { fetchRhProfile } from '../../services/rh';
import {
  AccountContext,
  Feature,
  Notification,
  RHAccountContext,
  RHNavbarContext,
  useAppDispatch,
  useAppSelector,
} from '@/store';
import axios from 'axios';
import { useAppWorkspace } from '@/hooks/useAppWorkspace';
import moment from 'moment';
import ModalAcceptNewCGU from '@/components/ModalAcceptNewCGU/ModalAcceptNewCGU';
import ModalResetPassword from '@/components/ModalResetPasswordRH/ModalResetPasswordRH';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line unicorn/prefer-set-has
const employeeRoute = ['/', '/profil', '/declaration', '/services', '/rdv', '/activites'];
const rhRoute = ['/pro', '/pro/absences', '/pro/actions', '/pro/stats', '/pro/admin', '/pro/tools'];

interface AccountRhWithReducerProps {
  children: React.ReactNode;
}

const AccountRhWithReducer = ({ children }: AccountRhWithReducerProps) => {
  const dispatch = useAppDispatch();
  const appWorkspace = useAppWorkspace();
  const user = useAppSelector(AccountContext.getUser);
  const rh = useAppSelector(RHAccountContext.getProfile);
  const isRoutePro = appWorkspace.match('pro');
  const location = useLocation();

  useEffect(() => {
    const initEmployee = async () => {
      if (!appWorkspace.match('employee')) {
        return; // skip
      }
      await dispatch(Feature.load()); // TODO: le chargement sera probablement conditionné par l'idBusiness il faudra donc le recharger dès qu'il change

      const token = localStorage.getItem('access_token_employee');
      if (token) {
        await dispatch(Notification.load());
        try {
          const employeeProfile = await fetchEmployeeProfile();

          if (employeeProfile.data.last_change_password) {
            dispatch(AccountContext.actions.setUser(employeeProfile.data));
            dispatch(AccountContext.actions.setLoading(false));
          } else {
            removeAuthData(UserTypes.EMPLOYEE);
          }
        } catch (error) {
          removeAuthData(UserTypes.EMPLOYEE);
          throw error;
        }
      } else {
        dispatch(AccountContext.actions.setUser(null));
      }
    };
    const initRh = async () => {
      if (!appWorkspace.match('pro')) {
        return; // skip
      }
      await dispatch(Feature.load()); // TODO: le chargement sera probablement conditionné par l'idBusiness il faudra donc le recharger dès qu'il change

      const token = localStorage.getItem('access_token_rh');
      if (token) {
        const decodedToken: any = jwtDecode(token);
        try {
          // TODO: Use RHAccountContext.load()
          const value = await fetchRhProfile();
          dispatch(RHAccountContext.actions.setRh(value.data.rh));
          dispatch(RHAccountContext.actions.setAdmin(Boolean(decodedToken.isAdmin)));
          dispatch(RHAccountContext.actions.setPermissionScopes(value.data.rh?.permission_scopes ?? {}));
          if (value.data.rh.rh_information.id_business) {
            dispatch(RHNavbarContext.actions.setSelectedIdBusiness(value.data.rh.rh_information.id_business));
          }
          if (value.data.rh.rh_information.id_businesses) {
            dispatch(
              RHNavbarContext.actions.setSelectedIdBusiness(
                value.data.rh.rh_information.id_business ||
                  JSON.parse(value.data.rh.rh_information.id_businesses).at(0),
              ),
            );
          }
          if (
            !value.data.rh.rh_information.id_business &&
            !value.data.rh.rh_information.id_group &&
            value.data.rh.rh_information.id_parent_company &&
            value.data.rh.rh_information.id_businesses
          ) {
            axios
              .get('/api/rh/admin/get_business', {
                params: {
                  listIdBusiness: JSON.stringify(JSON.parse(value.data.rh.rh_information.id_businesses)),
                },
              })
              .then((value_) => {
                dispatch(
                  RHNavbarContext.actions.setListSelectBusiness(
                    value_.data.businesses.map((v: any) => {
                      v.isChecked = true;
                      return v;
                    }),
                  ),
                );
                dispatch(RHNavbarContext.actions.setListBusiness(value_.data.businesses.map((v: any) => v.id)));
              });
          }
          if (
            !value.data.rh.rh_information.id_business &&
            !value.data.rh.rh_information.id_parent_company &&
            value.data.rh.rh_information.id_group &&
            value.data.rh.rh_information.id_parent_companies
          ) {
            axios
              .get('/api/rh/admin/get_company', {
                params: {
                  listIdCompany: JSON.stringify(
                    Object.keys(JSON.parse(value.data.rh.rh_information.id_parent_companies)),
                  ),
                },
              })
              .then((value_) => {
                dispatch(
                  RHNavbarContext.actions.setListSelectCompany(
                    value_.data.companies.map((v: any) => {
                      v.isChecked = false;
                      return v;
                    }),
                  ),
                );
              });
          }

          dispatch(RHAccountContext.actions.setLoading(false));
        } catch (error) {
          removeAuthData(UserTypes.HR);
          throw error;
        }
      } else {
        // FIXME: change this behavior !!
        dispatch(RHAccountContext.actions.reset());
      }
    };

    initEmployee().catch(console.error);
    initRh().catch(console.error);
  }, []);

  return (
    <>
      {children}
      {user &&
      moment().diff(moment(user.last_change_password), 'months') >= 6 &&
      employeeRoute.some((route) => location.pathname.startsWith(route)) ? (
        <ModalResetPassword type={'employee'} />
      ) : (
        <></>
      )}
      {rh &&
      // @ts-ignore FIXME: wrong typing
      moment().diff(moment(rh.last_change_password), 'months') >= 6 &&
      rhRoute.some((route) => location.pathname.startsWith(route)) ? (
        <ModalResetPassword type={'rh'} />
      ) : (
        <></>
      )}
      {user && !user.cgu_checked && !isRoutePro ? <ModalAcceptNewCGU /> : <></>}
    </>
  );
};

export default AccountRhWithReducer;
