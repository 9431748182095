import * as React from 'react';
import classNames from 'clsx';
import { IfPermission, type IfPermissionProps } from '@/components/IfPermission';
import { Box, Typography, type BoxProps } from '@mui/material';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { Link } from 'react-router-dom';
import { PRO_URL } from '@/constants/urls.constants';
import { Button } from '@/components/Button';

// Name and options
const componentName = 'PermissionBox';

// Component properties
export interface PermissionBoxProps extends BoxProps, IfPermissionProps {}

export function PermissionBox(props: PermissionBoxProps) {
  const { scope, action, ...otherProps } = props;

  return (
    <Box className={classNames(componentName, props.className)} {...otherProps}>
      <IfPermission
        scope={scope}
        action={action}
        fallback={
          <Box
            className={`${componentName}__NoPermission`}
            sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <Typography variant="h5" sx={{ p: 2 }}>
              {"Attention ! Vous n'avez pas l'autorisation d'accéder à cette page"}
            </Typography>
            <Box sx={{ p: 2 }}>
              <LockPersonIcon sx={{ fontSize: 128 }} color="action" />
            </Box>
            <Box sx={{ p: 2 }}>
              <Link to={PRO_URL.PREFIXE_PRO + PRO_URL.ACCUEIL}>
                <Button color="secondary">{"Retour à l'accueil"}</Button>
              </Link>
            </Box>
          </Box>
        }
      >
        {props.children}
      </IfPermission>
    </Box>
  );
}
