import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Signup.css';

function CreatePassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  var [error, setError] = useState('');
  const state = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state.state) {
      navigate('/signup');
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError('Attention, les mots de passe ne correspondent pas.');
    } else {
      navigate('/createcontact', {
        state: { ...state.state, password: password },
      });
    }
  };

  return (
    <div>
      <div className="container-fluid" align="center">
        <form onSubmit={handleSubmit} className="mt-5 mb-5 col-lg-4">
          <h3 className="question-form mb-5">Création d'un mot de passe</h3>

          <div className="input-group">
            <label>Mot de passe</label>
            <div className="label-password">
              <input
                name="password"
                type={!showPassword ? 'password' : 'text'}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                value={password}
                className="input-password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <FontAwesomeIcon
                icon={!showPassword ? faEye : faEyeSlash}
                className="icon-show-password"
                onClick={() => setShowPassword(!showPassword)}
              ></FontAwesomeIcon>
            </div>
          </div>
          <div className="msg-pass">
            <em>
              Votre mot de passe doit être composé de 8 caractères minimum, au moins une lettre majuscule, au moins une
              lettre minuscule, et une valeur numérique.
            </em>
          </div>
          <div className="input-group">
            <label>Confirmation du mot de passe</label>
            <div className="label-password">
              <input
                name="confirmpassword"
                type={!showConfirmPassword ? 'password' : 'text'}
                value={confirmPassword}
                className="input-password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                required
              />
              <FontAwesomeIcon
                icon={!showConfirmPassword ? faEye : faEyeSlash}
                className="icon-show-password"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              ></FontAwesomeIcon>
            </div>
          </div>
          <div className="err">{error}</div>

          <button className="btn-submit mt-5">Valider</button>
        </form>
      </div>
    </div>
  );
}

export default CreatePassword;
