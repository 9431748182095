import React from 'react';
import { RadioGroup, FormControlLabel, Radio, FormControl } from '@mui/material';

export interface DeclarationContactProps {
  value: boolean;
  onChange: (newValue: boolean) => void;
}

export function DeclarationContact(props: DeclarationContactProps) {
  const { value, onChange } = props;

  return (
    <>
      <FormControl sx={{ transform: 'translateX(50%)' }}>
        <RadioGroup row value={value} onChange={(event) => onChange(event.target.value === 'true')}>
          <FormControlLabel value={'true'} control={<Radio />} label="Oui" sx={{ marginRight: '3rem' }} />
          <FormControlLabel value={'false'} control={<Radio />} label="Non" />
        </RadioGroup>
      </FormControl>
    </>
  );
}
