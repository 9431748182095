import { Snackbar as MUISnackbar, styled } from '@mui/material';

export const Snackbar = styled(MUISnackbar)(({ theme }) => ({
  padding: 0,
  '& .MuiPaper-root': {
    padding: 0,
    margin: 0,
    fontSize: 14,
  },
}));
