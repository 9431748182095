import React from 'react';
import { Absence } from '../../../types/absences.type';
import { AbsenceDate } from '../Absences/AbsenceDate';
import { Box } from '@mui/material';
import { AbsenceLabel } from '../Absences/AbsenceLabel';
import { AbsenceTypeConversion } from '../Absences/AbsenceTypeConversion';

interface ChangeTypeAbsenceResultProperties {
  absences?: Absence[];
}

export const ChangeTypeAbsenceResult = ({ absences }: ChangeTypeAbsenceResultProperties) => {
  return (
    <>
      {absences &&
        absences.map((absence: Absence) => (
          <>
            <div className="mt-2">
              <Box>
                <AbsenceLabel absence={absence} through />
                {' ➜ '}
                <AbsenceTypeConversion absence={absence} />
              </Box>
              <AbsenceDate value={absence} />
            </div>
          </>
        ))}
    </>
  );
};
