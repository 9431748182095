import axios from '@/lib/axios';
import { Parcours } from '@/types/Parcours';
import { AxiosResponse } from 'axios';

export const parcoursDelete = async (id: Parcours['id'], params?: parcoursDelete.Parameters) => {
  return await axios.delete(`/api/rh/parcours/${id}`, { params });
};

export namespace parcoursDelete {
  export interface Parameters {}
  export type Return = AxiosResponse;
}
