/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { useAppSelector, RHAccountContext, useAppDispatch, RHNavbarContext, RHNotification } from '@/store';
import { Business } from '@/types';
import axios from 'axios';
import { SocketContext } from '@/contexts/socket.context';
import isAuthorized from '@/hooks/isAuthorized';

export interface LayoutCompanyProps {
  rh?: RHAccountContext.RhContext;
}

export function LayoutCompany(props: LayoutCompanyProps) {
  const { rh } = props;
  const dispatch = useAppDispatch();
  const socketClient = React.useContext(SocketContext);

  const [isShowBusiness, setIsShowBusiness] = React.useState(false);
  const [isShowCompany, setIsShowCompany] = React.useState(false);

  const listSelectBusiness = useAppSelector(RHNavbarContext.getBusinessSelectList);
  const selectedIdBusiness = useAppSelector(RHNavbarContext.getSelectedBusinessId);
  const listSelectCompany = useAppSelector(RHNavbarContext.getCompanySelectList);
  const listBusiness = useAppSelector(RHNavbarContext.getBusinessList);

  const _setSelectedIdBusiness = (value: string | null) => {
    dispatch(RHNavbarContext.actions.setSelectedIdBusiness(value));
  };

  const _setListSelectBusiness = (value: RHNavbarContext.BusinessEntry[]) => {
    dispatch(RHNavbarContext.actions.setListSelectBusiness(value));
  };

  const _setListBusiness = (value: Business['id'][]) => {
    dispatch(RHNavbarContext.actions.setListBusiness(value));
  };

  const _setListSelectCompany = (value: RHNavbarContext.CompanyEntry[]) => {
    dispatch(RHNavbarContext.actions.setListSelectCompany(value));
  };

  const handleSelectBusiness = (id: string) => {
    const newData = listSelectBusiness.map((value) => {
      if (value.id === id) {
        if (value.isChecked) {
          const data = listBusiness.filter((value_) => value_ !== id);
          _setListBusiness(data);
        } else {
          _setListBusiness([...listBusiness, id]);
        }
        value.isChecked = !value.isChecked;
      }
      return value;
    });
    _setListSelectBusiness(newData);
  };

  const handleSelectCompany = (id: string) => {
    const newData = listSelectCompany.map((value) => {
      if (value.id === id) {
        value.isChecked = true;
        // @ts-ignore FIXME: problème de typage
        const listIdBusiness = JSON.parse(rh.rh_information.id_parent_companies)[id];
        axios
          .get('/api/rh/admin/get_business', {
            params: {
              listIdBusiness: JSON.stringify(listIdBusiness),
            },
          })
          .then((value_) => {
            _setListSelectBusiness(
              value_.data.businesses.map((v: RHNavbarContext.BusinessEntry) => {
                v.isChecked = true;
                return v;
              }),
            );
            _setSelectedIdBusiness(listIdBusiness.at(0));
          });
        _setListBusiness(listIdBusiness);
      } else {
        value.isChecked = false;
      }
      return value;
    });

    _setListSelectCompany(newData);
  };

  const updateLength = () => {
    if (isAuthorized(location)) {
      dispatch(RHNotification.updateTotal());
    }
  };

  React.useEffect(() => {
    socketClient?.connect();
    return socketClient?.disconnect();
  }, [socketClient]);

  React.useEffect(() => {
    // @ts-ignore FIXME: problème de typage
    socketClient?.updateLength(updateLength);

    return () => {
      // @ts-ignore FIXME: problème de typage
      socketClient?.unsubscribeUpdateLength(updateLength);
    };
  }, [socketClient, listSelectBusiness, selectedIdBusiness]);

  React.useEffect(() => {
    if (isAuthorized(location)) {
      if (selectedIdBusiness) {
        updateLength();
      }
    }
  }, [selectedIdBusiness]);

  return (
    <>
      {rh && (
        <>
          {listSelectCompany.length > 0 && (
            <div className="text-center col-10 offset-1 mb-1">
              <span
                className="col-12"
                onClick={() => setIsShowCompany(true)}
                onMouseLeave={() => setIsShowCompany(false)}
                style={{ cursor: 'pointer' }}
              >
                <div className="form-select mt-0 dropdown-business-vertical">
                  <div
                    className="text-start short-text business-nav"
                    style={{
                      height: '20px',
                      fontSize: '12px',
                    }}
                  >
                    {listSelectCompany.some((value) => value.isChecked)
                      ? listSelectCompany
                          .filter((value) => value.isChecked)
                          .map((value) => value.name)
                          .join(', ')
                      : 'Sélectionner une société'}
                  </div>
                  {isShowCompany && (
                    <div className="dropdown-content-business-vertical">
                      <h3 className="titre-dropdown-business-vertical">Sélectionner</h3>
                      {listSelectCompany.map((value) => {
                        return (
                          <div className=" form-check style-check-business-vertical" key={value.id}>
                            <input
                              className="form-check-input checkbox-business-vertical my-2"
                              type="checkbox"
                              value=""
                              id={value.id}
                              checked={value.isChecked}
                              onChange={() => handleSelectCompany(value.id)}
                            />
                            <label className=" form-check-label" htmlFor={value.id}>
                              {value.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </span>
            </div>
          )}
          {listSelectBusiness.length > 0 && (
            <div className="text-center offset-1 col-10 mb-2">
              <span
                className="col-12"
                onClick={() => setIsShowBusiness(true)}
                onMouseLeave={() => setIsShowBusiness(false)}
                style={{ cursor: 'pointer' }}
              >
                <div className="form-select mt-0 dropdown-business-vertical">
                  <div
                    className="text-start business-nav"
                    style={{
                      height: '20px',
                      fontSize: '12px',
                    }}
                  >
                    {listSelectBusiness.length === listSelectBusiness.filter((value) => value.isChecked).length
                      ? 'Tous'
                      : listSelectBusiness.some((value) => value.isChecked)
                      ? listSelectBusiness
                          .filter((value) => value.isChecked)
                          .map((value) => value.name)
                          .join(', ')
                      : ''}
                  </div>
                  {isShowBusiness && (
                    <div className="dropdown-content-business-vertical">
                      <h3 className="titre-dropdown-business-vertical">Sélectionner</h3>
                      {listSelectBusiness.map((value) => {
                        return (
                          <div className=" form-check style-check-business-vertical" key={value.id}>
                            <input
                              className="form-check-input checkbox-business-vertical my-2"
                              type="checkbox"
                              value=""
                              id={value.id}
                              checked={value.isChecked}
                              onChange={() => handleSelectBusiness(value.id)}
                            />
                            <label className=" form-check-label" htmlFor={value.id}>
                              {value.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
}
