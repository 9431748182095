// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (max-width: 984px) {
  .NavbarItems {
    display: none;
  }
}

@media all and (min-width: 985px) {
  .NavbarItems {
    background: #0c064b;
    /* box-shadow: 0px 2px 4px rgba(52, 47, 108, 0.5); */
    /* margin-bottom:1em; */
    height: 60px;
  }

  .navbar-nav {
    display: inline-block;
  }

  .nav-item-custom {
    font-size: 1em;
    color: #ffffff;
    margin-right: 3em;
  }

  .text-link {
    color: inherit;
    text-decoration: inherit;
  }

  .nav-link:hover {
    color: #fccc69;
  }
  .nav-link:focus {
    color: #fccc69;
  }
}

@media all and (min-width: 985px) {
  .NavbarMobile {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,mBAAmB;IACnB,oDAAoD;IACpD,uBAAuB;IACvB,YAAY;EACd;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,cAAc;IACd,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,wBAAwB;EAC1B;;EAEA;IACE,cAAc;EAChB;EACA;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["@media all and (max-width: 984px) {\n  .NavbarItems {\n    display: none;\n  }\n}\n\n@media all and (min-width: 985px) {\n  .NavbarItems {\n    background: #0c064b;\n    /* box-shadow: 0px 2px 4px rgba(52, 47, 108, 0.5); */\n    /* margin-bottom:1em; */\n    height: 60px;\n  }\n\n  .navbar-nav {\n    display: inline-block;\n  }\n\n  .nav-item-custom {\n    font-size: 1em;\n    color: #ffffff;\n    margin-right: 3em;\n  }\n\n  .text-link {\n    color: inherit;\n    text-decoration: inherit;\n  }\n\n  .nav-link:hover {\n    color: #fccc69;\n  }\n  .nav-link:focus {\n    color: #fccc69;\n  }\n}\n\n@media all and (min-width: 985px) {\n  .NavbarMobile {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
