import { notificationType } from '@/constants';

export const NotificationsType = {
  MissingDocument: notificationType.TYPE_DOCUMENT_MANQUANT,
  AbsenceEnd: notificationType.TYPE_RETOUR_EMPLOI,
  VerifyDocument: notificationType.TYPE_DOCUMENT_PENDING_VALIDATION,
  Pathologie: notificationType.TYPE_DEPASSEMENT_CONGE_PATHOLOGIQUE,
  IntegrationFailed: notificationType.TYPE_ERROR_EXPORT,
} as const;
export type NotificationsType = (typeof NotificationsType)[keyof typeof NotificationsType];
export const isNotificationsType = (str: unknown): str is NotificationsType =>
  Object.values(NotificationsType).includes(str as NotificationsType);

export interface NotificationSearchFilterTypeItem {
  label: string;
  icon: React.ReactElement;
  checked: boolean;
}

export type NotificationSearchFilterType = { [K in NotificationsType]: NotificationSearchFilterTypeItem };

export interface NotificationSearchFilter {
  content: string;
  portfolio: boolean;
  type: NotificationSearchFilterType;
}
