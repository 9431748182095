import * as React from 'react';
import { AppSelect, AppSelectMultiple } from '@/components/AppSelect';
import { KerijBox } from '@/components/base';
import { InputProps } from '@/hooks/useInput';
import { EmployeeContactQuery } from '@/query';
import { Box } from '@mui/material';
import { HTMLEditor } from '@/components/HTMLEditor';

type ParcoursActions = any; //TODO: implement this

export interface ParcoursActionsInputProps extends InputProps<'div', ParcoursActions> {}

export function ParcoursActionsInput(props: ParcoursActionsInputProps) {
  const { value, onChange } = props;
  const employeeContactSelectItems = EmployeeContactQuery.useSelectItems();

  const useActionEdit = (actionName: string) => [
    value[actionName],
    (event: any, valueNew: any) => {
      onChange?.(event, {
        ...value,
        [actionName]: valueNew,
      });
    },
  ];
  const [actionEmail, setActionEmail] = useActionEdit('email');

  React.useEffect(() => {
    setActionEmail(
      {},
      actionEmail ?? {
        _: 'ParcoursActionEmail',
        contentType: 'text/html',
        content: [
          'Vous êtes éligible à un parcours',
          '',
          'Si vous souhaitez le suivre, vous pouvez en faire la demande <strong>directement depuis votre espace KERij</strong>',
        ].join('\n'),
      },
    );
  }, []);

  return (
    <>
      <KerijBox message="Configurez les actions à effectuer lorsque ce parcours est déclenché" type="info" />
      <Box>
        {/* <AppSelect label="Destinataire" items={employeeContactSelectItems.data ?? []} disabled={!emailEnabled} /> */}
        {/* <AppSelectMultiple
          label="Destinataires (copie)"
          items={employeeContactSelectItems.data ?? []}
          defaultValue={[emailTargetList[0]?.value]}
          disabled={!emailEnabled}
          // onChange={(_, _value) => {
          //   setCustomMultipleValue(_value ?? []);
          //   onChange?.({ ...value, value: _value ?? [] });
          // }}
        /> */}
        <HTMLEditor
          initialValue={actionEmail?.content ?? ''}
          onValueChange={(value) => {
            setActionEmail(
              {},
              {
                ...actionEmail,
                contentType: 'text/html',
                content: value,
              },
            );
          }}
        />
      </Box>
    </>
  );
}
