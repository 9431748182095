import { employeeVerifIsHospi } from '../../../services/employee';

export const getDataHospi = async (dateDebut: string): Promise<{ isHospi: boolean; dataHospi: any }> => {
  return await employeeVerifIsHospi({
    date_debut: dateDebut,
  })
    .then((value) => {
      if (value.data.isHospitalisation !== null) {
        return {
          isHospi: true,
          dataHospi: value.data.isHospitalisation,
        };
      }
      return { isHospi: false, dataHospi: null };
    })
    .catch((err) => {
      // eslint-disable-next-line unicorn/error-message
      throw new Error();
    });
};
