import React from 'react';

interface SpacerProperties {
  size?: 'small' | 'medium' | 'large';
}

export const Spacer = ({ size }: SpacerProperties) => {
  const marginTop = size === 'small' ? 0.5 : size === 'large' ? 2.5 : 1.25;
  return (
    <>
      <div style={{ marginTop: `${marginTop}rem` }}></div>
    </>
  );
};
