import React from 'react';
import { Absence } from '../../../types/absences.type';
import { EmployeeInformation } from '../../../types/user.interface';
import { getFullName } from '../../../shared/utils/getFullName.service';
import { Button } from '@mui/material';
import { AbsenceDate } from '../Absences/AbsenceDate';
import { AbsenceTypeConversion } from '../Absences/AbsenceTypeConversion';

interface ChangeTypeAbsenceResultValidationProperties {
  employee: EmployeeInformation;
  absences?: Absence[];
  isSubmit?: boolean;
  onReset: () => void;
}

export const ChangeTypeAbsenceResultValidation = ({
  employee,
  absences,
  isSubmit,
  onReset,
}: ChangeTypeAbsenceResultValidationProperties) => {
  return (
    <>
      {!isSubmit && (
        <div className="text-center kerij-box-border">
          <div className="mt-2 mb-2">
            Vous êtes sur le point de modifier la nature des arrêts suivants pour le collaborateur{' '}
            {employee && getFullName(employee)}
          </div>

          <div className="mt-2 mb-2">
            {absences?.map((absence: Absence) => (
              <>
                <div>
                  <AbsenceTypeConversion absence={absence} />
                  {' - '}
                  <AbsenceDate value={absence} />
                </div>
              </>
            ))}
          </div>
        </div>
      )}
      {isSubmit && (
        <div className="text-center">
          <div>Votre modification a bien été transmise et le manager informé de ce changement</div>
          <div>Le collaborateur va recevoir une notification</div>

          <Button variant="text" onClick={onReset}>
            MODIFIER UNE NOUVELLE ABSENCE
          </Button>
        </div>
      )}
    </>
  );
};
