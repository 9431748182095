import { useEffect, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import { verifyToken } from '../services/auth';
import { useToast } from '@/components/Toast';

const useDecodeToken = (token: string) => {
  const toast = useToast();
  const [decodedToken, setDecodedToken] = useState();
  const [tokenFetchError, setError] = useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        if (!token) return;
        const response = await verifyToken({}, token);
        if (response.status === StatusCodes.OK) {
          const decoded = response.data.decode;
          setDecodedToken(decoded);
        }
      } catch {
        setError(true);
        toast.present({ message: 'Connexion interrompue', severity: 'error' });
      }
    };
    fetchToken();
  }, []);

  return { decodedToken, tokenFetchError };
};

export default useDecodeToken;
