import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from '@/components/DashboardRH/Layout';
import { defineMenu } from './defineMenu';

export function HomePage() {
  const menu = defineMenu();

  return (
    <div>
      <Layout
        menu={menu}
        content={
          <div>
            <Outlet />
          </div>
        }
      />
    </div>
  );
}
