import React from 'react';
import { TableRow } from '@mui/material';
import { displayDate } from '@/utils/date.utils';
import { WarningBoolean, WarningColumns, WarningColumnsTypes } from './data';
import {
  WarningVerificationColumnButton,
  WarningVerificationColumnDate,
  WarningVerificationColumnEmpty,
  WarningVerificationColumnLabel,
  WarningVerificationColumnNoError,
  WarningVerificationColumnStatus,
} from './WarningVerificationColumn';

export interface WarningVerificationRowProps {
  type: WarningColumnsTypes;
  label: string;
  isValid?: boolean;
  isDisabled?: boolean;
  declared?: string | boolean | null;
  ocr?: string | boolean | null;
  onChange: (value: string | boolean | null) => void;
}

export const WarningVerificationRow = ({
  type,
  label,
  isValid,
  isDisabled,
  declared,
  ocr,
  onChange,
}: WarningVerificationRowProps) => {
  const [selectedValue, setSelectedValue] = React.useState<WarningColumns>();
  const [customValue, setCustomValue] = React.useState<string>('');

  const displayValue = (value: string | boolean | null | undefined, declare?: boolean): string => {
    if (value === null && type === WarningColumnsTypes.Date) return '';
    if (value === null) return declare ? WarningBoolean.False : WarningBoolean.True;
    if (typeof value === 'string' && type === WarningColumnsTypes.Date) return displayDate(value);
    if (typeof value === 'string') return value;
    return value ? WarningBoolean.True : WarningBoolean.False;
  };

  const setValue = (type: WarningColumns, value?: string) => {
    setSelectedValue(type);
    const booleanDeclareValue = typeof declared === 'boolean' ? declared : null;
    const stringDeclareValue = typeof declared === 'string' ? declared : null;
    const booleanOCRValue = typeof ocr === 'string' ? ocr : null;
    const stringOCRValue = typeof ocr === 'string' ? ocr : null;

    const declare = stringDeclareValue ?? booleanDeclareValue;
    const detect = stringOCRValue ?? booleanOCRValue;

    const valueNew =
      type === WarningColumns.Edit ? value ?? '' : type === WarningColumns.Declaration ? declare : detect;
    if (type === WarningColumns.Edit) setCustomValue(value ?? '');
    else setCustomValue('');

    onChange(valueNew);
  };

  const renderRow = () => (
    <>
      {/* Declaration */}
      <WarningVerificationColumnButton
        value={displayValue(declared)}
        onClick={() => setValue(WarningColumns.Declaration)}
        selected={selectedValue === WarningColumns.Declaration}
        disabled={isDisabled}
      />

      {/* OCRResult */}
      <WarningVerificationColumnButton
        value={displayValue(ocr, Boolean(declared))}
        onClick={() => setValue(WarningColumns.OCR)}
        selected={selectedValue === WarningColumns.OCR}
        disabled={(ocr == null && type === WarningColumnsTypes.Date) || isDisabled}
        undefinedValue={ocr == null}
      />
      {/* Edit value */}
      {type === WarningColumnsTypes.Date && isDisabled !== true ? (
        <WarningVerificationColumnDate
          value={customValue}
          onChange={(event) => setValue(WarningColumns.Edit, event)}
          selected={selectedValue === WarningColumns.Edit}
        />
      ) : (
        <WarningVerificationColumnEmpty />
      )}

      <WarningVerificationColumnStatus isValid={isValid} />
    </>
  );

  const renderRowNoError = () => (
    <>
      {[declared, ocr, ''].map((value, index) => (
        <WarningVerificationColumnNoError key={index} value={displayValue(value)} />
      ))}
      <WarningVerificationColumnStatus isValid />
    </>
  );

  return (
    <TableRow>
      <WarningVerificationColumnLabel label={label} />
      {declared === ocr ? renderRowNoError() : renderRow()}
    </TableRow>
  );
};
