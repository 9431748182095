import React from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import '../../../components/DashboardRH/DashboardRH.css';
import { getFullName } from '../../utils/getFullName.service';

export const defaultStyles = {
  content: {
    border: '0',
    borderRadius: '4px',
    bottom: 'auto',
    minHeight: '10rem',
    left: '50%',
    padding: '2rem',
    position: 'fixed',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    minWidth: '20rem',
    width: '100%',
    height: 'auto',
    maxWidth: '60rem',
    boxShadow: '1px 2px 12px #000000',
  },
};

export const BaseModalRH = ({ content, isOpen, onRequestClose, customStyles, selectedAction }) => {
  const styles = customStyles || defaultStyles;
  return (
    <Modal isOpen={isOpen} style={styles} className="Modal" overlayClassName="Overlay">
      <div className="float-left">
        <button
          onClick={() => {
            onRequestClose();
          }}
          className="btn-close exit-modal"
        ></button>
        <br></br>
      </div>
      <div className="card-actions-rh-header-modal row text-start p-2">
        <div className="col-12 row text-start">
          <div className="col-3">
            <strong>{getFullName(selectedAction.employee_information)}</strong>
            <p className="no-p-margin">{selectedAction.employee_information.business.name}</p>
          </div>
          {selectedAction.absence && (
            <div className="col-3 content-mid">
              {selectedAction.absence.type_absence.description_label === 'Congé paternité'
                ? 'Congé parentalité'
                : selectedAction.absence.type_absence.description_label}
            </div>
          )}
          {selectedAction.absence && (
            <div className="col-3 content-mid">Du {moment(selectedAction.absence.date_start).format('DD/MM/YYYY')}</div>
          )}
          {selectedAction.absence && (
            <div className="col-3 content-mid">
              Au {selectedAction.absence.date_end ? moment(selectedAction.absence.date_end).format('DD/MM/YYYY') : '-'}
            </div>
          )}
        </div>
      </div>
      <hr className="col-12"></hr>
      {/* Contenu de la modal */}
      {content}
    </Modal>
  );
};
