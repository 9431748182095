export enum CollaborateursActionTypes {
  GET_COLLABORATEURS_REQUEST = 'GET_COLLABORATEURS_REQUEST',
  LIST_COLLABORATEURS = 'LIST_COLLABORATEURS',
  LIST_COLLABORATEURS_SUCCESS = 'LIST_COLLABORATEURS_SUCCESS',
  LIST_COLLABORATEURS_FAILURE = 'LIST_COLLABORATEURS_FAILURE',
  LIST_COLLABORATEURS_LOADING = 'LIST_COLLABORATEURS_LOADING',
}

export interface ICollaborateursAction {
  type: CollaborateursActionTypes.GET_COLLABORATEURS_REQUEST;
  payload: any;
}

export interface ICollaborateursSuccessAction {
  type: CollaborateursActionTypes.LIST_COLLABORATEURS | CollaborateursActionTypes.LIST_COLLABORATEURS_SUCCESS;
  payload: any;
}

export interface ICollaborateursFailureAction {
  type: CollaborateursActionTypes.LIST_COLLABORATEURS_FAILURE;
  payload?: any;
}
export interface ICollaborateursAttemptAction {
  type: CollaborateursActionTypes.LIST_COLLABORATEURS_LOADING;
  payload?: boolean;
}

export type CollaborateursAction =
  | ICollaborateursAction
  | ICollaborateursSuccessAction
  | ICollaborateursFailureAction
  | ICollaborateursAttemptAction;
