import { AbsenceCode, AbsenceCodeLabel } from './AbsenceCode';
import {
  ParcoursRuleComparableKey,
  ParcoursRuleIdentifiableKey,
  ParcoursRuleKey,
  ParcoursRuleKeyLabel,
  isParcoursRuleKey,
} from './ParcoursRuleKey';
import {
  ParcoursRuleOperatorComparable,
  ParcoursRuleOperatorIdentifiable,
  ParcoursRuleOperatorLabel,
  isParcoursRuleOperator,
} from './ParcoursRuleOperator';
import { ParcoursRuleValue, extractRuleValue } from './ParcoursRuleValue';

export type ParcoursRule = {
  [K in ParcoursRuleComparableKey]?: { [Op in ParcoursRuleOperatorComparable]: ParcoursRuleValue };
} & {
  [K in ParcoursRuleIdentifiableKey]?: { [Op in ParcoursRuleOperatorIdentifiable]: ParcoursRuleValue };
};

export const ParcoursRuleType = {
  [ParcoursRuleKey.AbsenceDateStart]: { type: 'date' },
  [ParcoursRuleKey.AbsenceDateEnd]: { type: 'date' },
  [ParcoursRuleKey.AbsenceCount]: { type: 'duration' },
  [ParcoursRuleKey.AbsenceTotalDuration]: { type: 'duration' },
  [ParcoursRuleKey.AbsenceParentDuration]: { type: 'number' },
  [ParcoursRuleKey.InternalCodeAbsence]: {
    type: 'enum',
    items: Object.values(AbsenceCode).map((value) => ({
      label: AbsenceCodeLabel[value],
      value,
    })),
  },
} as const;
export type ParcoursRuleType = (typeof ParcoursRuleType)[keyof typeof ParcoursRuleType];

export const extractRule = (
  rule: ParcoursRule,
):
  | { success: true; rule: { key: string; operator: string; value: string; options?: object } }
  | { success: false; error: string } => {
  const parcoursKey = Object.keys(rule)[0];

  if (!isParcoursRuleKey(parcoursKey)) {
    return { success: false, error: `La règle est invalide` };
  }

  const parcoursOperator = Object.keys(rule[parcoursKey] ?? {})[0];
  if (!isParcoursRuleOperator(parcoursOperator)) {
    return { success: false, error: `L'opérateur ${JSON.stringify(parcoursOperator)} est incorrect` };
  }

  const extractedValue = extractRuleValue(Object.values(rule[parcoursKey] ?? {})[0]);
  if (extractedValue == null) {
    return { success: false, error: `Le paramétrage de la règle est incorrect est incorrect` };
  }

  return {
    success: true,
    rule: {
      key: ParcoursRuleKeyLabel[parcoursKey],
      operator: ParcoursRuleOperatorLabel[parcoursOperator],
      ...extractedValue,
    },
  };
};
