import React from 'react';
import { Absence } from '../../../types/absences.type';
import { isAccident, isHospitalisation, isMaladie } from '../../../services/rh/absences/data';

interface AbsenceTypeConversionProperties {
  absence?: Absence;
}

export const AbsenceTypeConversion = ({ absence }: AbsenceTypeConversionProperties) => {
  return (
    <>
      {absence && (
        <strong>
          {isMaladie(absence) && 'Accident - Maladie professionnelle'}
          {isAccident(absence) && 'Maladie'}
          {isHospitalisation(absence) && 'Accident - Maladie professionnelle'}
        </strong>
      )}
    </>
  );
};
