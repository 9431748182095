import React from 'react';
import { Link } from 'react-router-dom';
import '../DashboardRH.css';
import imgStats1 from '../../../assets/stats-1.jpg';
import imgStats2 from '../../../assets/stats-3.jpg';
import imgStats3 from '../../../assets/stats-2.jpg';
import imgStats4 from '../../../assets/stats-4.jpg';
import { PRO_URL } from '../../../constants/urls.constants';

function Stats1() {
  return (
    <div>
      <div className="container-fluid mt-5">
        <div className="row col-12 col-lg-8 mb-5">
          <div className="text-center">
            <h1 className="titre-rh"> Tableau de bord des statistiques</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-3 mb-3">
            <div className="card card-stats h-100">
              <img alt="kerij" className="mb-3 img-rdv" style={{ width: '250px' }} src={imgStats1} />
              <div className="card-body">
                <h5 className="card-title card-title-stats">Statistiques des en cours</h5>
                <p className="card-text">
                  Une vision globale et détaillée de la situation de l'absentéisme de mon organisation à un instant
                  donné.{' '}
                </p>
                <Link className="btn-link" to={PRO_URL.PREFIXE_PRO + PRO_URL.STATS_EN_COURS}>
                  <button className="btn btn-kerij-bleu mt-5 col-6">Visualiser</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3 mb-3">
            <div className="card card-stats h-100">
              <img alt="kerij" className="mb-3 img-rdv" style={{ width: '250px' }} src={imgStats2} />
              <div className="card-body">
                <h5 className="card-title card-title-stats">Les indicateurs de risque</h5>
                <p className="card-text">
                  Les différents indicateurs de risque concernant l'absentéisme général et détaillé de mon organisation
                </p>
                <button className="btn btn-kerij-bleu mt-5 col-6">Visualiser</button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3 mb-3">
            <div className="card card-stats h-100">
              <img alt="kerij" className="mb-3 img-rdv" style={{ width: '250px' }} src={imgStats3} />
              <div className="card-body">
                <h5 className="card-title card-title-stats">Les coût directs et indirects</h5>
                <p className="card-text">
                  Les indicateurs financiers permettant de chiffrer les coûts directs et indirects de mon absentéisme
                </p>
                <button className="btn btn-kerij-bleu mt-5 col-6">Visualiser</button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3 mb-3">
            <div className="card card-stats h-100">
              <img alt="kerij" className="mb-3 img-rdv" style={{ width: '250px' }} src={imgStats4} />
              <div className="card-body">
                <h5 className="card-title card-title-stats">La répartion au sein de mon organisation</h5>
                <p className="card-text">Une vision géographique des absences de mon organisation</p>
                <button className="btn btn-kerij-bleu mt-5 col-6">Visualiser</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats1;
