import React from 'react';
import ImgServiceKerijblanc from '@/assets/service-logokerij-blanc.jpg';
import { Box, Tab, Tabs, tabsClasses } from '@mui/material';
import { InputProps } from '@/hooks/useInput';

export interface FilterInputProps<InputValue> extends InputProps<Element, InputValue | undefined> {
  items: FilterInputElement<InputValue>[];
}

export interface FilterInputElement<InputValue> {
  value: InputValue;
  title: string;
  icon?: string;
}

export function FilterInput<InputValue extends string | undefined>(props: FilterInputProps<InputValue>) {
  const { items, value, disabled, onChange } = props;

  const [tabsValue, setTabsValue] = React.useState<InputValue | 0>(value ?? 0);

  const handleChange = (event: React.SyntheticEvent<Element>, newValue: InputValue | 0): void => {
    if (disabled) return;
    setTabsValue(newValue);

    const valueReturn = newValue === 0 ? undefined : newValue;
    onChange?.(event, valueReturn);
  };

  return (
    <Box>
      <Tabs
        value={tabsValue}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            borderRadius: '10px',
            '&.Mui-disabled': { opacity: 0.5 },
          },
        }}
      >
        {items.map((item, index) => (
          <Tab
            key={index}
            label={item.title}
            value={item.value}
            icon={<img src={item.icon || ImgServiceKerijblanc} style={{ height: '1rem' }} />}
            disableRipple
          />
        ))}
      </Tabs>
    </Box>
  );
}
