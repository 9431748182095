import { toISODate } from '@/utils/date.utils';
import { Absence, DocumentStatus } from './absences.type';
import { FormatDate } from '@/constants/date.constants';

export interface OCRAPIResponse {
  documentStatus: DocumentStatus;
  date_start?: OCRAPIResponseValue<string | null, string | null>;
  date_end?: OCRAPIResponseValue<string | null, string | null>;
  pregnancy?: OCRAPIResponseValue<boolean | null, boolean | null>;
  work?: OCRAPIResponseValue<boolean | null, boolean | null>;
  identity?: OCRAPIResponseValue<string | null, string | null>;
}

export type OCRAPIResponseValue<Declare, Detect> = {
  status: OCRComparatorStatus;
  value: { declare: Declare; detect: Detect };
};

export const OCRComparatorStatus = {
  Valid: 'valid',
  Invalid: 'invalid',
  Skip: 'skip',
} as const;
export type OCRComparatorStatus = (typeof OCRComparatorStatus)[keyof typeof OCRComparatorStatus];
export const isOCRComparatorStatus = (status: string): status is OCRComparatorStatus =>
  Object.values(OCRComparatorStatus).includes(status as any);

export function safeParseOCRAPIResponse(
  value: OCRAPIResponse | OCRResultV1 | { ocr: OCRResult; absence: Absence },
): OCRAPIResponse {
  if (isBasylicOCRResult(value)) {
    return safeParseBasylicResult(value);
  }
  if (isOCRResultV1(value)) {
    return {
      ...value,
      pregnancy: value.pregnancy
        ? {
            ...value.pregnancy,
            value: { ...value.pregnancy.value, detect: value.pregnancy.value.detect === BasylicFieldResult.Check },
          }
        : undefined,
      work: value.work
        ? {
            ...value.work,
            value: { ...value.work.value, detect: value.work.value.detect === BasylicFieldResult.Check },
          }
        : undefined,
    };
  }

  return value;
}

// Maintien des fonctionnalités Basylic
/** @deprecated Maintenue pour les ancienne absence Basylic */
function safeParseBasylicResult(value: { ocr: OCRResult; absence: Absence }): OCRAPIResponse {
  return {
    documentStatus: DocumentStatus.PENDING,
    date_start: {
      status:
        toISODate(value.absence.date_start) ===
        toISODate(value.ocr.medical_leave_date?.value || (value.ocr.entry_date?.value ?? ''), FormatDate.FRENCH_DATE)
          ? 'valid'
          : 'invalid',
      value: {
        declare: toISODate(value.absence.date_start),
        detect: toISODate(
          value.ocr.medical_leave_date?.value || (value.ocr.entry_date?.value ?? ''),
          FormatDate.FRENCH_DATE,
        ),
      },
    },
    date_end: {
      status:
        toISODate(value.absence.date_end) ===
        toISODate(value.ocr.medical_leave_end_date?.value || (value.ocr.exit_date?.value ?? ''), FormatDate.FRENCH_DATE)
          ? 'valid'
          : 'invalid',
      value: {
        declare: toISODate(value.absence.date_end),
        detect: toISODate(
          value.ocr.medical_leave_end_date?.value || (value.ocr.exit_date?.value ?? ''),
          FormatDate.FRENCH_DATE,
        ),
      },
    },
    identity: { status: 'skip', value: { declare: null, detect: null } },
    pregnancy: { status: 'skip', value: { declare: value.absence.type_absence.id === '5', detect: null } },
    work: {
      status: 'invalid',
      value: { declare: ['2', '3', '8', '9', '10'].includes(value.absence.type_absence.id), detect: null },
    },
  };
}

/** @deprecated Maintenue pour les ancienne absence Basylic */
export interface BasylicOCRResultItem {
  value: string;
  label: string;
  score: number;
  order: string;
}

/** @deprecated Maintenue pour les ancienne absence Basylic */
export type BasylicOCRResultKey = string;

/** @deprecated Maintenue pour les ancienne absence Basylic */
export interface OCRResult {
  document_nature?: BasylicOCRResultItem;
  document_basename?: BasylicOCRResultItem;
  page_number?: BasylicOCRResultItem;
  file_hash?: BasylicOCRResultItem;
  document_hash?: BasylicOCRResultItem;
  document_key?: BasylicOCRResultItem;
  document_source?: BasylicOCRResultItem;
  identity?: BasylicOCRResultItem;
  social_security_number?: BasylicOCRResultItem;
  doctor_identity?: BasylicOCRResultItem;
  medical_leave_date?: BasylicOCRResultItem;
  medical_leave_release_date?: BasylicOCRResultItem;
  medical_leave_end_date_str?: BasylicOCRResultItem;
  medical_leave_end_date?: BasylicOCRResultItem;
  pregnancy_related_tick?: BasylicOCRResultItem;
  pregnancy_not_related_tick?: BasylicOCRResultItem;
  work_related_tick?: BasylicOCRResultItem;
  work_not_related_tick?: BasylicOCRResultItem;
  authorized_release_tick?: BasylicOCRResultItem;
  not_authorized_release_tick?: BasylicOCRResultItem;
  process_time?: BasylicOCRResultItem;
  leave_extension_tick?: BasylicOCRResultItem;
  work_related_issue?: BasylicOCRResultItem;
  exit_date?: BasylicOCRResultItem;
  entry_date?: BasylicOCRResultItem;
  birth_date?: BasylicOCRResultItem;
  expected_delivery_date?: BasylicOCRResultItem;
}

/** @deprecated Maintenue pour les ancienne absence Basylic */
export const isOCRResult = (value: any): value is OCRResult =>
  value != null &&
  typeof value === 'object' &&
  (Object.hasOwn(value, 'medical_leave_date') ||
    Object.hasOwn(value, 'entry_date') ||
    Object.hasOwn(value, 'medical_leave_end_date') ||
    Object.hasOwn(value, 'exit_date'));

/** @deprecated Maintenue pour les ancienne absence Basylic */
export const isBasylicOCRResult = (value: any): value is { ocr: OCRResult; absence: Absence } =>
  typeof value === 'object' && Object.hasOwn(value, 'ocr') && isOCRResult(value.ocr);

/** @deprecated Maintenue pour les ancienne absence Basylic */
export interface OCRResultV1 {
  documentStatus: DocumentStatus;
  date_start?: OCRAPIResponseValue<string | null, string | null>;
  date_end?: OCRAPIResponseValue<string | null, string | null>;
  birthday?: OCRAPIResponseValue<string | null, string | null>;
  pregnancy?: OCRAPIResponseValue<boolean | null, string | null>;
  work?: OCRAPIResponseValue<boolean | null, string | null>;
  identity?: OCRAPIResponseValue<string | null, string | null>;
}

/** @deprecated Maintenue pour les ancienne absence Basylic */
export const isOCRResultV1 = (value: any): value is OCRResultV1 => {
  return (
    typeof value === 'object' &&
    ((Object.hasOwn(value, 'pregnancy') &&
      (typeof value.pregnancy.value?.detect === 'string' || typeof value.pregnancy.value?.declare === 'string')) ||
      (Object.hasOwn(value, 'work') &&
        (typeof value.work.value?.detect === 'string' || typeof value.work.value?.declare === 'string')))
  );
};

/** @deprecated Maintenue pour les ancienne absence Basylic */
export const BasylicFieldResult = {
  Undefined: 'CHAMP NON RECONNU',
  NotCheck: 'NON COCHEE',
  Check: 'COCHEE',
} as const;
export type BasylicFieldResult = (typeof BasylicFieldResult)[keyof typeof BasylicFieldResult];
