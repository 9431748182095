import { DocumentBoxType } from '../../../../../declaration/types/DocumentBoxType';
import React, { useEffect, useState } from 'react';
import { DocumentAbsence } from '../../../../../../types/absences.type';
import { Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import './DocumentPreview.scss';

interface DocumentPreviewProperties {
  document: DocumentAbsence;
  type?: DocumentBoxType;
}

const DocumentPreview = ({ document, type = DocumentBoxType.Success }: DocumentPreviewProperties) => {
  const [file, setFile] = useState<File>();
  const [numberPages, setNumberPages] = useState<number>();

  const onDocumentLoadSuccess = ({ numPages: nextNumberPages }: PDFDocumentProxy) => {
    setNumberPages(nextNumberPages);
  };

  const getDocumentClassName = (base: string) => {
    if (type === DocumentBoxType.Failed) return `${base} ${base}--Failed`;
    if (type === DocumentBoxType.Warning) return `${base} ${base}--Warning`;

    return `${base} ${base}--Default`;
  };

  useEffect(() => {
    const fetchFileFromUrl = async (url: string) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const parts = url.split('.');
        const extension = parts[parts.length - 1];
        setFile(
          new File([blob], document.name, {
            type: extension === 'pdf' ? 'application/pdf' : 'image/jpg',
          }),
        );
      } catch {
        /* empty */
      }
    };
    if (document && !file) {
      fetchFileFromUrl(document.url);
    }
  }, [document]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="box" style={{ cursor: 'zoom-in' }} onClick={() => window.open(document.url, '_blank')}>
        <Document
          className={getDocumentClassName('DocumentPreview')}
          file={document.url}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from({ length: numberPages || 0 }).map((element, index) => (
            <>
              <Page
                className={'DocumentPreview__Page'}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={800}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </>
          ))}
        </Document>
      </div>
    </div>
  );
};

export default React.memo(DocumentPreview);
