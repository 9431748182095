import { createContext } from 'react';

interface SocketContextInterface {
  afterAddEvent: (callback: () => void) => void;
  afterUpdateEvent: (callback: () => void) => void;
  unsubscribeAfterAddEvent: (callback: () => void) => void;
  unsubscribeAfterUpdateEvent: (callback: () => void) => void;
  afterDeleteEvent: (callback: (idNotification: string) => void) => void;
  deleteAction: ({ idNotification, length }: { idNotification: string; length: number }) => void;
  connect: () => void;
  disconnect: () => void;
}

export const SocketContext = createContext<SocketContextInterface>({
  afterAddEvent: () => {},
  afterUpdateEvent: () => {},
  unsubscribeAfterAddEvent: () => {},
  unsubscribeAfterUpdateEvent: () => {},
  afterDeleteEvent: () => {},
  deleteAction: () => {},
  connect: () => {},
  disconnect: () => {},
});
