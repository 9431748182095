import React from 'react';
import { Absence } from '../../../types/absences.type';
import { EmployeeInformation } from '../../../types/user.interface';
import { getFullName } from '../../../shared/utils/getFullName.service';
import { Button } from '@mui/material';
import { AbsenceDate } from '../Absences/AbsenceDate';
import { AbsenceLabel } from '../Absences/AbsenceLabel';

export interface AbsencesDeleteValidationProps {
  employee: EmployeeInformation;
  cause?: string | null;
  absences?: Absence[];
  isSubmit?: boolean;
  onReset: () => void;
}

export const AbsencesDeleteValidation = ({
  employee,
  cause,
  absences,
  isSubmit,
  onReset,
}: AbsencesDeleteValidationProps) => {
  return (
    <>
      {!isSubmit && (
        <div className="text-center kerij-box-border" style={{ padding: '1rem' }}>
          <div className="mt-2 mb-2">
            Vous êtes sur le point de supprimer les arrêts suivants pour le collaborateur{' '}
            {employee && getFullName(employee)}
          </div>

          <div className="mt-2 mb-2">
            {absences?.map((absence: Absence) => (
              <>
                <div>
                  <AbsenceLabel absence={absence} />
                  {' - '}
                  <AbsenceDate value={absence} />
                </div>
              </>
            ))}
          </div>

          <div style={{ textAlign: 'left', margin: '1rem', marginTop: '3rem' }}>Motif : {cause}</div>
        </div>
      )}
      {isSubmit && (
        <div className="text-center">
          <div>Votre suppression a bien été transmise et le manager informé de ce changement</div>
          <div>Le collaborateur va recevoir une notification</div>

          <Button variant="text" onClick={onReset}>
            SUPPRIMER UNE NOUVELLE ABSENCE
          </Button>
        </div>
      )}
    </>
  );
};
