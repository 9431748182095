import Verify from '../../components/Signup/Verify';
import { DefaultLayout } from '../../components/layouts';
import React from 'react';

export const VerifyPage: React.FC = () => {
  return (
    <DefaultLayout>
      <Verify />
    </DefaultLayout>
  );
};
