// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.creation-account__container {
  display: flex;
  flex-direction: column;
}
.creation-account__container .creation-account__label {
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
}
.creation-account__container .creation-account__label .creation-account__input-radio {
  display: none;
}
.creation-account__container .creation-account__label .creation-account__span {
  position: relative;
  line-height: 22px;
}
.creation-account__container .creation-account__label .creation-account__span::before,
.creation-account__container .creation-account__label .creation-account__span::after {
  content: "";
}
.creation-account__container .creation-account__label .creation-account__span::before {
  border: 1px solid #40c9c9;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}
.creation-account__container .creation-account__label .creation-account__span::after {
  background: #40c9c9;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 1.5px;
  left: 3px;
  transition: 300ms;
  opacity: 0;
}
.creation-account__container .creation-account__label .creation-account__input-radio:checked + .creation-account__span::after {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/features/dashboard-rh/create-absence-rh/components/Modal/CreationAccount.modal.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,qBAAA;EACA,iBAAA;EACA,eAAA;AACJ;AACI;EACE,aAAA;AACN;AAEI;EACE,kBAAA;EACA,iBAAA;AAAN;AAGI;;EAEE,WAAA;AADN;AAII;EACE,yBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,qBAAA;EACA,mBAAA;AAFN;AAKI;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;EACA,iBAAA;EACA,UAAA;AAHN;AAMI;EACE,UAAA;AAJN","sourcesContent":[".creation-account__container {\n  display: flex;\n  flex-direction: column;\n\n  .creation-account__label {\n    display: inline-block;\n    padding: 5px 10px;\n    cursor: pointer;\n\n    .creation-account__input-radio {\n      display: none;\n    }\n\n    .creation-account__span {\n      position: relative;\n      line-height: 22px;\n    }\n\n    .creation-account__span::before,\n    .creation-account__span::after {\n      content: '';\n    }\n\n    .creation-account__span::before {\n      border: 1px solid #40c9c9;\n      width: 20px;\n      height: 20px;\n      margin-right: 10px;\n      display: inline-block;\n      vertical-align: top;\n    }\n\n    .creation-account__span::after {\n      background: #40c9c9;\n      width: 14px;\n      height: 14px;\n      position: absolute;\n      top: 1.5px;\n      left: 3px;\n      transition: 300ms;\n      opacity: 0;\n    }\n\n    .creation-account__input-radio:checked + .creation-account__span::after {\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
