import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import ServicesLoginUI from './components/ServicesLoginUI';

export const ServicesLoginPage: React.FC = () => {
  return (
    <DefaultLayout>
      <ServicesLoginUI />
    </DefaultLayout>
  );
};
