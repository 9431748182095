/* eslint-disable @typescript-eslint/ban-ts-comment */
import { absenceType } from '../constants/absenceType.constant';
import { toSelect } from '../constants/absenceType.constant';
import { statementStep } from '../constants/step.constants';
import { AbsenceCreation, ProlongationChoices, StepProperties } from '../props/step.interface';
import React from 'react';
import TitleProcess from './TitleProcess';

const options = [
  {
    value: toSelect,
    title: 'Choisir la valeur',
  },
  {
    value: ProlongationChoices.INITIAL,
    title: 'Arrêt Initial',
  },
  {
    value: ProlongationChoices.PROLONGATION,
    title: 'Prolongation',
  },
];

export const IsProlongation = ({ absence, setAbsence, handleChangeStep }: Omit<StepProperties, 'infosEmployee'>) => {
  const setAbsenceValue = (absence: AbsenceCreation, prolongation: boolean | null) => {
    const state = prolongation === true ? ProlongationChoices.PROLONGATION : ProlongationChoices.INITIAL;
    return {
      ...absence,
      dateDebut: '',
      dateFin: '',
      period: [{ dateDebut: '', dateFin: '', error: -1 }],
      prolongation: prolongation ?? false,
      stateAbsence: prolongation === null ? `${toSelect}` : state,
      absenceType2: '', // Reset absenceType2, so user have to reselect
    };
  };

  const setProlongation = (nextStep: number, prolongation: boolean | null) => {
    setAbsence((absence: AbsenceCreation) => setAbsenceValue(absence, prolongation));
    handleChangeStep(nextStep);
  };

  const handleChangeStateAbsence = (event: React.ChangeEvent<HTMLSelectElement>) => {
    switch (event.target.value) {
      case ProlongationChoices.INITIAL: {
        setProlongation(statementStep.TypeAbsence2, false);
        break;
      }
      case ProlongationChoices.PROLONGATION: {
        setProlongation(statementStep.TypeAbsence2, true);
        break;
      }
      default: {
        setProlongation(statementStep.IsProlongation, null);
        break;
      }
    }
  };

  React.useEffect(() => {
    if (absence.absenceType !== absenceType.arret_maladie.toString()) {
      handleChangeStep(statementStep.TypeAbsence2);
    }
  }, []);

  if (absence.absenceType === absenceType.arret_maladie.toString()) {
    return (
      <div>
        <TitleProcess title={`C'est un(e)`} />
        <form
          className="form-declaration col-lg-12"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0px',
          }}
        >
          <label className="col-8">
            <select
              className="form-select"
              name="absence"
              value={absence.stateAbsence}
              onChange={handleChangeStateAbsence}
              required
            >
              {options.map((option, index) => (
                <option value={option.value} key={index}>
                  {option.title}
                </option>
              ))}
            </select>
          </label>
        </form>
      </div>
    );
  }

  return <></>;
};
