import React from 'react';
import { Box, Button } from '@mui/material';

interface AbsencesFormSubmitProps {
  labels: { confirm: string; cancel: string };
  disabledConfirm?: boolean;
  disabledCancel?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const AbsencesFormSubmit = ({
  labels,
  disabledConfirm,
  disabledCancel,
  onConfirm,
  onCancel,
}: AbsencesFormSubmitProps) => {
  return (
    <Box sx={{ mb: 2 }}>
      <div>
        <Button variant="contained" disabled={disabledConfirm} onClick={onConfirm} sx={{ mt: 1, mr: 1 }}>
          {labels.confirm}
        </Button>

        <Button disabled={disabledCancel} onClick={onCancel} sx={{ mt: 1, mr: 1 }}>
          {labels.cancel}
        </Button>
      </div>
    </Box>
  );
};
