export const notifications = {
  CODE_ALLNOTIFICATIONS: '1',
  CODE_ONLYLEAVING: '2',
  CODE_NONOTIFICATIONS: '3',
};

export const notificationType = {
  TYPE_VISITE_MEDICALE_DE_PREREPRISE: 'Visite medicale de prereprise',
  TYPE_VISITE_MEDICALE_DE_SST: 'Visite medicale de sante au travail',
  TYPE_VISITE_DE_REPRISE: 'Visite de reprise',
  TYPE_ENTRETIEN_RETOUR_EMPLOI: "Entretien de retour a l'emploi",
  TYPE_ENTRETIEN_DE_LIAISON: 'Entretien de liaison',
  TYPE_PLANIFIER_ENTRETIEN_DE_RETOUR: 'Planifier entretien de retour',
  TYPE_PLANIFIER_ENTRETIEN_DE_LIAISON: 'Planifier entretien de liaison',
  TYPE_EXTRACTION_ABSENCES: 'Extraction des absences',
  TYPE_UPDATE_DOCUMENT: 'Mise à jour des documents',
  TYPE_DOCUMENT_MANQUANT: 'Document manquant',
  TYPE_DOCUMENT_PENDING_VALIDATION: 'Document a valider',
  TYPE_DECLARE_NOUVELLE_ABSENCE: 'Declarer nouvelle absence',
  TYPE_ACTE_NAISSANCE_MANQUANT: 'Acte de naissance manquant',
  TYPE_HOSPI_NO_RETURN_DATE: 'Hospitalisation sans date de retour',
  TYPE_RETOUR_EMPLOI: "Retour a l'emploi",
  TYPE_DEPASSEMENT_CONGE_PATHOLOGIQUE: 'Dépassement de congé pathologique',
  TYPE_ERROR_EXPORT: "Erreur d'intégration de l'absence",
};
export const isInterview = (type?: string): boolean => {
  if (type == null) return false;

  return [
    notificationType.TYPE_VISITE_MEDICALE_DE_PREREPRISE,
    notificationType.TYPE_VISITE_MEDICALE_DE_SST,
    notificationType.TYPE_VISITE_DE_REPRISE,
    notificationType.TYPE_ENTRETIEN_RETOUR_EMPLOI,
    notificationType.TYPE_PLANIFIER_ENTRETIEN_DE_RETOUR,
    notificationType.TYPE_PLANIFIER_ENTRETIEN_DE_LIAISON,
    notificationType.TYPE_ENTRETIEN_DE_LIAISON,
  ].includes(type);
};

export const rhNotificationHistoriesAction = {
  ACTION_ORGANISATION_INDISPONIBLE: "L'organisateur est indisponible pour réaliser cet entretien de retour",
};
