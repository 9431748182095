import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from './NavigationContext';

export enum NavigationSteps {
  DECLARATION_PROLONGATION = 1,
  DECLARATION_TYPE_1 = 2,
  DECLARATION_TYPE_2 = 3,
  DECLARATION_DATE = 4,
  DECLARATION_DOCUMENT = 5,
  DECLARATION_INFORMATION = 6,
  DECLARATION_QUESTIONS_CONTACT = 7,
  DECLARATION_QUESTIONS_MESSAGE = 8,
  // DECLARATION_VERIFICATION = 9,
  DECLARATION_VALIDATION = 9,
}

const stepsToRoutes = {
  [NavigationSteps.DECLARATION_DATE]: '/declaration-date',
  [NavigationSteps.DECLARATION_TYPE_1]: '/declaration-type-1',
  [NavigationSteps.DECLARATION_PROLONGATION]: '/declaration-prolongation',
  [NavigationSteps.DECLARATION_TYPE_2]: '/declaration-type-2',
  [NavigationSteps.DECLARATION_QUESTIONS_CONTACT]: '/declaration-questions-contact',
  [NavigationSteps.DECLARATION_QUESTIONS_MESSAGE]: '/declaration-questions-message',
  [NavigationSteps.DECLARATION_DOCUMENT]: '/declaration-document',
  [NavigationSteps.DECLARATION_INFORMATION]: '/declaration-information',
  [NavigationSteps.DECLARATION_VALIDATION]: '/declaration-validation',
};

export const NavigationProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(NavigationSteps.DECLARATION_PROLONGATION);

  const changeStep = (newStep: NavigationSteps, state?: object) => {
    const nextRoute = stepsToRoutes[newStep];
    if (nextRoute) {
      setCurrentStep(newStep);
      navigate(nextRoute, state);
    } else {
      console.error(`Invalid step: ${newStep}`);
    }
  };

  const value = {
    currentStep,
    setCurrentStep,
    changeStep,
  };

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};
