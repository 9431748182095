import React, { useState, useEffect } from 'react';
import { Navbar } from '@/components/Navbar/Navbar';
import { NavbarMobile } from '@/components/Navbar/NavbarMobile';
import Footer from '../Footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import 'popstate-direction';
import { QUESTIONNAIRE_SUIVI_CHOICES } from './data';

export const QuestionnaireSuiviFin = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const [buttonLabel, setButtonLabel] = useState('');
  useEffect(() => {
    if (state.state) {
      if (state.state.etat === QUESTIONNAIRE_SUIVI_CHOICES.BACK_PROLONGATION) {
        setButtonLabel('Déclarer mon nouvel arrêt');
      } else {
        setButtonLabel("Retour à l'accueil");
      }
    }
  }, []);

  const handleClick = () => {
    if (state.state) {
      if (state.state.etat === QUESTIONNAIRE_SUIVI_CHOICES.BACK_PROLONGATION) {
        navigate('/declaration', { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    } else {
      navigate('/', { replace: true });
    }
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb-5 text-center">Questionnaire validé</h3>

        <form className="form-declaration text-center col-12 col-md-8">
          <div className="declaration-check mb-5">
            <FontAwesomeIcon className="fa-5x" icon={faCheck} />
          </div>

          <div className="col-12">
            <p>
              Merci. <br /> Vos réponses sont transmises à votre employeur. <br />
              <br />
            </p>
          </div>
        </form>
        <div className="position-relative col-12 col-md-8 mt-5 text-center">
          <button type="button" className="btn btn-kerij col-6 col-lg-3" onClick={handleClick}>
            {buttonLabel}
          </button>
        </div>
      </div>
      <NavbarMobile />
      <Footer />
    </div>
  );
};
