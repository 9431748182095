import React from 'react';
import { Typography } from '@mui/material';

export interface EmailInputProps {
  register: any;
  label: string;
  error?: any;
}

const InputEmailValidate = ({ register, label, error }: EmailInputProps) => {
  return (
    <div className="input-group">
      <label>{label}</label>
      <div
        className="label-password"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <input
          name="login"
          type="text"
          className="input-password"
          required
          {...register('email', {
            required: true,
            pattern: {
              value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i,
              message: `Format d'email invalide`,
            },
          })}
        />
        {error && <Typography style={{ color: 'red', marginLeft: '10px' }}>{error}</Typography>}
      </div>
    </div>
  );
};

export default InputEmailValidate;
