import { AbsenceCreation } from '@/features/dashboard-rh/create-absence-rh/props/step.interface';
import moment from 'moment';

export interface AbsenceDateRangeModel {
  dateStart?: string;
  dateEnd?: string;
  lastDayWork?: string;
  absence?: AbsenceCreation;
}

export const absenceDateRegex = /^(20)\d{2}-\d{2}-\d{2}$/;

export const isVerifyingDate = ({
  verifying,
  editing,
  hasVerifiedOnce,
}: {
  verifying: boolean;
  editing: boolean;
  hasVerifiedOnce: boolean;
}) => verifying || (editing && hasVerifiedOnce);

export const isEmptyDate = (value?: string): value is undefined => value == null || value === '';

export const isInvalideDate = (value?: string): boolean => value != null && !absenceDateRegex.test(value);
export const isToLate = (value?: string) => {
  if (value == null) return false;
  const twoYearsAgo = moment().subtract(2, 'years').endOf('day');
  return moment(value).startOf('day').isBefore(twoYearsAgo);
};
