import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

function ProfilValidationUI() {
  const state = useLocation();
  const navigate = useNavigate();
  const handleClick = (event: any) => {
    event.preventDefault();
    if (state.state.document && Object.keys(state.state.document).length > 0) {
      if (state.state.document.document) {
        // navigate("/declarationdetailstype1", {state: {...state.state.document}})
        navigate('/declaration-date', {
          state: { ...state.state.document },
        });
      } else {
        navigate('/declaration-date', {
          state: { document: state.state.document },
        });
      }
    } else if (state.state.page) {
      navigate(state.state.page);
    } else {
      navigate('/profil');
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb-5 text-center">Informations validées</h3>

        <form className="form-declaration text-center col-12 col-md-8">
          <div className="declaration-check mb-5">
            <FontAwesomeIcon className="fa-5x" icon={faCheck} />
          </div>

          <div className="col-12">
            <p>
              Merci. <br /> Vos informations sont validées.
            </p>
          </div>
        </form>
        <div className="text-center">
          <button type="button" className="btn btn-kerij" onClick={handleClick}>
            {(state.state.document && Object.keys(state.state.document).length > 0 && 'Continuer à déclarer') ||
              (state.state.page === '/services' && 'Accéder aux services') ||
              (state.state.page === '/activites' && 'Accéder aux activites') ||
              (state.state.page === '/rdv' && 'Accéder aux rdv') ||
              'Accéder au profil'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfilValidationUI;
